/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * OpenAPI spec version: 0.7.0
 */
import { useQuery, useMutation } from "@tanstack/react-query"
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query"
import { customMutator } from "./axios"
import { customFormDataFn } from "./customFormDataFn"
export type GetUsers200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetUsers200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetUsers200HydraSearchHydraMappingItem[]
}

export type GetUsers200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetUsers200 = {
  "hydra:member": UserJsonldUserReadCollection[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetUsers200HydraView
  "hydra:search"?: GetUsers200HydraSearch
}

export type GetUsersParams = {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
  id?: string
  "id[]"?: string[]
  "status.code"?: string
  "status.code[]"?: string[]
  search?: string
}

export type GetTsubscriptions200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetTsubscriptions200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetTsubscriptions200HydraSearchHydraMappingItem[]
}

export type GetTsubscriptions200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetTsubscriptions200 = {
  "hydra:member": TsubscriptionJsonldReadCollectionSubscription[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetTsubscriptions200HydraView
  "hydra:search"?: GetTsubscriptions200HydraSearch
}

export type GetTsubscriptionsParams = { page?: number; itemsPerPage?: number }

export type GettPropertyViews200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GettPropertyViews200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GettPropertyViews200HydraSearchHydraMappingItem[]
}

export type GettPropertyViews200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GettPropertyViews200 = {
  "hydra:member": TpropertyViewJsonldReadCollectionTpropertyView[]
  "hydra:totalItems"?: number
  "hydra:view"?: GettPropertyViews200HydraView
  "hydra:search"?: GettPropertyViews200HydraSearch
}

export type GettPropertyViewsParams = { page?: number; itemsPerPage?: number; pagination?: boolean }

export type GettPropertyViewTypes200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GettPropertyViewTypes200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GettPropertyViewTypes200HydraSearchHydraMappingItem[]
}

export type GettPropertyViewTypes200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GettPropertyViewTypes200 = {
  "hydra:member": TpropertyViewTypeJsonldReadCollectionTpropertyViewType[]
  "hydra:totalItems"?: number
  "hydra:view"?: GettPropertyViewTypes200HydraView
  "hydra:search"?: GettPropertyViewTypes200HydraSearch
}

export type GettPropertyViewTypesParams = {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
}

export type GettPropertySubTypes200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GettPropertySubTypes200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GettPropertySubTypes200HydraSearchHydraMappingItem[]
}

export type GettPropertySubTypes200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GettPropertySubTypes200 = {
  "hydra:member": TpropertySubTypeJsonldReadCollectionTpropertySubType[]
  "hydra:totalItems"?: number
  "hydra:view"?: GettPropertySubTypes200HydraView
  "hydra:search"?: GettPropertySubTypes200HydraSearch
}

export type GettPropertySubTypesParams = {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
}

export type GettPropertyStates200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GettPropertyStates200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GettPropertyStates200HydraSearchHydraMappingItem[]
}

export type GettPropertyStates200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GettPropertyStates200 = {
  "hydra:member": TpropertyStateJsonldReadCollectionTpropertyState[]
  "hydra:totalItems"?: number
  "hydra:view"?: GettPropertyStates200HydraView
  "hydra:search"?: GettPropertyStates200HydraSearch
}

export type GettPropertyStatesParams = {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
}

export type GettPropertySpecificities200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GettPropertySpecificities200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GettPropertySpecificities200HydraSearchHydraMappingItem[]
}

export type GettPropertySpecificities200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GettPropertySpecificities200 = {
  "hydra:member": TpropertySpecificityJsonldReadCollectionTpropertySpecificity[]
  "hydra:totalItems"?: number
  "hydra:view"?: GettPropertySpecificities200HydraView
  "hydra:search"?: GettPropertySpecificities200HydraSearch
}

export type GettPropertySpecificitiesParams = {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
}

export type GettPropertySituations200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GettPropertySituations200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GettPropertySituations200HydraSearchHydraMappingItem[]
}

export type GettPropertySituations200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GettPropertySituations200 = {
  "hydra:member": TpropertySituationJsonldReadCollectionTpropertySituation[]
  "hydra:totalItems"?: number
  "hydra:view"?: GettPropertySituations200HydraView
  "hydra:search"?: GettPropertySituations200HydraSearch
}

export type GettPropertySituationsParams = {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
}

export type GettPropertyMandateTypes200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GettPropertyMandateTypes200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GettPropertyMandateTypes200HydraSearchHydraMappingItem[]
}

export type GettPropertyMandateTypes200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GettPropertyMandateTypes200 = {
  "hydra:member": TpropertyMandateTypeJsonldReadCollectionTpropertyMandateType[]
  "hydra:totalItems"?: number
  "hydra:view"?: GettPropertyMandateTypes200HydraView
  "hydra:search"?: GettPropertyMandateTypes200HydraSearch
}

export type GettPropertyMandateTypesParams = {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
}

export type GettPropertyFacilities200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GettPropertyFacilities200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GettPropertyFacilities200HydraSearchHydraMappingItem[]
}

export type GettPropertyFacilities200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GettPropertyFacilities200 = {
  "hydra:member": TpropertyFacilityJsonldReadCollectionTpropertyFacility[]
  "hydra:totalItems"?: number
  "hydra:view"?: GettPropertyFacilities200HydraView
  "hydra:search"?: GettPropertyFacilities200HydraSearch
}

export type GettPropertyFacilitiesParams = {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
}

export type GettPropertyAvailabilities200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GettPropertyAvailabilities200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GettPropertyAvailabilities200HydraSearchHydraMappingItem[]
}

export type GettPropertyAvailabilities200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GettPropertyAvailabilities200 = {
  "hydra:member": TpropertyAvailabilityJsonldReadCollectionTpropertyAvailability[]
  "hydra:totalItems"?: number
  "hydra:view"?: GettPropertyAvailabilities200HydraView
  "hydra:search"?: GettPropertyAvailabilities200HydraSearch
}

export type GettPropertyAvailabilitiesParams = {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
}

export type GetTpropertiesExcelExportData200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetTpropertiesExcelExportData200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetTpropertiesExcelExportData200HydraSearchHydraMappingItem[]
}

export type GetTpropertiesExcelExportData200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetTpropertiesExcelExportData200 = {
  "hydra:member": TpropertyJsonldReadCollectionTpropertyExcelExport[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetTpropertiesExcelExportData200HydraView
  "hydra:search"?: GetTpropertiesExcelExportData200HydraSearch
}

export type GetTpropertiesExcelExportDataParams = {
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
}

export type GetTproperties200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetTproperties200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetTproperties200HydraSearchHydraMappingItem[]
}

export type GetTproperties200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetTproperties200 = {
  "hydra:member": TpropertyJsonldReadCollectionTpropertyWithoutPrivateData[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetTproperties200HydraView
  "hydra:search"?: GetTproperties200HydraSearch
}

export type GetTpropertiesParams = {
  page?: number
  itemsPerPage?: number
  search?: string
  businessUnit?: string
  "businessUnit[]"?: string[]
  user?: string
  "user[]"?: string[]
  type?: string
  "type[]"?: string[]
  subType?: string
  "subType[]"?: string[]
  mandateType?: string
  "mandateType[]"?: string[]
  specificities?: string
  "specificities[]"?: string[]
  facilities?: string
  "facilities[]"?: string[]
  state?: string
  "state[]"?: string[]
  views?: string
  "views[]"?: string[]
  availability?: string
  "availability[]"?: string[]
  viewTypes?: string
  "viewTypes[]"?: string[]
  situations?: string
  "situations[]"?: string[]
  currency?: string
  "currency[]"?: string[]
  pricePeriodicity?: string
  "pricePeriodicity[]"?: string[]
  "address.country"?: string
  "address.country[]"?: string[]
  "address.city"?: string
  "address.city[]"?: string[]
  "address.postcode"?: string
  "project.type"?: string
  "project.type[]"?: string[]
  "project.status"?: string
  "project.status[]"?: string[]
  "agreementStartAt[before]"?: string
  "agreementStartAt[strictly_before]"?: string
  "agreementStartAt[after]"?: string
  "agreementStartAt[strictly_after]"?: string
  "agreementEndAt[before]"?: string
  "agreementEndAt[strictly_before]"?: string
  "agreementEndAt[after]"?: string
  "agreementEndAt[strictly_after]"?: string
  "commitmentAt[before]"?: string
  "commitmentAt[strictly_before]"?: string
  "commitmentAt[after]"?: string
  "commitmentAt[strictly_after]"?: string
  "contractAt[before]"?: string
  "contractAt[strictly_before]"?: string
  "contractAt[after]"?: string
  "contractAt[strictly_after]"?: string
  "createdAt[before]"?: string
  "createdAt[strictly_before]"?: string
  "createdAt[after]"?: string
  "createdAt[strictly_after]"?: string
  "updatedAt[before]"?: string
  "updatedAt[strictly_before]"?: string
  "updatedAt[after]"?: string
  "updatedAt[strictly_after]"?: string
  "price[between]"?: string
  "price[gt]"?: string
  "price[gte]"?: string
  "price[lt]"?: string
  "price[lte]"?: string
  "numberOfRooms[between]"?: string
  "numberOfRooms[gt]"?: string
  "numberOfRooms[gte]"?: string
  "numberOfRooms[lt]"?: string
  "numberOfRooms[lte]"?: string
  "numberOfBedrooms[between]"?: string
  "numberOfBedrooms[gt]"?: string
  "numberOfBedrooms[gte]"?: string
  "numberOfBedrooms[lt]"?: string
  "numberOfBedrooms[lte]"?: string
  "surfaceAreaInSquareMeters[between]"?: string
  "surfaceAreaInSquareMeters[gt]"?: string
  "surfaceAreaInSquareMeters[gte]"?: string
  "surfaceAreaInSquareMeters[lt]"?: string
  "surfaceAreaInSquareMeters[lte]"?: string
  "landSurfaceAreaInSquareMeters[between]"?: string
  "landSurfaceAreaInSquareMeters[gt]"?: string
  "landSurfaceAreaInSquareMeters[gte]"?: string
  "landSurfaceAreaInSquareMeters[lt]"?: string
  "landSurfaceAreaInSquareMeters[lte]"?: string
  "floor[between]"?: string
  "floor[gt]"?: string
  "floor[gte]"?: string
  "floor[lt]"?: string
  "floor[lte]"?: string
  isGroundFloor?: boolean
  isTopFloor?: boolean
  "address.postcode[]"?: string[]
}

export type PostSocietyMediaBody = {
  file?: Blob
  usage?: string
  isDefault?: boolean
  order?: number
  skipUpload?: boolean
  replaceAll?: boolean
  replaceID?: string
}

export type GetSocietiesExcelExportData200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetSocietiesExcelExportData200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetSocietiesExcelExportData200HydraSearchHydraMappingItem[]
}

export type GetSocietiesExcelExportData200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetSocietiesExcelExportData200 = {
  "hydra:member": SocietyJsonldReadCollectionSocietyExcelExport[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetSocietiesExcelExportData200HydraView
  "hydra:search"?: GetSocietiesExcelExportData200HydraSearch
}

export type GetSocietiesExcelExportDataOrderUpdatedAt =
  (typeof GetSocietiesExcelExportDataOrderUpdatedAt)[keyof typeof GetSocietiesExcelExportDataOrderUpdatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSocietiesExcelExportDataOrderUpdatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetSocietiesExcelExportDataOrderCreatedAt =
  (typeof GetSocietiesExcelExportDataOrderCreatedAt)[keyof typeof GetSocietiesExcelExportDataOrderCreatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSocietiesExcelExportDataOrderCreatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetSocietiesExcelExportDataOrderVatNumber =
  (typeof GetSocietiesExcelExportDataOrderVatNumber)[keyof typeof GetSocietiesExcelExportDataOrderVatNumber]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSocietiesExcelExportDataOrderVatNumber = {
  asc: "asc",
  desc: "desc",
} as const

export type GetSocietiesExcelExportDataOrderOfficialCode =
  (typeof GetSocietiesExcelExportDataOrderOfficialCode)[keyof typeof GetSocietiesExcelExportDataOrderOfficialCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSocietiesExcelExportDataOrderOfficialCode = {
  asc: "asc",
  desc: "desc",
} as const

export type GetSocietiesExcelExportDataOrderOfficialName =
  (typeof GetSocietiesExcelExportDataOrderOfficialName)[keyof typeof GetSocietiesExcelExportDataOrderOfficialName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSocietiesExcelExportDataOrderOfficialName = {
  asc: "asc",
  desc: "desc",
} as const

export type GetSocietiesExcelExportDataOrderName =
  (typeof GetSocietiesExcelExportDataOrderName)[keyof typeof GetSocietiesExcelExportDataOrderName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSocietiesExcelExportDataOrderName = {
  asc: "asc",
  desc: "desc",
} as const

export type GetSocietiesExcelExportDataOrderId =
  (typeof GetSocietiesExcelExportDataOrderId)[keyof typeof GetSocietiesExcelExportDataOrderId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSocietiesExcelExportDataOrderId = {
  asc: "asc",
  desc: "desc",
} as const

export type GetSocietiesExcelExportDataParams = {
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
  "order[id]"?: GetSocietiesExcelExportDataOrderId
  "order[name]"?: GetSocietiesExcelExportDataOrderName
  "order[officialName]"?: GetSocietiesExcelExportDataOrderOfficialName
  "order[officialCode]"?: GetSocietiesExcelExportDataOrderOfficialCode
  "order[vatNumber]"?: GetSocietiesExcelExportDataOrderVatNumber
  "order[createdAt]"?: GetSocietiesExcelExportDataOrderCreatedAt
  "order[updatedAt]"?: GetSocietiesExcelExportDataOrderUpdatedAt
}

export type GetSocieties200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetSocieties200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetSocieties200HydraSearchHydraMappingItem[]
}

export type GetSocieties200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetSocieties200 = {
  "hydra:member": SocietyJsonldReadCollectionSociety[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetSocieties200HydraView
  "hydra:search"?: GetSocieties200HydraSearch
}

export type GetSocietiesOrderVatNumber =
  (typeof GetSocietiesOrderVatNumber)[keyof typeof GetSocietiesOrderVatNumber]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSocietiesOrderVatNumber = {
  asc: "asc",
  desc: "desc",
} as const

export type GetSocietiesOrderOfficialCode =
  (typeof GetSocietiesOrderOfficialCode)[keyof typeof GetSocietiesOrderOfficialCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSocietiesOrderOfficialCode = {
  asc: "asc",
  desc: "desc",
} as const

export type GetSocietiesOrderOfficialName =
  (typeof GetSocietiesOrderOfficialName)[keyof typeof GetSocietiesOrderOfficialName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSocietiesOrderOfficialName = {
  asc: "asc",
  desc: "desc",
} as const

export type GetSocietiesOrderId = (typeof GetSocietiesOrderId)[keyof typeof GetSocietiesOrderId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSocietiesOrderId = {
  asc: "asc",
  desc: "desc",
} as const

export type GetSocietiesOrderUpdatedAt =
  (typeof GetSocietiesOrderUpdatedAt)[keyof typeof GetSocietiesOrderUpdatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSocietiesOrderUpdatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetSocietiesOrderCreatedAt =
  (typeof GetSocietiesOrderCreatedAt)[keyof typeof GetSocietiesOrderCreatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSocietiesOrderCreatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetSocietiesOrderBrand =
  (typeof GetSocietiesOrderBrand)[keyof typeof GetSocietiesOrderBrand]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSocietiesOrderBrand = {
  asc: "asc",
  desc: "desc",
} as const

export type GetSocietiesOrderName =
  (typeof GetSocietiesOrderName)[keyof typeof GetSocietiesOrderName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSocietiesOrderName = {
  asc: "asc",
  desc: "desc",
} as const

export type GetSocietiesParams = {
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
  internalSource?: string
  "internalSource[]"?: string[]
  "contactDetails.contactDetails.zipcode"?: string
  "contactDetails.contactDetails.country"?: string
  "contactDetails.contactDetails.city"?: string
  "status.code"?: string
  "status.code[]"?: string[]
  "cluster.cluster.type"?: string
  "cluster.cluster.type[]"?: string[]
  "cluster.cluster.status.code"?: string
  "cluster.cluster.type.code"?: string
  "cluster.cluster.internalSource"?: string
  "cluster.cluster.internalSource[]"?: string[]
  "agency.agency.id"?: string
  "agency.agency.id[]"?: string[]
  "agency.agency.name"?: string
  "agency.agency.status.code"?: string
  "agency.agency.internalSource"?: string
  "agency.agency.internalSource[]"?: string[]
  "legalRepresentative.collaborator.id"?: string
  "legalRepresentative.collaborator.id[]"?: string[]
  "legalRepresentative.collaborator.firstname"?: string
  "legalRepresentative.collaborator.lastname"?: string
  "legalRepresentative.collaborator.status.code"?: string
  search?: string
  "order[name]"?: GetSocietiesOrderName
  "order[brand]"?: GetSocietiesOrderBrand
  "order[createdAt]"?: GetSocietiesOrderCreatedAt
  "order[updatedAt]"?: GetSocietiesOrderUpdatedAt
  "order[id]"?: GetSocietiesOrderId
  "order[officialName]"?: GetSocietiesOrderOfficialName
  "order[officialCode]"?: GetSocietiesOrderOfficialCode
  "order[vatNumber]"?: GetSocietiesOrderVatNumber
}

export type GetUserSharedDatas200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetUserSharedDatas200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetUserSharedDatas200HydraSearchHydraMappingItem[]
}

export type GetUserSharedDatas200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetUserSharedDatas200 = {
  "hydra:member": SharedDataJsonldReadCollectionSharedData[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetUserSharedDatas200HydraView
  "hydra:search"?: GetUserSharedDatas200HydraSearch
}

export type GetUserSharedDatasParams = {
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
  "from.id"?: string
  "from.id[]"?: string[]
  "to.id"?: string
  "to.id[]"?: string[]
  "people.id"?: string
  "people.id[]"?: string[]
  "type.code"?: string
  "type.code[]"?: string[]
  "status.code"?: string
  "status.code[]"?: string[]
}

export type GetRefLabelsExcelExportData200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetRefLabelsExcelExportData200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetRefLabelsExcelExportData200HydraSearchHydraMappingItem[]
}

export type GetRefLabelsExcelExportData200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetRefLabelsExcelExportData200 = {
  "hydra:member": RefLabelJsonldReadCollectionRefLabelsExcelExport[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetRefLabelsExcelExportData200HydraView
  "hydra:search"?: GetRefLabelsExcelExportData200HydraSearch
}

export type GetRefLabelsExcelExportDataParams = {
  page?: number
  itemsPerPage?: number
  group?: string
  code?: string
  "code[]"?: string[]
  value?: string
  label?: string
  search?: string
}

export type GetRefLabels200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetRefLabels200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetRefLabels200HydraSearchHydraMappingItem[]
}

export type GetRefLabels200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetRefLabels200 = {
  "hydra:member": RefLabelJsonldReadCollectionRefLabel[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetRefLabels200HydraView
  "hydra:search"?: GetRefLabels200HydraSearch
}

export type GetRefLabelsParams = {
  page?: number
  itemsPerPage?: number
  group?: string
  code?: string
  "code[]"?: string[]
  value?: string
  label?: string
  search?: string
}

export type GetRefLabelGroups200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetRefLabelGroups200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetRefLabelGroups200HydraSearchHydraMappingItem[]
}

export type GetRefLabelGroups200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetRefLabelGroups200 = {
  "hydra:member": RefLabelGroupJsonldReadCollectionRefLabelGroup[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetRefLabelGroups200HydraView
  "hydra:search"?: GetRefLabelGroups200HydraSearch
}

export type GetRefLabelGroupsParams = {
  page?: number
  itemsPerPage?: number
  group?: string
  label?: string
  search?: string
}

export type GetPropertiesSearchExcelExportData200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetPropertiesSearchExcelExportData200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetPropertiesSearchExcelExportData200HydraSearchHydraMappingItem[]
}

export type GetPropertiesSearchExcelExportData200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetPropertiesSearchExcelExportData200 = {
  "hydra:member": PropertySearchJsonldReadCollectionPropertySearchExcelExport[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetPropertiesSearchExcelExportData200HydraView
  "hydra:search"?: GetPropertiesSearchExcelExportData200HydraSearch
}

export type GetPropertiesSearchExcelExportDataParams = {
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
}

export type GetPropertiesSearch200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetPropertiesSearch200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetPropertiesSearch200HydraSearchHydraMappingItem[]
}

export type GetPropertiesSearch200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetPropertiesSearch200 = {
  "hydra:member": PropertySearchJsonldReadCollectionPropertySearch[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetPropertiesSearch200HydraView
  "hydra:search"?: GetPropertiesSearch200HydraSearch
}

export type GetPropertiesSearchParams = {
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
  search?: string
}

export type GetPropertiesOfferOfferExcelExportData200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetPropertiesOfferOfferExcelExportData200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetPropertiesOfferOfferExcelExportData200HydraSearchHydraMappingItem[]
}

export type GetPropertiesOfferOfferExcelExportData200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetPropertiesOfferOfferExcelExportData200 = {
  "hydra:member": PropertyOfferJsonldReadCollectionPropertyOfferExcelExport[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetPropertiesOfferOfferExcelExportData200HydraView
  "hydra:search"?: GetPropertiesOfferOfferExcelExportData200HydraSearch
}

export type GetPropertiesOfferOfferExcelExportDataParams = {
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
}

export type GetPropertiesOffer200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetPropertiesOffer200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetPropertiesOffer200HydraSearchHydraMappingItem[]
}

export type GetPropertiesOffer200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetPropertiesOffer200 = {
  "hydra:member": PropertyOfferJsonldReadCollectionPropertyOffer[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetPropertiesOffer200HydraView
  "hydra:search"?: GetPropertiesOffer200HydraSearch
}

export type GetPropertiesOfferParams = {
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
  reference?: string
  "reference[]"?: string[]
  search?: string
  notInternalSource?: string[]
}

export type GetPropertiesExcelExportData200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetPropertiesExcelExportData200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetPropertiesExcelExportData200HydraSearchHydraMappingItem[]
}

export type GetPropertiesExcelExportData200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetPropertiesExcelExportData200 = {
  "hydra:member": PropertyJsonldReadCollectionPropertyExcelExport[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetPropertiesExcelExportData200HydraView
  "hydra:search"?: GetPropertiesExcelExportData200HydraSearch
}

export type GetPropertiesExcelExportDataOrderUpdatedAt =
  (typeof GetPropertiesExcelExportDataOrderUpdatedAt)[keyof typeof GetPropertiesExcelExportDataOrderUpdatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPropertiesExcelExportDataOrderUpdatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetPropertiesExcelExportDataOrderCreatedAt =
  (typeof GetPropertiesExcelExportDataOrderCreatedAt)[keyof typeof GetPropertiesExcelExportDataOrderCreatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPropertiesExcelExportDataOrderCreatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetPropertiesExcelExportDataOrderId =
  (typeof GetPropertiesExcelExportDataOrderId)[keyof typeof GetPropertiesExcelExportDataOrderId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPropertiesExcelExportDataOrderId = {
  asc: "asc",
  desc: "desc",
} as const

export type GetPropertiesExcelExportDataParams = {
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
  "order[id]"?: GetPropertiesExcelExportDataOrderId
  "order[createdAt]"?: GetPropertiesExcelExportDataOrderCreatedAt
  "order[updatedAt]"?: GetPropertiesExcelExportDataOrderUpdatedAt
}

export type GetProperties200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetProperties200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetProperties200HydraSearchHydraMappingItem[]
}

export type GetProperties200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetProperties200 = {
  "hydra:member": PropertyJsonldReadCollectionProperty[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetProperties200HydraView
  "hydra:search"?: GetProperties200HydraSearch
}

export type GetPropertiesOrderUpdatedAt =
  (typeof GetPropertiesOrderUpdatedAt)[keyof typeof GetPropertiesOrderUpdatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPropertiesOrderUpdatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetPropertiesOrderCreatedAt =
  (typeof GetPropertiesOrderCreatedAt)[keyof typeof GetPropertiesOrderCreatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPropertiesOrderCreatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetPropertiesOrderId = (typeof GetPropertiesOrderId)[keyof typeof GetPropertiesOrderId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPropertiesOrderId = {
  asc: "asc",
  desc: "desc",
} as const

export type GetPropertiesParams = {
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
  name?: string
  areaTotal?: number
  "areaTotal[]"?: number[]
  availabilityStatus?: string
  "availabilityStatus[]"?: string[]
  "propertyOffer.propertyOffer.id"?: string
  "propertyOffer.propertyOffer.id[]"?: string[]
  "propertyOwners.people.id"?: string
  "propertyOwners.people.id[]"?: string[]
  search?: string
  "order[id]"?: GetPropertiesOrderId
  "order[createdAt]"?: GetPropertiesOrderCreatedAt
  "order[updatedAt]"?: GetPropertiesOrderUpdatedAt
}

export type GetProjectsExcelExportData200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetProjectsExcelExportData200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetProjectsExcelExportData200HydraSearchHydraMappingItem[]
}

export type GetProjectsExcelExportData200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetProjectsExcelExportData200 = {
  "hydra:member": ProjectJsonldReadCollectionProjectExcelExport[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetProjectsExcelExportData200HydraView
  "hydra:search"?: GetProjectsExcelExportData200HydraSearch
}

export type GetProjectsExcelExportDataOrderUpdatedAt =
  (typeof GetProjectsExcelExportDataOrderUpdatedAt)[keyof typeof GetProjectsExcelExportDataOrderUpdatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetProjectsExcelExportDataOrderUpdatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetProjectsExcelExportDataOrderCreatedAt =
  (typeof GetProjectsExcelExportDataOrderCreatedAt)[keyof typeof GetProjectsExcelExportDataOrderCreatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetProjectsExcelExportDataOrderCreatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetProjectsExcelExportDataOrderId =
  (typeof GetProjectsExcelExportDataOrderId)[keyof typeof GetProjectsExcelExportDataOrderId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetProjectsExcelExportDataOrderId = {
  asc: "asc",
  desc: "desc",
} as const

export type GetProjectsExcelExportDataParams = {
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
  "order[id]"?: GetProjectsExcelExportDataOrderId
  "order[createdAt]"?: GetProjectsExcelExportDataOrderCreatedAt
  "order[updatedAt]"?: GetProjectsExcelExportDataOrderUpdatedAt
}

export type GetProjects200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetProjects200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetProjects200HydraSearchHydraMappingItem[]
}

export type GetProjects200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetProjects200 = {
  "hydra:member": ProjectJsonldReadCollectionProject[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetProjects200HydraView
  "hydra:search"?: GetProjects200HydraSearch
}

export type GetProjectsOrderUpdatedAt =
  (typeof GetProjectsOrderUpdatedAt)[keyof typeof GetProjectsOrderUpdatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetProjectsOrderUpdatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetProjectsOrderCreatedAt =
  (typeof GetProjectsOrderCreatedAt)[keyof typeof GetProjectsOrderCreatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetProjectsOrderCreatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetProjectsOrderId = (typeof GetProjectsOrderId)[keyof typeof GetProjectsOrderId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetProjectsOrderId = {
  asc: "asc",
  desc: "desc",
} as const

export type GetProjectsParams = {
  page?: number
  itemsPerPage?: number
  "agreement.code"?: string
  "agreement.code[]"?: string[]
  agreementReference?: string
  "agreementReference[]"?: string[]
  id?: string
  "id[]"?: string[]
  internalSource?: string
  "internalSource[]"?: string[]
  "status.code"?: string
  "status.code[]"?: string[]
  "subtype.code"?: string
  "subtype.code[]"?: string[]
  "type.code"?: string
  "type.code[]"?: string[]
  "collaborator.collaborator.id"?: string
  "collaborator.collaborator.id[]"?: string[]
  "collaborator.collaborator.firstname"?: string
  "collaborator.collaborator.lastname"?: string
  "collaborator.collaborator.status.code"?: string
  "collaborator.collaborator.status.code[]"?: string[]
  "collaborator.collaborator.internalSource"?: string
  "collaborator.collaborator.internalSource[]"?: string[]
  "cluster.cluster.id"?: string
  "cluster.cluster.id[]"?: string[]
  "cluster.cluster.status.code"?: string
  "cluster.cluster.status.code[]"?: string[]
  "cluster.cluster.type.code"?: string
  "cluster.cluster.type.code[]"?: string[]
  "cluster.cluster.name"?: string
  "cluster.cluster.internalSource"?: string
  "cluster.cluster.internalSource[]"?: string[]
  "people.people.id"?: string
  "people.people.id[]"?: string[]
  "people.people.firstname"?: string
  "people.people.lastname"?: string
  "projectAdvertiser.projectAdvertiser.id"?: string
  "projectAdvertiser.projectAdvertiser.id[]"?: string[]
  "projectAdvertiser.projectAdvertiser.name"?: string
  "propertyOffer.propertyOffer.id"?: string
  "propertyOffer.propertyOffer.id[]"?: string[]
  "propertyOffer.propertyOffer.status.code"?: string
  "propertyOffer.propertyOffer.status.code[]"?: string[]
  "propertyOffer.propertyOffer.type.code"?: string
  "propertyOffer.propertyOffer.type.code[]"?: string[]
  "propertyOffer.propertyOffer.subtype.code"?: string
  "propertyOffer.propertyOffer.subtype.code[]"?: string[]
  "propertyOffer.propertyOffer.unitPrice.code"?: string
  "propertyOffer.propertyOffer.unitPrice.code[]"?: string[]
  "propertyOffer.propertyOffer.pricePeriod"?: string
  "propertyOffer.propertyOffer.pricePeriod[]"?: string[]
  "propertyOffer.propertyOffer.internalSource"?: string
  "propertyOffer.propertyOffer.internalSource[]"?: string[]
  "propertyOffer.propertyOffer.reference"?: string
  "propertyOffer.propertyOffer.group.code"?: string
  "propertyOffer.propertyOffer.group.code[]"?: string[]
  "propertyOffer.property.propertyActivities.code"?: string
  "propertyOffer.property.propertyActivities.code[]"?: string[]
  "propertyOffer.property.areaUnit.code"?: string
  "propertyOffer.property.areaUnit.code[]"?: string[]
  "propertyOffer.property.availabilityStatus.code"?: string
  "propertyOffer.property.availabilityStatus.code[]"?: string[]
  "propertyOffer.property.floor"?: number
  "propertyOffer.property.floor[]"?: number[]
  "propertyOffer.property.floorName.code"?: string
  "propertyOffer.property.floorName.code[]"?: string[]
  "propertyOffer.property.id"?: string
  "propertyOffer.property.id[]"?: string[]
  "propertyOffer.property.internalSource"?: string
  "propertyOffer.property.internalSource[]"?: string[]
  "propertyOffer.property.name"?: string
  "propertyOffer.property.propertyProximities.code"?: string
  "propertyOffer.property.propertyProximities.code[]"?: string[]
  "propertyOffer.property.propertyOrientations.code"?: string
  "propertyOffer.property.propertyOrientations.code[]"?: string[]
  "propertyOffer.property.propertyServices.code"?: string
  "propertyOffer.property.propertyServices.code[]"?: string[]
  "propertyOffer.property.status.code"?: string
  "propertyOffer.property.status.code[]"?: string[]
  "propertyOffer.property.type.code"?: string
  "propertyOffer.property.type.code[]"?: string[]
  "propertyOffer.property.subtype.code"?: string
  "propertyOffer.property.subtype.code[]"?: string[]
  "propertyOffer.property.propertyViewLandscapes.code"?: string
  "propertyOffer.property.propertyViewLandscapes.code[]"?: string[]
  "propertyOffer.property.propertyArea.propertyArea.type.code"?: string
  "propertyOffer.property.propertyArea.propertyArea.type.code[]"?: string[]
  "propertyOffer.property.propertyArea.propertyArea.unit.code"?: string
  "propertyOffer.property.propertyArea.propertyArea.unit.code[]"?: string[]
  "propertyOffer.property.propertyContactDetails.contactDetails.zipcode"?: string
  "propertyOffer.property.propertyContactDetails.contactDetails.country"?: string
  "propertyOffer.property.propertyContactDetails.contactDetails.city"?: string
  "propertySearch.id"?: string
  "propertySearch.id[]"?: string[]
  "propertySearch.propertySearch.internalSource"?: string
  "propertySearch.propertySearch.internalSource[]"?: string[]
  "propertySearch.propertySearch.status.code"?: string
  "propertySearch.propertySearch.status.code[]"?: string[]
  search?: string
  notInternalSource?: string[]
  "agreementFrom[between]"?: string
  "agreementFrom[gt]"?: string
  "agreementFrom[gte]"?: string
  "agreementFrom[lt]"?: string
  "agreementFrom[lte]"?: string
  "agreementFrom[eq]"?: string
  "agreementTo[between]"?: string
  "agreementTo[gt]"?: string
  "agreementTo[gte]"?: string
  "agreementTo[lt]"?: string
  "agreementTo[lte]"?: string
  "agreementTo[eq]"?: string
  "createdAt[between]"?: string
  "createdAt[gt]"?: string
  "createdAt[gte]"?: string
  "createdAt[lt]"?: string
  "createdAt[lte]"?: string
  "createdAt[eq]"?: string
  "updatedAt[between]"?: string
  "updatedAt[gt]"?: string
  "updatedAt[gte]"?: string
  "updatedAt[lt]"?: string
  "updatedAt[lte]"?: string
  "updatedAt[eq]"?: string
  "propertyOffer.property.areaValue[between]"?: string
  "propertyOffer.property.areaValue[gt]"?: string
  "propertyOffer.property.areaValue[gte]"?: string
  "propertyOffer.property.areaValue[lt]"?: string
  "propertyOffer.property.areaValue[lte]"?: string
  "propertyOffer.property.areaValue[eq]"?: string
  "propertyOffer.property.nbRooms[between]"?: string
  "propertyOffer.property.nbRooms[gt]"?: string
  "propertyOffer.property.nbRooms[gte]"?: string
  "propertyOffer.property.nbRooms[lt]"?: string
  "propertyOffer.property.nbRooms[lte]"?: string
  "propertyOffer.property.nbRooms[eq]"?: string
  "propertyOffer.property.nbBedrooms[between]"?: string
  "propertyOffer.property.nbBedrooms[gt]"?: string
  "propertyOffer.property.nbBedrooms[gte]"?: string
  "propertyOffer.property.nbBedrooms[lt]"?: string
  "propertyOffer.property.nbBedrooms[lte]"?: string
  "propertyOffer.property.nbBedrooms[eq]"?: string
  "propertyOffer.property.floor[between]"?: string
  "propertyOffer.property.floor[gt]"?: string
  "propertyOffer.property.floor[gte]"?: string
  "propertyOffer.property.floor[lt]"?: string
  "propertyOffer.property.floor[lte]"?: string
  "propertyOffer.property.floor[eq]"?: string
  "propertyOffer.propertyOffer.priceValue[between]"?: string
  "propertyOffer.propertyOffer.priceValue[gt]"?: string
  "propertyOffer.propertyOffer.priceValue[gte]"?: string
  "propertyOffer.propertyOffer.priceValue[lt]"?: string
  "propertyOffer.propertyOffer.priceValue[lte]"?: string
  "propertyOffer.propertyOffer.priceValue[eq]"?: string
  "order[id]"?: GetProjectsOrderId
  "order[createdAt]"?: GetProjectsOrderCreatedAt
  "order[updatedAt]"?: GetProjectsOrderUpdatedAt
}

export type GetPeopleParams = { withCollaborators?: boolean; withProjects?: boolean }

export type GetPeoplesExcelExportData200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetPeoplesExcelExportData200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetPeoplesExcelExportData200HydraSearchHydraMappingItem[]
}

export type GetPeoplesExcelExportData200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetPeoplesExcelExportData200 = {
  "hydra:member": PeopleJsonldReadCollectionPeopleExcelExport[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetPeoplesExcelExportData200HydraView
  "hydra:search"?: GetPeoplesExcelExportData200HydraSearch
}

export type GetPeoplesExcelExportDataOrderUpdatedAt =
  (typeof GetPeoplesExcelExportDataOrderUpdatedAt)[keyof typeof GetPeoplesExcelExportDataOrderUpdatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPeoplesExcelExportDataOrderUpdatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetPeoplesExcelExportDataOrderCreatedAt =
  (typeof GetPeoplesExcelExportDataOrderCreatedAt)[keyof typeof GetPeoplesExcelExportDataOrderCreatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPeoplesExcelExportDataOrderCreatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetPeoplesExcelExportDataOrderLastname =
  (typeof GetPeoplesExcelExportDataOrderLastname)[keyof typeof GetPeoplesExcelExportDataOrderLastname]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPeoplesExcelExportDataOrderLastname = {
  asc: "asc",
  desc: "desc",
} as const

export type GetPeoplesExcelExportDataOrderId =
  (typeof GetPeoplesExcelExportDataOrderId)[keyof typeof GetPeoplesExcelExportDataOrderId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPeoplesExcelExportDataOrderId = {
  asc: "asc",
  desc: "desc",
} as const

export type GetPeoplesExcelExportDataParams = {
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
  "order[id]"?: GetPeoplesExcelExportDataOrderId
  "order[lastname]"?: GetPeoplesExcelExportDataOrderLastname
  "order[createdAt]"?: GetPeoplesExcelExportDataOrderCreatedAt
  "order[updatedAt]"?: GetPeoplesExcelExportDataOrderUpdatedAt
  isShared?: boolean
}

export type GetPeoples200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetPeoples200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetPeoples200HydraSearchHydraMappingItem[]
}

export type GetPeoples200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetPeoples200 = {
  "hydra:member": PeopleJsonldReadCollectionPeople[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetPeoples200HydraView
  "hydra:search"?: GetPeoples200HydraSearch
}

export type GetPeoplesOrderUpdatedAt =
  (typeof GetPeoplesOrderUpdatedAt)[keyof typeof GetPeoplesOrderUpdatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPeoplesOrderUpdatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetPeoplesOrderCreatedAt =
  (typeof GetPeoplesOrderCreatedAt)[keyof typeof GetPeoplesOrderCreatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPeoplesOrderCreatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetPeoplesOrderLastname =
  (typeof GetPeoplesOrderLastname)[keyof typeof GetPeoplesOrderLastname]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPeoplesOrderLastname = {
  asc: "asc",
  desc: "desc",
} as const

export type GetPeoplesOrderId = (typeof GetPeoplesOrderId)[keyof typeof GetPeoplesOrderId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPeoplesOrderId = {
  asc: "asc",
  desc: "desc",
} as const

export type GetPeoplesParams = {
  withCollaborators?: boolean
  withProjects?: boolean
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
  "cluster.cluster.id"?: string
  "cluster.cluster.id[]"?: string[]
  "project.project.status.code"?: string
  "project.project.status.code[]"?: string[]
  "project.project.type.code"?: string
  "project.project.type.code[]"?: string[]
  "project.project.subtype.code"?: string
  "project.project.subtype.code[]"?: string[]
  "project.project.propertyOffer.property.type.code"?: string
  "project.project.propertyOffer.property.type.code[]"?: string[]
  "project.project.propertyOffer.property.subtype.code"?: string
  "project.project.propertyOffer.property.subtype.code[]"?: string[]
  "project.project.propertyOffer.property.areaUnit.code"?: string
  "project.project.propertyOffer.property.areaUnit.code[]"?: string[]
  "project.project.propertyOffer.propertyOffer.type.code"?: string
  "project.project.propertyOffer.propertyOffer.type.code[]"?: string[]
  "project.project.propertyOffer.propertyOffer.subtype.code"?: string
  "project.project.propertyOffer.propertyOffer.subtype.code[]"?: string[]
  "project.project.propertyOffer.propertyOffer.reference"?: string
  "project.project.propertyOffer.propertyOffer.reference[]"?: string[]
  "project.project.propertyOffer.propertyOffer.unitPrice.code"?: string
  "project.project.propertyOffer.propertyOffer.unitPrice.code[]"?: string[]
  search?: string
  "project.offer.count[between]"?: string
  "project.offer.count[gt]"?: string
  "project.offer.count[gte]"?: string
  "project.offer.count[lt]"?: string
  "project.offer.count[lte]"?: string
  "project.offer.count[eq]"?: string
  "project.request.count[between]"?: string
  "project.request.count[gt]"?: string
  "project.request.count[gte]"?: string
  "project.request.count[lt]"?: string
  "project.request.count[lte]"?: string
  "project.request.count[eq]"?: string
  "project.project.propertyOffer.property.areaValue[between]"?: string
  "project.project.propertyOffer.property.areaValue[gt]"?: string
  "project.project.propertyOffer.property.areaValue[gte]"?: string
  "project.project.propertyOffer.property.areaValue[lt]"?: string
  "project.project.propertyOffer.property.areaValue[lte]"?: string
  "project.project.propertyOffer.property.areaValue[eq]"?: string
  "project.project.propertyOffer.propertyOffer.priceValue[between]"?: string
  "project.project.propertyOffer.propertyOffer.priceValue[gt]"?: string
  "project.project.propertyOffer.propertyOffer.priceValue[gte]"?: string
  "project.project.propertyOffer.propertyOffer.priceValue[lt]"?: string
  "project.project.propertyOffer.propertyOffer.priceValue[lte]"?: string
  "project.project.propertyOffer.propertyOffer.priceValue[eq]"?: string
  "order[id]"?: GetPeoplesOrderId
  "order[lastname]"?: GetPeoplesOrderLastname
  "order[createdAt]"?: GetPeoplesOrderCreatedAt
  "order[updatedAt]"?: GetPeoplesOrderUpdatedAt
  isShared?: boolean
}

export type GetSynchroStatus200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetSynchroStatus200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetSynchroStatus200HydraSearchHydraMappingItem[]
}

export type GetSynchroStatus200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetSynchroStatus200 = {
  "hydra:member": MBV4SynchroJsonldReadCollectionSynchroStatus[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetSynchroStatus200HydraView
  "hydra:search"?: GetSynchroStatus200HydraSearch
}

export type GetSynchroStatusParams = { page?: number; itemsPerPage?: number }

export type GetBarnesStatusUpdated200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetBarnesStatusUpdated200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetBarnesStatusUpdated200HydraSearchHydraMappingItem[]
}

export type GetBarnesStatusUpdated200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetBarnesStatusUpdated200 = {
  "hydra:member": MBV4SynchroJsonldReadCollectionBarnesStatusUpdated[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetBarnesStatusUpdated200HydraView
  "hydra:search"?: GetBarnesStatusUpdated200HydraSearch
}

export type GetBarnesStatusUpdatedParams = { page?: number; itemsPerPage?: number }

export type GetBarnesStatusDeleted200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetBarnesStatusDeleted200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetBarnesStatusDeleted200HydraSearchHydraMappingItem[]
}

export type GetBarnesStatusDeleted200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetBarnesStatusDeleted200 = {
  "hydra:member": MBV4SynchroJsonldReadCollectionBarnesSttausDeleted[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetBarnesStatusDeleted200HydraView
  "hydra:search"?: GetBarnesStatusDeleted200HydraSearch
}

export type GetBarnesStatusDeletedParams = { page?: number; itemsPerPage?: number }

export type GetSentInterBUs200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetSentInterBUs200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetSentInterBUs200HydraSearchHydraMappingItem[]
}

export type GetSentInterBUs200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetSentInterBUs200 = {
  "hydra:member": InterBuJsonldReadCollectionInterbu[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetSentInterBUs200HydraView
  "hydra:search"?: GetSentInterBUs200HydraSearch
}

export type GetSentInterBUsParams = { page?: number; itemsPerPage?: number; search?: string }

export type GetAssignedInterBUs200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetAssignedInterBUs200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetAssignedInterBUs200HydraSearchHydraMappingItem[]
}

export type GetAssignedInterBUs200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetAssignedInterBUs200 = {
  "hydra:member": InterBuJsonldReadCollectionInterbu[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetAssignedInterBUs200HydraView
  "hydra:search"?: GetAssignedInterBUs200HydraSearch
}

export type GetAssignedInterBUsParams = { page?: number; itemsPerPage?: number; search?: string }

export type GetBuAdminInterBUs200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetBuAdminInterBUs200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetBuAdminInterBUs200HydraSearchHydraMappingItem[]
}

export type GetBuAdminInterBUs200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetBuAdminInterBUs200 = {
  "hydra:member": InterBuJsonldReadCollectionInterbu[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetBuAdminInterBUs200HydraView
  "hydra:search"?: GetBuAdminInterBUs200HydraSearch
}

export type GetBuAdminInterBUsParams = { page?: number; itemsPerPage?: number; search?: string }

export type GetHobbyCategories200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetHobbyCategories200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetHobbyCategories200HydraSearchHydraMappingItem[]
}

export type GetHobbyCategories200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetHobbyCategories200 = {
  "hydra:member": HobbyCategoryJsonldReadCollectionHobbyCategory[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetHobbyCategories200HydraView
  "hydra:search"?: GetHobbyCategories200HydraSearch
}

export type GetHobbyCategoriesParams = {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
}

export type GetEvents200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetEvents200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetEvents200HydraSearchHydraMappingItem[]
}

export type GetEvents200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetEvents200 = {
  "hydra:member": EventJsonldReadCollectionEvent[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetEvents200HydraView
  "hydra:search"?: GetEvents200HydraSearch
}

export type GetEventsParams = { page?: number; itemsPerPage?: number }

export type GetUserClusters200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetUserClusters200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetUserClusters200HydraSearchHydraMappingItem[]
}

export type GetUserClusters200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetUserClusters200 = {
  "hydra:member": EntityResourceJsonldReadCollectionUserClustersEntityCanSetId[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetUserClusters200HydraView
  "hydra:search"?: GetUserClusters200HydraSearch
}

export type GetUserClustersNoId200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetUserClustersNoId200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetUserClustersNoId200HydraSearchHydraMappingItem[]
}

export type GetUserClustersNoId200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetUserClustersNoId200 = {
  "hydra:member": EntityResourceJsonldReadCollectionUserClusters[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetUserClustersNoId200HydraView
  "hydra:search"?: GetUserClustersNoId200HydraSearch
}

export type GetUserClustersNoIdParams = { page?: number; itemsPerPage?: number }

export type PostCollaboratorMediaBody = {
  file?: Blob
  usage?: string
  isDefault?: boolean
  order?: number
  skipUpload?: boolean
  replaceAll?: boolean
  replaceID?: string
}

export type GetCollaboratorsExcelExportData200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetCollaboratorsExcelExportData200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetCollaboratorsExcelExportData200HydraSearchHydraMappingItem[]
}

export type GetCollaboratorsExcelExportData200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetCollaboratorsExcelExportData200 = {
  "hydra:member": CollaboratorJsonldReadCollectionCollaboratorExcelExport[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetCollaboratorsExcelExportData200HydraView
  "hydra:search"?: GetCollaboratorsExcelExportData200HydraSearch
}

export type GetCollaboratorsExcelExportDataOrderUpdatedAt =
  (typeof GetCollaboratorsExcelExportDataOrderUpdatedAt)[keyof typeof GetCollaboratorsExcelExportDataOrderUpdatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollaboratorsExcelExportDataOrderUpdatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetCollaboratorsExcelExportDataOrderCreatedAt =
  (typeof GetCollaboratorsExcelExportDataOrderCreatedAt)[keyof typeof GetCollaboratorsExcelExportDataOrderCreatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollaboratorsExcelExportDataOrderCreatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetCollaboratorsExcelExportDataOrderFullname =
  (typeof GetCollaboratorsExcelExportDataOrderFullname)[keyof typeof GetCollaboratorsExcelExportDataOrderFullname]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollaboratorsExcelExportDataOrderFullname = {
  asc: "asc",
  desc: "desc",
} as const

export type GetCollaboratorsExcelExportDataOrderLastname =
  (typeof GetCollaboratorsExcelExportDataOrderLastname)[keyof typeof GetCollaboratorsExcelExportDataOrderLastname]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollaboratorsExcelExportDataOrderLastname = {
  asc: "asc",
  desc: "desc",
} as const

export type GetCollaboratorsExcelExportDataOrderId =
  (typeof GetCollaboratorsExcelExportDataOrderId)[keyof typeof GetCollaboratorsExcelExportDataOrderId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollaboratorsExcelExportDataOrderId = {
  asc: "asc",
  desc: "desc",
} as const

export type GetCollaboratorsExcelExportDataParams = {
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
  "order[id]"?: GetCollaboratorsExcelExportDataOrderId
  "order[lastname]"?: GetCollaboratorsExcelExportDataOrderLastname
  "order[fullname]"?: GetCollaboratorsExcelExportDataOrderFullname
  "order[createdAt]"?: GetCollaboratorsExcelExportDataOrderCreatedAt
  "order[updatedAt]"?: GetCollaboratorsExcelExportDataOrderUpdatedAt
}

export type GetCollaborators200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetCollaborators200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetCollaborators200HydraSearchHydraMappingItem[]
}

export type GetCollaborators200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetCollaborators200 = {
  "hydra:member": CollaboratorJsonldReadCollectionCollaborator[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetCollaborators200HydraView
  "hydra:search"?: GetCollaborators200HydraSearch
}

export type GetCollaboratorsOrderUpdatedAt =
  (typeof GetCollaboratorsOrderUpdatedAt)[keyof typeof GetCollaboratorsOrderUpdatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollaboratorsOrderUpdatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetCollaboratorsOrderCreatedAt =
  (typeof GetCollaboratorsOrderCreatedAt)[keyof typeof GetCollaboratorsOrderCreatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollaboratorsOrderCreatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetCollaboratorsOrderFullname =
  (typeof GetCollaboratorsOrderFullname)[keyof typeof GetCollaboratorsOrderFullname]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollaboratorsOrderFullname = {
  asc: "asc",
  desc: "desc",
} as const

export type GetCollaboratorsOrderLastname =
  (typeof GetCollaboratorsOrderLastname)[keyof typeof GetCollaboratorsOrderLastname]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollaboratorsOrderLastname = {
  asc: "asc",
  desc: "desc",
} as const

export type GetCollaboratorsOrderId =
  (typeof GetCollaboratorsOrderId)[keyof typeof GetCollaboratorsOrderId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollaboratorsOrderId = {
  asc: "asc",
  desc: "desc",
} as const

export type GetCollaboratorsParams = {
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
  firstname?: string
  lastname?: string
  fullname?: string
  "status.code"?: string
  "status.code[]"?: string[]
  internalSource?: string
  "internalSource[]"?: string[]
  "legalRepresentative.agencyOwner.id"?: string
  "legalRepresentative.agencyOwner.id[]"?: string[]
  "cluster.cluster.id"?: string
  "cluster.cluster.id[]"?: string[]
  "cluster.cluster.internalSource"?: string
  "cluster.cluster.internalSource[]"?: string[]
  "cluster.cluster.type"?: string
  "cluster.cluster.type[]"?: string[]
  "cluster.cluster.status.code"?: string
  "cluster.cluster.status.code[]"?: string[]
  "cluster.cluster.type.code"?: string
  "cluster.cluster.type.code[]"?: string[]
  "agency.agency.id"?: string
  "agency.agency.id[]"?: string[]
  "agency.agency.internalSource"?: string
  "agency.agency.internalSource[]"?: string[]
  "agency.agency.name"?: string
  "agency.agency.status.code"?: string
  "agency.agency.status.code[]"?: string[]
  "project.project.id"?: string
  "project.project.id[]"?: string[]
  "project.project.internalSource"?: string
  "project.project.internalSource[]"?: string[]
  "project.project.status.code"?: string
  "project.project.type.code"?: string
  "project.project.propertyOffer.id"?: string
  "project.project.propertyOffer.id[]"?: string[]
  "project.project.propertyOffer.property.id"?: string
  "project.project.propertyOffer.property.id[]"?: string[]
  "project.project.propertyOffer.property.internalSource"?: string
  "project.project.propertyOffer.property.internalSource[]"?: string[]
  "project.project.propertyOffer.property.status.code"?: string
  "project.project.propertyOffer.property.type.code"?: string
  "project.project.propertyOffer.property.name"?: string
  "project.project.people.id"?: string
  "project.project.people.id[]"?: string[]
  "people.people.id"?: string
  "people.people.id[]"?: string[]
  "people.people.firstname"?: string
  "people.people.lastname"?: string
  search?: string
  "order[id]"?: GetCollaboratorsOrderId
  "order[lastname]"?: GetCollaboratorsOrderLastname
  notInternalSource?: string[]
  "order[fullname]"?: GetCollaboratorsOrderFullname
  "order[createdAt]"?: GetCollaboratorsOrderCreatedAt
  "order[updatedAt]"?: GetCollaboratorsOrderUpdatedAt
}

export type GetCollaboratorParams = {
  withProjects?: boolean
  withPeople?: boolean
  withLegalRepresentatives?: boolean
  withClusters?: boolean
  withAgencies?: boolean
}

export type PostClusterMediaBody = {
  file?: Blob
  usage?: string
  isDefault?: boolean
  order?: number
  skipUpload?: boolean
  replaceAll?: boolean
  replaceID?: string
}

export type GetClustersExcelExportData200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetClustersExcelExportData200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetClustersExcelExportData200HydraSearchHydraMappingItem[]
}

export type GetClustersExcelExportData200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetClustersExcelExportData200 = {
  "hydra:member": ClusterJsonldReadCollectionClusterExcelExport[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetClustersExcelExportData200HydraView
  "hydra:search"?: GetClustersExcelExportData200HydraSearch
}

export type GetClustersExcelExportDataOrderUpdatedAt =
  (typeof GetClustersExcelExportDataOrderUpdatedAt)[keyof typeof GetClustersExcelExportDataOrderUpdatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetClustersExcelExportDataOrderUpdatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetClustersExcelExportDataOrderCreatedAt =
  (typeof GetClustersExcelExportDataOrderCreatedAt)[keyof typeof GetClustersExcelExportDataOrderCreatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetClustersExcelExportDataOrderCreatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetClustersExcelExportDataOrderName =
  (typeof GetClustersExcelExportDataOrderName)[keyof typeof GetClustersExcelExportDataOrderName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetClustersExcelExportDataOrderName = {
  asc: "asc",
  desc: "desc",
} as const

export type GetClustersExcelExportDataOrderId =
  (typeof GetClustersExcelExportDataOrderId)[keyof typeof GetClustersExcelExportDataOrderId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetClustersExcelExportDataOrderId = {
  asc: "asc",
  desc: "desc",
} as const

export type GetClustersExcelExportDataParams = {
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
  "order[id]"?: GetClustersExcelExportDataOrderId
  "order[name]"?: GetClustersExcelExportDataOrderName
  "order[createdAt]"?: GetClustersExcelExportDataOrderCreatedAt
  "order[updatedAt]"?: GetClustersExcelExportDataOrderUpdatedAt
}

export type GetClusters200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetClusters200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetClusters200HydraSearchHydraMappingItem[]
}

export type GetClusters200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetClusters200 = {
  "hydra:member": ClusterJsonldReadCollectionCluster[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetClusters200HydraView
  "hydra:search"?: GetClusters200HydraSearch
}

export type GetClustersOrderUpdatedAt =
  (typeof GetClustersOrderUpdatedAt)[keyof typeof GetClustersOrderUpdatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetClustersOrderUpdatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetClustersOrderCreatedAt =
  (typeof GetClustersOrderCreatedAt)[keyof typeof GetClustersOrderCreatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetClustersOrderCreatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetClustersOrderId = (typeof GetClustersOrderId)[keyof typeof GetClustersOrderId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetClustersOrderId = {
  asc: "asc",
  desc: "desc",
} as const

export type GetClustersOrderName = (typeof GetClustersOrderName)[keyof typeof GetClustersOrderName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetClustersOrderName = {
  asc: "asc",
  desc: "desc",
} as const

export type GetClustersParams = {
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
  name?: string
  "type.code"?: string
  "type.code[]"?: string[]
  "status.code"?: string
  "status.code[]"?: string[]
  "agencyOwner.agencyOwner.id"?: string
  "agencyOwner.agencyOwner.id[]"?: string[]
  "agencyOwner.agencyOwner.name"?: string
  "agencyOwner.agencyOwner.officialName"?: string
  "agencyOwner.agencyOwner.brand"?: string
  "agencyOwner.agencyOwner.officialCode"?: string
  "agencyOwner.agencyOwner.status.code"?: string
  "collaborator.collaborator.id"?: string
  "collaborator.collaborator.id[]"?: string[]
  "collaborator.collaborator.firstname"?: string
  "collaborator.collaborator.lastname"?: string
  "collaborator.collaborator.status.code"?: string
  "agency.agency.id"?: string
  "agency.agency.id[]"?: string[]
  "agency.agency.name"?: string
  "agency.agency.status.code"?: string
  "project.project.id"?: string
  "project.project.id[]"?: string[]
  "project.project.status.code"?: string
  "project.project.type.code"?: string
  "project.project.propertyOffer.id"?: string
  "project.project.propertyOffer.id[]"?: string[]
  "project.project.propertyOffer.property.id"?: string
  "project.project.propertyOffer.property.id[]"?: string[]
  "project.project.propertyOffer.property.status.code"?: string
  "project.project.propertyOffer.property.type.code"?: string
  "project.project.propertyOffer.property.name"?: string
  "project.project.people.id"?: string
  "project.project.people.id[]"?: string[]
  "people.people.id"?: string
  "people.people.id[]"?: string[]
  search?: string
  "order[name]"?: GetClustersOrderName
  notInternalSource?: string[]
  "order[id]"?: GetClustersOrderId
  "order[createdAt]"?: GetClustersOrderCreatedAt
  "order[updatedAt]"?: GetClustersOrderUpdatedAt
}

export type GetClusterParams = {
  withCollaborators?: boolean
  withProjects?: boolean
  withPeople?: boolean
  withAgencies?: boolean
  withAgencyOwners?: boolean
}

export type GetClientProperties200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetClientProperties200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetClientProperties200HydraSearchHydraMappingItem[]
}

export type GetClientProperties200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetClientProperties200 = {
  "hydra:member": TpropertyJsonldReadCollectionTpropertyWithoutPrivateData[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetClientProperties200HydraView
  "hydra:search"?: GetClientProperties200HydraSearch
}

export type GetClientPropertiesParams = { page?: number; itemsPerPage?: number }

export type GetClientProjects200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetClientProjects200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetClientProjects200HydraSearchHydraMappingItem[]
}

export type GetClientProjects200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetClientProjects200 = {
  "hydra:member": TprojectJsonldReadCollectionProject[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetClientProjects200HydraView
  "hydra:search"?: GetClientProjects200HydraSearch
}

export type ApiClientsClientAccessRequestsGetSubresource200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type ApiClientsClientAccessRequestsGetSubresource200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: ApiClientsClientAccessRequestsGetSubresource200HydraSearchHydraMappingItem[]
}

export type ApiClientsClientAccessRequestsGetSubresource200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type ApiClientsClientAccessRequestsGetSubresource200 = {
  "hydra:member": ClientAccessRequestJsonldReadCollectionClientAccessRequest[]
  "hydra:totalItems"?: number
  "hydra:view"?: ApiClientsClientAccessRequestsGetSubresource200HydraView
  "hydra:search"?: ApiClientsClientAccessRequestsGetSubresource200HydraSearch
}

export type GetClientInterBUs200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetClientInterBUs200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetClientInterBUs200HydraSearchHydraMappingItem[]
}

export type GetClientInterBUs200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetClientInterBUs200 = {
  "hydra:member": InterBuJsonldReadCollectionInterbu[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetClientInterBUs200HydraView
  "hydra:search"?: GetClientInterBUs200HydraSearch
}

export type GetClientInterBUsParams = { page?: number; itemsPerPage?: number }

export type GetClients200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetClients200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetClients200HydraSearchHydraMappingItem[]
}

export type GetClients200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetClients200 = {
  "hydra:member": ClientJsonldReadCollectionClient[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetClients200HydraView
  "hydra:search"?: GetClients200HydraSearch
}

export type GetClientsOrderUpdatedAt =
  (typeof GetClientsOrderUpdatedAt)[keyof typeof GetClientsOrderUpdatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetClientsOrderUpdatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetClientsOrderCreatedAt =
  (typeof GetClientsOrderCreatedAt)[keyof typeof GetClientsOrderCreatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetClientsOrderCreatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetClientsOrderBusinessUnitName =
  (typeof GetClientsOrderBusinessUnitName)[keyof typeof GetClientsOrderBusinessUnitName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetClientsOrderBusinessUnitName = {
  asc: "asc",
  desc: "desc",
} as const

export type GetClientsOrderProfileType =
  (typeof GetClientsOrderProfileType)[keyof typeof GetClientsOrderProfileType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetClientsOrderProfileType = {
  asc: "asc",
  desc: "desc",
} as const

export type GetClientsOrderLastName =
  (typeof GetClientsOrderLastName)[keyof typeof GetClientsOrderLastName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetClientsOrderLastName = {
  asc: "asc",
  desc: "desc",
} as const

export type GetClientsOrderFirstName =
  (typeof GetClientsOrderFirstName)[keyof typeof GetClientsOrderFirstName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetClientsOrderFirstName = {
  asc: "asc",
  desc: "desc",
} as const

export type GetClientsParams = {
  page?: number
  itemsPerPage?: number
  profileType?: string
  "profileType[]"?: string[]
  familySituation?: string
  "familySituation[]"?: string[]
  nationality?: string
  "nationality[]"?: string[]
  businessUnit?: string
  "businessUnit[]"?: string[]
  contactOrigin?: string
  "contactOrigin[]"?: string[]
  "projects.type"?: string
  "projects.type[]"?: string[]
  "projects.status"?: string
  "projects.status[]"?: string[]
  "projects.property.type"?: string
  "projects.property.type[]"?: string[]
  "projects.property.currency"?: string
  "projects.property.currency[]"?: string[]
  "address.country"?: string
  "address.country[]"?: string[]
  "address.city"?: string
  "address.city[]"?: string[]
  "address.postcode"?: string
  "projects.property.address.country"?: string
  "projects.property.address.country[]"?: string[]
  "projects.property.address.city"?: string
  "projects.property.address.city[]"?: string[]
  "projects.property.address.postcode"?: string
  "birthdate[before]"?: string
  "birthdate[strictly_before]"?: string
  "birthdate[after]"?: string
  "birthdate[strictly_after]"?: string
  "projects.property.surfaceAreaInSquareMeters[between]"?: string
  "projects.property.surfaceAreaInSquareMeters[gt]"?: string
  "projects.property.surfaceAreaInSquareMeters[gte]"?: string
  "projects.property.surfaceAreaInSquareMeters[lt]"?: string
  "projects.property.surfaceAreaInSquareMeters[lte]"?: string
  "projects.property.price[between]"?: string
  "projects.property.price[gt]"?: string
  "projects.property.price[gte]"?: string
  "projects.property.price[lt]"?: string
  "projects.property.price[lte]"?: string
  languages?: string
  "languages[]"?: string[]
  hasChild?: boolean
  "order[firstName]"?: GetClientsOrderFirstName
  "order[lastName]"?: GetClientsOrderLastName
  "order[profileType]"?: GetClientsOrderProfileType
  "order[businessUnit.name]"?: GetClientsOrderBusinessUnitName
  "order[createdAt]"?: GetClientsOrderCreatedAt
  "order[updatedAt]"?: GetClientsOrderUpdatedAt
  or?: string
  "projects[gte]"?: string
  "projects[lte]"?: string
  search?: string
  "address.postcode[]"?: string[]
  "projects.property.address.postcode[]"?: string[]
}

export type GetPendingClientAccessRequest200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetPendingClientAccessRequest200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetPendingClientAccessRequest200HydraSearchHydraMappingItem[]
}

export type GetPendingClientAccessRequest200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetPendingClientAccessRequest200 = {
  "hydra:member": ClientAccessRequestJsonldReadCollectionClientAccessRequest[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetPendingClientAccessRequest200HydraView
  "hydra:search"?: GetPendingClientAccessRequest200HydraSearch
}

export type GetAnsweredClientAccessRequest200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetAnsweredClientAccessRequest200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetAnsweredClientAccessRequest200HydraSearchHydraMappingItem[]
}

export type GetAnsweredClientAccessRequest200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetAnsweredClientAccessRequest200 = {
  "hydra:member": ClientAccessRequestJsonldReadCollectionClientAccessRequest[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetAnsweredClientAccessRequest200HydraView
  "hydra:search"?: GetAnsweredClientAccessRequest200HydraSearch
}

export type GetCircles200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetCircles200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetCircles200HydraSearchHydraMappingItem[]
}

export type GetCircles200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetCircles200 = {
  "hydra:member": CircleJsonldReadCollectionCircle[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetCircles200HydraView
  "hydra:search"?: GetCircles200HydraSearch
}

export type GetCirclesParams = { page?: number; itemsPerPage?: number; pagination?: boolean }

export type GetBusinessUnitNames200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetBusinessUnitNames200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetBusinessUnitNames200HydraSearchHydraMappingItem[]
}

export type GetBusinessUnitNames200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetBusinessUnitNames200 = {
  "hydra:member": BusinessUnitJsonldReadCollectionNames[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetBusinessUnitNames200HydraView
  "hydra:search"?: GetBusinessUnitNames200HydraSearch
}

export type GetBusinessUnitNamesParams = { pagination?: boolean }

export type GetAgencyParams = {
  withCollaborators?: boolean
  withClusters?: boolean
  withAgencyOwners?: boolean
}

export type PostAgencyMediaBody = {
  file?: Blob
  usage?: string
  isDefault?: boolean
  order?: number
  skipUpload?: boolean
  replaceAll?: boolean
  replaceID?: string
}

export type GetAgenciesExcelExportData200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetAgenciesExcelExportData200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetAgenciesExcelExportData200HydraSearchHydraMappingItem[]
}

export type GetAgenciesExcelExportData200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetAgenciesExcelExportData200 = {
  "hydra:member": AgencyJsonldReadCollectionAgencyExcelExport[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetAgenciesExcelExportData200HydraView
  "hydra:search"?: GetAgenciesExcelExportData200HydraSearch
}

export type GetAgenciesExcelExportDataOrderUpdatedAt =
  (typeof GetAgenciesExcelExportDataOrderUpdatedAt)[keyof typeof GetAgenciesExcelExportDataOrderUpdatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAgenciesExcelExportDataOrderUpdatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetAgenciesExcelExportDataOrderCreatedAt =
  (typeof GetAgenciesExcelExportDataOrderCreatedAt)[keyof typeof GetAgenciesExcelExportDataOrderCreatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAgenciesExcelExportDataOrderCreatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetAgenciesExcelExportDataOrderOfficialName =
  (typeof GetAgenciesExcelExportDataOrderOfficialName)[keyof typeof GetAgenciesExcelExportDataOrderOfficialName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAgenciesExcelExportDataOrderOfficialName = {
  asc: "asc",
  desc: "desc",
} as const

export type GetAgenciesExcelExportDataOrderName =
  (typeof GetAgenciesExcelExportDataOrderName)[keyof typeof GetAgenciesExcelExportDataOrderName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAgenciesExcelExportDataOrderName = {
  asc: "asc",
  desc: "desc",
} as const

export type GetAgenciesExcelExportDataOrderId =
  (typeof GetAgenciesExcelExportDataOrderId)[keyof typeof GetAgenciesExcelExportDataOrderId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAgenciesExcelExportDataOrderId = {
  asc: "asc",
  desc: "desc",
} as const

export type GetAgenciesExcelExportDataParams = {
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
  "order[id]"?: GetAgenciesExcelExportDataOrderId
  "order[name]"?: GetAgenciesExcelExportDataOrderName
  "order[officialName]"?: GetAgenciesExcelExportDataOrderOfficialName
  "order[createdAt]"?: GetAgenciesExcelExportDataOrderCreatedAt
  "order[updatedAt]"?: GetAgenciesExcelExportDataOrderUpdatedAt
}

export type GetAgencies200HydraSearchHydraMappingItem = {
  "@type"?: string
  variable?: string
  property?: string | null
  required?: boolean
}

export type GetAgencies200HydraSearch = {
  "@type"?: string
  "hydra:template"?: string
  "hydra:variableRepresentation"?: string
  "hydra:mapping"?: GetAgencies200HydraSearchHydraMappingItem[]
}

export type GetAgencies200HydraView = {
  "@id"?: string
  "@type"?: string
  "hydra:first"?: string
  "hydra:last"?: string
  "hydra:previous"?: string
  "hydra:next"?: string
}

export type GetAgencies200 = {
  "hydra:member": AgencyJsonldReadCollectionAgency[]
  "hydra:totalItems"?: number
  "hydra:view"?: GetAgencies200HydraView
  "hydra:search"?: GetAgencies200HydraSearch
}

export type GetAgenciesOrderOfficialName =
  (typeof GetAgenciesOrderOfficialName)[keyof typeof GetAgenciesOrderOfficialName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAgenciesOrderOfficialName = {
  asc: "asc",
  desc: "desc",
} as const

export type GetAgenciesOrderId = (typeof GetAgenciesOrderId)[keyof typeof GetAgenciesOrderId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAgenciesOrderId = {
  asc: "asc",
  desc: "desc",
} as const

export type GetAgenciesOrderUpdatedAt =
  (typeof GetAgenciesOrderUpdatedAt)[keyof typeof GetAgenciesOrderUpdatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAgenciesOrderUpdatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetAgenciesOrderCreatedAt =
  (typeof GetAgenciesOrderCreatedAt)[keyof typeof GetAgenciesOrderCreatedAt]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAgenciesOrderCreatedAt = {
  asc: "asc",
  desc: "desc",
} as const

export type GetAgenciesOrderName = (typeof GetAgenciesOrderName)[keyof typeof GetAgenciesOrderName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAgenciesOrderName = {
  asc: "asc",
  desc: "desc",
} as const

export type GetAgenciesParams = {
  page?: number
  itemsPerPage?: number
  id?: string
  "id[]"?: string[]
  name?: string
  officialName?: string
  "status.code"?: string
  "status.code[]"?: string[]
  internalSource?: string
  "internalSource[]"?: string[]
  "contactDetails.contactDetails.zipcode"?: string
  "contactDetails.contactDetails.country"?: string
  "contactDetails.contactDetails.city"?: string
  "collaborator.collaborator.id"?: string
  "collaborator.collaborator.id[]"?: string[]
  "collaborator.collaborator.firstname"?: string
  "collaborator.collaborator.lastname"?: string
  "collaborator.collaborator.status.code"?: string
  "cluster.cluster.id"?: string
  "cluster.cluster.id[]"?: string[]
  "cluster.cluster.internalSource"?: string
  "cluster.cluster.internalSource[]"?: string[]
  "cluster.cluster.type"?: string
  "cluster.cluster.type[]"?: string[]
  "cluster.cluster.status.code"?: string
  "cluster.cluster.type.code"?: string
  "agencyOwner.agencyOwner.id"?: string
  "agencyOwner.agencyOwner.id[]"?: string[]
  "agencyOwner.agencyOwner.name"?: string
  "agencyOwner.agencyOwner.officialName"?: string
  "agencyOwner.agencyOwner.brand"?: string
  "agencyOwner.agencyOwner.officialCode"?: string
  "agencyOwner.agencyOwner.status.code"?: string
  "agencyOwner.agencyOwner.internalSource"?: string
  "agencyOwner.agencyOwner.internalSource[]"?: string[]
  search?: string
  "order[name]"?: GetAgenciesOrderName
  "order[createdAt]"?: GetAgenciesOrderCreatedAt
  "order[updatedAt]"?: GetAgenciesOrderUpdatedAt
  notInternalSource?: string[]
  "order[id]"?: GetAgenciesOrderId
  "order[officialName]"?: GetAgenciesOrderOfficialName
}

export interface InvalidateRefreshTokenResponse {
  code: number
  message: string
}

export interface Credentials {
  email: string
  password: string
}

export interface Token {
  readonly token: string
}

export type UserJsonldUserReadItemBusinessUnit = BusinessUnitJsonldUserReadItem | null

export type UserJsonldUserReadItemStatus = RefLabelJsonldUserReadItem | null

export type UserJsonldUserReadItemLocale =
  (typeof UserJsonldUserReadItemLocale)[keyof typeof UserJsonldUserReadItemLocale]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldUserReadItemLocale = {
  fr_FR: "fr_FR",
  en_GB: "en_GB",
} as const

export type UserJsonldUserReadItemRolesItem =
  (typeof UserJsonldUserReadItemRolesItem)[keyof typeof UserJsonldUserReadItemRolesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldUserReadItemRolesItem = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_COLLABORATOR: "ROLE_COLLABORATOR",
  ROLE_INTERBU_SUPERVISOR: "ROLE_INTERBU_SUPERVISOR",
  ROLE_BU_ADMIN: "ROLE_BU_ADMIN",
  ROLE_FINANCE: "ROLE_FINANCE",
  ROLE_MARKETING: "ROLE_MARKETING",
  ROLE_USER: "ROLE_USER",
} as const

export interface UserJsonldUserReadItem {
  readonly "@context"?: UserJsonldUserReadItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  email: string
  firstname?: string | null
  lastname?: string | null
  roles: UserJsonldUserReadItemRolesItem[]
  locale: UserJsonldUserReadItemLocale
  status?: UserJsonldUserReadItemStatus
  deactivationDate?: string | null
  deactivationComment?: string | null
  businessUnit?: UserJsonldUserReadItemBusinessUnit
  collaborators?: CollaboratorJsonldUserReadItem[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
  readonly isEmailRandom: boolean
}

export type UserJsonldUserReadItemContextOneOfHydra =
  (typeof UserJsonldUserReadItemContextOneOfHydra)[keyof typeof UserJsonldUserReadItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldUserReadItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type UserJsonldUserReadItemContextOneOf = {
  "@vocab": string
  hydra: UserJsonldUserReadItemContextOneOfHydra
  [key: string]: any
}

export type UserJsonldUserReadItemContext = string | UserJsonldUserReadItemContextOneOf

export type UserJsonldUserReadCollectionBusinessUnit = BusinessUnitJsonldUserReadCollection | null

export type UserJsonldUserReadCollectionStatus = RefLabelJsonldUserReadCollection | null

export type UserJsonldUserReadCollectionLocale =
  (typeof UserJsonldUserReadCollectionLocale)[keyof typeof UserJsonldUserReadCollectionLocale]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldUserReadCollectionLocale = {
  fr_FR: "fr_FR",
  en_GB: "en_GB",
} as const

export type UserJsonldUserReadCollectionRolesItem =
  (typeof UserJsonldUserReadCollectionRolesItem)[keyof typeof UserJsonldUserReadCollectionRolesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldUserReadCollectionRolesItem = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_COLLABORATOR: "ROLE_COLLABORATOR",
  ROLE_INTERBU_SUPERVISOR: "ROLE_INTERBU_SUPERVISOR",
  ROLE_BU_ADMIN: "ROLE_BU_ADMIN",
  ROLE_FINANCE: "ROLE_FINANCE",
  ROLE_MARKETING: "ROLE_MARKETING",
  ROLE_USER: "ROLE_USER",
} as const

export interface UserJsonldUserReadCollection {
  readonly "@id"?: string
  readonly "@type"?: string
  email: string
  firstname?: string | null
  lastname?: string | null
  roles: UserJsonldUserReadCollectionRolesItem[]
  locale: UserJsonldUserReadCollectionLocale
  status?: UserJsonldUserReadCollectionStatus
  deactivationDate?: string | null
  deactivationComment?: string | null
  lastLoginAt?: string | null
  businessUnit?: UserJsonldUserReadCollectionBusinessUnit
  collaborators?: CollaboratorJsonldUserReadCollection[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
  readonly isEmailRandom: boolean
}

export interface UserJsonldReadItemTpropertyWithoutPrivateData {
  readonly "@context"?: UserJsonldReadItemTpropertyWithoutPrivateDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  firstname?: string | null
  lastname?: string | null
  readonly id: string
  createdAt?: string
}

export type UserJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra =
  (typeof UserJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra)[keyof typeof UserJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type UserJsonldReadItemTpropertyWithoutPrivateDataContextOneOf = {
  "@vocab": string
  hydra: UserJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra
  [key: string]: any
}

export type UserJsonldReadItemTpropertyWithoutPrivateDataContext =
  | string
  | UserJsonldReadItemTpropertyWithoutPrivateDataContextOneOf

export type UserJsonldReadItemInterbuContextOneOfHydra =
  (typeof UserJsonldReadItemInterbuContextOneOfHydra)[keyof typeof UserJsonldReadItemInterbuContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldReadItemInterbuContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type UserJsonldReadItemInterbuContextOneOf = {
  "@vocab": string
  hydra: UserJsonldReadItemInterbuContextOneOfHydra
  [key: string]: any
}

export type UserJsonldReadItemInterbuContext = string | UserJsonldReadItemInterbuContextOneOf

export interface UserJsonldReadItemInterbu {
  readonly "@context"?: UserJsonldReadItemInterbuContext
  readonly "@id"?: string
  readonly "@type"?: string
  firstname?: string | null
  lastname?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type UserJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof UserJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof UserJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type UserJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: UserJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type UserJsonldReadItemCollaboratorItemContext =
  | string
  | UserJsonldReadItemCollaboratorItemContextOneOf

export interface UserJsonldReadItemCollaboratorItem {
  readonly "@context"?: UserJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface UserJsonldReadItemClientWithoutPrivateData {
  readonly "@context"?: UserJsonldReadItemClientWithoutPrivateDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  firstname?: string | null
  lastname?: string | null
  readonly id: string
}

export type UserJsonldReadItemClientWithoutPrivateDataContextOneOfHydra =
  (typeof UserJsonldReadItemClientWithoutPrivateDataContextOneOfHydra)[keyof typeof UserJsonldReadItemClientWithoutPrivateDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldReadItemClientWithoutPrivateDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type UserJsonldReadItemClientWithoutPrivateDataContextOneOf = {
  "@vocab": string
  hydra: UserJsonldReadItemClientWithoutPrivateDataContextOneOfHydra
  [key: string]: any
}

export type UserJsonldReadItemClientWithoutPrivateDataContext =
  | string
  | UserJsonldReadItemClientWithoutPrivateDataContextOneOf

export type UserJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOfHydra =
  (typeof UserJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOfHydra)[keyof typeof UserJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type UserJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOf = {
  "@vocab": string
  hydra: UserJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOfHydra
  [key: string]: any
}

export type UserJsonldReadCollectionTpropertyWithoutPrivateDataContext =
  | string
  | UserJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOf

export interface UserJsonldReadCollectionTpropertyWithoutPrivateData {
  readonly "@context"?: UserJsonldReadCollectionTpropertyWithoutPrivateDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  firstname?: string | null
  lastname?: string | null
  readonly id: string
  createdAt?: string
}

export type UserJsonldReadCollectionTpropertyExcelExportContextOneOfHydra =
  (typeof UserJsonldReadCollectionTpropertyExcelExportContextOneOfHydra)[keyof typeof UserJsonldReadCollectionTpropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldReadCollectionTpropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type UserJsonldReadCollectionTpropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: UserJsonldReadCollectionTpropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type UserJsonldReadCollectionTpropertyExcelExportContext =
  | string
  | UserJsonldReadCollectionTpropertyExcelExportContextOneOf

export interface UserJsonldReadCollectionTpropertyExcelExport {
  readonly "@context"?: UserJsonldReadCollectionTpropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  firstname?: string | null
  lastname?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type UserJsonldReadCollectionSharedDataReadItemSharedDataRolesItem =
  (typeof UserJsonldReadCollectionSharedDataReadItemSharedDataRolesItem)[keyof typeof UserJsonldReadCollectionSharedDataReadItemSharedDataRolesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldReadCollectionSharedDataReadItemSharedDataRolesItem = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_COLLABORATOR: "ROLE_COLLABORATOR",
  ROLE_INTERBU_SUPERVISOR: "ROLE_INTERBU_SUPERVISOR",
  ROLE_BU_ADMIN: "ROLE_BU_ADMIN",
  ROLE_FINANCE: "ROLE_FINANCE",
  ROLE_MARKETING: "ROLE_MARKETING",
  ROLE_USER: "ROLE_USER",
} as const

export interface UserJsonldReadCollectionSharedDataReadItemSharedData {
  readonly "@context"?: UserJsonldReadCollectionSharedDataReadItemSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  email: string
  firstname?: string | null
  lastname?: string | null
  roles: UserJsonldReadCollectionSharedDataReadItemSharedDataRolesItem[]
  createdAt?: string
  readonly updatedAt?: string
}

export type UserJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra =
  (typeof UserJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra)[keyof typeof UserJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type UserJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf = {
  "@vocab": string
  hydra: UserJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra
  [key: string]: any
}

export type UserJsonldReadCollectionSharedDataReadItemSharedDataContext =
  | string
  | UserJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf

export type UserJsonldReadCollectionSharedDataRolesItem =
  (typeof UserJsonldReadCollectionSharedDataRolesItem)[keyof typeof UserJsonldReadCollectionSharedDataRolesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldReadCollectionSharedDataRolesItem = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_COLLABORATOR: "ROLE_COLLABORATOR",
  ROLE_INTERBU_SUPERVISOR: "ROLE_INTERBU_SUPERVISOR",
  ROLE_BU_ADMIN: "ROLE_BU_ADMIN",
  ROLE_FINANCE: "ROLE_FINANCE",
  ROLE_MARKETING: "ROLE_MARKETING",
  ROLE_USER: "ROLE_USER",
} as const

export type UserJsonldReadCollectionSharedDataContextOneOfHydra =
  (typeof UserJsonldReadCollectionSharedDataContextOneOfHydra)[keyof typeof UserJsonldReadCollectionSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldReadCollectionSharedDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type UserJsonldReadCollectionSharedDataContextOneOf = {
  "@vocab": string
  hydra: UserJsonldReadCollectionSharedDataContextOneOfHydra
  [key: string]: any
}

export type UserJsonldReadCollectionSharedDataContext =
  | string
  | UserJsonldReadCollectionSharedDataContextOneOf

export interface UserJsonldReadCollectionSharedData {
  readonly "@context"?: UserJsonldReadCollectionSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  email: string
  firstname?: string | null
  lastname?: string | null
  roles: UserJsonldReadCollectionSharedDataRolesItem[]
  createdAt?: string
  readonly updatedAt?: string
}

export type UserJsonldReadCollectionProjectContextOneOfHydra =
  (typeof UserJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof UserJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type UserJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: UserJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type UserJsonldReadCollectionProjectContext =
  | string
  | UserJsonldReadCollectionProjectContextOneOf

export interface UserJsonldReadCollectionProject {
  readonly "@context"?: UserJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  firstname?: string | null
  lastname?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type UserJsonldReadCollectionInterbuContextOneOfHydra =
  (typeof UserJsonldReadCollectionInterbuContextOneOfHydra)[keyof typeof UserJsonldReadCollectionInterbuContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldReadCollectionInterbuContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type UserJsonldReadCollectionInterbuContextOneOf = {
  "@vocab": string
  hydra: UserJsonldReadCollectionInterbuContextOneOfHydra
  [key: string]: any
}

export type UserJsonldReadCollectionInterbuContext =
  | string
  | UserJsonldReadCollectionInterbuContextOneOf

export interface UserJsonldReadCollectionInterbu {
  readonly "@context"?: UserJsonldReadCollectionInterbuContext
  readonly "@id"?: string
  readonly "@type"?: string
  firstname?: string | null
  lastname?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type UserJsonldReadCollectionCollaboratorContextOneOfHydra =
  (typeof UserJsonldReadCollectionCollaboratorContextOneOfHydra)[keyof typeof UserJsonldReadCollectionCollaboratorContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldReadCollectionCollaboratorContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type UserJsonldReadCollectionCollaboratorContextOneOf = {
  "@vocab": string
  hydra: UserJsonldReadCollectionCollaboratorContextOneOfHydra
  [key: string]: any
}

export type UserJsonldReadCollectionCollaboratorContext =
  | string
  | UserJsonldReadCollectionCollaboratorContextOneOf

export interface UserJsonldReadCollectionCollaborator {
  readonly "@context"?: UserJsonldReadCollectionCollaboratorContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type UserJsonldReadCollectionClientAccessRequestContextOneOfHydra =
  (typeof UserJsonldReadCollectionClientAccessRequestContextOneOfHydra)[keyof typeof UserJsonldReadCollectionClientAccessRequestContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldReadCollectionClientAccessRequestContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type UserJsonldReadCollectionClientAccessRequestContextOneOf = {
  "@vocab": string
  hydra: UserJsonldReadCollectionClientAccessRequestContextOneOfHydra
  [key: string]: any
}

export type UserJsonldReadCollectionClientAccessRequestContext =
  | string
  | UserJsonldReadCollectionClientAccessRequestContextOneOf

export interface UserJsonldReadCollectionClientAccessRequest {
  readonly "@context"?: UserJsonldReadCollectionClientAccessRequestContext
  readonly "@id"?: string
  readonly "@type"?: string
  firstname?: string | null
  lastname?: string | null
  readonly id: string
  createdAt?: string
}

export interface UserJsonldReadCollectionClient {
  readonly "@context"?: UserJsonldReadCollectionClientContext
  readonly "@id"?: string
  readonly "@type"?: string
  firstname?: string | null
  lastname?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type UserJsonldReadCollectionClientContextOneOfHydra =
  (typeof UserJsonldReadCollectionClientContextOneOfHydra)[keyof typeof UserJsonldReadCollectionClientContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldReadCollectionClientContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type UserJsonldReadCollectionClientContextOneOf = {
  "@vocab": string
  hydra: UserJsonldReadCollectionClientContextOneOfHydra
  [key: string]: any
}

export type UserJsonldReadCollectionClientContext =
  | string
  | UserJsonldReadCollectionClientContextOneOf

export type UserJsonldLocale = (typeof UserJsonldLocale)[keyof typeof UserJsonldLocale]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldLocale = {
  fr_FR: "fr_FR",
  en_GB: "en_GB",
} as const

export type UserJsonldRolesItem = (typeof UserJsonldRolesItem)[keyof typeof UserJsonldRolesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldRolesItem = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_COLLABORATOR: "ROLE_COLLABORATOR",
  ROLE_INTERBU_SUPERVISOR: "ROLE_INTERBU_SUPERVISOR",
  ROLE_BU_ADMIN: "ROLE_BU_ADMIN",
  ROLE_FINANCE: "ROLE_FINANCE",
  ROLE_MARKETING: "ROLE_MARKETING",
  ROLE_USER: "ROLE_USER",
} as const

export interface UserJsonld {
  readonly "@context"?: UserJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  email: string
  firstname?: string | null
  lastname?: string | null
  password: string
  roles: UserJsonldRolesItem[]
  locale: UserJsonldLocale
  status?: string | null
  deactivationDate?: string | null
  deactivationComment?: string | null
  lastLoginAt?: string | null
  businessUnit?: string | null
  collaborators?: string[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
  readonly isEmailRandom: boolean
  readonly userIdentifier?: string
  readonly defaultCollaborator?: string | null
}

export type UserJsonldContextOneOfHydra =
  (typeof UserJsonldContextOneOfHydra)[keyof typeof UserJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type UserJsonldContextOneOf = {
  "@vocab": string
  hydra: UserJsonldContextOneOfHydra
  [key: string]: any
}

export type UserJsonldContext = string | UserJsonldContextOneOf

export interface UserCreateUserInputJsonld {
  firstname?: string | null
  lastname?: string | null
  email?: string
  roles?: string[]
  locale?: string | null
  collaborator?: CollaboratorUserDTOJsonld[] | null
  status?: string
  deactivationComment?: string | null
}

export interface UserCreateUserInput {
  firstname?: string | null
  lastname?: string | null
  email?: string
  roles?: string[]
  locale?: string | null
  collaborator?: CollaboratorUserDTO[] | null
  status?: string
  deactivationComment?: string | null
}

export type TsubscriptionJsonldReadItemClientWithoutPrivateDataContextOneOfHydra =
  (typeof TsubscriptionJsonldReadItemClientWithoutPrivateDataContextOneOfHydra)[keyof typeof TsubscriptionJsonldReadItemClientWithoutPrivateDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TsubscriptionJsonldReadItemClientWithoutPrivateDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type TsubscriptionJsonldReadItemClientWithoutPrivateDataContextOneOf = {
  "@vocab": string
  hydra: TsubscriptionJsonldReadItemClientWithoutPrivateDataContextOneOfHydra
  [key: string]: any
}

export type TsubscriptionJsonldReadItemClientWithoutPrivateDataContext =
  | string
  | TsubscriptionJsonldReadItemClientWithoutPrivateDataContextOneOf

export interface TsubscriptionJsonldReadItemClientWithoutPrivateData {
  readonly "@context"?: TsubscriptionJsonldReadItemClientWithoutPrivateDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  name?: string
  readonly id: string
}

export interface TsubscriptionJsonldReadCollectionSubscription {
  readonly "@id"?: string
  readonly "@type"?: string
  name?: string
  readonly id: string
}

export interface TsubscriptionJsonld {
  readonly "@context"?: TsubscriptionJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  name?: string
  clients?: string[]
  readonly id: string
}

export type TsubscriptionJsonldContextOneOfHydra =
  (typeof TsubscriptionJsonldContextOneOfHydra)[keyof typeof TsubscriptionJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TsubscriptionJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type TsubscriptionJsonldContextOneOf = {
  "@vocab": string
  hydra: TsubscriptionJsonldContextOneOfHydra
  [key: string]: any
}

export type TsubscriptionJsonldContext = string | TsubscriptionJsonldContextOneOf

export type TsubscriptionTranslationsDTOTranslations = { [key: string]: { [key: string]: string } }

export interface TsubscriptionTranslationsDTO {
  translations: TsubscriptionTranslationsDTOTranslations
}

export interface TsubscriptionPostSubscriptionInputJsonld {
  translations?: MandatoryTranslationsDTOJsonld
}

export type TsubscriptionPatchSubscriptionInputTranslations = OptionalTranslationsDTO | null

export interface TsubscriptionPatchSubscriptionInput {
  translations?: TsubscriptionPatchSubscriptionInputTranslations
}

export type TranslationsInputDTOJsonldContextOneOfHydra =
  (typeof TranslationsInputDTOJsonldContextOneOfHydra)[keyof typeof TranslationsInputDTOJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TranslationsInputDTOJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type TranslationsInputDTOJsonldContextOneOf = {
  "@vocab": string
  hydra: TranslationsInputDTOJsonldContextOneOfHydra
  [key: string]: any
}

export type TranslationsInputDTOJsonldContext = string | TranslationsInputDTOJsonldContextOneOf

export interface TranslationsInputDTOJsonld {
  readonly "@context"?: TranslationsInputDTOJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  locale?: string
  field?: string
  label?: string
}

export interface TranslationsInputDTO {
  locale?: string
  field?: string
  label?: string
}

export interface TpropertyViewTypeJsonldReadCollectionTpropertyViewType {
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
}

export interface TpropertyViewJsonldReadCollectionTpropertyView {
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
}

export interface TpropertySubTypeJsonldReadCollectionTpropertySubType {
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
}

export interface TpropertyStateJsonldReadCollectionTpropertyState {
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
}

export interface TpropertySpecificityJsonldReadCollectionTpropertySpecificity {
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
}

export interface TpropertySituationJsonldReadCollectionTpropertySituation {
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
}

export interface TpropertyMandateTypeJsonldReadCollectionTpropertyMandateType {
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
}

export interface TpropertyFacilityJsonldReadCollectionTpropertyFacility {
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
}

export interface TpropertyAvailabilityJsonldReadCollectionTpropertyAvailability {
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
}

export type TpropertyJsonldReadItemTpropertyWithoutPrivateDataApproximateCoordinates =
  | (
      | (PointJsonldReadItemTpropertyWithoutPrivateData & {
          readonly srid?: number
          readonly x?: number
          readonly y?: number
        })
      | null
    )
  | null

export type TpropertyJsonldReadItemTpropertyWithoutPrivateDataPricePeriodicity =
  (typeof TpropertyJsonldReadItemTpropertyWithoutPrivateDataPricePeriodicity)[keyof typeof TpropertyJsonldReadItemTpropertyWithoutPrivateDataPricePeriodicity]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TpropertyJsonldReadItemTpropertyWithoutPrivateDataPricePeriodicity = {
  PER_NIGHT: "PER_NIGHT",
  PER_WEEK: "PER_WEEK",
  ON_DEMAND: "ON_DEMAND",
} as const

export type TpropertyJsonldReadItemTpropertyWithoutPrivateDataDisplayLandSurfaceUnit =
  (typeof TpropertyJsonldReadItemTpropertyWithoutPrivateDataDisplayLandSurfaceUnit)[keyof typeof TpropertyJsonldReadItemTpropertyWithoutPrivateDataDisplayLandSurfaceUnit]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TpropertyJsonldReadItemTpropertyWithoutPrivateDataDisplayLandSurfaceUnit = {
  "m²": "m²",
  "ft²": "ft²",
  ha: "ha",
} as const

export type TpropertyJsonldReadItemTpropertyWithoutPrivateDataBroadcastedWebsitesItem =
  (typeof TpropertyJsonldReadItemTpropertyWithoutPrivateDataBroadcastedWebsitesItem)[keyof typeof TpropertyJsonldReadItemTpropertyWithoutPrivateDataBroadcastedWebsitesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TpropertyJsonldReadItemTpropertyWithoutPrivateDataBroadcastedWebsitesItem = {
  BARNES_INTERNATIONAL_REALTY_BRUSSELS: "BARNES_INTERNATIONAL_REALTY_BRUSSELS",
  BARNES_INTERNATIONAL_REALTY_SWISS: "BARNES_INTERNATIONAL_REALTY_SWISS",
  BARNES_INTERNATIONAL_REALTY: "BARNES_INTERNATIONAL_REALTY",
} as const

export type TpropertyJsonldReadItemTpropertyWithoutPrivateDataVisibility =
  (typeof TpropertyJsonldReadItemTpropertyWithoutPrivateDataVisibility)[keyof typeof TpropertyJsonldReadItemTpropertyWithoutPrivateDataVisibility]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TpropertyJsonldReadItemTpropertyWithoutPrivateDataVisibility = {
  NOT_CONFIDENTIAL: "NOT_CONFIDENTIAL",
  CONFIDENTIAL: "CONFIDENTIAL",
} as const

export type TpropertyJsonldReadItemTpropertyWithoutPrivateDataDisplaySurfaceUnit =
  (typeof TpropertyJsonldReadItemTpropertyWithoutPrivateDataDisplaySurfaceUnit)[keyof typeof TpropertyJsonldReadItemTpropertyWithoutPrivateDataDisplaySurfaceUnit]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TpropertyJsonldReadItemTpropertyWithoutPrivateDataDisplaySurfaceUnit = {
  "m²": "m²",
  "ft²": "ft²",
  ha: "ha",
} as const

export type TpropertyJsonldReadItemTpropertyWithoutPrivateDataProjectType =
  (typeof TpropertyJsonldReadItemTpropertyWithoutPrivateDataProjectType)[keyof typeof TpropertyJsonldReadItemTpropertyWithoutPrivateDataProjectType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TpropertyJsonldReadItemTpropertyWithoutPrivateDataProjectType = {
  ACQUISITION: "ACQUISITION",
  LOCATION: "LOCATION",
  SEASONAL_LOCATION: "SEASONAL_LOCATION",
  SALE: "SALE",
  RENTAL: "RENTAL",
  SEASONAL_RENTAL: "SEASONAL_RENTAL",
  RENTAL_MANAGEMENT: "RENTAL_MANAGEMENT",
} as const

export type TpropertyJsonldReadItemTpropertyWithoutPrivateDataAddress =
  AddressJsonldReadItemTpropertyWithoutPrivateData | null

export type TpropertyJsonldReadItemTpropertyWithoutPrivateDataType =
  (typeof TpropertyJsonldReadItemTpropertyWithoutPrivateDataType)[keyof typeof TpropertyJsonldReadItemTpropertyWithoutPrivateDataType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TpropertyJsonldReadItemTpropertyWithoutPrivateDataType = {
  HOUSE: "HOUSE",
  VILLA: "VILLA",
  COTTAGE: "COTTAGE",
  FARM: "FARM",
  FLAT: "FLAT",
  LOFT: "LOFT",
  DUPLEX: "DUPLEX",
  LAND: "LAND",
  MANSION: "MANSION",
  BUILDING: "BUILDING",
  OFFICE_OR_BUSINESS: "OFFICE_OR_BUSINESS",
  CASTEL_OR_MANOR: "CASTEL_OR_MANOR",
  WINE_ESTATE: "WINE_ESTATE",
  STUD_FARM_AND_EQUESTRIAN_ESTATE: "STUD_FARM_AND_EQUESTRIAN_ESTATE",
  HUNTING_TERRAIN: "HUNTING_TERRAIN",
  CELLAR_OR_BOX: "CELLAR_OR_BOX",
  PARKING: "PARKING",
  APARTMENT: "APARTMENT",
} as const

export interface TpropertyJsonldReadItemTpropertyWithoutPrivateData {
  readonly "@context"?: TpropertyJsonldReadItemTpropertyWithoutPrivateDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  type: TpropertyJsonldReadItemTpropertyWithoutPrivateDataType
  address?: TpropertyJsonldReadItemTpropertyWithoutPrivateDataAddress
  businessUnit: BusinessUnitJsonldReadItemTpropertyWithoutPrivateData
  user: UserJsonldReadItemTpropertyWithoutPrivateData
  projectType: TpropertyJsonldReadItemTpropertyWithoutPrivateDataProjectType
  numberOfRooms?: number | null
  numberOfBathrooms?: number | null
  hasElevator?: boolean | null
  hasFireplace?: boolean | null
  hasDoubleGlazing?: boolean | null
  dpe?: string | null
  surfaceAreaInSquareMeters?: number | null
  displaySurfaceUnit?: TpropertyJsonldReadItemTpropertyWithoutPrivateDataDisplaySurfaceUnit
  numberOfBedrooms?: number | null
  floor?: number | null
  numberOfParking?: number | null
  hasInternet?: boolean | null
  hasSauna?: boolean | null
  ges?: string | null
  client: ClientJsonldReadItemTpropertyWithoutPrivateData
  pictures?: string[]
  visibility: TpropertyJsonldReadItemTpropertyWithoutPrivateDataVisibility
  broadcastedWebsites: TpropertyJsonldReadItemTpropertyWithoutPrivateDataBroadcastedWebsitesItem[]
  externalReference?: string | null
  landSurfaceAreaInSquareMeters?: number | null
  displayLandSurfaceUnit?: TpropertyJsonldReadItemTpropertyWithoutPrivateDataDisplayLandSurfaceUnit
  project: TprojectJsonldReadItemTpropertyWithoutPrivateData
  pricePeriodicity?: TpropertyJsonldReadItemTpropertyWithoutPrivateDataPricePeriodicity
  readonly id: string
  createdAt?: string
  readonly confidentialDataIncluded?: boolean
  readonly approximateCoordinates?: TpropertyJsonldReadItemTpropertyWithoutPrivateDataApproximateCoordinates
}

export type TpropertyJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra =
  (typeof TpropertyJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra)[keyof typeof TpropertyJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TpropertyJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type TpropertyJsonldReadItemTpropertyWithoutPrivateDataContextOneOf = {
  "@vocab": string
  hydra: TpropertyJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra
  [key: string]: any
}

export type TpropertyJsonldReadItemTpropertyWithoutPrivateDataContext =
  | string
  | TpropertyJsonldReadItemTpropertyWithoutPrivateDataContextOneOf

export type TpropertyJsonldReadCollectionTpropertyWithoutPrivateDataDisplayLandSurfaceUnit =
  (typeof TpropertyJsonldReadCollectionTpropertyWithoutPrivateDataDisplayLandSurfaceUnit)[keyof typeof TpropertyJsonldReadCollectionTpropertyWithoutPrivateDataDisplayLandSurfaceUnit]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TpropertyJsonldReadCollectionTpropertyWithoutPrivateDataDisplayLandSurfaceUnit = {
  "m²": "m²",
  "ft²": "ft²",
  ha: "ha",
} as const

export type TpropertyJsonldReadCollectionTpropertyWithoutPrivateDataDisplaySurfaceUnit =
  (typeof TpropertyJsonldReadCollectionTpropertyWithoutPrivateDataDisplaySurfaceUnit)[keyof typeof TpropertyJsonldReadCollectionTpropertyWithoutPrivateDataDisplaySurfaceUnit]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TpropertyJsonldReadCollectionTpropertyWithoutPrivateDataDisplaySurfaceUnit = {
  "m²": "m²",
  "ft²": "ft²",
  ha: "ha",
} as const

export type TpropertyJsonldReadCollectionTpropertyWithoutPrivateDataAddress =
  AddressJsonldReadCollectionTpropertyWithoutPrivateData | null

export type TpropertyJsonldReadCollectionTpropertyWithoutPrivateDataType =
  (typeof TpropertyJsonldReadCollectionTpropertyWithoutPrivateDataType)[keyof typeof TpropertyJsonldReadCollectionTpropertyWithoutPrivateDataType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TpropertyJsonldReadCollectionTpropertyWithoutPrivateDataType = {
  HOUSE: "HOUSE",
  VILLA: "VILLA",
  COTTAGE: "COTTAGE",
  FARM: "FARM",
  FLAT: "FLAT",
  LOFT: "LOFT",
  DUPLEX: "DUPLEX",
  LAND: "LAND",
  MANSION: "MANSION",
  BUILDING: "BUILDING",
  OFFICE_OR_BUSINESS: "OFFICE_OR_BUSINESS",
  CASTEL_OR_MANOR: "CASTEL_OR_MANOR",
  WINE_ESTATE: "WINE_ESTATE",
  STUD_FARM_AND_EQUESTRIAN_ESTATE: "STUD_FARM_AND_EQUESTRIAN_ESTATE",
  HUNTING_TERRAIN: "HUNTING_TERRAIN",
  CELLAR_OR_BOX: "CELLAR_OR_BOX",
  PARKING: "PARKING",
  APARTMENT: "APARTMENT",
} as const

export interface TpropertyJsonldReadCollectionTpropertyWithoutPrivateData {
  readonly "@id"?: string
  readonly "@type"?: string
  type: TpropertyJsonldReadCollectionTpropertyWithoutPrivateDataType
  address?: TpropertyJsonldReadCollectionTpropertyWithoutPrivateDataAddress
  businessUnit: BusinessUnitJsonldReadCollectionTpropertyWithoutPrivateData
  user: UserJsonldReadCollectionTpropertyWithoutPrivateData
  numberOfRooms?: number | null
  surfaceAreaInSquareMeters?: number | null
  displaySurfaceUnit?: TpropertyJsonldReadCollectionTpropertyWithoutPrivateDataDisplaySurfaceUnit
  pictures?: string[]
  landSurfaceAreaInSquareMeters?: number | null
  displayLandSurfaceUnit?: TpropertyJsonldReadCollectionTpropertyWithoutPrivateDataDisplayLandSurfaceUnit
  readonly id: string
  createdAt?: string
  readonly confidentialDataIncluded?: boolean
}

export type TpropertyJsonldReadCollectionTpropertyExcelExportDisplaySurfaceUnit =
  (typeof TpropertyJsonldReadCollectionTpropertyExcelExportDisplaySurfaceUnit)[keyof typeof TpropertyJsonldReadCollectionTpropertyExcelExportDisplaySurfaceUnit]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TpropertyJsonldReadCollectionTpropertyExcelExportDisplaySurfaceUnit = {
  "m²": "m²",
  "ft²": "ft²",
  ha: "ha",
} as const

export type TpropertyJsonldReadCollectionTpropertyExcelExportCurrency =
  (typeof TpropertyJsonldReadCollectionTpropertyExcelExportCurrency)[keyof typeof TpropertyJsonldReadCollectionTpropertyExcelExportCurrency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TpropertyJsonldReadCollectionTpropertyExcelExportCurrency = {
  EUR: "EUR",
  USD: "USD",
  CAD: "CAD",
  HUF: "HUF",
  MUR: "MUR",
  MAD: "MAD",
  RUB: "RUB",
  CHF: "CHF",
  GBP: "GBP",
} as const

export type TpropertyJsonldReadCollectionTpropertyExcelExportAddress =
  AddressJsonldReadCollectionTpropertyExcelExport | null

export type TpropertyJsonldReadCollectionTpropertyExcelExportType =
  (typeof TpropertyJsonldReadCollectionTpropertyExcelExportType)[keyof typeof TpropertyJsonldReadCollectionTpropertyExcelExportType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TpropertyJsonldReadCollectionTpropertyExcelExportType = {
  HOUSE: "HOUSE",
  VILLA: "VILLA",
  COTTAGE: "COTTAGE",
  FARM: "FARM",
  FLAT: "FLAT",
  LOFT: "LOFT",
  DUPLEX: "DUPLEX",
  LAND: "LAND",
  MANSION: "MANSION",
  BUILDING: "BUILDING",
  OFFICE_OR_BUSINESS: "OFFICE_OR_BUSINESS",
  CASTEL_OR_MANOR: "CASTEL_OR_MANOR",
  WINE_ESTATE: "WINE_ESTATE",
  STUD_FARM_AND_EQUESTRIAN_ESTATE: "STUD_FARM_AND_EQUESTRIAN_ESTATE",
  HUNTING_TERRAIN: "HUNTING_TERRAIN",
  CELLAR_OR_BOX: "CELLAR_OR_BOX",
  PARKING: "PARKING",
  APARTMENT: "APARTMENT",
} as const

export interface TpropertyJsonldReadCollectionTpropertyExcelExport {
  readonly "@id"?: string
  readonly "@type"?: string
  type: TpropertyJsonldReadCollectionTpropertyExcelExportType
  address?: TpropertyJsonldReadCollectionTpropertyExcelExportAddress
  businessUnit: BusinessUnitJsonldReadCollectionTpropertyExcelExport
  user: UserJsonldReadCollectionTpropertyExcelExport
  price?: number | null
  currency?: TpropertyJsonldReadCollectionTpropertyExcelExportCurrency
  surfaceAreaInSquareMeters?: number | null
  displaySurfaceUnit?: TpropertyJsonldReadCollectionTpropertyExcelExportDisplaySurfaceUnit
  numberOfBedrooms?: number | null
  floor?: number | null
  client: ClientJsonldReadCollectionTpropertyExcelExport
  project: TprojectJsonldReadCollectionTpropertyExcelExport
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type TpropertyJsonldReadCollectionProjectAddress = AddressJsonldReadCollectionProject | null

export type TpropertyJsonldReadCollectionProjectType =
  (typeof TpropertyJsonldReadCollectionProjectType)[keyof typeof TpropertyJsonldReadCollectionProjectType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TpropertyJsonldReadCollectionProjectType = {
  HOUSE: "HOUSE",
  VILLA: "VILLA",
  COTTAGE: "COTTAGE",
  FARM: "FARM",
  FLAT: "FLAT",
  LOFT: "LOFT",
  DUPLEX: "DUPLEX",
  LAND: "LAND",
  MANSION: "MANSION",
  BUILDING: "BUILDING",
  OFFICE_OR_BUSINESS: "OFFICE_OR_BUSINESS",
  CASTEL_OR_MANOR: "CASTEL_OR_MANOR",
  WINE_ESTATE: "WINE_ESTATE",
  STUD_FARM_AND_EQUESTRIAN_ESTATE: "STUD_FARM_AND_EQUESTRIAN_ESTATE",
  HUNTING_TERRAIN: "HUNTING_TERRAIN",
  CELLAR_OR_BOX: "CELLAR_OR_BOX",
  PARKING: "PARKING",
  APARTMENT: "APARTMENT",
} as const

export interface TpropertyJsonldReadCollectionProject {
  readonly "@context"?: TpropertyJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  type: TpropertyJsonldReadCollectionProjectType
  address?: TpropertyJsonldReadCollectionProjectAddress
  businessUnit: BusinessUnitJsonldReadCollectionProject
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type TpropertyJsonldReadCollectionProjectContextOneOfHydra =
  (typeof TpropertyJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof TpropertyJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TpropertyJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type TpropertyJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: TpropertyJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type TpropertyJsonldReadCollectionProjectContext =
  | string
  | TpropertyJsonldReadCollectionProjectContextOneOf

export type TpropertyLogsOutputJsonldContextOneOfHydra =
  (typeof TpropertyLogsOutputJsonldContextOneOfHydra)[keyof typeof TpropertyLogsOutputJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TpropertyLogsOutputJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type TpropertyLogsOutputJsonldContextOneOf = {
  "@vocab": string
  hydra: TpropertyLogsOutputJsonldContextOneOfHydra
  [key: string]: any
}

export type TpropertyLogsOutputJsonldContext = string | TpropertyLogsOutputJsonldContextOneOf

export interface TpropertyLogsOutputJsonld {
  readonly "@context"?: TpropertyLogsOutputJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  logs?: LogJsonld[]
}

export type TpropertyWriteItemTpropertyBroadcastedWebsitesItem =
  (typeof TpropertyWriteItemTpropertyBroadcastedWebsitesItem)[keyof typeof TpropertyWriteItemTpropertyBroadcastedWebsitesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TpropertyWriteItemTpropertyBroadcastedWebsitesItem = {
  BARNES_INTERNATIONAL_REALTY_BRUSSELS: "BARNES_INTERNATIONAL_REALTY_BRUSSELS",
  BARNES_INTERNATIONAL_REALTY_SWISS: "BARNES_INTERNATIONAL_REALTY_SWISS",
  BARNES_INTERNATIONAL_REALTY: "BARNES_INTERNATIONAL_REALTY",
} as const

export interface TpropertyWriteItemTproperty {
  broadcastedWebsites: TpropertyWriteItemTpropertyBroadcastedWebsitesItem[]
}

export type TprojectJsonldReadItemTpropertyWithoutPrivateDataStatus =
  (typeof TprojectJsonldReadItemTpropertyWithoutPrivateDataStatus)[keyof typeof TprojectJsonldReadItemTpropertyWithoutPrivateDataStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TprojectJsonldReadItemTpropertyWithoutPrivateDataStatus = {
  FINISHED: "FINISHED",
  IN_PROGRESS: "IN_PROGRESS",
  ABANDONED: "ABANDONED",
} as const

export type TprojectJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra =
  (typeof TprojectJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra)[keyof typeof TprojectJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TprojectJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type TprojectJsonldReadItemTpropertyWithoutPrivateDataContextOneOf = {
  "@vocab": string
  hydra: TprojectJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra
  [key: string]: any
}

export type TprojectJsonldReadItemTpropertyWithoutPrivateDataContext =
  | string
  | TprojectJsonldReadItemTpropertyWithoutPrivateDataContextOneOf

export interface TprojectJsonldReadItemTpropertyWithoutPrivateData {
  readonly "@context"?: TprojectJsonldReadItemTpropertyWithoutPrivateDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  status: TprojectJsonldReadItemTpropertyWithoutPrivateDataStatus
  readonly id: string
  createdAt?: string
}

export type TprojectJsonldReadCollectionTpropertyExcelExportType =
  (typeof TprojectJsonldReadCollectionTpropertyExcelExportType)[keyof typeof TprojectJsonldReadCollectionTpropertyExcelExportType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TprojectJsonldReadCollectionTpropertyExcelExportType = {
  ACQUISITION: "ACQUISITION",
  LOCATION: "LOCATION",
  SEASONAL_LOCATION: "SEASONAL_LOCATION",
  SALE: "SALE",
  RENTAL: "RENTAL",
  SEASONAL_RENTAL: "SEASONAL_RENTAL",
  RENTAL_MANAGEMENT: "RENTAL_MANAGEMENT",
  PRIVATE_AVIATION: "PRIVATE_AVIATION",
  CONSTRUCTION: "CONSTRUCTION",
  ART_ADVISORY: "ART_ADVISORY",
  YACHTING: "YACHTING",
} as const

export type TprojectJsonldReadCollectionTpropertyExcelExportStatus =
  (typeof TprojectJsonldReadCollectionTpropertyExcelExportStatus)[keyof typeof TprojectJsonldReadCollectionTpropertyExcelExportStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TprojectJsonldReadCollectionTpropertyExcelExportStatus = {
  FINISHED: "FINISHED",
  IN_PROGRESS: "IN_PROGRESS",
  ABANDONED: "ABANDONED",
} as const

export type TprojectJsonldReadCollectionTpropertyExcelExportContextOneOfHydra =
  (typeof TprojectJsonldReadCollectionTpropertyExcelExportContextOneOfHydra)[keyof typeof TprojectJsonldReadCollectionTpropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TprojectJsonldReadCollectionTpropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type TprojectJsonldReadCollectionTpropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: TprojectJsonldReadCollectionTpropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type TprojectJsonldReadCollectionTpropertyExcelExportContext =
  | string
  | TprojectJsonldReadCollectionTpropertyExcelExportContextOneOf

export interface TprojectJsonldReadCollectionTpropertyExcelExport {
  readonly "@context"?: TprojectJsonldReadCollectionTpropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  status: TprojectJsonldReadCollectionTpropertyExcelExportStatus
  type: TprojectJsonldReadCollectionTpropertyExcelExportType
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type TprojectJsonldReadCollectionProjectProperty =
  TpropertyJsonldReadCollectionProject | null

export type TprojectJsonldReadCollectionProjectType =
  (typeof TprojectJsonldReadCollectionProjectType)[keyof typeof TprojectJsonldReadCollectionProjectType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TprojectJsonldReadCollectionProjectType = {
  ACQUISITION: "ACQUISITION",
  LOCATION: "LOCATION",
  SEASONAL_LOCATION: "SEASONAL_LOCATION",
  SALE: "SALE",
  RENTAL: "RENTAL",
  SEASONAL_RENTAL: "SEASONAL_RENTAL",
  RENTAL_MANAGEMENT: "RENTAL_MANAGEMENT",
  PRIVATE_AVIATION: "PRIVATE_AVIATION",
  CONSTRUCTION: "CONSTRUCTION",
  ART_ADVISORY: "ART_ADVISORY",
  YACHTING: "YACHTING",
} as const

export type TprojectJsonldReadCollectionProjectStatus =
  (typeof TprojectJsonldReadCollectionProjectStatus)[keyof typeof TprojectJsonldReadCollectionProjectStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TprojectJsonldReadCollectionProjectStatus = {
  FINISHED: "FINISHED",
  IN_PROGRESS: "IN_PROGRESS",
  ABANDONED: "ABANDONED",
} as const

export interface TprojectJsonldReadCollectionProject {
  readonly "@id"?: string
  readonly "@type"?: string
  status: TprojectJsonldReadCollectionProjectStatus
  type: TprojectJsonldReadCollectionProjectType
  user: UserJsonldReadCollectionProject
  property?: TprojectJsonldReadCollectionProjectProperty
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface TimetablesHoursDTOJsonld {
  readonly "@context"?: TimetablesHoursDTOJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  from?: string
  to?: string
}

export type TimetablesHoursDTOJsonldContextOneOfHydra =
  (typeof TimetablesHoursDTOJsonldContextOneOfHydra)[keyof typeof TimetablesHoursDTOJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimetablesHoursDTOJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type TimetablesHoursDTOJsonldContextOneOf = {
  "@vocab": string
  hydra: TimetablesHoursDTOJsonldContextOneOfHydra
  [key: string]: any
}

export type TimetablesHoursDTOJsonldContext = string | TimetablesHoursDTOJsonldContextOneOf

export interface TimetablesHoursDTO {
  from?: string
  to?: string
}

export interface TimetablesDTOJsonld {
  readonly "@context"?: TimetablesDTOJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  monday?: TimetablesHoursDTOJsonld[] | null
  tuesday?: TimetablesHoursDTOJsonld[] | null
  wednesday?: TimetablesHoursDTOJsonld[] | null
  thursday?: TimetablesHoursDTOJsonld[] | null
  friday?: TimetablesHoursDTOJsonld[] | null
  saturday?: TimetablesHoursDTOJsonld[] | null
  sunday?: TimetablesHoursDTOJsonld[] | null
}

export type TimetablesDTOJsonldContextOneOfHydra =
  (typeof TimetablesDTOJsonldContextOneOfHydra)[keyof typeof TimetablesDTOJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimetablesDTOJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type TimetablesDTOJsonldContextOneOf = {
  "@vocab": string
  hydra: TimetablesDTOJsonldContextOneOfHydra
  [key: string]: any
}

export type TimetablesDTOJsonldContext = string | TimetablesDTOJsonldContextOneOf

export interface TimetablesDTO {
  monday?: TimetablesHoursDTO[] | null
  tuesday?: TimetablesHoursDTO[] | null
  wednesday?: TimetablesHoursDTO[] | null
  thursday?: TimetablesHoursDTO[] | null
  friday?: TimetablesHoursDTO[] | null
  saturday?: TimetablesHoursDTO[] | null
  sunday?: TimetablesHoursDTO[] | null
}

export type SubscriptionJsonldReadItemProjectType = RefLabelJsonldReadItemProject | null

export interface SubscriptionJsonldReadItemProject {
  readonly "@context"?: SubscriptionJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  subscribeAt?: string | null
  type?: SubscriptionJsonldReadItemProjectType
  unsubscribeAt?: string | null
  value?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type SubscriptionJsonldReadItemProjectContextOneOfHydra =
  (typeof SubscriptionJsonldReadItemProjectContextOneOfHydra)[keyof typeof SubscriptionJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SubscriptionJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: SubscriptionJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type SubscriptionJsonldReadItemProjectContext =
  | string
  | SubscriptionJsonldReadItemProjectContextOneOf

export type SubscriptionJsonldReadItemPeopleType = RefLabelJsonldReadItemPeople | null

export interface SubscriptionJsonldReadItemPeople {
  readonly "@context"?: SubscriptionJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  subscribeAt?: string | null
  type?: SubscriptionJsonldReadItemPeopleType
  unsubscribeAt?: string | null
  value?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type SubscriptionJsonldReadItemPeopleContextOneOfHydra =
  (typeof SubscriptionJsonldReadItemPeopleContextOneOfHydra)[keyof typeof SubscriptionJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SubscriptionJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: SubscriptionJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type SubscriptionJsonldReadItemPeopleContext =
  | string
  | SubscriptionJsonldReadItemPeopleContextOneOf

export type SubscriptionJsonldReadCollectionProjectExcelExportType =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export interface SubscriptionJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: SubscriptionJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  subscribeAt?: string | null
  type?: SubscriptionJsonldReadCollectionProjectExcelExportType
  unsubscribeAt?: string | null
  value?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type SubscriptionJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof SubscriptionJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof SubscriptionJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SubscriptionJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: SubscriptionJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type SubscriptionJsonldReadCollectionProjectExcelExportContext =
  | string
  | SubscriptionJsonldReadCollectionProjectExcelExportContextOneOf

export type SubscriptionJsonldReadCollectionPeopleExcelExportType =
  RefLabelJsonldReadCollectionPeopleExcelExport | null

export type SubscriptionJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof SubscriptionJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof SubscriptionJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SubscriptionJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: SubscriptionJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type SubscriptionJsonldReadCollectionPeopleExcelExportContext =
  | string
  | SubscriptionJsonldReadCollectionPeopleExcelExportContextOneOf

export interface SubscriptionJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: SubscriptionJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  subscribeAt?: string | null
  type?: SubscriptionJsonldReadCollectionPeopleExcelExportType
  unsubscribeAt?: string | null
  value?: string | null
  readonly id: string
  readonly updatedAt?: string
}

export type SocietyMediaJsonldReadItemSocietyItemUsage = RefLabelJsonldReadItemSocietyItem | null

export type SocietyMediaJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof SocietyMediaJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof SocietyMediaJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocietyMediaJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SocietyMediaJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: SocietyMediaJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type SocietyMediaJsonldReadItemSocietyItemContext =
  | string
  | SocietyMediaJsonldReadItemSocietyItemContextOneOf

export interface SocietyMediaJsonldReadItemSocietyItem {
  readonly "@context"?: SocietyMediaJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  society?: SocietyJsonldReadItemSocietyItem
  media?: MediaJsonldReadItemSocietyItem
  order?: number | null
  isDefault?: boolean | null
  usage?: SocietyMediaJsonldReadItemSocietyItemUsage
  readonly id: string
  /** The media usage code */
  usageCode?: string | null
}

export type SocietyMediaJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof SocietyMediaJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof SocietyMediaJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocietyMediaJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SocietyMediaJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: SocietyMediaJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type SocietyMediaJsonldReadItemCollaboratorItemContext =
  | string
  | SocietyMediaJsonldReadItemCollaboratorItemContextOneOf

export interface SocietyMediaJsonldReadItemCollaboratorItem {
  readonly "@context"?: SocietyMediaJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type SocietyMediaJsonldReadItemClusterItemContextOneOfHydra =
  (typeof SocietyMediaJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof SocietyMediaJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocietyMediaJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SocietyMediaJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: SocietyMediaJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type SocietyMediaJsonldReadItemClusterItemContext =
  | string
  | SocietyMediaJsonldReadItemClusterItemContextOneOf

export interface SocietyMediaJsonldReadItemClusterItem {
  readonly "@context"?: SocietyMediaJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export interface SocietyMediaJsonldReadItemAgencyItem {
  readonly "@context"?: SocietyMediaJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type SocietyMediaJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof SocietyMediaJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof SocietyMediaJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocietyMediaJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SocietyMediaJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: SocietyMediaJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type SocietyMediaJsonldReadItemAgencyItemContext =
  | string
  | SocietyMediaJsonldReadItemAgencyItemContextOneOf

export type SocietyMediaJsonldReadCollectionSocietyExcelExportUsage =
  RefLabelJsonldReadCollectionSocietyExcelExport | null

export interface SocietyMediaJsonldReadCollectionSocietyExcelExport {
  readonly "@context"?: SocietyMediaJsonldReadCollectionSocietyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  society?: SocietyJsonldReadCollectionSocietyExcelExport
  media?: MediaJsonldReadCollectionSocietyExcelExport
  order?: number | null
  isDefault?: boolean | null
  usage?: SocietyMediaJsonldReadCollectionSocietyExcelExportUsage
  readonly id: string
  /** The media usage code */
  usageCode?: string | null
}

export type SocietyMediaJsonldReadCollectionSocietyExcelExportContextOneOfHydra =
  (typeof SocietyMediaJsonldReadCollectionSocietyExcelExportContextOneOfHydra)[keyof typeof SocietyMediaJsonldReadCollectionSocietyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocietyMediaJsonldReadCollectionSocietyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SocietyMediaJsonldReadCollectionSocietyExcelExportContextOneOf = {
  "@vocab": string
  hydra: SocietyMediaJsonldReadCollectionSocietyExcelExportContextOneOfHydra
  [key: string]: any
}

export type SocietyMediaJsonldReadCollectionSocietyExcelExportContext =
  | string
  | SocietyMediaJsonldReadCollectionSocietyExcelExportContextOneOf

export type SocietyMediaJsonldReadCollectionSocietyUsage =
  RefLabelJsonldReadCollectionSociety | null

export interface SocietyMediaJsonldReadCollectionSociety {
  readonly "@context"?: SocietyMediaJsonldReadCollectionSocietyContext
  readonly "@id"?: string
  readonly "@type"?: string
  society?: SocietyJsonldReadCollectionSociety
  media?: MediaJsonldReadCollectionSociety
  order?: number | null
  isDefault?: boolean | null
  usage?: SocietyMediaJsonldReadCollectionSocietyUsage
  readonly id: string
  /** The media usage code */
  usageCode?: string | null
}

export type SocietyMediaJsonldReadCollectionSocietyContextOneOf = {
  "@vocab": string
  hydra: SocietyMediaJsonldReadCollectionSocietyContextOneOfHydra
  [key: string]: any
}

export type SocietyMediaJsonldReadCollectionSocietyContext =
  | string
  | SocietyMediaJsonldReadCollectionSocietyContextOneOf

export type SocietyMediaJsonldReadCollectionSocietyContextOneOfHydra =
  (typeof SocietyMediaJsonldReadCollectionSocietyContextOneOfHydra)[keyof typeof SocietyMediaJsonldReadCollectionSocietyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocietyMediaJsonldReadCollectionSocietyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export interface SocietyMediaJsonld {
  readonly "@context"?: SocietyMediaJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  society?: string
  media?: MediaJsonld
  order?: number | null
  isDefault?: boolean | null
  usage?: string | null
  readonly id: string
  /** The media usage code */
  usageCode?: string | null
}

export type SocietyMediaJsonldContextOneOfHydra =
  (typeof SocietyMediaJsonldContextOneOfHydra)[keyof typeof SocietyMediaJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocietyMediaJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SocietyMediaJsonldContextOneOf = {
  "@vocab": string
  hydra: SocietyMediaJsonldContextOneOfHydra
  [key: string]: any
}

export type SocietyMediaJsonldContext = string | SocietyMediaJsonldContextOneOf

export type SocietyJsonldReadItemSocietyItemLicense = RefLabelJsonldReadItemSocietyItem | null

export type SocietyJsonldReadItemSocietyItemStatus = RefLabelJsonldReadItemSocietyItem | null

export interface SocietyJsonldReadItemSocietyItem {
  readonly "@context"?: SocietyJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  name?: string | null
  officialName?: string | null
  brand?: string | null
  officialCode?: string | null
  vatNumber?: string | null
  status?: SocietyJsonldReadItemSocietyItemStatus
  media?: SocietyMediaJsonldReadItemSocietyItem[]
  agency?: RelAgencySocietyJsonldReadItemSocietyItem[]
  cluster?: RelAgencyOwnerClusterJsonldReadItemSocietyItem[]
  licenseNumber?: string | null
  registrationCode?: string | null
  legalRepresentative?: RelAgencyOwnerLegalRepresentativeJsonldReadItemSocietyItem[]
  legals?: RelAgencyOwnerLegalJsonldReadItemSocietyItem[]
  license?: SocietyJsonldReadItemSocietyItemLicense
  parent?: string | null
  contactDetails?: RelAgencyOwnerContactDetailsJsonldReadItemSocietyItem[]
  companyRegistry?: string | null
  referenceUrl?: string | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  nbAgency?: number
  nbCluster?: number
  nbLegalRepresentative?: number
  nbLegals?: number
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type SocietyJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof SocietyJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof SocietyJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocietyJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SocietyJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: SocietyJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type SocietyJsonldReadItemSocietyItemContext =
  | string
  | SocietyJsonldReadItemSocietyItemContextOneOf

export type SocietyJsonldReadItemCollaboratorItemStatus =
  RefLabelJsonldReadItemCollaboratorItem | null

export interface SocietyJsonldReadItemCollaboratorItem {
  readonly "@context"?: SocietyJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  name?: string | null
  officialName?: string | null
  brand?: string | null
  officialCode?: string | null
  vatNumber?: string | null
  status?: SocietyJsonldReadItemCollaboratorItemStatus
  media?: SocietyMediaJsonldReadItemCollaboratorItem[]
  licenseNumber?: string | null
  registrationCode?: string | null
  contactDetails?: RelAgencyOwnerContactDetailsJsonldReadItemCollaboratorItem[]
  companyRegistry?: string | null
  referenceUrl?: string | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type SocietyJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof SocietyJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof SocietyJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocietyJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SocietyJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: SocietyJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type SocietyJsonldReadItemCollaboratorItemContext =
  | string
  | SocietyJsonldReadItemCollaboratorItemContextOneOf

export type SocietyJsonldReadItemClusterItemLicense = RefLabelJsonldReadItemClusterItem | null

export type SocietyJsonldReadItemClusterItemStatus = RefLabelJsonldReadItemClusterItem | null

export interface SocietyJsonldReadItemClusterItem {
  readonly "@context"?: SocietyJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  name?: string | null
  officialName?: string | null
  brand?: string | null
  officialCode?: string | null
  status?: SocietyJsonldReadItemClusterItemStatus
  media?: SocietyMediaJsonldReadItemClusterItem[]
  licenseNumber?: string | null
  registrationCode?: string | null
  legalRepresentative?: RelAgencyOwnerLegalRepresentativeJsonldReadItemClusterItem[]
  legals?: RelAgencyOwnerLegalJsonldReadItemClusterItem[]
  license?: SocietyJsonldReadItemClusterItemLicense
  parent?: string | null
  contactDetails?: RelAgencyOwnerContactDetailsJsonldReadItemClusterItem[]
  companyRegistry?: string | null
  referenceUrl?: string | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type SocietyJsonldReadItemClusterItemContextOneOfHydra =
  (typeof SocietyJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof SocietyJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocietyJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SocietyJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: SocietyJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type SocietyJsonldReadItemClusterItemContext =
  | string
  | SocietyJsonldReadItemClusterItemContextOneOf

export type SocietyJsonldReadItemAgencyItemLicense = RefLabelJsonldReadItemAgencyItem | null

export type SocietyJsonldReadItemAgencyItemStatus = RefLabelJsonldReadItemAgencyItem | null

export type SocietyJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof SocietyJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof SocietyJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocietyJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SocietyJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: SocietyJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type SocietyJsonldReadItemAgencyItemContext =
  | string
  | SocietyJsonldReadItemAgencyItemContextOneOf

export interface SocietyJsonldReadItemAgencyItem {
  readonly "@context"?: SocietyJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  name?: string | null
  officialName?: string | null
  brand?: string | null
  officialCode?: string | null
  vatNumber?: string | null
  status?: SocietyJsonldReadItemAgencyItemStatus
  media?: SocietyMediaJsonldReadItemAgencyItem[]
  licenseNumber?: string | null
  registrationCode?: string | null
  legalRepresentative?: RelAgencyOwnerLegalRepresentativeJsonldReadItemAgencyItem[]
  legals?: RelAgencyOwnerLegalJsonldReadItemAgencyItem[]
  license?: SocietyJsonldReadItemAgencyItemLicense
  parent?: string | null
  contactDetails?: RelAgencyOwnerContactDetailsJsonldReadItemAgencyItem[]
  companyRegistry?: string | null
  referenceUrl?: string | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type SocietyJsonldReadCollectionSocietyExcelExportLicense =
  RefLabelJsonldReadCollectionSocietyExcelExport | null

export type SocietyJsonldReadCollectionSocietyExcelExportStatus =
  RefLabelJsonldReadCollectionSocietyExcelExport | null

export interface SocietyJsonldReadCollectionSocietyExcelExport {
  readonly "@id"?: string
  readonly "@type"?: string
  readonly "@context"?: SocietyJsonldReadCollectionSocietyExcelExportContext
  name?: string | null
  officialName?: string | null
  brand?: string | null
  officialCode?: string | null
  vatNumber?: string | null
  status?: SocietyJsonldReadCollectionSocietyExcelExportStatus
  media?: SocietyMediaJsonldReadCollectionSocietyExcelExport[]
  agency?: RelAgencySocietyJsonldReadCollectionSocietyExcelExport[]
  cluster?: RelAgencyOwnerClusterJsonldReadCollectionSocietyExcelExport[]
  licenseNumber?: string | null
  registrationCode?: string | null
  legalRepresentative?: RelAgencyOwnerLegalRepresentativeJsonldReadCollectionSocietyExcelExport[]
  legals?: RelAgencyOwnerLegalJsonldReadCollectionSocietyExcelExport[]
  license?: SocietyJsonldReadCollectionSocietyExcelExportLicense
  parent?: string | null
  contactDetails?: RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyExcelExport[]
  companyRegistry?: string | null
  referenceUrl?: string | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type SocietyJsonldReadCollectionSocietyExcelExportContextOneOfHydra =
  (typeof SocietyJsonldReadCollectionSocietyExcelExportContextOneOfHydra)[keyof typeof SocietyJsonldReadCollectionSocietyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocietyJsonldReadCollectionSocietyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SocietyJsonldReadCollectionSocietyExcelExportContextOneOf = {
  "@vocab": string
  hydra: SocietyJsonldReadCollectionSocietyExcelExportContextOneOfHydra
  [key: string]: any
}

export type SocietyJsonldReadCollectionSocietyExcelExportContext =
  | string
  | SocietyJsonldReadCollectionSocietyExcelExportContextOneOf

export type SocietyJsonldReadCollectionSocietyLicense = RefLabelJsonldReadCollectionSociety | null

export type SocietyJsonldReadCollectionSocietyStatus = RefLabelJsonldReadCollectionSociety | null

export interface SocietyJsonldReadCollectionSociety {
  readonly "@id"?: string
  readonly "@type"?: string
  readonly "@context"?: SocietyJsonldReadCollectionSocietyContext
  name?: string | null
  officialName?: string | null
  brand?: string | null
  officialCode?: string | null
  vatNumber?: string | null
  status?: SocietyJsonldReadCollectionSocietyStatus
  media?: SocietyMediaJsonldReadCollectionSociety[]
  licenseNumber?: string | null
  registrationCode?: string | null
  license?: SocietyJsonldReadCollectionSocietyLicense
  parent?: string | null
  contactDetails?: RelAgencyOwnerContactDetailsJsonldReadCollectionSociety[]
  companyRegistry?: string | null
  referenceUrl?: string | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  nbAgency?: number
  nbCluster?: number
  nbLegalRepresentative?: number
  nbLegals?: number
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type SocietyJsonldReadCollectionSocietyContextOneOfHydra =
  (typeof SocietyJsonldReadCollectionSocietyContextOneOfHydra)[keyof typeof SocietyJsonldReadCollectionSocietyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocietyJsonldReadCollectionSocietyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SocietyJsonldReadCollectionSocietyContextOneOf = {
  "@vocab": string
  hydra: SocietyJsonldReadCollectionSocietyContextOneOfHydra
  [key: string]: any
}

export type SocietyJsonldReadCollectionSocietyContext =
  | string
  | SocietyJsonldReadCollectionSocietyContextOneOf

export type SocietyJsonldContextOneOfHydra =
  (typeof SocietyJsonldContextOneOfHydra)[keyof typeof SocietyJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SocietyJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SocietyJsonldContextOneOf = {
  "@vocab": string
  hydra: SocietyJsonldContextOneOfHydra
  [key: string]: any
}

export type SocietyJsonldContext = string | SocietyJsonldContextOneOf

export interface SocietyJsonld {
  readonly "@context"?: SocietyJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  name?: string | null
  officialName?: string | null
  brand?: string | null
  officialCode?: string | null
  vatNumber?: string | null
  status?: string | null
  media?: SocietyMediaJsonld[]
  agency?: RelAgencySocietyJsonld[]
  cluster?: RelAgencyOwnerClusterJsonld[]
  licenseNumber?: string | null
  registrationCode?: string | null
  legalRepresentative?: RelAgencyOwnerLegalRepresentativeJsonld[]
  legals?: RelAgencyOwnerLegalJsonld[]
  license?: string | null
  parent?: string | null
  contactDetails?: RelAgencyOwnerContactDetailsJsonld[]
  companyRegistry?: string | null
  referenceUrl?: string | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  nbAgency?: number
  nbCluster?: number
  nbLegalRepresentative?: number
  nbLegals?: number
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface SocietyPostSocietyInputJsonld {
  name?: string | null
  brand?: string | null
  officialName: string
  officialCode?: string | null
  vatNumber?: string | null
  licenseNumber?: string | null
  registrationCode?: string | null
  license?: string | null
  parent?: string | null
  companyRegistry?: string | null
  referenceUrl?: string | null
  contactDetails?: ContactDetailsDTOJsonld[] | null
  status: string
  agency?: AgencySocietyDTOJsonld[] | null
  cluster?: ClusterSocietyDTOJsonld[] | null
  legalRepresentative?: LegalRepresentativeDTOJsonld[] | null
  legals?: LegalsDTOJsonld[] | null
  accessLevel?: string | null
  accessLevelForced?: string | null
}

export interface SocietyPatchSocietyInput {
  name?: string | null
  brand?: string | null
  officialName?: string | null
  officialCode?: string | null
  vatNumber?: string | null
  licenseNumber?: string | null
  registrationCode?: string | null
  license?: string | null
  parent?: string | null
  companyRegistry?: string | null
  referenceUrl?: string | null
  contactDetails?: ContactDetailsDTO[] | null
  status?: string | null
  agency?: AgencySocietyDTO[] | null
  cluster?: ClusterSocietyDTO[] | null
  legalRepresentative?: LegalRepresentativeDTO[] | null
  legals?: LegalsDTO[] | null
  accessLevel?: string | null
  accessLevelForced?: string | null
}

export interface SocietyPatchEntityMediaInput {
  datas?: EntityMediaDTO[]
}

export type SharedDataJsonldReadCollectionSharedDataReadItemSharedDataProject =
  ProjectJsonldReadCollectionSharedDataReadItemSharedData | null

export type SharedDataJsonldReadCollectionSharedDataReadItemSharedDataPeople =
  PeopleJsonldReadCollectionSharedDataReadItemSharedData | null

export type SharedDataJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra =
  (typeof SharedDataJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra)[keyof typeof SharedDataJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SharedDataJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SharedDataJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf = {
  "@vocab": string
  hydra: SharedDataJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra
  [key: string]: any
}

export type SharedDataJsonldReadCollectionSharedDataReadItemSharedDataContext =
  | string
  | SharedDataJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf

/**
 * SharedData entity for managing shared data between users, collaborators and people.
 */
export interface SharedDataJsonldReadCollectionSharedDataReadItemSharedData {
  readonly "@context"?: SharedDataJsonldReadCollectionSharedDataReadItemSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  from?: UserJsonldReadCollectionSharedDataReadItemSharedData
  to?: CollaboratorJsonldReadCollectionSharedDataReadItemSharedData
  type?: RefLabelJsonldReadCollectionSharedDataReadItemSharedData
  status?: RefLabelJsonldReadCollectionSharedDataReadItemSharedData
  subject?: string
  message?: string | null
  people?: SharedDataJsonldReadCollectionSharedDataReadItemSharedDataPeople
  project?: SharedDataJsonldReadCollectionSharedDataReadItemSharedDataProject
  acceptedAt?: string | null
  deniedAt?: string | null
  revokedAt?: string | null
  createdAt?: string
  readonly updatedAt?: string
}

export type SharedDataJsonldReadCollectionSharedDataProject =
  ProjectJsonldReadCollectionSharedData | null

export type SharedDataJsonldReadCollectionSharedDataPeople =
  PeopleJsonldReadCollectionSharedData | null

/**
 * SharedData entity for managing shared data between users, collaborators and people.
 */
export interface SharedDataJsonldReadCollectionSharedData {
  readonly "@id"?: string
  readonly "@type"?: string
  from?: UserJsonldReadCollectionSharedData
  to?: CollaboratorJsonldReadCollectionSharedData
  type?: RefLabelJsonldReadCollectionSharedData
  status?: RefLabelJsonldReadCollectionSharedData
  subject?: string
  message?: string | null
  people?: SharedDataJsonldReadCollectionSharedDataPeople
  project?: SharedDataJsonldReadCollectionSharedDataProject
  acceptedAt?: string | null
  deniedAt?: string | null
  revokedAt?: string | null
  createdAt?: string
  readonly updatedAt?: string
}

export interface SharedDataJsonldReadCollectionProject {
  readonly "@context"?: SharedDataJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type SharedDataJsonldReadCollectionProjectContextOneOfHydra =
  (typeof SharedDataJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof SharedDataJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SharedDataJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SharedDataJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: SharedDataJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type SharedDataJsonldReadCollectionProjectContext =
  | string
  | SharedDataJsonldReadCollectionProjectContextOneOf

export interface SharedDataJsonldReadCollectionPeople {
  readonly "@context"?: SharedDataJsonldReadCollectionPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type SharedDataJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof SharedDataJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof SharedDataJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SharedDataJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SharedDataJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: SharedDataJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type SharedDataJsonldReadCollectionPeopleContext =
  | string
  | SharedDataJsonldReadCollectionPeopleContextOneOf

export type SharedDataJsonldReadCollectionCollaboratorProject =
  ProjectJsonldReadCollectionCollaborator | null

export type SharedDataJsonldReadCollectionCollaboratorPeople =
  PeopleJsonldReadCollectionCollaborator | null

export interface SharedDataJsonldReadCollectionCollaborator {
  readonly "@context"?: SharedDataJsonldReadCollectionCollaboratorContext
  readonly "@id"?: string
  readonly "@type"?: string
  from?: UserJsonldReadCollectionCollaborator
  to?: CollaboratorJsonldReadCollectionCollaborator
  type?: RefLabelJsonldReadCollectionCollaborator
  status?: RefLabelJsonldReadCollectionCollaborator
  people?: SharedDataJsonldReadCollectionCollaboratorPeople
  project?: SharedDataJsonldReadCollectionCollaboratorProject
  acceptedAt?: string | null
  deniedAt?: string | null
  revokedAt?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type SharedDataJsonldReadCollectionCollaboratorContextOneOfHydra =
  (typeof SharedDataJsonldReadCollectionCollaboratorContextOneOfHydra)[keyof typeof SharedDataJsonldReadCollectionCollaboratorContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SharedDataJsonldReadCollectionCollaboratorContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SharedDataJsonldReadCollectionCollaboratorContextOneOf = {
  "@vocab": string
  hydra: SharedDataJsonldReadCollectionCollaboratorContextOneOfHydra
  [key: string]: any
}

export type SharedDataJsonldReadCollectionCollaboratorContext =
  | string
  | SharedDataJsonldReadCollectionCollaboratorContextOneOf

/**
 * SharedData entity for managing shared data between users, collaborators and people.
 */
export interface SharedDataPostSharedDataInputJsonldWriteItemSharedData {
  user_id?: string | null
  people_id?: string | null
  project_id?: string | null
  subject: string
  message?: string | null
}

/**
 * SharedData entity for managing shared data between users, collaborators and people.
 */
export interface SharedDataPatchSharedDataInputWriteItemSharedData {
  id: string
  status: string
}

export type SettingsJsonldContextOneOfHydra =
  (typeof SettingsJsonldContextOneOfHydra)[keyof typeof SettingsJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SettingsJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type SettingsJsonldContextOneOf = {
  "@vocab": string
  hydra: SettingsJsonldContextOneOfHydra
  [key: string]: any
}

export type SettingsJsonldContext = string | SettingsJsonldContextOneOf

export interface SettingsJsonld {
  readonly "@context"?: SettingsJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locales?: string[]
}

export type ResetPasswordRequestJsonldContextOneOfHydra =
  (typeof ResetPasswordRequestJsonldContextOneOfHydra)[keyof typeof ResetPasswordRequestJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResetPasswordRequestJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ResetPasswordRequestJsonldContextOneOf = {
  "@vocab": string
  hydra: ResetPasswordRequestJsonldContextOneOfHydra
  [key: string]: any
}

export type ResetPasswordRequestJsonldContext = string | ResetPasswordRequestJsonldContextOneOf

export interface ResetPasswordRequestJsonld {
  readonly "@context"?: ResetPasswordRequestJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id?: number
  user?: string
  hashedToken?: string
  readonly requestedAt?: string
  expiresAt?: string
  readonly expired?: boolean
}

export interface ResetPasswordRequestPostResetPasswordRequestDTOJsonld {
  email?: string
}

export interface ResetPasswordRequestPostCheckResetPasswordRequestDTOJsonld {
  token: string
  password: string
}

export interface RelPeopleCollaboratorJsonldReadItemProject {
  readonly "@context"?: RelPeopleCollaboratorJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelPeopleCollaboratorJsonldReadItemProjectContextOneOfHydra =
  (typeof RelPeopleCollaboratorJsonldReadItemProjectContextOneOfHydra)[keyof typeof RelPeopleCollaboratorJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelPeopleCollaboratorJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelPeopleCollaboratorJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: RelPeopleCollaboratorJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type RelPeopleCollaboratorJsonldReadItemProjectContext =
  | string
  | RelPeopleCollaboratorJsonldReadItemProjectContextOneOf

export type RelPeopleCollaboratorJsonldReadItemPeopleRole = RefLabelJsonldReadItemPeople | null

export type RelPeopleCollaboratorJsonldReadItemPeopleCollaborator =
  CollaboratorJsonldReadItemPeople | null

export interface RelPeopleCollaboratorJsonldReadItemPeople {
  readonly "@context"?: RelPeopleCollaboratorJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  collaborator?: RelPeopleCollaboratorJsonldReadItemPeopleCollaborator
  role?: RelPeopleCollaboratorJsonldReadItemPeopleRole
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelPeopleCollaboratorJsonldReadItemPeopleContextOneOfHydra =
  (typeof RelPeopleCollaboratorJsonldReadItemPeopleContextOneOfHydra)[keyof typeof RelPeopleCollaboratorJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelPeopleCollaboratorJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelPeopleCollaboratorJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: RelPeopleCollaboratorJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type RelPeopleCollaboratorJsonldReadItemPeopleContext =
  | string
  | RelPeopleCollaboratorJsonldReadItemPeopleContextOneOf

export type RelPeopleCollaboratorJsonldReadItemCollaboratorItemRole =
  RefLabelJsonldReadItemCollaboratorItem | null

export type RelPeopleCollaboratorJsonldReadItemCollaboratorItemPeople =
  PeopleJsonldReadItemCollaboratorItem | null

export interface RelPeopleCollaboratorJsonldReadItemCollaboratorItem {
  readonly "@context"?: RelPeopleCollaboratorJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  people?: RelPeopleCollaboratorJsonldReadItemCollaboratorItemPeople
  role?: RelPeopleCollaboratorJsonldReadItemCollaboratorItemRole
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelPeopleCollaboratorJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof RelPeopleCollaboratorJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof RelPeopleCollaboratorJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelPeopleCollaboratorJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelPeopleCollaboratorJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: RelPeopleCollaboratorJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type RelPeopleCollaboratorJsonldReadItemCollaboratorItemContext =
  | string
  | RelPeopleCollaboratorJsonldReadItemCollaboratorItemContextOneOf

export interface RelPeopleCollaboratorJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: RelPeopleCollaboratorJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelPeopleCollaboratorJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof RelPeopleCollaboratorJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof RelPeopleCollaboratorJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelPeopleCollaboratorJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelPeopleCollaboratorJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelPeopleCollaboratorJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelPeopleCollaboratorJsonldReadCollectionProjectExcelExportContext =
  | string
  | RelPeopleCollaboratorJsonldReadCollectionProjectExcelExportContextOneOf

export type RelPeopleCollaboratorJsonldReadCollectionPeopleExcelExportRole =
  RefLabelJsonldReadCollectionPeopleExcelExport | null

export type RelPeopleCollaboratorJsonldReadCollectionPeopleExcelExportCollaborator =
  CollaboratorJsonldReadCollectionPeopleExcelExport | null

export interface RelPeopleCollaboratorJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: RelPeopleCollaboratorJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  collaborator?: RelPeopleCollaboratorJsonldReadCollectionPeopleExcelExportCollaborator
  role?: RelPeopleCollaboratorJsonldReadCollectionPeopleExcelExportRole
  isDefault?: boolean | null
  readonly id: string
  readonly updatedAt?: string
}

export type RelPeopleCollaboratorJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof RelPeopleCollaboratorJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof RelPeopleCollaboratorJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelPeopleCollaboratorJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelPeopleCollaboratorJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelPeopleCollaboratorJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelPeopleCollaboratorJsonldReadCollectionPeopleExcelExportContext =
  | string
  | RelPeopleCollaboratorJsonldReadCollectionPeopleExcelExportContextOneOf

export type RelPeopleCollaboratorJsonldReadCollectionPeopleRole =
  RefLabelJsonldReadCollectionPeople | null

export type RelPeopleCollaboratorJsonldReadCollectionPeopleCollaborator =
  CollaboratorJsonldReadCollectionPeople | null

export interface RelPeopleCollaboratorJsonldReadCollectionPeople {
  readonly "@context"?: RelPeopleCollaboratorJsonldReadCollectionPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  collaborator?: RelPeopleCollaboratorJsonldReadCollectionPeopleCollaborator
  role?: RelPeopleCollaboratorJsonldReadCollectionPeopleRole
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelPeopleCollaboratorJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof RelPeopleCollaboratorJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof RelPeopleCollaboratorJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelPeopleCollaboratorJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelPeopleCollaboratorJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: RelPeopleCollaboratorJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type RelPeopleCollaboratorJsonldReadCollectionPeopleContext =
  | string
  | RelPeopleCollaboratorJsonldReadCollectionPeopleContextOneOf

export interface RelPeopleCollaboratorJsonld {
  readonly "@context"?: RelPeopleCollaboratorJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  people?: string | null
  collaborator?: string | null
  role?: string | null
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelPeopleCollaboratorJsonldContextOneOfHydra =
  (typeof RelPeopleCollaboratorJsonldContextOneOfHydra)[keyof typeof RelPeopleCollaboratorJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelPeopleCollaboratorJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelPeopleCollaboratorJsonldContextOneOf = {
  "@vocab": string
  hydra: RelPeopleCollaboratorJsonldContextOneOfHydra
  [key: string]: any
}

export type RelPeopleCollaboratorJsonldContext = string | RelPeopleCollaboratorJsonldContextOneOf

export interface RelCollaboratorContactDetailsJsonldUserReadItem {
  readonly "@context"?: RelCollaboratorContactDetailsJsonldUserReadItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldUserReadItem
  isDefault?: boolean
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorContactDetailsJsonldUserReadItemContextOneOfHydra =
  (typeof RelCollaboratorContactDetailsJsonldUserReadItemContextOneOfHydra)[keyof typeof RelCollaboratorContactDetailsJsonldUserReadItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorContactDetailsJsonldUserReadItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorContactDetailsJsonldUserReadItemContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorContactDetailsJsonldUserReadItemContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorContactDetailsJsonldUserReadItemContext =
  | string
  | RelCollaboratorContactDetailsJsonldUserReadItemContextOneOf

export interface RelCollaboratorContactDetailsJsonldUserReadCollection {
  readonly "@context"?: RelCollaboratorContactDetailsJsonldUserReadCollectionContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldUserReadCollection
  isDefault?: boolean
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorContactDetailsJsonldUserReadCollectionContextOneOfHydra =
  (typeof RelCollaboratorContactDetailsJsonldUserReadCollectionContextOneOfHydra)[keyof typeof RelCollaboratorContactDetailsJsonldUserReadCollectionContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorContactDetailsJsonldUserReadCollectionContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorContactDetailsJsonldUserReadCollectionContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorContactDetailsJsonldUserReadCollectionContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorContactDetailsJsonldUserReadCollectionContext =
  | string
  | RelCollaboratorContactDetailsJsonldUserReadCollectionContextOneOf

export type RelCollaboratorContactDetailsJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof RelCollaboratorContactDetailsJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof RelCollaboratorContactDetailsJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorContactDetailsJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorContactDetailsJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorContactDetailsJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorContactDetailsJsonldReadItemSocietyItemContext =
  | string
  | RelCollaboratorContactDetailsJsonldReadItemSocietyItemContextOneOf

export interface RelCollaboratorContactDetailsJsonldReadItemSocietyItem {
  readonly "@context"?: RelCollaboratorContactDetailsJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorContactDetailsJsonldReadItemProjectContextOneOfHydra =
  (typeof RelCollaboratorContactDetailsJsonldReadItemProjectContextOneOfHydra)[keyof typeof RelCollaboratorContactDetailsJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorContactDetailsJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorContactDetailsJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorContactDetailsJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorContactDetailsJsonldReadItemProjectContext =
  | string
  | RelCollaboratorContactDetailsJsonldReadItemProjectContextOneOf

export interface RelCollaboratorContactDetailsJsonldReadItemProject {
  readonly "@context"?: RelCollaboratorContactDetailsJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorContactDetailsJsonldReadItemPeopleContextOneOfHydra =
  (typeof RelCollaboratorContactDetailsJsonldReadItemPeopleContextOneOfHydra)[keyof typeof RelCollaboratorContactDetailsJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorContactDetailsJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorContactDetailsJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorContactDetailsJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorContactDetailsJsonldReadItemPeopleContext =
  | string
  | RelCollaboratorContactDetailsJsonldReadItemPeopleContextOneOf

export interface RelCollaboratorContactDetailsJsonldReadItemPeople {
  readonly "@context"?: RelCollaboratorContactDetailsJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof RelCollaboratorContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof RelCollaboratorContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorContactDetailsJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorContactDetailsJsonldReadItemCollaboratorItemContext =
  | string
  | RelCollaboratorContactDetailsJsonldReadItemCollaboratorItemContextOneOf

export interface RelCollaboratorContactDetailsJsonldReadItemCollaboratorItem {
  readonly "@context"?: RelCollaboratorContactDetailsJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldReadItemCollaboratorItem
  isDefault?: boolean
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RelCollaboratorContactDetailsJsonldReadItemClusterItem {
  readonly "@context"?: RelCollaboratorContactDetailsJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorContactDetailsJsonldReadItemClusterItemContextOneOfHydra =
  (typeof RelCollaboratorContactDetailsJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof RelCollaboratorContactDetailsJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorContactDetailsJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorContactDetailsJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorContactDetailsJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorContactDetailsJsonldReadItemClusterItemContext =
  | string
  | RelCollaboratorContactDetailsJsonldReadItemClusterItemContextOneOf

export interface RelCollaboratorContactDetailsJsonldReadItemAgencyItem {
  readonly "@context"?: RelCollaboratorContactDetailsJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorContactDetailsJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof RelCollaboratorContactDetailsJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof RelCollaboratorContactDetailsJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorContactDetailsJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorContactDetailsJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorContactDetailsJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorContactDetailsJsonldReadItemAgencyItemContext =
  | string
  | RelCollaboratorContactDetailsJsonldReadItemAgencyItemContextOneOf

export type RelCollaboratorContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra =
  (typeof RelCollaboratorContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra)[keyof typeof RelCollaboratorContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra =
  {
    httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
  } as const

export type RelCollaboratorContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf =
  {
    "@vocab": string
    hydra: RelCollaboratorContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra
    [key: string]: any
  }

export type RelCollaboratorContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContext =
  | string
  | RelCollaboratorContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf

export interface RelCollaboratorContactDetailsJsonldReadCollectionSharedDataReadItemSharedData {
  readonly "@context"?: RelCollaboratorContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldReadCollectionSharedDataReadItemSharedData
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorContactDetailsJsonldReadCollectionSharedDataContextOneOfHydra =
  (typeof RelCollaboratorContactDetailsJsonldReadCollectionSharedDataContextOneOfHydra)[keyof typeof RelCollaboratorContactDetailsJsonldReadCollectionSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorContactDetailsJsonldReadCollectionSharedDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorContactDetailsJsonldReadCollectionSharedDataContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorContactDetailsJsonldReadCollectionSharedDataContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorContactDetailsJsonldReadCollectionSharedDataContext =
  | string
  | RelCollaboratorContactDetailsJsonldReadCollectionSharedDataContextOneOf

export interface RelCollaboratorContactDetailsJsonldReadCollectionSharedData {
  readonly "@context"?: RelCollaboratorContactDetailsJsonldReadCollectionSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldReadCollectionSharedData
  createdAt?: string
  readonly updatedAt?: string
}

export interface RelCollaboratorContactDetailsJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: RelCollaboratorContactDetailsJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof RelCollaboratorContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof RelCollaboratorContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  {
    httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
  } as const

export type RelCollaboratorContactDetailsJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorContactDetailsJsonldReadCollectionProjectExcelExportContext =
  | string
  | RelCollaboratorContactDetailsJsonldReadCollectionProjectExcelExportContextOneOf

export type RelCollaboratorContactDetailsJsonldReadCollectionProjectContextOneOfHydra =
  (typeof RelCollaboratorContactDetailsJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof RelCollaboratorContactDetailsJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorContactDetailsJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorContactDetailsJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorContactDetailsJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorContactDetailsJsonldReadCollectionProjectContext =
  | string
  | RelCollaboratorContactDetailsJsonldReadCollectionProjectContextOneOf

export interface RelCollaboratorContactDetailsJsonldReadCollectionProject {
  readonly "@context"?: RelCollaboratorContactDetailsJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof RelCollaboratorContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof RelCollaboratorContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorContactDetailsJsonldReadCollectionPeopleExcelExportContext =
  | string
  | RelCollaboratorContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOf

export interface RelCollaboratorContactDetailsJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: RelCollaboratorContactDetailsJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  readonly updatedAt?: string
}

export interface RelCollaboratorContactDetailsJsonldReadCollectionPeople {
  readonly "@context"?: RelCollaboratorContactDetailsJsonldReadCollectionPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorContactDetailsJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof RelCollaboratorContactDetailsJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof RelCollaboratorContactDetailsJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorContactDetailsJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorContactDetailsJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorContactDetailsJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorContactDetailsJsonldReadCollectionPeopleContext =
  | string
  | RelCollaboratorContactDetailsJsonldReadCollectionPeopleContextOneOf

export interface RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorExcelExport {
  readonly "@context"?: RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldReadCollectionCollaboratorExcelExport
  isDefault?: boolean
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra =
  (typeof RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra)[keyof typeof RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra =
  {
    httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
  } as const

export type RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorExcelExportContext =
  | string
  | RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorExcelExportContextOneOf

export interface RelCollaboratorContactDetailsJsonldReadCollectionCollaborator {
  readonly "@context"?: RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldReadCollectionCollaborator
  isDefault?: boolean
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorContextOneOfHydra =
  (typeof RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorContextOneOfHydra)[keyof typeof RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorContext =
  | string
  | RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorContextOneOf

export type RelCollaboratorContactDetailsJsonldContextOneOfHydra =
  (typeof RelCollaboratorContactDetailsJsonldContextOneOfHydra)[keyof typeof RelCollaboratorContactDetailsJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorContactDetailsJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorContactDetailsJsonldContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorContactDetailsJsonldContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorContactDetailsJsonldContext =
  | string
  | RelCollaboratorContactDetailsJsonldContextOneOf

export interface RelCollaboratorContactDetailsJsonld {
  readonly "@context"?: RelCollaboratorContactDetailsJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonld
  collaborator?: string
  isDefault?: boolean
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorClusterJsonldUserReadItemCluster = ClusterJsonldUserReadItem | null

export type RelCollaboratorClusterJsonldUserReadItemRank = RefLabelJsonldUserReadItem | null

export type RelCollaboratorClusterJsonldUserReadItemRole = RefLabelJsonldUserReadItem | null

export type RelCollaboratorClusterJsonldUserReadItemContextOneOfHydra =
  (typeof RelCollaboratorClusterJsonldUserReadItemContextOneOfHydra)[keyof typeof RelCollaboratorClusterJsonldUserReadItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorClusterJsonldUserReadItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorClusterJsonldUserReadItemContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorClusterJsonldUserReadItemContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorClusterJsonldUserReadItemContext =
  | string
  | RelCollaboratorClusterJsonldUserReadItemContextOneOf

export interface RelCollaboratorClusterJsonldUserReadItem {
  readonly "@context"?: RelCollaboratorClusterJsonldUserReadItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  role?: RelCollaboratorClusterJsonldUserReadItemRole
  rank?: RelCollaboratorClusterJsonldUserReadItemRank
  function?: string | null
  cluster?: RelCollaboratorClusterJsonldUserReadItemCluster
  isDefault?: boolean | null
  isPublished?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorClusterJsonldUserReadCollectionCluster =
  ClusterJsonldUserReadCollection | null

export type RelCollaboratorClusterJsonldUserReadCollectionRank =
  RefLabelJsonldUserReadCollection | null

export type RelCollaboratorClusterJsonldUserReadCollectionRole =
  RefLabelJsonldUserReadCollection | null

export interface RelCollaboratorClusterJsonldUserReadCollection {
  readonly "@context"?: RelCollaboratorClusterJsonldUserReadCollectionContext
  readonly "@id"?: string
  readonly "@type"?: string
  role?: RelCollaboratorClusterJsonldUserReadCollectionRole
  rank?: RelCollaboratorClusterJsonldUserReadCollectionRank
  function?: string | null
  cluster?: RelCollaboratorClusterJsonldUserReadCollectionCluster
  isDefault?: boolean | null
  isPublished?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorClusterJsonldUserReadCollectionContextOneOfHydra =
  (typeof RelCollaboratorClusterJsonldUserReadCollectionContextOneOfHydra)[keyof typeof RelCollaboratorClusterJsonldUserReadCollectionContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorClusterJsonldUserReadCollectionContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorClusterJsonldUserReadCollectionContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorClusterJsonldUserReadCollectionContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorClusterJsonldUserReadCollectionContext =
  | string
  | RelCollaboratorClusterJsonldUserReadCollectionContextOneOf

export type RelCollaboratorClusterJsonldReadItemCollaboratorItemCluster =
  ClusterJsonldReadItemCollaboratorItem | null

export type RelCollaboratorClusterJsonldReadItemCollaboratorItemRank =
  RefLabelJsonldReadItemCollaboratorItem | null

export type RelCollaboratorClusterJsonldReadItemCollaboratorItemRole =
  RefLabelJsonldReadItemCollaboratorItem | null

export interface RelCollaboratorClusterJsonldReadItemCollaboratorItem {
  readonly "@context"?: RelCollaboratorClusterJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  role?: RelCollaboratorClusterJsonldReadItemCollaboratorItemRole
  rank?: RelCollaboratorClusterJsonldReadItemCollaboratorItemRank
  function?: string | null
  cluster?: RelCollaboratorClusterJsonldReadItemCollaboratorItemCluster
  isDefault?: boolean | null
  isPublished?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorClusterJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof RelCollaboratorClusterJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof RelCollaboratorClusterJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorClusterJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorClusterJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorClusterJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorClusterJsonldReadItemCollaboratorItemContext =
  | string
  | RelCollaboratorClusterJsonldReadItemCollaboratorItemContextOneOf

export type RelCollaboratorClusterJsonldReadItemClusterItemCollaborator =
  CollaboratorJsonldReadItemClusterItem | null

export type RelCollaboratorClusterJsonldReadItemClusterItemRank =
  RefLabelJsonldReadItemClusterItem | null

export type RelCollaboratorClusterJsonldReadItemClusterItemRole =
  RefLabelJsonldReadItemClusterItem | null

export type RelCollaboratorClusterJsonldReadItemClusterItemContextOneOfHydra =
  (typeof RelCollaboratorClusterJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof RelCollaboratorClusterJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorClusterJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorClusterJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorClusterJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorClusterJsonldReadItemClusterItemContext =
  | string
  | RelCollaboratorClusterJsonldReadItemClusterItemContextOneOf

export interface RelCollaboratorClusterJsonldReadItemClusterItem {
  readonly "@context"?: RelCollaboratorClusterJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  role?: RelCollaboratorClusterJsonldReadItemClusterItemRole
  rank?: RelCollaboratorClusterJsonldReadItemClusterItemRank
  function?: string | null
  collaborator?: RelCollaboratorClusterJsonldReadItemClusterItemCollaborator
  isDefault?: boolean | null
  isPublished?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorClusterJsonldReadCollectionCollaboratorCluster =
  ClusterJsonldReadCollectionCollaborator | null

export type RelCollaboratorClusterJsonldReadCollectionCollaboratorRank =
  RefLabelJsonldReadCollectionCollaborator | null

export type RelCollaboratorClusterJsonldReadCollectionCollaboratorRole =
  RefLabelJsonldReadCollectionCollaborator | null

export interface RelCollaboratorClusterJsonldReadCollectionCollaborator {
  readonly "@context"?: RelCollaboratorClusterJsonldReadCollectionCollaboratorContext
  readonly "@id"?: string
  readonly "@type"?: string
  role?: RelCollaboratorClusterJsonldReadCollectionCollaboratorRole
  rank?: RelCollaboratorClusterJsonldReadCollectionCollaboratorRank
  function?: string | null
  cluster?: RelCollaboratorClusterJsonldReadCollectionCollaboratorCluster
  isDefault?: boolean | null
  isPublished?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorClusterJsonldReadCollectionCollaboratorContextOneOfHydra =
  (typeof RelCollaboratorClusterJsonldReadCollectionCollaboratorContextOneOfHydra)[keyof typeof RelCollaboratorClusterJsonldReadCollectionCollaboratorContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorClusterJsonldReadCollectionCollaboratorContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorClusterJsonldReadCollectionCollaboratorContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorClusterJsonldReadCollectionCollaboratorContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorClusterJsonldReadCollectionCollaboratorContext =
  | string
  | RelCollaboratorClusterJsonldReadCollectionCollaboratorContextOneOf

export interface RelCollaboratorClusterJsonldReadCollectionClusterExcelExport {
  readonly "@context"?: RelCollaboratorClusterJsonldReadCollectionClusterExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra =
  (typeof RelCollaboratorClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra)[keyof typeof RelCollaboratorClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorClusterJsonldReadCollectionClusterExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorClusterJsonldReadCollectionClusterExcelExportContext =
  | string
  | RelCollaboratorClusterJsonldReadCollectionClusterExcelExportContextOneOf

export type RelCollaboratorClusterJsonldContextOneOfHydra =
  (typeof RelCollaboratorClusterJsonldContextOneOfHydra)[keyof typeof RelCollaboratorClusterJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorClusterJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorClusterJsonldContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorClusterJsonldContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorClusterJsonldContext = string | RelCollaboratorClusterJsonldContextOneOf

export interface RelCollaboratorClusterJsonld {
  readonly "@context"?: RelCollaboratorClusterJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  role?: string | null
  rank?: string | null
  function?: string | null
  collaborator?: string | null
  cluster?: string | null
  isDefault?: boolean | null
  isPublished?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RelCollaboratorAgencyJsonldUserReadItem {
  readonly "@context"?: RelCollaboratorAgencyJsonldUserReadItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorAgencyJsonldUserReadItemContextOneOfHydra =
  (typeof RelCollaboratorAgencyJsonldUserReadItemContextOneOfHydra)[keyof typeof RelCollaboratorAgencyJsonldUserReadItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorAgencyJsonldUserReadItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorAgencyJsonldUserReadItemContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorAgencyJsonldUserReadItemContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorAgencyJsonldUserReadItemContext =
  | string
  | RelCollaboratorAgencyJsonldUserReadItemContextOneOf

export interface RelCollaboratorAgencyJsonldUserReadCollection {
  readonly "@context"?: RelCollaboratorAgencyJsonldUserReadCollectionContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorAgencyJsonldUserReadCollectionContextOneOfHydra =
  (typeof RelCollaboratorAgencyJsonldUserReadCollectionContextOneOfHydra)[keyof typeof RelCollaboratorAgencyJsonldUserReadCollectionContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorAgencyJsonldUserReadCollectionContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorAgencyJsonldUserReadCollectionContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorAgencyJsonldUserReadCollectionContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorAgencyJsonldUserReadCollectionContext =
  | string
  | RelCollaboratorAgencyJsonldUserReadCollectionContextOneOf

export type RelCollaboratorAgencyJsonldReadItemSocietyItemRole =
  RefLabelJsonldReadItemSocietyItem | null

export interface RelCollaboratorAgencyJsonldReadItemSocietyItem {
  readonly "@context"?: RelCollaboratorAgencyJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  role?: RelCollaboratorAgencyJsonldReadItemSocietyItemRole
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorAgencyJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof RelCollaboratorAgencyJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof RelCollaboratorAgencyJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorAgencyJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorAgencyJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorAgencyJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorAgencyJsonldReadItemSocietyItemContext =
  | string
  | RelCollaboratorAgencyJsonldReadItemSocietyItemContextOneOf

export type RelCollaboratorAgencyJsonldReadItemProjectContextOneOfHydra =
  (typeof RelCollaboratorAgencyJsonldReadItemProjectContextOneOfHydra)[keyof typeof RelCollaboratorAgencyJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorAgencyJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorAgencyJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorAgencyJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorAgencyJsonldReadItemProjectContext =
  | string
  | RelCollaboratorAgencyJsonldReadItemProjectContextOneOf

export interface RelCollaboratorAgencyJsonldReadItemProject {
  readonly "@context"?: RelCollaboratorAgencyJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorAgencyJsonldReadItemPeopleContextOneOfHydra =
  (typeof RelCollaboratorAgencyJsonldReadItemPeopleContextOneOfHydra)[keyof typeof RelCollaboratorAgencyJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorAgencyJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorAgencyJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorAgencyJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorAgencyJsonldReadItemPeopleContext =
  | string
  | RelCollaboratorAgencyJsonldReadItemPeopleContextOneOf

export interface RelCollaboratorAgencyJsonldReadItemPeople {
  readonly "@context"?: RelCollaboratorAgencyJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorAgencyJsonldReadItemCollaboratorItemAgency =
  AgencyJsonldReadItemCollaboratorItem | null

export type RelCollaboratorAgencyJsonldReadItemCollaboratorItemRole =
  RefLabelJsonldReadItemCollaboratorItem | null

export type RelCollaboratorAgencyJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof RelCollaboratorAgencyJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof RelCollaboratorAgencyJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorAgencyJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorAgencyJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorAgencyJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorAgencyJsonldReadItemCollaboratorItemContext =
  | string
  | RelCollaboratorAgencyJsonldReadItemCollaboratorItemContextOneOf

export interface RelCollaboratorAgencyJsonldReadItemCollaboratorItem {
  readonly "@context"?: RelCollaboratorAgencyJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  role?: RelCollaboratorAgencyJsonldReadItemCollaboratorItemRole
  isDefault?: boolean | null
  agency?: RelCollaboratorAgencyJsonldReadItemCollaboratorItemAgency
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorAgencyJsonldReadItemClusterItemRole =
  RefLabelJsonldReadItemClusterItem | null

export type RelCollaboratorAgencyJsonldReadItemClusterItemContextOneOfHydra =
  (typeof RelCollaboratorAgencyJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof RelCollaboratorAgencyJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorAgencyJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorAgencyJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorAgencyJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorAgencyJsonldReadItemClusterItemContext =
  | string
  | RelCollaboratorAgencyJsonldReadItemClusterItemContextOneOf

export interface RelCollaboratorAgencyJsonldReadItemClusterItem {
  readonly "@context"?: RelCollaboratorAgencyJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  role?: RelCollaboratorAgencyJsonldReadItemClusterItemRole
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorAgencyJsonldReadItemAgencyItemCollaborator =
  CollaboratorJsonldReadItemAgencyItem | null

export type RelCollaboratorAgencyJsonldReadItemAgencyItemRole =
  RefLabelJsonldReadItemAgencyItem | null

export interface RelCollaboratorAgencyJsonldReadItemAgencyItem {
  readonly "@context"?: RelCollaboratorAgencyJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  role?: RelCollaboratorAgencyJsonldReadItemAgencyItemRole
  isDefault?: boolean | null
  collaborator?: RelCollaboratorAgencyJsonldReadItemAgencyItemCollaborator
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorAgencyJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof RelCollaboratorAgencyJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof RelCollaboratorAgencyJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorAgencyJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorAgencyJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorAgencyJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorAgencyJsonldReadItemAgencyItemContext =
  | string
  | RelCollaboratorAgencyJsonldReadItemAgencyItemContextOneOf

export type RelCollaboratorAgencyJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof RelCollaboratorAgencyJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof RelCollaboratorAgencyJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorAgencyJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorAgencyJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorAgencyJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorAgencyJsonldReadCollectionProjectExcelExportContext =
  | string
  | RelCollaboratorAgencyJsonldReadCollectionProjectExcelExportContextOneOf

export interface RelCollaboratorAgencyJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: RelCollaboratorAgencyJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RelCollaboratorAgencyJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: RelCollaboratorAgencyJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  readonly updatedAt?: string
}

export type RelCollaboratorAgencyJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof RelCollaboratorAgencyJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof RelCollaboratorAgencyJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorAgencyJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorAgencyJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorAgencyJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorAgencyJsonldReadCollectionPeopleExcelExportContext =
  | string
  | RelCollaboratorAgencyJsonldReadCollectionPeopleExcelExportContextOneOf

export type RelCollaboratorAgencyJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof RelCollaboratorAgencyJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof RelCollaboratorAgencyJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorAgencyJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorAgencyJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorAgencyJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorAgencyJsonldReadCollectionPeopleContext =
  | string
  | RelCollaboratorAgencyJsonldReadCollectionPeopleContextOneOf

export interface RelCollaboratorAgencyJsonldReadCollectionPeople {
  readonly "@context"?: RelCollaboratorAgencyJsonldReadCollectionPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorAgencyJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra =
  (typeof RelCollaboratorAgencyJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra)[keyof typeof RelCollaboratorAgencyJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorAgencyJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorAgencyJsonldReadCollectionCollaboratorExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorAgencyJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelCollaboratorAgencyJsonldReadCollectionCollaboratorExcelExportContext =
  | string
  | RelCollaboratorAgencyJsonldReadCollectionCollaboratorExcelExportContextOneOf

export interface RelCollaboratorAgencyJsonldReadCollectionCollaboratorExcelExport {
  readonly "@context"?: RelCollaboratorAgencyJsonldReadCollectionCollaboratorExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorAgencyJsonldContext = string | RelCollaboratorAgencyJsonldContextOneOf

export interface RelCollaboratorAgencyJsonld {
  readonly "@context"?: RelCollaboratorAgencyJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  role?: string | null
  isDefault?: boolean | null
  agency?: string | null
  collaborator?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelCollaboratorAgencyJsonldContextOneOfHydra =
  (typeof RelCollaboratorAgencyJsonldContextOneOfHydra)[keyof typeof RelCollaboratorAgencyJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelCollaboratorAgencyJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelCollaboratorAgencyJsonldContextOneOf = {
  "@vocab": string
  hydra: RelCollaboratorAgencyJsonldContextOneOfHydra
  [key: string]: any
}

export type RelClusterPeopleJsonldReadItemPeopleCluster = ClusterJsonldReadItemPeople | null

export type RelClusterPeopleJsonldReadItemPeopleContextOneOfHydra =
  (typeof RelClusterPeopleJsonldReadItemPeopleContextOneOfHydra)[keyof typeof RelClusterPeopleJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterPeopleJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterPeopleJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: RelClusterPeopleJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type RelClusterPeopleJsonldReadItemPeopleContext =
  | string
  | RelClusterPeopleJsonldReadItemPeopleContextOneOf

export interface RelClusterPeopleJsonldReadItemPeople {
  readonly "@context"?: RelClusterPeopleJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  cluster?: RelClusterPeopleJsonldReadItemPeopleCluster
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterPeopleJsonldReadItemClusterItemPeople = PeopleJsonldReadItemClusterItem | null

export type RelClusterPeopleJsonldReadItemClusterItemContextOneOfHydra =
  (typeof RelClusterPeopleJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof RelClusterPeopleJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterPeopleJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterPeopleJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: RelClusterPeopleJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type RelClusterPeopleJsonldReadItemClusterItemContext =
  | string
  | RelClusterPeopleJsonldReadItemClusterItemContextOneOf

export interface RelClusterPeopleJsonldReadItemClusterItem {
  readonly "@context"?: RelClusterPeopleJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  people?: RelClusterPeopleJsonldReadItemClusterItemPeople
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterPeopleJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof RelClusterPeopleJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof RelClusterPeopleJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterPeopleJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterPeopleJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelClusterPeopleJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelClusterPeopleJsonldReadCollectionPeopleExcelExportContext =
  | string
  | RelClusterPeopleJsonldReadCollectionPeopleExcelExportContextOneOf

export interface RelClusterPeopleJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: RelClusterPeopleJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  readonly updatedAt?: string
}

export type RelClusterPeopleJsonldReadCollectionPeopleCluster =
  ClusterJsonldReadCollectionPeople | null

export interface RelClusterPeopleJsonldReadCollectionPeople {
  readonly "@context"?: RelClusterPeopleJsonldReadCollectionPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  cluster?: RelClusterPeopleJsonldReadCollectionPeopleCluster
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterPeopleJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof RelClusterPeopleJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof RelClusterPeopleJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterPeopleJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterPeopleJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: RelClusterPeopleJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type RelClusterPeopleJsonldReadCollectionPeopleContext =
  | string
  | RelClusterPeopleJsonldReadCollectionPeopleContextOneOf

export type RelClusterPeopleJsonldReadCollectionClusterExcelExportContextOneOfHydra =
  (typeof RelClusterPeopleJsonldReadCollectionClusterExcelExportContextOneOfHydra)[keyof typeof RelClusterPeopleJsonldReadCollectionClusterExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterPeopleJsonldReadCollectionClusterExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterPeopleJsonldReadCollectionClusterExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelClusterPeopleJsonldReadCollectionClusterExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelClusterPeopleJsonldReadCollectionClusterExcelExportContext =
  | string
  | RelClusterPeopleJsonldReadCollectionClusterExcelExportContextOneOf

export interface RelClusterPeopleJsonldReadCollectionClusterExcelExport {
  readonly "@context"?: RelClusterPeopleJsonldReadCollectionClusterExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RelClusterPeopleJsonld {
  readonly "@context"?: RelClusterPeopleJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  cluster?: string | null
  people?: string | null
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterPeopleJsonldContextOneOfHydra =
  (typeof RelClusterPeopleJsonldContextOneOfHydra)[keyof typeof RelClusterPeopleJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterPeopleJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterPeopleJsonldContextOneOf = {
  "@vocab": string
  hydra: RelClusterPeopleJsonldContextOneOfHydra
  [key: string]: any
}

export type RelClusterPeopleJsonldContext = string | RelClusterPeopleJsonldContextOneOf

export type RelClusterI18nTextJsonldReadItemClusterItemI18nText =
  I18nTextJsonldReadItemClusterItem | null

export type RelClusterI18nTextJsonldReadItemClusterItemContextOneOfHydra =
  (typeof RelClusterI18nTextJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof RelClusterI18nTextJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterI18nTextJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterI18nTextJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: RelClusterI18nTextJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type RelClusterI18nTextJsonldReadItemClusterItemContext =
  | string
  | RelClusterI18nTextJsonldReadItemClusterItemContextOneOf

export interface RelClusterI18nTextJsonldReadItemClusterItem {
  readonly "@context"?: RelClusterI18nTextJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  i18nText?: RelClusterI18nTextJsonldReadItemClusterItemI18nText
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterI18nTextJsonldReadCollectionClusterExcelExportContextOneOfHydra =
  (typeof RelClusterI18nTextJsonldReadCollectionClusterExcelExportContextOneOfHydra)[keyof typeof RelClusterI18nTextJsonldReadCollectionClusterExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterI18nTextJsonldReadCollectionClusterExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterI18nTextJsonldReadCollectionClusterExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelClusterI18nTextJsonldReadCollectionClusterExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelClusterI18nTextJsonldReadCollectionClusterExcelExportContext =
  | string
  | RelClusterI18nTextJsonldReadCollectionClusterExcelExportContextOneOf

export interface RelClusterI18nTextJsonldReadCollectionClusterExcelExport {
  readonly "@context"?: RelClusterI18nTextJsonldReadCollectionClusterExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterI18nTextJsonldI18nText = I18nTextJsonld | null

export interface RelClusterI18nTextJsonld {
  readonly "@context"?: RelClusterI18nTextJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  cluster?: string | null
  i18nText?: RelClusterI18nTextJsonldI18nText
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterI18nTextJsonldContextOneOfHydra =
  (typeof RelClusterI18nTextJsonldContextOneOfHydra)[keyof typeof RelClusterI18nTextJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterI18nTextJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterI18nTextJsonldContextOneOf = {
  "@vocab": string
  hydra: RelClusterI18nTextJsonldContextOneOfHydra
  [key: string]: any
}

export type RelClusterI18nTextJsonldContext = string | RelClusterI18nTextJsonldContextOneOf

export interface RelClusterExternalPlatformJsonldUserReadItem {
  readonly "@context"?: RelClusterExternalPlatformJsonldUserReadItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterExternalPlatformJsonldUserReadItemContextOneOfHydra =
  (typeof RelClusterExternalPlatformJsonldUserReadItemContextOneOfHydra)[keyof typeof RelClusterExternalPlatformJsonldUserReadItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterExternalPlatformJsonldUserReadItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterExternalPlatformJsonldUserReadItemContextOneOf = {
  "@vocab": string
  hydra: RelClusterExternalPlatformJsonldUserReadItemContextOneOfHydra
  [key: string]: any
}

export type RelClusterExternalPlatformJsonldUserReadItemContext =
  | string
  | RelClusterExternalPlatformJsonldUserReadItemContextOneOf

export type RelClusterExternalPlatformJsonldUserReadCollectionContextOneOfHydra =
  (typeof RelClusterExternalPlatformJsonldUserReadCollectionContextOneOfHydra)[keyof typeof RelClusterExternalPlatformJsonldUserReadCollectionContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterExternalPlatformJsonldUserReadCollectionContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterExternalPlatformJsonldUserReadCollectionContextOneOf = {
  "@vocab": string
  hydra: RelClusterExternalPlatformJsonldUserReadCollectionContextOneOfHydra
  [key: string]: any
}

export type RelClusterExternalPlatformJsonldUserReadCollectionContext =
  | string
  | RelClusterExternalPlatformJsonldUserReadCollectionContextOneOf

export interface RelClusterExternalPlatformJsonldUserReadCollection {
  readonly "@context"?: RelClusterExternalPlatformJsonldUserReadCollectionContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterExternalPlatformJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof RelClusterExternalPlatformJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof RelClusterExternalPlatformJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterExternalPlatformJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterExternalPlatformJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: RelClusterExternalPlatformJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type RelClusterExternalPlatformJsonldReadItemSocietyItemContext =
  | string
  | RelClusterExternalPlatformJsonldReadItemSocietyItemContextOneOf

export interface RelClusterExternalPlatformJsonldReadItemSocietyItem {
  readonly "@context"?: RelClusterExternalPlatformJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterExternalPlatformJsonldReadItemProjectContext =
  | string
  | RelClusterExternalPlatformJsonldReadItemProjectContextOneOf

export interface RelClusterExternalPlatformJsonldReadItemProject {
  readonly "@context"?: RelClusterExternalPlatformJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterExternalPlatformJsonldReadItemProjectContextOneOfHydra =
  (typeof RelClusterExternalPlatformJsonldReadItemProjectContextOneOfHydra)[keyof typeof RelClusterExternalPlatformJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterExternalPlatformJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterExternalPlatformJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: RelClusterExternalPlatformJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export interface RelClusterExternalPlatformJsonldReadItemCollaboratorItem {
  readonly "@context"?: RelClusterExternalPlatformJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterExternalPlatformJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof RelClusterExternalPlatformJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof RelClusterExternalPlatformJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterExternalPlatformJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterExternalPlatformJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: RelClusterExternalPlatformJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type RelClusterExternalPlatformJsonldReadItemCollaboratorItemContext =
  | string
  | RelClusterExternalPlatformJsonldReadItemCollaboratorItemContextOneOf

export type RelClusterExternalPlatformJsonldReadItemClusterItemExternalPlatform =
  ExternalPlatformJsonldReadItemClusterItem | null

export type RelClusterExternalPlatformJsonldReadItemClusterItemContextOneOfHydra =
  (typeof RelClusterExternalPlatformJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof RelClusterExternalPlatformJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterExternalPlatformJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterExternalPlatformJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: RelClusterExternalPlatformJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type RelClusterExternalPlatformJsonldReadItemClusterItemContext =
  | string
  | RelClusterExternalPlatformJsonldReadItemClusterItemContextOneOf

export interface RelClusterExternalPlatformJsonldReadItemClusterItem {
  readonly "@context"?: RelClusterExternalPlatformJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  externalPlatform?: RelClusterExternalPlatformJsonldReadItemClusterItemExternalPlatform
  extId?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterExternalPlatformJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof RelClusterExternalPlatformJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof RelClusterExternalPlatformJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterExternalPlatformJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterExternalPlatformJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: RelClusterExternalPlatformJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type RelClusterExternalPlatformJsonldReadItemAgencyItemContext =
  | string
  | RelClusterExternalPlatformJsonldReadItemAgencyItemContextOneOf

export interface RelClusterExternalPlatformJsonldReadItemAgencyItem {
  readonly "@context"?: RelClusterExternalPlatformJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterExternalPlatformJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof RelClusterExternalPlatformJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof RelClusterExternalPlatformJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterExternalPlatformJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterExternalPlatformJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelClusterExternalPlatformJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelClusterExternalPlatformJsonldReadCollectionProjectExcelExportContext =
  | string
  | RelClusterExternalPlatformJsonldReadCollectionProjectExcelExportContextOneOf

export interface RelClusterExternalPlatformJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: RelClusterExternalPlatformJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterExternalPlatformJsonldReadCollectionProjectContextOneOfHydra =
  (typeof RelClusterExternalPlatformJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof RelClusterExternalPlatformJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterExternalPlatformJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterExternalPlatformJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: RelClusterExternalPlatformJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type RelClusterExternalPlatformJsonldReadCollectionProjectContext =
  | string
  | RelClusterExternalPlatformJsonldReadCollectionProjectContextOneOf

export interface RelClusterExternalPlatformJsonldReadCollectionProject {
  readonly "@context"?: RelClusterExternalPlatformJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterExternalPlatformJsonldReadCollectionCollaboratorContextOneOfHydra =
  (typeof RelClusterExternalPlatformJsonldReadCollectionCollaboratorContextOneOfHydra)[keyof typeof RelClusterExternalPlatformJsonldReadCollectionCollaboratorContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterExternalPlatformJsonldReadCollectionCollaboratorContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterExternalPlatformJsonldReadCollectionCollaboratorContextOneOf = {
  "@vocab": string
  hydra: RelClusterExternalPlatformJsonldReadCollectionCollaboratorContextOneOfHydra
  [key: string]: any
}

export type RelClusterExternalPlatformJsonldReadCollectionCollaboratorContext =
  | string
  | RelClusterExternalPlatformJsonldReadCollectionCollaboratorContextOneOf

export interface RelClusterExternalPlatformJsonldReadCollectionCollaborator {
  readonly "@context"?: RelClusterExternalPlatformJsonldReadCollectionCollaboratorContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterExternalPlatformJsonldReadCollectionClusterExcelExportExternalPlatform =
  ExternalPlatformJsonldReadCollectionClusterExcelExport | null

export type RelClusterExternalPlatformJsonldReadCollectionClusterExcelExportContextOneOfHydra =
  (typeof RelClusterExternalPlatformJsonldReadCollectionClusterExcelExportContextOneOfHydra)[keyof typeof RelClusterExternalPlatformJsonldReadCollectionClusterExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterExternalPlatformJsonldReadCollectionClusterExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterExternalPlatformJsonldReadCollectionClusterExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelClusterExternalPlatformJsonldReadCollectionClusterExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelClusterExternalPlatformJsonldReadCollectionClusterExcelExportContext =
  | string
  | RelClusterExternalPlatformJsonldReadCollectionClusterExcelExportContextOneOf

export interface RelClusterExternalPlatformJsonldReadCollectionClusterExcelExport {
  readonly "@context"?: RelClusterExternalPlatformJsonldReadCollectionClusterExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  externalPlatform?: RelClusterExternalPlatformJsonldReadCollectionClusterExcelExportExternalPlatform
  extId?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterExternalPlatformJsonldReadCollectionClusterExternalPlatform =
  ExternalPlatformJsonldReadCollectionCluster | null

export type RelClusterExternalPlatformJsonldReadCollectionClusterContextOneOfHydra =
  (typeof RelClusterExternalPlatformJsonldReadCollectionClusterContextOneOfHydra)[keyof typeof RelClusterExternalPlatformJsonldReadCollectionClusterContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterExternalPlatformJsonldReadCollectionClusterContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterExternalPlatformJsonldReadCollectionClusterContextOneOf = {
  "@vocab": string
  hydra: RelClusterExternalPlatformJsonldReadCollectionClusterContextOneOfHydra
  [key: string]: any
}

export type RelClusterExternalPlatformJsonldReadCollectionClusterContext =
  | string
  | RelClusterExternalPlatformJsonldReadCollectionClusterContextOneOf

export interface RelClusterExternalPlatformJsonldReadCollectionCluster {
  readonly "@context"?: RelClusterExternalPlatformJsonldReadCollectionClusterContext
  readonly "@id"?: string
  readonly "@type"?: string
  externalPlatform?: RelClusterExternalPlatformJsonldReadCollectionClusterExternalPlatform
  extId?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterExternalPlatformJsonldExternalPlatform = ExternalPlatformJsonld | null

export interface RelClusterExternalPlatformJsonld {
  readonly "@context"?: RelClusterExternalPlatformJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  cluster?: string | null
  externalPlatform?: RelClusterExternalPlatformJsonldExternalPlatform
  extId?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterExternalPlatformJsonldContextOneOfHydra =
  (typeof RelClusterExternalPlatformJsonldContextOneOfHydra)[keyof typeof RelClusterExternalPlatformJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterExternalPlatformJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterExternalPlatformJsonldContextOneOf = {
  "@vocab": string
  hydra: RelClusterExternalPlatformJsonldContextOneOfHydra
  [key: string]: any
}

export type RelClusterExternalPlatformJsonldContext =
  | string
  | RelClusterExternalPlatformJsonldContextOneOf

export interface RelClusterContactDetailsJsonldReadItemSocietyItem {
  readonly "@context"?: RelClusterContactDetailsJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterContactDetailsJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof RelClusterContactDetailsJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof RelClusterContactDetailsJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterContactDetailsJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterContactDetailsJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: RelClusterContactDetailsJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type RelClusterContactDetailsJsonldReadItemSocietyItemContext =
  | string
  | RelClusterContactDetailsJsonldReadItemSocietyItemContextOneOf

export type RelClusterContactDetailsJsonldReadItemProjectContextOneOfHydra =
  (typeof RelClusterContactDetailsJsonldReadItemProjectContextOneOfHydra)[keyof typeof RelClusterContactDetailsJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterContactDetailsJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterContactDetailsJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: RelClusterContactDetailsJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type RelClusterContactDetailsJsonldReadItemProjectContext =
  | string
  | RelClusterContactDetailsJsonldReadItemProjectContextOneOf

export interface RelClusterContactDetailsJsonldReadItemProject {
  readonly "@context"?: RelClusterContactDetailsJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RelClusterContactDetailsJsonldReadItemCollaboratorItem {
  readonly "@context"?: RelClusterContactDetailsJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof RelClusterContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof RelClusterContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterContactDetailsJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: RelClusterContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type RelClusterContactDetailsJsonldReadItemCollaboratorItemContext =
  | string
  | RelClusterContactDetailsJsonldReadItemCollaboratorItemContextOneOf

export type RelClusterContactDetailsJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: RelClusterContactDetailsJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type RelClusterContactDetailsJsonldReadItemClusterItemContext =
  | string
  | RelClusterContactDetailsJsonldReadItemClusterItemContextOneOf

export interface RelClusterContactDetailsJsonldReadItemClusterItem {
  readonly "@context"?: RelClusterContactDetailsJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldReadItemClusterItem
  isDefault?: boolean
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterContactDetailsJsonldReadItemClusterItemContextOneOfHydra =
  (typeof RelClusterContactDetailsJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof RelClusterContactDetailsJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterContactDetailsJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export interface RelClusterContactDetailsJsonldReadItemAgencyItem {
  readonly "@context"?: RelClusterContactDetailsJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterContactDetailsJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof RelClusterContactDetailsJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof RelClusterContactDetailsJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterContactDetailsJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterContactDetailsJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: RelClusterContactDetailsJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type RelClusterContactDetailsJsonldReadItemAgencyItemContext =
  | string
  | RelClusterContactDetailsJsonldReadItemAgencyItemContextOneOf

export interface RelClusterContactDetailsJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: RelClusterContactDetailsJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof RelClusterContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof RelClusterContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterContactDetailsJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelClusterContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelClusterContactDetailsJsonldReadCollectionProjectExcelExportContext =
  | string
  | RelClusterContactDetailsJsonldReadCollectionProjectExcelExportContextOneOf

export type RelClusterContactDetailsJsonldReadCollectionProjectContextOneOfHydra =
  (typeof RelClusterContactDetailsJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof RelClusterContactDetailsJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterContactDetailsJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterContactDetailsJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: RelClusterContactDetailsJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type RelClusterContactDetailsJsonldReadCollectionProjectContext =
  | string
  | RelClusterContactDetailsJsonldReadCollectionProjectContextOneOf

export interface RelClusterContactDetailsJsonldReadCollectionProject {
  readonly "@context"?: RelClusterContactDetailsJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterContactDetailsJsonldReadCollectionClusterExcelExportContextOneOfHydra =
  (typeof RelClusterContactDetailsJsonldReadCollectionClusterExcelExportContextOneOfHydra)[keyof typeof RelClusterContactDetailsJsonldReadCollectionClusterExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterContactDetailsJsonldReadCollectionClusterExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterContactDetailsJsonldReadCollectionClusterExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelClusterContactDetailsJsonldReadCollectionClusterExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelClusterContactDetailsJsonldReadCollectionClusterExcelExportContext =
  | string
  | RelClusterContactDetailsJsonldReadCollectionClusterExcelExportContextOneOf

export interface RelClusterContactDetailsJsonldReadCollectionClusterExcelExport {
  readonly "@context"?: RelClusterContactDetailsJsonldReadCollectionClusterExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldReadCollectionClusterExcelExport
  isDefault?: boolean
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterContactDetailsJsonldReadCollectionClusterContextOneOfHydra =
  (typeof RelClusterContactDetailsJsonldReadCollectionClusterContextOneOfHydra)[keyof typeof RelClusterContactDetailsJsonldReadCollectionClusterContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterContactDetailsJsonldReadCollectionClusterContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterContactDetailsJsonldReadCollectionClusterContextOneOf = {
  "@vocab": string
  hydra: RelClusterContactDetailsJsonldReadCollectionClusterContextOneOfHydra
  [key: string]: any
}

export type RelClusterContactDetailsJsonldReadCollectionClusterContext =
  | string
  | RelClusterContactDetailsJsonldReadCollectionClusterContextOneOf

export interface RelClusterContactDetailsJsonldReadCollectionCluster {
  readonly "@context"?: RelClusterContactDetailsJsonldReadCollectionClusterContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldReadCollectionCluster
  isDefault?: boolean
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelClusterContactDetailsJsonldContextOneOfHydra =
  (typeof RelClusterContactDetailsJsonldContextOneOfHydra)[keyof typeof RelClusterContactDetailsJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelClusterContactDetailsJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelClusterContactDetailsJsonldContextOneOf = {
  "@vocab": string
  hydra: RelClusterContactDetailsJsonldContextOneOfHydra
  [key: string]: any
}

export type RelClusterContactDetailsJsonldContext =
  | string
  | RelClusterContactDetailsJsonldContextOneOf

export interface RelClusterContactDetailsJsonld {
  readonly "@context"?: RelClusterContactDetailsJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonld
  cluster?: string
  isDefault?: boolean
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencySocietyJsonldReadItemSocietyItemAgency = AgencyJsonldReadItemSocietyItem | null

export type RelAgencySocietyJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof RelAgencySocietyJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof RelAgencySocietyJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencySocietyJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencySocietyJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencySocietyJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencySocietyJsonldReadItemSocietyItemContext =
  | string
  | RelAgencySocietyJsonldReadItemSocietyItemContextOneOf

export interface RelAgencySocietyJsonldReadItemSocietyItem {
  readonly "@context"?: RelAgencySocietyJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  agency?: RelAgencySocietyJsonldReadItemSocietyItemAgency
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RelAgencySocietyJsonldReadItemCollaboratorItem {
  readonly "@context"?: RelAgencySocietyJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencySocietyJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof RelAgencySocietyJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof RelAgencySocietyJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencySocietyJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencySocietyJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencySocietyJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencySocietyJsonldReadItemCollaboratorItemContext =
  | string
  | RelAgencySocietyJsonldReadItemCollaboratorItemContextOneOf

export type RelAgencySocietyJsonldReadItemAgencyItemAgencyOwner =
  SocietyJsonldReadItemAgencyItem | null

export interface RelAgencySocietyJsonldReadItemAgencyItem {
  readonly "@context"?: RelAgencySocietyJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  agencyOwner?: RelAgencySocietyJsonldReadItemAgencyItemAgencyOwner
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencySocietyJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof RelAgencySocietyJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof RelAgencySocietyJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencySocietyJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencySocietyJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencySocietyJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencySocietyJsonldReadItemAgencyItemContext =
  | string
  | RelAgencySocietyJsonldReadItemAgencyItemContextOneOf

export type RelAgencySocietyJsonldReadCollectionSocietyExcelExportContextOneOfHydra =
  (typeof RelAgencySocietyJsonldReadCollectionSocietyExcelExportContextOneOfHydra)[keyof typeof RelAgencySocietyJsonldReadCollectionSocietyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencySocietyJsonldReadCollectionSocietyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencySocietyJsonldReadCollectionSocietyExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelAgencySocietyJsonldReadCollectionSocietyExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelAgencySocietyJsonldReadCollectionSocietyExcelExportContext =
  | string
  | RelAgencySocietyJsonldReadCollectionSocietyExcelExportContextOneOf

export interface RelAgencySocietyJsonldReadCollectionSocietyExcelExport {
  readonly "@context"?: RelAgencySocietyJsonldReadCollectionSocietyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RelAgencySocietyJsonld {
  readonly "@context"?: RelAgencySocietyJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  agency?: string | null
  agencyOwner?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencySocietyJsonldContextOneOfHydra =
  (typeof RelAgencySocietyJsonldContextOneOfHydra)[keyof typeof RelAgencySocietyJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencySocietyJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencySocietyJsonldContextOneOf = {
  "@vocab": string
  hydra: RelAgencySocietyJsonldContextOneOfHydra
  [key: string]: any
}

export type RelAgencySocietyJsonldContext = string | RelAgencySocietyJsonldContextOneOf

export type RelAgencyOwnerLegalRepresentativeJsonldReadItemSocietyItemCollaborator =
  CollaboratorJsonldReadItemSocietyItem | null

export interface RelAgencyOwnerLegalRepresentativeJsonldReadItemSocietyItem {
  readonly "@context"?: RelAgencyOwnerLegalRepresentativeJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  collaborator?: RelAgencyOwnerLegalRepresentativeJsonldReadItemSocietyItemCollaborator
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerLegalRepresentativeJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof RelAgencyOwnerLegalRepresentativeJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof RelAgencyOwnerLegalRepresentativeJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerLegalRepresentativeJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerLegalRepresentativeJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerLegalRepresentativeJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerLegalRepresentativeJsonldReadItemSocietyItemContext =
  | string
  | RelAgencyOwnerLegalRepresentativeJsonldReadItemSocietyItemContextOneOf

export type RelAgencyOwnerLegalRepresentativeJsonldReadItemCollaboratorItemAgencyOwner =
  SocietyJsonldReadItemCollaboratorItem | null

export interface RelAgencyOwnerLegalRepresentativeJsonldReadItemCollaboratorItem {
  readonly "@context"?: RelAgencyOwnerLegalRepresentativeJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  agencyOwner?: RelAgencyOwnerLegalRepresentativeJsonldReadItemCollaboratorItemAgencyOwner
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerLegalRepresentativeJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof RelAgencyOwnerLegalRepresentativeJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof RelAgencyOwnerLegalRepresentativeJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerLegalRepresentativeJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerLegalRepresentativeJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerLegalRepresentativeJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerLegalRepresentativeJsonldReadItemCollaboratorItemContext =
  | string
  | RelAgencyOwnerLegalRepresentativeJsonldReadItemCollaboratorItemContextOneOf

export interface RelAgencyOwnerLegalRepresentativeJsonldReadItemClusterItem {
  readonly "@context"?: RelAgencyOwnerLegalRepresentativeJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerLegalRepresentativeJsonldReadItemClusterItemContextOneOfHydra =
  (typeof RelAgencyOwnerLegalRepresentativeJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof RelAgencyOwnerLegalRepresentativeJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerLegalRepresentativeJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerLegalRepresentativeJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerLegalRepresentativeJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerLegalRepresentativeJsonldReadItemClusterItemContext =
  | string
  | RelAgencyOwnerLegalRepresentativeJsonldReadItemClusterItemContextOneOf

export interface RelAgencyOwnerLegalRepresentativeJsonldReadItemAgencyItem {
  readonly "@context"?: RelAgencyOwnerLegalRepresentativeJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerLegalRepresentativeJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof RelAgencyOwnerLegalRepresentativeJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof RelAgencyOwnerLegalRepresentativeJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerLegalRepresentativeJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerLegalRepresentativeJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerLegalRepresentativeJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerLegalRepresentativeJsonldReadItemAgencyItemContext =
  | string
  | RelAgencyOwnerLegalRepresentativeJsonldReadItemAgencyItemContextOneOf

export interface RelAgencyOwnerLegalRepresentativeJsonldReadCollectionSocietyExcelExport {
  readonly "@context"?: RelAgencyOwnerLegalRepresentativeJsonldReadCollectionSocietyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerLegalRepresentativeJsonldReadCollectionSocietyExcelExportContextOneOfHydra =
  (typeof RelAgencyOwnerLegalRepresentativeJsonldReadCollectionSocietyExcelExportContextOneOfHydra)[keyof typeof RelAgencyOwnerLegalRepresentativeJsonldReadCollectionSocietyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerLegalRepresentativeJsonldReadCollectionSocietyExcelExportContextOneOfHydra =
  {
    httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
  } as const

export type RelAgencyOwnerLegalRepresentativeJsonldReadCollectionSocietyExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerLegalRepresentativeJsonldReadCollectionSocietyExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerLegalRepresentativeJsonldReadCollectionSocietyExcelExportContext =
  | string
  | RelAgencyOwnerLegalRepresentativeJsonldReadCollectionSocietyExcelExportContextOneOf

export type RelAgencyOwnerLegalRepresentativeJsonldContextOneOfHydra =
  (typeof RelAgencyOwnerLegalRepresentativeJsonldContextOneOfHydra)[keyof typeof RelAgencyOwnerLegalRepresentativeJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerLegalRepresentativeJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerLegalRepresentativeJsonldContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerLegalRepresentativeJsonldContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerLegalRepresentativeJsonldContext =
  | string
  | RelAgencyOwnerLegalRepresentativeJsonldContextOneOf

export interface RelAgencyOwnerLegalRepresentativeJsonld {
  readonly "@context"?: RelAgencyOwnerLegalRepresentativeJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  agencyOwner?: string | null
  collaborator?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerLegalJsonldReadItemSocietyItemAgencyOwnerLegals =
  AgencyOwnerLegalsJsonldReadItemSocietyItem | null

export interface RelAgencyOwnerLegalJsonldReadItemSocietyItem {
  readonly "@context"?: RelAgencyOwnerLegalJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  agencyOwnerLegals?: RelAgencyOwnerLegalJsonldReadItemSocietyItemAgencyOwnerLegals
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerLegalJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof RelAgencyOwnerLegalJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof RelAgencyOwnerLegalJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerLegalJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerLegalJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerLegalJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerLegalJsonldReadItemSocietyItemContext =
  | string
  | RelAgencyOwnerLegalJsonldReadItemSocietyItemContextOneOf

export type RelAgencyOwnerLegalJsonldReadItemClusterItemContextOneOfHydra =
  (typeof RelAgencyOwnerLegalJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof RelAgencyOwnerLegalJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerLegalJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerLegalJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerLegalJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerLegalJsonldReadItemClusterItemContext =
  | string
  | RelAgencyOwnerLegalJsonldReadItemClusterItemContextOneOf

export interface RelAgencyOwnerLegalJsonldReadItemClusterItem {
  readonly "@context"?: RelAgencyOwnerLegalJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RelAgencyOwnerLegalJsonldReadItemAgencyItem {
  readonly "@context"?: RelAgencyOwnerLegalJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerLegalJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof RelAgencyOwnerLegalJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof RelAgencyOwnerLegalJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerLegalJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerLegalJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerLegalJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerLegalJsonldReadItemAgencyItemContext =
  | string
  | RelAgencyOwnerLegalJsonldReadItemAgencyItemContextOneOf

export interface RelAgencyOwnerLegalJsonldReadCollectionSocietyExcelExport {
  readonly "@context"?: RelAgencyOwnerLegalJsonldReadCollectionSocietyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerLegalJsonldReadCollectionSocietyExcelExportContextOneOfHydra =
  (typeof RelAgencyOwnerLegalJsonldReadCollectionSocietyExcelExportContextOneOfHydra)[keyof typeof RelAgencyOwnerLegalJsonldReadCollectionSocietyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerLegalJsonldReadCollectionSocietyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerLegalJsonldReadCollectionSocietyExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerLegalJsonldReadCollectionSocietyExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerLegalJsonldReadCollectionSocietyExcelExportContext =
  | string
  | RelAgencyOwnerLegalJsonldReadCollectionSocietyExcelExportContextOneOf

export type RelAgencyOwnerLegalJsonldAgencyOwnerLegals = AgencyOwnerLegalsJsonld | null

export interface RelAgencyOwnerLegalJsonld {
  readonly "@context"?: RelAgencyOwnerLegalJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  agencyOwner?: string | null
  agencyOwnerLegals?: RelAgencyOwnerLegalJsonldAgencyOwnerLegals
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerLegalJsonldContextOneOfHydra =
  (typeof RelAgencyOwnerLegalJsonldContextOneOfHydra)[keyof typeof RelAgencyOwnerLegalJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerLegalJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerLegalJsonldContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerLegalJsonldContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerLegalJsonldContext = string | RelAgencyOwnerLegalJsonldContextOneOf

export type RelAgencyOwnerContactDetailsJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof RelAgencyOwnerContactDetailsJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof RelAgencyOwnerContactDetailsJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerContactDetailsJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerContactDetailsJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerContactDetailsJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerContactDetailsJsonldReadItemSocietyItemContext =
  | string
  | RelAgencyOwnerContactDetailsJsonldReadItemSocietyItemContextOneOf

export interface RelAgencyOwnerContactDetailsJsonldReadItemSocietyItem {
  readonly "@context"?: RelAgencyOwnerContactDetailsJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldReadItemSocietyItem
  isDefault?: boolean
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RelAgencyOwnerContactDetailsJsonldReadItemCollaboratorItem {
  readonly "@context"?: RelAgencyOwnerContactDetailsJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof RelAgencyOwnerContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof RelAgencyOwnerContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerContactDetailsJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerContactDetailsJsonldReadItemCollaboratorItemContext =
  | string
  | RelAgencyOwnerContactDetailsJsonldReadItemCollaboratorItemContextOneOf

export type RelAgencyOwnerContactDetailsJsonldReadItemClusterItemContextOneOfHydra =
  (typeof RelAgencyOwnerContactDetailsJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof RelAgencyOwnerContactDetailsJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerContactDetailsJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerContactDetailsJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerContactDetailsJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerContactDetailsJsonldReadItemClusterItemContext =
  | string
  | RelAgencyOwnerContactDetailsJsonldReadItemClusterItemContextOneOf

export interface RelAgencyOwnerContactDetailsJsonldReadItemClusterItem {
  readonly "@context"?: RelAgencyOwnerContactDetailsJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RelAgencyOwnerContactDetailsJsonldReadItemAgencyItem {
  readonly "@context"?: RelAgencyOwnerContactDetailsJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerContactDetailsJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof RelAgencyOwnerContactDetailsJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof RelAgencyOwnerContactDetailsJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerContactDetailsJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerContactDetailsJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerContactDetailsJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerContactDetailsJsonldReadItemAgencyItemContext =
  | string
  | RelAgencyOwnerContactDetailsJsonldReadItemAgencyItemContextOneOf

export type RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyExcelExportContext =
  | string
  | RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyExcelExportContextOneOf

export interface RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyExcelExport {
  readonly "@context"?: RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldReadCollectionSocietyExcelExport
  isDefault?: boolean
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyExcelExportContextOneOfHydra =
  (typeof RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyExcelExportContextOneOfHydra)[keyof typeof RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export interface RelAgencyOwnerContactDetailsJsonldReadCollectionSociety {
  readonly "@context"?: RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldReadCollectionSociety
  isDefault?: boolean
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyContextOneOfHydra =
  (typeof RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyContextOneOfHydra)[keyof typeof RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyContext =
  | string
  | RelAgencyOwnerContactDetailsJsonldReadCollectionSocietyContextOneOf

export interface RelAgencyOwnerContactDetailsJsonld {
  readonly "@context"?: RelAgencyOwnerContactDetailsJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonld
  society?: string
  isDefault?: boolean
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerContactDetailsJsonldContextOneOfHydra =
  (typeof RelAgencyOwnerContactDetailsJsonldContextOneOfHydra)[keyof typeof RelAgencyOwnerContactDetailsJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerContactDetailsJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerContactDetailsJsonldContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerContactDetailsJsonldContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerContactDetailsJsonldContext =
  | string
  | RelAgencyOwnerContactDetailsJsonldContextOneOf

export type RelAgencyOwnerClusterJsonldReadItemSocietyItemCluster =
  ClusterJsonldReadItemSocietyItem | null

export interface RelAgencyOwnerClusterJsonldReadItemSocietyItem {
  readonly "@context"?: RelAgencyOwnerClusterJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  cluster?: RelAgencyOwnerClusterJsonldReadItemSocietyItemCluster
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerClusterJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof RelAgencyOwnerClusterJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof RelAgencyOwnerClusterJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerClusterJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerClusterJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerClusterJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerClusterJsonldReadItemSocietyItemContext =
  | string
  | RelAgencyOwnerClusterJsonldReadItemSocietyItemContextOneOf

export type RelAgencyOwnerClusterJsonldReadItemClusterItemAgencyOwner =
  SocietyJsonldReadItemClusterItem | null

export interface RelAgencyOwnerClusterJsonldReadItemClusterItem {
  readonly "@context"?: RelAgencyOwnerClusterJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  agencyOwner?: RelAgencyOwnerClusterJsonldReadItemClusterItemAgencyOwner
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerClusterJsonldReadItemClusterItemContextOneOfHydra =
  (typeof RelAgencyOwnerClusterJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof RelAgencyOwnerClusterJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerClusterJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerClusterJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerClusterJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerClusterJsonldReadItemClusterItemContext =
  | string
  | RelAgencyOwnerClusterJsonldReadItemClusterItemContextOneOf

export type RelAgencyOwnerClusterJsonldReadCollectionSocietyExcelExportContextOneOfHydra =
  (typeof RelAgencyOwnerClusterJsonldReadCollectionSocietyExcelExportContextOneOfHydra)[keyof typeof RelAgencyOwnerClusterJsonldReadCollectionSocietyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerClusterJsonldReadCollectionSocietyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerClusterJsonldReadCollectionSocietyExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerClusterJsonldReadCollectionSocietyExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerClusterJsonldReadCollectionSocietyExcelExportContext =
  | string
  | RelAgencyOwnerClusterJsonldReadCollectionSocietyExcelExportContextOneOf

export interface RelAgencyOwnerClusterJsonldReadCollectionSocietyExcelExport {
  readonly "@context"?: RelAgencyOwnerClusterJsonldReadCollectionSocietyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra =
  (typeof RelAgencyOwnerClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra)[keyof typeof RelAgencyOwnerClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerClusterJsonldReadCollectionClusterExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerClusterJsonldReadCollectionClusterExcelExportContext =
  | string
  | RelAgencyOwnerClusterJsonldReadCollectionClusterExcelExportContextOneOf

export interface RelAgencyOwnerClusterJsonldReadCollectionClusterExcelExport {
  readonly "@context"?: RelAgencyOwnerClusterJsonldReadCollectionClusterExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RelAgencyOwnerClusterJsonld {
  readonly "@context"?: RelAgencyOwnerClusterJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  agencyOwner?: string | null
  cluster?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyOwnerClusterJsonldContextOneOfHydra =
  (typeof RelAgencyOwnerClusterJsonldContextOneOfHydra)[keyof typeof RelAgencyOwnerClusterJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyOwnerClusterJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyOwnerClusterJsonldContextOneOf = {
  "@vocab": string
  hydra: RelAgencyOwnerClusterJsonldContextOneOfHydra
  [key: string]: any
}

export type RelAgencyOwnerClusterJsonldContext = string | RelAgencyOwnerClusterJsonldContextOneOf

export type RelAgencyI18nTextJsonldReadItemAgencyItemI18nText =
  I18nTextJsonldReadItemAgencyItem | null

export type RelAgencyI18nTextJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof RelAgencyI18nTextJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof RelAgencyI18nTextJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyI18nTextJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyI18nTextJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyI18nTextJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyI18nTextJsonldReadItemAgencyItemContext =
  | string
  | RelAgencyI18nTextJsonldReadItemAgencyItemContextOneOf

export interface RelAgencyI18nTextJsonldReadItemAgencyItem {
  readonly "@context"?: RelAgencyI18nTextJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  i18nText?: RelAgencyI18nTextJsonldReadItemAgencyItemI18nText
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyI18nTextJsonldReadCollectionAgencyExcelExportContextOneOfHydra =
  (typeof RelAgencyI18nTextJsonldReadCollectionAgencyExcelExportContextOneOfHydra)[keyof typeof RelAgencyI18nTextJsonldReadCollectionAgencyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyI18nTextJsonldReadCollectionAgencyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyI18nTextJsonldReadCollectionAgencyExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelAgencyI18nTextJsonldReadCollectionAgencyExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelAgencyI18nTextJsonldReadCollectionAgencyExcelExportContext =
  | string
  | RelAgencyI18nTextJsonldReadCollectionAgencyExcelExportContextOneOf

export interface RelAgencyI18nTextJsonldReadCollectionAgencyExcelExport {
  readonly "@context"?: RelAgencyI18nTextJsonldReadCollectionAgencyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyI18nTextJsonldI18nText = I18nTextJsonld | null

export interface RelAgencyI18nTextJsonld {
  readonly "@context"?: RelAgencyI18nTextJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  agency?: string | null
  i18nText?: RelAgencyI18nTextJsonldI18nText
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyI18nTextJsonldContextOneOfHydra =
  (typeof RelAgencyI18nTextJsonldContextOneOfHydra)[keyof typeof RelAgencyI18nTextJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyI18nTextJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyI18nTextJsonldContextOneOf = {
  "@vocab": string
  hydra: RelAgencyI18nTextJsonldContextOneOfHydra
  [key: string]: any
}

export type RelAgencyI18nTextJsonldContext = string | RelAgencyI18nTextJsonldContextOneOf

export type RelAgencyContactDetailsJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof RelAgencyContactDetailsJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof RelAgencyContactDetailsJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyContactDetailsJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyContactDetailsJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyContactDetailsJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyContactDetailsJsonldReadItemSocietyItemContext =
  | string
  | RelAgencyContactDetailsJsonldReadItemSocietyItemContextOneOf

export interface RelAgencyContactDetailsJsonldReadItemSocietyItem {
  readonly "@context"?: RelAgencyContactDetailsJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RelAgencyContactDetailsJsonldReadItemCollaboratorItem {
  readonly "@context"?: RelAgencyContactDetailsJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof RelAgencyContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof RelAgencyContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyContactDetailsJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyContactDetailsJsonldReadItemCollaboratorItemContext =
  | string
  | RelAgencyContactDetailsJsonldReadItemCollaboratorItemContextOneOf

export type RelAgencyContactDetailsJsonldReadItemClusterItemContextOneOfHydra =
  (typeof RelAgencyContactDetailsJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof RelAgencyContactDetailsJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyContactDetailsJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyContactDetailsJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyContactDetailsJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyContactDetailsJsonldReadItemClusterItemContext =
  | string
  | RelAgencyContactDetailsJsonldReadItemClusterItemContextOneOf

export interface RelAgencyContactDetailsJsonldReadItemClusterItem {
  readonly "@context"?: RelAgencyContactDetailsJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RelAgencyContactDetailsJsonldReadItemAgencyItem {
  readonly "@context"?: RelAgencyContactDetailsJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldReadItemAgencyItem
  isDefault?: boolean
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyContactDetailsJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof RelAgencyContactDetailsJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof RelAgencyContactDetailsJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyContactDetailsJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyContactDetailsJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyContactDetailsJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyContactDetailsJsonldReadItemAgencyItemContext =
  | string
  | RelAgencyContactDetailsJsonldReadItemAgencyItemContextOneOf

export interface RelAgencyContactDetailsJsonldReadCollectionAgencyExcelExport {
  readonly "@context"?: RelAgencyContactDetailsJsonldReadCollectionAgencyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldReadCollectionAgencyExcelExport
  isDefault?: boolean
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyContactDetailsJsonldReadCollectionAgencyExcelExportContextOneOfHydra =
  (typeof RelAgencyContactDetailsJsonldReadCollectionAgencyExcelExportContextOneOfHydra)[keyof typeof RelAgencyContactDetailsJsonldReadCollectionAgencyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyContactDetailsJsonldReadCollectionAgencyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyContactDetailsJsonldReadCollectionAgencyExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelAgencyContactDetailsJsonldReadCollectionAgencyExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelAgencyContactDetailsJsonldReadCollectionAgencyExcelExportContext =
  | string
  | RelAgencyContactDetailsJsonldReadCollectionAgencyExcelExportContextOneOf

export interface RelAgencyContactDetailsJsonldReadCollectionAgency {
  readonly "@context"?: RelAgencyContactDetailsJsonldReadCollectionAgencyContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldReadCollectionAgency
  isDefault?: boolean
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyContactDetailsJsonldReadCollectionAgencyContextOneOfHydra =
  (typeof RelAgencyContactDetailsJsonldReadCollectionAgencyContextOneOfHydra)[keyof typeof RelAgencyContactDetailsJsonldReadCollectionAgencyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyContactDetailsJsonldReadCollectionAgencyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyContactDetailsJsonldReadCollectionAgencyContextOneOf = {
  "@vocab": string
  hydra: RelAgencyContactDetailsJsonldReadCollectionAgencyContextOneOfHydra
  [key: string]: any
}

export type RelAgencyContactDetailsJsonldReadCollectionAgencyContext =
  | string
  | RelAgencyContactDetailsJsonldReadCollectionAgencyContextOneOf

export type RelAgencyContactDetailsJsonldContextOneOfHydra =
  (typeof RelAgencyContactDetailsJsonldContextOneOfHydra)[keyof typeof RelAgencyContactDetailsJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyContactDetailsJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyContactDetailsJsonldContextOneOf = {
  "@vocab": string
  hydra: RelAgencyContactDetailsJsonldContextOneOfHydra
  [key: string]: any
}

export type RelAgencyContactDetailsJsonldContext =
  | string
  | RelAgencyContactDetailsJsonldContextOneOf

export interface RelAgencyContactDetailsJsonld {
  readonly "@context"?: RelAgencyContactDetailsJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonld
  agency?: string
  isDefault?: boolean
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyClusterJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof RelAgencyClusterJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof RelAgencyClusterJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyClusterJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyClusterJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyClusterJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyClusterJsonldReadItemSocietyItemContext =
  | string
  | RelAgencyClusterJsonldReadItemSocietyItemContextOneOf

export interface RelAgencyClusterJsonldReadItemSocietyItem {
  readonly "@context"?: RelAgencyClusterJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyClusterJsonldReadItemCollaboratorItemCluster =
  ClusterJsonldReadItemCollaboratorItem | null

export interface RelAgencyClusterJsonldReadItemCollaboratorItem {
  readonly "@context"?: RelAgencyClusterJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  isDefault?: boolean | null
  cluster?: RelAgencyClusterJsonldReadItemCollaboratorItemCluster
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyClusterJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof RelAgencyClusterJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof RelAgencyClusterJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyClusterJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyClusterJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyClusterJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyClusterJsonldReadItemCollaboratorItemContext =
  | string
  | RelAgencyClusterJsonldReadItemCollaboratorItemContextOneOf

export type RelAgencyClusterJsonldReadItemClusterItemAgency = AgencyJsonldReadItemClusterItem | null

export type RelAgencyClusterJsonldReadItemClusterItemContextOneOfHydra =
  (typeof RelAgencyClusterJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof RelAgencyClusterJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyClusterJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyClusterJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyClusterJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyClusterJsonldReadItemClusterItemContext =
  | string
  | RelAgencyClusterJsonldReadItemClusterItemContextOneOf

export interface RelAgencyClusterJsonldReadItemClusterItem {
  readonly "@context"?: RelAgencyClusterJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  isDefault?: boolean | null
  agency?: RelAgencyClusterJsonldReadItemClusterItemAgency
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyClusterJsonldReadItemAgencyItemCluster = ClusterJsonldReadItemAgencyItem | null

export type RelAgencyClusterJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof RelAgencyClusterJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof RelAgencyClusterJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyClusterJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyClusterJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyClusterJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyClusterJsonldReadItemAgencyItemContext =
  | string
  | RelAgencyClusterJsonldReadItemAgencyItemContextOneOf

export interface RelAgencyClusterJsonldReadItemAgencyItem {
  readonly "@context"?: RelAgencyClusterJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  isDefault?: boolean | null
  cluster?: RelAgencyClusterJsonldReadItemAgencyItemCluster
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra =
  (typeof RelAgencyClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra)[keyof typeof RelAgencyClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyClusterJsonldReadCollectionClusterExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelAgencyClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelAgencyClusterJsonldReadCollectionClusterExcelExportContext =
  | string
  | RelAgencyClusterJsonldReadCollectionClusterExcelExportContextOneOf

export interface RelAgencyClusterJsonldReadCollectionClusterExcelExport {
  readonly "@context"?: RelAgencyClusterJsonldReadCollectionClusterExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyClusterJsonldContextOneOfHydra =
  (typeof RelAgencyClusterJsonldContextOneOfHydra)[keyof typeof RelAgencyClusterJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyClusterJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyClusterJsonldContextOneOf = {
  "@vocab": string
  hydra: RelAgencyClusterJsonldContextOneOfHydra
  [key: string]: any
}

export type RelAgencyClusterJsonldContext = string | RelAgencyClusterJsonldContextOneOf

export interface RelAgencyClusterJsonld {
  readonly "@context"?: RelAgencyClusterJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  isDefault?: boolean | null
  agency?: string | null
  cluster?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RelAgencyActivityJsonldReadItemCollaboratorItem {
  readonly "@context"?: RelAgencyActivityJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyActivityJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof RelAgencyActivityJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof RelAgencyActivityJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyActivityJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyActivityJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyActivityJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyActivityJsonldReadItemCollaboratorItemContext =
  | string
  | RelAgencyActivityJsonldReadItemCollaboratorItemContextOneOf

export type RelAgencyActivityJsonldReadItemAgencyItemCode =
  (typeof RelAgencyActivityJsonldReadItemAgencyItemCode)[keyof typeof RelAgencyActivityJsonldReadItemAgencyItemCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyActivityJsonldReadItemAgencyItemCode = {
  "real-estate-sales": "real-estate-sales",
  "real-estate-rentals": "real-estate-rentals",
  "real-estate-vacations": "real-estate-vacations",
  "property-management": "property-management",
  services: "services",
  expertise: "expertise",
  support: "support",
} as const

export interface RelAgencyActivityJsonldReadItemAgencyItem {
  readonly "@context"?: RelAgencyActivityJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: RelAgencyActivityJsonldReadItemAgencyItemCode
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyActivityJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof RelAgencyActivityJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof RelAgencyActivityJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyActivityJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyActivityJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: RelAgencyActivityJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type RelAgencyActivityJsonldReadItemAgencyItemContext =
  | string
  | RelAgencyActivityJsonldReadItemAgencyItemContextOneOf

export type RelAgencyActivityJsonldReadCollectionAgencyExcelExportCode =
  (typeof RelAgencyActivityJsonldReadCollectionAgencyExcelExportCode)[keyof typeof RelAgencyActivityJsonldReadCollectionAgencyExcelExportCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyActivityJsonldReadCollectionAgencyExcelExportCode = {
  "real-estate-sales": "real-estate-sales",
  "real-estate-rentals": "real-estate-rentals",
  "real-estate-vacations": "real-estate-vacations",
  "property-management": "property-management",
  services: "services",
  expertise: "expertise",
  support: "support",
} as const

export interface RelAgencyActivityJsonldReadCollectionAgencyExcelExport {
  readonly "@context"?: RelAgencyActivityJsonldReadCollectionAgencyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: RelAgencyActivityJsonldReadCollectionAgencyExcelExportCode
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyActivityJsonldReadCollectionAgencyExcelExportContextOneOfHydra =
  (typeof RelAgencyActivityJsonldReadCollectionAgencyExcelExportContextOneOfHydra)[keyof typeof RelAgencyActivityJsonldReadCollectionAgencyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyActivityJsonldReadCollectionAgencyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyActivityJsonldReadCollectionAgencyExcelExportContextOneOf = {
  "@vocab": string
  hydra: RelAgencyActivityJsonldReadCollectionAgencyExcelExportContextOneOfHydra
  [key: string]: any
}

export type RelAgencyActivityJsonldReadCollectionAgencyExcelExportContext =
  | string
  | RelAgencyActivityJsonldReadCollectionAgencyExcelExportContextOneOf

export interface RelAgencyActivityJsonldReadCollectionAgency {
  readonly "@context"?: RelAgencyActivityJsonldReadCollectionAgencyContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RelAgencyActivityJsonldReadCollectionAgencyContextOneOfHydra =
  (typeof RelAgencyActivityJsonldReadCollectionAgencyContextOneOfHydra)[keyof typeof RelAgencyActivityJsonldReadCollectionAgencyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyActivityJsonldReadCollectionAgencyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyActivityJsonldReadCollectionAgencyContextOneOf = {
  "@vocab": string
  hydra: RelAgencyActivityJsonldReadCollectionAgencyContextOneOfHydra
  [key: string]: any
}

export type RelAgencyActivityJsonldReadCollectionAgencyContext =
  | string
  | RelAgencyActivityJsonldReadCollectionAgencyContextOneOf

export type RelAgencyActivityJsonldCode =
  (typeof RelAgencyActivityJsonldCode)[keyof typeof RelAgencyActivityJsonldCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyActivityJsonldCode = {
  "real-estate-sales": "real-estate-sales",
  "real-estate-rentals": "real-estate-rentals",
  "real-estate-vacations": "real-estate-vacations",
  "property-management": "property-management",
  services: "services",
  expertise: "expertise",
  support: "support",
} as const

export type RelAgencyActivityJsonldContextOneOfHydra =
  (typeof RelAgencyActivityJsonldContextOneOfHydra)[keyof typeof RelAgencyActivityJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelAgencyActivityJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RelAgencyActivityJsonldContextOneOf = {
  "@vocab": string
  hydra: RelAgencyActivityJsonldContextOneOfHydra
  [key: string]: any
}

export type RelAgencyActivityJsonldContext = string | RelAgencyActivityJsonldContextOneOf

export interface RelAgencyActivityJsonld {
  readonly "@context"?: RelAgencyActivityJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: RelAgencyActivityJsonldCode
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelTranslationJsonldUserReadItemContextOneOfHydra =
  (typeof RefLabelTranslationJsonldUserReadItemContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldUserReadItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldUserReadItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldUserReadItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldUserReadItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldUserReadItemContext =
  | string
  | RefLabelTranslationJsonldUserReadItemContextOneOf

export interface RefLabelTranslationJsonldUserReadItem {
  readonly "@context"?: RefLabelTranslationJsonldUserReadItemContext
  readonly "@id"?: string
  readonly "@type"?: string
}

export type RefLabelTranslationJsonldUserReadCollectionContextOneOfHydra =
  (typeof RefLabelTranslationJsonldUserReadCollectionContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldUserReadCollectionContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldUserReadCollectionContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldUserReadCollectionContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldUserReadCollectionContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldUserReadCollectionContext =
  | string
  | RefLabelTranslationJsonldUserReadCollectionContextOneOf

export interface RefLabelTranslationJsonldUserReadCollection {
  readonly "@context"?: RefLabelTranslationJsonldUserReadCollectionContext
  readonly "@id"?: string
  readonly "@type"?: string
}

export type RefLabelTranslationJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadItemSocietyItemContext =
  | string
  | RefLabelTranslationJsonldReadItemSocietyItemContextOneOf

export interface RefLabelTranslationJsonldReadItemSocietyItem {
  readonly "@context"?: RefLabelTranslationJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelTranslationJsonldReadItemRefLabelItemContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadItemRefLabelItemContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadItemRefLabelItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadItemRefLabelItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadItemRefLabelItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadItemRefLabelItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadItemRefLabelItemContext =
  | string
  | RefLabelTranslationJsonldReadItemRefLabelItemContextOneOf

export interface RefLabelTranslationJsonldReadItemRefLabelItem {
  readonly "@context"?: RefLabelTranslationJsonldReadItemRefLabelItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export interface RefLabelTranslationJsonldReadItemRefLabelGroupItem {
  readonly "@context"?: RefLabelTranslationJsonldReadItemRefLabelGroupItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelTranslationJsonldReadItemRefLabelGroupItemContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadItemRefLabelGroupItemContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadItemRefLabelGroupItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadItemRefLabelGroupItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadItemRefLabelGroupItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadItemRefLabelGroupItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadItemRefLabelGroupItemContext =
  | string
  | RefLabelTranslationJsonldReadItemRefLabelGroupItemContextOneOf

export interface RefLabelTranslationJsonldReadItemPropertySearch {
  readonly "@context"?: RefLabelTranslationJsonldReadItemPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelTranslationJsonldReadItemPropertySearchContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadItemPropertySearchContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadItemPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadItemPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadItemPropertySearchContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadItemPropertySearchContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadItemPropertySearchContext =
  | string
  | RefLabelTranslationJsonldReadItemPropertySearchContextOneOf

export type RefLabelTranslationJsonldReadItemPropertyOfferContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadItemPropertyOfferContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadItemPropertyOfferContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadItemPropertyOfferContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadItemPropertyOfferContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadItemPropertyOfferContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadItemPropertyOfferContext =
  | string
  | RefLabelTranslationJsonldReadItemPropertyOfferContextOneOf

export interface RefLabelTranslationJsonldReadItemPropertyOffer {
  readonly "@context"?: RefLabelTranslationJsonldReadItemPropertyOfferContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelTranslationJsonldReadItemPropertyContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadItemPropertyContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadItemPropertyContext =
  | string
  | RefLabelTranslationJsonldReadItemPropertyContextOneOf

export interface RefLabelTranslationJsonldReadItemProperty {
  readonly "@context"?: RefLabelTranslationJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export interface RefLabelTranslationJsonldReadItemProject {
  readonly "@context"?: RefLabelTranslationJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelTranslationJsonldReadItemProjectContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadItemProjectContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadItemProjectContext =
  | string
  | RefLabelTranslationJsonldReadItemProjectContextOneOf

export type RefLabelTranslationJsonldReadItemPeopleContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadItemPeopleContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadItemPeopleContext =
  | string
  | RefLabelTranslationJsonldReadItemPeopleContextOneOf

export interface RefLabelTranslationJsonldReadItemPeople {
  readonly "@context"?: RefLabelTranslationJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export interface RefLabelTranslationJsonldReadItemCollaboratorItem {
  readonly "@context"?: RefLabelTranslationJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelTranslationJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadItemCollaboratorItemContext =
  | string
  | RefLabelTranslationJsonldReadItemCollaboratorItemContextOneOf

export type RefLabelTranslationJsonldReadItemClusterItemContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadItemClusterItemContext =
  | string
  | RefLabelTranslationJsonldReadItemClusterItemContextOneOf

export interface RefLabelTranslationJsonldReadItemClusterItem {
  readonly "@context"?: RefLabelTranslationJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export interface RefLabelTranslationJsonldReadItemAgencyItem {
  readonly "@context"?: RefLabelTranslationJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelTranslationJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadItemAgencyItemContext =
  | string
  | RefLabelTranslationJsonldReadItemAgencyItemContextOneOf

export interface RefLabelTranslationJsonldReadCollectionSociety {
  readonly "@context"?: RefLabelTranslationJsonldReadCollectionSocietyContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelTranslationJsonldReadCollectionSocietyContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadCollectionSocietyContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadCollectionSocietyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadCollectionSocietyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadCollectionSocietyContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadCollectionSocietyContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadCollectionSocietyContext =
  | string
  | RefLabelTranslationJsonldReadCollectionSocietyContextOneOf

export interface RefLabelTranslationJsonldReadCollectionSharedDataReadItemSharedData {
  readonly "@context"?: RefLabelTranslationJsonldReadCollectionSharedDataReadItemSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
}

export type RefLabelTranslationJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra =
  {
    httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
  } as const

export type RefLabelTranslationJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadCollectionSharedDataReadItemSharedDataContext =
  | string
  | RefLabelTranslationJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf

export type RefLabelTranslationJsonldReadCollectionSharedDataContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadCollectionSharedDataContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadCollectionSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadCollectionSharedDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadCollectionSharedDataContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadCollectionSharedDataContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadCollectionSharedDataContext =
  | string
  | RefLabelTranslationJsonldReadCollectionSharedDataContextOneOf

export interface RefLabelTranslationJsonldReadCollectionSharedData {
  readonly "@context"?: RefLabelTranslationJsonldReadCollectionSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
}

export type RefLabelTranslationJsonldReadCollectionRefLabelGroupContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadCollectionRefLabelGroupContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadCollectionRefLabelGroupContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadCollectionRefLabelGroupContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadCollectionRefLabelGroupContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadCollectionRefLabelGroupContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadCollectionRefLabelGroupContext =
  | string
  | RefLabelTranslationJsonldReadCollectionRefLabelGroupContextOneOf

export interface RefLabelTranslationJsonldReadCollectionRefLabelGroup {
  readonly "@context"?: RefLabelTranslationJsonldReadCollectionRefLabelGroupContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelTranslationJsonldReadCollectionRefLabelContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadCollectionRefLabelContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadCollectionRefLabelContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadCollectionRefLabelContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadCollectionRefLabelContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadCollectionRefLabelContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadCollectionRefLabelContext =
  | string
  | RefLabelTranslationJsonldReadCollectionRefLabelContextOneOf

export interface RefLabelTranslationJsonldReadCollectionRefLabel {
  readonly "@context"?: RefLabelTranslationJsonldReadCollectionRefLabelContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelTranslationJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadCollectionPropertySearchExcelExportContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadCollectionPropertySearchExcelExportContext =
  | string
  | RefLabelTranslationJsonldReadCollectionPropertySearchExcelExportContextOneOf

export interface RefLabelTranslationJsonldReadCollectionPropertySearchExcelExport {
  readonly "@context"?: RefLabelTranslationJsonldReadCollectionPropertySearchExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export interface RefLabelTranslationJsonldReadCollectionPropertySearch {
  readonly "@context"?: RefLabelTranslationJsonldReadCollectionPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelTranslationJsonldReadCollectionPropertySearchContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadCollectionPropertySearchContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadCollectionPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadCollectionPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadCollectionPropertySearchContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadCollectionPropertySearchContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadCollectionPropertySearchContext =
  | string
  | RefLabelTranslationJsonldReadCollectionPropertySearchContextOneOf

export interface RefLabelTranslationJsonldReadCollectionPropertyOfferExcelExport {
  readonly "@context"?: RefLabelTranslationJsonldReadCollectionPropertyOfferExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelTranslationJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadCollectionPropertyOfferExcelExportContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadCollectionPropertyOfferExcelExportContext =
  | string
  | RefLabelTranslationJsonldReadCollectionPropertyOfferExcelExportContextOneOf

export type RefLabelTranslationJsonldReadCollectionPropertyOfferContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadCollectionPropertyOfferContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadCollectionPropertyOfferContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadCollectionPropertyOfferContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadCollectionPropertyOfferContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadCollectionPropertyOfferContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadCollectionPropertyOfferContext =
  | string
  | RefLabelTranslationJsonldReadCollectionPropertyOfferContextOneOf

export interface RefLabelTranslationJsonldReadCollectionPropertyOffer {
  readonly "@context"?: RefLabelTranslationJsonldReadCollectionPropertyOfferContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelTranslationJsonldReadCollectionPropertyContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadCollectionPropertyContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadCollectionPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadCollectionPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadCollectionPropertyContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadCollectionPropertyContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadCollectionPropertyContext =
  | string
  | RefLabelTranslationJsonldReadCollectionPropertyContextOneOf

export interface RefLabelTranslationJsonldReadCollectionProperty {
  readonly "@context"?: RefLabelTranslationJsonldReadCollectionPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export interface RefLabelTranslationJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: RefLabelTranslationJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
}

export type RefLabelTranslationJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadCollectionProjectExcelExportContext =
  | string
  | RefLabelTranslationJsonldReadCollectionProjectExcelExportContextOneOf

export type RefLabelTranslationJsonldReadCollectionProjectContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadCollectionProjectContext =
  | string
  | RefLabelTranslationJsonldReadCollectionProjectContextOneOf

export interface RefLabelTranslationJsonldReadCollectionProject {
  readonly "@context"?: RefLabelTranslationJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelTranslationJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadCollectionPeopleExcelExportContext =
  | string
  | RefLabelTranslationJsonldReadCollectionPeopleExcelExportContextOneOf

export interface RefLabelTranslationJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: RefLabelTranslationJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export interface RefLabelTranslationJsonldReadCollectionPeople {
  readonly "@context"?: RefLabelTranslationJsonldReadCollectionPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
}

export type RefLabelTranslationJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadCollectionPeopleContext =
  | string
  | RefLabelTranslationJsonldReadCollectionPeopleContextOneOf

export interface RefLabelTranslationJsonldReadCollectionCollaborator {
  readonly "@context"?: RefLabelTranslationJsonldReadCollectionCollaboratorContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelTranslationJsonldReadCollectionCollaboratorContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadCollectionCollaboratorContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadCollectionCollaboratorContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadCollectionCollaboratorContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadCollectionCollaboratorContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadCollectionCollaboratorContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadCollectionCollaboratorContext =
  | string
  | RefLabelTranslationJsonldReadCollectionCollaboratorContextOneOf

export interface RefLabelTranslationJsonldReadCollectionCluster {
  readonly "@context"?: RefLabelTranslationJsonldReadCollectionClusterContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelTranslationJsonldReadCollectionClusterContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadCollectionClusterContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadCollectionClusterContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadCollectionClusterContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadCollectionClusterContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadCollectionClusterContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadCollectionClusterContext =
  | string
  | RefLabelTranslationJsonldReadCollectionClusterContextOneOf

export type RefLabelTranslationJsonldReadCollectionAgencyContextOneOfHydra =
  (typeof RefLabelTranslationJsonldReadCollectionAgencyContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldReadCollectionAgencyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldReadCollectionAgencyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldReadCollectionAgencyContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldReadCollectionAgencyContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldReadCollectionAgencyContext =
  | string
  | RefLabelTranslationJsonldReadCollectionAgencyContextOneOf

export interface RefLabelTranslationJsonldReadCollectionAgency {
  readonly "@context"?: RefLabelTranslationJsonldReadCollectionAgencyContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelTranslationJsonldContextOneOfHydra =
  (typeof RefLabelTranslationJsonldContextOneOfHydra)[keyof typeof RefLabelTranslationJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelTranslationJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelTranslationJsonldContextOneOf = {
  "@vocab": string
  hydra: RefLabelTranslationJsonldContextOneOfHydra
  [key: string]: any
}

export type RefLabelTranslationJsonldContext = string | RefLabelTranslationJsonldContextOneOf

export interface RefLabelTranslationJsonld {
  readonly "@context"?: RefLabelTranslationJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  /** Get related object */
  object?: string | null
  locale?: string
  field?: string
  readonly label?: string
  readonly id?: number
  content?: string | null
}

export type RefLabelGroupTranslationJsonldReadItemRefLabelItemContextOneOfHydra =
  (typeof RefLabelGroupTranslationJsonldReadItemRefLabelItemContextOneOfHydra)[keyof typeof RefLabelGroupTranslationJsonldReadItemRefLabelItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupTranslationJsonldReadItemRefLabelItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupTranslationJsonldReadItemRefLabelItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupTranslationJsonldReadItemRefLabelItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupTranslationJsonldReadItemRefLabelItemContext =
  | string
  | RefLabelGroupTranslationJsonldReadItemRefLabelItemContextOneOf

export interface RefLabelGroupTranslationJsonldReadItemRefLabelItem {
  readonly "@context"?: RefLabelGroupTranslationJsonldReadItemRefLabelItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelGroupTranslationJsonldReadItemRefLabelGroupItemContextOneOfHydra =
  (typeof RefLabelGroupTranslationJsonldReadItemRefLabelGroupItemContextOneOfHydra)[keyof typeof RefLabelGroupTranslationJsonldReadItemRefLabelGroupItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupTranslationJsonldReadItemRefLabelGroupItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupTranslationJsonldReadItemRefLabelGroupItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupTranslationJsonldReadItemRefLabelGroupItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupTranslationJsonldReadItemRefLabelGroupItemContext =
  | string
  | RefLabelGroupTranslationJsonldReadItemRefLabelGroupItemContextOneOf

export interface RefLabelGroupTranslationJsonldReadItemRefLabelGroupItem {
  readonly "@context"?: RefLabelGroupTranslationJsonldReadItemRefLabelGroupItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export interface RefLabelGroupTranslationJsonldReadCollectionRefLabelGroup {
  readonly "@context"?: RefLabelGroupTranslationJsonldReadCollectionRefLabelGroupContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelGroupTranslationJsonldReadCollectionRefLabelGroupContextOneOfHydra =
  (typeof RefLabelGroupTranslationJsonldReadCollectionRefLabelGroupContextOneOfHydra)[keyof typeof RefLabelGroupTranslationJsonldReadCollectionRefLabelGroupContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupTranslationJsonldReadCollectionRefLabelGroupContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupTranslationJsonldReadCollectionRefLabelGroupContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupTranslationJsonldReadCollectionRefLabelGroupContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupTranslationJsonldReadCollectionRefLabelGroupContext =
  | string
  | RefLabelGroupTranslationJsonldReadCollectionRefLabelGroupContextOneOf

export interface RefLabelGroupTranslationJsonldReadCollectionRefLabel {
  readonly "@context"?: RefLabelGroupTranslationJsonldReadCollectionRefLabelContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly locale?: string
  readonly field?: string
  readonly label?: string
}

export type RefLabelGroupTranslationJsonldReadCollectionRefLabelContextOneOfHydra =
  (typeof RefLabelGroupTranslationJsonldReadCollectionRefLabelContextOneOfHydra)[keyof typeof RefLabelGroupTranslationJsonldReadCollectionRefLabelContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupTranslationJsonldReadCollectionRefLabelContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupTranslationJsonldReadCollectionRefLabelContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupTranslationJsonldReadCollectionRefLabelContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupTranslationJsonldReadCollectionRefLabelContext =
  | string
  | RefLabelGroupTranslationJsonldReadCollectionRefLabelContextOneOf

export type RefLabelGroupTranslationJsonldContextOneOfHydra =
  (typeof RefLabelGroupTranslationJsonldContextOneOfHydra)[keyof typeof RefLabelGroupTranslationJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupTranslationJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupTranslationJsonldContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupTranslationJsonldContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupTranslationJsonldContext =
  | string
  | RefLabelGroupTranslationJsonldContextOneOf

export interface RefLabelGroupTranslationJsonld {
  readonly "@context"?: RefLabelGroupTranslationJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  /** Get related object */
  object?: string | null
  locale?: string
  field?: string
  readonly label?: string
  readonly id?: number
  content?: string | null
}

export type RefLabelGroupRelationDTOJsonldContextOneOfHydra =
  (typeof RefLabelGroupRelationDTOJsonldContextOneOfHydra)[keyof typeof RefLabelGroupRelationDTOJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationDTOJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationDTOJsonldContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationDTOJsonldContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationDTOJsonldContext =
  | string
  | RefLabelGroupRelationDTOJsonldContextOneOf

export interface RefLabelGroupRelationDTOJsonld {
  readonly "@context"?: RefLabelGroupRelationDTOJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  id?: string
  order?: number
}

export interface RefLabelGroupRelationDTO {
  id?: string
  order?: number
}

export interface RefLabelGroupRelationJsonldUserReadItem {
  readonly "@context"?: RefLabelGroupRelationJsonldUserReadItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldUserReadItemContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldUserReadItemContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldUserReadItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldUserReadItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldUserReadItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldUserReadItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldUserReadItemContext =
  | string
  | RefLabelGroupRelationJsonldUserReadItemContextOneOf

export type RefLabelGroupRelationJsonldUserReadCollectionContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldUserReadCollectionContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldUserReadCollectionContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldUserReadCollectionContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldUserReadCollectionContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldUserReadCollectionContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldUserReadCollectionContext =
  | string
  | RefLabelGroupRelationJsonldUserReadCollectionContextOneOf

export interface RefLabelGroupRelationJsonldUserReadCollection {
  readonly "@context"?: RefLabelGroupRelationJsonldUserReadCollectionContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadItemSocietyItemContext =
  | string
  | RefLabelGroupRelationJsonldReadItemSocietyItemContextOneOf

export interface RefLabelGroupRelationJsonldReadItemSocietyItem {
  readonly "@context"?: RefLabelGroupRelationJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RefLabelGroupRelationJsonldReadItemRefLabelItem {
  readonly "@context"?: RefLabelGroupRelationJsonldReadItemRefLabelItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  refLabelGroup?: RefLabelGroupJsonldReadItemRefLabelItem
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldReadItemRefLabelItemContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadItemRefLabelItemContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadItemRefLabelItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadItemRefLabelItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadItemRefLabelItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadItemRefLabelItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadItemRefLabelItemContext =
  | string
  | RefLabelGroupRelationJsonldReadItemRefLabelItemContextOneOf

export interface RefLabelGroupRelationJsonldReadItemRefLabelGroupItem {
  readonly "@context"?: RefLabelGroupRelationJsonldReadItemRefLabelGroupItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  refLabel?: RefLabelJsonldReadItemRefLabelGroupItem
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldReadItemRefLabelGroupItemContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadItemRefLabelGroupItemContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadItemRefLabelGroupItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadItemRefLabelGroupItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadItemRefLabelGroupItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadItemRefLabelGroupItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadItemRefLabelGroupItemContext =
  | string
  | RefLabelGroupRelationJsonldReadItemRefLabelGroupItemContextOneOf

export interface RefLabelGroupRelationJsonldReadItemPropertySearch {
  readonly "@context"?: RefLabelGroupRelationJsonldReadItemPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldReadItemPropertySearchContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadItemPropertySearchContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadItemPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadItemPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadItemPropertySearchContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadItemPropertySearchContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadItemPropertySearchContext =
  | string
  | RefLabelGroupRelationJsonldReadItemPropertySearchContextOneOf

export type RefLabelGroupRelationJsonldReadItemPropertyOfferContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadItemPropertyOfferContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadItemPropertyOfferContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadItemPropertyOfferContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadItemPropertyOfferContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadItemPropertyOfferContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadItemPropertyOfferContext =
  | string
  | RefLabelGroupRelationJsonldReadItemPropertyOfferContextOneOf

export interface RefLabelGroupRelationJsonldReadItemPropertyOffer {
  readonly "@context"?: RefLabelGroupRelationJsonldReadItemPropertyOfferContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldReadItemPropertyContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadItemPropertyContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadItemPropertyContext =
  | string
  | RefLabelGroupRelationJsonldReadItemPropertyContextOneOf

export interface RefLabelGroupRelationJsonldReadItemProperty {
  readonly "@context"?: RefLabelGroupRelationJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldReadItemPeopleContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadItemPeopleContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadItemPeopleContext =
  | string
  | RefLabelGroupRelationJsonldReadItemPeopleContextOneOf

export interface RefLabelGroupRelationJsonldReadItemPeople {
  readonly "@context"?: RefLabelGroupRelationJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadItemCollaboratorItemContext =
  | string
  | RefLabelGroupRelationJsonldReadItemCollaboratorItemContextOneOf

export interface RefLabelGroupRelationJsonldReadItemCollaboratorItem {
  readonly "@context"?: RefLabelGroupRelationJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RefLabelGroupRelationJsonldReadItemClusterItem {
  readonly "@context"?: RefLabelGroupRelationJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldReadItemClusterItemContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadItemClusterItemContext =
  | string
  | RefLabelGroupRelationJsonldReadItemClusterItemContextOneOf

export interface RefLabelGroupRelationJsonldReadItemAgencyItem {
  readonly "@context"?: RefLabelGroupRelationJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadItemAgencyItemContext =
  | string
  | RefLabelGroupRelationJsonldReadItemAgencyItemContextOneOf

export type RefLabelGroupRelationJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra =
  {
    httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
  } as const

export type RefLabelGroupRelationJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadCollectionSharedDataReadItemSharedDataContext =
  | string
  | RefLabelGroupRelationJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf

export interface RefLabelGroupRelationJsonldReadCollectionSharedDataReadItemSharedData {
  readonly "@context"?: RefLabelGroupRelationJsonldReadCollectionSharedDataReadItemSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldReadCollectionSharedDataContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadCollectionSharedDataContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadCollectionSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadCollectionSharedDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadCollectionSharedDataContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadCollectionSharedDataContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadCollectionSharedDataContext =
  | string
  | RefLabelGroupRelationJsonldReadCollectionSharedDataContextOneOf

export interface RefLabelGroupRelationJsonldReadCollectionSharedData {
  readonly "@context"?: RefLabelGroupRelationJsonldReadCollectionSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RefLabelGroupRelationJsonldReadCollectionRefLabelGroup {
  readonly "@context"?: RefLabelGroupRelationJsonldReadCollectionRefLabelGroupContext
  readonly "@id"?: string
  readonly "@type"?: string
  refLabel?: RefLabelJsonldReadCollectionRefLabelGroup
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldReadCollectionRefLabelGroupContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadCollectionRefLabelGroupContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadCollectionRefLabelGroupContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadCollectionRefLabelGroupContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadCollectionRefLabelGroupContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadCollectionRefLabelGroupContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadCollectionRefLabelGroupContext =
  | string
  | RefLabelGroupRelationJsonldReadCollectionRefLabelGroupContextOneOf

export type RefLabelGroupRelationJsonldReadCollectionRefLabelContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadCollectionRefLabelContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadCollectionRefLabelContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadCollectionRefLabelContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadCollectionRefLabelContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadCollectionRefLabelContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadCollectionRefLabelContext =
  | string
  | RefLabelGroupRelationJsonldReadCollectionRefLabelContextOneOf

export interface RefLabelGroupRelationJsonldReadCollectionRefLabel {
  readonly "@context"?: RefLabelGroupRelationJsonldReadCollectionRefLabelContext
  readonly "@id"?: string
  readonly "@type"?: string
  refLabelGroup?: RefLabelGroupJsonldReadCollectionRefLabel
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadCollectionPropertySearchExcelExportContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadCollectionPropertySearchExcelExportContext =
  | string
  | RefLabelGroupRelationJsonldReadCollectionPropertySearchExcelExportContextOneOf

export interface RefLabelGroupRelationJsonldReadCollectionPropertySearchExcelExport {
  readonly "@context"?: RefLabelGroupRelationJsonldReadCollectionPropertySearchExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldReadCollectionPropertySearchContext =
  | string
  | RefLabelGroupRelationJsonldReadCollectionPropertySearchContextOneOf

export interface RefLabelGroupRelationJsonldReadCollectionPropertySearch {
  readonly "@context"?: RefLabelGroupRelationJsonldReadCollectionPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldReadCollectionPropertySearchContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadCollectionPropertySearchContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadCollectionPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadCollectionPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadCollectionPropertySearchContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadCollectionPropertySearchContextOneOfHydra
  [key: string]: any
}

export interface RefLabelGroupRelationJsonldReadCollectionPropertyOfferExcelExport {
  readonly "@context"?: RefLabelGroupRelationJsonldReadCollectionPropertyOfferExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadCollectionPropertyOfferExcelExportContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadCollectionPropertyOfferExcelExportContext =
  | string
  | RefLabelGroupRelationJsonldReadCollectionPropertyOfferExcelExportContextOneOf

export interface RefLabelGroupRelationJsonldReadCollectionPropertyOffer {
  readonly "@context"?: RefLabelGroupRelationJsonldReadCollectionPropertyOfferContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldReadCollectionPropertyOfferContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadCollectionPropertyOfferContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadCollectionPropertyOfferContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadCollectionPropertyOfferContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadCollectionPropertyOfferContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadCollectionPropertyOfferContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadCollectionPropertyOfferContext =
  | string
  | RefLabelGroupRelationJsonldReadCollectionPropertyOfferContextOneOf

export type RefLabelGroupRelationJsonldReadCollectionPropertyContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadCollectionPropertyContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadCollectionPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadCollectionPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadCollectionPropertyContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadCollectionPropertyContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadCollectionPropertyContext =
  | string
  | RefLabelGroupRelationJsonldReadCollectionPropertyContextOneOf

export interface RefLabelGroupRelationJsonldReadCollectionProperty {
  readonly "@context"?: RefLabelGroupRelationJsonldReadCollectionPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadCollectionPeopleExcelExportContext =
  | string
  | RefLabelGroupRelationJsonldReadCollectionPeopleExcelExportContextOneOf

export interface RefLabelGroupRelationJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: RefLabelGroupRelationJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldReadCollectionPeopleContext =
  | string
  | RefLabelGroupRelationJsonldReadCollectionPeopleContextOneOf

export interface RefLabelGroupRelationJsonldReadCollectionPeople {
  readonly "@context"?: RefLabelGroupRelationJsonldReadCollectionPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupRelationJsonldContextOneOfHydra =
  (typeof RefLabelGroupRelationJsonldContextOneOfHydra)[keyof typeof RefLabelGroupRelationJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupRelationJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupRelationJsonldContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupRelationJsonldContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupRelationJsonldContext = string | RefLabelGroupRelationJsonldContextOneOf

export interface RefLabelGroupRelationJsonld {
  readonly "@context"?: RefLabelGroupRelationJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  refLabel?: string
  refLabelGroup?: string
  order?: number
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RefLabelGroupJsonldReadItemRefLabelItem {
  readonly "@context"?: RefLabelGroupJsonldReadItemRefLabelItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  group?: string
  canEdit?: boolean
  translations?: RefLabelGroupTranslationJsonldReadItemRefLabelItem[]
  label?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupJsonldReadItemRefLabelItemContextOneOfHydra =
  (typeof RefLabelGroupJsonldReadItemRefLabelItemContextOneOfHydra)[keyof typeof RefLabelGroupJsonldReadItemRefLabelItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupJsonldReadItemRefLabelItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupJsonldReadItemRefLabelItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupJsonldReadItemRefLabelItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupJsonldReadItemRefLabelItemContext =
  | string
  | RefLabelGroupJsonldReadItemRefLabelItemContextOneOf

export interface RefLabelGroupJsonldReadItemRefLabelGroupItem {
  readonly "@context"?: RefLabelGroupJsonldReadItemRefLabelGroupItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  group?: string
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadItemRefLabelGroupItem[]
  translations?: RefLabelGroupTranslationJsonldReadItemRefLabelGroupItem[]
  label?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupJsonldReadItemRefLabelGroupItemContextOneOfHydra =
  (typeof RefLabelGroupJsonldReadItemRefLabelGroupItemContextOneOfHydra)[keyof typeof RefLabelGroupJsonldReadItemRefLabelGroupItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupJsonldReadItemRefLabelGroupItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupJsonldReadItemRefLabelGroupItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupJsonldReadItemRefLabelGroupItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupJsonldReadItemRefLabelGroupItemContext =
  | string
  | RefLabelGroupJsonldReadItemRefLabelGroupItemContextOneOf

export interface RefLabelGroupJsonldReadCollectionRefLabelGroup {
  readonly "@id"?: string
  readonly "@type"?: string
  group?: string
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadCollectionRefLabelGroup[]
  translations?: RefLabelGroupTranslationJsonldReadCollectionRefLabelGroup[]
  label?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupJsonldReadCollectionRefLabelContextOneOfHydra =
  (typeof RefLabelGroupJsonldReadCollectionRefLabelContextOneOfHydra)[keyof typeof RefLabelGroupJsonldReadCollectionRefLabelContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupJsonldReadCollectionRefLabelContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupJsonldReadCollectionRefLabelContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupJsonldReadCollectionRefLabelContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupJsonldReadCollectionRefLabelContext =
  | string
  | RefLabelGroupJsonldReadCollectionRefLabelContextOneOf

export interface RefLabelGroupJsonldReadCollectionRefLabel {
  readonly "@context"?: RefLabelGroupJsonldReadCollectionRefLabelContext
  readonly "@id"?: string
  readonly "@type"?: string
  group?: string
  canEdit?: boolean
  translations?: RefLabelGroupTranslationJsonldReadCollectionRefLabel[]
  label?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type RefLabelGroupJsonldContextOneOfHydra =
  (typeof RefLabelGroupJsonldContextOneOfHydra)[keyof typeof RefLabelGroupJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelGroupJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelGroupJsonldContextOneOf = {
  "@vocab": string
  hydra: RefLabelGroupJsonldContextOneOfHydra
  [key: string]: any
}

export type RefLabelGroupJsonldContext = string | RefLabelGroupJsonldContextOneOf

export interface RefLabelGroupJsonld {
  readonly "@context"?: RefLabelGroupJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  group?: string
  canEdit?: boolean
  refLabelGroupRelation?: string[]
  translations?: RefLabelGroupTranslationJsonld[]
  label?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface RefLabelGroupPostRefLabelGroupInputJsonld {
  group?: string | null
  refLabelGroupRelation?: RefLabelGroupRelationDTOJsonld[] | null
  translations?: TranslationsInputDTOJsonld[] | null
}

export interface RefLabelGroupPatchRefLabelGroupInput {
  group?: string | null
  refLabelGroupRelation?: RefLabelGroupRelationDTO[] | null
  translations?: TranslationsInputDTO[] | null
}

export type RefLabelJsonldUserReadItemContextOneOfHydra =
  (typeof RefLabelJsonldUserReadItemContextOneOfHydra)[keyof typeof RefLabelJsonldUserReadItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldUserReadItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldUserReadItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldUserReadItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldUserReadItemContext = string | RefLabelJsonldUserReadItemContextOneOf

export interface RefLabelJsonldUserReadItem {
  readonly "@context"?: RefLabelJsonldUserReadItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldUserReadItem[]
  translations?: RefLabelTranslationJsonldUserReadItem[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export interface RefLabelJsonldUserReadCollection {
  readonly "@context"?: RefLabelJsonldUserReadCollectionContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldUserReadCollection[]
  translations?: RefLabelTranslationJsonldUserReadCollection[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldUserReadCollectionContextOneOfHydra =
  (typeof RefLabelJsonldUserReadCollectionContextOneOfHydra)[keyof typeof RefLabelJsonldUserReadCollectionContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldUserReadCollectionContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldUserReadCollectionContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldUserReadCollectionContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldUserReadCollectionContext =
  | string
  | RefLabelJsonldUserReadCollectionContextOneOf

export type RefLabelJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof RefLabelJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof RefLabelJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadItemSocietyItemContext =
  | string
  | RefLabelJsonldReadItemSocietyItemContextOneOf

export interface RefLabelJsonldReadItemSocietyItem {
  readonly "@context"?: RefLabelJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadItemSocietyItem[]
  translations?: RefLabelTranslationJsonldReadItemSocietyItem[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export interface RefLabelJsonldReadItemRefLabelItem {
  readonly "@context"?: RefLabelJsonldReadItemRefLabelItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadItemRefLabelItem[]
  translations?: RefLabelTranslationJsonldReadItemRefLabelItem[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadItemRefLabelItemContextOneOfHydra =
  (typeof RefLabelJsonldReadItemRefLabelItemContextOneOfHydra)[keyof typeof RefLabelJsonldReadItemRefLabelItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadItemRefLabelItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadItemRefLabelItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadItemRefLabelItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadItemRefLabelItemContext =
  | string
  | RefLabelJsonldReadItemRefLabelItemContextOneOf

export interface RefLabelJsonldReadItemRefLabelGroupItem {
  readonly "@context"?: RefLabelJsonldReadItemRefLabelGroupItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  translations?: RefLabelTranslationJsonldReadItemRefLabelGroupItem[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadItemRefLabelGroupItemContextOneOfHydra =
  (typeof RefLabelJsonldReadItemRefLabelGroupItemContextOneOfHydra)[keyof typeof RefLabelJsonldReadItemRefLabelGroupItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadItemRefLabelGroupItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadItemRefLabelGroupItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadItemRefLabelGroupItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadItemRefLabelGroupItemContext =
  | string
  | RefLabelJsonldReadItemRefLabelGroupItemContextOneOf

export interface RefLabelJsonldReadItemPropertySearch {
  readonly "@context"?: RefLabelJsonldReadItemPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadItemPropertySearch[]
  translations?: RefLabelTranslationJsonldReadItemPropertySearch[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadItemPropertySearchContextOneOfHydra =
  (typeof RefLabelJsonldReadItemPropertySearchContextOneOfHydra)[keyof typeof RefLabelJsonldReadItemPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadItemPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadItemPropertySearchContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadItemPropertySearchContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadItemPropertySearchContext =
  | string
  | RefLabelJsonldReadItemPropertySearchContextOneOf

export type RefLabelJsonldReadItemPropertyOfferContextOneOfHydra =
  (typeof RefLabelJsonldReadItemPropertyOfferContextOneOfHydra)[keyof typeof RefLabelJsonldReadItemPropertyOfferContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadItemPropertyOfferContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadItemPropertyOfferContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadItemPropertyOfferContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadItemPropertyOfferContext =
  | string
  | RefLabelJsonldReadItemPropertyOfferContextOneOf

export interface RefLabelJsonldReadItemPropertyOffer {
  readonly "@context"?: RefLabelJsonldReadItemPropertyOfferContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadItemPropertyOffer[]
  translations?: RefLabelTranslationJsonldReadItemPropertyOffer[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadItemPropertyContextOneOfHydra =
  (typeof RefLabelJsonldReadItemPropertyContextOneOfHydra)[keyof typeof RefLabelJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadItemPropertyContext =
  | string
  | RefLabelJsonldReadItemPropertyContextOneOf

export interface RefLabelJsonldReadItemProperty {
  readonly "@context"?: RefLabelJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadItemProperty[]
  translations?: RefLabelTranslationJsonldReadItemProperty[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadItemProjectContextOneOfHydra =
  (typeof RefLabelJsonldReadItemProjectContextOneOfHydra)[keyof typeof RefLabelJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadItemProjectContext =
  | string
  | RefLabelJsonldReadItemProjectContextOneOf

export interface RefLabelJsonldReadItemProject {
  readonly "@context"?: RefLabelJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  translations?: RefLabelTranslationJsonldReadItemProject[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadItemPeopleContextOneOfHydra =
  (typeof RefLabelJsonldReadItemPeopleContextOneOfHydra)[keyof typeof RefLabelJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadItemPeopleContext = string | RefLabelJsonldReadItemPeopleContextOneOf

export interface RefLabelJsonldReadItemPeople {
  readonly "@context"?: RefLabelJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadItemPeople[]
  translations?: RefLabelTranslationJsonldReadItemPeople[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export interface RefLabelJsonldReadItemCollaboratorItem {
  readonly "@context"?: RefLabelJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadItemCollaboratorItem[]
  translations?: RefLabelTranslationJsonldReadItemCollaboratorItem[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof RefLabelJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof RefLabelJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadItemCollaboratorItemContext =
  | string
  | RefLabelJsonldReadItemCollaboratorItemContextOneOf

export interface RefLabelJsonldReadItemClusterItem {
  readonly "@context"?: RefLabelJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadItemClusterItem[]
  translations?: RefLabelTranslationJsonldReadItemClusterItem[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadItemClusterItemContextOneOfHydra =
  (typeof RefLabelJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof RefLabelJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadItemClusterItemContext =
  | string
  | RefLabelJsonldReadItemClusterItemContextOneOf

export type RefLabelJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof RefLabelJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof RefLabelJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadItemAgencyItemContext =
  | string
  | RefLabelJsonldReadItemAgencyItemContextOneOf

export interface RefLabelJsonldReadItemAgencyItem {
  readonly "@context"?: RefLabelJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadItemAgencyItem[]
  translations?: RefLabelTranslationJsonldReadItemAgencyItem[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadCollectionSocietyExcelExportContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionSocietyExcelExportContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionSocietyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionSocietyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionSocietyExcelExportContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionSocietyExcelExportContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionSocietyExcelExportContext =
  | string
  | RefLabelJsonldReadCollectionSocietyExcelExportContextOneOf

export interface RefLabelJsonldReadCollectionSocietyExcelExport {
  readonly "@context"?: RefLabelJsonldReadCollectionSocietyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export interface RefLabelJsonldReadCollectionSociety {
  readonly "@context"?: RefLabelJsonldReadCollectionSocietyContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  translations?: RefLabelTranslationJsonldReadCollectionSociety[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadCollectionSocietyContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionSocietyContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionSocietyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionSocietyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionSocietyContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionSocietyContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionSocietyContext =
  | string
  | RefLabelJsonldReadCollectionSocietyContextOneOf

export type RefLabelJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionSharedDataReadItemSharedDataContext =
  | string
  | RefLabelJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf

export interface RefLabelJsonldReadCollectionSharedDataReadItemSharedData {
  readonly "@context"?: RefLabelJsonldReadCollectionSharedDataReadItemSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadCollectionSharedDataReadItemSharedData[]
  translations?: RefLabelTranslationJsonldReadCollectionSharedDataReadItemSharedData[]
  label?: string | null
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadCollectionSharedDataContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionSharedDataContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionSharedDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionSharedDataContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionSharedDataContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionSharedDataContext =
  | string
  | RefLabelJsonldReadCollectionSharedDataContextOneOf

export interface RefLabelJsonldReadCollectionSharedData {
  readonly "@context"?: RefLabelJsonldReadCollectionSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadCollectionSharedData[]
  translations?: RefLabelTranslationJsonldReadCollectionSharedData[]
  label?: string | null
  createdAt?: string
  updatedAt?: string
}

export interface RefLabelJsonldReadCollectionRefLabelsExcelExport {
  readonly "@id"?: string
  readonly "@type"?: string
}

export interface RefLabelJsonldReadCollectionRefLabelGroup {
  readonly "@context"?: RefLabelJsonldReadCollectionRefLabelGroupContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  translations?: RefLabelTranslationJsonldReadCollectionRefLabelGroup[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadCollectionRefLabelGroupContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionRefLabelGroupContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionRefLabelGroupContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionRefLabelGroupContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionRefLabelGroupContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionRefLabelGroupContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionRefLabelGroupContext =
  | string
  | RefLabelJsonldReadCollectionRefLabelGroupContextOneOf

export interface RefLabelJsonldReadCollectionRefLabel {
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadCollectionRefLabel[]
  translations?: RefLabelTranslationJsonldReadCollectionRefLabel[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export interface RefLabelJsonldReadCollectionPropertySearchExcelExport {
  readonly "@context"?: RefLabelJsonldReadCollectionPropertySearchExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadCollectionPropertySearchExcelExport[]
  translations?: RefLabelTranslationJsonldReadCollectionPropertySearchExcelExport[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionPropertySearchExcelExportContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionPropertySearchExcelExportContext =
  | string
  | RefLabelJsonldReadCollectionPropertySearchExcelExportContextOneOf

export type RefLabelJsonldReadCollectionPropertySearchContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionPropertySearchContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionPropertySearchContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionPropertySearchContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionPropertySearchContext =
  | string
  | RefLabelJsonldReadCollectionPropertySearchContextOneOf

export interface RefLabelJsonldReadCollectionPropertySearch {
  readonly "@context"?: RefLabelJsonldReadCollectionPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadCollectionPropertySearch[]
  translations?: RefLabelTranslationJsonldReadCollectionPropertySearch[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionPropertyOfferExcelExportContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionPropertyOfferExcelExportContext =
  | string
  | RefLabelJsonldReadCollectionPropertyOfferExcelExportContextOneOf

export interface RefLabelJsonldReadCollectionPropertyOfferExcelExport {
  readonly "@context"?: RefLabelJsonldReadCollectionPropertyOfferExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadCollectionPropertyOfferExcelExport[]
  translations?: RefLabelTranslationJsonldReadCollectionPropertyOfferExcelExport[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadCollectionPropertyOfferContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionPropertyOfferContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionPropertyOfferContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionPropertyOfferContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionPropertyOfferContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionPropertyOfferContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionPropertyOfferContext =
  | string
  | RefLabelJsonldReadCollectionPropertyOfferContextOneOf

export interface RefLabelJsonldReadCollectionPropertyOffer {
  readonly "@context"?: RefLabelJsonldReadCollectionPropertyOfferContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadCollectionPropertyOffer[]
  translations?: RefLabelTranslationJsonldReadCollectionPropertyOffer[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadCollectionPropertyExcelExportContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionPropertyExcelExportContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionPropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionPropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionPropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionPropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionPropertyExcelExportContext =
  | string
  | RefLabelJsonldReadCollectionPropertyExcelExportContextOneOf

export interface RefLabelJsonldReadCollectionPropertyExcelExport {
  readonly "@context"?: RefLabelJsonldReadCollectionPropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export interface RefLabelJsonldReadCollectionProperty {
  readonly "@context"?: RefLabelJsonldReadCollectionPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadCollectionProperty[]
  translations?: RefLabelTranslationJsonldReadCollectionProperty[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadCollectionPropertyContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionPropertyContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionPropertyContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionPropertyContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionPropertyContext =
  | string
  | RefLabelJsonldReadCollectionPropertyContextOneOf

export interface RefLabelJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: RefLabelJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  translations?: RefLabelTranslationJsonldReadCollectionProjectExcelExport[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionProjectExcelExportContext =
  | string
  | RefLabelJsonldReadCollectionProjectExcelExportContextOneOf

export type RefLabelJsonldReadCollectionProjectContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionProjectContext =
  | string
  | RefLabelJsonldReadCollectionProjectContextOneOf

export interface RefLabelJsonldReadCollectionProject {
  readonly "@context"?: RefLabelJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  translations?: RefLabelTranslationJsonldReadCollectionProject[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionPeopleExcelExportContext =
  | string
  | RefLabelJsonldReadCollectionPeopleExcelExportContextOneOf

export interface RefLabelJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: RefLabelJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadCollectionPeopleExcelExport[]
  translations?: RefLabelTranslationJsonldReadCollectionPeopleExcelExport[]
  label?: string | null
  readonly id: string
  updatedAt?: string
}

export interface RefLabelJsonldReadCollectionPeople {
  readonly "@context"?: RefLabelJsonldReadCollectionPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: RefLabelGroupRelationJsonldReadCollectionPeople[]
  translations?: RefLabelTranslationJsonldReadCollectionPeople[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionPeopleContext =
  | string
  | RefLabelJsonldReadCollectionPeopleContextOneOf

export type RefLabelJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionCollaboratorExcelExportContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionCollaboratorExcelExportContext =
  | string
  | RefLabelJsonldReadCollectionCollaboratorExcelExportContextOneOf

export interface RefLabelJsonldReadCollectionCollaboratorExcelExport {
  readonly "@context"?: RefLabelJsonldReadCollectionCollaboratorExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export interface RefLabelJsonldReadCollectionCollaborator {
  readonly "@context"?: RefLabelJsonldReadCollectionCollaboratorContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  translations?: RefLabelTranslationJsonldReadCollectionCollaborator[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadCollectionCollaboratorContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionCollaboratorContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionCollaboratorContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionCollaboratorContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionCollaboratorContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionCollaboratorContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionCollaboratorContext =
  | string
  | RefLabelJsonldReadCollectionCollaboratorContextOneOf

export type RefLabelJsonldReadCollectionClusterExcelExportContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionClusterExcelExportContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionClusterExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionClusterExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionClusterExcelExportContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionClusterExcelExportContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionClusterExcelExportContext =
  | string
  | RefLabelJsonldReadCollectionClusterExcelExportContextOneOf

export interface RefLabelJsonldReadCollectionClusterExcelExport {
  readonly "@context"?: RefLabelJsonldReadCollectionClusterExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export interface RefLabelJsonldReadCollectionCluster {
  readonly "@context"?: RefLabelJsonldReadCollectionClusterContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  translations?: RefLabelTranslationJsonldReadCollectionCluster[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadCollectionClusterContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionClusterContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionClusterContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionClusterContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionClusterContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionClusterContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionClusterContext =
  | string
  | RefLabelJsonldReadCollectionClusterContextOneOf

export interface RefLabelJsonldReadCollectionAgencyExcelExport {
  readonly "@context"?: RefLabelJsonldReadCollectionAgencyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadCollectionAgencyExcelExportContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionAgencyExcelExportContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionAgencyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionAgencyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionAgencyExcelExportContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionAgencyExcelExportContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionAgencyExcelExportContext =
  | string
  | RefLabelJsonldReadCollectionAgencyExcelExportContextOneOf

export interface RefLabelJsonldReadCollectionAgency {
  readonly "@context"?: RefLabelJsonldReadCollectionAgencyContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  translations?: RefLabelTranslationJsonldReadCollectionAgency[]
  label?: string | null
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export type RefLabelJsonldReadCollectionAgencyContextOneOfHydra =
  (typeof RefLabelJsonldReadCollectionAgencyContextOneOfHydra)[keyof typeof RefLabelJsonldReadCollectionAgencyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldReadCollectionAgencyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldReadCollectionAgencyContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldReadCollectionAgencyContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldReadCollectionAgencyContext =
  | string
  | RefLabelJsonldReadCollectionAgencyContextOneOf

export type RefLabelJsonldContextOneOfHydra =
  (typeof RefLabelJsonldContextOneOfHydra)[keyof typeof RefLabelJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RefLabelJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type RefLabelJsonldContextOneOf = {
  "@vocab": string
  hydra: RefLabelJsonldContextOneOfHydra
  [key: string]: any
}

export type RefLabelJsonldContext = string | RefLabelJsonldContextOneOf

export interface RefLabelJsonld {
  readonly "@context"?: RefLabelJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  order?: number | null
  canEdit?: boolean
  refLabelGroupRelation?: string[]
  translations?: RefLabelTranslationJsonld[]
  label?: string | null
  peopleLocale?: PeopleLocaleJsonld[]
  clusterType?: string[]
  readonly id: string
  createdAt?: string
  updatedAt?: string
}

export interface RefLabelPostRefLabelInputJsonld {
  code?: string | null
  value?: string | null
  order?: string | null
  refLabelGroupRelation?: RefLabelGroupRelationDTOJsonld[] | null
  translations?: TranslationsInputDTOJsonld[] | null
}

export interface RefLabelPatchRefLabelInput {
  code?: string | null
  value?: string | null
  order?: string | null
  refLabelGroupRelation?: RefLabelGroupRelationDTO[] | null
  translations?: TranslationsInputDTO[] | null
}

export type PropertyTagJsonldReadItemPropertySearchContextOneOfHydra =
  (typeof PropertyTagJsonldReadItemPropertySearchContextOneOfHydra)[keyof typeof PropertyTagJsonldReadItemPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyTagJsonldReadItemPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyTagJsonldReadItemPropertySearchContextOneOf = {
  "@vocab": string
  hydra: PropertyTagJsonldReadItemPropertySearchContextOneOfHydra
  [key: string]: any
}

export type PropertyTagJsonldReadItemPropertySearchContext =
  | string
  | PropertyTagJsonldReadItemPropertySearchContextOneOf

export interface PropertyTagJsonldReadItemPropertySearch {
  readonly "@context"?: PropertyTagJsonldReadItemPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyTagJsonldReadItemPropertyContextOneOfHydra =
  (typeof PropertyTagJsonldReadItemPropertyContextOneOfHydra)[keyof typeof PropertyTagJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyTagJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyTagJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: PropertyTagJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type PropertyTagJsonldReadItemPropertyContext =
  | string
  | PropertyTagJsonldReadItemPropertyContextOneOf

export interface PropertyTagJsonldReadItemProperty {
  readonly "@context"?: PropertyTagJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface PropertyTagJsonldReadItemProject {
  readonly "@context"?: PropertyTagJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyTagJsonldReadItemProjectContextOneOfHydra =
  (typeof PropertyTagJsonldReadItemProjectContextOneOfHydra)[keyof typeof PropertyTagJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyTagJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyTagJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: PropertyTagJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type PropertyTagJsonldReadItemProjectContext =
  | string
  | PropertyTagJsonldReadItemProjectContextOneOf

export interface PropertyTagJsonldReadCollectionPropertySearchExcelExport {
  readonly "@context"?: PropertyTagJsonldReadCollectionPropertySearchExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyTagJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra =
  (typeof PropertyTagJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra)[keyof typeof PropertyTagJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyTagJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyTagJsonldReadCollectionPropertySearchExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyTagJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyTagJsonldReadCollectionPropertySearchExcelExportContext =
  | string
  | PropertyTagJsonldReadCollectionPropertySearchExcelExportContextOneOf

export type PropertyTagJsonldReadCollectionPropertySearchContextOneOfHydra =
  (typeof PropertyTagJsonldReadCollectionPropertySearchContextOneOfHydra)[keyof typeof PropertyTagJsonldReadCollectionPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyTagJsonldReadCollectionPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyTagJsonldReadCollectionPropertySearchContextOneOf = {
  "@vocab": string
  hydra: PropertyTagJsonldReadCollectionPropertySearchContextOneOfHydra
  [key: string]: any
}

export type PropertyTagJsonldReadCollectionPropertySearchContext =
  | string
  | PropertyTagJsonldReadCollectionPropertySearchContextOneOf

export interface PropertyTagJsonldReadCollectionPropertySearch {
  readonly "@context"?: PropertyTagJsonldReadCollectionPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyTagJsonldReadCollectionPropertyExcelExportContextOneOfHydra =
  (typeof PropertyTagJsonldReadCollectionPropertyExcelExportContextOneOfHydra)[keyof typeof PropertyTagJsonldReadCollectionPropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyTagJsonldReadCollectionPropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyTagJsonldReadCollectionPropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyTagJsonldReadCollectionPropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyTagJsonldReadCollectionPropertyExcelExportContext =
  | string
  | PropertyTagJsonldReadCollectionPropertyExcelExportContextOneOf

export interface PropertyTagJsonldReadCollectionPropertyExcelExport {
  readonly "@context"?: PropertyTagJsonldReadCollectionPropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface PropertyTagJsonldReadCollectionProperty {
  readonly "@context"?: PropertyTagJsonldReadCollectionPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  value?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyTagJsonldReadCollectionPropertyContextOneOfHydra =
  (typeof PropertyTagJsonldReadCollectionPropertyContextOneOfHydra)[keyof typeof PropertyTagJsonldReadCollectionPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyTagJsonldReadCollectionPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyTagJsonldReadCollectionPropertyContextOneOf = {
  "@vocab": string
  hydra: PropertyTagJsonldReadCollectionPropertyContextOneOfHydra
  [key: string]: any
}

export type PropertyTagJsonldReadCollectionPropertyContext =
  | string
  | PropertyTagJsonldReadCollectionPropertyContextOneOf

export type PropertyTagJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof PropertyTagJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof PropertyTagJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyTagJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyTagJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyTagJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyTagJsonldReadCollectionProjectExcelExportContext =
  | string
  | PropertyTagJsonldReadCollectionProjectExcelExportContextOneOf

export interface PropertyTagJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: PropertyTagJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface PropertyTagJsonldReadCollectionProject {
  readonly "@context"?: PropertyTagJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyTagJsonldReadCollectionProjectContextOneOfHydra =
  (typeof PropertyTagJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof PropertyTagJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyTagJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyTagJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: PropertyTagJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type PropertyTagJsonldReadCollectionProjectContext =
  | string
  | PropertyTagJsonldReadCollectionProjectContextOneOf

export type PropertySearchPropertyAreaJsonldReadItemPropertySearchContextOneOf = {
  "@vocab": string
  hydra: PropertySearchPropertyAreaJsonldReadItemPropertySearchContextOneOfHydra
  [key: string]: any
}

export type PropertySearchPropertyAreaJsonldReadItemPropertySearchContext =
  | string
  | PropertySearchPropertyAreaJsonldReadItemPropertySearchContextOneOf

export interface PropertySearchPropertyAreaJsonldReadItemPropertySearch {
  readonly "@context"?: PropertySearchPropertyAreaJsonldReadItemPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number | null
  propertySearch?: PropertySearchJsonldReadItemPropertySearch
  propertyArea?: PropertyAreaJsonldReadItemPropertySearch
  readonly id: string
}

export type PropertySearchPropertyAreaJsonldReadItemPropertySearchContextOneOfHydra =
  (typeof PropertySearchPropertyAreaJsonldReadItemPropertySearchContextOneOfHydra)[keyof typeof PropertySearchPropertyAreaJsonldReadItemPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertySearchPropertyAreaJsonldReadItemPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export interface PropertySearchPropertyAreaJsonldReadItemProject {
  readonly "@context"?: PropertySearchPropertyAreaJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number | null
  propertyArea?: PropertyAreaJsonldReadItemProject
  readonly id: string
}

export type PropertySearchPropertyAreaJsonldReadItemProjectContextOneOfHydra =
  (typeof PropertySearchPropertyAreaJsonldReadItemProjectContextOneOfHydra)[keyof typeof PropertySearchPropertyAreaJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertySearchPropertyAreaJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertySearchPropertyAreaJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: PropertySearchPropertyAreaJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type PropertySearchPropertyAreaJsonldReadItemProjectContext =
  | string
  | PropertySearchPropertyAreaJsonldReadItemProjectContextOneOf

export interface PropertySearchPropertyAreaJsonldReadCollectionPropertySearchExcelExport {
  readonly "@context"?: PropertySearchPropertyAreaJsonldReadCollectionPropertySearchExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number | null
  propertySearch?: PropertySearchJsonldReadCollectionPropertySearchExcelExport
  propertyArea?: PropertyAreaJsonldReadCollectionPropertySearchExcelExport
  readonly id: string
}

export type PropertySearchPropertyAreaJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra =
  (typeof PropertySearchPropertyAreaJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra)[keyof typeof PropertySearchPropertyAreaJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertySearchPropertyAreaJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra =
  {
    httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
  } as const

export type PropertySearchPropertyAreaJsonldReadCollectionPropertySearchExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertySearchPropertyAreaJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertySearchPropertyAreaJsonldReadCollectionPropertySearchExcelExportContext =
  | string
  | PropertySearchPropertyAreaJsonldReadCollectionPropertySearchExcelExportContextOneOf

export type PropertySearchPropertyAreaJsonldReadCollectionPropertySearchContextOneOfHydra =
  (typeof PropertySearchPropertyAreaJsonldReadCollectionPropertySearchContextOneOfHydra)[keyof typeof PropertySearchPropertyAreaJsonldReadCollectionPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertySearchPropertyAreaJsonldReadCollectionPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertySearchPropertyAreaJsonldReadCollectionPropertySearchContextOneOf = {
  "@vocab": string
  hydra: PropertySearchPropertyAreaJsonldReadCollectionPropertySearchContextOneOfHydra
  [key: string]: any
}

export type PropertySearchPropertyAreaJsonldReadCollectionPropertySearchContext =
  | string
  | PropertySearchPropertyAreaJsonldReadCollectionPropertySearchContextOneOf

export interface PropertySearchPropertyAreaJsonldReadCollectionPropertySearch {
  readonly "@context"?: PropertySearchPropertyAreaJsonldReadCollectionPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number | null
  propertySearch?: PropertySearchJsonldReadCollectionPropertySearch
  propertyArea?: PropertyAreaJsonldReadCollectionPropertySearch
  readonly id: string
}

export type PropertySearchPropertyAreaJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof PropertySearchPropertyAreaJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof PropertySearchPropertyAreaJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertySearchPropertyAreaJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertySearchPropertyAreaJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertySearchPropertyAreaJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertySearchPropertyAreaJsonldReadCollectionProjectExcelExportContext =
  | string
  | PropertySearchPropertyAreaJsonldReadCollectionProjectExcelExportContextOneOf

export interface PropertySearchPropertyAreaJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: PropertySearchPropertyAreaJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number | null
  propertyArea?: PropertyAreaJsonldReadCollectionProjectExcelExport
  readonly id: string
}

export type PropertySearchPropertyAreaJsonldReadCollectionProjectContextOneOfHydra =
  (typeof PropertySearchPropertyAreaJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof PropertySearchPropertyAreaJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertySearchPropertyAreaJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertySearchPropertyAreaJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: PropertySearchPropertyAreaJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type PropertySearchPropertyAreaJsonldReadCollectionProjectContext =
  | string
  | PropertySearchPropertyAreaJsonldReadCollectionProjectContextOneOf

export interface PropertySearchPropertyAreaJsonldReadCollectionProject {
  readonly "@context"?: PropertySearchPropertyAreaJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number | null
  propertyArea?: PropertyAreaJsonldReadCollectionProject
  readonly id: string
}

export type PropertySearchJsonldReadItemPropertySearchViewQuality =
  RefLabelJsonldReadItemPropertySearch | null

export type PropertySearchJsonldReadItemPropertySearchSubtype =
  RefLabelJsonldReadItemPropertySearch | null

export type PropertySearchJsonldReadItemPropertySearchStatus =
  RefLabelJsonldReadItemPropertySearch | null

export type PropertySearchJsonldReadItemPropertySearchStanding =
  RefLabelJsonldReadItemPropertySearch | null

export type PropertySearchJsonldReadItemPropertySearchPriceCurrency =
  RefLabelJsonldReadItemPropertySearch | null

export type PropertySearchJsonldReadItemPropertySearchFloorName =
  RefLabelJsonldReadItemPropertySearch | null

export type PropertySearchJsonldReadItemPropertySearchCondition =
  RefLabelJsonldReadItemPropertySearch | null

export type PropertySearchJsonldReadItemPropertySearchAvailibilityStatus =
  RefLabelJsonldReadItemPropertySearch | null

export type PropertySearchJsonldReadItemPropertySearchAreaUnit =
  RefLabelJsonldReadItemPropertySearch | null

export type PropertySearchJsonldReadItemPropertySearchContextOneOfHydra =
  (typeof PropertySearchJsonldReadItemPropertySearchContextOneOfHydra)[keyof typeof PropertySearchJsonldReadItemPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertySearchJsonldReadItemPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertySearchJsonldReadItemPropertySearchContextOneOf = {
  "@vocab": string
  hydra: PropertySearchJsonldReadItemPropertySearchContextOneOfHydra
  [key: string]: any
}

export type PropertySearchJsonldReadItemPropertySearchContext =
  | string
  | PropertySearchJsonldReadItemPropertySearchContextOneOf

export interface PropertySearchJsonldReadItemPropertySearch {
  readonly "@context"?: PropertySearchJsonldReadItemPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  areaMax?: number | null
  areaMin?: number | null
  areaUnit?: PropertySearchJsonldReadItemPropertySearchAreaUnit
  availibilityStatus?: PropertySearchJsonldReadItemPropertySearchAvailibilityStatus
  condition?: PropertySearchJsonldReadItemPropertySearchCondition
  floor?: number | null
  floorMax?: number | null
  floorMin?: number | null
  floorName?: PropertySearchJsonldReadItemPropertySearchFloorName
  marginEstimated?: number | null
  nbBedroomsMax?: number | null
  nbBedroomsMin?: number | null
  nbRoomsMax?: number | null
  nbRoomsMin?: number | null
  priceCurrency?: PropertySearchJsonldReadItemPropertySearchPriceCurrency
  priceMax?: number | null
  priceMin?: number | null
  standing?: PropertySearchJsonldReadItemPropertySearchStanding
  status?: PropertySearchJsonldReadItemPropertySearchStatus
  subtype?: PropertySearchJsonldReadItemPropertySearchSubtype
  viewQuality?: PropertySearchJsonldReadItemPropertySearchViewQuality
  projectPropertySearch?: ProjectPropertySearchJsonldReadItemPropertySearch[]
  activities?: RefLabelJsonldReadItemPropertySearch[]
  contactDetails?: ContactDetailsJsonldReadItemPropertySearch[]
  i18nText?: I18nTextJsonldReadItemPropertySearch[]
  propertyArea?: PropertySearchPropertyAreaJsonldReadItemPropertySearch[]
  proximities?: RefLabelJsonldReadItemPropertySearch[]
  services?: RefLabelJsonldReadItemPropertySearch[]
  tag?: PropertyTagJsonldReadItemPropertySearch[]
  viewLandscapes?: RefLabelJsonldReadItemPropertySearch[]
  types?: RefLabelJsonldReadItemPropertySearch[]
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertySearchJsonldReadItemProjectViewQuality = RefLabelJsonldReadItemProject | null

export type PropertySearchJsonldReadItemProjectSubtype = RefLabelJsonldReadItemProject | null

export type PropertySearchJsonldReadItemProjectStatus = RefLabelJsonldReadItemProject | null

export type PropertySearchJsonldReadItemProjectStanding = RefLabelJsonldReadItemProject | null

export type PropertySearchJsonldReadItemProjectPriceCurrency = RefLabelJsonldReadItemProject | null

export type PropertySearchJsonldReadItemProjectFloorName = RefLabelJsonldReadItemProject | null

export type PropertySearchJsonldReadItemProjectCondition = RefLabelJsonldReadItemProject | null

export type PropertySearchJsonldReadItemProjectAvailibilityStatus =
  RefLabelJsonldReadItemProject | null

export type PropertySearchJsonldReadItemProjectAreaUnit = RefLabelJsonldReadItemProject | null

export type PropertySearchJsonldReadItemProjectContextOneOfHydra =
  (typeof PropertySearchJsonldReadItemProjectContextOneOfHydra)[keyof typeof PropertySearchJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertySearchJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertySearchJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: PropertySearchJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type PropertySearchJsonldReadItemProjectContext =
  | string
  | PropertySearchJsonldReadItemProjectContextOneOf

export interface PropertySearchJsonldReadItemProject {
  readonly "@context"?: PropertySearchJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  areaMax?: number | null
  areaMin?: number | null
  areaUnit?: PropertySearchJsonldReadItemProjectAreaUnit
  availibilityStatus?: PropertySearchJsonldReadItemProjectAvailibilityStatus
  condition?: PropertySearchJsonldReadItemProjectCondition
  floor?: number | null
  floorMax?: number | null
  floorMin?: number | null
  floorName?: PropertySearchJsonldReadItemProjectFloorName
  marginEstimated?: number | null
  nbBedroomsMax?: number | null
  nbBedroomsMin?: number | null
  nbRoomsMax?: number | null
  nbRoomsMin?: number | null
  priceCurrency?: PropertySearchJsonldReadItemProjectPriceCurrency
  priceMax?: number | null
  priceMin?: number | null
  standing?: PropertySearchJsonldReadItemProjectStanding
  status?: PropertySearchJsonldReadItemProjectStatus
  subtype?: PropertySearchJsonldReadItemProjectSubtype
  viewQuality?: PropertySearchJsonldReadItemProjectViewQuality
  activities?: RefLabelJsonldReadItemProject[]
  contactDetails?: ContactDetailsJsonldReadItemProject[]
  i18nText?: I18nTextJsonldReadItemProject[]
  propertyArea?: PropertySearchPropertyAreaJsonldReadItemProject[]
  proximities?: RefLabelJsonldReadItemProject[]
  services?: RefLabelJsonldReadItemProject[]
  tag?: PropertyTagJsonldReadItemProject[]
  viewLandscapes?: RefLabelJsonldReadItemProject[]
  types?: RefLabelJsonldReadItemProject[]
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertySearchJsonldReadItemClusterItemStatus = RefLabelJsonldReadItemClusterItem | null

export interface PropertySearchJsonldReadItemClusterItem {
  readonly "@context"?: PropertySearchJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  status?: PropertySearchJsonldReadItemClusterItemStatus
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertySearchJsonldReadItemClusterItemContextOneOfHydra =
  (typeof PropertySearchJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof PropertySearchJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertySearchJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertySearchJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: PropertySearchJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type PropertySearchJsonldReadItemClusterItemContext =
  | string
  | PropertySearchJsonldReadItemClusterItemContextOneOf

export type PropertySearchJsonldReadCollectionPropertySearchExcelExportViewQuality =
  RefLabelJsonldReadCollectionPropertySearchExcelExport | null

export type PropertySearchJsonldReadCollectionPropertySearchExcelExportSubtype =
  RefLabelJsonldReadCollectionPropertySearchExcelExport | null

export type PropertySearchJsonldReadCollectionPropertySearchExcelExportStatus =
  RefLabelJsonldReadCollectionPropertySearchExcelExport | null

export type PropertySearchJsonldReadCollectionPropertySearchExcelExportStanding =
  RefLabelJsonldReadCollectionPropertySearchExcelExport | null

export type PropertySearchJsonldReadCollectionPropertySearchExcelExportPriceCurrency =
  RefLabelJsonldReadCollectionPropertySearchExcelExport | null

export type PropertySearchJsonldReadCollectionPropertySearchExcelExportFloorName =
  RefLabelJsonldReadCollectionPropertySearchExcelExport | null

export type PropertySearchJsonldReadCollectionPropertySearchExcelExportCondition =
  RefLabelJsonldReadCollectionPropertySearchExcelExport | null

export type PropertySearchJsonldReadCollectionPropertySearchExcelExportAvailibilityStatus =
  RefLabelJsonldReadCollectionPropertySearchExcelExport | null

export type PropertySearchJsonldReadCollectionPropertySearchExcelExportAreaUnit =
  RefLabelJsonldReadCollectionPropertySearchExcelExport | null

export interface PropertySearchJsonldReadCollectionPropertySearchExcelExport {
  readonly "@id"?: string
  readonly "@type"?: string
  readonly "@context"?: PropertySearchJsonldReadCollectionPropertySearchExcelExportContext
  areaMax?: number | null
  areaMin?: number | null
  areaUnit?: PropertySearchJsonldReadCollectionPropertySearchExcelExportAreaUnit
  availibilityStatus?: PropertySearchJsonldReadCollectionPropertySearchExcelExportAvailibilityStatus
  condition?: PropertySearchJsonldReadCollectionPropertySearchExcelExportCondition
  floor?: number | null
  floorMax?: number | null
  floorMin?: number | null
  floorName?: PropertySearchJsonldReadCollectionPropertySearchExcelExportFloorName
  marginEstimated?: number | null
  nbBedroomsMax?: number | null
  nbBedroomsMin?: number | null
  nbRoomsMax?: number | null
  nbRoomsMin?: number | null
  priceCurrency?: PropertySearchJsonldReadCollectionPropertySearchExcelExportPriceCurrency
  priceMax?: number | null
  priceMin?: number | null
  standing?: PropertySearchJsonldReadCollectionPropertySearchExcelExportStanding
  status?: PropertySearchJsonldReadCollectionPropertySearchExcelExportStatus
  subtype?: PropertySearchJsonldReadCollectionPropertySearchExcelExportSubtype
  viewQuality?: PropertySearchJsonldReadCollectionPropertySearchExcelExportViewQuality
  projectPropertySearch?: ProjectPropertySearchJsonldReadCollectionPropertySearchExcelExport[]
  activities?: RefLabelJsonldReadCollectionPropertySearchExcelExport[]
  contactDetails?: ContactDetailsJsonldReadCollectionPropertySearchExcelExport[]
  i18nText?: I18nTextJsonldReadCollectionPropertySearchExcelExport[]
  propertyArea?: PropertySearchPropertyAreaJsonldReadCollectionPropertySearchExcelExport[]
  proximities?: RefLabelJsonldReadCollectionPropertySearchExcelExport[]
  services?: RefLabelJsonldReadCollectionPropertySearchExcelExport[]
  tag?: PropertyTagJsonldReadCollectionPropertySearchExcelExport[]
  viewLandscapes?: RefLabelJsonldReadCollectionPropertySearchExcelExport[]
  types?: RefLabelJsonldReadCollectionPropertySearchExcelExport[]
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertySearchJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra =
  (typeof PropertySearchJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra)[keyof typeof PropertySearchJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertySearchJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertySearchJsonldReadCollectionPropertySearchExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertySearchJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertySearchJsonldReadCollectionPropertySearchExcelExportContext =
  | string
  | PropertySearchJsonldReadCollectionPropertySearchExcelExportContextOneOf

export type PropertySearchJsonldReadCollectionPropertySearchViewQuality =
  RefLabelJsonldReadCollectionPropertySearch | null

export type PropertySearchJsonldReadCollectionPropertySearchSubtype =
  RefLabelJsonldReadCollectionPropertySearch | null

export type PropertySearchJsonldReadCollectionPropertySearchStatus =
  RefLabelJsonldReadCollectionPropertySearch | null

export type PropertySearchJsonldReadCollectionPropertySearchStanding =
  RefLabelJsonldReadCollectionPropertySearch | null

export type PropertySearchJsonldReadCollectionPropertySearchPriceCurrency =
  RefLabelJsonldReadCollectionPropertySearch | null

export type PropertySearchJsonldReadCollectionPropertySearchFloorName =
  RefLabelJsonldReadCollectionPropertySearch | null

export type PropertySearchJsonldReadCollectionPropertySearchCondition =
  RefLabelJsonldReadCollectionPropertySearch | null

export type PropertySearchJsonldReadCollectionPropertySearchAvailibilityStatus =
  RefLabelJsonldReadCollectionPropertySearch | null

export type PropertySearchJsonldReadCollectionPropertySearchAreaUnit =
  RefLabelJsonldReadCollectionPropertySearch | null

export interface PropertySearchJsonldReadCollectionPropertySearch {
  readonly "@id"?: string
  readonly "@type"?: string
  readonly "@context"?: PropertySearchJsonldReadCollectionPropertySearchContext
  areaMax?: number | null
  areaMin?: number | null
  areaUnit?: PropertySearchJsonldReadCollectionPropertySearchAreaUnit
  availibilityStatus?: PropertySearchJsonldReadCollectionPropertySearchAvailibilityStatus
  condition?: PropertySearchJsonldReadCollectionPropertySearchCondition
  floor?: number | null
  floorMax?: number | null
  floorMin?: number | null
  floorName?: PropertySearchJsonldReadCollectionPropertySearchFloorName
  marginEstimated?: number | null
  nbBedroomsMax?: number | null
  nbBedroomsMin?: number | null
  nbRoomsMax?: number | null
  nbRoomsMin?: number | null
  priceCurrency?: PropertySearchJsonldReadCollectionPropertySearchPriceCurrency
  priceMax?: number | null
  priceMin?: number | null
  standing?: PropertySearchJsonldReadCollectionPropertySearchStanding
  status?: PropertySearchJsonldReadCollectionPropertySearchStatus
  subtype?: PropertySearchJsonldReadCollectionPropertySearchSubtype
  viewQuality?: PropertySearchJsonldReadCollectionPropertySearchViewQuality
  projectPropertySearch?: ProjectPropertySearchJsonldReadCollectionPropertySearch[]
  activities?: RefLabelJsonldReadCollectionPropertySearch[]
  contactDetails?: ContactDetailsJsonldReadCollectionPropertySearch[]
  i18nText?: I18nTextJsonldReadCollectionPropertySearch[]
  propertyArea?: PropertySearchPropertyAreaJsonldReadCollectionPropertySearch[]
  proximities?: RefLabelJsonldReadCollectionPropertySearch[]
  services?: RefLabelJsonldReadCollectionPropertySearch[]
  tag?: PropertyTagJsonldReadCollectionPropertySearch[]
  viewLandscapes?: RefLabelJsonldReadCollectionPropertySearch[]
  types?: RefLabelJsonldReadCollectionPropertySearch[]
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertySearchJsonldReadCollectionPropertySearchContextOneOfHydra =
  (typeof PropertySearchJsonldReadCollectionPropertySearchContextOneOfHydra)[keyof typeof PropertySearchJsonldReadCollectionPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertySearchJsonldReadCollectionPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertySearchJsonldReadCollectionPropertySearchContextOneOf = {
  "@vocab": string
  hydra: PropertySearchJsonldReadCollectionPropertySearchContextOneOfHydra
  [key: string]: any
}

export type PropertySearchJsonldReadCollectionPropertySearchContext =
  | string
  | PropertySearchJsonldReadCollectionPropertySearchContextOneOf

export type PropertySearchJsonldReadCollectionProjectExcelExportViewQuality =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertySearchJsonldReadCollectionProjectExcelExportSubtype =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertySearchJsonldReadCollectionProjectExcelExportStatus =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertySearchJsonldReadCollectionProjectExcelExportStanding =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertySearchJsonldReadCollectionProjectExcelExportPriceCurrency =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertySearchJsonldReadCollectionProjectExcelExportFloorName =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertySearchJsonldReadCollectionProjectExcelExportCondition =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertySearchJsonldReadCollectionProjectExcelExportAvailibilityStatus =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertySearchJsonldReadCollectionProjectExcelExportAreaUnit =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertySearchJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof PropertySearchJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof PropertySearchJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertySearchJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertySearchJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertySearchJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertySearchJsonldReadCollectionProjectExcelExportContext =
  | string
  | PropertySearchJsonldReadCollectionProjectExcelExportContextOneOf

export interface PropertySearchJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: PropertySearchJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  areaMax?: number | null
  areaMin?: number | null
  areaUnit?: PropertySearchJsonldReadCollectionProjectExcelExportAreaUnit
  availibilityStatus?: PropertySearchJsonldReadCollectionProjectExcelExportAvailibilityStatus
  condition?: PropertySearchJsonldReadCollectionProjectExcelExportCondition
  floor?: number | null
  floorMax?: number | null
  floorMin?: number | null
  floorName?: PropertySearchJsonldReadCollectionProjectExcelExportFloorName
  marginEstimated?: number | null
  nbBedroomsMax?: number | null
  nbBedroomsMin?: number | null
  nbRoomsMax?: number | null
  nbRoomsMin?: number | null
  priceCurrency?: PropertySearchJsonldReadCollectionProjectExcelExportPriceCurrency
  priceMax?: number | null
  priceMin?: number | null
  standing?: PropertySearchJsonldReadCollectionProjectExcelExportStanding
  status?: PropertySearchJsonldReadCollectionProjectExcelExportStatus
  subtype?: PropertySearchJsonldReadCollectionProjectExcelExportSubtype
  viewQuality?: PropertySearchJsonldReadCollectionProjectExcelExportViewQuality
  activities?: RefLabelJsonldReadCollectionProjectExcelExport[]
  contactDetails?: ContactDetailsJsonldReadCollectionProjectExcelExport[]
  i18nText?: I18nTextJsonldReadCollectionProjectExcelExport[]
  propertyArea?: PropertySearchPropertyAreaJsonldReadCollectionProjectExcelExport[]
  proximities?: RefLabelJsonldReadCollectionProjectExcelExport[]
  services?: RefLabelJsonldReadCollectionProjectExcelExport[]
  tag?: PropertyTagJsonldReadCollectionProjectExcelExport[]
  viewLandscapes?: RefLabelJsonldReadCollectionProjectExcelExport[]
  types?: RefLabelJsonldReadCollectionProjectExcelExport[]
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertySearchJsonldReadCollectionProjectViewQuality =
  RefLabelJsonldReadCollectionProject | null

export type PropertySearchJsonldReadCollectionProjectSubtype =
  RefLabelJsonldReadCollectionProject | null

export type PropertySearchJsonldReadCollectionProjectStatus =
  RefLabelJsonldReadCollectionProject | null

export type PropertySearchJsonldReadCollectionProjectStanding =
  RefLabelJsonldReadCollectionProject | null

export type PropertySearchJsonldReadCollectionProjectPriceCurrency =
  RefLabelJsonldReadCollectionProject | null

export type PropertySearchJsonldReadCollectionProjectFloorName =
  RefLabelJsonldReadCollectionProject | null

export type PropertySearchJsonldReadCollectionProjectCondition =
  RefLabelJsonldReadCollectionProject | null

export type PropertySearchJsonldReadCollectionProjectAvailibilityStatus =
  RefLabelJsonldReadCollectionProject | null

export type PropertySearchJsonldReadCollectionProjectAreaUnit =
  RefLabelJsonldReadCollectionProject | null

export type PropertySearchJsonldReadCollectionProjectContextOneOfHydra =
  (typeof PropertySearchJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof PropertySearchJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertySearchJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertySearchJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: PropertySearchJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type PropertySearchJsonldReadCollectionProjectContext =
  | string
  | PropertySearchJsonldReadCollectionProjectContextOneOf

export interface PropertySearchJsonldReadCollectionProject {
  readonly "@context"?: PropertySearchJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  areaMax?: number | null
  areaMin?: number | null
  areaUnit?: PropertySearchJsonldReadCollectionProjectAreaUnit
  availibilityStatus?: PropertySearchJsonldReadCollectionProjectAvailibilityStatus
  condition?: PropertySearchJsonldReadCollectionProjectCondition
  floor?: number | null
  floorMax?: number | null
  floorMin?: number | null
  floorName?: PropertySearchJsonldReadCollectionProjectFloorName
  marginEstimated?: number | null
  nbBedroomsMax?: number | null
  nbBedroomsMin?: number | null
  nbRoomsMax?: number | null
  nbRoomsMin?: number | null
  priceCurrency?: PropertySearchJsonldReadCollectionProjectPriceCurrency
  priceMax?: number | null
  priceMin?: number | null
  standing?: PropertySearchJsonldReadCollectionProjectStanding
  status?: PropertySearchJsonldReadCollectionProjectStatus
  subtype?: PropertySearchJsonldReadCollectionProjectSubtype
  viewQuality?: PropertySearchJsonldReadCollectionProjectViewQuality
  activities?: RefLabelJsonldReadCollectionProject[]
  contactDetails?: ContactDetailsJsonldReadCollectionProject[]
  i18nText?: I18nTextJsonldReadCollectionProject[]
  propertyArea?: PropertySearchPropertyAreaJsonldReadCollectionProject[]
  proximities?: RefLabelJsonldReadCollectionProject[]
  services?: RefLabelJsonldReadCollectionProject[]
  tag?: PropertyTagJsonldReadCollectionProject[]
  viewLandscapes?: RefLabelJsonldReadCollectionProject[]
  types?: RefLabelJsonldReadCollectionProject[]
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyPropertyAreaJsonldReadItemPropertyContextOneOfHydra =
  (typeof PropertyPropertyAreaJsonldReadItemPropertyContextOneOfHydra)[keyof typeof PropertyPropertyAreaJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyPropertyAreaJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyPropertyAreaJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: PropertyPropertyAreaJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type PropertyPropertyAreaJsonldReadItemPropertyContext =
  | string
  | PropertyPropertyAreaJsonldReadItemPropertyContextOneOf

export interface PropertyPropertyAreaJsonldReadItemProperty {
  readonly "@context"?: PropertyPropertyAreaJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number | null
  property?: PropertyJsonldReadItemProperty
  propertyArea?: PropertyAreaJsonldReadItemProperty
  readonly id: string
}

export type PropertyPropertyAreaJsonldReadItemProjectContextOneOfHydra =
  (typeof PropertyPropertyAreaJsonldReadItemProjectContextOneOfHydra)[keyof typeof PropertyPropertyAreaJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyPropertyAreaJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyPropertyAreaJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: PropertyPropertyAreaJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type PropertyPropertyAreaJsonldReadItemProjectContext =
  | string
  | PropertyPropertyAreaJsonldReadItemProjectContextOneOf

export interface PropertyPropertyAreaJsonldReadItemProject {
  readonly "@context"?: PropertyPropertyAreaJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number | null
  readonly id: string
}

export type PropertyPropertyAreaJsonldReadCollectionPropertyExcelExportContext =
  | string
  | PropertyPropertyAreaJsonldReadCollectionPropertyExcelExportContextOneOf

export interface PropertyPropertyAreaJsonldReadCollectionPropertyExcelExport {
  readonly "@context"?: PropertyPropertyAreaJsonldReadCollectionPropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number | null
  property?: PropertyJsonldReadCollectionPropertyExcelExport
  propertyArea?: PropertyAreaJsonldReadCollectionPropertyExcelExport
  readonly id: string
}

export type PropertyPropertyAreaJsonldReadCollectionPropertyExcelExportContextOneOfHydra =
  (typeof PropertyPropertyAreaJsonldReadCollectionPropertyExcelExportContextOneOfHydra)[keyof typeof PropertyPropertyAreaJsonldReadCollectionPropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyPropertyAreaJsonldReadCollectionPropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyPropertyAreaJsonldReadCollectionPropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyPropertyAreaJsonldReadCollectionPropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export interface PropertyPropertyAreaJsonldReadCollectionProperty {
  readonly "@context"?: PropertyPropertyAreaJsonldReadCollectionPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number | null
  property?: PropertyJsonldReadCollectionProperty
  propertyArea?: PropertyAreaJsonldReadCollectionProperty
  readonly id: string
}

export type PropertyPropertyAreaJsonldReadCollectionPropertyContextOneOfHydra =
  (typeof PropertyPropertyAreaJsonldReadCollectionPropertyContextOneOfHydra)[keyof typeof PropertyPropertyAreaJsonldReadCollectionPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyPropertyAreaJsonldReadCollectionPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyPropertyAreaJsonldReadCollectionPropertyContextOneOf = {
  "@vocab": string
  hydra: PropertyPropertyAreaJsonldReadCollectionPropertyContextOneOfHydra
  [key: string]: any
}

export type PropertyPropertyAreaJsonldReadCollectionPropertyContext =
  | string
  | PropertyPropertyAreaJsonldReadCollectionPropertyContextOneOf

export interface PropertyPropertyAreaJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: PropertyPropertyAreaJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number | null
  readonly id: string
}

export type PropertyPropertyAreaJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof PropertyPropertyAreaJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof PropertyPropertyAreaJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyPropertyAreaJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyPropertyAreaJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyPropertyAreaJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyPropertyAreaJsonldReadCollectionProjectExcelExportContext =
  | string
  | PropertyPropertyAreaJsonldReadCollectionProjectExcelExportContextOneOf

export type PropertyPropertyAreaJsonldReadCollectionProjectContextOneOfHydra =
  (typeof PropertyPropertyAreaJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof PropertyPropertyAreaJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyPropertyAreaJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyPropertyAreaJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: PropertyPropertyAreaJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type PropertyPropertyAreaJsonldReadCollectionProjectContext =
  | string
  | PropertyPropertyAreaJsonldReadCollectionProjectContextOneOf

export interface PropertyPropertyAreaJsonldReadCollectionProject {
  readonly "@context"?: PropertyPropertyAreaJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number | null
  readonly id: string
}

export type PropertyOwnersJsonldReadItemPropertyContextOneOfHydra =
  (typeof PropertyOwnersJsonldReadItemPropertyContextOneOfHydra)[keyof typeof PropertyOwnersJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyOwnersJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyOwnersJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: PropertyOwnersJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type PropertyOwnersJsonldReadItemPropertyContext =
  | string
  | PropertyOwnersJsonldReadItemPropertyContextOneOf

export interface PropertyOwnersJsonldReadItemProperty {
  readonly "@context"?: PropertyOwnersJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  people?: PeopleJsonldReadItemProperty
  sharesPercent?: number | null
  dateFrom?: string | null
  dateTo?: string | null
  readonly id: string
}

export type PropertyOwnersJsonldReadItemProjectContextOneOfHydra =
  (typeof PropertyOwnersJsonldReadItemProjectContextOneOfHydra)[keyof typeof PropertyOwnersJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyOwnersJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyOwnersJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: PropertyOwnersJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type PropertyOwnersJsonldReadItemProjectContext =
  | string
  | PropertyOwnersJsonldReadItemProjectContextOneOf

export interface PropertyOwnersJsonldReadItemProject {
  readonly "@context"?: PropertyOwnersJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type PropertyOwnersJsonldReadItemPeopleContextOneOfHydra =
  (typeof PropertyOwnersJsonldReadItemPeopleContextOneOfHydra)[keyof typeof PropertyOwnersJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyOwnersJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyOwnersJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: PropertyOwnersJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type PropertyOwnersJsonldReadItemPeopleContext =
  | string
  | PropertyOwnersJsonldReadItemPeopleContextOneOf

export interface PropertyOwnersJsonldReadItemPeople {
  readonly "@context"?: PropertyOwnersJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  property?: PropertyJsonldReadItemPeople
  sharesPercent?: number | null
  dateFrom?: string | null
  dateTo?: string | null
  readonly id: string
}

export interface PropertyOwnersJsonldReadCollectionPropertyExcelExport {
  readonly "@context"?: PropertyOwnersJsonldReadCollectionPropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  people?: PeopleJsonldReadCollectionPropertyExcelExport
  sharesPercent?: number | null
  dateFrom?: string | null
  dateTo?: string | null
  readonly id: string
}

export type PropertyOwnersJsonldReadCollectionPropertyExcelExportContextOneOfHydra =
  (typeof PropertyOwnersJsonldReadCollectionPropertyExcelExportContextOneOfHydra)[keyof typeof PropertyOwnersJsonldReadCollectionPropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyOwnersJsonldReadCollectionPropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyOwnersJsonldReadCollectionPropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyOwnersJsonldReadCollectionPropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyOwnersJsonldReadCollectionPropertyExcelExportContext =
  | string
  | PropertyOwnersJsonldReadCollectionPropertyExcelExportContextOneOf

export interface PropertyOwnersJsonldReadCollectionProperty {
  readonly "@context"?: PropertyOwnersJsonldReadCollectionPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  people?: PeopleJsonldReadCollectionProperty
  sharesPercent?: number | null
  dateFrom?: string | null
  dateTo?: string | null
  readonly id: string
}

export type PropertyOwnersJsonldReadCollectionPropertyContextOneOfHydra =
  (typeof PropertyOwnersJsonldReadCollectionPropertyContextOneOfHydra)[keyof typeof PropertyOwnersJsonldReadCollectionPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyOwnersJsonldReadCollectionPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyOwnersJsonldReadCollectionPropertyContextOneOf = {
  "@vocab": string
  hydra: PropertyOwnersJsonldReadCollectionPropertyContextOneOfHydra
  [key: string]: any
}

export type PropertyOwnersJsonldReadCollectionPropertyContext =
  | string
  | PropertyOwnersJsonldReadCollectionPropertyContextOneOf

export type PropertyOwnersJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof PropertyOwnersJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof PropertyOwnersJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyOwnersJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyOwnersJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyOwnersJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyOwnersJsonldReadCollectionProjectExcelExportContext =
  | string
  | PropertyOwnersJsonldReadCollectionProjectExcelExportContextOneOf

export interface PropertyOwnersJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: PropertyOwnersJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type PropertyOwnersJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof PropertyOwnersJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof PropertyOwnersJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyOwnersJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyOwnersJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyOwnersJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyOwnersJsonldReadCollectionPeopleExcelExportContext =
  | string
  | PropertyOwnersJsonldReadCollectionPeopleExcelExportContextOneOf

export interface PropertyOwnersJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: PropertyOwnersJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  property?: PropertyJsonldReadCollectionPeopleExcelExport
  sharesPercent?: number | null
  dateFrom?: string | null
  dateTo?: string | null
  readonly id: string
}

export type PropertyOfferJsonldReadItemPropertyOfferGroup =
  RefLabelJsonldReadItemPropertyOffer | null

export type PropertyOfferJsonldReadItemPropertyOfferUnitPrice =
  RefLabelJsonldReadItemPropertyOffer | null

export type PropertyOfferJsonldReadItemPropertyOfferType =
  RefLabelJsonldReadItemPropertyOffer | null

export type PropertyOfferJsonldReadItemPropertyOfferSubtype =
  RefLabelJsonldReadItemPropertyOffer | null

export type PropertyOfferJsonldReadItemPropertyOfferStatus =
  RefLabelJsonldReadItemPropertyOffer | null

export type PropertyOfferJsonldReadItemPropertyOfferPricePeriod =
  RefLabelJsonldReadItemPropertyOffer | null

export type PropertyOfferJsonldReadItemPropertyOfferCurrency =
  RefLabelJsonldReadItemPropertyOffer | null

export interface PropertyOfferJsonldReadItemPropertyOffer {
  readonly "@context"?: PropertyOfferJsonldReadItemPropertyOfferContext
  readonly "@id"?: string
  readonly "@type"?: string
  auctionLink?: string | null
  commissionBuyer?: number | null
  commissionBuyerRate?: number | null
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  currency?: PropertyOfferJsonldReadItemPropertyOfferCurrency
  estimationMax?: number | null
  estimationMin?: number | null
  inventoryFees?: number | null
  isPriceHidden?: boolean | null
  priceContribution?: number | null
  priceDeposit?: number | null
  priceFees?: number | null
  priceMax?: number | null
  priceNegotiable?: number | null
  pricePeriod?: PropertyOfferJsonldReadItemPropertyOfferPricePeriod
  priceSeller?: number | null
  priceSold?: number | null
  priceValue?: number | null
  priceVat?: number | null
  reference?: string | null
  soldAt?: string | null
  status?: PropertyOfferJsonldReadItemPropertyOfferStatus
  subtype?: PropertyOfferJsonldReadItemPropertyOfferSubtype
  tenantAmount?: number | null
  transfertTax?: number | null
  type?: PropertyOfferJsonldReadItemPropertyOfferType
  unitPrice?: PropertyOfferJsonldReadItemPropertyOfferUnitPrice
  feesDetails?: RefLabelJsonldReadItemPropertyOffer[]
  projectPropertyOffer?: ProjectPropertyOfferJsonldReadItemPropertyOffer[]
  group?: PropertyOfferJsonldReadItemPropertyOfferGroup
  readonly wwwbiCanAccessDate?: string | null
  readonly wwwbiHasAccessDate?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyOfferJsonldReadItemPropertyOfferContextOneOfHydra =
  (typeof PropertyOfferJsonldReadItemPropertyOfferContextOneOfHydra)[keyof typeof PropertyOfferJsonldReadItemPropertyOfferContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyOfferJsonldReadItemPropertyOfferContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyOfferJsonldReadItemPropertyOfferContextOneOf = {
  "@vocab": string
  hydra: PropertyOfferJsonldReadItemPropertyOfferContextOneOfHydra
  [key: string]: any
}

export type PropertyOfferJsonldReadItemPropertyOfferContext =
  | string
  | PropertyOfferJsonldReadItemPropertyOfferContextOneOf

export type PropertyOfferJsonldReadItemPropertyGroup = RefLabelJsonldReadItemProperty | null

export type PropertyOfferJsonldReadItemPropertyUnitPrice = RefLabelJsonldReadItemProperty | null

export type PropertyOfferJsonldReadItemPropertyType = RefLabelJsonldReadItemProperty | null

export type PropertyOfferJsonldReadItemPropertySubtype = RefLabelJsonldReadItemProperty | null

export type PropertyOfferJsonldReadItemPropertyStatus = RefLabelJsonldReadItemProperty | null

export type PropertyOfferJsonldReadItemPropertyPricePeriod = RefLabelJsonldReadItemProperty | null

export type PropertyOfferJsonldReadItemPropertyCurrency = RefLabelJsonldReadItemProperty | null

export type PropertyOfferJsonldReadItemPropertyContextOneOfHydra =
  (typeof PropertyOfferJsonldReadItemPropertyContextOneOfHydra)[keyof typeof PropertyOfferJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyOfferJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyOfferJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: PropertyOfferJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type PropertyOfferJsonldReadItemPropertyContext =
  | string
  | PropertyOfferJsonldReadItemPropertyContextOneOf

export interface PropertyOfferJsonldReadItemProperty {
  readonly "@context"?: PropertyOfferJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  auctionLink?: string | null
  commissionBuyer?: number | null
  commissionBuyerRate?: number | null
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  currency?: PropertyOfferJsonldReadItemPropertyCurrency
  estimationMax?: number | null
  estimationMin?: number | null
  inventoryFees?: number | null
  isPriceHidden?: boolean | null
  priceContribution?: number | null
  priceDeposit?: number | null
  priceFees?: number | null
  priceMax?: number | null
  priceNegotiable?: number | null
  pricePeriod?: PropertyOfferJsonldReadItemPropertyPricePeriod
  priceSeller?: number | null
  priceSold?: number | null
  priceValue?: number | null
  priceVat?: number | null
  reference?: string | null
  soldAt?: string | null
  status?: PropertyOfferJsonldReadItemPropertyStatus
  subtype?: PropertyOfferJsonldReadItemPropertySubtype
  tenantAmount?: number | null
  transfertTax?: number | null
  type?: PropertyOfferJsonldReadItemPropertyType
  unitPrice?: PropertyOfferJsonldReadItemPropertyUnitPrice
  feesDetails?: RefLabelJsonldReadItemProperty[]
  group?: PropertyOfferJsonldReadItemPropertyGroup
  readonly wwwbiCanAccessDate?: string | null
  readonly wwwbiHasAccessDate?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyOfferJsonldReadItemProjectGroup = RefLabelJsonldReadItemProject | null

export type PropertyOfferJsonldReadItemProjectUnitPrice = RefLabelJsonldReadItemProject | null

export type PropertyOfferJsonldReadItemProjectType = RefLabelJsonldReadItemProject | null

export type PropertyOfferJsonldReadItemProjectSubtype = RefLabelJsonldReadItemProject | null

export type PropertyOfferJsonldReadItemProjectStatus = RefLabelJsonldReadItemProject | null

export type PropertyOfferJsonldReadItemProjectPricePeriod = RefLabelJsonldReadItemProject | null

export type PropertyOfferJsonldReadItemProjectCurrency = RefLabelJsonldReadItemProject | null

export type PropertyOfferJsonldReadItemProjectContextOneOfHydra =
  (typeof PropertyOfferJsonldReadItemProjectContextOneOfHydra)[keyof typeof PropertyOfferJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyOfferJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyOfferJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: PropertyOfferJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type PropertyOfferJsonldReadItemProjectContext =
  | string
  | PropertyOfferJsonldReadItemProjectContextOneOf

export interface PropertyOfferJsonldReadItemProject {
  readonly "@context"?: PropertyOfferJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  auctionLink?: string | null
  commissionBuyer?: number | null
  commissionBuyerRate?: number | null
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  currency?: PropertyOfferJsonldReadItemProjectCurrency
  estimationMax?: number | null
  estimationMin?: number | null
  inventoryFees?: number | null
  isPriceHidden?: boolean | null
  priceContribution?: number | null
  priceDeposit?: number | null
  priceFees?: number | null
  priceMax?: number | null
  priceNegotiable?: number | null
  pricePeriod?: PropertyOfferJsonldReadItemProjectPricePeriod
  priceSeller?: number | null
  priceSold?: number | null
  priceValue?: number | null
  priceVat?: number | null
  reference?: string | null
  soldAt?: string | null
  status?: PropertyOfferJsonldReadItemProjectStatus
  subtype?: PropertyOfferJsonldReadItemProjectSubtype
  tenantAmount?: number | null
  transfertTax?: number | null
  type?: PropertyOfferJsonldReadItemProjectType
  unitPrice?: PropertyOfferJsonldReadItemProjectUnitPrice
  feesDetails?: RefLabelJsonldReadItemProject[]
  group?: PropertyOfferJsonldReadItemProjectGroup
  readonly wwwbiCanAccessDate?: string | null
  readonly wwwbiHasAccessDate?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyOfferJsonldReadItemCollaboratorItemType =
  RefLabelJsonldReadItemCollaboratorItem | null

export type PropertyOfferJsonldReadItemCollaboratorItemSubtype =
  RefLabelJsonldReadItemCollaboratorItem | null

export type PropertyOfferJsonldReadItemCollaboratorItemStatus =
  RefLabelJsonldReadItemCollaboratorItem | null

export interface PropertyOfferJsonldReadItemCollaboratorItem {
  readonly "@context"?: PropertyOfferJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  auctionLink?: string | null
  commissionBuyer?: number | null
  status?: PropertyOfferJsonldReadItemCollaboratorItemStatus
  subtype?: PropertyOfferJsonldReadItemCollaboratorItemSubtype
  type?: PropertyOfferJsonldReadItemCollaboratorItemType
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyOfferJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof PropertyOfferJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof PropertyOfferJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyOfferJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyOfferJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: PropertyOfferJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type PropertyOfferJsonldReadItemCollaboratorItemContext =
  | string
  | PropertyOfferJsonldReadItemCollaboratorItemContextOneOf

export type PropertyOfferJsonldReadCollectionPropertyOfferExcelExportGroup =
  RefLabelJsonldReadCollectionPropertyOfferExcelExport | null

export type PropertyOfferJsonldReadCollectionPropertyOfferExcelExportUnitPrice =
  RefLabelJsonldReadCollectionPropertyOfferExcelExport | null

export type PropertyOfferJsonldReadCollectionPropertyOfferExcelExportType =
  RefLabelJsonldReadCollectionPropertyOfferExcelExport | null

export type PropertyOfferJsonldReadCollectionPropertyOfferExcelExportSubtype =
  RefLabelJsonldReadCollectionPropertyOfferExcelExport | null

export type PropertyOfferJsonldReadCollectionPropertyOfferExcelExportStatus =
  RefLabelJsonldReadCollectionPropertyOfferExcelExport | null

export type PropertyOfferJsonldReadCollectionPropertyOfferExcelExportPricePeriod =
  RefLabelJsonldReadCollectionPropertyOfferExcelExport | null

export type PropertyOfferJsonldReadCollectionPropertyOfferExcelExportCurrency =
  RefLabelJsonldReadCollectionPropertyOfferExcelExport | null

export interface PropertyOfferJsonldReadCollectionPropertyOfferExcelExport {
  readonly "@id"?: string
  readonly "@type"?: string
  auctionLink?: string | null
  commissionBuyer?: number | null
  commissionBuyerRate?: number | null
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  currency?: PropertyOfferJsonldReadCollectionPropertyOfferExcelExportCurrency
  estimationMax?: number | null
  estimationMin?: number | null
  inventoryFees?: number | null
  isPriceHidden?: boolean | null
  priceContribution?: number | null
  priceDeposit?: number | null
  priceFees?: number | null
  priceMax?: number | null
  priceNegotiable?: number | null
  pricePeriod?: PropertyOfferJsonldReadCollectionPropertyOfferExcelExportPricePeriod
  priceSeller?: number | null
  priceSold?: number | null
  priceValue?: number | null
  priceVat?: number | null
  reference?: string | null
  soldAt?: string | null
  status?: PropertyOfferJsonldReadCollectionPropertyOfferExcelExportStatus
  subtype?: PropertyOfferJsonldReadCollectionPropertyOfferExcelExportSubtype
  tenantAmount?: number | null
  transfertTax?: number | null
  type?: PropertyOfferJsonldReadCollectionPropertyOfferExcelExportType
  unitPrice?: PropertyOfferJsonldReadCollectionPropertyOfferExcelExportUnitPrice
  feesDetails?: RefLabelJsonldReadCollectionPropertyOfferExcelExport[]
  projectPropertyOffer?: ProjectPropertyOfferJsonldReadCollectionPropertyOfferExcelExport[]
  group?: PropertyOfferJsonldReadCollectionPropertyOfferExcelExportGroup
  readonly wwwbiCanAccessDate?: string | null
  readonly wwwbiHasAccessDate?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyOfferJsonldReadCollectionPropertyOfferGroup =
  RefLabelJsonldReadCollectionPropertyOffer | null

export type PropertyOfferJsonldReadCollectionPropertyOfferUnitPrice =
  RefLabelJsonldReadCollectionPropertyOffer | null

export type PropertyOfferJsonldReadCollectionPropertyOfferType =
  RefLabelJsonldReadCollectionPropertyOffer | null

export type PropertyOfferJsonldReadCollectionPropertyOfferSubtype =
  RefLabelJsonldReadCollectionPropertyOffer | null

export type PropertyOfferJsonldReadCollectionPropertyOfferStatus =
  RefLabelJsonldReadCollectionPropertyOffer | null

export type PropertyOfferJsonldReadCollectionPropertyOfferPricePeriod =
  RefLabelJsonldReadCollectionPropertyOffer | null

export type PropertyOfferJsonldReadCollectionPropertyOfferCurrency =
  RefLabelJsonldReadCollectionPropertyOffer | null

export interface PropertyOfferJsonldReadCollectionPropertyOffer {
  readonly "@id"?: string
  readonly "@type"?: string
  auctionLink?: string | null
  commissionBuyer?: number | null
  commissionBuyerRate?: number | null
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  currency?: PropertyOfferJsonldReadCollectionPropertyOfferCurrency
  estimationMax?: number | null
  estimationMin?: number | null
  inventoryFees?: number | null
  isPriceHidden?: boolean | null
  priceContribution?: number | null
  priceDeposit?: number | null
  priceFees?: number | null
  priceMax?: number | null
  priceNegotiable?: number | null
  pricePeriod?: PropertyOfferJsonldReadCollectionPropertyOfferPricePeriod
  priceSeller?: number | null
  priceSold?: number | null
  priceValue?: number | null
  priceVat?: number | null
  reference?: string | null
  soldAt?: string | null
  status?: PropertyOfferJsonldReadCollectionPropertyOfferStatus
  subtype?: PropertyOfferJsonldReadCollectionPropertyOfferSubtype
  tenantAmount?: number | null
  transfertTax?: number | null
  type?: PropertyOfferJsonldReadCollectionPropertyOfferType
  unitPrice?: PropertyOfferJsonldReadCollectionPropertyOfferUnitPrice
  feesDetails?: RefLabelJsonldReadCollectionPropertyOffer[]
  projectPropertyOffer?: ProjectPropertyOfferJsonldReadCollectionPropertyOffer[]
  group?: PropertyOfferJsonldReadCollectionPropertyOfferGroup
  readonly wwwbiCanAccessDate?: string | null
  readonly wwwbiHasAccessDate?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface PropertyOfferJsonldReadCollectionPropertyExcelExport {
  readonly "@context"?: PropertyOfferJsonldReadCollectionPropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyOfferJsonldReadCollectionPropertyExcelExportContextOneOfHydra =
  (typeof PropertyOfferJsonldReadCollectionPropertyExcelExportContextOneOfHydra)[keyof typeof PropertyOfferJsonldReadCollectionPropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyOfferJsonldReadCollectionPropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyOfferJsonldReadCollectionPropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyOfferJsonldReadCollectionPropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyOfferJsonldReadCollectionPropertyExcelExportContext =
  | string
  | PropertyOfferJsonldReadCollectionPropertyExcelExportContextOneOf

export type PropertyOfferJsonldReadCollectionPropertyContextOneOfHydra =
  (typeof PropertyOfferJsonldReadCollectionPropertyContextOneOfHydra)[keyof typeof PropertyOfferJsonldReadCollectionPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyOfferJsonldReadCollectionPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyOfferJsonldReadCollectionPropertyContextOneOf = {
  "@vocab": string
  hydra: PropertyOfferJsonldReadCollectionPropertyContextOneOfHydra
  [key: string]: any
}

export type PropertyOfferJsonldReadCollectionPropertyContext =
  | string
  | PropertyOfferJsonldReadCollectionPropertyContextOneOf

export interface PropertyOfferJsonldReadCollectionProperty {
  readonly "@context"?: PropertyOfferJsonldReadCollectionPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  reference?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyOfferJsonldReadCollectionProjectExcelExportGroup =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyOfferJsonldReadCollectionProjectExcelExportUnitPrice =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyOfferJsonldReadCollectionProjectExcelExportType =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyOfferJsonldReadCollectionProjectExcelExportSubtype =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyOfferJsonldReadCollectionProjectExcelExportStatus =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyOfferJsonldReadCollectionProjectExcelExportPricePeriod =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyOfferJsonldReadCollectionProjectExcelExportCurrency =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export interface PropertyOfferJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: PropertyOfferJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  auctionLink?: string | null
  commissionBuyer?: number | null
  commissionBuyerRate?: number | null
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  currency?: PropertyOfferJsonldReadCollectionProjectExcelExportCurrency
  estimationMax?: number | null
  estimationMin?: number | null
  inventoryFees?: number | null
  isPriceHidden?: boolean | null
  priceContribution?: number | null
  priceDeposit?: number | null
  priceFees?: number | null
  priceMax?: number | null
  priceNegotiable?: number | null
  pricePeriod?: PropertyOfferJsonldReadCollectionProjectExcelExportPricePeriod
  priceSeller?: number | null
  priceSold?: number | null
  priceValue?: number | null
  priceVat?: number | null
  reference?: string | null
  soldAt?: string | null
  status?: PropertyOfferJsonldReadCollectionProjectExcelExportStatus
  subtype?: PropertyOfferJsonldReadCollectionProjectExcelExportSubtype
  tenantAmount?: number | null
  transfertTax?: number | null
  type?: PropertyOfferJsonldReadCollectionProjectExcelExportType
  unitPrice?: PropertyOfferJsonldReadCollectionProjectExcelExportUnitPrice
  feesDetails?: RefLabelJsonldReadCollectionProjectExcelExport[]
  group?: PropertyOfferJsonldReadCollectionProjectExcelExportGroup
  readonly wwwbiCanAccessDate?: string | null
  readonly wwwbiHasAccessDate?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyOfferJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof PropertyOfferJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof PropertyOfferJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyOfferJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyOfferJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyOfferJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyOfferJsonldReadCollectionProjectExcelExportContext =
  | string
  | PropertyOfferJsonldReadCollectionProjectExcelExportContextOneOf

export type PropertyOfferJsonldReadCollectionProjectGroup =
  RefLabelJsonldReadCollectionProject | null

export type PropertyOfferJsonldReadCollectionProjectUnitPrice =
  RefLabelJsonldReadCollectionProject | null

export type PropertyOfferJsonldReadCollectionProjectType =
  RefLabelJsonldReadCollectionProject | null

export type PropertyOfferJsonldReadCollectionProjectSubtype =
  RefLabelJsonldReadCollectionProject | null

export type PropertyOfferJsonldReadCollectionProjectStatus =
  RefLabelJsonldReadCollectionProject | null

export type PropertyOfferJsonldReadCollectionProjectPricePeriod =
  RefLabelJsonldReadCollectionProject | null

export type PropertyOfferJsonldReadCollectionProjectCurrency =
  RefLabelJsonldReadCollectionProject | null

export interface PropertyOfferJsonldReadCollectionProject {
  readonly "@context"?: PropertyOfferJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  auctionLink?: string | null
  commissionBuyer?: number | null
  commissionBuyerRate?: number | null
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  currency?: PropertyOfferJsonldReadCollectionProjectCurrency
  estimationMax?: number | null
  estimationMin?: number | null
  inventoryFees?: number | null
  isPriceHidden?: boolean | null
  priceContribution?: number | null
  priceDeposit?: number | null
  priceFees?: number | null
  priceMax?: number | null
  priceNegotiable?: number | null
  pricePeriod?: PropertyOfferJsonldReadCollectionProjectPricePeriod
  priceSeller?: number | null
  priceSold?: number | null
  priceValue?: number | null
  priceVat?: number | null
  reference?: string | null
  soldAt?: string | null
  status?: PropertyOfferJsonldReadCollectionProjectStatus
  subtype?: PropertyOfferJsonldReadCollectionProjectSubtype
  tenantAmount?: number | null
  transfertTax?: number | null
  type?: PropertyOfferJsonldReadCollectionProjectType
  unitPrice?: PropertyOfferJsonldReadCollectionProjectUnitPrice
  feesDetails?: RefLabelJsonldReadCollectionProject[]
  group?: PropertyOfferJsonldReadCollectionProjectGroup
  readonly wwwbiCanAccessDate?: string | null
  readonly wwwbiHasAccessDate?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyOfferJsonldReadCollectionProjectContextOneOfHydra =
  (typeof PropertyOfferJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof PropertyOfferJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyOfferJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyOfferJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: PropertyOfferJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type PropertyOfferJsonldReadCollectionProjectContext =
  | string
  | PropertyOfferJsonldReadCollectionProjectContextOneOf

export type PropertyMediaJsonldReadItemPropertyContextOneOfHydra =
  (typeof PropertyMediaJsonldReadItemPropertyContextOneOfHydra)[keyof typeof PropertyMediaJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyMediaJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyMediaJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: PropertyMediaJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type PropertyMediaJsonldReadItemPropertyContext =
  | string
  | PropertyMediaJsonldReadItemPropertyContextOneOf

export interface PropertyMediaJsonldReadItemProperty {
  readonly "@context"?: PropertyMediaJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  property?: PropertyJsonldReadItemProperty
  media?: MediaJsonldReadItemProperty
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
}

export type PropertyMediaJsonldReadItemProjectContextOneOfHydra =
  (typeof PropertyMediaJsonldReadItemProjectContextOneOfHydra)[keyof typeof PropertyMediaJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyMediaJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyMediaJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: PropertyMediaJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type PropertyMediaJsonldReadItemProjectContext =
  | string
  | PropertyMediaJsonldReadItemProjectContextOneOf

export interface PropertyMediaJsonldReadItemProject {
  readonly "@context"?: PropertyMediaJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  property?: PropertyJsonldReadItemProject
  media?: MediaJsonldReadItemProject
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
}

export type PropertyMediaJsonldReadCollectionPropertyExcelExportContextOneOfHydra =
  (typeof PropertyMediaJsonldReadCollectionPropertyExcelExportContextOneOfHydra)[keyof typeof PropertyMediaJsonldReadCollectionPropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyMediaJsonldReadCollectionPropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyMediaJsonldReadCollectionPropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyMediaJsonldReadCollectionPropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyMediaJsonldReadCollectionPropertyExcelExportContext =
  | string
  | PropertyMediaJsonldReadCollectionPropertyExcelExportContextOneOf

export interface PropertyMediaJsonldReadCollectionPropertyExcelExport {
  readonly "@context"?: PropertyMediaJsonldReadCollectionPropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  property?: PropertyJsonldReadCollectionPropertyExcelExport
  media?: MediaJsonldReadCollectionPropertyExcelExport
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
}

export interface PropertyMediaJsonldReadCollectionProperty {
  readonly "@context"?: PropertyMediaJsonldReadCollectionPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  property?: PropertyJsonldReadCollectionProperty
  media?: MediaJsonldReadCollectionProperty
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
}

export type PropertyMediaJsonldReadCollectionPropertyContextOneOfHydra =
  (typeof PropertyMediaJsonldReadCollectionPropertyContextOneOfHydra)[keyof typeof PropertyMediaJsonldReadCollectionPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyMediaJsonldReadCollectionPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyMediaJsonldReadCollectionPropertyContextOneOf = {
  "@vocab": string
  hydra: PropertyMediaJsonldReadCollectionPropertyContextOneOfHydra
  [key: string]: any
}

export type PropertyMediaJsonldReadCollectionPropertyContext =
  | string
  | PropertyMediaJsonldReadCollectionPropertyContextOneOf

export interface PropertyMediaJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: PropertyMediaJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  property?: PropertyJsonldReadCollectionProjectExcelExport
  media?: MediaJsonldReadCollectionProjectExcelExport
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
}

export type PropertyMediaJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof PropertyMediaJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof PropertyMediaJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyMediaJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyMediaJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyMediaJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyMediaJsonldReadCollectionProjectExcelExportContext =
  | string
  | PropertyMediaJsonldReadCollectionProjectExcelExportContextOneOf

export type PropertyMediaJsonldReadCollectionProjectContextOneOfHydra =
  (typeof PropertyMediaJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof PropertyMediaJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyMediaJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyMediaJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: PropertyMediaJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type PropertyMediaJsonldReadCollectionProjectContext =
  | string
  | PropertyMediaJsonldReadCollectionProjectContextOneOf

export interface PropertyMediaJsonldReadCollectionProject {
  readonly "@context"?: PropertyMediaJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  property?: PropertyJsonldReadCollectionProject
  media?: MediaJsonldReadCollectionProject
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
}

export type PropertyMediaJsonldContextOneOfHydra =
  (typeof PropertyMediaJsonldContextOneOfHydra)[keyof typeof PropertyMediaJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyMediaJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyMediaJsonldContextOneOf = {
  "@vocab": string
  hydra: PropertyMediaJsonldContextOneOfHydra
  [key: string]: any
}

export type PropertyMediaJsonldContext = string | PropertyMediaJsonldContextOneOf

export interface PropertyMediaJsonld {
  readonly "@context"?: PropertyMediaJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  property?: string
  media?: MediaJsonld
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
}

export interface PropertyContactDetailsJsonldReadItemProperty {
  readonly "@context"?: PropertyContactDetailsJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  property?: PropertyJsonldReadItemProperty
  contactDetails?: ContactDetailsJsonldReadItemProperty
  order?: number
  isDefault?: boolean
  readonly id: string
}

export type PropertyContactDetailsJsonldReadItemPropertyContextOneOfHydra =
  (typeof PropertyContactDetailsJsonldReadItemPropertyContextOneOfHydra)[keyof typeof PropertyContactDetailsJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyContactDetailsJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyContactDetailsJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: PropertyContactDetailsJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type PropertyContactDetailsJsonldReadItemPropertyContext =
  | string
  | PropertyContactDetailsJsonldReadItemPropertyContextOneOf

export type PropertyContactDetailsJsonldReadItemProjectContextOneOfHydra =
  (typeof PropertyContactDetailsJsonldReadItemProjectContextOneOfHydra)[keyof typeof PropertyContactDetailsJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyContactDetailsJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyContactDetailsJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: PropertyContactDetailsJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type PropertyContactDetailsJsonldReadItemProjectContext =
  | string
  | PropertyContactDetailsJsonldReadItemProjectContextOneOf

export interface PropertyContactDetailsJsonldReadItemProject {
  readonly "@context"?: PropertyContactDetailsJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  property?: PropertyJsonldReadItemProject
  contactDetails?: ContactDetailsJsonldReadItemProject
  order?: number
  isDefault?: boolean
  readonly id: string
}

export interface PropertyContactDetailsJsonldReadCollectionPropertyExcelExport {
  readonly "@context"?: PropertyContactDetailsJsonldReadCollectionPropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  property?: PropertyJsonldReadCollectionPropertyExcelExport
  contactDetails?: ContactDetailsJsonldReadCollectionPropertyExcelExport
  order?: number
  isDefault?: boolean
  readonly id: string
}

export type PropertyContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOfHydra =
  (typeof PropertyContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOfHydra)[keyof typeof PropertyContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyContactDetailsJsonldReadCollectionPropertyExcelExportContext =
  | string
  | PropertyContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOf

export type PropertyContactDetailsJsonldReadCollectionPropertyContextOneOfHydra =
  (typeof PropertyContactDetailsJsonldReadCollectionPropertyContextOneOfHydra)[keyof typeof PropertyContactDetailsJsonldReadCollectionPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyContactDetailsJsonldReadCollectionPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyContactDetailsJsonldReadCollectionPropertyContextOneOf = {
  "@vocab": string
  hydra: PropertyContactDetailsJsonldReadCollectionPropertyContextOneOfHydra
  [key: string]: any
}

export type PropertyContactDetailsJsonldReadCollectionPropertyContext =
  | string
  | PropertyContactDetailsJsonldReadCollectionPropertyContextOneOf

export interface PropertyContactDetailsJsonldReadCollectionProperty {
  readonly "@context"?: PropertyContactDetailsJsonldReadCollectionPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  property?: PropertyJsonldReadCollectionProperty
  contactDetails?: ContactDetailsJsonldReadCollectionProperty
  order?: number
  isDefault?: boolean
  readonly id: string
}

export interface PropertyContactDetailsJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: PropertyContactDetailsJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  property?: PropertyJsonldReadCollectionProjectExcelExport
  contactDetails?: ContactDetailsJsonldReadCollectionProjectExcelExport
  order?: number
  isDefault?: boolean
  readonly id: string
}

export type PropertyContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof PropertyContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof PropertyContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyContactDetailsJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyContactDetailsJsonldReadCollectionProjectExcelExportContext =
  | string
  | PropertyContactDetailsJsonldReadCollectionProjectExcelExportContextOneOf

export interface PropertyContactDetailsJsonldReadCollectionProject {
  readonly "@context"?: PropertyContactDetailsJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  property?: PropertyJsonldReadCollectionProject
  contactDetails?: ContactDetailsJsonldReadCollectionProject
  order?: number
  isDefault?: boolean
  readonly id: string
}

export type PropertyContactDetailsJsonldReadCollectionProjectContextOneOfHydra =
  (typeof PropertyContactDetailsJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof PropertyContactDetailsJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyContactDetailsJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyContactDetailsJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: PropertyContactDetailsJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type PropertyContactDetailsJsonldReadCollectionProjectContext =
  | string
  | PropertyContactDetailsJsonldReadCollectionProjectContextOneOf

export type PropertyContactDetailsJsonldContextOneOfHydra =
  (typeof PropertyContactDetailsJsonldContextOneOfHydra)[keyof typeof PropertyContactDetailsJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyContactDetailsJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyContactDetailsJsonldContextOneOf = {
  "@vocab": string
  hydra: PropertyContactDetailsJsonldContextOneOfHydra
  [key: string]: any
}

export type PropertyContactDetailsJsonldContext = string | PropertyContactDetailsJsonldContextOneOf

export interface PropertyContactDetailsJsonld {
  readonly "@context"?: PropertyContactDetailsJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  property?: string
  contactDetails?: ContactDetailsJsonld
  order?: number
  isDefault?: boolean
  readonly id: string
}

export type PropertyAreaJsonldReadItemPropertySearchContextOneOfHydra =
  (typeof PropertyAreaJsonldReadItemPropertySearchContextOneOfHydra)[keyof typeof PropertyAreaJsonldReadItemPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyAreaJsonldReadItemPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyAreaJsonldReadItemPropertySearchContextOneOf = {
  "@vocab": string
  hydra: PropertyAreaJsonldReadItemPropertySearchContextOneOfHydra
  [key: string]: any
}

export type PropertyAreaJsonldReadItemPropertySearchContext =
  | string
  | PropertyAreaJsonldReadItemPropertySearchContextOneOf

export interface PropertyAreaJsonldReadItemPropertySearch {
  readonly "@context"?: PropertyAreaJsonldReadItemPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyAreaJsonldReadItemPropertyUnit = RefLabelJsonldReadItemProperty | null

export type PropertyAreaJsonldReadItemPropertyType = RefLabelJsonldReadItemProperty | null

export type PropertyAreaJsonldReadItemPropertyFloorName = RefLabelJsonldReadItemProperty | null

export type PropertyAreaJsonldReadItemPropertyFloorCoating = RefLabelJsonldReadItemProperty | null

export interface PropertyAreaJsonldReadItemProperty {
  readonly "@context"?: PropertyAreaJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  ceilingHeight?: number | null
  floor?: number | null
  floorCoating?: PropertyAreaJsonldReadItemPropertyFloorCoating
  floorName?: PropertyAreaJsonldReadItemPropertyFloorName
  number?: number | null
  surface?: number | null
  type?: PropertyAreaJsonldReadItemPropertyType
  unit?: PropertyAreaJsonldReadItemPropertyUnit
  areaOrientations?: RefLabelJsonldReadItemProperty[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyAreaJsonldReadItemPropertyContextOneOfHydra =
  (typeof PropertyAreaJsonldReadItemPropertyContextOneOfHydra)[keyof typeof PropertyAreaJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyAreaJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyAreaJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: PropertyAreaJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type PropertyAreaJsonldReadItemPropertyContext =
  | string
  | PropertyAreaJsonldReadItemPropertyContextOneOf

export type PropertyAreaJsonldReadItemProjectUnit = RefLabelJsonldReadItemProject | null

export type PropertyAreaJsonldReadItemProjectType = RefLabelJsonldReadItemProject | null

export type PropertyAreaJsonldReadItemProjectFloorName = RefLabelJsonldReadItemProject | null

export type PropertyAreaJsonldReadItemProjectFloorCoating = RefLabelJsonldReadItemProject | null

export interface PropertyAreaJsonldReadItemProject {
  readonly "@context"?: PropertyAreaJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  ceilingHeight?: number | null
  floor?: number | null
  floorCoating?: PropertyAreaJsonldReadItemProjectFloorCoating
  floorName?: PropertyAreaJsonldReadItemProjectFloorName
  number?: number | null
  surface?: number | null
  type?: PropertyAreaJsonldReadItemProjectType
  unit?: PropertyAreaJsonldReadItemProjectUnit
  areaOrientations?: RefLabelJsonldReadItemProject[]
  property?: PropertyPropertyAreaJsonldReadItemProject[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyAreaJsonldReadItemProjectContextOneOfHydra =
  (typeof PropertyAreaJsonldReadItemProjectContextOneOfHydra)[keyof typeof PropertyAreaJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyAreaJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyAreaJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: PropertyAreaJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type PropertyAreaJsonldReadItemProjectContext =
  | string
  | PropertyAreaJsonldReadItemProjectContextOneOf

export type PropertyAreaJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra =
  (typeof PropertyAreaJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra)[keyof typeof PropertyAreaJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyAreaJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyAreaJsonldReadCollectionPropertySearchExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyAreaJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyAreaJsonldReadCollectionPropertySearchExcelExportContext =
  | string
  | PropertyAreaJsonldReadCollectionPropertySearchExcelExportContextOneOf

export interface PropertyAreaJsonldReadCollectionPropertySearchExcelExport {
  readonly "@context"?: PropertyAreaJsonldReadCollectionPropertySearchExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyAreaJsonldReadCollectionPropertySearchContextOneOfHydra =
  (typeof PropertyAreaJsonldReadCollectionPropertySearchContextOneOfHydra)[keyof typeof PropertyAreaJsonldReadCollectionPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyAreaJsonldReadCollectionPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyAreaJsonldReadCollectionPropertySearchContextOneOf = {
  "@vocab": string
  hydra: PropertyAreaJsonldReadCollectionPropertySearchContextOneOfHydra
  [key: string]: any
}

export type PropertyAreaJsonldReadCollectionPropertySearchContext =
  | string
  | PropertyAreaJsonldReadCollectionPropertySearchContextOneOf

export interface PropertyAreaJsonldReadCollectionPropertySearch {
  readonly "@context"?: PropertyAreaJsonldReadCollectionPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyAreaJsonldReadCollectionPropertyExcelExportUnit =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyAreaJsonldReadCollectionPropertyExcelExportType =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyAreaJsonldReadCollectionPropertyExcelExportFloorName =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyAreaJsonldReadCollectionPropertyExcelExportFloorCoating =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export interface PropertyAreaJsonldReadCollectionPropertyExcelExport {
  readonly "@context"?: PropertyAreaJsonldReadCollectionPropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  ceilingHeight?: number | null
  floor?: number | null
  floorCoating?: PropertyAreaJsonldReadCollectionPropertyExcelExportFloorCoating
  floorName?: PropertyAreaJsonldReadCollectionPropertyExcelExportFloorName
  number?: number | null
  surface?: number | null
  type?: PropertyAreaJsonldReadCollectionPropertyExcelExportType
  unit?: PropertyAreaJsonldReadCollectionPropertyExcelExportUnit
  areaOrientations?: RefLabelJsonldReadCollectionPropertyExcelExport[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyAreaJsonldReadCollectionPropertyExcelExportContextOneOfHydra =
  (typeof PropertyAreaJsonldReadCollectionPropertyExcelExportContextOneOfHydra)[keyof typeof PropertyAreaJsonldReadCollectionPropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyAreaJsonldReadCollectionPropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyAreaJsonldReadCollectionPropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyAreaJsonldReadCollectionPropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyAreaJsonldReadCollectionPropertyExcelExportContext =
  | string
  | PropertyAreaJsonldReadCollectionPropertyExcelExportContextOneOf

export type PropertyAreaJsonldReadCollectionPropertyUnit =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyAreaJsonldReadCollectionPropertyType =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyAreaJsonldReadCollectionPropertyFloorName =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyAreaJsonldReadCollectionPropertyFloorCoating =
  RefLabelJsonldReadCollectionProperty | null

export interface PropertyAreaJsonldReadCollectionProperty {
  readonly "@context"?: PropertyAreaJsonldReadCollectionPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  ceilingHeight?: number | null
  floor?: number | null
  floorCoating?: PropertyAreaJsonldReadCollectionPropertyFloorCoating
  floorName?: PropertyAreaJsonldReadCollectionPropertyFloorName
  number?: number | null
  surface?: number | null
  type?: PropertyAreaJsonldReadCollectionPropertyType
  unit?: PropertyAreaJsonldReadCollectionPropertyUnit
  areaOrientations?: RefLabelJsonldReadCollectionProperty[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyAreaJsonldReadCollectionPropertyContextOneOfHydra =
  (typeof PropertyAreaJsonldReadCollectionPropertyContextOneOfHydra)[keyof typeof PropertyAreaJsonldReadCollectionPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyAreaJsonldReadCollectionPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyAreaJsonldReadCollectionPropertyContextOneOf = {
  "@vocab": string
  hydra: PropertyAreaJsonldReadCollectionPropertyContextOneOfHydra
  [key: string]: any
}

export type PropertyAreaJsonldReadCollectionPropertyContext =
  | string
  | PropertyAreaJsonldReadCollectionPropertyContextOneOf

export type PropertyAreaJsonldReadCollectionProjectExcelExportUnit =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyAreaJsonldReadCollectionProjectExcelExportType =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyAreaJsonldReadCollectionProjectExcelExportFloorName =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyAreaJsonldReadCollectionProjectExcelExportFloorCoating =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export interface PropertyAreaJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: PropertyAreaJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  ceilingHeight?: number | null
  floor?: number | null
  floorCoating?: PropertyAreaJsonldReadCollectionProjectExcelExportFloorCoating
  floorName?: PropertyAreaJsonldReadCollectionProjectExcelExportFloorName
  number?: number | null
  surface?: number | null
  type?: PropertyAreaJsonldReadCollectionProjectExcelExportType
  unit?: PropertyAreaJsonldReadCollectionProjectExcelExportUnit
  areaOrientations?: RefLabelJsonldReadCollectionProjectExcelExport[]
  property?: PropertyPropertyAreaJsonldReadCollectionProjectExcelExport[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyAreaJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof PropertyAreaJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof PropertyAreaJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyAreaJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyAreaJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyAreaJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyAreaJsonldReadCollectionProjectExcelExportContext =
  | string
  | PropertyAreaJsonldReadCollectionProjectExcelExportContextOneOf

export type PropertyAreaJsonldReadCollectionProjectUnit = RefLabelJsonldReadCollectionProject | null

export type PropertyAreaJsonldReadCollectionProjectType = RefLabelJsonldReadCollectionProject | null

export type PropertyAreaJsonldReadCollectionProjectFloorName =
  RefLabelJsonldReadCollectionProject | null

export type PropertyAreaJsonldReadCollectionProjectFloorCoating =
  RefLabelJsonldReadCollectionProject | null

export type PropertyAreaJsonldReadCollectionProjectContextOneOfHydra =
  (typeof PropertyAreaJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof PropertyAreaJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyAreaJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyAreaJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: PropertyAreaJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type PropertyAreaJsonldReadCollectionProjectContext =
  | string
  | PropertyAreaJsonldReadCollectionProjectContextOneOf

export interface PropertyAreaJsonldReadCollectionProject {
  readonly "@context"?: PropertyAreaJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  ceilingHeight?: number | null
  floor?: number | null
  floorCoating?: PropertyAreaJsonldReadCollectionProjectFloorCoating
  floorName?: PropertyAreaJsonldReadCollectionProjectFloorName
  number?: number | null
  surface?: number | null
  type?: PropertyAreaJsonldReadCollectionProjectType
  unit?: PropertyAreaJsonldReadCollectionProjectUnit
  areaOrientations?: RefLabelJsonldReadCollectionProject[]
  property?: PropertyPropertyAreaJsonldReadCollectionProject[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyJsonldReadItemPropertyWaterWaste = RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertyWaterHotNetwork = RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertyWaterHotMode = RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertyViewQuality = RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertyType = RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertySubtype = RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertyStyleName = RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertyStatus = RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertyStanding = RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertyPlotNetFloorUnit = RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertyPlotLandType = RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertyHeatingNetwork = RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertyFloorName = RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertyConstructionStep = RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertyCondominiumType = RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertyCondominiumCurrency =
  RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertyCondominiumFeesPeriod =
  RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertyCondition = RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertyAvailabilityStatus = RefLabelJsonldReadItemProperty | null

export type PropertyJsonldReadItemPropertyAreaUnit = RefLabelJsonldReadItemProperty | null

export interface PropertyJsonldReadItemProperty {
  readonly "@context"?: PropertyJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  areaTotal?: number | null
  areaUnit?: PropertyJsonldReadItemPropertyAreaUnit
  areaValue?: number | null
  areaWeighted?: number | null
  availabilityDate?: string | null
  availabilityStatus?: PropertyJsonldReadItemPropertyAvailabilityStatus
  buildingUnit?: string | null
  code?: string | null
  codeUpdatedDate?: string | null
  condition?: PropertyJsonldReadItemPropertyCondition
  condominiumFees?: number | null
  condominiumFeesPeriod?: PropertyJsonldReadItemPropertyCondominiumFeesPeriod
  condominiumCurrency?: PropertyJsonldReadItemPropertyCondominiumCurrency
  condominiumHeight?: string | null
  condominiumLenght?: number | null
  condominiumNbFrontage?: number | null
  condominiumNbLots?: number | null
  condominiumType?: PropertyJsonldReadItemPropertyCondominiumType
  constructionDate?: string | null
  constructionStep?: PropertyJsonldReadItemPropertyConstructionStep
  constructionYear?: number | null
  deliveryDate?: string | null
  floor?: number | null
  floorName?: PropertyJsonldReadItemPropertyFloorName
  heatingNetwork?: PropertyJsonldReadItemPropertyHeatingNetwork
  landRegister?: string | null
  name?: string | null
  nameBuilding?: string | null
  nbBathrooms?: number | null
  nbBedrooms?: number | null
  nbFloors?: number | null
  nbLevels?: number | null
  nbRooms?: number | null
  nbSleeps?: number | null
  plotHeight?: number | null
  plotNetFloor?: number | null
  plotLandType?: PropertyJsonldReadItemPropertyPlotLandType
  plotNetFloorUnit?: PropertyJsonldReadItemPropertyPlotNetFloorUnit
  plotServiced?: boolean | null
  plotWidth?: number | null
  renovationCost?: number | null
  renovationYear?: number | null
  shareRating?: number | null
  stairsNumber?: string | null
  standing?: PropertyJsonldReadItemPropertyStanding
  status?: PropertyJsonldReadItemPropertyStatus
  styleName?: PropertyJsonldReadItemPropertyStyleName
  subtype?: PropertyJsonldReadItemPropertySubtype
  type?: PropertyJsonldReadItemPropertyType
  viewQuality?: PropertyJsonldReadItemPropertyViewQuality
  waterHotMode?: PropertyJsonldReadItemPropertyWaterHotMode
  waterHotNetwork?: PropertyJsonldReadItemPropertyWaterHotNetwork
  waterWaste?: PropertyJsonldReadItemPropertyWaterWaste
  websiteUrl?: string | null
  parent?: string | null
  activities?: RefLabelJsonldReadItemProperty[]
  buildingMaterials?: RefLabelJsonldReadItemProperty[]
  contactDetails?: PropertyContactDetailsJsonldReadItemProperty[]
  media?: PropertyMediaJsonldReadItemProperty[]
  heatingEnergies?: RefLabelJsonldReadItemProperty[]
  heatingModes?: RefLabelJsonldReadItemProperty[]
  orientations?: RefLabelJsonldReadItemProperty[]
  proximities?: RefLabelJsonldReadItemProperty[]
  services?: RefLabelJsonldReadItemProperty[]
  tag?: PropertyTagJsonldReadItemProperty[]
  viewLandscapes?: RefLabelJsonldReadItemProperty[]
  propertyOffer?: ProjectPropertyOfferJsonldReadItemProperty[]
  i18nText?: I18nTextJsonldReadItemProperty[]
  propertyArea?: PropertyPropertyAreaJsonldReadItemProperty[]
  propertyOwners?: PropertyOwnersJsonldReadItemProperty[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyJsonldReadItemPropertyContextOneOfHydra =
  (typeof PropertyJsonldReadItemPropertyContextOneOfHydra)[keyof typeof PropertyJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: PropertyJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type PropertyJsonldReadItemPropertyContext =
  | string
  | PropertyJsonldReadItemPropertyContextOneOf

export type PropertyJsonldReadItemProjectWaterWaste = RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectWaterHotNetwork = RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectWaterHotMode = RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectViewQuality = RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectType = RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectSubtype = RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectStyleName = RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectStatus = RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectStanding = RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectPlotNetFloorUnit = RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectPlotLandType = RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectHeatingNetwork = RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectFloorName = RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectConstructionStep = RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectCondominiumType = RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectCondominiumCurrency = RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectCondominiumFeesPeriod =
  RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectCondition = RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectAvailabilityStatus = RefLabelJsonldReadItemProject | null

export type PropertyJsonldReadItemProjectAreaUnit = RefLabelJsonldReadItemProject | null

export interface PropertyJsonldReadItemProject {
  readonly "@context"?: PropertyJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  areaTotal?: number | null
  areaUnit?: PropertyJsonldReadItemProjectAreaUnit
  areaValue?: number | null
  areaWeighted?: number | null
  availabilityDate?: string | null
  availabilityStatus?: PropertyJsonldReadItemProjectAvailabilityStatus
  buildingUnit?: string | null
  code?: string | null
  codeUpdatedDate?: string | null
  condition?: PropertyJsonldReadItemProjectCondition
  condominiumFees?: number | null
  condominiumFeesPeriod?: PropertyJsonldReadItemProjectCondominiumFeesPeriod
  condominiumCurrency?: PropertyJsonldReadItemProjectCondominiumCurrency
  condominiumHeight?: string | null
  condominiumLenght?: number | null
  condominiumNbFrontage?: number | null
  condominiumNbLots?: number | null
  condominiumType?: PropertyJsonldReadItemProjectCondominiumType
  constructionDate?: string | null
  constructionStep?: PropertyJsonldReadItemProjectConstructionStep
  constructionYear?: number | null
  deliveryDate?: string | null
  floor?: number | null
  floorName?: PropertyJsonldReadItemProjectFloorName
  heatingNetwork?: PropertyJsonldReadItemProjectHeatingNetwork
  landRegister?: string | null
  name?: string | null
  nameBuilding?: string | null
  nbBathrooms?: number | null
  nbBedrooms?: number | null
  nbFloors?: number | null
  nbLevels?: number | null
  nbRooms?: number | null
  nbSleeps?: number | null
  plotHeight?: number | null
  plotNetFloor?: number | null
  plotLandType?: PropertyJsonldReadItemProjectPlotLandType
  plotNetFloorUnit?: PropertyJsonldReadItemProjectPlotNetFloorUnit
  plotServiced?: boolean | null
  plotWidth?: number | null
  renovationCost?: number | null
  renovationYear?: number | null
  shareRating?: number | null
  stairsNumber?: string | null
  standing?: PropertyJsonldReadItemProjectStanding
  status?: PropertyJsonldReadItemProjectStatus
  styleName?: PropertyJsonldReadItemProjectStyleName
  subtype?: PropertyJsonldReadItemProjectSubtype
  type?: PropertyJsonldReadItemProjectType
  viewQuality?: PropertyJsonldReadItemProjectViewQuality
  waterHotMode?: PropertyJsonldReadItemProjectWaterHotMode
  waterHotNetwork?: PropertyJsonldReadItemProjectWaterHotNetwork
  waterWaste?: PropertyJsonldReadItemProjectWaterWaste
  websiteUrl?: string | null
  parent?: string | null
  activities?: RefLabelJsonldReadItemProject[]
  buildingMaterials?: RefLabelJsonldReadItemProject[]
  contactDetails?: PropertyContactDetailsJsonldReadItemProject[]
  media?: PropertyMediaJsonldReadItemProject[]
  heatingEnergies?: RefLabelJsonldReadItemProject[]
  heatingModes?: RefLabelJsonldReadItemProject[]
  orientations?: RefLabelJsonldReadItemProject[]
  proximities?: RefLabelJsonldReadItemProject[]
  services?: RefLabelJsonldReadItemProject[]
  tag?: PropertyTagJsonldReadItemProject[]
  viewLandscapes?: RefLabelJsonldReadItemProject[]
  i18nText?: I18nTextJsonldReadItemProject[]
  propertyArea?: PropertyPropertyAreaJsonldReadItemProject[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyJsonldReadItemProjectContextOneOfHydra =
  (typeof PropertyJsonldReadItemProjectContextOneOfHydra)[keyof typeof PropertyJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: PropertyJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type PropertyJsonldReadItemProjectContext =
  | string
  | PropertyJsonldReadItemProjectContextOneOf

export type PropertyJsonldReadItemPeopleStatus = RefLabelJsonldReadItemPeople | null

export type PropertyJsonldReadItemPeopleContextOneOfHydra =
  (typeof PropertyJsonldReadItemPeopleContextOneOfHydra)[keyof typeof PropertyJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: PropertyJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type PropertyJsonldReadItemPeopleContext = string | PropertyJsonldReadItemPeopleContextOneOf

export interface PropertyJsonldReadItemPeople {
  readonly "@context"?: PropertyJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  status?: PropertyJsonldReadItemPeopleStatus
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyJsonldReadItemCollaboratorItemStatus =
  RefLabelJsonldReadItemCollaboratorItem | null

export interface PropertyJsonldReadItemCollaboratorItem {
  readonly "@context"?: PropertyJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  status?: PropertyJsonldReadItemCollaboratorItemStatus
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof PropertyJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof PropertyJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: PropertyJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type PropertyJsonldReadItemCollaboratorItemContext =
  | string
  | PropertyJsonldReadItemCollaboratorItemContextOneOf

export type PropertyJsonldReadCollectionPropertyExcelExportWaterWaste =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportWaterHotNetwork =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportWaterHotMode =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportViewQuality =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportType =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportSubtype =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportStyleName =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportStatus =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportStanding =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportPlotNetFloorUnit =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportPlotLandType =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportHeatingNetwork =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportFloorName =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportConstructionStep =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportCondominiumType =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportCondominiumCurrency =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportCondominiumFeesPeriod =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportCondition =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportAvailabilityStatus =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportAreaUnit =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PropertyJsonldReadCollectionPropertyExcelExportContextOneOfHydra =
  (typeof PropertyJsonldReadCollectionPropertyExcelExportContextOneOfHydra)[keyof typeof PropertyJsonldReadCollectionPropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyJsonldReadCollectionPropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyJsonldReadCollectionPropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyJsonldReadCollectionPropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyJsonldReadCollectionPropertyExcelExportContext =
  | string
  | PropertyJsonldReadCollectionPropertyExcelExportContextOneOf

export interface PropertyJsonldReadCollectionPropertyExcelExport {
  readonly "@id"?: string
  readonly "@type"?: string
  readonly "@context"?: PropertyJsonldReadCollectionPropertyExcelExportContext
  areaTotal?: number | null
  areaUnit?: PropertyJsonldReadCollectionPropertyExcelExportAreaUnit
  areaValue?: number | null
  areaWeighted?: number | null
  availabilityDate?: string | null
  availabilityStatus?: PropertyJsonldReadCollectionPropertyExcelExportAvailabilityStatus
  buildingUnit?: string | null
  code?: string | null
  codeUpdatedDate?: string | null
  condition?: PropertyJsonldReadCollectionPropertyExcelExportCondition
  condominiumFees?: number | null
  condominiumFeesPeriod?: PropertyJsonldReadCollectionPropertyExcelExportCondominiumFeesPeriod
  condominiumCurrency?: PropertyJsonldReadCollectionPropertyExcelExportCondominiumCurrency
  condominiumHeight?: string | null
  condominiumLenght?: number | null
  condominiumNbFrontage?: number | null
  condominiumNbLots?: number | null
  condominiumType?: PropertyJsonldReadCollectionPropertyExcelExportCondominiumType
  constructionDate?: string | null
  constructionStep?: PropertyJsonldReadCollectionPropertyExcelExportConstructionStep
  constructionYear?: number | null
  deliveryDate?: string | null
  floor?: number | null
  floorName?: PropertyJsonldReadCollectionPropertyExcelExportFloorName
  heatingNetwork?: PropertyJsonldReadCollectionPropertyExcelExportHeatingNetwork
  landRegister?: string | null
  name?: string | null
  nameBuilding?: string | null
  nbBathrooms?: number | null
  nbBedrooms?: number | null
  nbFloors?: number | null
  nbLevels?: number | null
  nbRooms?: number | null
  nbSleeps?: number | null
  plotHeight?: number | null
  plotNetFloor?: number | null
  plotLandType?: PropertyJsonldReadCollectionPropertyExcelExportPlotLandType
  plotNetFloorUnit?: PropertyJsonldReadCollectionPropertyExcelExportPlotNetFloorUnit
  plotServiced?: boolean | null
  plotWidth?: number | null
  renovationCost?: number | null
  renovationYear?: number | null
  shareRating?: number | null
  stairsNumber?: string | null
  standing?: PropertyJsonldReadCollectionPropertyExcelExportStanding
  status?: PropertyJsonldReadCollectionPropertyExcelExportStatus
  styleName?: PropertyJsonldReadCollectionPropertyExcelExportStyleName
  subtype?: PropertyJsonldReadCollectionPropertyExcelExportSubtype
  type?: PropertyJsonldReadCollectionPropertyExcelExportType
  viewQuality?: PropertyJsonldReadCollectionPropertyExcelExportViewQuality
  waterHotMode?: PropertyJsonldReadCollectionPropertyExcelExportWaterHotMode
  waterHotNetwork?: PropertyJsonldReadCollectionPropertyExcelExportWaterHotNetwork
  waterWaste?: PropertyJsonldReadCollectionPropertyExcelExportWaterWaste
  websiteUrl?: string | null
  parent?: string | null
  activities?: RefLabelJsonldReadCollectionPropertyExcelExport[]
  buildingMaterials?: RefLabelJsonldReadCollectionPropertyExcelExport[]
  contactDetails?: PropertyContactDetailsJsonldReadCollectionPropertyExcelExport[]
  media?: PropertyMediaJsonldReadCollectionPropertyExcelExport[]
  heatingEnergies?: RefLabelJsonldReadCollectionPropertyExcelExport[]
  heatingModes?: RefLabelJsonldReadCollectionPropertyExcelExport[]
  orientations?: RefLabelJsonldReadCollectionPropertyExcelExport[]
  proximities?: RefLabelJsonldReadCollectionPropertyExcelExport[]
  services?: RefLabelJsonldReadCollectionPropertyExcelExport[]
  tag?: PropertyTagJsonldReadCollectionPropertyExcelExport[]
  viewLandscapes?: RefLabelJsonldReadCollectionPropertyExcelExport[]
  propertyOffer?: ProjectPropertyOfferJsonldReadCollectionPropertyExcelExport[]
  i18nText?: I18nTextJsonldReadCollectionPropertyExcelExport[]
  propertyArea?: PropertyPropertyAreaJsonldReadCollectionPropertyExcelExport[]
  propertyOwners?: PropertyOwnersJsonldReadCollectionPropertyExcelExport[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyJsonldReadCollectionPropertyWaterWaste =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertyWaterHotNetwork =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertyWaterHotMode =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertyViewQuality =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertyType = RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertySubtype =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertyStyleName =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertyStatus = RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertyStanding =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertyPlotNetFloorUnit =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertyPlotLandType =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertyHeatingNetwork =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertyFloorName =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertyConstructionStep =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertyCondominiumType =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertyCondominiumCurrency =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertyCondominiumFeesPeriod =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertyCondition =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertyAvailabilityStatus =
  RefLabelJsonldReadCollectionProperty | null

export type PropertyJsonldReadCollectionPropertyAreaUnit =
  RefLabelJsonldReadCollectionProperty | null

export interface PropertyJsonldReadCollectionProperty {
  readonly "@id"?: string
  readonly "@type"?: string
  readonly "@context"?: PropertyJsonldReadCollectionPropertyContext
  areaTotal?: number | null
  areaUnit?: PropertyJsonldReadCollectionPropertyAreaUnit
  areaValue?: number | null
  areaWeighted?: number | null
  availabilityDate?: string | null
  availabilityStatus?: PropertyJsonldReadCollectionPropertyAvailabilityStatus
  buildingUnit?: string | null
  code?: string | null
  codeUpdatedDate?: string | null
  condition?: PropertyJsonldReadCollectionPropertyCondition
  condominiumFees?: number | null
  condominiumFeesPeriod?: PropertyJsonldReadCollectionPropertyCondominiumFeesPeriod
  condominiumCurrency?: PropertyJsonldReadCollectionPropertyCondominiumCurrency
  condominiumHeight?: string | null
  condominiumLenght?: number | null
  condominiumNbFrontage?: number | null
  condominiumNbLots?: number | null
  condominiumType?: PropertyJsonldReadCollectionPropertyCondominiumType
  constructionDate?: string | null
  constructionStep?: PropertyJsonldReadCollectionPropertyConstructionStep
  constructionYear?: number | null
  deliveryDate?: string | null
  floor?: number | null
  floorName?: PropertyJsonldReadCollectionPropertyFloorName
  heatingNetwork?: PropertyJsonldReadCollectionPropertyHeatingNetwork
  landRegister?: string | null
  name?: string | null
  nameBuilding?: string | null
  nbBathrooms?: number | null
  nbBedrooms?: number | null
  nbFloors?: number | null
  nbLevels?: number | null
  nbRooms?: number | null
  nbSleeps?: number | null
  plotHeight?: number | null
  plotNetFloor?: number | null
  plotLandType?: PropertyJsonldReadCollectionPropertyPlotLandType
  plotNetFloorUnit?: PropertyJsonldReadCollectionPropertyPlotNetFloorUnit
  plotServiced?: boolean | null
  plotWidth?: number | null
  renovationCost?: number | null
  renovationYear?: number | null
  shareRating?: number | null
  stairsNumber?: string | null
  standing?: PropertyJsonldReadCollectionPropertyStanding
  status?: PropertyJsonldReadCollectionPropertyStatus
  styleName?: PropertyJsonldReadCollectionPropertyStyleName
  subtype?: PropertyJsonldReadCollectionPropertySubtype
  type?: PropertyJsonldReadCollectionPropertyType
  viewQuality?: PropertyJsonldReadCollectionPropertyViewQuality
  waterHotMode?: PropertyJsonldReadCollectionPropertyWaterHotMode
  waterHotNetwork?: PropertyJsonldReadCollectionPropertyWaterHotNetwork
  waterWaste?: PropertyJsonldReadCollectionPropertyWaterWaste
  websiteUrl?: string | null
  parent?: string | null
  activities?: RefLabelJsonldReadCollectionProperty[]
  buildingMaterials?: RefLabelJsonldReadCollectionProperty[]
  contactDetails?: PropertyContactDetailsJsonldReadCollectionProperty[]
  media?: PropertyMediaJsonldReadCollectionProperty[]
  heatingEnergies?: RefLabelJsonldReadCollectionProperty[]
  heatingModes?: RefLabelJsonldReadCollectionProperty[]
  orientations?: RefLabelJsonldReadCollectionProperty[]
  proximities?: RefLabelJsonldReadCollectionProperty[]
  services?: RefLabelJsonldReadCollectionProperty[]
  tag?: PropertyTagJsonldReadCollectionProperty[]
  viewLandscapes?: RefLabelJsonldReadCollectionProperty[]
  propertyOffer?: ProjectPropertyOfferJsonldReadCollectionProperty[]
  i18nText?: I18nTextJsonldReadCollectionProperty[]
  propertyArea?: PropertyPropertyAreaJsonldReadCollectionProperty[]
  propertyOwners?: PropertyOwnersJsonldReadCollectionProperty[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyJsonldReadCollectionPropertyContextOneOfHydra =
  (typeof PropertyJsonldReadCollectionPropertyContextOneOfHydra)[keyof typeof PropertyJsonldReadCollectionPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyJsonldReadCollectionPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyJsonldReadCollectionPropertyContextOneOf = {
  "@vocab": string
  hydra: PropertyJsonldReadCollectionPropertyContextOneOfHydra
  [key: string]: any
}

export type PropertyJsonldReadCollectionPropertyContext =
  | string
  | PropertyJsonldReadCollectionPropertyContextOneOf

export type PropertyJsonldReadCollectionProjectExcelExportWaterWaste =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportWaterHotNetwork =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportWaterHotMode =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportViewQuality =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportType =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportSubtype =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportStyleName =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportStatus =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportStanding =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportPlotNetFloorUnit =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportPlotLandType =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportHeatingNetwork =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportFloorName =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportConstructionStep =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportCondominiumType =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportCondominiumCurrency =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportCondominiumFeesPeriod =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportCondition =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportAvailabilityStatus =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PropertyJsonldReadCollectionProjectExcelExportAreaUnit =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export interface PropertyJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: PropertyJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  areaTotal?: number | null
  areaUnit?: PropertyJsonldReadCollectionProjectExcelExportAreaUnit
  areaValue?: number | null
  areaWeighted?: number | null
  availabilityDate?: string | null
  availabilityStatus?: PropertyJsonldReadCollectionProjectExcelExportAvailabilityStatus
  buildingUnit?: string | null
  code?: string | null
  codeUpdatedDate?: string | null
  condition?: PropertyJsonldReadCollectionProjectExcelExportCondition
  condominiumFees?: number | null
  condominiumFeesPeriod?: PropertyJsonldReadCollectionProjectExcelExportCondominiumFeesPeriod
  condominiumCurrency?: PropertyJsonldReadCollectionProjectExcelExportCondominiumCurrency
  condominiumHeight?: string | null
  condominiumLenght?: number | null
  condominiumNbFrontage?: number | null
  condominiumNbLots?: number | null
  condominiumType?: PropertyJsonldReadCollectionProjectExcelExportCondominiumType
  constructionDate?: string | null
  constructionStep?: PropertyJsonldReadCollectionProjectExcelExportConstructionStep
  constructionYear?: number | null
  deliveryDate?: string | null
  floor?: number | null
  floorName?: PropertyJsonldReadCollectionProjectExcelExportFloorName
  heatingNetwork?: PropertyJsonldReadCollectionProjectExcelExportHeatingNetwork
  landRegister?: string | null
  name?: string | null
  nameBuilding?: string | null
  nbBathrooms?: number | null
  nbBedrooms?: number | null
  nbFloors?: number | null
  nbLevels?: number | null
  nbRooms?: number | null
  nbSleeps?: number | null
  plotHeight?: number | null
  plotNetFloor?: number | null
  plotLandType?: PropertyJsonldReadCollectionProjectExcelExportPlotLandType
  plotNetFloorUnit?: PropertyJsonldReadCollectionProjectExcelExportPlotNetFloorUnit
  plotServiced?: boolean | null
  plotWidth?: number | null
  renovationCost?: number | null
  renovationYear?: number | null
  shareRating?: number | null
  stairsNumber?: string | null
  standing?: PropertyJsonldReadCollectionProjectExcelExportStanding
  status?: PropertyJsonldReadCollectionProjectExcelExportStatus
  styleName?: PropertyJsonldReadCollectionProjectExcelExportStyleName
  subtype?: PropertyJsonldReadCollectionProjectExcelExportSubtype
  type?: PropertyJsonldReadCollectionProjectExcelExportType
  viewQuality?: PropertyJsonldReadCollectionProjectExcelExportViewQuality
  waterHotMode?: PropertyJsonldReadCollectionProjectExcelExportWaterHotMode
  waterHotNetwork?: PropertyJsonldReadCollectionProjectExcelExportWaterHotNetwork
  waterWaste?: PropertyJsonldReadCollectionProjectExcelExportWaterWaste
  websiteUrl?: string | null
  parent?: string | null
  activities?: RefLabelJsonldReadCollectionProjectExcelExport[]
  buildingMaterials?: RefLabelJsonldReadCollectionProjectExcelExport[]
  contactDetails?: PropertyContactDetailsJsonldReadCollectionProjectExcelExport[]
  media?: PropertyMediaJsonldReadCollectionProjectExcelExport[]
  heatingEnergies?: RefLabelJsonldReadCollectionProjectExcelExport[]
  heatingModes?: RefLabelJsonldReadCollectionProjectExcelExport[]
  orientations?: RefLabelJsonldReadCollectionProjectExcelExport[]
  proximities?: RefLabelJsonldReadCollectionProjectExcelExport[]
  services?: RefLabelJsonldReadCollectionProjectExcelExport[]
  tag?: PropertyTagJsonldReadCollectionProjectExcelExport[]
  viewLandscapes?: RefLabelJsonldReadCollectionProjectExcelExport[]
  i18nText?: I18nTextJsonldReadCollectionProjectExcelExport[]
  propertyArea?: PropertyPropertyAreaJsonldReadCollectionProjectExcelExport[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof PropertyJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof PropertyJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyJsonldReadCollectionProjectExcelExportContext =
  | string
  | PropertyJsonldReadCollectionProjectExcelExportContextOneOf

export type PropertyJsonldReadCollectionProjectWaterWaste =
  RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectWaterHotNetwork =
  RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectWaterHotMode =
  RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectViewQuality =
  RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectType = RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectSubtype = RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectStyleName =
  RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectStatus = RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectStanding = RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectPlotNetFloorUnit =
  RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectPlotLandType =
  RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectHeatingNetwork =
  RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectFloorName =
  RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectConstructionStep =
  RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectCondominiumType =
  RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectCondominiumCurrency =
  RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectCondominiumFeesPeriod =
  RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectCondition =
  RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectAvailabilityStatus =
  RefLabelJsonldReadCollectionProject | null

export type PropertyJsonldReadCollectionProjectAreaUnit = RefLabelJsonldReadCollectionProject | null

export interface PropertyJsonldReadCollectionProject {
  readonly "@context"?: PropertyJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  areaTotal?: number | null
  areaUnit?: PropertyJsonldReadCollectionProjectAreaUnit
  areaValue?: number | null
  areaWeighted?: number | null
  availabilityDate?: string | null
  availabilityStatus?: PropertyJsonldReadCollectionProjectAvailabilityStatus
  buildingUnit?: string | null
  code?: string | null
  codeUpdatedDate?: string | null
  condition?: PropertyJsonldReadCollectionProjectCondition
  condominiumFees?: number | null
  condominiumFeesPeriod?: PropertyJsonldReadCollectionProjectCondominiumFeesPeriod
  condominiumCurrency?: PropertyJsonldReadCollectionProjectCondominiumCurrency
  condominiumHeight?: string | null
  condominiumLenght?: number | null
  condominiumNbFrontage?: number | null
  condominiumNbLots?: number | null
  condominiumType?: PropertyJsonldReadCollectionProjectCondominiumType
  constructionDate?: string | null
  constructionStep?: PropertyJsonldReadCollectionProjectConstructionStep
  constructionYear?: number | null
  deliveryDate?: string | null
  floor?: number | null
  floorName?: PropertyJsonldReadCollectionProjectFloorName
  heatingNetwork?: PropertyJsonldReadCollectionProjectHeatingNetwork
  landRegister?: string | null
  name?: string | null
  nameBuilding?: string | null
  nbBathrooms?: number | null
  nbBedrooms?: number | null
  nbFloors?: number | null
  nbLevels?: number | null
  nbRooms?: number | null
  nbSleeps?: number | null
  plotHeight?: number | null
  plotNetFloor?: number | null
  plotLandType?: PropertyJsonldReadCollectionProjectPlotLandType
  plotNetFloorUnit?: PropertyJsonldReadCollectionProjectPlotNetFloorUnit
  plotServiced?: boolean | null
  plotWidth?: number | null
  renovationCost?: number | null
  renovationYear?: number | null
  shareRating?: number | null
  stairsNumber?: string | null
  standing?: PropertyJsonldReadCollectionProjectStanding
  status?: PropertyJsonldReadCollectionProjectStatus
  styleName?: PropertyJsonldReadCollectionProjectStyleName
  subtype?: PropertyJsonldReadCollectionProjectSubtype
  type?: PropertyJsonldReadCollectionProjectType
  viewQuality?: PropertyJsonldReadCollectionProjectViewQuality
  waterHotMode?: PropertyJsonldReadCollectionProjectWaterHotMode
  waterHotNetwork?: PropertyJsonldReadCollectionProjectWaterHotNetwork
  waterWaste?: PropertyJsonldReadCollectionProjectWaterWaste
  websiteUrl?: string | null
  parent?: string | null
  activities?: RefLabelJsonldReadCollectionProject[]
  buildingMaterials?: RefLabelJsonldReadCollectionProject[]
  contactDetails?: PropertyContactDetailsJsonldReadCollectionProject[]
  media?: PropertyMediaJsonldReadCollectionProject[]
  heatingEnergies?: RefLabelJsonldReadCollectionProject[]
  heatingModes?: RefLabelJsonldReadCollectionProject[]
  orientations?: RefLabelJsonldReadCollectionProject[]
  proximities?: RefLabelJsonldReadCollectionProject[]
  services?: RefLabelJsonldReadCollectionProject[]
  tag?: PropertyTagJsonldReadCollectionProject[]
  viewLandscapes?: RefLabelJsonldReadCollectionProject[]
  i18nText?: I18nTextJsonldReadCollectionProject[]
  propertyArea?: PropertyPropertyAreaJsonldReadCollectionProject[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PropertyJsonldReadCollectionProjectContextOneOfHydra =
  (typeof PropertyJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof PropertyJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: PropertyJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type PropertyJsonldReadCollectionProjectContext =
  | string
  | PropertyJsonldReadCollectionProjectContextOneOf

export interface PropertyJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: PropertyJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  readonly updatedAt?: string
}

export type PropertyJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof PropertyJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof PropertyJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PropertyJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PropertyJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: PropertyJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type PropertyJsonldReadCollectionPeopleExcelExportContext =
  | string
  | PropertyJsonldReadCollectionPeopleExcelExportContextOneOf

export interface ProjectPropertySearchJsonldReadItemPropertySearch {
  readonly "@context"?: ProjectPropertySearchJsonldReadItemPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  project?: ProjectJsonldReadItemPropertySearch
  order?: number | null
  readonly id: string
}

export type ProjectPropertySearchJsonldReadItemPropertySearchContextOneOfHydra =
  (typeof ProjectPropertySearchJsonldReadItemPropertySearchContextOneOfHydra)[keyof typeof ProjectPropertySearchJsonldReadItemPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertySearchJsonldReadItemPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertySearchJsonldReadItemPropertySearchContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertySearchJsonldReadItemPropertySearchContextOneOfHydra
  [key: string]: any
}

export type ProjectPropertySearchJsonldReadItemPropertySearchContext =
  | string
  | ProjectPropertySearchJsonldReadItemPropertySearchContextOneOf

export interface ProjectPropertySearchJsonldReadItemProject {
  readonly "@context"?: ProjectPropertySearchJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  propertySearch?: PropertySearchJsonldReadItemProject
  order?: number | null
  readonly id: string
}

export type ProjectPropertySearchJsonldReadItemProjectContextOneOfHydra =
  (typeof ProjectPropertySearchJsonldReadItemProjectContextOneOfHydra)[keyof typeof ProjectPropertySearchJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertySearchJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertySearchJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertySearchJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type ProjectPropertySearchJsonldReadItemProjectContext =
  | string
  | ProjectPropertySearchJsonldReadItemProjectContextOneOf

export type ProjectPropertySearchJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof ProjectPropertySearchJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof ProjectPropertySearchJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertySearchJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertySearchJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertySearchJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type ProjectPropertySearchJsonldReadItemCollaboratorItemContext =
  | string
  | ProjectPropertySearchJsonldReadItemCollaboratorItemContextOneOf

export interface ProjectPropertySearchJsonldReadItemCollaboratorItem {
  readonly "@context"?: ProjectPropertySearchJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type ProjectPropertySearchJsonldReadItemClusterItemContextOneOfHydra =
  (typeof ProjectPropertySearchJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof ProjectPropertySearchJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertySearchJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertySearchJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertySearchJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type ProjectPropertySearchJsonldReadItemClusterItemContext =
  | string
  | ProjectPropertySearchJsonldReadItemClusterItemContextOneOf

export interface ProjectPropertySearchJsonldReadItemClusterItem {
  readonly "@context"?: ProjectPropertySearchJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  propertySearch?: PropertySearchJsonldReadItemClusterItem
  order?: number | null
  readonly id: string
}

export type ProjectPropertySearchJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra =
  (typeof ProjectPropertySearchJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra)[keyof typeof ProjectPropertySearchJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertySearchJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertySearchJsonldReadCollectionPropertySearchExcelExportContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertySearchJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra
  [key: string]: any
}

export type ProjectPropertySearchJsonldReadCollectionPropertySearchExcelExportContext =
  | string
  | ProjectPropertySearchJsonldReadCollectionPropertySearchExcelExportContextOneOf

export interface ProjectPropertySearchJsonldReadCollectionPropertySearchExcelExport {
  readonly "@context"?: ProjectPropertySearchJsonldReadCollectionPropertySearchExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  project?: ProjectJsonldReadCollectionPropertySearchExcelExport
  order?: number | null
  readonly id: string
}

export type ProjectPropertySearchJsonldReadCollectionPropertySearchContextOneOfHydra =
  (typeof ProjectPropertySearchJsonldReadCollectionPropertySearchContextOneOfHydra)[keyof typeof ProjectPropertySearchJsonldReadCollectionPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertySearchJsonldReadCollectionPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertySearchJsonldReadCollectionPropertySearchContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertySearchJsonldReadCollectionPropertySearchContextOneOfHydra
  [key: string]: any
}

export type ProjectPropertySearchJsonldReadCollectionPropertySearchContext =
  | string
  | ProjectPropertySearchJsonldReadCollectionPropertySearchContextOneOf

export interface ProjectPropertySearchJsonldReadCollectionPropertySearch {
  readonly "@context"?: ProjectPropertySearchJsonldReadCollectionPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  project?: ProjectJsonldReadCollectionPropertySearch
  order?: number | null
  readonly id: string
}

export interface ProjectPropertySearchJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: ProjectPropertySearchJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  propertySearch?: PropertySearchJsonldReadCollectionProjectExcelExport
  order?: number | null
  readonly id: string
}

export type ProjectPropertySearchJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof ProjectPropertySearchJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof ProjectPropertySearchJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertySearchJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertySearchJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertySearchJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type ProjectPropertySearchJsonldReadCollectionProjectExcelExportContext =
  | string
  | ProjectPropertySearchJsonldReadCollectionProjectExcelExportContextOneOf

export interface ProjectPropertySearchJsonldReadCollectionProject {
  readonly "@context"?: ProjectPropertySearchJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  propertySearch?: PropertySearchJsonldReadCollectionProject
  order?: number | null
  readonly id: string
}

export type ProjectPropertySearchJsonldReadCollectionProjectContextOneOfHydra =
  (typeof ProjectPropertySearchJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof ProjectPropertySearchJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertySearchJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertySearchJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertySearchJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type ProjectPropertySearchJsonldReadCollectionProjectContext =
  | string
  | ProjectPropertySearchJsonldReadCollectionProjectContextOneOf

export type ProjectPropertyOfferJsonldReadItemPropertyOfferContextOneOfHydra =
  (typeof ProjectPropertyOfferJsonldReadItemPropertyOfferContextOneOfHydra)[keyof typeof ProjectPropertyOfferJsonldReadItemPropertyOfferContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertyOfferJsonldReadItemPropertyOfferContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertyOfferJsonldReadItemPropertyOfferContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertyOfferJsonldReadItemPropertyOfferContextOneOfHydra
  [key: string]: any
}

export type ProjectPropertyOfferJsonldReadItemPropertyOfferContext =
  | string
  | ProjectPropertyOfferJsonldReadItemPropertyOfferContextOneOf

export interface ProjectPropertyOfferJsonldReadItemPropertyOffer {
  readonly "@context"?: ProjectPropertyOfferJsonldReadItemPropertyOfferContext
  readonly "@id"?: string
  readonly "@type"?: string
  project?: ProjectJsonldReadItemPropertyOffer
  order?: number | null
  readonly id: string
}

export type ProjectPropertyOfferJsonldReadItemPropertyContextOneOfHydra =
  (typeof ProjectPropertyOfferJsonldReadItemPropertyContextOneOfHydra)[keyof typeof ProjectPropertyOfferJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertyOfferJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertyOfferJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertyOfferJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type ProjectPropertyOfferJsonldReadItemPropertyContext =
  | string
  | ProjectPropertyOfferJsonldReadItemPropertyContextOneOf

export interface ProjectPropertyOfferJsonldReadItemProperty {
  readonly "@context"?: ProjectPropertyOfferJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  propertyOffer?: PropertyOfferJsonldReadItemProperty
  order?: number | null
  readonly id: string
}

export interface ProjectPropertyOfferJsonldReadItemProject {
  readonly "@context"?: ProjectPropertyOfferJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  propertyOffer?: PropertyOfferJsonldReadItemProject
  order?: number | null
  property?: PropertyJsonldReadItemProject
  readonly id: string
}

export type ProjectPropertyOfferJsonldReadItemProjectContextOneOfHydra =
  (typeof ProjectPropertyOfferJsonldReadItemProjectContextOneOfHydra)[keyof typeof ProjectPropertyOfferJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertyOfferJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertyOfferJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertyOfferJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type ProjectPropertyOfferJsonldReadItemProjectContext =
  | string
  | ProjectPropertyOfferJsonldReadItemProjectContextOneOf

export type ProjectPropertyOfferJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof ProjectPropertyOfferJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof ProjectPropertyOfferJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertyOfferJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertyOfferJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertyOfferJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type ProjectPropertyOfferJsonldReadItemCollaboratorItemContext =
  | string
  | ProjectPropertyOfferJsonldReadItemCollaboratorItemContextOneOf

export interface ProjectPropertyOfferJsonldReadItemCollaboratorItem {
  readonly "@context"?: ProjectPropertyOfferJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  propertyOffer?: PropertyOfferJsonldReadItemCollaboratorItem
  order?: number | null
  property?: PropertyJsonldReadItemCollaboratorItem
  readonly id: string
}

export type ProjectPropertyOfferJsonldReadItemClusterItemContextOneOfHydra =
  (typeof ProjectPropertyOfferJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof ProjectPropertyOfferJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertyOfferJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertyOfferJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertyOfferJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type ProjectPropertyOfferJsonldReadItemClusterItemContext =
  | string
  | ProjectPropertyOfferJsonldReadItemClusterItemContextOneOf

export interface ProjectPropertyOfferJsonldReadItemClusterItem {
  readonly "@context"?: ProjectPropertyOfferJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type ProjectPropertyOfferJsonldReadCollectionPropertyOfferExcelExportContext =
  | string
  | ProjectPropertyOfferJsonldReadCollectionPropertyOfferExcelExportContextOneOf

export interface ProjectPropertyOfferJsonldReadCollectionPropertyOfferExcelExport {
  readonly "@context"?: ProjectPropertyOfferJsonldReadCollectionPropertyOfferExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  project?: ProjectJsonldReadCollectionPropertyOfferExcelExport
  order?: number | null
  readonly id: string
}

export type ProjectPropertyOfferJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra =
  (typeof ProjectPropertyOfferJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra)[keyof typeof ProjectPropertyOfferJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertyOfferJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertyOfferJsonldReadCollectionPropertyOfferExcelExportContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertyOfferJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra
  [key: string]: any
}

export interface ProjectPropertyOfferJsonldReadCollectionPropertyOffer {
  readonly "@context"?: ProjectPropertyOfferJsonldReadCollectionPropertyOfferContext
  readonly "@id"?: string
  readonly "@type"?: string
  project?: ProjectJsonldReadCollectionPropertyOffer
  order?: number | null
  readonly id: string
}

export type ProjectPropertyOfferJsonldReadCollectionPropertyOfferContextOneOfHydra =
  (typeof ProjectPropertyOfferJsonldReadCollectionPropertyOfferContextOneOfHydra)[keyof typeof ProjectPropertyOfferJsonldReadCollectionPropertyOfferContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertyOfferJsonldReadCollectionPropertyOfferContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertyOfferJsonldReadCollectionPropertyOfferContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertyOfferJsonldReadCollectionPropertyOfferContextOneOfHydra
  [key: string]: any
}

export type ProjectPropertyOfferJsonldReadCollectionPropertyOfferContext =
  | string
  | ProjectPropertyOfferJsonldReadCollectionPropertyOfferContextOneOf

export interface ProjectPropertyOfferJsonldReadCollectionPropertyExcelExport {
  readonly "@context"?: ProjectPropertyOfferJsonldReadCollectionPropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  propertyOffer?: PropertyOfferJsonldReadCollectionPropertyExcelExport
  order?: number | null
  readonly id: string
}

export type ProjectPropertyOfferJsonldReadCollectionPropertyExcelExportContextOneOfHydra =
  (typeof ProjectPropertyOfferJsonldReadCollectionPropertyExcelExportContextOneOfHydra)[keyof typeof ProjectPropertyOfferJsonldReadCollectionPropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertyOfferJsonldReadCollectionPropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertyOfferJsonldReadCollectionPropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertyOfferJsonldReadCollectionPropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type ProjectPropertyOfferJsonldReadCollectionPropertyExcelExportContext =
  | string
  | ProjectPropertyOfferJsonldReadCollectionPropertyExcelExportContextOneOf

export type ProjectPropertyOfferJsonldReadCollectionPropertyContextOneOfHydra =
  (typeof ProjectPropertyOfferJsonldReadCollectionPropertyContextOneOfHydra)[keyof typeof ProjectPropertyOfferJsonldReadCollectionPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertyOfferJsonldReadCollectionPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertyOfferJsonldReadCollectionPropertyContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertyOfferJsonldReadCollectionPropertyContextOneOfHydra
  [key: string]: any
}

export type ProjectPropertyOfferJsonldReadCollectionPropertyContext =
  | string
  | ProjectPropertyOfferJsonldReadCollectionPropertyContextOneOf

export interface ProjectPropertyOfferJsonldReadCollectionProperty {
  readonly "@context"?: ProjectPropertyOfferJsonldReadCollectionPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  propertyOffer?: PropertyOfferJsonldReadCollectionProperty
  order?: number | null
  readonly id: string
}

export type ProjectPropertyOfferJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof ProjectPropertyOfferJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof ProjectPropertyOfferJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertyOfferJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertyOfferJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertyOfferJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type ProjectPropertyOfferJsonldReadCollectionProjectExcelExportContext =
  | string
  | ProjectPropertyOfferJsonldReadCollectionProjectExcelExportContextOneOf

export interface ProjectPropertyOfferJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: ProjectPropertyOfferJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  propertyOffer?: PropertyOfferJsonldReadCollectionProjectExcelExport
  order?: number | null
  property?: PropertyJsonldReadCollectionProjectExcelExport
  readonly id: string
}

export type ProjectPropertyOfferJsonldReadCollectionProjectContextOneOfHydra =
  (typeof ProjectPropertyOfferJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof ProjectPropertyOfferJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPropertyOfferJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPropertyOfferJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: ProjectPropertyOfferJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type ProjectPropertyOfferJsonldReadCollectionProjectContext =
  | string
  | ProjectPropertyOfferJsonldReadCollectionProjectContextOneOf

export interface ProjectPropertyOfferJsonldReadCollectionProject {
  readonly "@context"?: ProjectPropertyOfferJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  propertyOffer?: PropertyOfferJsonldReadCollectionProject
  order?: number | null
  property?: PropertyJsonldReadCollectionProject
  readonly id: string
}

export type ProjectProjectAdvertiserJsonldReadItemProjectContextOneOfHydra =
  (typeof ProjectProjectAdvertiserJsonldReadItemProjectContextOneOfHydra)[keyof typeof ProjectProjectAdvertiserJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectProjectAdvertiserJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectProjectAdvertiserJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: ProjectProjectAdvertiserJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type ProjectProjectAdvertiserJsonldReadItemProjectContext =
  | string
  | ProjectProjectAdvertiserJsonldReadItemProjectContextOneOf

export interface ProjectProjectAdvertiserJsonldReadItemProject {
  readonly "@context"?: ProjectProjectAdvertiserJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  projectAdvertiser?: ProjectAdvertiserJsonldReadItemProject
  isPictureHidden?: boolean | null
  isPriceHidden?: boolean | null
  isCommentHidden?: boolean | null
  comment?: string | null
  isWatermark?: boolean | null
  readonly id: string
}

export interface ProjectProjectAdvertiserJsonldReadItemCollaboratorItem {
  readonly "@context"?: ProjectProjectAdvertiserJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type ProjectProjectAdvertiserJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof ProjectProjectAdvertiserJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof ProjectProjectAdvertiserJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectProjectAdvertiserJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectProjectAdvertiserJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: ProjectProjectAdvertiserJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type ProjectProjectAdvertiserJsonldReadItemCollaboratorItemContext =
  | string
  | ProjectProjectAdvertiserJsonldReadItemCollaboratorItemContextOneOf

export interface ProjectProjectAdvertiserJsonldReadItemClusterItem {
  readonly "@context"?: ProjectProjectAdvertiserJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type ProjectProjectAdvertiserJsonldReadItemClusterItemContextOneOfHydra =
  (typeof ProjectProjectAdvertiserJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof ProjectProjectAdvertiserJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectProjectAdvertiserJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectProjectAdvertiserJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: ProjectProjectAdvertiserJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type ProjectProjectAdvertiserJsonldReadItemClusterItemContext =
  | string
  | ProjectProjectAdvertiserJsonldReadItemClusterItemContextOneOf

export type ProjectProjectAdvertiserJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof ProjectProjectAdvertiserJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof ProjectProjectAdvertiserJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectProjectAdvertiserJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectProjectAdvertiserJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: ProjectProjectAdvertiserJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type ProjectProjectAdvertiserJsonldReadCollectionProjectExcelExportContext =
  | string
  | ProjectProjectAdvertiserJsonldReadCollectionProjectExcelExportContextOneOf

export interface ProjectProjectAdvertiserJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: ProjectProjectAdvertiserJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  projectAdvertiser?: ProjectAdvertiserJsonldReadCollectionProjectExcelExport
  isPictureHidden?: boolean | null
  isPriceHidden?: boolean | null
  isCommentHidden?: boolean | null
  comment?: string | null
  isWatermark?: boolean | null
  readonly id: string
}

export type ProjectProjectAdvertiserJsonldReadCollectionProjectContextOneOfHydra =
  (typeof ProjectProjectAdvertiserJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof ProjectProjectAdvertiserJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectProjectAdvertiserJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectProjectAdvertiserJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: ProjectProjectAdvertiserJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type ProjectProjectAdvertiserJsonldReadCollectionProjectContext =
  | string
  | ProjectProjectAdvertiserJsonldReadCollectionProjectContextOneOf

export interface ProjectProjectAdvertiserJsonldReadCollectionProject {
  readonly "@context"?: ProjectProjectAdvertiserJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  projectAdvertiser?: ProjectAdvertiserJsonldReadCollectionProject
  isPictureHidden?: boolean | null
  isPriceHidden?: boolean | null
  isCommentHidden?: boolean | null
  comment?: string | null
  isWatermark?: boolean | null
  readonly id: string
}

export type ProjectPeopleJsonldReadItemProjectRole = RefLabelJsonldReadItemProject | null

export type ProjectPeopleJsonldReadItemProjectContextOneOfHydra =
  (typeof ProjectPeopleJsonldReadItemProjectContextOneOfHydra)[keyof typeof ProjectPeopleJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPeopleJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPeopleJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: ProjectPeopleJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type ProjectPeopleJsonldReadItemProjectContext =
  | string
  | ProjectPeopleJsonldReadItemProjectContextOneOf

export interface ProjectPeopleJsonldReadItemProject {
  readonly "@context"?: ProjectPeopleJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  people?: PeopleJsonldReadItemProject
  role?: ProjectPeopleJsonldReadItemProjectRole
  readonly id: string
}

export type ProjectPeopleJsonldReadItemPeopleRole = RefLabelJsonldReadItemPeople | null

export interface ProjectPeopleJsonldReadItemPeople {
  readonly "@context"?: ProjectPeopleJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  project?: ProjectJsonldReadItemPeople
  role?: ProjectPeopleJsonldReadItemPeopleRole
  readonly id: string
}

export type ProjectPeopleJsonldReadItemPeopleContextOneOfHydra =
  (typeof ProjectPeopleJsonldReadItemPeopleContextOneOfHydra)[keyof typeof ProjectPeopleJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPeopleJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPeopleJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: ProjectPeopleJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type ProjectPeopleJsonldReadItemPeopleContext =
  | string
  | ProjectPeopleJsonldReadItemPeopleContextOneOf

export type ProjectPeopleJsonldReadItemCollaboratorItemRole =
  RefLabelJsonldReadItemCollaboratorItem | null

export interface ProjectPeopleJsonldReadItemCollaboratorItem {
  readonly "@context"?: ProjectPeopleJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  people?: PeopleJsonldReadItemCollaboratorItem
  role?: ProjectPeopleJsonldReadItemCollaboratorItemRole
  readonly id: string
}

export type ProjectPeopleJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof ProjectPeopleJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof ProjectPeopleJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPeopleJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPeopleJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: ProjectPeopleJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type ProjectPeopleJsonldReadItemCollaboratorItemContext =
  | string
  | ProjectPeopleJsonldReadItemCollaboratorItemContextOneOf

export type ProjectPeopleJsonldReadItemClusterItemRole = RefLabelJsonldReadItemClusterItem | null

export type ProjectPeopleJsonldReadItemClusterItemContextOneOfHydra =
  (typeof ProjectPeopleJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof ProjectPeopleJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPeopleJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPeopleJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: ProjectPeopleJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type ProjectPeopleJsonldReadItemClusterItemContext =
  | string
  | ProjectPeopleJsonldReadItemClusterItemContextOneOf

export interface ProjectPeopleJsonldReadItemClusterItem {
  readonly "@context"?: ProjectPeopleJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  people?: PeopleJsonldReadItemClusterItem
  role?: ProjectPeopleJsonldReadItemClusterItemRole
  readonly id: string
}

export type ProjectPeopleJsonldReadCollectionProjectExcelExportRole =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type ProjectPeopleJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof ProjectPeopleJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof ProjectPeopleJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPeopleJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPeopleJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: ProjectPeopleJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type ProjectPeopleJsonldReadCollectionProjectExcelExportContext =
  | string
  | ProjectPeopleJsonldReadCollectionProjectExcelExportContextOneOf

export interface ProjectPeopleJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: ProjectPeopleJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  people?: PeopleJsonldReadCollectionProjectExcelExport
  role?: ProjectPeopleJsonldReadCollectionProjectExcelExportRole
  readonly id: string
}

export type ProjectPeopleJsonldReadCollectionProjectRole =
  RefLabelJsonldReadCollectionProject | null

export interface ProjectPeopleJsonldReadCollectionProject {
  readonly "@context"?: ProjectPeopleJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  people?: PeopleJsonldReadCollectionProject
  role?: ProjectPeopleJsonldReadCollectionProjectRole
  readonly id: string
}

export type ProjectPeopleJsonldReadCollectionProjectContextOneOfHydra =
  (typeof ProjectPeopleJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof ProjectPeopleJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPeopleJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPeopleJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: ProjectPeopleJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type ProjectPeopleJsonldReadCollectionProjectContext =
  | string
  | ProjectPeopleJsonldReadCollectionProjectContextOneOf

export type ProjectPeopleJsonldReadCollectionPeopleExcelExportRole =
  RefLabelJsonldReadCollectionPeopleExcelExport | null

export interface ProjectPeopleJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: ProjectPeopleJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  project?: ProjectJsonldReadCollectionPeopleExcelExport
  role?: ProjectPeopleJsonldReadCollectionPeopleExcelExportRole
  readonly id: string
}

export type ProjectPeopleJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof ProjectPeopleJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof ProjectPeopleJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPeopleJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPeopleJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: ProjectPeopleJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type ProjectPeopleJsonldReadCollectionPeopleExcelExportContext =
  | string
  | ProjectPeopleJsonldReadCollectionPeopleExcelExportContextOneOf

export type ProjectPeopleJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof ProjectPeopleJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof ProjectPeopleJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPeopleJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPeopleJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: ProjectPeopleJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type ProjectPeopleJsonldReadCollectionPeopleContext =
  | string
  | ProjectPeopleJsonldReadCollectionPeopleContextOneOf

export interface ProjectPeopleJsonldReadCollectionPeople {
  readonly "@context"?: ProjectPeopleJsonldReadCollectionPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  project?: ProjectJsonldReadCollectionPeople
  readonly id: string
}

export type ProjectPeopleJsonldReadCollectionCollaboratorRole =
  RefLabelJsonldReadCollectionCollaborator | null

export interface ProjectPeopleJsonldReadCollectionCollaborator {
  readonly "@context"?: ProjectPeopleJsonldReadCollectionCollaboratorContext
  readonly "@id"?: string
  readonly "@type"?: string
  people?: PeopleJsonldReadCollectionCollaborator
  role?: ProjectPeopleJsonldReadCollectionCollaboratorRole
  readonly id: string
}

export type ProjectPeopleJsonldReadCollectionCollaboratorContextOneOfHydra =
  (typeof ProjectPeopleJsonldReadCollectionCollaboratorContextOneOfHydra)[keyof typeof ProjectPeopleJsonldReadCollectionCollaboratorContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPeopleJsonldReadCollectionCollaboratorContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectPeopleJsonldReadCollectionCollaboratorContextOneOf = {
  "@vocab": string
  hydra: ProjectPeopleJsonldReadCollectionCollaboratorContextOneOfHydra
  [key: string]: any
}

export type ProjectPeopleJsonldReadCollectionCollaboratorContext =
  | string
  | ProjectPeopleJsonldReadCollectionCollaboratorContextOneOf

export type ProjectCollaboratorJsonldReadItemProjectRole = RefLabelJsonldReadItemProject | null

export type ProjectCollaboratorJsonldReadItemProjectContextOneOfHydra =
  (typeof ProjectCollaboratorJsonldReadItemProjectContextOneOfHydra)[keyof typeof ProjectCollaboratorJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectCollaboratorJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectCollaboratorJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: ProjectCollaboratorJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type ProjectCollaboratorJsonldReadItemProjectContext =
  | string
  | ProjectCollaboratorJsonldReadItemProjectContextOneOf

export interface ProjectCollaboratorJsonldReadItemProject {
  readonly "@context"?: ProjectCollaboratorJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  collaborator?: CollaboratorJsonldReadItemProject
  role?: ProjectCollaboratorJsonldReadItemProjectRole
  isDefault?: boolean
  readonly id: string
}

export type ProjectCollaboratorJsonldReadItemCollaboratorItemRole =
  RefLabelJsonldReadItemCollaboratorItem | null

export interface ProjectCollaboratorJsonldReadItemCollaboratorItem {
  readonly "@context"?: ProjectCollaboratorJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  project?: ProjectJsonldReadItemCollaboratorItem
  role?: ProjectCollaboratorJsonldReadItemCollaboratorItemRole
  isDefault?: boolean
  readonly id: string
}

export type ProjectCollaboratorJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof ProjectCollaboratorJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof ProjectCollaboratorJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectCollaboratorJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectCollaboratorJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: ProjectCollaboratorJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type ProjectCollaboratorJsonldReadItemCollaboratorItemContext =
  | string
  | ProjectCollaboratorJsonldReadItemCollaboratorItemContextOneOf

export type ProjectCollaboratorJsonldReadCollectionProjectExcelExportRole =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export interface ProjectCollaboratorJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: ProjectCollaboratorJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  collaborator?: CollaboratorJsonldReadCollectionProjectExcelExport
  role?: ProjectCollaboratorJsonldReadCollectionProjectExcelExportRole
  isDefault?: boolean
  readonly id: string
}

export type ProjectCollaboratorJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof ProjectCollaboratorJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof ProjectCollaboratorJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectCollaboratorJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectCollaboratorJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: ProjectCollaboratorJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type ProjectCollaboratorJsonldReadCollectionProjectExcelExportContext =
  | string
  | ProjectCollaboratorJsonldReadCollectionProjectExcelExportContextOneOf

export type ProjectCollaboratorJsonldReadCollectionProjectRole =
  RefLabelJsonldReadCollectionProject | null

export type ProjectCollaboratorJsonldReadCollectionProjectContextOneOfHydra =
  (typeof ProjectCollaboratorJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof ProjectCollaboratorJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectCollaboratorJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectCollaboratorJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: ProjectCollaboratorJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type ProjectCollaboratorJsonldReadCollectionProjectContext =
  | string
  | ProjectCollaboratorJsonldReadCollectionProjectContextOneOf

export interface ProjectCollaboratorJsonldReadCollectionProject {
  readonly "@context"?: ProjectCollaboratorJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  collaborator?: CollaboratorJsonldReadCollectionProject
  role?: ProjectCollaboratorJsonldReadCollectionProjectRole
  isDefault?: boolean
  readonly id: string
}

export interface ProjectCollaboratorJsonld {
  readonly "@context"?: ProjectCollaboratorJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  project?: string
  collaborator?: string
  role?: string | null
  isDefault?: boolean
  readonly id: string
}

export type ProjectCollaboratorJsonldContextOneOfHydra =
  (typeof ProjectCollaboratorJsonldContextOneOfHydra)[keyof typeof ProjectCollaboratorJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectCollaboratorJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectCollaboratorJsonldContextOneOf = {
  "@vocab": string
  hydra: ProjectCollaboratorJsonldContextOneOfHydra
  [key: string]: any
}

export type ProjectCollaboratorJsonldContext = string | ProjectCollaboratorJsonldContextOneOf

export type ProjectClusterDTOJsonldContextOneOfHydra =
  (typeof ProjectClusterDTOJsonldContextOneOfHydra)[keyof typeof ProjectClusterDTOJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectClusterDTOJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectClusterDTOJsonldContextOneOf = {
  "@vocab": string
  hydra: ProjectClusterDTOJsonldContextOneOfHydra
  [key: string]: any
}

export type ProjectClusterDTOJsonldContext = string | ProjectClusterDTOJsonldContextOneOf

export interface ProjectClusterDTOJsonld {
  readonly "@context"?: ProjectClusterDTOJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  project?: string
  order?: number
}

export interface ProjectClusterDTO {
  project?: string
  order?: number
}

export interface ProjectClusterJsonldReadItemProject {
  readonly "@context"?: ProjectClusterJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number | null
  cluster?: ClusterJsonldReadItemProject
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectClusterJsonldReadItemProjectContextOneOfHydra =
  (typeof ProjectClusterJsonldReadItemProjectContextOneOfHydra)[keyof typeof ProjectClusterJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectClusterJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectClusterJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: ProjectClusterJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type ProjectClusterJsonldReadItemProjectContext =
  | string
  | ProjectClusterJsonldReadItemProjectContextOneOf

export interface ProjectClusterJsonldReadItemClusterItem {
  readonly "@context"?: ProjectClusterJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number | null
  project?: ProjectJsonldReadItemClusterItem
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectClusterJsonldReadItemClusterItemContextOneOfHydra =
  (typeof ProjectClusterJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof ProjectClusterJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectClusterJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectClusterJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: ProjectClusterJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type ProjectClusterJsonldReadItemClusterItemContext =
  | string
  | ProjectClusterJsonldReadItemClusterItemContextOneOf

export type ProjectClusterJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof ProjectClusterJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof ProjectClusterJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectClusterJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectClusterJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: ProjectClusterJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type ProjectClusterJsonldReadCollectionProjectExcelExportContext =
  | string
  | ProjectClusterJsonldReadCollectionProjectExcelExportContextOneOf

export interface ProjectClusterJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: ProjectClusterJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number | null
  cluster?: ClusterJsonldReadCollectionProjectExcelExport
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectClusterJsonldReadCollectionProjectContextOneOfHydra =
  (typeof ProjectClusterJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof ProjectClusterJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectClusterJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectClusterJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: ProjectClusterJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type ProjectClusterJsonldReadCollectionProjectContext =
  | string
  | ProjectClusterJsonldReadCollectionProjectContextOneOf

export interface ProjectClusterJsonldReadCollectionProject {
  readonly "@context"?: ProjectClusterJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number | null
  cluster?: ClusterJsonldReadCollectionProject
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra =
  (typeof ProjectClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra)[keyof typeof ProjectClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectClusterJsonldReadCollectionClusterExcelExportContextOneOf = {
  "@vocab": string
  hydra: ProjectClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra
  [key: string]: any
}

export type ProjectClusterJsonldReadCollectionClusterExcelExportContext =
  | string
  | ProjectClusterJsonldReadCollectionClusterExcelExportContextOneOf

export interface ProjectClusterJsonldReadCollectionClusterExcelExport {
  readonly "@context"?: ProjectClusterJsonldReadCollectionClusterExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number | null
  project?: ProjectJsonldReadCollectionClusterExcelExport
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectClusterJsonldContextOneOfHydra =
  (typeof ProjectClusterJsonldContextOneOfHydra)[keyof typeof ProjectClusterJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectClusterJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectClusterJsonldContextOneOf = {
  "@vocab": string
  hydra: ProjectClusterJsonldContextOneOfHydra
  [key: string]: any
}

export type ProjectClusterJsonldContext = string | ProjectClusterJsonldContextOneOf

export interface ProjectClusterJsonld {
  readonly "@context"?: ProjectClusterJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number | null
  project?: string
  cluster?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface ProjectAdvertiserJsonldReadItemProject {
  readonly "@context"?: ProjectAdvertiserJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  name?: string | null
  url?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectAdvertiserJsonldReadItemProjectContextOneOfHydra =
  (typeof ProjectAdvertiserJsonldReadItemProjectContextOneOfHydra)[keyof typeof ProjectAdvertiserJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectAdvertiserJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectAdvertiserJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: ProjectAdvertiserJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type ProjectAdvertiserJsonldReadItemProjectContext =
  | string
  | ProjectAdvertiserJsonldReadItemProjectContextOneOf

export interface ProjectAdvertiserJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: ProjectAdvertiserJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  name?: string | null
  url?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectAdvertiserJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof ProjectAdvertiserJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof ProjectAdvertiserJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectAdvertiserJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectAdvertiserJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: ProjectAdvertiserJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type ProjectAdvertiserJsonldReadCollectionProjectExcelExportContext =
  | string
  | ProjectAdvertiserJsonldReadCollectionProjectExcelExportContextOneOf

export type ProjectAdvertiserJsonldReadCollectionProjectContextOneOfHydra =
  (typeof ProjectAdvertiserJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof ProjectAdvertiserJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectAdvertiserJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectAdvertiserJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: ProjectAdvertiserJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type ProjectAdvertiserJsonldReadCollectionProjectContext =
  | string
  | ProjectAdvertiserJsonldReadCollectionProjectContextOneOf

export interface ProjectAdvertiserJsonldReadCollectionProject {
  readonly "@context"?: ProjectAdvertiserJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  name?: string | null
  url?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectJsonldReadItemPropertySearchContextOneOfHydra =
  (typeof ProjectJsonldReadItemPropertySearchContextOneOfHydra)[keyof typeof ProjectJsonldReadItemPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectJsonldReadItemPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectJsonldReadItemPropertySearchContextOneOf = {
  "@vocab": string
  hydra: ProjectJsonldReadItemPropertySearchContextOneOfHydra
  [key: string]: any
}

export type ProjectJsonldReadItemPropertySearchContext =
  | string
  | ProjectJsonldReadItemPropertySearchContextOneOf

export interface ProjectJsonldReadItemPropertySearch {
  readonly "@context"?: ProjectJsonldReadItemPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface ProjectJsonldReadItemPropertyOffer {
  readonly "@context"?: ProjectJsonldReadItemPropertyOfferContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectJsonldReadItemPropertyOfferContextOneOfHydra =
  (typeof ProjectJsonldReadItemPropertyOfferContextOneOfHydra)[keyof typeof ProjectJsonldReadItemPropertyOfferContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectJsonldReadItemPropertyOfferContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectJsonldReadItemPropertyOfferContextOneOf = {
  "@vocab": string
  hydra: ProjectJsonldReadItemPropertyOfferContextOneOfHydra
  [key: string]: any
}

export type ProjectJsonldReadItemPropertyOfferContext =
  | string
  | ProjectJsonldReadItemPropertyOfferContextOneOf

export type ProjectJsonldReadItemProjectType = RefLabelJsonldReadItemProject | null

export type ProjectJsonldReadItemProjectSubtype = RefLabelJsonldReadItemProject | null

export type ProjectJsonldReadItemProjectStatus = RefLabelJsonldReadItemProject | null

export type ProjectJsonldReadItemProjectAgreement = RefLabelJsonldReadItemProject | null

export interface ProjectJsonldReadItemProject {
  readonly "@context"?: ProjectJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  agreement?: ProjectJsonldReadItemProjectAgreement
  agreementFrom?: string | null
  agreementReference?: string | null
  agreementTo?: string | null
  comment?: string | null
  commentInternal?: string | null
  status?: ProjectJsonldReadItemProjectStatus
  statusComment?: string | null
  subtype?: ProjectJsonldReadItemProjectSubtype
  type?: ProjectJsonldReadItemProjectType
  visitInstructions?: string | null
  visitKeyNumber?: string | null
  cluster?: ProjectClusterJsonldReadItemProject[]
  collaborator?: ProjectCollaboratorJsonldReadItemProject[]
  propertyOffer?: ProjectPropertyOfferJsonldReadItemProject[]
  propertySearch?: ProjectPropertySearchJsonldReadItemProject[]
  people?: ProjectPeopleJsonldReadItemProject[]
  projectAdvertiser?: ProjectProjectAdvertiserJsonldReadItemProject[]
  readonly wwwbiCanAccessDate?: string | null
  readonly wwwbiHasAccessDate?: string | null
  isPublic?: boolean
  isShared?: boolean
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectJsonldReadItemProjectContextOneOfHydra =
  (typeof ProjectJsonldReadItemProjectContextOneOfHydra)[keyof typeof ProjectJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: ProjectJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type ProjectJsonldReadItemProjectContext = string | ProjectJsonldReadItemProjectContextOneOf

export type ProjectJsonldReadItemPeopleType = RefLabelJsonldReadItemPeople | null

export type ProjectJsonldReadItemPeopleStatus = RefLabelJsonldReadItemPeople | null

export type ProjectJsonldReadItemPeopleContext = string | ProjectJsonldReadItemPeopleContextOneOf

export interface ProjectJsonldReadItemPeople {
  readonly "@context"?: ProjectJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  status?: ProjectJsonldReadItemPeopleStatus
  type?: ProjectJsonldReadItemPeopleType
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectJsonldReadItemPeopleContextOneOfHydra =
  (typeof ProjectJsonldReadItemPeopleContextOneOfHydra)[keyof typeof ProjectJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: ProjectJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type ProjectJsonldReadItemCollaboratorItemType =
  RefLabelJsonldReadItemCollaboratorItem | null

export type ProjectJsonldReadItemCollaboratorItemSubtype =
  RefLabelJsonldReadItemCollaboratorItem | null

export type ProjectJsonldReadItemCollaboratorItemStatus =
  RefLabelJsonldReadItemCollaboratorItem | null

export type ProjectJsonldReadItemCollaboratorItemAgreement =
  RefLabelJsonldReadItemCollaboratorItem | null

export type ProjectJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof ProjectJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof ProjectJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: ProjectJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type ProjectJsonldReadItemCollaboratorItemContext =
  | string
  | ProjectJsonldReadItemCollaboratorItemContextOneOf

export interface ProjectJsonldReadItemCollaboratorItem {
  readonly "@context"?: ProjectJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  agreement?: ProjectJsonldReadItemCollaboratorItemAgreement
  agreementFrom?: string | null
  agreementReference?: string | null
  agreementTo?: string | null
  comment?: string | null
  commentInternal?: string | null
  status?: ProjectJsonldReadItemCollaboratorItemStatus
  statusComment?: string | null
  subtype?: ProjectJsonldReadItemCollaboratorItemSubtype
  type?: ProjectJsonldReadItemCollaboratorItemType
  visitInstructions?: string | null
  visitKeyNumber?: string | null
  propertyOffer?: ProjectPropertyOfferJsonldReadItemCollaboratorItem[]
  propertySearch?: ProjectPropertySearchJsonldReadItemCollaboratorItem[]
  people?: ProjectPeopleJsonldReadItemCollaboratorItem[]
  projectAdvertiser?: ProjectProjectAdvertiserJsonldReadItemCollaboratorItem[]
  readonly wwwbiCanAccessDate?: string | null
  readonly wwwbiHasAccessDate?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectJsonldReadItemClusterItemType = RefLabelJsonldReadItemClusterItem | null

export type ProjectJsonldReadItemClusterItemSubtype = RefLabelJsonldReadItemClusterItem | null

export type ProjectJsonldReadItemClusterItemStatus = RefLabelJsonldReadItemClusterItem | null

export type ProjectJsonldReadItemClusterItemAgreement = RefLabelJsonldReadItemClusterItem | null

export interface ProjectJsonldReadItemClusterItem {
  readonly "@context"?: ProjectJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  agreement?: ProjectJsonldReadItemClusterItemAgreement
  agreementFrom?: string | null
  agreementReference?: string | null
  agreementTo?: string | null
  comment?: string | null
  commentInternal?: string | null
  status?: ProjectJsonldReadItemClusterItemStatus
  statusComment?: string | null
  subtype?: ProjectJsonldReadItemClusterItemSubtype
  type?: ProjectJsonldReadItemClusterItemType
  visitInstructions?: string | null
  visitKeyNumber?: string | null
  propertyOffer?: ProjectPropertyOfferJsonldReadItemClusterItem[]
  propertySearch?: ProjectPropertySearchJsonldReadItemClusterItem[]
  people?: ProjectPeopleJsonldReadItemClusterItem[]
  projectAdvertiser?: ProjectProjectAdvertiserJsonldReadItemClusterItem[]
  readonly wwwbiCanAccessDate?: string | null
  readonly wwwbiHasAccessDate?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectJsonldReadItemClusterItemContextOneOfHydra =
  (typeof ProjectJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof ProjectJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: ProjectJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type ProjectJsonldReadItemClusterItemContext =
  | string
  | ProjectJsonldReadItemClusterItemContextOneOf

export type ProjectJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra =
  (typeof ProjectJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra)[keyof typeof ProjectJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf = {
  "@vocab": string
  hydra: ProjectJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra
  [key: string]: any
}

export type ProjectJsonldReadCollectionSharedDataReadItemSharedDataContext =
  | string
  | ProjectJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf

export interface ProjectJsonldReadCollectionSharedDataReadItemSharedData {
  readonly "@context"?: ProjectJsonldReadCollectionSharedDataReadItemSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectJsonldReadCollectionSharedDataContextOneOfHydra =
  (typeof ProjectJsonldReadCollectionSharedDataContextOneOfHydra)[keyof typeof ProjectJsonldReadCollectionSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectJsonldReadCollectionSharedDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectJsonldReadCollectionSharedDataContextOneOf = {
  "@vocab": string
  hydra: ProjectJsonldReadCollectionSharedDataContextOneOfHydra
  [key: string]: any
}

export type ProjectJsonldReadCollectionSharedDataContext =
  | string
  | ProjectJsonldReadCollectionSharedDataContextOneOf

export interface ProjectJsonldReadCollectionSharedData {
  readonly "@context"?: ProjectJsonldReadCollectionSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface ProjectJsonldReadCollectionPropertySearchExcelExport {
  readonly "@context"?: ProjectJsonldReadCollectionPropertySearchExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra =
  (typeof ProjectJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra)[keyof typeof ProjectJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectJsonldReadCollectionPropertySearchExcelExportContextOneOf = {
  "@vocab": string
  hydra: ProjectJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra
  [key: string]: any
}

export type ProjectJsonldReadCollectionPropertySearchExcelExportContext =
  | string
  | ProjectJsonldReadCollectionPropertySearchExcelExportContextOneOf

export interface ProjectJsonldReadCollectionPropertySearch {
  readonly "@context"?: ProjectJsonldReadCollectionPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectJsonldReadCollectionPropertySearchContextOneOfHydra =
  (typeof ProjectJsonldReadCollectionPropertySearchContextOneOfHydra)[keyof typeof ProjectJsonldReadCollectionPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectJsonldReadCollectionPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectJsonldReadCollectionPropertySearchContextOneOf = {
  "@vocab": string
  hydra: ProjectJsonldReadCollectionPropertySearchContextOneOfHydra
  [key: string]: any
}

export type ProjectJsonldReadCollectionPropertySearchContext =
  | string
  | ProjectJsonldReadCollectionPropertySearchContextOneOf

export type ProjectJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra =
  (typeof ProjectJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra)[keyof typeof ProjectJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectJsonldReadCollectionPropertyOfferExcelExportContextOneOf = {
  "@vocab": string
  hydra: ProjectJsonldReadCollectionPropertyOfferExcelExportContextOneOfHydra
  [key: string]: any
}

export type ProjectJsonldReadCollectionPropertyOfferExcelExportContext =
  | string
  | ProjectJsonldReadCollectionPropertyOfferExcelExportContextOneOf

export interface ProjectJsonldReadCollectionPropertyOfferExcelExport {
  readonly "@context"?: ProjectJsonldReadCollectionPropertyOfferExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectJsonldReadCollectionPropertyOfferContextOneOfHydra =
  (typeof ProjectJsonldReadCollectionPropertyOfferContextOneOfHydra)[keyof typeof ProjectJsonldReadCollectionPropertyOfferContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectJsonldReadCollectionPropertyOfferContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectJsonldReadCollectionPropertyOfferContextOneOf = {
  "@vocab": string
  hydra: ProjectJsonldReadCollectionPropertyOfferContextOneOfHydra
  [key: string]: any
}

export type ProjectJsonldReadCollectionPropertyOfferContext =
  | string
  | ProjectJsonldReadCollectionPropertyOfferContextOneOf

export interface ProjectJsonldReadCollectionPropertyOffer {
  readonly "@context"?: ProjectJsonldReadCollectionPropertyOfferContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectJsonldReadCollectionProjectExcelExportType =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type ProjectJsonldReadCollectionProjectExcelExportSubtype =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type ProjectJsonldReadCollectionProjectExcelExportStatus =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type ProjectJsonldReadCollectionProjectExcelExportAgreement =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export interface ProjectJsonldReadCollectionProjectExcelExport {
  readonly "@id"?: string
  readonly "@type"?: string
  agreement?: ProjectJsonldReadCollectionProjectExcelExportAgreement
  agreementFrom?: string | null
  agreementReference?: string | null
  agreementTo?: string | null
  comment?: string | null
  commentInternal?: string | null
  status?: ProjectJsonldReadCollectionProjectExcelExportStatus
  statusComment?: string | null
  subtype?: ProjectJsonldReadCollectionProjectExcelExportSubtype
  type?: ProjectJsonldReadCollectionProjectExcelExportType
  visitInstructions?: string | null
  visitKeyNumber?: string | null
  cluster?: ProjectClusterJsonldReadCollectionProjectExcelExport[]
  collaborator?: ProjectCollaboratorJsonldReadCollectionProjectExcelExport[]
  propertyOffer?: ProjectPropertyOfferJsonldReadCollectionProjectExcelExport[]
  propertySearch?: ProjectPropertySearchJsonldReadCollectionProjectExcelExport[]
  people?: ProjectPeopleJsonldReadCollectionProjectExcelExport[]
  projectAdvertiser?: ProjectProjectAdvertiserJsonldReadCollectionProjectExcelExport[]
  readonly wwwbiCanAccessDate?: string | null
  readonly wwwbiHasAccessDate?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectJsonldReadCollectionProjectType = RefLabelJsonldReadCollectionProject | null

export type ProjectJsonldReadCollectionProjectSubtype = RefLabelJsonldReadCollectionProject | null

export type ProjectJsonldReadCollectionProjectStatus = RefLabelJsonldReadCollectionProject | null

export type ProjectJsonldReadCollectionProjectAgreement = RefLabelJsonldReadCollectionProject | null

export interface ProjectJsonldReadCollectionProject {
  readonly "@id"?: string
  readonly "@type"?: string
  agreement?: ProjectJsonldReadCollectionProjectAgreement
  agreementFrom?: string | null
  agreementReference?: string | null
  agreementTo?: string | null
  comment?: string | null
  commentInternal?: string | null
  status?: ProjectJsonldReadCollectionProjectStatus
  statusComment?: string | null
  subtype?: ProjectJsonldReadCollectionProjectSubtype
  type?: ProjectJsonldReadCollectionProjectType
  visitInstructions?: string | null
  visitKeyNumber?: string | null
  cluster?: ProjectClusterJsonldReadCollectionProject[]
  collaborator?: ProjectCollaboratorJsonldReadCollectionProject[]
  propertyOffer?: ProjectPropertyOfferJsonldReadCollectionProject[]
  propertySearch?: ProjectPropertySearchJsonldReadCollectionProject[]
  people?: ProjectPeopleJsonldReadCollectionProject[]
  projectAdvertiser?: ProjectProjectAdvertiserJsonldReadCollectionProject[]
  readonly wwwbiCanAccessDate?: string | null
  readonly wwwbiHasAccessDate?: string | null
  isPublic?: boolean
  isShared?: boolean
  sharedData?: SharedDataJsonldReadCollectionProject[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof ProjectJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof ProjectJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: ProjectJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type ProjectJsonldReadCollectionPeopleExcelExportContext =
  | string
  | ProjectJsonldReadCollectionPeopleExcelExportContextOneOf

export interface ProjectJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: ProjectJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  readonly updatedAt?: string
}

export type ProjectJsonldReadCollectionPeopleType = RefLabelJsonldReadCollectionPeople | null

export type ProjectJsonldReadCollectionPeopleStatus = RefLabelJsonldReadCollectionPeople | null

export interface ProjectJsonldReadCollectionPeople {
  readonly "@context"?: ProjectJsonldReadCollectionPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  status?: ProjectJsonldReadCollectionPeopleStatus
  type?: ProjectJsonldReadCollectionPeopleType
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof ProjectJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof ProjectJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: ProjectJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type ProjectJsonldReadCollectionPeopleContext =
  | string
  | ProjectJsonldReadCollectionPeopleContextOneOf

export type ProjectJsonldReadCollectionCollaboratorType =
  RefLabelJsonldReadCollectionCollaborator | null

export type ProjectJsonldReadCollectionCollaboratorSubtype =
  RefLabelJsonldReadCollectionCollaborator | null

export type ProjectJsonldReadCollectionCollaboratorStatus =
  RefLabelJsonldReadCollectionCollaborator | null

export type ProjectJsonldReadCollectionCollaboratorAgreement =
  RefLabelJsonldReadCollectionCollaborator | null

export type ProjectJsonldReadCollectionCollaboratorContextOneOfHydra =
  (typeof ProjectJsonldReadCollectionCollaboratorContextOneOfHydra)[keyof typeof ProjectJsonldReadCollectionCollaboratorContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectJsonldReadCollectionCollaboratorContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectJsonldReadCollectionCollaboratorContextOneOf = {
  "@vocab": string
  hydra: ProjectJsonldReadCollectionCollaboratorContextOneOfHydra
  [key: string]: any
}

export type ProjectJsonldReadCollectionCollaboratorContext =
  | string
  | ProjectJsonldReadCollectionCollaboratorContextOneOf

export interface ProjectJsonldReadCollectionCollaborator {
  readonly "@context"?: ProjectJsonldReadCollectionCollaboratorContext
  readonly "@id"?: string
  readonly "@type"?: string
  agreement?: ProjectJsonldReadCollectionCollaboratorAgreement
  status?: ProjectJsonldReadCollectionCollaboratorStatus
  subtype?: ProjectJsonldReadCollectionCollaboratorSubtype
  type?: ProjectJsonldReadCollectionCollaboratorType
  people?: ProjectPeopleJsonldReadCollectionCollaborator[]
  readonly wwwbiCanAccessDate?: string | null
  readonly wwwbiHasAccessDate?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ProjectJsonldReadCollectionClusterExcelExportContextOneOfHydra =
  (typeof ProjectJsonldReadCollectionClusterExcelExportContextOneOfHydra)[keyof typeof ProjectJsonldReadCollectionClusterExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectJsonldReadCollectionClusterExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ProjectJsonldReadCollectionClusterExcelExportContextOneOf = {
  "@vocab": string
  hydra: ProjectJsonldReadCollectionClusterExcelExportContextOneOfHydra
  [key: string]: any
}

export type ProjectJsonldReadCollectionClusterExcelExportContext =
  | string
  | ProjectJsonldReadCollectionClusterExcelExportContextOneOf

export interface ProjectJsonldReadCollectionClusterExcelExport {
  readonly "@context"?: ProjectJsonldReadCollectionClusterExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PointJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra =
  (typeof PointJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra)[keyof typeof PointJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PointJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PointJsonldReadItemTpropertyWithoutPrivateDataContextOneOf = {
  "@vocab": string
  hydra: PointJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra
  [key: string]: any
}

export type PointJsonldReadItemTpropertyWithoutPrivateDataContext =
  | string
  | PointJsonldReadItemTpropertyWithoutPrivateDataContextOneOf

export interface PointJsonldReadItemTpropertyWithoutPrivateData {
  readonly "@context"?: PointJsonldReadItemTpropertyWithoutPrivateDataContext
  readonly "@id"?: string
  readonly "@type"?: string
}

export type PeopleSpouseJsonldReadItemProjectContextOneOfHydra =
  (typeof PeopleSpouseJsonldReadItemProjectContextOneOfHydra)[keyof typeof PeopleSpouseJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleSpouseJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleSpouseJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: PeopleSpouseJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type PeopleSpouseJsonldReadItemProjectContext =
  | string
  | PeopleSpouseJsonldReadItemProjectContextOneOf

export interface PeopleSpouseJsonldReadItemProject {
  readonly "@context"?: PeopleSpouseJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export interface PeopleSpouseJsonldReadItemPeople {
  readonly "@context"?: PeopleSpouseJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type PeopleSpouseJsonldReadItemPeopleContextOneOfHydra =
  (typeof PeopleSpouseJsonldReadItemPeopleContextOneOfHydra)[keyof typeof PeopleSpouseJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleSpouseJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleSpouseJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: PeopleSpouseJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type PeopleSpouseJsonldReadItemPeopleContext =
  | string
  | PeopleSpouseJsonldReadItemPeopleContextOneOf

export interface PeopleSpouseJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: PeopleSpouseJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type PeopleSpouseJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof PeopleSpouseJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof PeopleSpouseJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleSpouseJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleSpouseJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: PeopleSpouseJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type PeopleSpouseJsonldReadCollectionProjectExcelExportContext =
  | string
  | PeopleSpouseJsonldReadCollectionProjectExcelExportContextOneOf

export type PeopleSpouseJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof PeopleSpouseJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof PeopleSpouseJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleSpouseJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleSpouseJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: PeopleSpouseJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type PeopleSpouseJsonldReadCollectionPeopleExcelExportContext =
  | string
  | PeopleSpouseJsonldReadCollectionPeopleExcelExportContextOneOf

export interface PeopleSpouseJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: PeopleSpouseJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type PeopleMediaJsonldReadItemPropertyContextOneOfHydra =
  (typeof PeopleMediaJsonldReadItemPropertyContextOneOfHydra)[keyof typeof PeopleMediaJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleMediaJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleMediaJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: PeopleMediaJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type PeopleMediaJsonldReadItemPropertyContext =
  | string
  | PeopleMediaJsonldReadItemPropertyContextOneOf

export interface PeopleMediaJsonldReadItemProperty {
  readonly "@context"?: PeopleMediaJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export interface PeopleMediaJsonldReadItemPeople {
  readonly "@context"?: PeopleMediaJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  people?: PeopleJsonldReadItemPeople
  media?: MediaJsonldReadItemPeople
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
}

export type PeopleMediaJsonldReadItemPeopleContextOneOfHydra =
  (typeof PeopleMediaJsonldReadItemPeopleContextOneOfHydra)[keyof typeof PeopleMediaJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleMediaJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleMediaJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: PeopleMediaJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type PeopleMediaJsonldReadItemPeopleContext =
  | string
  | PeopleMediaJsonldReadItemPeopleContextOneOf

export type PeopleMediaJsonldReadCollectionPropertyExcelExportContextOneOfHydra =
  (typeof PeopleMediaJsonldReadCollectionPropertyExcelExportContextOneOfHydra)[keyof typeof PeopleMediaJsonldReadCollectionPropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleMediaJsonldReadCollectionPropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleMediaJsonldReadCollectionPropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: PeopleMediaJsonldReadCollectionPropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type PeopleMediaJsonldReadCollectionPropertyExcelExportContext =
  | string
  | PeopleMediaJsonldReadCollectionPropertyExcelExportContextOneOf

export interface PeopleMediaJsonldReadCollectionPropertyExcelExport {
  readonly "@context"?: PeopleMediaJsonldReadCollectionPropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export interface PeopleMediaJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: PeopleMediaJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  people?: PeopleJsonldReadCollectionPeopleExcelExport
  media?: MediaJsonldReadCollectionPeopleExcelExport
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
}

export type PeopleMediaJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof PeopleMediaJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof PeopleMediaJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleMediaJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleMediaJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: PeopleMediaJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type PeopleMediaJsonldReadCollectionPeopleExcelExportContext =
  | string
  | PeopleMediaJsonldReadCollectionPeopleExcelExportContextOneOf

export type PeopleMediaJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof PeopleMediaJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof PeopleMediaJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleMediaJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleMediaJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: PeopleMediaJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type PeopleMediaJsonldReadCollectionPeopleContext =
  | string
  | PeopleMediaJsonldReadCollectionPeopleContextOneOf

export interface PeopleMediaJsonldReadCollectionPeople {
  readonly "@context"?: PeopleMediaJsonldReadCollectionPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  people?: PeopleJsonldReadCollectionPeople
  media?: MediaJsonldReadCollectionPeople
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
}

export interface PeopleMediaJsonld {
  readonly "@context"?: PeopleMediaJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  people?: string
  media?: MediaJsonld
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
}

export type PeopleMediaJsonldContextOneOfHydra =
  (typeof PeopleMediaJsonldContextOneOfHydra)[keyof typeof PeopleMediaJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleMediaJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleMediaJsonldContextOneOf = {
  "@vocab": string
  hydra: PeopleMediaJsonldContextOneOfHydra
  [key: string]: any
}

export type PeopleMediaJsonldContext = string | PeopleMediaJsonldContextOneOf

export interface PeopleLocaleJsonldReadItemProperty {
  readonly "@context"?: PeopleLocaleJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type PeopleLocaleJsonldReadItemPropertyContextOneOfHydra =
  (typeof PeopleLocaleJsonldReadItemPropertyContextOneOfHydra)[keyof typeof PeopleLocaleJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleLocaleJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleLocaleJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: PeopleLocaleJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type PeopleLocaleJsonldReadItemPropertyContext =
  | string
  | PeopleLocaleJsonldReadItemPropertyContextOneOf

export type PeopleLocaleJsonldReadItemProjectRefLabel = RefLabelJsonldReadItemProject | null

export type PeopleLocaleJsonldReadItemProjectContextOneOfHydra =
  (typeof PeopleLocaleJsonldReadItemProjectContextOneOfHydra)[keyof typeof PeopleLocaleJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleLocaleJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleLocaleJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: PeopleLocaleJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type PeopleLocaleJsonldReadItemProjectContext =
  | string
  | PeopleLocaleJsonldReadItemProjectContextOneOf

export interface PeopleLocaleJsonldReadItemProject {
  readonly "@context"?: PeopleLocaleJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  isDefault?: boolean | null
  refLabel?: PeopleLocaleJsonldReadItemProjectRefLabel
  readonly id: string
}

export type PeopleLocaleJsonldReadItemPeopleRefLabel = RefLabelJsonldReadItemPeople | null

export interface PeopleLocaleJsonldReadItemPeople {
  readonly "@context"?: PeopleLocaleJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  isDefault?: boolean | null
  refLabel?: PeopleLocaleJsonldReadItemPeopleRefLabel
  readonly id: string
}

export type PeopleLocaleJsonldReadItemPeopleContextOneOfHydra =
  (typeof PeopleLocaleJsonldReadItemPeopleContextOneOfHydra)[keyof typeof PeopleLocaleJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleLocaleJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleLocaleJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: PeopleLocaleJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type PeopleLocaleJsonldReadItemPeopleContext =
  | string
  | PeopleLocaleJsonldReadItemPeopleContextOneOf

export type PeopleLocaleJsonldReadCollectionPropertyExcelExportContextOneOfHydra =
  (typeof PeopleLocaleJsonldReadCollectionPropertyExcelExportContextOneOfHydra)[keyof typeof PeopleLocaleJsonldReadCollectionPropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleLocaleJsonldReadCollectionPropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleLocaleJsonldReadCollectionPropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: PeopleLocaleJsonldReadCollectionPropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type PeopleLocaleJsonldReadCollectionPropertyExcelExportContext =
  | string
  | PeopleLocaleJsonldReadCollectionPropertyExcelExportContextOneOf

export interface PeopleLocaleJsonldReadCollectionPropertyExcelExport {
  readonly "@context"?: PeopleLocaleJsonldReadCollectionPropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type PeopleLocaleJsonldReadCollectionPropertyContextOneOfHydra =
  (typeof PeopleLocaleJsonldReadCollectionPropertyContextOneOfHydra)[keyof typeof PeopleLocaleJsonldReadCollectionPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleLocaleJsonldReadCollectionPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleLocaleJsonldReadCollectionPropertyContextOneOf = {
  "@vocab": string
  hydra: PeopleLocaleJsonldReadCollectionPropertyContextOneOfHydra
  [key: string]: any
}

export type PeopleLocaleJsonldReadCollectionPropertyContext =
  | string
  | PeopleLocaleJsonldReadCollectionPropertyContextOneOf

export interface PeopleLocaleJsonldReadCollectionProperty {
  readonly "@context"?: PeopleLocaleJsonldReadCollectionPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type PeopleLocaleJsonldReadCollectionProjectExcelExportRefLabel =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PeopleLocaleJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof PeopleLocaleJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof PeopleLocaleJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleLocaleJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleLocaleJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: PeopleLocaleJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type PeopleLocaleJsonldReadCollectionProjectExcelExportContext =
  | string
  | PeopleLocaleJsonldReadCollectionProjectExcelExportContextOneOf

export interface PeopleLocaleJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: PeopleLocaleJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  isDefault?: boolean | null
  refLabel?: PeopleLocaleJsonldReadCollectionProjectExcelExportRefLabel
  readonly id: string
}

export type PeopleLocaleJsonldReadCollectionProjectRefLabel =
  RefLabelJsonldReadCollectionProject | null

export type PeopleLocaleJsonldReadCollectionProjectContextOneOfHydra =
  (typeof PeopleLocaleJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof PeopleLocaleJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleLocaleJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleLocaleJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: PeopleLocaleJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type PeopleLocaleJsonldReadCollectionProjectContext =
  | string
  | PeopleLocaleJsonldReadCollectionProjectContextOneOf

export interface PeopleLocaleJsonldReadCollectionProject {
  readonly "@context"?: PeopleLocaleJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  isDefault?: boolean | null
  refLabel?: PeopleLocaleJsonldReadCollectionProjectRefLabel
  readonly id: string
}

export type PeopleLocaleJsonldReadCollectionPeopleExcelExportRefLabel =
  RefLabelJsonldReadCollectionPeopleExcelExport | null

export interface PeopleLocaleJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: PeopleLocaleJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  isDefault?: boolean | null
  refLabel?: PeopleLocaleJsonldReadCollectionPeopleExcelExportRefLabel
  readonly id: string
}

export type PeopleLocaleJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof PeopleLocaleJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof PeopleLocaleJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleLocaleJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleLocaleJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: PeopleLocaleJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type PeopleLocaleJsonldReadCollectionPeopleExcelExportContext =
  | string
  | PeopleLocaleJsonldReadCollectionPeopleExcelExportContextOneOf

export interface PeopleLocaleJsonld {
  readonly "@context"?: PeopleLocaleJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  isDefault?: boolean | null
  people?: string
  refLabel?: string | null
  readonly id: string
}

export type PeopleLocaleJsonldContextOneOfHydra =
  (typeof PeopleLocaleJsonldContextOneOfHydra)[keyof typeof PeopleLocaleJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleLocaleJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleLocaleJsonldContextOneOf = {
  "@vocab": string
  hydra: PeopleLocaleJsonldContextOneOfHydra
  [key: string]: any
}

export type PeopleLocaleJsonldContext = string | PeopleLocaleJsonldContextOneOf

export type PeopleCountersJsonldReadItemPeopleContextOneOfHydra =
  (typeof PeopleCountersJsonldReadItemPeopleContextOneOfHydra)[keyof typeof PeopleCountersJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleCountersJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleCountersJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: PeopleCountersJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type PeopleCountersJsonldReadItemPeopleContext =
  | string
  | PeopleCountersJsonldReadItemPeopleContextOneOf

export interface PeopleCountersJsonldReadItemPeople {
  readonly "@context"?: PeopleCountersJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  project_offer?: number
  project_request?: number
}

export interface PeopleCountersJsonldReadCollectionPeople {
  readonly "@context"?: PeopleCountersJsonldReadCollectionPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  project_offer?: number
  project_request?: number
}

export type PeopleCountersJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof PeopleCountersJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof PeopleCountersJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleCountersJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleCountersJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: PeopleCountersJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type PeopleCountersJsonldReadCollectionPeopleContext =
  | string
  | PeopleCountersJsonldReadCollectionPeopleContextOneOf

export type PeopleContactDetailsJsonldReadItemPropertyContextOneOfHydra =
  (typeof PeopleContactDetailsJsonldReadItemPropertyContextOneOfHydra)[keyof typeof PeopleContactDetailsJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleContactDetailsJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleContactDetailsJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: PeopleContactDetailsJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type PeopleContactDetailsJsonldReadItemPropertyContext =
  | string
  | PeopleContactDetailsJsonldReadItemPropertyContextOneOf

export interface PeopleContactDetailsJsonldReadItemProperty {
  readonly "@context"?: PeopleContactDetailsJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface PeopleContactDetailsJsonldReadItemProject {
  readonly "@context"?: PeopleContactDetailsJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number
  isDefault?: boolean
  contactDetails?: ContactDetailsJsonldReadItemProject
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleContactDetailsJsonldReadItemProjectContextOneOfHydra =
  (typeof PeopleContactDetailsJsonldReadItemProjectContextOneOfHydra)[keyof typeof PeopleContactDetailsJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleContactDetailsJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleContactDetailsJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: PeopleContactDetailsJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type PeopleContactDetailsJsonldReadItemProjectContext =
  | string
  | PeopleContactDetailsJsonldReadItemProjectContextOneOf

export interface PeopleContactDetailsJsonldReadItemPeople {
  readonly "@context"?: PeopleContactDetailsJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number
  isDefault?: boolean
  contactDetails?: ContactDetailsJsonldReadItemPeople
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleContactDetailsJsonldReadItemPeopleContextOneOfHydra =
  (typeof PeopleContactDetailsJsonldReadItemPeopleContextOneOfHydra)[keyof typeof PeopleContactDetailsJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleContactDetailsJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleContactDetailsJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: PeopleContactDetailsJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type PeopleContactDetailsJsonldReadItemPeopleContext =
  | string
  | PeopleContactDetailsJsonldReadItemPeopleContextOneOf

export interface PeopleContactDetailsJsonldReadCollectionSharedDataReadItemSharedData {
  readonly "@context"?: PeopleContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldReadCollectionSharedDataReadItemSharedData
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra =
  (typeof PeopleContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra)[keyof typeof PeopleContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra =
  {
    httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
  } as const

export type PeopleContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf = {
  "@vocab": string
  hydra: PeopleContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra
  [key: string]: any
}

export type PeopleContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContext =
  | string
  | PeopleContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf

export type PeopleContactDetailsJsonldReadCollectionSharedDataContextOneOfHydra =
  (typeof PeopleContactDetailsJsonldReadCollectionSharedDataContextOneOfHydra)[keyof typeof PeopleContactDetailsJsonldReadCollectionSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleContactDetailsJsonldReadCollectionSharedDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleContactDetailsJsonldReadCollectionSharedDataContextOneOf = {
  "@vocab": string
  hydra: PeopleContactDetailsJsonldReadCollectionSharedDataContextOneOfHydra
  [key: string]: any
}

export type PeopleContactDetailsJsonldReadCollectionSharedDataContext =
  | string
  | PeopleContactDetailsJsonldReadCollectionSharedDataContextOneOf

export interface PeopleContactDetailsJsonldReadCollectionSharedData {
  readonly "@context"?: PeopleContactDetailsJsonldReadCollectionSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldReadCollectionSharedData
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOfHydra =
  (typeof PeopleContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOfHydra)[keyof typeof PeopleContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: PeopleContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type PeopleContactDetailsJsonldReadCollectionPropertyExcelExportContext =
  | string
  | PeopleContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOf

export interface PeopleContactDetailsJsonldReadCollectionPropertyExcelExport {
  readonly "@context"?: PeopleContactDetailsJsonldReadCollectionPropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof PeopleContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof PeopleContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleContactDetailsJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: PeopleContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type PeopleContactDetailsJsonldReadCollectionProjectExcelExportContext =
  | string
  | PeopleContactDetailsJsonldReadCollectionProjectExcelExportContextOneOf

export interface PeopleContactDetailsJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: PeopleContactDetailsJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number
  isDefault?: boolean
  contactDetails?: ContactDetailsJsonldReadCollectionProjectExcelExport
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleContactDetailsJsonldReadCollectionProjectContextOneOfHydra =
  (typeof PeopleContactDetailsJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof PeopleContactDetailsJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleContactDetailsJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleContactDetailsJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: PeopleContactDetailsJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type PeopleContactDetailsJsonldReadCollectionProjectContext =
  | string
  | PeopleContactDetailsJsonldReadCollectionProjectContextOneOf

export interface PeopleContactDetailsJsonldReadCollectionProject {
  readonly "@context"?: PeopleContactDetailsJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number
  isDefault?: boolean
  contactDetails?: ContactDetailsJsonldReadCollectionProject
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface PeopleContactDetailsJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: PeopleContactDetailsJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number
  isDefault?: boolean
  contactDetails?: ContactDetailsJsonldReadCollectionPeopleExcelExport
  readonly id: string
  readonly updatedAt?: string
}

export type PeopleContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof PeopleContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof PeopleContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: PeopleContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type PeopleContactDetailsJsonldReadCollectionPeopleExcelExportContext =
  | string
  | PeopleContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOf

export interface PeopleContactDetailsJsonldReadCollectionPeople {
  readonly "@context"?: PeopleContactDetailsJsonldReadCollectionPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  contactDetails?: ContactDetailsJsonldReadCollectionPeople
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleContactDetailsJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof PeopleContactDetailsJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof PeopleContactDetailsJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleContactDetailsJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleContactDetailsJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: PeopleContactDetailsJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type PeopleContactDetailsJsonldReadCollectionPeopleContext =
  | string
  | PeopleContactDetailsJsonldReadCollectionPeopleContextOneOf

export type PeopleContactDetailsJsonldContextOneOfHydra =
  (typeof PeopleContactDetailsJsonldContextOneOfHydra)[keyof typeof PeopleContactDetailsJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleContactDetailsJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleContactDetailsJsonldContextOneOf = {
  "@vocab": string
  hydra: PeopleContactDetailsJsonldContextOneOfHydra
  [key: string]: any
}

export type PeopleContactDetailsJsonldContext = string | PeopleContactDetailsJsonldContextOneOf

export interface PeopleContactDetailsJsonld {
  readonly "@context"?: PeopleContactDetailsJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  order?: number
  isDefault?: boolean
  people?: string
  contactDetails?: ContactDetailsJsonld
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleClusterDTOJsonldContextOneOfHydra =
  (typeof PeopleClusterDTOJsonldContextOneOfHydra)[keyof typeof PeopleClusterDTOJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleClusterDTOJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleClusterDTOJsonldContextOneOf = {
  "@vocab": string
  hydra: PeopleClusterDTOJsonldContextOneOfHydra
  [key: string]: any
}

export type PeopleClusterDTOJsonldContext = string | PeopleClusterDTOJsonldContextOneOf

export interface PeopleClusterDTOJsonld {
  readonly "@context"?: PeopleClusterDTOJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  id?: string
  cluster?: string
  people?: string
}

export interface PeopleClusterDTO {
  id?: string
  cluster?: string
  people?: string
}

export type PeopleJsonldReadItemPropertyTitle = RefLabelJsonldReadItemProperty | null

export type PeopleJsonldReadItemPropertyMaritalStatus = RefLabelJsonldReadItemProperty | null

export type PeopleJsonldReadItemPropertyMaritalContractStatus =
  RefLabelJsonldReadItemProperty | null

export type PeopleJsonldReadItemPropertyCustomerType = RefLabelJsonldReadItemProperty | null

export type PeopleJsonldReadItemPropertyCurrency = RefLabelJsonldReadItemProperty | null

export type PeopleJsonldReadItemPropertyActivity = RefLabelJsonldReadItemProperty | null

export type PeopleJsonldReadItemPropertyContextOneOfHydra =
  (typeof PeopleJsonldReadItemPropertyContextOneOfHydra)[keyof typeof PeopleJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: PeopleJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type PeopleJsonldReadItemPropertyContext = string | PeopleJsonldReadItemPropertyContextOneOf

export interface PeopleJsonldReadItemProperty {
  readonly "@context"?: PeopleJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  activity?: PeopleJsonldReadItemPropertyActivity
  birthdate?: string | null
  birthplace?: string | null
  childCount?: number | null
  comment?: string | null
  company?: string | null
  companyVatNumber?: string | null
  currency?: PeopleJsonldReadItemPropertyCurrency
  customerType?: PeopleJsonldReadItemPropertyCustomerType
  firstname?: string | null
  hobbies?: RefLabelJsonldReadItemProperty[]
  jobTitle?: string | null
  keywords?: string | null
  lastname?: string | null
  maritalContractStatus?: PeopleJsonldReadItemPropertyMaritalContractStatus
  maritalStatus?: PeopleJsonldReadItemPropertyMaritalStatus
  ranking?: number | null
  taxcode?: string | null
  title?: PeopleJsonldReadItemPropertyTitle
  contactDetails?: PeopleContactDetailsJsonldReadItemProperty[]
  contactMethod?: RefLabelJsonldReadItemProperty[]
  locale?: PeopleLocaleJsonldReadItemProperty[]
  media?: PeopleMediaJsonldReadItemProperty[]
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleJsonldReadItemProjectTitle = RefLabelJsonldReadItemProject | null

export type PeopleJsonldReadItemProjectMaritalStatus = RefLabelJsonldReadItemProject | null

export type PeopleJsonldReadItemProjectMaritalContractStatus = RefLabelJsonldReadItemProject | null

export type PeopleJsonldReadItemProjectCustomerType = RefLabelJsonldReadItemProject | null

export type PeopleJsonldReadItemProjectCurrency = RefLabelJsonldReadItemProject | null

export type PeopleJsonldReadItemProjectActivity = RefLabelJsonldReadItemProject | null

export interface PeopleJsonldReadItemProject {
  readonly "@context"?: PeopleJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  activity?: PeopleJsonldReadItemProjectActivity
  birthdate?: string | null
  birthplace?: string | null
  childCount?: number | null
  comment?: string | null
  company?: string | null
  companyVatNumber?: string | null
  currency?: PeopleJsonldReadItemProjectCurrency
  customerType?: PeopleJsonldReadItemProjectCustomerType
  firstname?: string | null
  hobbies?: RefLabelJsonldReadItemProject[]
  jobTitle?: string | null
  keywords?: string | null
  lastname?: string | null
  maritalContractStatus?: PeopleJsonldReadItemProjectMaritalContractStatus
  maritalStatus?: PeopleJsonldReadItemProjectMaritalStatus
  ranking?: number | null
  taxcode?: string | null
  title?: PeopleJsonldReadItemProjectTitle
  contactDetails?: PeopleContactDetailsJsonldReadItemProject[]
  contactMethod?: RefLabelJsonldReadItemProject[]
  locale?: PeopleLocaleJsonldReadItemProject[]
  propertyOwners?: PropertyOwnersJsonldReadItemProject[]
  collaborator?: RelPeopleCollaboratorJsonldReadItemProject[]
  spouse?: PeopleSpouseJsonldReadItemProject[]
  spouseDst?: PeopleSpouseJsonldReadItemProject[]
  subscription?: SubscriptionJsonldReadItemProject[]
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleJsonldReadItemProjectContextOneOfHydra =
  (typeof PeopleJsonldReadItemProjectContextOneOfHydra)[keyof typeof PeopleJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: PeopleJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type PeopleJsonldReadItemProjectContext = string | PeopleJsonldReadItemProjectContextOneOf

export type PeopleJsonldReadItemPeopleCounters = PeopleCountersJsonldReadItemPeople | null

export type PeopleJsonldReadItemPeopleTitle = RefLabelJsonldReadItemPeople | null

export type PeopleJsonldReadItemPeopleMaritalStatus = RefLabelJsonldReadItemPeople | null

export type PeopleJsonldReadItemPeopleMaritalContractStatus = RefLabelJsonldReadItemPeople | null

export type PeopleJsonldReadItemPeopleCustomerType = RefLabelJsonldReadItemPeople | null

export type PeopleJsonldReadItemPeopleCurrency = RefLabelJsonldReadItemPeople | null

export type PeopleJsonldReadItemPeopleActivity = RefLabelJsonldReadItemPeople | null

export type PeopleJsonldReadItemPeopleContextOneOfHydra =
  (typeof PeopleJsonldReadItemPeopleContextOneOfHydra)[keyof typeof PeopleJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: PeopleJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type PeopleJsonldReadItemPeopleContext = string | PeopleJsonldReadItemPeopleContextOneOf

export interface PeopleJsonldReadItemPeople {
  readonly "@context"?: PeopleJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  activity?: PeopleJsonldReadItemPeopleActivity
  birthdate?: string | null
  birthplace?: string | null
  childCount?: number | null
  comment?: string | null
  company?: string | null
  companyVatNumber?: string | null
  currency?: PeopleJsonldReadItemPeopleCurrency
  customerType?: PeopleJsonldReadItemPeopleCustomerType
  firstname?: string | null
  hobbies?: RefLabelJsonldReadItemPeople[]
  jobTitle?: string | null
  keywords?: string | null
  lastname?: string | null
  maritalContractStatus?: PeopleJsonldReadItemPeopleMaritalContractStatus
  maritalStatus?: PeopleJsonldReadItemPeopleMaritalStatus
  ranking?: number | null
  taxcode?: string | null
  title?: PeopleJsonldReadItemPeopleTitle
  contactDetails?: PeopleContactDetailsJsonldReadItemPeople[]
  contactMethod?: RefLabelJsonldReadItemPeople[]
  locale?: PeopleLocaleJsonldReadItemPeople[]
  media?: PeopleMediaJsonldReadItemPeople[]
  propertyOwners?: PropertyOwnersJsonldReadItemPeople[]
  project?: ProjectPeopleJsonldReadItemPeople[]
  collaborator?: RelPeopleCollaboratorJsonldReadItemPeople[]
  spouse?: PeopleSpouseJsonldReadItemPeople[]
  spouseDst?: PeopleSpouseJsonldReadItemPeople[]
  subscription?: SubscriptionJsonldReadItemPeople[]
  cluster?: RelClusterPeopleJsonldReadItemPeople[]
  internalSource?: string | null
  readonly counters?: PeopleJsonldReadItemPeopleCounters
  isShared?: boolean
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleJsonldReadItemCollaboratorItemTitle =
  RefLabelJsonldReadItemCollaboratorItem | null

export type PeopleJsonldReadItemCollaboratorItemCustomerType =
  RefLabelJsonldReadItemCollaboratorItem | null

export type PeopleJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof PeopleJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof PeopleJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: PeopleJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type PeopleJsonldReadItemCollaboratorItemContext =
  | string
  | PeopleJsonldReadItemCollaboratorItemContextOneOf

export interface PeopleJsonldReadItemCollaboratorItem {
  readonly "@context"?: PeopleJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  customerType?: PeopleJsonldReadItemCollaboratorItemCustomerType
  firstname?: string | null
  lastname?: string | null
  title?: PeopleJsonldReadItemCollaboratorItemTitle
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleJsonldReadItemClusterItemTitle = RefLabelJsonldReadItemClusterItem | null

export type PeopleJsonldReadItemClusterItemCustomerType = RefLabelJsonldReadItemClusterItem | null

export interface PeopleJsonldReadItemClusterItem {
  readonly "@context"?: PeopleJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  customerType?: PeopleJsonldReadItemClusterItemCustomerType
  firstname?: string | null
  lastname?: string | null
  title?: PeopleJsonldReadItemClusterItemTitle
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleJsonldReadItemClusterItemContextOneOfHydra =
  (typeof PeopleJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof PeopleJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: PeopleJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type PeopleJsonldReadItemClusterItemContext =
  | string
  | PeopleJsonldReadItemClusterItemContextOneOf

export interface PeopleJsonldReadCollectionSharedDataReadItemSharedData {
  readonly "@context"?: PeopleJsonldReadCollectionSharedDataReadItemSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  firstname?: string | null
  lastname?: string | null
  contactDetails?: PeopleContactDetailsJsonldReadCollectionSharedDataReadItemSharedData[]
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra =
  (typeof PeopleJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra)[keyof typeof PeopleJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf = {
  "@vocab": string
  hydra: PeopleJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra
  [key: string]: any
}

export type PeopleJsonldReadCollectionSharedDataReadItemSharedDataContext =
  | string
  | PeopleJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf

export type PeopleJsonldReadCollectionSharedDataContextOneOfHydra =
  (typeof PeopleJsonldReadCollectionSharedDataContextOneOfHydra)[keyof typeof PeopleJsonldReadCollectionSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleJsonldReadCollectionSharedDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleJsonldReadCollectionSharedDataContextOneOf = {
  "@vocab": string
  hydra: PeopleJsonldReadCollectionSharedDataContextOneOfHydra
  [key: string]: any
}

export type PeopleJsonldReadCollectionSharedDataContext =
  | string
  | PeopleJsonldReadCollectionSharedDataContextOneOf

export interface PeopleJsonldReadCollectionSharedData {
  readonly "@context"?: PeopleJsonldReadCollectionSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  firstname?: string | null
  lastname?: string | null
  contactDetails?: PeopleContactDetailsJsonldReadCollectionSharedData[]
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleJsonldReadCollectionPropertyExcelExportTitle =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PeopleJsonldReadCollectionPropertyExcelExportMaritalStatus =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PeopleJsonldReadCollectionPropertyExcelExportMaritalContractStatus =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PeopleJsonldReadCollectionPropertyExcelExportCustomerType =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PeopleJsonldReadCollectionPropertyExcelExportCurrency =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PeopleJsonldReadCollectionPropertyExcelExportActivity =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export type PeopleJsonldReadCollectionPropertyExcelExportContextOneOfHydra =
  (typeof PeopleJsonldReadCollectionPropertyExcelExportContextOneOfHydra)[keyof typeof PeopleJsonldReadCollectionPropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleJsonldReadCollectionPropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleJsonldReadCollectionPropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: PeopleJsonldReadCollectionPropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type PeopleJsonldReadCollectionPropertyExcelExportContext =
  | string
  | PeopleJsonldReadCollectionPropertyExcelExportContextOneOf

export interface PeopleJsonldReadCollectionPropertyExcelExport {
  readonly "@context"?: PeopleJsonldReadCollectionPropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  activity?: PeopleJsonldReadCollectionPropertyExcelExportActivity
  birthdate?: string | null
  birthplace?: string | null
  childCount?: number | null
  comment?: string | null
  company?: string | null
  companyVatNumber?: string | null
  currency?: PeopleJsonldReadCollectionPropertyExcelExportCurrency
  customerType?: PeopleJsonldReadCollectionPropertyExcelExportCustomerType
  firstname?: string | null
  hobbies?: RefLabelJsonldReadCollectionPropertyExcelExport[]
  jobTitle?: string | null
  keywords?: string | null
  lastname?: string | null
  maritalContractStatus?: PeopleJsonldReadCollectionPropertyExcelExportMaritalContractStatus
  maritalStatus?: PeopleJsonldReadCollectionPropertyExcelExportMaritalStatus
  ranking?: number | null
  taxcode?: string | null
  title?: PeopleJsonldReadCollectionPropertyExcelExportTitle
  contactDetails?: PeopleContactDetailsJsonldReadCollectionPropertyExcelExport[]
  contactMethod?: RefLabelJsonldReadCollectionPropertyExcelExport[]
  locale?: PeopleLocaleJsonldReadCollectionPropertyExcelExport[]
  media?: PeopleMediaJsonldReadCollectionPropertyExcelExport[]
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleJsonldReadCollectionPropertyTitle = RefLabelJsonldReadCollectionProperty | null

export type PeopleJsonldReadCollectionPropertyCustomerType =
  RefLabelJsonldReadCollectionProperty | null

export interface PeopleJsonldReadCollectionProperty {
  readonly "@context"?: PeopleJsonldReadCollectionPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  customerType?: PeopleJsonldReadCollectionPropertyCustomerType
  firstname?: string | null
  lastname?: string | null
  title?: PeopleJsonldReadCollectionPropertyTitle
  locale?: PeopleLocaleJsonldReadCollectionProperty[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleJsonldReadCollectionPropertyContextOneOfHydra =
  (typeof PeopleJsonldReadCollectionPropertyContextOneOfHydra)[keyof typeof PeopleJsonldReadCollectionPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleJsonldReadCollectionPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleJsonldReadCollectionPropertyContextOneOf = {
  "@vocab": string
  hydra: PeopleJsonldReadCollectionPropertyContextOneOfHydra
  [key: string]: any
}

export type PeopleJsonldReadCollectionPropertyContext =
  | string
  | PeopleJsonldReadCollectionPropertyContextOneOf

export type PeopleJsonldReadCollectionProjectExcelExportTitle =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PeopleJsonldReadCollectionProjectExcelExportMaritalStatus =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PeopleJsonldReadCollectionProjectExcelExportMaritalContractStatus =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PeopleJsonldReadCollectionProjectExcelExportCustomerType =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PeopleJsonldReadCollectionProjectExcelExportCurrency =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type PeopleJsonldReadCollectionProjectExcelExportActivity =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export interface PeopleJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: PeopleJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  activity?: PeopleJsonldReadCollectionProjectExcelExportActivity
  birthdate?: string | null
  birthplace?: string | null
  childCount?: number | null
  comment?: string | null
  company?: string | null
  companyVatNumber?: string | null
  currency?: PeopleJsonldReadCollectionProjectExcelExportCurrency
  customerType?: PeopleJsonldReadCollectionProjectExcelExportCustomerType
  firstname?: string | null
  hobbies?: RefLabelJsonldReadCollectionProjectExcelExport[]
  jobTitle?: string | null
  keywords?: string | null
  lastname?: string | null
  maritalContractStatus?: PeopleJsonldReadCollectionProjectExcelExportMaritalContractStatus
  maritalStatus?: PeopleJsonldReadCollectionProjectExcelExportMaritalStatus
  ranking?: number | null
  taxcode?: string | null
  title?: PeopleJsonldReadCollectionProjectExcelExportTitle
  contactDetails?: PeopleContactDetailsJsonldReadCollectionProjectExcelExport[]
  contactMethod?: RefLabelJsonldReadCollectionProjectExcelExport[]
  locale?: PeopleLocaleJsonldReadCollectionProjectExcelExport[]
  propertyOwners?: PropertyOwnersJsonldReadCollectionProjectExcelExport[]
  collaborator?: RelPeopleCollaboratorJsonldReadCollectionProjectExcelExport[]
  spouse?: PeopleSpouseJsonldReadCollectionProjectExcelExport[]
  spouseDst?: PeopleSpouseJsonldReadCollectionProjectExcelExport[]
  subscription?: SubscriptionJsonldReadCollectionProjectExcelExport[]
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof PeopleJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof PeopleJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: PeopleJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type PeopleJsonldReadCollectionProjectExcelExportContext =
  | string
  | PeopleJsonldReadCollectionProjectExcelExportContextOneOf

export type PeopleJsonldReadCollectionProjectTitle = RefLabelJsonldReadCollectionProject | null

export type PeopleJsonldReadCollectionProjectCustomerType =
  RefLabelJsonldReadCollectionProject | null

export type PeopleJsonldReadCollectionProjectCurrency = RefLabelJsonldReadCollectionProject | null

export interface PeopleJsonldReadCollectionProject {
  readonly "@context"?: PeopleJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  currency?: PeopleJsonldReadCollectionProjectCurrency
  customerType?: PeopleJsonldReadCollectionProjectCustomerType
  firstname?: string | null
  lastname?: string | null
  title?: PeopleJsonldReadCollectionProjectTitle
  contactDetails?: PeopleContactDetailsJsonldReadCollectionProject[]
  locale?: PeopleLocaleJsonldReadCollectionProject[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleJsonldReadCollectionProjectContextOneOfHydra =
  (typeof PeopleJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof PeopleJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: PeopleJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type PeopleJsonldReadCollectionProjectContext =
  | string
  | PeopleJsonldReadCollectionProjectContextOneOf

export type PeopleJsonldReadCollectionPeopleExcelExportTitle =
  RefLabelJsonldReadCollectionPeopleExcelExport | null

export type PeopleJsonldReadCollectionPeopleExcelExportMaritalStatus =
  RefLabelJsonldReadCollectionPeopleExcelExport | null

export type PeopleJsonldReadCollectionPeopleExcelExportMaritalContractStatus =
  RefLabelJsonldReadCollectionPeopleExcelExport | null

export type PeopleJsonldReadCollectionPeopleExcelExportCustomerType =
  RefLabelJsonldReadCollectionPeopleExcelExport | null

export type PeopleJsonldReadCollectionPeopleExcelExportCurrency =
  RefLabelJsonldReadCollectionPeopleExcelExport | null

export type PeopleJsonldReadCollectionPeopleExcelExportActivity =
  RefLabelJsonldReadCollectionPeopleExcelExport | null

export type PeopleJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof PeopleJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof PeopleJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: PeopleJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type PeopleJsonldReadCollectionPeopleExcelExportContext =
  | string
  | PeopleJsonldReadCollectionPeopleExcelExportContextOneOf

export interface PeopleJsonldReadCollectionPeopleExcelExport {
  readonly "@id"?: string
  readonly "@type"?: string
  readonly "@context"?: PeopleJsonldReadCollectionPeopleExcelExportContext
  activity?: PeopleJsonldReadCollectionPeopleExcelExportActivity
  birthdate?: string | null
  birthplace?: string | null
  childCount?: number | null
  comment?: string | null
  company?: string | null
  companyVatNumber?: string | null
  currency?: PeopleJsonldReadCollectionPeopleExcelExportCurrency
  customerType?: PeopleJsonldReadCollectionPeopleExcelExportCustomerType
  firstname?: string | null
  hobbies?: RefLabelJsonldReadCollectionPeopleExcelExport[]
  jobTitle?: string | null
  keywords?: string | null
  lastname?: string | null
  maritalContractStatus?: PeopleJsonldReadCollectionPeopleExcelExportMaritalContractStatus
  maritalStatus?: PeopleJsonldReadCollectionPeopleExcelExportMaritalStatus
  ranking?: number | null
  taxcode?: string | null
  title?: PeopleJsonldReadCollectionPeopleExcelExportTitle
  contactDetails?: PeopleContactDetailsJsonldReadCollectionPeopleExcelExport[]
  contactMethod?: RefLabelJsonldReadCollectionPeopleExcelExport[]
  locale?: PeopleLocaleJsonldReadCollectionPeopleExcelExport[]
  media?: PeopleMediaJsonldReadCollectionPeopleExcelExport[]
  propertyOwners?: PropertyOwnersJsonldReadCollectionPeopleExcelExport[]
  project?: ProjectPeopleJsonldReadCollectionPeopleExcelExport[]
  collaborator?: RelPeopleCollaboratorJsonldReadCollectionPeopleExcelExport[]
  spouse?: PeopleSpouseJsonldReadCollectionPeopleExcelExport[]
  spouseDst?: PeopleSpouseJsonldReadCollectionPeopleExcelExport[]
  subscription?: SubscriptionJsonldReadCollectionPeopleExcelExport[]
  cluster?: RelClusterPeopleJsonldReadCollectionPeopleExcelExport[]
  internalSource?: string | null
  readonly id: string
  readonly updatedAt?: string
}

export type PeopleJsonldReadCollectionPeopleCounters =
  PeopleCountersJsonldReadCollectionPeople | null

export type PeopleJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof PeopleJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof PeopleJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: PeopleJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type PeopleJsonldReadCollectionPeopleContext =
  | string
  | PeopleJsonldReadCollectionPeopleContextOneOf

export interface PeopleJsonldReadCollectionPeople {
  readonly "@id"?: string
  readonly "@type"?: string
  readonly "@context"?: PeopleJsonldReadCollectionPeopleContext
  firstname?: string | null
  lastname?: string | null
  contactDetails?: PeopleContactDetailsJsonldReadCollectionPeople[]
  media?: PeopleMediaJsonldReadCollectionPeople[]
  project?: ProjectPeopleJsonldReadCollectionPeople[]
  collaborator?: RelPeopleCollaboratorJsonldReadCollectionPeople[]
  cluster?: RelClusterPeopleJsonldReadCollectionPeople[]
  readonly counters?: PeopleJsonldReadCollectionPeopleCounters
  isShared?: boolean
  sharedData?: SharedDataJsonldReadCollectionPeople[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleJsonldReadCollectionCollaboratorTitle =
  RefLabelJsonldReadCollectionCollaborator | null

export type PeopleJsonldReadCollectionCollaboratorCustomerType =
  RefLabelJsonldReadCollectionCollaborator | null

export interface PeopleJsonldReadCollectionCollaborator {
  readonly "@context"?: PeopleJsonldReadCollectionCollaboratorContext
  readonly "@id"?: string
  readonly "@type"?: string
  customerType?: PeopleJsonldReadCollectionCollaboratorCustomerType
  firstname?: string | null
  lastname?: string | null
  title?: PeopleJsonldReadCollectionCollaboratorTitle
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type PeopleJsonldReadCollectionCollaboratorContextOneOfHydra =
  (typeof PeopleJsonldReadCollectionCollaboratorContextOneOfHydra)[keyof typeof PeopleJsonldReadCollectionCollaboratorContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeopleJsonldReadCollectionCollaboratorContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type PeopleJsonldReadCollectionCollaboratorContextOneOf = {
  "@vocab": string
  hydra: PeopleJsonldReadCollectionCollaboratorContextOneOfHydra
  [key: string]: any
}

export type PeopleJsonldReadCollectionCollaboratorContext =
  | string
  | PeopleJsonldReadCollectionCollaboratorContextOneOf

export interface OptionalTranslationsDTO {
  fr_FR?: string | null
  en_GB?: string | null
}

export type MediaJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof MediaJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof MediaJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadItemSocietyItemContext =
  | string
  | MediaJsonldReadItemSocietyItemContextOneOf

export interface MediaJsonldReadItemSocietyItem {
  readonly "@context"?: MediaJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  key?: string | null
  path?: string
  isWatermarked?: boolean | null
  isCdn?: boolean | null
  isPrivate?: boolean | null
  isPanorama?: boolean | null
  latitude?: number | null
  longitude?: number | null
  width?: number | null
  height?: number | null
  size?: string | null
  type?: string | null
  title?: string | null
  source?: string | null
  author?: string | null
  copyright?: string | null
  comment?: string | null
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface MediaJsonldReadItemProperty {
  readonly "@context"?: MediaJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  key?: string | null
  path?: string
  isWatermarked?: boolean | null
  isCdn?: boolean | null
  isPrivate?: boolean | null
  isPanorama?: boolean | null
  latitude?: number | null
  longitude?: number | null
  width?: number | null
  height?: number | null
  size?: string | null
  type?: string | null
  title?: string | null
  source?: string | null
  author?: string | null
  copyright?: string | null
  comment?: string | null
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type MediaJsonldReadItemPropertyContextOneOfHydra =
  (typeof MediaJsonldReadItemPropertyContextOneOfHydra)[keyof typeof MediaJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadItemPropertyContext = string | MediaJsonldReadItemPropertyContextOneOf

export type MediaJsonldReadItemProjectContextOneOfHydra =
  (typeof MediaJsonldReadItemProjectContextOneOfHydra)[keyof typeof MediaJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadItemProjectContext = string | MediaJsonldReadItemProjectContextOneOf

export interface MediaJsonldReadItemProject {
  readonly "@context"?: MediaJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  key?: string | null
  path?: string
  isWatermarked?: boolean | null
  isCdn?: boolean | null
  isPrivate?: boolean | null
  isPanorama?: boolean | null
  latitude?: number | null
  longitude?: number | null
  width?: number | null
  height?: number | null
  size?: string | null
  type?: string | null
  title?: string | null
  source?: string | null
  author?: string | null
  copyright?: string | null
  comment?: string | null
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface MediaJsonldReadItemPeople {
  readonly "@context"?: MediaJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  key?: string | null
  path?: string
  isWatermarked?: boolean | null
  isCdn?: boolean | null
  isPrivate?: boolean | null
  isPanorama?: boolean | null
  longitude?: number | null
  width?: number | null
  height?: number | null
  size?: string | null
  type?: string | null
  title?: string | null
  source?: string | null
  author?: string | null
  copyright?: string | null
  comment?: string | null
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type MediaJsonldReadItemPeopleContextOneOfHydra =
  (typeof MediaJsonldReadItemPeopleContextOneOfHydra)[keyof typeof MediaJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadItemPeopleContext = string | MediaJsonldReadItemPeopleContextOneOf

export type MediaJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof MediaJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof MediaJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadItemCollaboratorItemContext =
  | string
  | MediaJsonldReadItemCollaboratorItemContextOneOf

export interface MediaJsonldReadItemCollaboratorItem {
  readonly "@context"?: MediaJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  key?: string | null
  path?: string
  isWatermarked?: boolean | null
  isCdn?: boolean | null
  isPrivate?: boolean | null
  isPanorama?: boolean | null
  latitude?: number | null
  longitude?: number | null
  width?: number | null
  height?: number | null
  size?: string | null
  type?: string | null
  title?: string | null
  source?: string | null
  author?: string | null
  copyright?: string | null
  comment?: string | null
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface MediaJsonldReadItemClusterItem {
  readonly "@context"?: MediaJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  key?: string | null
  path?: string
  isWatermarked?: boolean | null
  isCdn?: boolean | null
  isPrivate?: boolean | null
  isPanorama?: boolean | null
  latitude?: number | null
  longitude?: number | null
  width?: number | null
  height?: number | null
  size?: string | null
  type?: string | null
  title?: string | null
  source?: string | null
  author?: string | null
  copyright?: string | null
  comment?: string | null
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type MediaJsonldReadItemClusterItemContextOneOfHydra =
  (typeof MediaJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof MediaJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadItemClusterItemContext =
  | string
  | MediaJsonldReadItemClusterItemContextOneOf

export interface MediaJsonldReadItemAgencyItem {
  readonly "@context"?: MediaJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  key?: string | null
  path?: string
  isWatermarked?: boolean | null
  isCdn?: boolean | null
  isPrivate?: boolean | null
  isPanorama?: boolean | null
  latitude?: number | null
  longitude?: number | null
  width?: number | null
  height?: number | null
  size?: string | null
  type?: string | null
  title?: string | null
  source?: string | null
  author?: string | null
  copyright?: string | null
  comment?: string | null
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type MediaJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof MediaJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof MediaJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadItemAgencyItemContext =
  | string
  | MediaJsonldReadItemAgencyItemContextOneOf

export interface MediaJsonldReadCollectionSocietyExcelExport {
  readonly "@context"?: MediaJsonldReadCollectionSocietyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type MediaJsonldReadCollectionSocietyExcelExportContextOneOfHydra =
  (typeof MediaJsonldReadCollectionSocietyExcelExportContextOneOfHydra)[keyof typeof MediaJsonldReadCollectionSocietyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadCollectionSocietyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadCollectionSocietyExcelExportContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadCollectionSocietyExcelExportContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadCollectionSocietyExcelExportContext =
  | string
  | MediaJsonldReadCollectionSocietyExcelExportContextOneOf

export type MediaJsonldReadCollectionSocietyContextOneOfHydra =
  (typeof MediaJsonldReadCollectionSocietyContextOneOfHydra)[keyof typeof MediaJsonldReadCollectionSocietyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadCollectionSocietyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadCollectionSocietyContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadCollectionSocietyContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadCollectionSocietyContext =
  | string
  | MediaJsonldReadCollectionSocietyContextOneOf

export interface MediaJsonldReadCollectionSociety {
  readonly "@context"?: MediaJsonldReadCollectionSocietyContext
  readonly "@id"?: string
  readonly "@type"?: string
  key?: string | null
  path?: string
  isWatermarked?: boolean | null
  isCdn?: boolean | null
  isPrivate?: boolean | null
  isPanorama?: boolean | null
  latitude?: number | null
  longitude?: number | null
  width?: number | null
  height?: number | null
  size?: string | null
  type?: string | null
  title?: string | null
  source?: string | null
  author?: string | null
  copyright?: string | null
  comment?: string | null
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type MediaJsonldReadCollectionPropertyExcelExportContextOneOfHydra =
  (typeof MediaJsonldReadCollectionPropertyExcelExportContextOneOfHydra)[keyof typeof MediaJsonldReadCollectionPropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadCollectionPropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadCollectionPropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadCollectionPropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadCollectionPropertyExcelExportContext =
  | string
  | MediaJsonldReadCollectionPropertyExcelExportContextOneOf

export interface MediaJsonldReadCollectionPropertyExcelExport {
  readonly "@context"?: MediaJsonldReadCollectionPropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  key?: string | null
  path?: string
  isWatermarked?: boolean | null
  isCdn?: boolean | null
  isPrivate?: boolean | null
  isPanorama?: boolean | null
  latitude?: number | null
  longitude?: number | null
  width?: number | null
  height?: number | null
  size?: string | null
  type?: string | null
  title?: string | null
  source?: string | null
  author?: string | null
  copyright?: string | null
  comment?: string | null
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type MediaJsonldReadCollectionPropertyContextOneOfHydra =
  (typeof MediaJsonldReadCollectionPropertyContextOneOfHydra)[keyof typeof MediaJsonldReadCollectionPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadCollectionPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadCollectionPropertyContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadCollectionPropertyContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadCollectionPropertyContext =
  | string
  | MediaJsonldReadCollectionPropertyContextOneOf

export interface MediaJsonldReadCollectionProperty {
  readonly "@context"?: MediaJsonldReadCollectionPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  key?: string | null
  path?: string
  isWatermarked?: boolean | null
  isCdn?: boolean | null
  isPrivate?: boolean | null
  isPanorama?: boolean | null
  latitude?: number | null
  longitude?: number | null
  width?: number | null
  height?: number | null
  size?: string | null
  type?: string | null
  title?: string | null
  source?: string | null
  author?: string | null
  copyright?: string | null
  comment?: string | null
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type MediaJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof MediaJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof MediaJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadCollectionProjectExcelExportContext =
  | string
  | MediaJsonldReadCollectionProjectExcelExportContextOneOf

export interface MediaJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: MediaJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  key?: string | null
  path?: string
  isWatermarked?: boolean | null
  isCdn?: boolean | null
  isPrivate?: boolean | null
  isPanorama?: boolean | null
  latitude?: number | null
  longitude?: number | null
  width?: number | null
  height?: number | null
  size?: string | null
  type?: string | null
  title?: string | null
  source?: string | null
  author?: string | null
  copyright?: string | null
  comment?: string | null
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface MediaJsonldReadCollectionProject {
  readonly "@context"?: MediaJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  key?: string | null
  path?: string
  isWatermarked?: boolean | null
  isCdn?: boolean | null
  isPrivate?: boolean | null
  isPanorama?: boolean | null
  latitude?: number | null
  longitude?: number | null
  width?: number | null
  height?: number | null
  size?: string | null
  type?: string | null
  title?: string | null
  source?: string | null
  author?: string | null
  copyright?: string | null
  comment?: string | null
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type MediaJsonldReadCollectionProjectContextOneOfHydra =
  (typeof MediaJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof MediaJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadCollectionProjectContext =
  | string
  | MediaJsonldReadCollectionProjectContextOneOf

export interface MediaJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: MediaJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  key?: string | null
  path?: string
  isWatermarked?: boolean | null
  isCdn?: boolean | null
  isPrivate?: boolean | null
  isPanorama?: boolean | null
  longitude?: number | null
  width?: number | null
  height?: number | null
  size?: string | null
  type?: string | null
  title?: string | null
  source?: string | null
  author?: string | null
  copyright?: string | null
  comment?: string | null
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
  readonly updatedAt?: string
}

export type MediaJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof MediaJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof MediaJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadCollectionPeopleExcelExportContext =
  | string
  | MediaJsonldReadCollectionPeopleExcelExportContextOneOf

export type MediaJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof MediaJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof MediaJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadCollectionPeopleContext =
  | string
  | MediaJsonldReadCollectionPeopleContextOneOf

export interface MediaJsonldReadCollectionPeople {
  readonly "@context"?: MediaJsonldReadCollectionPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  key?: string | null
  path?: string
  isWatermarked?: boolean | null
  isCdn?: boolean | null
  isPrivate?: boolean | null
  isPanorama?: boolean | null
  longitude?: number | null
  width?: number | null
  height?: number | null
  size?: string | null
  type?: string | null
  title?: string | null
  source?: string | null
  author?: string | null
  copyright?: string | null
  comment?: string | null
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type MediaJsonldReadCollectionCollaboratorContextOneOfHydra =
  (typeof MediaJsonldReadCollectionCollaboratorContextOneOfHydra)[keyof typeof MediaJsonldReadCollectionCollaboratorContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadCollectionCollaboratorContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadCollectionCollaboratorContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadCollectionCollaboratorContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadCollectionCollaboratorContext =
  | string
  | MediaJsonldReadCollectionCollaboratorContextOneOf

export interface MediaJsonldReadCollectionCollaborator {
  readonly "@context"?: MediaJsonldReadCollectionCollaboratorContext
  readonly "@id"?: string
  readonly "@type"?: string
  key?: string | null
  path?: string
  isWatermarked?: boolean | null
  isCdn?: boolean | null
  isPrivate?: boolean | null
  isPanorama?: boolean | null
  latitude?: number | null
  longitude?: number | null
  width?: number | null
  height?: number | null
  size?: string | null
  type?: string | null
  title?: string | null
  source?: string | null
  author?: string | null
  copyright?: string | null
  comment?: string | null
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface MediaJsonldReadCollectionClusterExcelExport {
  readonly "@context"?: MediaJsonldReadCollectionClusterExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type MediaJsonldReadCollectionClusterExcelExportContextOneOfHydra =
  (typeof MediaJsonldReadCollectionClusterExcelExportContextOneOfHydra)[keyof typeof MediaJsonldReadCollectionClusterExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadCollectionClusterExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadCollectionClusterExcelExportContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadCollectionClusterExcelExportContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadCollectionClusterExcelExportContext =
  | string
  | MediaJsonldReadCollectionClusterExcelExportContextOneOf

export type MediaJsonldReadCollectionClusterContextOneOfHydra =
  (typeof MediaJsonldReadCollectionClusterContextOneOfHydra)[keyof typeof MediaJsonldReadCollectionClusterContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadCollectionClusterContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadCollectionClusterContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadCollectionClusterContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadCollectionClusterContext =
  | string
  | MediaJsonldReadCollectionClusterContextOneOf

export interface MediaJsonldReadCollectionCluster {
  readonly "@context"?: MediaJsonldReadCollectionClusterContext
  readonly "@id"?: string
  readonly "@type"?: string
  key?: string | null
  path?: string
  isWatermarked?: boolean | null
  isCdn?: boolean | null
  isPrivate?: boolean | null
  isPanorama?: boolean | null
  latitude?: number | null
  longitude?: number | null
  width?: number | null
  height?: number | null
  size?: string | null
  type?: string | null
  title?: string | null
  source?: string | null
  author?: string | null
  copyright?: string | null
  comment?: string | null
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type MediaJsonldReadCollectionAgencyContextOneOfHydra =
  (typeof MediaJsonldReadCollectionAgencyContextOneOfHydra)[keyof typeof MediaJsonldReadCollectionAgencyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldReadCollectionAgencyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldReadCollectionAgencyContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldReadCollectionAgencyContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldReadCollectionAgencyContext =
  | string
  | MediaJsonldReadCollectionAgencyContextOneOf

export interface MediaJsonldReadCollectionAgency {
  readonly "@context"?: MediaJsonldReadCollectionAgencyContext
  readonly "@id"?: string
  readonly "@type"?: string
  key?: string | null
  path?: string
  isWatermarked?: boolean | null
  isCdn?: boolean | null
  isPrivate?: boolean | null
  isPanorama?: boolean | null
  latitude?: number | null
  longitude?: number | null
  width?: number | null
  height?: number | null
  size?: string | null
  type?: string | null
  title?: string | null
  source?: string | null
  author?: string | null
  copyright?: string | null
  comment?: string | null
  order?: number | null
  isDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface MediaJsonld {
  readonly "@context"?: MediaJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  key?: string | null
  path?: string
  isWatermarked?: boolean | null
  isCdn?: boolean | null
  isPrivate?: boolean | null
  isPanorama?: boolean | null
  latitude?: number | null
  longitude?: number | null
  width?: number | null
  height?: number | null
  size?: string | null
  type?: string | null
  title?: string | null
  source?: string | null
  author?: string | null
  copyright?: string | null
  comment?: string | null
  order?: number | null
  isDefault?: boolean | null
  readonly property?: PropertyMediaJsonld[]
  people?: PeopleMediaJsonld[]
  collaboratorMedias?: CollaboratorMediaJsonld[]
  clusterMedias?: ClusterMediaJsonld[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
  readonly collaborators?: CollaboratorMediaJsonld[]
  readonly clusters?: ClusterMediaJsonld[]
}

export type MediaJsonldContextOneOfHydra =
  (typeof MediaJsonldContextOneOfHydra)[keyof typeof MediaJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MediaJsonldContextOneOf = {
  "@vocab": string
  hydra: MediaJsonldContextOneOfHydra
  [key: string]: any
}

export type MediaJsonldContext = string | MediaJsonldContextOneOf

export interface MandatoryTranslationsDTOJsonld {
  readonly "@context"?: MandatoryTranslationsDTOJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  fr_FR: string
  en_GB: string
}

export type MandatoryTranslationsDTOJsonldContextOneOfHydra =
  (typeof MandatoryTranslationsDTOJsonldContextOneOfHydra)[keyof typeof MandatoryTranslationsDTOJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MandatoryTranslationsDTOJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MandatoryTranslationsDTOJsonldContextOneOf = {
  "@vocab": string
  hydra: MandatoryTranslationsDTOJsonldContextOneOfHydra
  [key: string]: any
}

export type MandatoryTranslationsDTOJsonldContext =
  | string
  | MandatoryTranslationsDTOJsonldContextOneOf

export interface MBV4SynchroJsonldReadCollectionSynchroStatus {
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id?: number
  startAt?: string
  endAt?: string | null
  status?: string | null
  readonly last_log_message?: string | null
}

export interface MBV4SynchroJsonldReadCollectionBarnesSttausDeleted {
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id?: number
  datasUpdatedAt?: string
  entity?: string
  entityId?: string
  status?: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface MBV4SynchroJsonldReadCollectionBarnesStatusUpdated {
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id?: number
  barnesId?: string | null
  entity?: string | null
  entityCreatedAt?: string
  entityUpdatedAt?: string
  datasUpdatedAt?: string
  status?: string
  createdAt?: string
  readonly updatedAt?: string
}

export type MBV4SynchroJsonldContextOneOfHydra =
  (typeof MBV4SynchroJsonldContextOneOfHydra)[keyof typeof MBV4SynchroJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MBV4SynchroJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type MBV4SynchroJsonldContextOneOf = {
  "@vocab": string
  hydra: MBV4SynchroJsonldContextOneOfHydra
  [key: string]: any
}

export type MBV4SynchroJsonldContext = string | MBV4SynchroJsonldContextOneOf

export interface MBV4SynchroJsonld {
  readonly "@context"?: MBV4SynchroJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
}

export interface MBV4SynchroSynchroLogDTOJsonld {
  log_id?: number
}

export interface MBV4SynchroPostMbv4SynchroInputJsonld {
  [key: string]: any
}

export interface MBV4SynchroPostMbv4ImportInputJsonld {
  withReset?: boolean | null
  force?: boolean | null
}

export type LogJsonldContextOneOfHydra =
  (typeof LogJsonldContextOneOfHydra)[keyof typeof LogJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LogJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type LogJsonldContextOneOf = {
  "@vocab": string
  hydra: LogJsonldContextOneOfHydra
  [key: string]: any
}

export type LogJsonldContext = string | LogJsonldContextOneOf

export interface LogJsonld {
  readonly "@context"?: LogJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  id?: string | null
  userFirstname?: string | null
  userLastname?: string | null
  oldValue?: unknown
  newValue?: unknown
  action?: string | null
  loggedAt?: string | null
  field?: string | null
}

export type LegalsDTOJsonldContextOneOfHydra =
  (typeof LegalsDTOJsonldContextOneOfHydra)[keyof typeof LegalsDTOJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LegalsDTOJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type LegalsDTOJsonldContextOneOf = {
  "@vocab": string
  hydra: LegalsDTOJsonldContextOneOfHydra
  [key: string]: any
}

export type LegalsDTOJsonldContext = string | LegalsDTOJsonldContextOneOf

export interface LegalsDTOJsonld {
  readonly "@context"?: LegalsDTOJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  id?: string
  agencyOwner?: string
  agencyOwnerLegals?: string
}

export interface LegalsDTO {
  id?: string
  agencyOwner?: string
  agencyOwnerLegals?: string
}

export interface LegalRepresentativeDTOJsonld {
  readonly "@context"?: LegalRepresentativeDTOJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  id?: string
  agencyOwner?: string
  collaborator?: string
}

export type LegalRepresentativeDTOJsonldContextOneOfHydra =
  (typeof LegalRepresentativeDTOJsonldContextOneOfHydra)[keyof typeof LegalRepresentativeDTOJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LegalRepresentativeDTOJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type LegalRepresentativeDTOJsonldContextOneOf = {
  "@vocab": string
  hydra: LegalRepresentativeDTOJsonldContextOneOfHydra
  [key: string]: any
}

export type LegalRepresentativeDTOJsonldContext = string | LegalRepresentativeDTOJsonldContextOneOf

export interface LegalRepresentativeDTO {
  id?: string
  agencyOwner?: string
  collaborator?: string
}

export interface InterBuProgressStepUploadedFileJsonldReadItemInterbu {
  readonly "@context"?: InterBuProgressStepUploadedFileJsonldReadItemInterbuContext
  readonly "@id"?: string
  readonly "@type"?: string
  fileUrl?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type InterBuProgressStepUploadedFileJsonldReadItemInterbuContextOneOfHydra =
  (typeof InterBuProgressStepUploadedFileJsonldReadItemInterbuContextOneOfHydra)[keyof typeof InterBuProgressStepUploadedFileJsonldReadItemInterbuContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuProgressStepUploadedFileJsonldReadItemInterbuContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type InterBuProgressStepUploadedFileJsonldReadItemInterbuContextOneOf = {
  "@vocab": string
  hydra: InterBuProgressStepUploadedFileJsonldReadItemInterbuContextOneOfHydra
  [key: string]: any
}

export type InterBuProgressStepUploadedFileJsonldReadItemInterbuContext =
  | string
  | InterBuProgressStepUploadedFileJsonldReadItemInterbuContextOneOf

export type InterBuProgressStepJsonldReadItemInterbuRecipientBusinessUnit =
  BusinessUnitJsonldReadItemInterbu | null

export type InterBuProgressStepJsonldReadItemInterbuRecipientUser = UserJsonldReadItemInterbu | null

export type InterBuProgressStepJsonldReadItemInterbuMotivation =
  (typeof InterBuProgressStepJsonldReadItemInterbuMotivation)[keyof typeof InterBuProgressStepJsonldReadItemInterbuMotivation]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuProgressStepJsonldReadItemInterbuMotivation = {
  ALREADY_KNOWN_CLIENT_OR_PROJECT: "ALREADY_KNOWN_CLIENT_OR_PROJECT",
  IMPOSSIBLE_PROJECT: "IMPOSSIBLE_PROJECT",
  NOT_IN_SCOPE: "NOT_IN_SCOPE",
  OTHER: "OTHER",
  NOTICE_OF_VALUE: "NOTICE_OF_VALUE",
  PROJECT_CANCELLED: "PROJECT_CANCELLED",
  PROJECT_COMPLETED_OUTSIDE_BARNES: "PROJECT_COMPLETED_OUTSIDE_BARNES",
  CLIENT_NOT_RESPONSIVE: "CLIENT_NOT_RESPONSIVE",
  LACK_OF_FUNDING: "LACK_OF_FUNDING",
} as const

export type InterBuProgressStepJsonldReadItemInterbuProgressStep =
  (typeof InterBuProgressStepJsonldReadItemInterbuProgressStep)[keyof typeof InterBuProgressStepJsonldReadItemInterbuProgressStep]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuProgressStepJsonldReadItemInterbuProgressStep = {
  SENT_TO_BU: "SENT_TO_BU",
  ASSIGNED_TO_USER: "ASSIGNED_TO_USER",
  CONTACTED: "CONTACTED",
  IN_PROGRESS: "IN_PROGRESS",
  FINISHED: "FINISHED",
  ABANDONED: "ABANDONED",
  REFUSED: "REFUSED",
} as const

export interface InterBuProgressStepJsonldReadItemInterbu {
  readonly "@context"?: InterBuProgressStepJsonldReadItemInterbuContext
  readonly "@id"?: string
  readonly "@type"?: string
  progressStep: InterBuProgressStepJsonldReadItemInterbuProgressStep
  comment?: string | null
  uploadedFiles?: InterBuProgressStepUploadedFileJsonldReadItemInterbu[]
  user: UserJsonldReadItemInterbu
  motivation?: InterBuProgressStepJsonldReadItemInterbuMotivation
  recipientUser?: InterBuProgressStepJsonldReadItemInterbuRecipientUser
  recipientBusinessUnit?: InterBuProgressStepJsonldReadItemInterbuRecipientBusinessUnit
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type InterBuProgressStepJsonldReadItemInterbuContextOneOfHydra =
  (typeof InterBuProgressStepJsonldReadItemInterbuContextOneOfHydra)[keyof typeof InterBuProgressStepJsonldReadItemInterbuContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuProgressStepJsonldReadItemInterbuContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type InterBuProgressStepJsonldReadItemInterbuContextOneOf = {
  "@vocab": string
  hydra: InterBuProgressStepJsonldReadItemInterbuContextOneOfHydra
  [key: string]: any
}

export type InterBuProgressStepJsonldReadItemInterbuContext =
  | string
  | InterBuProgressStepJsonldReadItemInterbuContextOneOf

export type InterBuProgressStepCreateInterBuProgressStepInputProgressStep =
  (typeof InterBuProgressStepCreateInterBuProgressStepInputProgressStep)[keyof typeof InterBuProgressStepCreateInterBuProgressStepInputProgressStep]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuProgressStepCreateInterBuProgressStepInputProgressStep = {
  SENT_TO_BU: "SENT_TO_BU",
  ASSIGNED_TO_USER: "ASSIGNED_TO_USER",
  CONTACTED: "CONTACTED",
  IN_PROGRESS: "IN_PROGRESS",
  FINISHED: "FINISHED",
  ABANDONED: "ABANDONED",
  REFUSED: "REFUSED",
} as const

export interface InterBuProgressStepCreateInterBuProgressStepInput {
  interBuId: string
  progressStep: InterBuProgressStepCreateInterBuProgressStepInputProgressStep
  "files[]"?: Blob[]
  comment?: string | null
  motivation?: string | null
  recipientUserId?: string | null
  recipientBuId?: string | null
}

export type InterBuJsonldReadItemInterbuStatus =
  (typeof InterBuJsonldReadItemInterbuStatus)[keyof typeof InterBuJsonldReadItemInterbuStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuJsonldReadItemInterbuStatus = {
  WAITING: "WAITING",
  IN_PROGRESS: "IN_PROGRESS",
  FINISHED: "FINISHED",
  ABANDONED: "ABANDONED",
  REFUSED: "REFUSED",
} as const

export type InterBuJsonldReadItemInterbuArrivalCountry =
  (typeof InterBuJsonldReadItemInterbuArrivalCountry)[keyof typeof InterBuJsonldReadItemInterbuArrivalCountry]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuJsonldReadItemInterbuArrivalCountry = {
  af: "af",
  ax: "ax",
  al: "al",
  dz: "dz",
  as: "as",
  ad: "ad",
  ao: "ao",
  ai: "ai",
  aq: "aq",
  ag: "ag",
  ar: "ar",
  am: "am",
  aw: "aw",
  au: "au",
  at: "at",
  az: "az",
  bs: "bs",
  bh: "bh",
  bd: "bd",
  bb: "bb",
  by: "by",
  be: "be",
  bz: "bz",
  bj: "bj",
  bm: "bm",
  bt: "bt",
  bo: "bo",
  ba: "ba",
  bw: "bw",
  bv: "bv",
  br: "br",
  io: "io",
  vg: "vg",
  bn: "bn",
  bg: "bg",
  bf: "bf",
  bi: "bi",
  kh: "kh",
  cm: "cm",
  ca: "ca",
  cv: "cv",
  bq: "bq",
  ky: "ky",
  cf: "cf",
  td: "td",
  cl: "cl",
  cn: "cn",
  cx: "cx",
  cc: "cc",
  co: "co",
  km: "km",
  ck: "ck",
  cr: "cr",
  ci: "ci",
  hr: "hr",
  cu: "cu",
  cw: "cw",
  cy: "cy",
  cz: "cz",
  cd: "cd",
  dk: "dk",
  dj: "dj",
  dm: "dm",
  do: "do",
  ec: "ec",
  eg: "eg",
  sv: "sv",
  gq: "gq",
  er: "er",
  ee: "ee",
  sz: "sz",
  et: "et",
  fk: "fk",
  fo: "fo",
  fj: "fj",
  fi: "fi",
  fr: "fr",
  gf: "gf",
  pf: "pf",
  tf: "tf",
  ga: "ga",
  gm: "gm",
  ge: "ge",
  de: "de",
  gh: "gh",
  gi: "gi",
  gr: "gr",
  gl: "gl",
  gd: "gd",
  gp: "gp",
  gu: "gu",
  gt: "gt",
  gg: "gg",
  gn: "gn",
  gw: "gw",
  gy: "gy",
  ht: "ht",
  hm: "hm",
  hn: "hn",
  hk: "hk",
  hu: "hu",
  is: "is",
  in: "in",
  id: "id",
  ir: "ir",
  iq: "iq",
  ie: "ie",
  im: "im",
  il: "il",
  it: "it",
  jm: "jm",
  jp: "jp",
  je: "je",
  jo: "jo",
  kz: "kz",
  ke: "ke",
  ki: "ki",
  xk: "xk",
  kw: "kw",
  kg: "kg",
  la: "la",
  lv: "lv",
  lb: "lb",
  ls: "ls",
  lr: "lr",
  ly: "ly",
  li: "li",
  lt: "lt",
  lu: "lu",
  mo: "mo",
  mg: "mg",
  mw: "mw",
  my: "my",
  mv: "mv",
  ml: "ml",
  mt: "mt",
  mh: "mh",
  mq: "mq",
  mr: "mr",
  mu: "mu",
  yt: "yt",
  mx: "mx",
  fm: "fm",
  md: "md",
  mc: "mc",
  mn: "mn",
  me: "me",
  ms: "ms",
  ma: "ma",
  mz: "mz",
  mm: "mm",
  na: "na",
  nr: "nr",
  np: "np",
  nl: "nl",
  nc: "nc",
  nz: "nz",
  ni: "ni",
  ne: "ne",
  ng: "ng",
  nu: "nu",
  nf: "nf",
  kp: "kp",
  mk: "mk",
  mp: "mp",
  no: "no",
  om: "om",
  pk: "pk",
  pw: "pw",
  ps: "ps",
  pa: "pa",
  pg: "pg",
  py: "py",
  pe: "pe",
  ph: "ph",
  pn: "pn",
  pl: "pl",
  pt: "pt",
  pr: "pr",
  qa: "qa",
  cg: "cg",
  re: "re",
  ro: "ro",
  ru: "ru",
  rw: "rw",
  bl: "bl",
  sh: "sh",
  kn: "kn",
  lc: "lc",
  mf: "mf",
  pm: "pm",
  vc: "vc",
  ws: "ws",
  sm: "sm",
  st: "st",
  sa: "sa",
  sn: "sn",
  rs: "rs",
  sc: "sc",
  sl: "sl",
  sg: "sg",
  sx: "sx",
  sk: "sk",
  si: "si",
  sb: "sb",
  so: "so",
  za: "za",
  gs: "gs",
  kr: "kr",
  ss: "ss",
  es: "es",
  lk: "lk",
  sd: "sd",
  sr: "sr",
  sj: "sj",
  se: "se",
  ch: "ch",
  sy: "sy",
  tw: "tw",
  tj: "tj",
  tz: "tz",
  th: "th",
  tl: "tl",
  tg: "tg",
  tk: "tk",
  to: "to",
  tt: "tt",
  tn: "tn",
  tr: "tr",
  tm: "tm",
  tc: "tc",
  tv: "tv",
  ug: "ug",
  ua: "ua",
  ae: "ae",
  gb: "gb",
  us: "us",
  um: "um",
  vi: "vi",
  uy: "uy",
  uz: "uz",
  vu: "vu",
  va: "va",
  ve: "ve",
  vn: "vn",
  wf: "wf",
  eh: "eh",
  ye: "ye",
  zm: "zm",
  zw: "zw",
} as const

export type InterBuJsonldReadItemInterbuDepartureCountry =
  (typeof InterBuJsonldReadItemInterbuDepartureCountry)[keyof typeof InterBuJsonldReadItemInterbuDepartureCountry]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuJsonldReadItemInterbuDepartureCountry = {
  af: "af",
  ax: "ax",
  al: "al",
  dz: "dz",
  as: "as",
  ad: "ad",
  ao: "ao",
  ai: "ai",
  aq: "aq",
  ag: "ag",
  ar: "ar",
  am: "am",
  aw: "aw",
  au: "au",
  at: "at",
  az: "az",
  bs: "bs",
  bh: "bh",
  bd: "bd",
  bb: "bb",
  by: "by",
  be: "be",
  bz: "bz",
  bj: "bj",
  bm: "bm",
  bt: "bt",
  bo: "bo",
  ba: "ba",
  bw: "bw",
  bv: "bv",
  br: "br",
  io: "io",
  vg: "vg",
  bn: "bn",
  bg: "bg",
  bf: "bf",
  bi: "bi",
  kh: "kh",
  cm: "cm",
  ca: "ca",
  cv: "cv",
  bq: "bq",
  ky: "ky",
  cf: "cf",
  td: "td",
  cl: "cl",
  cn: "cn",
  cx: "cx",
  cc: "cc",
  co: "co",
  km: "km",
  ck: "ck",
  cr: "cr",
  ci: "ci",
  hr: "hr",
  cu: "cu",
  cw: "cw",
  cy: "cy",
  cz: "cz",
  cd: "cd",
  dk: "dk",
  dj: "dj",
  dm: "dm",
  do: "do",
  ec: "ec",
  eg: "eg",
  sv: "sv",
  gq: "gq",
  er: "er",
  ee: "ee",
  sz: "sz",
  et: "et",
  fk: "fk",
  fo: "fo",
  fj: "fj",
  fi: "fi",
  fr: "fr",
  gf: "gf",
  pf: "pf",
  tf: "tf",
  ga: "ga",
  gm: "gm",
  ge: "ge",
  de: "de",
  gh: "gh",
  gi: "gi",
  gr: "gr",
  gl: "gl",
  gd: "gd",
  gp: "gp",
  gu: "gu",
  gt: "gt",
  gg: "gg",
  gn: "gn",
  gw: "gw",
  gy: "gy",
  ht: "ht",
  hm: "hm",
  hn: "hn",
  hk: "hk",
  hu: "hu",
  is: "is",
  in: "in",
  id: "id",
  ir: "ir",
  iq: "iq",
  ie: "ie",
  im: "im",
  il: "il",
  it: "it",
  jm: "jm",
  jp: "jp",
  je: "je",
  jo: "jo",
  kz: "kz",
  ke: "ke",
  ki: "ki",
  xk: "xk",
  kw: "kw",
  kg: "kg",
  la: "la",
  lv: "lv",
  lb: "lb",
  ls: "ls",
  lr: "lr",
  ly: "ly",
  li: "li",
  lt: "lt",
  lu: "lu",
  mo: "mo",
  mg: "mg",
  mw: "mw",
  my: "my",
  mv: "mv",
  ml: "ml",
  mt: "mt",
  mh: "mh",
  mq: "mq",
  mr: "mr",
  mu: "mu",
  yt: "yt",
  mx: "mx",
  fm: "fm",
  md: "md",
  mc: "mc",
  mn: "mn",
  me: "me",
  ms: "ms",
  ma: "ma",
  mz: "mz",
  mm: "mm",
  na: "na",
  nr: "nr",
  np: "np",
  nl: "nl",
  nc: "nc",
  nz: "nz",
  ni: "ni",
  ne: "ne",
  ng: "ng",
  nu: "nu",
  nf: "nf",
  kp: "kp",
  mk: "mk",
  mp: "mp",
  no: "no",
  om: "om",
  pk: "pk",
  pw: "pw",
  ps: "ps",
  pa: "pa",
  pg: "pg",
  py: "py",
  pe: "pe",
  ph: "ph",
  pn: "pn",
  pl: "pl",
  pt: "pt",
  pr: "pr",
  qa: "qa",
  cg: "cg",
  re: "re",
  ro: "ro",
  ru: "ru",
  rw: "rw",
  bl: "bl",
  sh: "sh",
  kn: "kn",
  lc: "lc",
  mf: "mf",
  pm: "pm",
  vc: "vc",
  ws: "ws",
  sm: "sm",
  st: "st",
  sa: "sa",
  sn: "sn",
  rs: "rs",
  sc: "sc",
  sl: "sl",
  sg: "sg",
  sx: "sx",
  sk: "sk",
  si: "si",
  sb: "sb",
  so: "so",
  za: "za",
  gs: "gs",
  kr: "kr",
  ss: "ss",
  es: "es",
  lk: "lk",
  sd: "sd",
  sr: "sr",
  sj: "sj",
  se: "se",
  ch: "ch",
  sy: "sy",
  tw: "tw",
  tj: "tj",
  tz: "tz",
  th: "th",
  tl: "tl",
  tg: "tg",
  tk: "tk",
  to: "to",
  tt: "tt",
  tn: "tn",
  tr: "tr",
  tm: "tm",
  tc: "tc",
  tv: "tv",
  ug: "ug",
  ua: "ua",
  ae: "ae",
  gb: "gb",
  us: "us",
  um: "um",
  vi: "vi",
  uy: "uy",
  uz: "uz",
  vu: "vu",
  va: "va",
  ve: "ve",
  vn: "vn",
  wf: "wf",
  eh: "eh",
  ye: "ye",
  zm: "zm",
  zw: "zw",
} as const

export type InterBuJsonldReadItemInterbuSenderBusinessUnit =
  BusinessUnitJsonldReadItemInterbu | null

export type InterBuJsonldReadItemInterbuCountry =
  (typeof InterBuJsonldReadItemInterbuCountry)[keyof typeof InterBuJsonldReadItemInterbuCountry]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuJsonldReadItemInterbuCountry = {
  be: "be",
  bg: "bg",
  ca: "ca",
  es: "es",
  us: "us",
  fr: "fr",
  gr: "gr",
  hu: "hu",
  it: "it",
  lu: "lu",
  mu: "mu",
  ma: "ma",
  mc: "mc",
  pt: "pt",
  ru: "ru",
  bl: "bl",
  ch: "ch",
  gb: "gb",
} as const

export type InterBuJsonldReadItemInterbuSurfaceAreaUnit =
  (typeof InterBuJsonldReadItemInterbuSurfaceAreaUnit)[keyof typeof InterBuJsonldReadItemInterbuSurfaceAreaUnit]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuJsonldReadItemInterbuSurfaceAreaUnit = {
  "m²": "m²",
  "ft²": "ft²",
  ha: "ha",
} as const

export type InterBuJsonldReadItemInterbuSpendingLimitCurrency =
  (typeof InterBuJsonldReadItemInterbuSpendingLimitCurrency)[keyof typeof InterBuJsonldReadItemInterbuSpendingLimitCurrency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuJsonldReadItemInterbuSpendingLimitCurrency = {
  EUR: "EUR",
  USD: "USD",
  CAD: "CAD",
  HUF: "HUF",
  MUR: "MUR",
  MAD: "MAD",
  RUB: "RUB",
  CHF: "CHF",
  GBP: "GBP",
} as const

export type InterBuJsonldReadItemInterbuPropertyType =
  (typeof InterBuJsonldReadItemInterbuPropertyType)[keyof typeof InterBuJsonldReadItemInterbuPropertyType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuJsonldReadItemInterbuPropertyType = {
  HOUSE: "HOUSE",
  VILLA: "VILLA",
  COTTAGE: "COTTAGE",
  FARM: "FARM",
  FLAT: "FLAT",
  LOFT: "LOFT",
  DUPLEX: "DUPLEX",
  LAND: "LAND",
  MANSION: "MANSION",
  BUILDING: "BUILDING",
  OFFICE_OR_BUSINESS: "OFFICE_OR_BUSINESS",
  CASTEL_OR_MANOR: "CASTEL_OR_MANOR",
  WINE_ESTATE: "WINE_ESTATE",
  STUD_FARM_AND_EQUESTRIAN_ESTATE: "STUD_FARM_AND_EQUESTRIAN_ESTATE",
  HUNTING_TERRAIN: "HUNTING_TERRAIN",
  CELLAR_OR_BOX: "CELLAR_OR_BOX",
  PARKING: "PARKING",
  APARTMENT: "APARTMENT",
} as const

export type InterBuJsonldReadItemInterbuProjectType =
  (typeof InterBuJsonldReadItemInterbuProjectType)[keyof typeof InterBuJsonldReadItemInterbuProjectType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuJsonldReadItemInterbuProjectType = {
  ACQUISITION: "ACQUISITION",
  LOCATION: "LOCATION",
  SEASONAL_LOCATION: "SEASONAL_LOCATION",
  SALE: "SALE",
  RENTAL: "RENTAL",
  SEASONAL_RENTAL: "SEASONAL_RENTAL",
  RENTAL_MANAGEMENT: "RENTAL_MANAGEMENT",
} as const

export type InterBuJsonldReadItemInterbuActivity =
  (typeof InterBuJsonldReadItemInterbuActivity)[keyof typeof InterBuJsonldReadItemInterbuActivity]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuJsonldReadItemInterbuActivity = {
  PROPERTY: "PROPERTY",
  PRIVATE_AVIATION: "PRIVATE_AVIATION",
  CONSTRUCTION: "CONSTRUCTION",
  ART_ADVISORY: "ART_ADVISORY",
} as const

export type InterBuJsonldReadItemInterbuUserRole =
  (typeof InterBuJsonldReadItemInterbuUserRole)[keyof typeof InterBuJsonldReadItemInterbuUserRole]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuJsonldReadItemInterbuUserRole = {
  DIRECT_DECISION_MAKER: "DIRECT_DECISION_MAKER",
  INDIRECT_DECISION_MAKER: "INDIRECT_DECISION_MAKER",
  INTERMEDIATE: "INTERMEDIATE",
} as const

export type InterBuJsonldReadItemInterbuRelationType =
  (typeof InterBuJsonldReadItemInterbuRelationType)[keyof typeof InterBuJsonldReadItemInterbuRelationType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuJsonldReadItemInterbuRelationType = {
  COUPLE: "COUPLE",
  FAMILY: "FAMILY",
  PROFESSIONAL: "PROFESSIONAL",
  INTERMEDIATE: "INTERMEDIATE",
  ADVICE: "ADVICE",
} as const

export type InterBuJsonldReadItemInterbuRecipientUser = UserJsonldReadItemInterbu | null

export interface InterBuJsonldReadItemInterbu {
  readonly "@context"?: InterBuJsonldReadItemInterbuContext
  readonly "@id"?: string
  readonly "@type"?: string
  recipientUser?: InterBuJsonldReadItemInterbuRecipientUser
  senderUser: UserJsonldReadItemInterbu
  client: ClientJsonldReadItemInterbu
  relationType: InterBuJsonldReadItemInterbuRelationType
  userRole: InterBuJsonldReadItemInterbuUserRole
  clientComment?: string | null
  activity?: InterBuJsonldReadItemInterbuActivity
  projectType?: InterBuJsonldReadItemInterbuProjectType
  propertyType?: InterBuJsonldReadItemInterbuPropertyType
  spendingLimit?: number | null
  spendingLimitCurrency?: InterBuJsonldReadItemInterbuSpendingLimitCurrency
  surfaceArea?: number | null
  surfaceAreaUnit?: InterBuJsonldReadItemInterbuSurfaceAreaUnit
  country?: InterBuJsonldReadItemInterbuCountry
  projectComment?: string | null
  recipientBusinessUnit: BusinessUnitJsonldReadItemInterbu
  recipientComment?: string | null
  senderBusinessUnit?: InterBuJsonldReadItemInterbuSenderBusinessUnit
  isClientMailConfidential: boolean
  isClientPhoneNumberConfidential: boolean
  progressSteps?: InterBuProgressStepJsonldReadItemInterbu[]
  departureCountry?: InterBuJsonldReadItemInterbuDepartureCountry
  arrivalCountry?: InterBuJsonldReadItemInterbuArrivalCountry
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
  readonly status: InterBuJsonldReadItemInterbuStatus
}

export type InterBuJsonldReadItemInterbuContextOneOfHydra =
  (typeof InterBuJsonldReadItemInterbuContextOneOfHydra)[keyof typeof InterBuJsonldReadItemInterbuContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuJsonldReadItemInterbuContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type InterBuJsonldReadItemInterbuContextOneOf = {
  "@vocab": string
  hydra: InterBuJsonldReadItemInterbuContextOneOfHydra
  [key: string]: any
}

export type InterBuJsonldReadItemInterbuContext = string | InterBuJsonldReadItemInterbuContextOneOf

export type InterBuJsonldReadCollectionInterbuStatus =
  (typeof InterBuJsonldReadCollectionInterbuStatus)[keyof typeof InterBuJsonldReadCollectionInterbuStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuJsonldReadCollectionInterbuStatus = {
  WAITING: "WAITING",
  IN_PROGRESS: "IN_PROGRESS",
  FINISHED: "FINISHED",
  ABANDONED: "ABANDONED",
  REFUSED: "REFUSED",
} as const

export type InterBuJsonldReadCollectionInterbuSenderBusinessUnit =
  BusinessUnitJsonldReadCollectionInterbu | null

export type InterBuJsonldReadCollectionInterbuPropertyType =
  (typeof InterBuJsonldReadCollectionInterbuPropertyType)[keyof typeof InterBuJsonldReadCollectionInterbuPropertyType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuJsonldReadCollectionInterbuPropertyType = {
  HOUSE: "HOUSE",
  VILLA: "VILLA",
  COTTAGE: "COTTAGE",
  FARM: "FARM",
  FLAT: "FLAT",
  LOFT: "LOFT",
  DUPLEX: "DUPLEX",
  LAND: "LAND",
  MANSION: "MANSION",
  BUILDING: "BUILDING",
  OFFICE_OR_BUSINESS: "OFFICE_OR_BUSINESS",
  CASTEL_OR_MANOR: "CASTEL_OR_MANOR",
  WINE_ESTATE: "WINE_ESTATE",
  STUD_FARM_AND_EQUESTRIAN_ESTATE: "STUD_FARM_AND_EQUESTRIAN_ESTATE",
  HUNTING_TERRAIN: "HUNTING_TERRAIN",
  CELLAR_OR_BOX: "CELLAR_OR_BOX",
  PARKING: "PARKING",
  APARTMENT: "APARTMENT",
} as const

export type InterBuJsonldReadCollectionInterbuProjectType =
  (typeof InterBuJsonldReadCollectionInterbuProjectType)[keyof typeof InterBuJsonldReadCollectionInterbuProjectType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuJsonldReadCollectionInterbuProjectType = {
  ACQUISITION: "ACQUISITION",
  LOCATION: "LOCATION",
  SEASONAL_LOCATION: "SEASONAL_LOCATION",
  SALE: "SALE",
  RENTAL: "RENTAL",
  SEASONAL_RENTAL: "SEASONAL_RENTAL",
  RENTAL_MANAGEMENT: "RENTAL_MANAGEMENT",
} as const

export type InterBuJsonldReadCollectionInterbuActivity =
  (typeof InterBuJsonldReadCollectionInterbuActivity)[keyof typeof InterBuJsonldReadCollectionInterbuActivity]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterBuJsonldReadCollectionInterbuActivity = {
  PROPERTY: "PROPERTY",
  PRIVATE_AVIATION: "PRIVATE_AVIATION",
  CONSTRUCTION: "CONSTRUCTION",
  ART_ADVISORY: "ART_ADVISORY",
} as const

export type InterBuJsonldReadCollectionInterbuRecipientUser = UserJsonldReadCollectionInterbu | null

export interface InterBuJsonldReadCollectionInterbu {
  readonly "@id"?: string
  readonly "@type"?: string
  recipientUser?: InterBuJsonldReadCollectionInterbuRecipientUser
  senderUser: UserJsonldReadCollectionInterbu
  client: ClientJsonldReadCollectionInterbu
  activity?: InterBuJsonldReadCollectionInterbuActivity
  projectType?: InterBuJsonldReadCollectionInterbuProjectType
  propertyType?: InterBuJsonldReadCollectionInterbuPropertyType
  recipientBusinessUnit: BusinessUnitJsonldReadCollectionInterbu
  senderBusinessUnit?: InterBuJsonldReadCollectionInterbuSenderBusinessUnit
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
  readonly status: InterBuJsonldReadCollectionInterbuStatus
}

export interface InterBuCreateInterBUsInputJsonld {
  clientId?: string
  relationType?: string
  userRole?: string
  clientComment?: string | null
  activity?: string
  projectType?: string | null
  propertyType?: string | null
  spendingLimit?: number | null
  spendingLimitCurrency?: string | null
  surfaceArea?: number | null
  surfaceAreaUnit?: string | null
  country?: string | null
  city?: string | null
  address?: string | null
  projectComment?: string | null
  isClientMailConfidential?: boolean
  isClientPhoneNumberConfidential?: boolean
  departureCountry?: string | null
  departureCity?: string | null
  arrivalCountry?: string | null
  arrivalCity?: string | null
  returnDate?: string | null
  departureDate?: string | null
  recipientBusinessUnitsIds?: string[]
  recipientComment?: string | null
}

export type I18nTextJsonldReadItemPropertySearchPropertySearch =
  PropertySearchJsonldReadItemPropertySearch | null

export interface I18nTextJsonldReadItemPropertySearch {
  readonly "@context"?: I18nTextJsonldReadItemPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  propertySearch?: I18nTextJsonldReadItemPropertySearchPropertySearch
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type I18nTextJsonldReadItemPropertySearchContextOneOfHydra =
  (typeof I18nTextJsonldReadItemPropertySearchContextOneOfHydra)[keyof typeof I18nTextJsonldReadItemPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const I18nTextJsonldReadItemPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type I18nTextJsonldReadItemPropertySearchContextOneOf = {
  "@vocab": string
  hydra: I18nTextJsonldReadItemPropertySearchContextOneOfHydra
  [key: string]: any
}

export type I18nTextJsonldReadItemPropertySearchContext =
  | string
  | I18nTextJsonldReadItemPropertySearchContextOneOf

export type I18nTextJsonldReadItemPropertyProperty = PropertyJsonldReadItemProperty | null

export type I18nTextJsonldReadItemPropertyLocale = RefLabelJsonldReadItemProperty | null

export type I18nTextJsonldReadItemPropertyContextOneOfHydra =
  (typeof I18nTextJsonldReadItemPropertyContextOneOfHydra)[keyof typeof I18nTextJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const I18nTextJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type I18nTextJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: I18nTextJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type I18nTextJsonldReadItemPropertyContext =
  | string
  | I18nTextJsonldReadItemPropertyContextOneOf

export interface I18nTextJsonldReadItemProperty {
  readonly "@context"?: I18nTextJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  hook?: string | null
  isPrivate?: boolean | null
  locale?: I18nTextJsonldReadItemPropertyLocale
  message?: string | null
  shortMessage?: string | null
  subtitle?: string | null
  title?: string | null
  property?: I18nTextJsonldReadItemPropertyProperty
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type I18nTextJsonldReadItemProjectLocale = RefLabelJsonldReadItemProject | null

export interface I18nTextJsonldReadItemProject {
  readonly "@context"?: I18nTextJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  hook?: string | null
  isPrivate?: boolean | null
  locale?: I18nTextJsonldReadItemProjectLocale
  message?: string | null
  shortMessage?: string | null
  subtitle?: string | null
  title?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type I18nTextJsonldReadItemProjectContextOneOfHydra =
  (typeof I18nTextJsonldReadItemProjectContextOneOfHydra)[keyof typeof I18nTextJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const I18nTextJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type I18nTextJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: I18nTextJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type I18nTextJsonldReadItemProjectContext =
  | string
  | I18nTextJsonldReadItemProjectContextOneOf

export type I18nTextJsonldReadItemClusterItemLocale = RefLabelJsonldReadItemClusterItem | null

export type I18nTextJsonldReadItemClusterItemContextOneOfHydra =
  (typeof I18nTextJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof I18nTextJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const I18nTextJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type I18nTextJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: I18nTextJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type I18nTextJsonldReadItemClusterItemContext =
  | string
  | I18nTextJsonldReadItemClusterItemContextOneOf

export interface I18nTextJsonldReadItemClusterItem {
  readonly "@context"?: I18nTextJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  hook?: string | null
  isPrivate?: boolean | null
  locale?: I18nTextJsonldReadItemClusterItemLocale
  message?: string | null
  shortMessage?: string | null
  subtitle?: string | null
  title?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type I18nTextJsonldReadItemAgencyItemLocale = RefLabelJsonldReadItemAgencyItem | null

export interface I18nTextJsonldReadItemAgencyItem {
  readonly "@context"?: I18nTextJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  hook?: string | null
  isPrivate?: boolean | null
  locale?: I18nTextJsonldReadItemAgencyItemLocale
  message?: string | null
  shortMessage?: string | null
  subtitle?: string | null
  title?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type I18nTextJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof I18nTextJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof I18nTextJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const I18nTextJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type I18nTextJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: I18nTextJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type I18nTextJsonldReadItemAgencyItemContext =
  | string
  | I18nTextJsonldReadItemAgencyItemContextOneOf

export type I18nTextJsonldReadCollectionPropertySearchExcelExportPropertySearch =
  PropertySearchJsonldReadCollectionPropertySearchExcelExport | null

export interface I18nTextJsonldReadCollectionPropertySearchExcelExport {
  readonly "@context"?: I18nTextJsonldReadCollectionPropertySearchExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  propertySearch?: I18nTextJsonldReadCollectionPropertySearchExcelExportPropertySearch
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type I18nTextJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra =
  (typeof I18nTextJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra)[keyof typeof I18nTextJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const I18nTextJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type I18nTextJsonldReadCollectionPropertySearchExcelExportContextOneOf = {
  "@vocab": string
  hydra: I18nTextJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra
  [key: string]: any
}

export type I18nTextJsonldReadCollectionPropertySearchExcelExportContext =
  | string
  | I18nTextJsonldReadCollectionPropertySearchExcelExportContextOneOf

export type I18nTextJsonldReadCollectionPropertySearchPropertySearch =
  PropertySearchJsonldReadCollectionPropertySearch | null

export type I18nTextJsonldReadCollectionPropertySearchContextOneOfHydra =
  (typeof I18nTextJsonldReadCollectionPropertySearchContextOneOfHydra)[keyof typeof I18nTextJsonldReadCollectionPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const I18nTextJsonldReadCollectionPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type I18nTextJsonldReadCollectionPropertySearchContextOneOf = {
  "@vocab": string
  hydra: I18nTextJsonldReadCollectionPropertySearchContextOneOfHydra
  [key: string]: any
}

export type I18nTextJsonldReadCollectionPropertySearchContext =
  | string
  | I18nTextJsonldReadCollectionPropertySearchContextOneOf

export interface I18nTextJsonldReadCollectionPropertySearch {
  readonly "@context"?: I18nTextJsonldReadCollectionPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  propertySearch?: I18nTextJsonldReadCollectionPropertySearchPropertySearch
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type I18nTextJsonldReadCollectionPropertyExcelExportProperty =
  PropertyJsonldReadCollectionPropertyExcelExport | null

export type I18nTextJsonldReadCollectionPropertyExcelExportLocale =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export interface I18nTextJsonldReadCollectionPropertyExcelExport {
  readonly "@context"?: I18nTextJsonldReadCollectionPropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  hook?: string | null
  isPrivate?: boolean | null
  locale?: I18nTextJsonldReadCollectionPropertyExcelExportLocale
  message?: string | null
  shortMessage?: string | null
  subtitle?: string | null
  title?: string | null
  property?: I18nTextJsonldReadCollectionPropertyExcelExportProperty
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type I18nTextJsonldReadCollectionPropertyExcelExportContextOneOfHydra =
  (typeof I18nTextJsonldReadCollectionPropertyExcelExportContextOneOfHydra)[keyof typeof I18nTextJsonldReadCollectionPropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const I18nTextJsonldReadCollectionPropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type I18nTextJsonldReadCollectionPropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: I18nTextJsonldReadCollectionPropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type I18nTextJsonldReadCollectionPropertyExcelExportContext =
  | string
  | I18nTextJsonldReadCollectionPropertyExcelExportContextOneOf

export type I18nTextJsonldReadCollectionPropertyProperty =
  PropertyJsonldReadCollectionProperty | null

export type I18nTextJsonldReadCollectionPropertyLocale = RefLabelJsonldReadCollectionProperty | null

export interface I18nTextJsonldReadCollectionProperty {
  readonly "@context"?: I18nTextJsonldReadCollectionPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  hook?: string | null
  isPrivate?: boolean | null
  locale?: I18nTextJsonldReadCollectionPropertyLocale
  message?: string | null
  shortMessage?: string | null
  subtitle?: string | null
  title?: string | null
  property?: I18nTextJsonldReadCollectionPropertyProperty
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type I18nTextJsonldReadCollectionPropertyContextOneOfHydra =
  (typeof I18nTextJsonldReadCollectionPropertyContextOneOfHydra)[keyof typeof I18nTextJsonldReadCollectionPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const I18nTextJsonldReadCollectionPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type I18nTextJsonldReadCollectionPropertyContextOneOf = {
  "@vocab": string
  hydra: I18nTextJsonldReadCollectionPropertyContextOneOfHydra
  [key: string]: any
}

export type I18nTextJsonldReadCollectionPropertyContext =
  | string
  | I18nTextJsonldReadCollectionPropertyContextOneOf

export type I18nTextJsonldReadCollectionProjectExcelExportLocale =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type I18nTextJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof I18nTextJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof I18nTextJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const I18nTextJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type I18nTextJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: I18nTextJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type I18nTextJsonldReadCollectionProjectExcelExportContext =
  | string
  | I18nTextJsonldReadCollectionProjectExcelExportContextOneOf

export interface I18nTextJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: I18nTextJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  hook?: string | null
  isPrivate?: boolean | null
  locale?: I18nTextJsonldReadCollectionProjectExcelExportLocale
  message?: string | null
  shortMessage?: string | null
  subtitle?: string | null
  title?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type I18nTextJsonldReadCollectionProjectLocale = RefLabelJsonldReadCollectionProject | null

export interface I18nTextJsonldReadCollectionProject {
  readonly "@context"?: I18nTextJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  hook?: string | null
  isPrivate?: boolean | null
  locale?: I18nTextJsonldReadCollectionProjectLocale
  message?: string | null
  shortMessage?: string | null
  subtitle?: string | null
  title?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type I18nTextJsonldReadCollectionProjectContextOneOfHydra =
  (typeof I18nTextJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof I18nTextJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const I18nTextJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type I18nTextJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: I18nTextJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type I18nTextJsonldReadCollectionProjectContext =
  | string
  | I18nTextJsonldReadCollectionProjectContextOneOf

export type I18nTextJsonldContextOneOfHydra =
  (typeof I18nTextJsonldContextOneOfHydra)[keyof typeof I18nTextJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const I18nTextJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type I18nTextJsonldContextOneOf = {
  "@vocab": string
  hydra: I18nTextJsonldContextOneOfHydra
  [key: string]: any
}

export type I18nTextJsonldContext = string | I18nTextJsonldContextOneOf

export interface I18nTextJsonld {
  readonly "@context"?: I18nTextJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  hook?: string | null
  isPrivate?: boolean | null
  locale?: string | null
  message?: string | null
  shortMessage?: string | null
  subtitle?: string | null
  title?: string | null
  property?: string | null
  propertySearch?: string | null
  agency?: RelAgencyI18nTextJsonld[]
  cluster?: RelClusterI18nTextJsonld[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type HolidayLocationJsonldReadItemClientWithoutPrivateDataContextOneOfHydra =
  (typeof HolidayLocationJsonldReadItemClientWithoutPrivateDataContextOneOfHydra)[keyof typeof HolidayLocationJsonldReadItemClientWithoutPrivateDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HolidayLocationJsonldReadItemClientWithoutPrivateDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type HolidayLocationJsonldReadItemClientWithoutPrivateDataContextOneOf = {
  "@vocab": string
  hydra: HolidayLocationJsonldReadItemClientWithoutPrivateDataContextOneOfHydra
  [key: string]: any
}

export type HolidayLocationJsonldReadItemClientWithoutPrivateDataContext =
  | string
  | HolidayLocationJsonldReadItemClientWithoutPrivateDataContextOneOf

export interface HolidayLocationJsonldReadItemClientWithoutPrivateData {
  readonly "@context"?: HolidayLocationJsonldReadItemClientWithoutPrivateDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  country?: string | null
  readonly id: string
}

export interface HobbyCategoryJsonldReadCollectionHobbyCategory {
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  hobbies?: HobbyJsonldReadCollectionHobbyCategory[]
  readonly id: string
}

export type HobbyCategoryJsonldContextOneOfHydra =
  (typeof HobbyCategoryJsonldContextOneOfHydra)[keyof typeof HobbyCategoryJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HobbyCategoryJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type HobbyCategoryJsonldContextOneOf = {
  "@vocab": string
  hydra: HobbyCategoryJsonldContextOneOfHydra
  [key: string]: any
}

export type HobbyCategoryJsonldContext = string | HobbyCategoryJsonldContextOneOf

export interface HobbyCategoryJsonld {
  readonly "@context"?: HobbyCategoryJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  hobbies?: string[]
  readonly id: string
}

export type HobbyCategoryTranslationsDTOTranslations = { [key: string]: { [key: string]: string } }

export interface HobbyCategoryTranslationsDTO {
  translations: HobbyCategoryTranslationsDTOTranslations
}

export interface HobbyCategoryPostHobbyCategoryInputJsonld {
  translations?: MandatoryTranslationsDTOJsonld
}

export type HobbyCategoryPatchHobbyCategoryInputTranslations = OptionalTranslationsDTO | null

export interface HobbyCategoryPatchHobbyCategoryInput {
  translations?: HobbyCategoryPatchHobbyCategoryInputTranslations
}

export interface HobbyJsonldReadItemClientWithoutPrivateData {
  readonly "@context"?: HobbyJsonldReadItemClientWithoutPrivateDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
}

export type HobbyJsonldReadItemClientWithoutPrivateDataContextOneOfHydra =
  (typeof HobbyJsonldReadItemClientWithoutPrivateDataContextOneOfHydra)[keyof typeof HobbyJsonldReadItemClientWithoutPrivateDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HobbyJsonldReadItemClientWithoutPrivateDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type HobbyJsonldReadItemClientWithoutPrivateDataContextOneOf = {
  "@vocab": string
  hydra: HobbyJsonldReadItemClientWithoutPrivateDataContextOneOfHydra
  [key: string]: any
}

export type HobbyJsonldReadItemClientWithoutPrivateDataContext =
  | string
  | HobbyJsonldReadItemClientWithoutPrivateDataContextOneOf

export type HobbyJsonldReadCollectionHobbyCategoryContextOneOfHydra =
  (typeof HobbyJsonldReadCollectionHobbyCategoryContextOneOfHydra)[keyof typeof HobbyJsonldReadCollectionHobbyCategoryContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HobbyJsonldReadCollectionHobbyCategoryContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type HobbyJsonldReadCollectionHobbyCategoryContextOneOf = {
  "@vocab": string
  hydra: HobbyJsonldReadCollectionHobbyCategoryContextOneOfHydra
  [key: string]: any
}

export type HobbyJsonldReadCollectionHobbyCategoryContext =
  | string
  | HobbyJsonldReadCollectionHobbyCategoryContextOneOf

export interface HobbyJsonldReadCollectionHobbyCategory {
  readonly "@context"?: HobbyJsonldReadCollectionHobbyCategoryContext
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
}

export type HobbyJsonldContextOneOfHydra =
  (typeof HobbyJsonldContextOneOfHydra)[keyof typeof HobbyJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HobbyJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type HobbyJsonldContextOneOf = {
  "@vocab": string
  hydra: HobbyJsonldContextOneOfHydra
  [key: string]: any
}

export type HobbyJsonldContext = string | HobbyJsonldContextOneOf

export interface HobbyJsonld {
  readonly "@context"?: HobbyJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  category?: string
  clientHobbies?: ClientHobbyJsonld[]
  readonly id: string
}

export type HobbyTranslationsDTOTranslations = { [key: string]: { [key: string]: string } }

export interface HobbyTranslationsDTO {
  translations: HobbyTranslationsDTOTranslations
}

export interface HobbyPostHobbyInputJsonld {
  hobbyCategoryId: string
  translations?: MandatoryTranslationsDTOJsonld
}

export type HobbyPatchHobbyInputTranslations = OptionalTranslationsDTO | null

export interface HobbyPatchHobbyInput {
  translations?: HobbyPatchHobbyInputTranslations
}

export interface ExternalPlatformJsonldReadItemClusterItem {
  readonly "@context"?: ExternalPlatformJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  name?: string
  url?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ExternalPlatformJsonldReadItemClusterItemContextOneOfHydra =
  (typeof ExternalPlatformJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof ExternalPlatformJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExternalPlatformJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ExternalPlatformJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: ExternalPlatformJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type ExternalPlatformJsonldReadItemClusterItemContext =
  | string
  | ExternalPlatformJsonldReadItemClusterItemContextOneOf

export type ExternalPlatformJsonldReadCollectionClusterExcelExportContextOneOfHydra =
  (typeof ExternalPlatformJsonldReadCollectionClusterExcelExportContextOneOfHydra)[keyof typeof ExternalPlatformJsonldReadCollectionClusterExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExternalPlatformJsonldReadCollectionClusterExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ExternalPlatformJsonldReadCollectionClusterExcelExportContextOneOf = {
  "@vocab": string
  hydra: ExternalPlatformJsonldReadCollectionClusterExcelExportContextOneOfHydra
  [key: string]: any
}

export type ExternalPlatformJsonldReadCollectionClusterExcelExportContext =
  | string
  | ExternalPlatformJsonldReadCollectionClusterExcelExportContextOneOf

export interface ExternalPlatformJsonldReadCollectionClusterExcelExport {
  readonly "@context"?: ExternalPlatformJsonldReadCollectionClusterExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  name?: string
  url?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface ExternalPlatformJsonldReadCollectionCluster {
  readonly "@context"?: ExternalPlatformJsonldReadCollectionClusterContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  name?: string
  url?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ExternalPlatformJsonldReadCollectionClusterContextOneOfHydra =
  (typeof ExternalPlatformJsonldReadCollectionClusterContextOneOfHydra)[keyof typeof ExternalPlatformJsonldReadCollectionClusterContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExternalPlatformJsonldReadCollectionClusterContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ExternalPlatformJsonldReadCollectionClusterContextOneOf = {
  "@vocab": string
  hydra: ExternalPlatformJsonldReadCollectionClusterContextOneOfHydra
  [key: string]: any
}

export type ExternalPlatformJsonldReadCollectionClusterContext =
  | string
  | ExternalPlatformJsonldReadCollectionClusterContextOneOf

export interface ExternalPlatformJsonld {
  readonly "@context"?: ExternalPlatformJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  code?: string
  name?: string
  url?: string | null
  cluster?: RelClusterExternalPlatformJsonld[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ExternalPlatformJsonldContextOneOfHydra =
  (typeof ExternalPlatformJsonldContextOneOfHydra)[keyof typeof ExternalPlatformJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExternalPlatformJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ExternalPlatformJsonldContextOneOf = {
  "@vocab": string
  hydra: ExternalPlatformJsonldContextOneOfHydra
  [key: string]: any
}

export type ExternalPlatformJsonldContext = string | ExternalPlatformJsonldContextOneOf

export interface EventJsonldReadCollectionEvent {
  readonly "@id"?: string
  readonly "@type"?: string
  startDate: string
  endDate: string
  title: string
  readonly id: string
}

export type EventJsonldContextOneOfHydra =
  (typeof EventJsonldContextOneOfHydra)[keyof typeof EventJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type EventJsonldContextOneOf = {
  "@vocab": string
  hydra: EventJsonldContextOneOfHydra
  [key: string]: any
}

export type EventJsonldContext = string | EventJsonldContextOneOf

export interface EventJsonld {
  readonly "@context"?: EventJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  startDate: string
  endDate: string
  title: string
  clients?: string[]
  readonly id: string
}

export interface EventPostEventInputJsonld {
  startDate: string
  endDate: string
  translations?: MandatoryTranslationsDTOJsonld
}

export type EventPatchEventInputTranslations = OptionalTranslationsDTO | null

export interface EventPatchEventInput {
  startDate?: string | null
  endDate?: string | null
  translations?: EventPatchEventInputTranslations
}

export type EventEventWithTranslationsDTOTranslations = { [key: string]: { [key: string]: string } }

export interface EventEventWithTranslationsDTO {
  startDate: string
  endDate: string
  translations: EventEventWithTranslationsDTOTranslations
}

export interface EntityResourceJsonldReadCollectionUserClustersEntityCanSetId {
  readonly "@id"?: string
  readonly "@type"?: string
}

export interface EntityResourceJsonldReadCollectionUserClusters {
  readonly "@id"?: string
  readonly "@type"?: string
}

export interface EntityMediaDTO {
  mediaID?: string
  order?: number
  isDefault?: boolean
}

export type ContactDetailsDTOJsonldTimetables = TimetablesDTOJsonld | null

export interface ContactDetailsDTOJsonld {
  readonly "@context"?: ContactDetailsDTOJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  id?: string | null
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLatitude?: string | null
  neighborhoodLongitude?: string | null
  region?: string | null
  country?: string | null
  timetables?: ContactDetailsDTOJsonldTimetables
  altitude?: string | null
  longitude?: string | null
  latitude?: string | null
  radius?: string | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: string | null
  publicLatitude?: string | null
  publicLongitude?: string | null
  order?: string | null
  isDefault?: string | null
}

export type ContactDetailsDTOJsonldContextOneOfHydra =
  (typeof ContactDetailsDTOJsonldContextOneOfHydra)[keyof typeof ContactDetailsDTOJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsDTOJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsDTOJsonldContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsDTOJsonldContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsDTOJsonldContext = string | ContactDetailsDTOJsonldContextOneOf

export type ContactDetailsDTOTimetables = TimetablesDTO | null

export interface ContactDetailsDTO {
  id?: string | null
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLatitude?: string | null
  neighborhoodLongitude?: string | null
  region?: string | null
  country?: string | null
  timetables?: ContactDetailsDTOTimetables
  altitude?: string | null
  longitude?: string | null
  latitude?: string | null
  radius?: string | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: string | null
  publicLatitude?: string | null
  publicLongitude?: string | null
  order?: string | null
  isDefault?: string | null
}

export type ContactDetailsJsonldUserReadItemCanton = RefLabelJsonldUserReadItem | null

export type ContactDetailsJsonldUserReadItemContextOneOfHydra =
  (typeof ContactDetailsJsonldUserReadItemContextOneOfHydra)[keyof typeof ContactDetailsJsonldUserReadItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldUserReadItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldUserReadItemContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldUserReadItemContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldUserReadItemContext =
  | string
  | ContactDetailsJsonldUserReadItemContextOneOf

export interface ContactDetailsJsonldUserReadItem {
  readonly "@context"?: ContactDetailsJsonldUserReadItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldUserReadItemCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldUserReadCollectionCanton = RefLabelJsonldUserReadCollection | null

export interface ContactDetailsJsonldUserReadCollection {
  readonly "@context"?: ContactDetailsJsonldUserReadCollectionContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldUserReadCollectionCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldUserReadCollectionContextOneOfHydra =
  (typeof ContactDetailsJsonldUserReadCollectionContextOneOfHydra)[keyof typeof ContactDetailsJsonldUserReadCollectionContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldUserReadCollectionContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldUserReadCollectionContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldUserReadCollectionContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldUserReadCollectionContext =
  | string
  | ContactDetailsJsonldUserReadCollectionContextOneOf

export type ContactDetailsJsonldReadItemSocietyItemCanton = RefLabelJsonldReadItemSocietyItem | null

export interface ContactDetailsJsonldReadItemSocietyItem {
  readonly "@context"?: ContactDetailsJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadItemSocietyItemCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof ContactDetailsJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadItemSocietyItemContext =
  | string
  | ContactDetailsJsonldReadItemSocietyItemContextOneOf

export interface ContactDetailsJsonldReadItemPropertySearch {
  readonly "@context"?: ContactDetailsJsonldReadItemPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadItemPropertySearchContextOneOfHydra =
  (typeof ContactDetailsJsonldReadItemPropertySearchContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadItemPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadItemPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadItemPropertySearchContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadItemPropertySearchContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadItemPropertySearchContext =
  | string
  | ContactDetailsJsonldReadItemPropertySearchContextOneOf

export type ContactDetailsJsonldReadItemPropertyCanton = RefLabelJsonldReadItemProperty | null

export interface ContactDetailsJsonldReadItemProperty {
  readonly "@context"?: ContactDetailsJsonldReadItemPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadItemPropertyCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadItemPropertyContextOneOfHydra =
  (typeof ContactDetailsJsonldReadItemPropertyContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadItemPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadItemPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadItemPropertyContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadItemPropertyContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadItemPropertyContext =
  | string
  | ContactDetailsJsonldReadItemPropertyContextOneOf

export type ContactDetailsJsonldReadItemProjectCanton = RefLabelJsonldReadItemProject | null

export interface ContactDetailsJsonldReadItemProject {
  readonly "@context"?: ContactDetailsJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadItemProjectCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadItemProjectContextOneOfHydra =
  (typeof ContactDetailsJsonldReadItemProjectContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadItemProjectContext =
  | string
  | ContactDetailsJsonldReadItemProjectContextOneOf

export type ContactDetailsJsonldReadItemPeopleCanton = RefLabelJsonldReadItemPeople | null

export type ContactDetailsJsonldReadItemPeopleContextOneOfHydra =
  (typeof ContactDetailsJsonldReadItemPeopleContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadItemPeopleContext =
  | string
  | ContactDetailsJsonldReadItemPeopleContextOneOf

export interface ContactDetailsJsonldReadItemPeople {
  readonly "@context"?: ContactDetailsJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadItemPeopleCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadItemCollaboratorItemCanton =
  RefLabelJsonldReadItemCollaboratorItem | null

export type ContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof ContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadItemCollaboratorItemContext =
  | string
  | ContactDetailsJsonldReadItemCollaboratorItemContextOneOf

export interface ContactDetailsJsonldReadItemCollaboratorItem {
  readonly "@context"?: ContactDetailsJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadItemCollaboratorItemCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadItemClusterItemCanton = RefLabelJsonldReadItemClusterItem | null

export interface ContactDetailsJsonldReadItemClusterItem {
  readonly "@context"?: ContactDetailsJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadItemClusterItemCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadItemClusterItemContextOneOfHydra =
  (typeof ContactDetailsJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadItemClusterItemContext =
  | string
  | ContactDetailsJsonldReadItemClusterItemContextOneOf

export type ContactDetailsJsonldReadItemAgencyItemCanton = RefLabelJsonldReadItemAgencyItem | null

export interface ContactDetailsJsonldReadItemAgencyItem {
  readonly "@context"?: ContactDetailsJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadItemAgencyItemCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof ContactDetailsJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadItemAgencyItemContext =
  | string
  | ContactDetailsJsonldReadItemAgencyItemContextOneOf

export type ContactDetailsJsonldReadCollectionSocietyExcelExportCanton =
  RefLabelJsonldReadCollectionSocietyExcelExport | null

export interface ContactDetailsJsonldReadCollectionSocietyExcelExport {
  readonly "@context"?: ContactDetailsJsonldReadCollectionSocietyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadCollectionSocietyExcelExportCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadCollectionSocietyExcelExportContextOneOfHydra =
  (typeof ContactDetailsJsonldReadCollectionSocietyExcelExportContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadCollectionSocietyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadCollectionSocietyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadCollectionSocietyExcelExportContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadCollectionSocietyExcelExportContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadCollectionSocietyExcelExportContext =
  | string
  | ContactDetailsJsonldReadCollectionSocietyExcelExportContextOneOf

export type ContactDetailsJsonldReadCollectionSocietyCanton =
  RefLabelJsonldReadCollectionSociety | null

export type ContactDetailsJsonldReadCollectionSocietyContextOneOfHydra =
  (typeof ContactDetailsJsonldReadCollectionSocietyContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadCollectionSocietyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadCollectionSocietyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadCollectionSocietyContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadCollectionSocietyContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadCollectionSocietyContext =
  | string
  | ContactDetailsJsonldReadCollectionSocietyContextOneOf

export interface ContactDetailsJsonldReadCollectionSociety {
  readonly "@context"?: ContactDetailsJsonldReadCollectionSocietyContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadCollectionSocietyCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface ContactDetailsJsonldReadCollectionSharedDataReadItemSharedData {
  readonly "@context"?: ContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  mobile?: string | null
  email?: string | null
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra =
  (typeof ContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContext =
  | string
  | ContactDetailsJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf

export type ContactDetailsJsonldReadCollectionSharedDataContextOneOfHydra =
  (typeof ContactDetailsJsonldReadCollectionSharedDataContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadCollectionSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadCollectionSharedDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadCollectionSharedDataContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadCollectionSharedDataContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadCollectionSharedDataContext =
  | string
  | ContactDetailsJsonldReadCollectionSharedDataContextOneOf

export interface ContactDetailsJsonldReadCollectionSharedData {
  readonly "@context"?: ContactDetailsJsonldReadCollectionSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  mobile?: string | null
  email?: string | null
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra =
  (typeof ContactDetailsJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadCollectionPropertySearchExcelExportContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadCollectionPropertySearchExcelExportContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadCollectionPropertySearchExcelExportContext =
  | string
  | ContactDetailsJsonldReadCollectionPropertySearchExcelExportContextOneOf

export interface ContactDetailsJsonldReadCollectionPropertySearchExcelExport {
  readonly "@context"?: ContactDetailsJsonldReadCollectionPropertySearchExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface ContactDetailsJsonldReadCollectionPropertySearch {
  readonly "@context"?: ContactDetailsJsonldReadCollectionPropertySearchContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadCollectionPropertySearchContextOneOfHydra =
  (typeof ContactDetailsJsonldReadCollectionPropertySearchContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadCollectionPropertySearchContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadCollectionPropertySearchContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadCollectionPropertySearchContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadCollectionPropertySearchContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadCollectionPropertySearchContext =
  | string
  | ContactDetailsJsonldReadCollectionPropertySearchContextOneOf

export type ContactDetailsJsonldReadCollectionPropertyExcelExportCanton =
  RefLabelJsonldReadCollectionPropertyExcelExport | null

export interface ContactDetailsJsonldReadCollectionPropertyExcelExport {
  readonly "@context"?: ContactDetailsJsonldReadCollectionPropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadCollectionPropertyExcelExportCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOfHydra =
  (typeof ContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadCollectionPropertyExcelExportContext =
  | string
  | ContactDetailsJsonldReadCollectionPropertyExcelExportContextOneOf

export type ContactDetailsJsonldReadCollectionPropertyCanton =
  RefLabelJsonldReadCollectionProperty | null

export interface ContactDetailsJsonldReadCollectionProperty {
  readonly "@context"?: ContactDetailsJsonldReadCollectionPropertyContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadCollectionPropertyCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadCollectionPropertyContextOneOfHydra =
  (typeof ContactDetailsJsonldReadCollectionPropertyContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadCollectionPropertyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadCollectionPropertyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadCollectionPropertyContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadCollectionPropertyContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadCollectionPropertyContext =
  | string
  | ContactDetailsJsonldReadCollectionPropertyContextOneOf

export type ContactDetailsJsonldReadCollectionProjectExcelExportCanton =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export interface ContactDetailsJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: ContactDetailsJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadCollectionProjectExcelExportCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof ContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadCollectionProjectExcelExportContext =
  | string
  | ContactDetailsJsonldReadCollectionProjectExcelExportContextOneOf

export type ContactDetailsJsonldReadCollectionProjectCanton =
  RefLabelJsonldReadCollectionProject | null

export type ContactDetailsJsonldReadCollectionProjectContextOneOfHydra =
  (typeof ContactDetailsJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadCollectionProjectContext =
  | string
  | ContactDetailsJsonldReadCollectionProjectContextOneOf

export interface ContactDetailsJsonldReadCollectionProject {
  readonly "@context"?: ContactDetailsJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadCollectionProjectCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadCollectionPeopleExcelExportCanton =
  RefLabelJsonldReadCollectionPeopleExcelExport | null

export interface ContactDetailsJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: ContactDetailsJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadCollectionPeopleExcelExportCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof ContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadCollectionPeopleExcelExportContext =
  | string
  | ContactDetailsJsonldReadCollectionPeopleExcelExportContextOneOf

export type ContactDetailsJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof ContactDetailsJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadCollectionPeopleContext =
  | string
  | ContactDetailsJsonldReadCollectionPeopleContextOneOf

export interface ContactDetailsJsonldReadCollectionPeople {
  readonly "@context"?: ContactDetailsJsonldReadCollectionPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  email?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadCollectionCollaboratorExcelExportCanton =
  RefLabelJsonldReadCollectionCollaboratorExcelExport | null

export interface ContactDetailsJsonldReadCollectionCollaboratorExcelExport {
  readonly "@context"?: ContactDetailsJsonldReadCollectionCollaboratorExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadCollectionCollaboratorExcelExportCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra =
  (typeof ContactDetailsJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadCollectionCollaboratorExcelExportContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadCollectionCollaboratorExcelExportContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadCollectionCollaboratorExcelExportContext =
  | string
  | ContactDetailsJsonldReadCollectionCollaboratorExcelExportContextOneOf

export type ContactDetailsJsonldReadCollectionCollaboratorCanton =
  RefLabelJsonldReadCollectionCollaborator | null

export interface ContactDetailsJsonldReadCollectionCollaborator {
  readonly "@context"?: ContactDetailsJsonldReadCollectionCollaboratorContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadCollectionCollaboratorCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadCollectionCollaboratorContextOneOfHydra =
  (typeof ContactDetailsJsonldReadCollectionCollaboratorContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadCollectionCollaboratorContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadCollectionCollaboratorContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadCollectionCollaboratorContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadCollectionCollaboratorContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadCollectionCollaboratorContext =
  | string
  | ContactDetailsJsonldReadCollectionCollaboratorContextOneOf

export type ContactDetailsJsonldReadCollectionClusterExcelExportCanton =
  RefLabelJsonldReadCollectionClusterExcelExport | null

export type ContactDetailsJsonldReadCollectionClusterExcelExportContextOneOfHydra =
  (typeof ContactDetailsJsonldReadCollectionClusterExcelExportContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadCollectionClusterExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadCollectionClusterExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadCollectionClusterExcelExportContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadCollectionClusterExcelExportContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadCollectionClusterExcelExportContext =
  | string
  | ContactDetailsJsonldReadCollectionClusterExcelExportContextOneOf

export interface ContactDetailsJsonldReadCollectionClusterExcelExport {
  readonly "@context"?: ContactDetailsJsonldReadCollectionClusterExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadCollectionClusterExcelExportCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadCollectionClusterCanton =
  RefLabelJsonldReadCollectionCluster | null

export interface ContactDetailsJsonldReadCollectionCluster {
  readonly "@context"?: ContactDetailsJsonldReadCollectionClusterContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadCollectionClusterCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadCollectionClusterContextOneOfHydra =
  (typeof ContactDetailsJsonldReadCollectionClusterContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadCollectionClusterContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadCollectionClusterContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadCollectionClusterContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadCollectionClusterContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadCollectionClusterContext =
  | string
  | ContactDetailsJsonldReadCollectionClusterContextOneOf

export type ContactDetailsJsonldReadCollectionAgencyExcelExportCanton =
  RefLabelJsonldReadCollectionAgencyExcelExport | null

export interface ContactDetailsJsonldReadCollectionAgencyExcelExport {
  readonly "@context"?: ContactDetailsJsonldReadCollectionAgencyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadCollectionAgencyExcelExportCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldReadCollectionAgencyExcelExportContextOneOfHydra =
  (typeof ContactDetailsJsonldReadCollectionAgencyExcelExportContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadCollectionAgencyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadCollectionAgencyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadCollectionAgencyExcelExportContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadCollectionAgencyExcelExportContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadCollectionAgencyExcelExportContext =
  | string
  | ContactDetailsJsonldReadCollectionAgencyExcelExportContextOneOf

export type ContactDetailsJsonldReadCollectionAgencyCanton =
  RefLabelJsonldReadCollectionAgency | null

export type ContactDetailsJsonldReadCollectionAgencyContextOneOfHydra =
  (typeof ContactDetailsJsonldReadCollectionAgencyContextOneOfHydra)[keyof typeof ContactDetailsJsonldReadCollectionAgencyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldReadCollectionAgencyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldReadCollectionAgencyContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldReadCollectionAgencyContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldReadCollectionAgencyContext =
  | string
  | ContactDetailsJsonldReadCollectionAgencyContextOneOf

export interface ContactDetailsJsonldReadCollectionAgency {
  readonly "@context"?: ContactDetailsJsonldReadCollectionAgencyContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: ContactDetailsJsonldReadCollectionAgencyCanton
  publicLatitude?: number | null
  publicLongitude?: number | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ContactDetailsJsonldContextOneOfHydra =
  (typeof ContactDetailsJsonldContextOneOfHydra)[keyof typeof ContactDetailsJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactDetailsJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ContactDetailsJsonldContextOneOf = {
  "@vocab": string
  hydra: ContactDetailsJsonldContextOneOfHydra
  [key: string]: any
}

export type ContactDetailsJsonldContext = string | ContactDetailsJsonldContextOneOf

export interface ContactDetailsJsonld {
  readonly "@context"?: ContactDetailsJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  phone?: string | null
  phone2?: string | null
  phoneWww?: string | null
  mobile?: string | null
  mobile2?: string | null
  fax?: string | null
  email?: string | null
  emailFallback?: string | null
  emailLeads?: string | null
  emailRedir?: string | null
  url?: string | null
  address1?: string | null
  address2?: string | null
  address3?: string | null
  zipcode?: string | null
  city?: string | null
  neighborhood?: string | null
  neighborhoodLongitude?: number | null
  neighborhoodLatitude?: number | null
  region?: string | null
  country?: string | null
  timetables?: unknown
  altitude?: number | null
  longitude?: number | null
  latitude?: number | null
  radius?: number | null
  socialInstagram?: string | null
  socialLinkedin?: string | null
  socialTwitter?: string | null
  socialFacebook?: string | null
  socialYoutube?: string | null
  canton?: string | null
  publicLatitude?: number | null
  publicLongitude?: number | null
  propertyContactDetails?: PropertyContactDetailsJsonld[]
  people?: PeopleContactDetailsJsonld[]
  agencyOwner?: RelAgencyOwnerContactDetailsJsonld[]
  agency?: RelAgencyContactDetailsJsonld[]
  collaborator?: RelCollaboratorContactDetailsJsonld[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type CollaboratorUserDTOJsonldContextOneOfHydra =
  (typeof CollaboratorUserDTOJsonldContextOneOfHydra)[keyof typeof CollaboratorUserDTOJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorUserDTOJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorUserDTOJsonldContextOneOf = {
  "@vocab": string
  hydra: CollaboratorUserDTOJsonldContextOneOfHydra
  [key: string]: any
}

export type CollaboratorUserDTOJsonldContext = string | CollaboratorUserDTOJsonldContextOneOf

export interface CollaboratorUserDTOJsonld {
  readonly "@context"?: CollaboratorUserDTOJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  collaborator?: string
  isUserDefault?: boolean
}

export interface CollaboratorUserDTO {
  collaborator?: string
  isUserDefault?: boolean
}

export interface CollaboratorPeopleDTOJsonld {
  readonly "@context"?: CollaboratorPeopleDTOJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  id?: string
  collaborator?: string
  people?: string
  role?: string
}

export type CollaboratorPeopleDTOJsonldContextOneOfHydra =
  (typeof CollaboratorPeopleDTOJsonldContextOneOfHydra)[keyof typeof CollaboratorPeopleDTOJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorPeopleDTOJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorPeopleDTOJsonldContextOneOf = {
  "@vocab": string
  hydra: CollaboratorPeopleDTOJsonldContextOneOfHydra
  [key: string]: any
}

export type CollaboratorPeopleDTOJsonldContext = string | CollaboratorPeopleDTOJsonldContextOneOf

export interface CollaboratorPeopleDTO {
  id?: string
  collaborator?: string
  people?: string
  role?: string
}

export interface CollaboratorMediaJsonldUserReadItem {
  readonly "@context"?: CollaboratorMediaJsonldUserReadItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type CollaboratorMediaJsonldUserReadItemContextOneOfHydra =
  (typeof CollaboratorMediaJsonldUserReadItemContextOneOfHydra)[keyof typeof CollaboratorMediaJsonldUserReadItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorMediaJsonldUserReadItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorMediaJsonldUserReadItemContextOneOf = {
  "@vocab": string
  hydra: CollaboratorMediaJsonldUserReadItemContextOneOfHydra
  [key: string]: any
}

export type CollaboratorMediaJsonldUserReadItemContext =
  | string
  | CollaboratorMediaJsonldUserReadItemContextOneOf

export type CollaboratorMediaJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof CollaboratorMediaJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof CollaboratorMediaJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorMediaJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorMediaJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: CollaboratorMediaJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type CollaboratorMediaJsonldReadItemSocietyItemContext =
  | string
  | CollaboratorMediaJsonldReadItemSocietyItemContextOneOf

export interface CollaboratorMediaJsonldReadItemSocietyItem {
  readonly "@context"?: CollaboratorMediaJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type CollaboratorMediaJsonldReadItemProjectContextOneOfHydra =
  (typeof CollaboratorMediaJsonldReadItemProjectContextOneOfHydra)[keyof typeof CollaboratorMediaJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorMediaJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorMediaJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: CollaboratorMediaJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type CollaboratorMediaJsonldReadItemProjectContext =
  | string
  | CollaboratorMediaJsonldReadItemProjectContextOneOf

export interface CollaboratorMediaJsonldReadItemProject {
  readonly "@context"?: CollaboratorMediaJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type CollaboratorMediaJsonldReadItemPeopleContextOneOfHydra =
  (typeof CollaboratorMediaJsonldReadItemPeopleContextOneOfHydra)[keyof typeof CollaboratorMediaJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorMediaJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorMediaJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: CollaboratorMediaJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type CollaboratorMediaJsonldReadItemPeopleContext =
  | string
  | CollaboratorMediaJsonldReadItemPeopleContextOneOf

export interface CollaboratorMediaJsonldReadItemPeople {
  readonly "@context"?: CollaboratorMediaJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type CollaboratorMediaJsonldReadItemCollaboratorItemUsage =
  RefLabelJsonldReadItemCollaboratorItem | null

export interface CollaboratorMediaJsonldReadItemCollaboratorItem {
  readonly "@context"?: CollaboratorMediaJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  collaborator?: CollaboratorJsonldReadItemCollaboratorItem
  media?: MediaJsonldReadItemCollaboratorItem
  order?: number | null
  isDefault?: boolean | null
  usage?: CollaboratorMediaJsonldReadItemCollaboratorItemUsage
  readonly id: string
  /** The media usage code */
  usageCode?: string | null
}

export type CollaboratorMediaJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof CollaboratorMediaJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof CollaboratorMediaJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorMediaJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorMediaJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: CollaboratorMediaJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type CollaboratorMediaJsonldReadItemCollaboratorItemContext =
  | string
  | CollaboratorMediaJsonldReadItemCollaboratorItemContextOneOf

export interface CollaboratorMediaJsonldReadItemClusterItem {
  readonly "@context"?: CollaboratorMediaJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type CollaboratorMediaJsonldReadItemClusterItemContextOneOfHydra =
  (typeof CollaboratorMediaJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof CollaboratorMediaJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorMediaJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorMediaJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: CollaboratorMediaJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type CollaboratorMediaJsonldReadItemClusterItemContext =
  | string
  | CollaboratorMediaJsonldReadItemClusterItemContextOneOf

export interface CollaboratorMediaJsonldReadItemAgencyItem {
  readonly "@context"?: CollaboratorMediaJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type CollaboratorMediaJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof CollaboratorMediaJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof CollaboratorMediaJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorMediaJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorMediaJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: CollaboratorMediaJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type CollaboratorMediaJsonldReadItemAgencyItemContext =
  | string
  | CollaboratorMediaJsonldReadItemAgencyItemContextOneOf

export type CollaboratorMediaJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof CollaboratorMediaJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof CollaboratorMediaJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorMediaJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorMediaJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: CollaboratorMediaJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type CollaboratorMediaJsonldReadCollectionProjectExcelExportContext =
  | string
  | CollaboratorMediaJsonldReadCollectionProjectExcelExportContextOneOf

export interface CollaboratorMediaJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: CollaboratorMediaJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type CollaboratorMediaJsonldReadCollectionProjectContextOneOfHydra =
  (typeof CollaboratorMediaJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof CollaboratorMediaJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorMediaJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorMediaJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: CollaboratorMediaJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type CollaboratorMediaJsonldReadCollectionProjectContext =
  | string
  | CollaboratorMediaJsonldReadCollectionProjectContextOneOf

export interface CollaboratorMediaJsonldReadCollectionProject {
  readonly "@context"?: CollaboratorMediaJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export interface CollaboratorMediaJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: CollaboratorMediaJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type CollaboratorMediaJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof CollaboratorMediaJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof CollaboratorMediaJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorMediaJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorMediaJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: CollaboratorMediaJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type CollaboratorMediaJsonldReadCollectionPeopleExcelExportContext =
  | string
  | CollaboratorMediaJsonldReadCollectionPeopleExcelExportContextOneOf

export type CollaboratorMediaJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof CollaboratorMediaJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof CollaboratorMediaJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorMediaJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorMediaJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: CollaboratorMediaJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type CollaboratorMediaJsonldReadCollectionPeopleContext =
  | string
  | CollaboratorMediaJsonldReadCollectionPeopleContextOneOf

export interface CollaboratorMediaJsonldReadCollectionPeople {
  readonly "@context"?: CollaboratorMediaJsonldReadCollectionPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type CollaboratorMediaJsonldReadCollectionCollaboratorUsage =
  RefLabelJsonldReadCollectionCollaborator | null

export type CollaboratorMediaJsonldReadCollectionCollaboratorContextOneOfHydra =
  (typeof CollaboratorMediaJsonldReadCollectionCollaboratorContextOneOfHydra)[keyof typeof CollaboratorMediaJsonldReadCollectionCollaboratorContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorMediaJsonldReadCollectionCollaboratorContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorMediaJsonldReadCollectionCollaboratorContextOneOf = {
  "@vocab": string
  hydra: CollaboratorMediaJsonldReadCollectionCollaboratorContextOneOfHydra
  [key: string]: any
}

export type CollaboratorMediaJsonldReadCollectionCollaboratorContext =
  | string
  | CollaboratorMediaJsonldReadCollectionCollaboratorContextOneOf

export interface CollaboratorMediaJsonldReadCollectionCollaborator {
  readonly "@context"?: CollaboratorMediaJsonldReadCollectionCollaboratorContext
  readonly "@id"?: string
  readonly "@type"?: string
  collaborator?: CollaboratorJsonldReadCollectionCollaborator
  media?: MediaJsonldReadCollectionCollaborator
  order?: number | null
  isDefault?: boolean | null
  usage?: CollaboratorMediaJsonldReadCollectionCollaboratorUsage
  readonly id: string
  /** The media usage code */
  usageCode?: string | null
}

export type CollaboratorMediaJsonldContext = string | CollaboratorMediaJsonldContextOneOf

export interface CollaboratorMediaJsonld {
  readonly "@context"?: CollaboratorMediaJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  collaborator?: string
  media?: MediaJsonld
  order?: number | null
  isDefault?: boolean | null
  usage?: string | null
  readonly id: string
  /** The media usage code */
  usageCode?: string | null
}

export type CollaboratorMediaJsonldContextOneOfHydra =
  (typeof CollaboratorMediaJsonldContextOneOfHydra)[keyof typeof CollaboratorMediaJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorMediaJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorMediaJsonldContextOneOf = {
  "@vocab": string
  hydra: CollaboratorMediaJsonldContextOneOfHydra
  [key: string]: any
}

export interface CollaboratorClusterDTOJsonld {
  readonly "@context"?: CollaboratorClusterDTOJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  id?: string
  collaborator?: string
  cluster?: string
  role?: string
  rank?: string
  function?: string | null
  isDefault?: boolean
  isPublished?: boolean
}

export type CollaboratorClusterDTOJsonldContextOneOfHydra =
  (typeof CollaboratorClusterDTOJsonldContextOneOfHydra)[keyof typeof CollaboratorClusterDTOJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorClusterDTOJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorClusterDTOJsonldContextOneOf = {
  "@vocab": string
  hydra: CollaboratorClusterDTOJsonldContextOneOfHydra
  [key: string]: any
}

export type CollaboratorClusterDTOJsonldContext = string | CollaboratorClusterDTOJsonldContextOneOf

export interface CollaboratorClusterDTO {
  id?: string
  collaborator?: string
  cluster?: string
  role?: string
  rank?: string
  function?: string | null
  isDefault?: boolean
  isPublished?: boolean
}

export interface CollaboratorAgencyDTOJsonld {
  readonly "@context"?: CollaboratorAgencyDTOJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  id?: string
  role?: string
  agency?: string
  collaborator?: string
  isDefault?: boolean
}

export type CollaboratorAgencyDTOJsonldContextOneOfHydra =
  (typeof CollaboratorAgencyDTOJsonldContextOneOfHydra)[keyof typeof CollaboratorAgencyDTOJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorAgencyDTOJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorAgencyDTOJsonldContextOneOf = {
  "@vocab": string
  hydra: CollaboratorAgencyDTOJsonldContextOneOfHydra
  [key: string]: any
}

export type CollaboratorAgencyDTOJsonldContext = string | CollaboratorAgencyDTOJsonldContextOneOf

export interface CollaboratorAgencyDTO {
  id?: string
  role?: string
  agency?: string
  collaborator?: string
  isDefault?: boolean
}

export type CollaboratorJsonldUserReadItemStatus = RefLabelJsonldUserReadItem | null

export type CollaboratorJsonldUserReadItemTitle = RefLabelJsonldUserReadItem | null

export interface CollaboratorJsonldUserReadItem {
  readonly "@context"?: CollaboratorJsonldUserReadItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  title?: CollaboratorJsonldUserReadItemTitle
  lastname?: string
  firstname?: string
  fullname?: string | null
  introduction?: string | null
  birthdate?: string | null
  nationality?: string | null
  status?: CollaboratorJsonldUserReadItemStatus
  contactDetails?: RelCollaboratorContactDetailsJsonldUserReadItem[]
  media?: CollaboratorMediaJsonldUserReadItem[]
  cluster?: RelCollaboratorClusterJsonldUserReadItem[]
  languages?: RefLabelJsonldUserReadItem[]
  isIndependent?: boolean | null
  legal?: string | null
  agency?: RelCollaboratorAgencyJsonldUserReadItem[]
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  isUserDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type CollaboratorJsonldUserReadItemContextOneOfHydra =
  (typeof CollaboratorJsonldUserReadItemContextOneOfHydra)[keyof typeof CollaboratorJsonldUserReadItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorJsonldUserReadItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorJsonldUserReadItemContextOneOf = {
  "@vocab": string
  hydra: CollaboratorJsonldUserReadItemContextOneOfHydra
  [key: string]: any
}

export type CollaboratorJsonldUserReadItemContext =
  | string
  | CollaboratorJsonldUserReadItemContextOneOf

export type CollaboratorJsonldUserReadCollectionTitle = RefLabelJsonldUserReadCollection | null

export interface CollaboratorJsonldUserReadCollection {
  readonly "@context"?: CollaboratorJsonldUserReadCollectionContext
  readonly "@id"?: string
  readonly "@type"?: string
  title?: CollaboratorJsonldUserReadCollectionTitle
  lastname?: string
  firstname?: string
  fullname?: string | null
  contactDetails?: RelCollaboratorContactDetailsJsonldUserReadCollection[]
  cluster?: RelCollaboratorClusterJsonldUserReadCollection[]
  languages?: RefLabelJsonldUserReadCollection[]
  isIndependent?: boolean | null
  legal?: string | null
  agency?: RelCollaboratorAgencyJsonldUserReadCollection[]
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  isUserDefault?: boolean | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type CollaboratorJsonldUserReadCollectionContextOneOfHydra =
  (typeof CollaboratorJsonldUserReadCollectionContextOneOfHydra)[keyof typeof CollaboratorJsonldUserReadCollectionContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorJsonldUserReadCollectionContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorJsonldUserReadCollectionContextOneOf = {
  "@vocab": string
  hydra: CollaboratorJsonldUserReadCollectionContextOneOfHydra
  [key: string]: any
}

export type CollaboratorJsonldUserReadCollectionContext =
  | string
  | CollaboratorJsonldUserReadCollectionContextOneOf

export type CollaboratorJsonldReadItemSocietyItemTitle = RefLabelJsonldReadItemSocietyItem | null

export interface CollaboratorJsonldReadItemSocietyItem {
  readonly "@context"?: CollaboratorJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  title?: CollaboratorJsonldReadItemSocietyItemTitle
  lastname?: string
  firstname?: string
  fullname?: string | null
  contactDetails?: RelCollaboratorContactDetailsJsonldReadItemSocietyItem[]
  media?: CollaboratorMediaJsonldReadItemSocietyItem[]
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  dateOfEntry?: string | null
  dateOfLeaving?: string | null
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type CollaboratorJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof CollaboratorJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof CollaboratorJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: CollaboratorJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type CollaboratorJsonldReadItemSocietyItemContext =
  | string
  | CollaboratorJsonldReadItemSocietyItemContextOneOf

export type CollaboratorJsonldReadItemProjectStatus = RefLabelJsonldReadItemProject | null

export type CollaboratorJsonldReadItemProjectTitle = RefLabelJsonldReadItemProject | null

export interface CollaboratorJsonldReadItemProject {
  readonly "@context"?: CollaboratorJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  title?: CollaboratorJsonldReadItemProjectTitle
  lastname?: string
  firstname?: string
  fullname?: string | null
  introduction?: string | null
  birthdate?: string | null
  nationality?: string | null
  status?: CollaboratorJsonldReadItemProjectStatus
  contactDetails?: RelCollaboratorContactDetailsJsonldReadItemProject[]
  media?: CollaboratorMediaJsonldReadItemProject[]
  languages?: RefLabelJsonldReadItemProject[]
  isIndependent?: boolean | null
  legal?: string | null
  agency?: RelCollaboratorAgencyJsonldReadItemProject[]
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  dateOfEntry?: string | null
  dateOfLeaving?: string | null
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type CollaboratorJsonldReadItemProjectContextOneOfHydra =
  (typeof CollaboratorJsonldReadItemProjectContextOneOfHydra)[keyof typeof CollaboratorJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: CollaboratorJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type CollaboratorJsonldReadItemProjectContext =
  | string
  | CollaboratorJsonldReadItemProjectContextOneOf

export type CollaboratorJsonldReadItemPeopleStatus = RefLabelJsonldReadItemPeople | null

export type CollaboratorJsonldReadItemPeopleTitle = RefLabelJsonldReadItemPeople | null

export interface CollaboratorJsonldReadItemPeople {
  readonly "@context"?: CollaboratorJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  title?: CollaboratorJsonldReadItemPeopleTitle
  lastname?: string
  firstname?: string
  fullname?: string | null
  introduction?: string | null
  birthdate?: string | null
  nationality?: string | null
  status?: CollaboratorJsonldReadItemPeopleStatus
  contactDetails?: RelCollaboratorContactDetailsJsonldReadItemPeople[]
  media?: CollaboratorMediaJsonldReadItemPeople[]
  languages?: RefLabelJsonldReadItemPeople[]
  isIndependent?: boolean | null
  legal?: string | null
  agency?: RelCollaboratorAgencyJsonldReadItemPeople[]
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  dateOfEntry?: string | null
  dateOfLeaving?: string | null
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type CollaboratorJsonldReadItemPeopleContextOneOfHydra =
  (typeof CollaboratorJsonldReadItemPeopleContextOneOfHydra)[keyof typeof CollaboratorJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: CollaboratorJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type CollaboratorJsonldReadItemPeopleContext =
  | string
  | CollaboratorJsonldReadItemPeopleContextOneOf

export type CollaboratorJsonldReadItemCollaboratorItemNbProjectsByType = { [key: string]: number }

export type CollaboratorJsonldReadItemCollaboratorItemUsers =
  UserJsonldReadItemCollaboratorItem | null

export type CollaboratorJsonldReadItemCollaboratorItemStatus =
  RefLabelJsonldReadItemCollaboratorItem | null

export type CollaboratorJsonldReadItemCollaboratorItemTitle =
  RefLabelJsonldReadItemCollaboratorItem | null

export interface CollaboratorJsonldReadItemCollaboratorItem {
  readonly "@context"?: CollaboratorJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  title?: CollaboratorJsonldReadItemCollaboratorItemTitle
  lastname?: string
  firstname?: string
  fullname?: string | null
  introduction?: string | null
  birthdate?: string | null
  nationality?: string | null
  status?: CollaboratorJsonldReadItemCollaboratorItemStatus
  contactDetails?: RelCollaboratorContactDetailsJsonldReadItemCollaboratorItem[]
  media?: CollaboratorMediaJsonldReadItemCollaboratorItem[]
  cluster?: RelCollaboratorClusterJsonldReadItemCollaboratorItem[]
  project?: ProjectCollaboratorJsonldReadItemCollaboratorItem[]
  people?: RelPeopleCollaboratorJsonldReadItemCollaboratorItem[]
  languages?: RefLabelJsonldReadItemCollaboratorItem[]
  isIndependent?: boolean | null
  legal?: string | null
  agency?: RelCollaboratorAgencyJsonldReadItemCollaboratorItem[]
  legalRepresentative?: RelAgencyOwnerLegalRepresentativeJsonldReadItemCollaboratorItem[]
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  nbAgencies?: number
  nbPeople?: number
  nbLegalRepresentatives?: number
  nbClusters?: number
  dateOfEntry?: string | null
  dateOfLeaving?: string | null
  users?: CollaboratorJsonldReadItemCollaboratorItemUsers
  internalSource?: string | null
  nbProjectsByType?: CollaboratorJsonldReadItemCollaboratorItemNbProjectsByType
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type CollaboratorJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof CollaboratorJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof CollaboratorJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: CollaboratorJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type CollaboratorJsonldReadItemCollaboratorItemContext =
  | string
  | CollaboratorJsonldReadItemCollaboratorItemContextOneOf

export type CollaboratorJsonldReadItemClusterItemStatus = RefLabelJsonldReadItemClusterItem | null

export type CollaboratorJsonldReadItemClusterItemTitle = RefLabelJsonldReadItemClusterItem | null

export type CollaboratorJsonldReadItemClusterItemContextOneOfHydra =
  (typeof CollaboratorJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof CollaboratorJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: CollaboratorJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type CollaboratorJsonldReadItemClusterItemContext =
  | string
  | CollaboratorJsonldReadItemClusterItemContextOneOf

export interface CollaboratorJsonldReadItemClusterItem {
  readonly "@context"?: CollaboratorJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  title?: CollaboratorJsonldReadItemClusterItemTitle
  lastname?: string
  firstname?: string
  fullname?: string | null
  status?: CollaboratorJsonldReadItemClusterItemStatus
  contactDetails?: RelCollaboratorContactDetailsJsonldReadItemClusterItem[]
  media?: CollaboratorMediaJsonldReadItemClusterItem[]
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  dateOfEntry?: string | null
  dateOfLeaving?: string | null
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type CollaboratorJsonldReadItemAgencyItemStatus = RefLabelJsonldReadItemAgencyItem | null

export type CollaboratorJsonldReadItemAgencyItemTitle = RefLabelJsonldReadItemAgencyItem | null

export interface CollaboratorJsonldReadItemAgencyItem {
  readonly "@context"?: CollaboratorJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  title?: CollaboratorJsonldReadItemAgencyItemTitle
  lastname?: string
  firstname?: string
  fullname?: string | null
  status?: CollaboratorJsonldReadItemAgencyItemStatus
  contactDetails?: RelCollaboratorContactDetailsJsonldReadItemAgencyItem[]
  media?: CollaboratorMediaJsonldReadItemAgencyItem[]
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  dateOfEntry?: string | null
  dateOfLeaving?: string | null
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type CollaboratorJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof CollaboratorJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof CollaboratorJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: CollaboratorJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type CollaboratorJsonldReadItemAgencyItemContext =
  | string
  | CollaboratorJsonldReadItemAgencyItemContextOneOf

export interface CollaboratorJsonldReadCollectionSharedDataReadItemSharedData {
  readonly "@context"?: CollaboratorJsonldReadCollectionSharedDataReadItemSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  lastname?: string
  firstname?: string
  fullname?: string | null
  contactDetails?: RelCollaboratorContactDetailsJsonldReadCollectionSharedDataReadItemSharedData[]
  createdAt?: string
  readonly updatedAt?: string
}

export type CollaboratorJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra =
  (typeof CollaboratorJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra)[keyof typeof CollaboratorJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf = {
  "@vocab": string
  hydra: CollaboratorJsonldReadCollectionSharedDataReadItemSharedDataContextOneOfHydra
  [key: string]: any
}

export type CollaboratorJsonldReadCollectionSharedDataReadItemSharedDataContext =
  | string
  | CollaboratorJsonldReadCollectionSharedDataReadItemSharedDataContextOneOf

export interface CollaboratorJsonldReadCollectionSharedData {
  readonly "@context"?: CollaboratorJsonldReadCollectionSharedDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  lastname?: string
  firstname?: string
  fullname?: string | null
  contactDetails?: RelCollaboratorContactDetailsJsonldReadCollectionSharedData[]
  createdAt?: string
  readonly updatedAt?: string
}

export type CollaboratorJsonldReadCollectionSharedDataContextOneOfHydra =
  (typeof CollaboratorJsonldReadCollectionSharedDataContextOneOfHydra)[keyof typeof CollaboratorJsonldReadCollectionSharedDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorJsonldReadCollectionSharedDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorJsonldReadCollectionSharedDataContextOneOf = {
  "@vocab": string
  hydra: CollaboratorJsonldReadCollectionSharedDataContextOneOfHydra
  [key: string]: any
}

export type CollaboratorJsonldReadCollectionSharedDataContext =
  | string
  | CollaboratorJsonldReadCollectionSharedDataContextOneOf

export type CollaboratorJsonldReadCollectionProjectExcelExportStatus =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export type CollaboratorJsonldReadCollectionProjectExcelExportTitle =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export interface CollaboratorJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: CollaboratorJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  title?: CollaboratorJsonldReadCollectionProjectExcelExportTitle
  lastname?: string
  firstname?: string
  fullname?: string | null
  introduction?: string | null
  birthdate?: string | null
  nationality?: string | null
  status?: CollaboratorJsonldReadCollectionProjectExcelExportStatus
  contactDetails?: RelCollaboratorContactDetailsJsonldReadCollectionProjectExcelExport[]
  media?: CollaboratorMediaJsonldReadCollectionProjectExcelExport[]
  languages?: RefLabelJsonldReadCollectionProjectExcelExport[]
  isIndependent?: boolean | null
  legal?: string | null
  agency?: RelCollaboratorAgencyJsonldReadCollectionProjectExcelExport[]
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  dateOfEntry?: string | null
  dateOfLeaving?: string | null
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type CollaboratorJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof CollaboratorJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof CollaboratorJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: CollaboratorJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type CollaboratorJsonldReadCollectionProjectExcelExportContext =
  | string
  | CollaboratorJsonldReadCollectionProjectExcelExportContextOneOf

export type CollaboratorJsonldReadCollectionProjectStatus =
  RefLabelJsonldReadCollectionProject | null

export type CollaboratorJsonldReadCollectionProjectTitle =
  RefLabelJsonldReadCollectionProject | null

export type CollaboratorJsonldReadCollectionProjectContextOneOfHydra =
  (typeof CollaboratorJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof CollaboratorJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: CollaboratorJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type CollaboratorJsonldReadCollectionProjectContext =
  | string
  | CollaboratorJsonldReadCollectionProjectContextOneOf

export interface CollaboratorJsonldReadCollectionProject {
  readonly "@context"?: CollaboratorJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  title?: CollaboratorJsonldReadCollectionProjectTitle
  lastname?: string
  firstname?: string
  fullname?: string | null
  status?: CollaboratorJsonldReadCollectionProjectStatus
  contactDetails?: RelCollaboratorContactDetailsJsonldReadCollectionProject[]
  media?: CollaboratorMediaJsonldReadCollectionProject[]
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  dateOfEntry?: string | null
  dateOfLeaving?: string | null
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type CollaboratorJsonldReadCollectionPeopleExcelExportStatus =
  RefLabelJsonldReadCollectionPeopleExcelExport | null

export type CollaboratorJsonldReadCollectionPeopleExcelExportTitle =
  RefLabelJsonldReadCollectionPeopleExcelExport | null

export type CollaboratorJsonldReadCollectionPeopleExcelExportContextOneOfHydra =
  (typeof CollaboratorJsonldReadCollectionPeopleExcelExportContextOneOfHydra)[keyof typeof CollaboratorJsonldReadCollectionPeopleExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorJsonldReadCollectionPeopleExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorJsonldReadCollectionPeopleExcelExportContextOneOf = {
  "@vocab": string
  hydra: CollaboratorJsonldReadCollectionPeopleExcelExportContextOneOfHydra
  [key: string]: any
}

export type CollaboratorJsonldReadCollectionPeopleExcelExportContext =
  | string
  | CollaboratorJsonldReadCollectionPeopleExcelExportContextOneOf

export interface CollaboratorJsonldReadCollectionPeopleExcelExport {
  readonly "@context"?: CollaboratorJsonldReadCollectionPeopleExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  title?: CollaboratorJsonldReadCollectionPeopleExcelExportTitle
  lastname?: string
  firstname?: string
  fullname?: string | null
  introduction?: string | null
  birthdate?: string | null
  nationality?: string | null
  status?: CollaboratorJsonldReadCollectionPeopleExcelExportStatus
  contactDetails?: RelCollaboratorContactDetailsJsonldReadCollectionPeopleExcelExport[]
  media?: CollaboratorMediaJsonldReadCollectionPeopleExcelExport[]
  languages?: RefLabelJsonldReadCollectionPeopleExcelExport[]
  isIndependent?: boolean | null
  legal?: string | null
  agency?: RelCollaboratorAgencyJsonldReadCollectionPeopleExcelExport[]
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  dateOfEntry?: string | null
  dateOfLeaving?: string | null
  internalSource?: string | null
  readonly id: string
  readonly updatedAt?: string
}

export type CollaboratorJsonldReadCollectionPeopleStatus = RefLabelJsonldReadCollectionPeople | null

export type CollaboratorJsonldReadCollectionPeopleTitle = RefLabelJsonldReadCollectionPeople | null

export interface CollaboratorJsonldReadCollectionPeople {
  readonly "@context"?: CollaboratorJsonldReadCollectionPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  title?: CollaboratorJsonldReadCollectionPeopleTitle
  lastname?: string
  firstname?: string
  fullname?: string | null
  introduction?: string | null
  birthdate?: string | null
  nationality?: string | null
  status?: CollaboratorJsonldReadCollectionPeopleStatus
  contactDetails?: RelCollaboratorContactDetailsJsonldReadCollectionPeople[]
  media?: CollaboratorMediaJsonldReadCollectionPeople[]
  languages?: RefLabelJsonldReadCollectionPeople[]
  isIndependent?: boolean | null
  legal?: string | null
  agency?: RelCollaboratorAgencyJsonldReadCollectionPeople[]
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  dateOfEntry?: string | null
  dateOfLeaving?: string | null
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type CollaboratorJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof CollaboratorJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof CollaboratorJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: CollaboratorJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type CollaboratorJsonldReadCollectionPeopleContext =
  | string
  | CollaboratorJsonldReadCollectionPeopleContextOneOf

export type CollaboratorJsonldReadCollectionCollaboratorExcelExportStatus =
  RefLabelJsonldReadCollectionCollaboratorExcelExport | null

export type CollaboratorJsonldReadCollectionCollaboratorExcelExportTitle =
  RefLabelJsonldReadCollectionCollaboratorExcelExport | null

export interface CollaboratorJsonldReadCollectionCollaboratorExcelExport {
  readonly "@id"?: string
  readonly "@type"?: string
  title?: CollaboratorJsonldReadCollectionCollaboratorExcelExportTitle
  lastname?: string
  firstname?: string
  fullname?: string | null
  introduction?: string | null
  birthdate?: string | null
  nationality?: string | null
  status?: CollaboratorJsonldReadCollectionCollaboratorExcelExportStatus
  contactDetails?: RelCollaboratorContactDetailsJsonldReadCollectionCollaboratorExcelExport[]
  languages?: RefLabelJsonldReadCollectionCollaboratorExcelExport[]
  isIndependent?: boolean | null
  legal?: string | null
  agency?: RelCollaboratorAgencyJsonldReadCollectionCollaboratorExcelExport[]
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  dateOfEntry?: string | null
  dateOfLeaving?: string | null
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type CollaboratorJsonldReadCollectionCollaboratorNbProjectsByType = { [key: string]: number }

export type CollaboratorJsonldReadCollectionCollaboratorUsers =
  UserJsonldReadCollectionCollaborator | null

export type CollaboratorJsonldReadCollectionCollaboratorStatus =
  RefLabelJsonldReadCollectionCollaborator | null

export type CollaboratorJsonldReadCollectionCollaboratorTitle =
  RefLabelJsonldReadCollectionCollaborator | null

export interface CollaboratorJsonldReadCollectionCollaborator {
  readonly "@id"?: string
  readonly "@type"?: string
  readonly "@context"?: CollaboratorJsonldReadCollectionCollaboratorContext
  title?: CollaboratorJsonldReadCollectionCollaboratorTitle
  lastname?: string
  firstname?: string
  fullname?: string | null
  introduction?: string | null
  birthdate?: string | null
  nationality?: string | null
  status?: CollaboratorJsonldReadCollectionCollaboratorStatus
  contactDetails?: RelCollaboratorContactDetailsJsonldReadCollectionCollaborator[]
  media?: CollaboratorMediaJsonldReadCollectionCollaborator[]
  cluster?: RelCollaboratorClusterJsonldReadCollectionCollaborator[]
  languages?: RefLabelJsonldReadCollectionCollaborator[]
  isIndependent?: boolean | null
  legal?: string | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  nbAgencies?: number
  nbPeople?: number
  nbLegalRepresentatives?: number
  nbClusters?: number
  dateOfEntry?: string | null
  dateOfLeaving?: string | null
  users?: CollaboratorJsonldReadCollectionCollaboratorUsers
  internalSource?: string | null
  nbProjectsByType?: CollaboratorJsonldReadCollectionCollaboratorNbProjectsByType
  sharedData?: SharedDataJsonldReadCollectionCollaborator[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type CollaboratorJsonldReadCollectionCollaboratorContextOneOfHydra =
  (typeof CollaboratorJsonldReadCollectionCollaboratorContextOneOfHydra)[keyof typeof CollaboratorJsonldReadCollectionCollaboratorContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorJsonldReadCollectionCollaboratorContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorJsonldReadCollectionCollaboratorContextOneOf = {
  "@vocab": string
  hydra: CollaboratorJsonldReadCollectionCollaboratorContextOneOfHydra
  [key: string]: any
}

export type CollaboratorJsonldReadCollectionCollaboratorContext =
  | string
  | CollaboratorJsonldReadCollectionCollaboratorContextOneOf

export type CollaboratorJsonldNbProjectsByType = { [key: string]: number }

export interface CollaboratorJsonld {
  readonly "@context"?: CollaboratorJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  title?: string | null
  lastname?: string
  firstname?: string
  fullname?: string | null
  introduction?: string | null
  birthdate?: string | null
  nationality?: string | null
  status?: string | null
  contactDetails?: RelCollaboratorContactDetailsJsonld[]
  media?: CollaboratorMediaJsonld[]
  cluster?: RelCollaboratorClusterJsonld[]
  project?: ProjectCollaboratorJsonld[]
  people?: RelPeopleCollaboratorJsonld[]
  languages?: string[]
  isIndependent?: boolean | null
  legal?: string | null
  agency?: RelCollaboratorAgencyJsonld[]
  legalRepresentative?: RelAgencyOwnerLegalRepresentativeJsonld[]
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  nbAgencies?: number
  nbPeople?: number
  nbLegalRepresentatives?: number
  nbClusters?: number
  dateOfEntry?: string | null
  dateOfLeaving?: string | null
  users?: string | null
  isUserDefault?: boolean | null
  internalSource?: string | null
  nbProjectsByType?: CollaboratorJsonldNbProjectsByType
  sharedData?: string[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
  readonly defaultCluster?: string | null
}

export type CollaboratorJsonldContextOneOfHydra =
  (typeof CollaboratorJsonldContextOneOfHydra)[keyof typeof CollaboratorJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollaboratorJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CollaboratorJsonldContextOneOf = {
  "@vocab": string
  hydra: CollaboratorJsonldContextOneOfHydra
  [key: string]: any
}

export type CollaboratorJsonldContext = string | CollaboratorJsonldContextOneOf

export interface CollaboratorPostCollaboratorInputJsonld {
  title?: string | null
  lastname?: string
  firstname?: string
  fullname?: string | null
  introduction?: string | null
  birthdate?: string | null
  nationality?: string | null
  status?: string
  legal?: string | null
  isIndependent?: string | null
  languages?: string[]
  contactDetails?: ContactDetailsDTOJsonld[] | null
  agency?: CollaboratorAgencyDTOJsonld[] | null
  legalRepresentative?: LegalRepresentativeDTOJsonld[] | null
  cluster?: CollaboratorClusterDTOJsonld[] | null
  people?: CollaboratorPeopleDTOJsonld[] | null
  accessLevel?: string | null
  accessLevelForced?: string | null
  dateOfEntry?: string | null
  dateOfLeaving?: string | null
}

export interface CollaboratorPatchEntityMediaInput {
  datas?: EntityMediaDTO[]
}

export interface CollaboratorPatchCollaboratorInput {
  title?: string | null
  lastname?: string | null
  firstname?: string | null
  fullname?: string | null
  introduction?: string | null
  birthdate?: string | null
  nationality?: string | null
  status?: string | null
  legal?: string | null
  isIndependent?: string | null
  languages?: string[]
  contactDetails?: ContactDetailsDTO[] | null
  agency?: CollaboratorAgencyDTO[] | null
  legalRepresentative?: LegalRepresentativeDTO[] | null
  cluster?: CollaboratorClusterDTO[] | null
  people?: CollaboratorPeopleDTO[] | null
  accessLevel?: string | null
  accessLevelForced?: string | null
  dateOfEntry?: string | null
  dateOfLeaving?: string | null
}

export type ClusterSocietyDTOJsonldContextOneOf = {
  "@vocab": string
  hydra: ClusterSocietyDTOJsonldContextOneOfHydra
  [key: string]: any
}

export type ClusterSocietyDTOJsonldContext = string | ClusterSocietyDTOJsonldContextOneOf

export interface ClusterSocietyDTOJsonld {
  readonly "@context"?: ClusterSocietyDTOJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  id?: string
  cluster?: string
  agencyOwner?: string
}

export type ClusterSocietyDTOJsonldContextOneOfHydra =
  (typeof ClusterSocietyDTOJsonldContextOneOfHydra)[keyof typeof ClusterSocietyDTOJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterSocietyDTOJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export interface ClusterSocietyDTO {
  id?: string
  cluster?: string
  agencyOwner?: string
}

export interface ClusterMediaJsonldReadItemSocietyItem {
  readonly "@context"?: ClusterMediaJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type ClusterMediaJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof ClusterMediaJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof ClusterMediaJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterMediaJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterMediaJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: ClusterMediaJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type ClusterMediaJsonldReadItemSocietyItemContext =
  | string
  | ClusterMediaJsonldReadItemSocietyItemContextOneOf

export interface ClusterMediaJsonldReadItemCollaboratorItem {
  readonly "@context"?: ClusterMediaJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type ClusterMediaJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof ClusterMediaJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof ClusterMediaJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterMediaJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterMediaJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: ClusterMediaJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type ClusterMediaJsonldReadItemCollaboratorItemContext =
  | string
  | ClusterMediaJsonldReadItemCollaboratorItemContextOneOf

export type ClusterMediaJsonldReadItemClusterItemUsage = RefLabelJsonldReadItemClusterItem | null

export interface ClusterMediaJsonldReadItemClusterItem {
  readonly "@context"?: ClusterMediaJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  cluster?: ClusterJsonldReadItemClusterItem
  media?: MediaJsonldReadItemClusterItem
  order?: number | null
  isDefault?: boolean | null
  usage?: ClusterMediaJsonldReadItemClusterItemUsage
  readonly id: string
  /** The media usage code */
  usageCode?: string | null
}

export type ClusterMediaJsonldReadItemClusterItemContextOneOfHydra =
  (typeof ClusterMediaJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof ClusterMediaJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterMediaJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterMediaJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: ClusterMediaJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type ClusterMediaJsonldReadItemClusterItemContext =
  | string
  | ClusterMediaJsonldReadItemClusterItemContextOneOf

export type ClusterMediaJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof ClusterMediaJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof ClusterMediaJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterMediaJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterMediaJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: ClusterMediaJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type ClusterMediaJsonldReadItemAgencyItemContext =
  | string
  | ClusterMediaJsonldReadItemAgencyItemContextOneOf

export interface ClusterMediaJsonldReadItemAgencyItem {
  readonly "@context"?: ClusterMediaJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type ClusterMediaJsonldReadCollectionClusterExcelExportUsage =
  RefLabelJsonldReadCollectionClusterExcelExport | null

export interface ClusterMediaJsonldReadCollectionClusterExcelExport {
  readonly "@context"?: ClusterMediaJsonldReadCollectionClusterExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  cluster?: ClusterJsonldReadCollectionClusterExcelExport
  media?: MediaJsonldReadCollectionClusterExcelExport
  order?: number | null
  isDefault?: boolean | null
  usage?: ClusterMediaJsonldReadCollectionClusterExcelExportUsage
  readonly id: string
  /** The media usage code */
  usageCode?: string | null
}

export type ClusterMediaJsonldReadCollectionClusterExcelExportContextOneOfHydra =
  (typeof ClusterMediaJsonldReadCollectionClusterExcelExportContextOneOfHydra)[keyof typeof ClusterMediaJsonldReadCollectionClusterExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterMediaJsonldReadCollectionClusterExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterMediaJsonldReadCollectionClusterExcelExportContextOneOf = {
  "@vocab": string
  hydra: ClusterMediaJsonldReadCollectionClusterExcelExportContextOneOfHydra
  [key: string]: any
}

export type ClusterMediaJsonldReadCollectionClusterExcelExportContext =
  | string
  | ClusterMediaJsonldReadCollectionClusterExcelExportContextOneOf

export type ClusterMediaJsonldReadCollectionClusterUsage =
  RefLabelJsonldReadCollectionCluster | null

export interface ClusterMediaJsonldReadCollectionCluster {
  readonly "@context"?: ClusterMediaJsonldReadCollectionClusterContext
  readonly "@id"?: string
  readonly "@type"?: string
  cluster?: ClusterJsonldReadCollectionCluster
  media?: MediaJsonldReadCollectionCluster
  order?: number | null
  isDefault?: boolean | null
  usage?: ClusterMediaJsonldReadCollectionClusterUsage
  readonly id: string
  /** The media usage code */
  usageCode?: string | null
}

export type ClusterMediaJsonldReadCollectionClusterContextOneOfHydra =
  (typeof ClusterMediaJsonldReadCollectionClusterContextOneOfHydra)[keyof typeof ClusterMediaJsonldReadCollectionClusterContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterMediaJsonldReadCollectionClusterContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterMediaJsonldReadCollectionClusterContextOneOf = {
  "@vocab": string
  hydra: ClusterMediaJsonldReadCollectionClusterContextOneOfHydra
  [key: string]: any
}

export type ClusterMediaJsonldReadCollectionClusterContext =
  | string
  | ClusterMediaJsonldReadCollectionClusterContextOneOf

export interface ClusterMediaJsonld {
  readonly "@context"?: ClusterMediaJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  cluster?: string
  media?: MediaJsonld
  order?: number | null
  isDefault?: boolean | null
  usage?: string | null
  readonly id: string
  /** The media usage code */
  usageCode?: string | null
}

export type ClusterMediaJsonldContextOneOfHydra =
  (typeof ClusterMediaJsonldContextOneOfHydra)[keyof typeof ClusterMediaJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterMediaJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterMediaJsonldContextOneOf = {
  "@vocab": string
  hydra: ClusterMediaJsonldContextOneOfHydra
  [key: string]: any
}

export type ClusterMediaJsonldContext = string | ClusterMediaJsonldContextOneOf

export type ClusterJsonldUserReadItemStatus = RefLabelJsonldUserReadItem | null

export interface ClusterJsonldUserReadItem {
  readonly "@context"?: ClusterJsonldUserReadItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  status?: ClusterJsonldUserReadItemStatus
  name?: string
  comment?: string | null
  type?: RefLabelJsonldUserReadItem[]
  externalPlatform?: RelClusterExternalPlatformJsonldUserReadItem[]
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  commissionTenant?: number | null
  commissionTenantRate?: number | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  coordsDisplayMode?: string
  isL12nOutsourced?: boolean
  internalSource?: string | null
  createdAt?: string
  readonly updatedAt?: string
}

export type ClusterJsonldUserReadItemContextOneOfHydra =
  (typeof ClusterJsonldUserReadItemContextOneOfHydra)[keyof typeof ClusterJsonldUserReadItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterJsonldUserReadItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterJsonldUserReadItemContextOneOf = {
  "@vocab": string
  hydra: ClusterJsonldUserReadItemContextOneOfHydra
  [key: string]: any
}

export type ClusterJsonldUserReadItemContext = string | ClusterJsonldUserReadItemContextOneOf

export type ClusterJsonldUserReadCollectionStatus = RefLabelJsonldUserReadCollection | null

export interface ClusterJsonldUserReadCollection {
  readonly "@context"?: ClusterJsonldUserReadCollectionContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  status?: ClusterJsonldUserReadCollectionStatus
  name?: string
  comment?: string | null
  type?: RefLabelJsonldUserReadCollection[]
  externalPlatform?: RelClusterExternalPlatformJsonldUserReadCollection[]
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  commissionTenant?: number | null
  commissionTenantRate?: number | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  coordsDisplayMode?: string
  isL12nOutsourced?: boolean
  internalSource?: string | null
  createdAt?: string
  readonly updatedAt?: string
}

export type ClusterJsonldUserReadCollectionContextOneOfHydra =
  (typeof ClusterJsonldUserReadCollectionContextOneOfHydra)[keyof typeof ClusterJsonldUserReadCollectionContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterJsonldUserReadCollectionContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterJsonldUserReadCollectionContextOneOf = {
  "@vocab": string
  hydra: ClusterJsonldUserReadCollectionContextOneOfHydra
  [key: string]: any
}

export type ClusterJsonldUserReadCollectionContext =
  | string
  | ClusterJsonldUserReadCollectionContextOneOf

export type ClusterJsonldReadItemSocietyItemStatus = RefLabelJsonldReadItemSocietyItem | null

export type ClusterJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof ClusterJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof ClusterJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: ClusterJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type ClusterJsonldReadItemSocietyItemContext =
  | string
  | ClusterJsonldReadItemSocietyItemContextOneOf

export interface ClusterJsonldReadItemSocietyItem {
  readonly "@context"?: ClusterJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  status?: ClusterJsonldReadItemSocietyItemStatus
  name?: string
  contactDetails?: RelClusterContactDetailsJsonldReadItemSocietyItem[]
  media?: ClusterMediaJsonldReadItemSocietyItem[]
  agency?: RelAgencyClusterJsonldReadItemSocietyItem[]
  type?: RefLabelJsonldReadItemSocietyItem[]
  externalPlatform?: RelClusterExternalPlatformJsonldReadItemSocietyItem[]
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  commissionTenant?: number | null
  commissionTenantRate?: number | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  coordsDisplayMode?: string
  isL12nOutsourced?: boolean
  internalSource?: string | null
  createdAt?: string
  readonly updatedAt?: string
}

export type ClusterJsonldReadItemProjectStatus = RefLabelJsonldReadItemProject | null

export interface ClusterJsonldReadItemProject {
  readonly "@context"?: ClusterJsonldReadItemProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  status?: ClusterJsonldReadItemProjectStatus
  name?: string
  contactDetails?: RelClusterContactDetailsJsonldReadItemProject[]
  comment?: string | null
  type?: RefLabelJsonldReadItemProject[]
  externalPlatform?: RelClusterExternalPlatformJsonldReadItemProject[]
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  commissionTenant?: number | null
  commissionTenantRate?: number | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  coordsDisplayMode?: string
  isL12nOutsourced?: boolean
  internalSource?: string | null
  createdAt?: string
  readonly updatedAt?: string
}

export type ClusterJsonldReadItemProjectContextOneOfHydra =
  (typeof ClusterJsonldReadItemProjectContextOneOfHydra)[keyof typeof ClusterJsonldReadItemProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterJsonldReadItemProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterJsonldReadItemProjectContextOneOf = {
  "@vocab": string
  hydra: ClusterJsonldReadItemProjectContextOneOfHydra
  [key: string]: any
}

export type ClusterJsonldReadItemProjectContext = string | ClusterJsonldReadItemProjectContextOneOf

export type ClusterJsonldReadItemPeopleStatus = RefLabelJsonldReadItemPeople | null

export interface ClusterJsonldReadItemPeople {
  readonly "@context"?: ClusterJsonldReadItemPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  status?: ClusterJsonldReadItemPeopleStatus
  name?: string
  type?: RefLabelJsonldReadItemPeople[]
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  commissionTenant?: number | null
  commissionTenantRate?: number | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  coordsDisplayMode?: string
  isL12nOutsourced?: boolean
  internalSource?: string | null
  createdAt?: string
  readonly updatedAt?: string
}

export type ClusterJsonldReadItemPeopleContextOneOfHydra =
  (typeof ClusterJsonldReadItemPeopleContextOneOfHydra)[keyof typeof ClusterJsonldReadItemPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterJsonldReadItemPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterJsonldReadItemPeopleContextOneOf = {
  "@vocab": string
  hydra: ClusterJsonldReadItemPeopleContextOneOfHydra
  [key: string]: any
}

export type ClusterJsonldReadItemPeopleContext = string | ClusterJsonldReadItemPeopleContextOneOf

export type ClusterJsonldReadItemCollaboratorItemStatus =
  RefLabelJsonldReadItemCollaboratorItem | null

export type ClusterJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof ClusterJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof ClusterJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: ClusterJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type ClusterJsonldReadItemCollaboratorItemContext =
  | string
  | ClusterJsonldReadItemCollaboratorItemContextOneOf

export interface ClusterJsonldReadItemCollaboratorItem {
  readonly "@context"?: ClusterJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  status?: ClusterJsonldReadItemCollaboratorItemStatus
  name?: string
  contactDetails?: RelClusterContactDetailsJsonldReadItemCollaboratorItem[]
  media?: ClusterMediaJsonldReadItemCollaboratorItem[]
  type?: RefLabelJsonldReadItemCollaboratorItem[]
  externalPlatform?: RelClusterExternalPlatformJsonldReadItemCollaboratorItem[]
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  commissionTenant?: number | null
  commissionTenantRate?: number | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  coordsDisplayMode?: string
  isL12nOutsourced?: boolean
  internalSource?: string | null
  createdAt?: string
  readonly updatedAt?: string
}

export type ClusterJsonldReadItemClusterItemNbProjectsByType = { [key: string]: number }

export type ClusterJsonldReadItemClusterItemStatus = RefLabelJsonldReadItemClusterItem | null

export interface ClusterJsonldReadItemClusterItem {
  readonly "@context"?: ClusterJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  status?: ClusterJsonldReadItemClusterItemStatus
  name?: string
  contactDetails?: RelClusterContactDetailsJsonldReadItemClusterItem[]
  comment?: string | null
  collaborator?: RelCollaboratorClusterJsonldReadItemClusterItem[]
  media?: ClusterMediaJsonldReadItemClusterItem[]
  project?: ProjectClusterJsonldReadItemClusterItem[]
  agency?: RelAgencyClusterJsonldReadItemClusterItem[]
  type?: RefLabelJsonldReadItemClusterItem[]
  agencyOwner?: RelAgencyOwnerClusterJsonldReadItemClusterItem[]
  people?: RelClusterPeopleJsonldReadItemClusterItem[]
  i18nText?: RelClusterI18nTextJsonldReadItemClusterItem[]
  externalPlatform?: RelClusterExternalPlatformJsonldReadItemClusterItem[]
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  commissionTenant?: number | null
  commissionTenantRate?: number | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  coordsDisplayMode?: string
  isL12nOutsourced?: boolean
  nbCollaborators?: number
  nbPeople?: number
  nbAgencyOwners?: number
  nbAgencies?: number
  internalSource?: string | null
  nbProjectsByType?: ClusterJsonldReadItemClusterItemNbProjectsByType
  createdAt?: string
  readonly updatedAt?: string
}

export type ClusterJsonldReadItemClusterItemContextOneOfHydra =
  (typeof ClusterJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof ClusterJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: ClusterJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type ClusterJsonldReadItemClusterItemContext =
  | string
  | ClusterJsonldReadItemClusterItemContextOneOf

export type ClusterJsonldReadItemAgencyItemStatus = RefLabelJsonldReadItemAgencyItem | null

export interface ClusterJsonldReadItemAgencyItem {
  readonly "@context"?: ClusterJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  status?: ClusterJsonldReadItemAgencyItemStatus
  name?: string
  contactDetails?: RelClusterContactDetailsJsonldReadItemAgencyItem[]
  media?: ClusterMediaJsonldReadItemAgencyItem[]
  type?: RefLabelJsonldReadItemAgencyItem[]
  externalPlatform?: RelClusterExternalPlatformJsonldReadItemAgencyItem[]
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  commissionTenant?: number | null
  commissionTenantRate?: number | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  coordsDisplayMode?: string
  isL12nOutsourced?: boolean
  internalSource?: string | null
  createdAt?: string
  readonly updatedAt?: string
}

export type ClusterJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof ClusterJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof ClusterJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: ClusterJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type ClusterJsonldReadItemAgencyItemContext =
  | string
  | ClusterJsonldReadItemAgencyItemContextOneOf

export type ClusterJsonldReadCollectionProjectExcelExportStatus =
  RefLabelJsonldReadCollectionProjectExcelExport | null

export interface ClusterJsonldReadCollectionProjectExcelExport {
  readonly "@context"?: ClusterJsonldReadCollectionProjectExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  status?: ClusterJsonldReadCollectionProjectExcelExportStatus
  name?: string
  contactDetails?: RelClusterContactDetailsJsonldReadCollectionProjectExcelExport[]
  comment?: string | null
  type?: RefLabelJsonldReadCollectionProjectExcelExport[]
  externalPlatform?: RelClusterExternalPlatformJsonldReadCollectionProjectExcelExport[]
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  commissionTenant?: number | null
  commissionTenantRate?: number | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  coordsDisplayMode?: string
  isL12nOutsourced?: boolean
  internalSource?: string | null
  createdAt?: string
  readonly updatedAt?: string
}

export type ClusterJsonldReadCollectionProjectExcelExportContextOneOfHydra =
  (typeof ClusterJsonldReadCollectionProjectExcelExportContextOneOfHydra)[keyof typeof ClusterJsonldReadCollectionProjectExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterJsonldReadCollectionProjectExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterJsonldReadCollectionProjectExcelExportContextOneOf = {
  "@vocab": string
  hydra: ClusterJsonldReadCollectionProjectExcelExportContextOneOfHydra
  [key: string]: any
}

export type ClusterJsonldReadCollectionProjectExcelExportContext =
  | string
  | ClusterJsonldReadCollectionProjectExcelExportContextOneOf

export type ClusterJsonldReadCollectionProjectStatus = RefLabelJsonldReadCollectionProject | null

export interface ClusterJsonldReadCollectionProject {
  readonly "@context"?: ClusterJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  status?: ClusterJsonldReadCollectionProjectStatus
  name?: string
  contactDetails?: RelClusterContactDetailsJsonldReadCollectionProject[]
  type?: RefLabelJsonldReadCollectionProject[]
  externalPlatform?: RelClusterExternalPlatformJsonldReadCollectionProject[]
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  commissionTenant?: number | null
  commissionTenantRate?: number | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  coordsDisplayMode?: string
  isL12nOutsourced?: boolean
  internalSource?: string | null
  createdAt?: string
  readonly updatedAt?: string
}

export type ClusterJsonldReadCollectionProjectContextOneOfHydra =
  (typeof ClusterJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof ClusterJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: ClusterJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type ClusterJsonldReadCollectionProjectContext =
  | string
  | ClusterJsonldReadCollectionProjectContextOneOf

export interface ClusterJsonldReadCollectionPeople {
  readonly "@context"?: ClusterJsonldReadCollectionPeopleContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  name?: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ClusterJsonldReadCollectionPeopleContextOneOfHydra =
  (typeof ClusterJsonldReadCollectionPeopleContextOneOfHydra)[keyof typeof ClusterJsonldReadCollectionPeopleContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterJsonldReadCollectionPeopleContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterJsonldReadCollectionPeopleContextOneOf = {
  "@vocab": string
  hydra: ClusterJsonldReadCollectionPeopleContextOneOfHydra
  [key: string]: any
}

export type ClusterJsonldReadCollectionPeopleContext =
  | string
  | ClusterJsonldReadCollectionPeopleContextOneOf

export type ClusterJsonldReadCollectionCollaboratorStatus =
  RefLabelJsonldReadCollectionCollaborator | null

export type ClusterJsonldReadCollectionCollaboratorContextOneOfHydra =
  (typeof ClusterJsonldReadCollectionCollaboratorContextOneOfHydra)[keyof typeof ClusterJsonldReadCollectionCollaboratorContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterJsonldReadCollectionCollaboratorContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterJsonldReadCollectionCollaboratorContextOneOf = {
  "@vocab": string
  hydra: ClusterJsonldReadCollectionCollaboratorContextOneOfHydra
  [key: string]: any
}

export type ClusterJsonldReadCollectionCollaboratorContext =
  | string
  | ClusterJsonldReadCollectionCollaboratorContextOneOf

export interface ClusterJsonldReadCollectionCollaborator {
  readonly "@context"?: ClusterJsonldReadCollectionCollaboratorContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  status?: ClusterJsonldReadCollectionCollaboratorStatus
  name?: string
  externalPlatform?: RelClusterExternalPlatformJsonldReadCollectionCollaborator[]
  createdAt?: string
  readonly updatedAt?: string
}

export type ClusterJsonldReadCollectionClusterExcelExportStatus =
  RefLabelJsonldReadCollectionClusterExcelExport | null

export interface ClusterJsonldReadCollectionClusterExcelExport {
  readonly "@id"?: string
  readonly "@type"?: string
  readonly "@context"?: ClusterJsonldReadCollectionClusterExcelExportContext
  readonly id: string
  status?: ClusterJsonldReadCollectionClusterExcelExportStatus
  name?: string
  contactDetails?: RelClusterContactDetailsJsonldReadCollectionClusterExcelExport[]
  comment?: string | null
  collaborator?: RelCollaboratorClusterJsonldReadCollectionClusterExcelExport[]
  media?: ClusterMediaJsonldReadCollectionClusterExcelExport[]
  project?: ProjectClusterJsonldReadCollectionClusterExcelExport[]
  agency?: RelAgencyClusterJsonldReadCollectionClusterExcelExport[]
  type?: RefLabelJsonldReadCollectionClusterExcelExport[]
  agencyOwner?: RelAgencyOwnerClusterJsonldReadCollectionClusterExcelExport[]
  people?: RelClusterPeopleJsonldReadCollectionClusterExcelExport[]
  i18nText?: RelClusterI18nTextJsonldReadCollectionClusterExcelExport[]
  externalPlatform?: RelClusterExternalPlatformJsonldReadCollectionClusterExcelExport[]
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  commissionTenant?: number | null
  commissionTenantRate?: number | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  coordsDisplayMode?: string
  isL12nOutsourced?: boolean
  internalSource?: string | null
  createdAt?: string
  readonly updatedAt?: string
}

export type ClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra =
  (typeof ClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra)[keyof typeof ClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterJsonldReadCollectionClusterExcelExportContextOneOf = {
  "@vocab": string
  hydra: ClusterJsonldReadCollectionClusterExcelExportContextOneOfHydra
  [key: string]: any
}

export type ClusterJsonldReadCollectionClusterExcelExportContext =
  | string
  | ClusterJsonldReadCollectionClusterExcelExportContextOneOf

export type ClusterJsonldReadCollectionClusterNbProjectsByType = { [key: string]: number }

export type ClusterJsonldReadCollectionClusterStatus = RefLabelJsonldReadCollectionCluster | null

export interface ClusterJsonldReadCollectionCluster {
  readonly "@id"?: string
  readonly "@type"?: string
  readonly "@context"?: ClusterJsonldReadCollectionClusterContext
  readonly id: string
  status?: ClusterJsonldReadCollectionClusterStatus
  name?: string
  contactDetails?: RelClusterContactDetailsJsonldReadCollectionCluster[]
  comment?: string | null
  media?: ClusterMediaJsonldReadCollectionCluster[]
  type?: RefLabelJsonldReadCollectionCluster[]
  externalPlatform?: RelClusterExternalPlatformJsonldReadCollectionCluster[]
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  commissionTenant?: number | null
  commissionTenantRate?: number | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  coordsDisplayMode?: string
  isL12nOutsourced?: boolean
  nbCollaborators?: number
  nbPeople?: number
  nbAgencyOwners?: number
  nbAgencies?: number
  internalSource?: string | null
  nbProjectsByType?: ClusterJsonldReadCollectionClusterNbProjectsByType
  createdAt?: string
  readonly updatedAt?: string
}

export type ClusterJsonldReadCollectionClusterContextOneOfHydra =
  (typeof ClusterJsonldReadCollectionClusterContextOneOfHydra)[keyof typeof ClusterJsonldReadCollectionClusterContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterJsonldReadCollectionClusterContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterJsonldReadCollectionClusterContextOneOf = {
  "@vocab": string
  hydra: ClusterJsonldReadCollectionClusterContextOneOfHydra
  [key: string]: any
}

export type ClusterJsonldReadCollectionClusterContext =
  | string
  | ClusterJsonldReadCollectionClusterContextOneOf

export type ClusterJsonldNbProjectsByType = { [key: string]: number }

export interface ClusterJsonld {
  readonly "@context"?: ClusterJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  status?: string | null
  name?: string
  contactDetails?: RelClusterContactDetailsJsonld[]
  comment?: string | null
  clusterParent?: string | null
  collaborator?: RelCollaboratorClusterJsonld[]
  media?: ClusterMediaJsonld[]
  project?: ProjectClusterJsonld[]
  agency?: RelAgencyClusterJsonld[]
  type?: string[]
  agencyOwner?: RelAgencyOwnerClusterJsonld[]
  people?: RelClusterPeopleJsonld[]
  i18nText?: RelClusterI18nTextJsonld[]
  externalPlatform?: RelClusterExternalPlatformJsonld[]
  commissionOwner?: number | null
  commissionOwnerRate?: number | null
  commissionTenant?: number | null
  commissionTenantRate?: number | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  coordsDisplayMode?: string
  isL12nOutsourced?: boolean
  nbCollaborators?: number
  nbPeople?: number
  nbAgencyOwners?: number
  nbAgencies?: number
  internalSource?: string | null
  nbProjectsByType?: ClusterJsonldNbProjectsByType
  createdAt?: string
  readonly updatedAt?: string
}

export type ClusterJsonldContextOneOfHydra =
  (typeof ClusterJsonldContextOneOfHydra)[keyof typeof ClusterJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClusterJsonldContextOneOf = {
  "@vocab": string
  hydra: ClusterJsonldContextOneOfHydra
  [key: string]: any
}

export type ClusterJsonldContext = string | ClusterJsonldContextOneOf

export interface ClusterPostClusterInputJsonld {
  name?: string
  status?: string | null
  type?: string[] | null
  comment?: string | null
  clusterParent?: string | null
  contactDetails?: ContactDetailsDTOJsonld[] | null
  agencyOwner?: ClusterSocietyDTOJsonld[] | null
  agency?: AgencyClusterDTOJsonld[] | null
  collaborator?: CollaboratorClusterDTOJsonld[] | null
  project?: ProjectClusterDTOJsonld[] | null
  people?: PeopleClusterDTOJsonld[] | null
  accessLevel?: string | null
  accessLevelForced?: string | null
  coordsDisplayMode?: string | null
  isL12nOutsourced?: string | null
}

export interface ClusterPatchEntityMediaInput {
  datas?: EntityMediaDTO[]
}

export interface ClusterPatchClusterInput {
  name?: string | null
  status?: string | null
  type?: string[] | null
  comment?: string | null
  clusterParent?: string | null
  contactDetails?: ContactDetailsDTO[] | null
  agencyOwner?: ClusterSocietyDTO[] | null
  agency?: AgencyClusterDTO[] | null
  collaborator?: CollaboratorClusterDTO[] | null
  project?: ProjectClusterDTO[] | null
  people?: PeopleClusterDTO[] | null
  accessLevel?: string | null
  accessLevelForced?: string | null
  coordsDisplayMode?: string | null
  isL12nOutsourced?: string | null
}

export type ClientHobbyJsonldReadItemClientWithoutPrivateDataContext =
  | string
  | ClientHobbyJsonldReadItemClientWithoutPrivateDataContextOneOf

export interface ClientHobbyJsonldReadItemClientWithoutPrivateData {
  readonly "@context"?: ClientHobbyJsonldReadItemClientWithoutPrivateDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  hobby?: HobbyJsonldReadItemClientWithoutPrivateData
}

export type ClientHobbyJsonldReadItemClientWithoutPrivateDataContextOneOfHydra =
  (typeof ClientHobbyJsonldReadItemClientWithoutPrivateDataContextOneOfHydra)[keyof typeof ClientHobbyJsonldReadItemClientWithoutPrivateDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientHobbyJsonldReadItemClientWithoutPrivateDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClientHobbyJsonldReadItemClientWithoutPrivateDataContextOneOf = {
  "@vocab": string
  hydra: ClientHobbyJsonldReadItemClientWithoutPrivateDataContextOneOfHydra
  [key: string]: any
}

export interface ClientHobbyJsonld {
  readonly "@context"?: ClientHobbyJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  client?: string
  hobby?: string
  comment?: string | null
}

export type ClientHobbyJsonldContextOneOfHydra =
  (typeof ClientHobbyJsonldContextOneOfHydra)[keyof typeof ClientHobbyJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientHobbyJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClientHobbyJsonldContextOneOf = {
  "@vocab": string
  hydra: ClientHobbyJsonldContextOneOfHydra
  [key: string]: any
}

export type ClientHobbyJsonldContext = string | ClientHobbyJsonldContextOneOf

export type ClientAccessRequestJsonldReadCollectionClientAccessRequestStatus =
  (typeof ClientAccessRequestJsonldReadCollectionClientAccessRequestStatus)[keyof typeof ClientAccessRequestJsonldReadCollectionClientAccessRequestStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientAccessRequestJsonldReadCollectionClientAccessRequestStatus = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  REVOKED: "REVOKED",
} as const

export type ClientAccessRequestJsonldReadCollectionClientAccessRequestBusinessUnit =
  BusinessUnitJsonldReadCollectionClientAccessRequest | null

export type ClientAccessRequestJsonldReadCollectionClientAccessRequestMotivation =
  (typeof ClientAccessRequestJsonldReadCollectionClientAccessRequestMotivation)[keyof typeof ClientAccessRequestJsonldReadCollectionClientAccessRequestMotivation]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientAccessRequestJsonldReadCollectionClientAccessRequestMotivation = {
  JOINT_PROJECT: "JOINT_PROJECT",
  NEW_PROSPECTS: "NEW_PROSPECTS",
} as const

export interface ClientAccessRequestJsonldReadCollectionClientAccessRequest {
  readonly "@id"?: string
  readonly "@type"?: string
  client: ClientJsonldReadCollectionClientAccessRequest
  motivation?: ClientAccessRequestJsonldReadCollectionClientAccessRequestMotivation
  user?: UserJsonldReadCollectionClientAccessRequest
  businessUnit?: ClientAccessRequestJsonldReadCollectionClientAccessRequestBusinessUnit
  status?: ClientAccessRequestJsonldReadCollectionClientAccessRequestStatus
  answerDate?: string | null
  comment?: string | null
  readonly id: string
  createdAt?: string
}

export type ClientAccessRequestJsonldStatus =
  (typeof ClientAccessRequestJsonldStatus)[keyof typeof ClientAccessRequestJsonldStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientAccessRequestJsonldStatus = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  REVOKED: "REVOKED",
} as const

export type ClientAccessRequestJsonldMotivation =
  (typeof ClientAccessRequestJsonldMotivation)[keyof typeof ClientAccessRequestJsonldMotivation]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientAccessRequestJsonldMotivation = {
  JOINT_PROJECT: "JOINT_PROJECT",
  NEW_PROSPECTS: "NEW_PROSPECTS",
} as const

export interface ClientAccessRequestJsonld {
  readonly "@context"?: ClientAccessRequestJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  client: string
  motivation?: ClientAccessRequestJsonldMotivation
  user?: string
  businessUnit?: string | null
  status?: ClientAccessRequestJsonldStatus
  answerDate?: string | null
  comment?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ClientAccessRequestJsonldContextOneOfHydra =
  (typeof ClientAccessRequestJsonldContextOneOfHydra)[keyof typeof ClientAccessRequestJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientAccessRequestJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClientAccessRequestJsonldContextOneOf = {
  "@vocab": string
  hydra: ClientAccessRequestJsonldContextOneOfHydra
  [key: string]: any
}

export type ClientAccessRequestJsonldContext = string | ClientAccessRequestJsonldContextOneOf

export type ClientAccessRequestCreateClientAccessRequestInputJsonldMotivation =
  (typeof ClientAccessRequestCreateClientAccessRequestInputJsonldMotivation)[keyof typeof ClientAccessRequestCreateClientAccessRequestInputJsonldMotivation]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientAccessRequestCreateClientAccessRequestInputJsonldMotivation = {
  JOINT_PROJECT: "JOINT_PROJECT",
  NEW_PROSPECTS: "NEW_PROSPECTS",
} as const

export interface ClientAccessRequestCreateClientAccessRequestInputJsonld {
  clientId: string
  motivation: ClientAccessRequestCreateClientAccessRequestInputJsonldMotivation
  comment?: string | null
}

export type ClientAccessRequestWriteItemClientAccessRequestStatus =
  (typeof ClientAccessRequestWriteItemClientAccessRequestStatus)[keyof typeof ClientAccessRequestWriteItemClientAccessRequestStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientAccessRequestWriteItemClientAccessRequestStatus = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  REVOKED: "REVOKED",
} as const

export interface ClientAccessRequestWriteItemClientAccessRequest {
  status?: ClientAccessRequestWriteItemClientAccessRequestStatus
}

export type ClientJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra =
  (typeof ClientJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra)[keyof typeof ClientJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClientJsonldReadItemTpropertyWithoutPrivateDataContextOneOf = {
  "@vocab": string
  hydra: ClientJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra
  [key: string]: any
}

export type ClientJsonldReadItemTpropertyWithoutPrivateDataContext =
  | string
  | ClientJsonldReadItemTpropertyWithoutPrivateDataContextOneOf

export interface ClientJsonldReadItemTpropertyWithoutPrivateData {
  readonly "@context"?: ClientJsonldReadItemTpropertyWithoutPrivateDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  firstName?: string | null
  lastName?: string | null
  readonly id: string
  createdAt?: string
}

export type ClientJsonldReadItemInterbuLanguagesItem =
  (typeof ClientJsonldReadItemInterbuLanguagesItem)[keyof typeof ClientJsonldReadItemInterbuLanguagesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadItemInterbuLanguagesItem = {
  af_ZA: "af_ZA",
  am_ET: "am_ET",
  ar_AA: "ar_AA",
  as_IN: "as_IN",
  az_AZ: "az_AZ",
  be_BY: "be_BY",
  bg_BG: "bg_BG",
  bn_BD: "bn_BD",
  bo_IN: "bo_IN",
  bs_BA: "bs_BA",
  ca_ES: "ca_ES",
  cs_CZ: "cs_CZ",
  da_DK: "da_DK",
  de_DE: "de_DE",
  el_GR: "el_GR",
  en_GB: "en_GB",
  es_ES: "es_ES",
  et_EE: "et_EE",
  fi_FI: "fi_FI",
  fr_FR: "fr_FR",
  ga_IE: "ga_IE",
  he_IL: "he_IL",
  hi_IN: "hi_IN",
  hr_BA: "hr_BA",
  hu_HU: "hu_HU",
  hy_AM: "hy_AM",
  id_ID: "id_ID",
  ig_NG: "ig_NG",
  ii_CN: "ii_CN",
  is_IS: "is_IS",
  it_IT: "it_IT",
  iu_CA: "iu_CA",
  ja_JP: "ja_JP",
  jv_ID: "jv_ID",
  ka_GE: "ka_GE",
  ki_KE: "ki_KE",
  kk_KZ: "kk_KZ",
  km_KH: "km_KH",
  kn_IN: "kn_IN",
  ko_KR: "ko_KR",
  ks_IN: "ks_IN",
  ku_TR: "ku_TR",
  kw_GB: "kw_GB",
  lb_LU: "lb_LU",
  lg_UG: "lg_UG",
  ln_AO: "ln_AO",
  lo_LA: "lo_LA",
  lt_LT: "lt_LT",
  lu_CD: "lu_CD",
  lv_LV: "lv_LV",
  mg_MG: "mg_MG",
  en_MH: "en_MH",
  mi_NZ: "mi_NZ",
  mk_MK: "mk_MK",
  ml_IN: "ml_IN",
  mn_MN: "mn_MN",
  mr_IN: "mr_IN",
  ms_BN: "ms_BN",
  mt_MT: "mt_MT",
  my_MM: "my_MM",
  en_NR: "en_NR",
  nb_NO: "nb_NO",
  ne_IN: "ne_IN",
  nl_NL: "nl_NL",
  nn_NO: "nn_NO",
  no_NO: "no_NO",
  nr_ZA: "nr_ZA",
  om_ET: "om_ET",
  pl_PL: "pl_PL",
  ps_PK: "ps_PK",
  pt_PT: "pt_PT",
  qu_PE: "qu_PE",
  rm_CH: "rm_CH",
  ro_RO: "ro_RO",
  ru_RU: "ru_RU",
  rw_RW: "rw_RW",
  sa_IN: "sa_IN",
  sd_PK: "sd_PK",
  se_SE: "se_SE",
  sg_CF: "sg_CF",
  sk_SK: "sk_SK",
  sl_SL: "sl_SL",
  sn_ZW: "sn_ZW",
  so_SO: "so_SO",
  sq_AL: "sq_AL",
  sr_BA: "sr_BA",
  ss_SZ: "ss_SZ",
  st_LS: "st_LS",
  sv_SE: "sv_SE",
  te_IN: "te_IN",
  tg_TJ: "tg_TJ",
  th_TH: "th_TH",
  ti_ER: "ti_ER",
  tk_TM: "tk_TM",
  tn_BW: "tn_BW",
  to_TO: "to_TO",
  tr_TR: "tr_TR",
  ts_ZA: "ts_ZA",
  tt_RU: "tt_RU",
  uk_UA: "uk_UA",
  ur_PK: "ur_PK",
  uz_UZ: "uz_UZ",
  ve_ZA: "ve_ZA",
  vi_VN: "vi_VN",
  wo_SN: "wo_SN",
  xh_ZA: "xh_ZA",
  "yi-001": "yi-001",
  yo_NG: "yo_NG",
  zh_CN: "zh_CN",
  zu_ZA: "zu_ZA",
} as const

export type ClientJsonldReadItemInterbuCivility =
  (typeof ClientJsonldReadItemInterbuCivility)[keyof typeof ClientJsonldReadItemInterbuCivility]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadItemInterbuCivility = {
  MALE: "MALE",
  FEMALE: "FEMALE",
} as const

export type ClientJsonldReadItemInterbuContextOneOfHydra =
  (typeof ClientJsonldReadItemInterbuContextOneOfHydra)[keyof typeof ClientJsonldReadItemInterbuContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadItemInterbuContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClientJsonldReadItemInterbuContextOneOf = {
  "@vocab": string
  hydra: ClientJsonldReadItemInterbuContextOneOfHydra
  [key: string]: any
}

export type ClientJsonldReadItemInterbuContext = string | ClientJsonldReadItemInterbuContextOneOf

export interface ClientJsonldReadItemInterbu {
  readonly "@context"?: ClientJsonldReadItemInterbuContext
  readonly "@id"?: string
  readonly "@type"?: string
  civility?: ClientJsonldReadItemInterbuCivility
  firstName?: string | null
  lastName?: string | null
  languages?: ClientJsonldReadItemInterbuLanguagesItem[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ClientJsonldReadItemClientWithoutPrivateDataProjectStatus =
  (typeof ClientJsonldReadItemClientWithoutPrivateDataProjectStatus)[keyof typeof ClientJsonldReadItemClientWithoutPrivateDataProjectStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadItemClientWithoutPrivateDataProjectStatus = {
  FINISHED: "FINISHED",
  IN_PROGRESS: "IN_PROGRESS",
  ABANDONED: "ABANDONED",
} as const

export type ClientJsonldReadItemClientWithoutPrivateDataFamilySituation =
  (typeof ClientJsonldReadItemClientWithoutPrivateDataFamilySituation)[keyof typeof ClientJsonldReadItemClientWithoutPrivateDataFamilySituation]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadItemClientWithoutPrivateDataFamilySituation = {
  SINGLE: "SINGLE",
  MARRIED: "MARRIED",
  WIDOWED: "WIDOWED",
  DIVORCED: "DIVORCED",
} as const

export type ClientJsonldReadItemClientWithoutPrivateDataVisibility =
  (typeof ClientJsonldReadItemClientWithoutPrivateDataVisibility)[keyof typeof ClientJsonldReadItemClientWithoutPrivateDataVisibility]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadItemClientWithoutPrivateDataVisibility = {
  NOT_CONFIDENTIAL: "NOT_CONFIDENTIAL",
  CONFIDENTIAL: "CONFIDENTIAL",
} as const

export type ClientJsonldReadItemClientWithoutPrivateDataLanguagesItem =
  (typeof ClientJsonldReadItemClientWithoutPrivateDataLanguagesItem)[keyof typeof ClientJsonldReadItemClientWithoutPrivateDataLanguagesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadItemClientWithoutPrivateDataLanguagesItem = {
  af_ZA: "af_ZA",
  am_ET: "am_ET",
  ar_AA: "ar_AA",
  as_IN: "as_IN",
  az_AZ: "az_AZ",
  be_BY: "be_BY",
  bg_BG: "bg_BG",
  bn_BD: "bn_BD",
  bo_IN: "bo_IN",
  bs_BA: "bs_BA",
  ca_ES: "ca_ES",
  cs_CZ: "cs_CZ",
  da_DK: "da_DK",
  de_DE: "de_DE",
  el_GR: "el_GR",
  en_GB: "en_GB",
  es_ES: "es_ES",
  et_EE: "et_EE",
  fi_FI: "fi_FI",
  fr_FR: "fr_FR",
  ga_IE: "ga_IE",
  he_IL: "he_IL",
  hi_IN: "hi_IN",
  hr_BA: "hr_BA",
  hu_HU: "hu_HU",
  hy_AM: "hy_AM",
  id_ID: "id_ID",
  ig_NG: "ig_NG",
  ii_CN: "ii_CN",
  is_IS: "is_IS",
  it_IT: "it_IT",
  iu_CA: "iu_CA",
  ja_JP: "ja_JP",
  jv_ID: "jv_ID",
  ka_GE: "ka_GE",
  ki_KE: "ki_KE",
  kk_KZ: "kk_KZ",
  km_KH: "km_KH",
  kn_IN: "kn_IN",
  ko_KR: "ko_KR",
  ks_IN: "ks_IN",
  ku_TR: "ku_TR",
  kw_GB: "kw_GB",
  lb_LU: "lb_LU",
  lg_UG: "lg_UG",
  ln_AO: "ln_AO",
  lo_LA: "lo_LA",
  lt_LT: "lt_LT",
  lu_CD: "lu_CD",
  lv_LV: "lv_LV",
  mg_MG: "mg_MG",
  en_MH: "en_MH",
  mi_NZ: "mi_NZ",
  mk_MK: "mk_MK",
  ml_IN: "ml_IN",
  mn_MN: "mn_MN",
  mr_IN: "mr_IN",
  ms_BN: "ms_BN",
  mt_MT: "mt_MT",
  my_MM: "my_MM",
  en_NR: "en_NR",
  nb_NO: "nb_NO",
  ne_IN: "ne_IN",
  nl_NL: "nl_NL",
  nn_NO: "nn_NO",
  no_NO: "no_NO",
  nr_ZA: "nr_ZA",
  om_ET: "om_ET",
  pl_PL: "pl_PL",
  ps_PK: "ps_PK",
  pt_PT: "pt_PT",
  qu_PE: "qu_PE",
  rm_CH: "rm_CH",
  ro_RO: "ro_RO",
  ru_RU: "ru_RU",
  rw_RW: "rw_RW",
  sa_IN: "sa_IN",
  sd_PK: "sd_PK",
  se_SE: "se_SE",
  sg_CF: "sg_CF",
  sk_SK: "sk_SK",
  sl_SL: "sl_SL",
  sn_ZW: "sn_ZW",
  so_SO: "so_SO",
  sq_AL: "sq_AL",
  sr_BA: "sr_BA",
  ss_SZ: "ss_SZ",
  st_LS: "st_LS",
  sv_SE: "sv_SE",
  te_IN: "te_IN",
  tg_TJ: "tg_TJ",
  th_TH: "th_TH",
  ti_ER: "ti_ER",
  tk_TM: "tk_TM",
  tn_BW: "tn_BW",
  to_TO: "to_TO",
  tr_TR: "tr_TR",
  ts_ZA: "ts_ZA",
  tt_RU: "tt_RU",
  uk_UA: "uk_UA",
  ur_PK: "ur_PK",
  uz_UZ: "uz_UZ",
  ve_ZA: "ve_ZA",
  vi_VN: "vi_VN",
  wo_SN: "wo_SN",
  xh_ZA: "xh_ZA",
  "yi-001": "yi-001",
  yo_NG: "yo_NG",
  zh_CN: "zh_CN",
  zu_ZA: "zu_ZA",
} as const

export type ClientJsonldReadItemClientWithoutPrivateDataUser =
  UserJsonldReadItemClientWithoutPrivateData | null

export type ClientJsonldReadItemClientWithoutPrivateDataBusinessUnit =
  BusinessUnitJsonldReadItemClientWithoutPrivateData | null

export type ClientJsonldReadItemClientWithoutPrivateDataProfileType =
  (typeof ClientJsonldReadItemClientWithoutPrivateDataProfileType)[keyof typeof ClientJsonldReadItemClientWithoutPrivateDataProfileType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadItemClientWithoutPrivateDataProfileType = {
  PRIVATE_INDIVIDUAL: "PRIVATE_INDIVIDUAL",
  INVESTOR: "INVESTOR",
  INSTITUTIONAL_INVESTOR: "INSTITUTIONAL_INVESTOR",
  JOINT_OWNERSHIP: "JOINT_OWNERSHIP",
  PROPERTY_TRADER: "PROPERTY_TRADER",
  NOTARY: "NOTARY",
  INTERMEDIATE: "INTERMEDIATE",
  COUNTERPART: "COUNTERPART",
} as const

export type ClientJsonldReadItemClientWithoutPrivateDataContactOrigin =
  (typeof ClientJsonldReadItemClientWithoutPrivateDataContactOrigin)[keyof typeof ClientJsonldReadItemClientWithoutPrivateDataContactOrigin]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadItemClientWithoutPrivateDataContactOrigin = {
  WEB: "WEB",
  VISIT: "VISIT",
  PHONE: "PHONE",
  MAGAZINE: "MAGAZINE",
} as const

export type ClientJsonldReadItemClientWithoutPrivateDataReferenceCurrency =
  (typeof ClientJsonldReadItemClientWithoutPrivateDataReferenceCurrency)[keyof typeof ClientJsonldReadItemClientWithoutPrivateDataReferenceCurrency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadItemClientWithoutPrivateDataReferenceCurrency = {
  EUR: "EUR",
  USD: "USD",
  CAD: "CAD",
  HUF: "HUF",
  MUR: "MUR",
  MAD: "MAD",
  RUB: "RUB",
  CHF: "CHF",
  GBP: "GBP",
} as const

export type ClientJsonldReadItemClientWithoutPrivateDataNationality =
  (typeof ClientJsonldReadItemClientWithoutPrivateDataNationality)[keyof typeof ClientJsonldReadItemClientWithoutPrivateDataNationality]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadItemClientWithoutPrivateDataNationality = {
  ad: "ad",
  ae: "ae",
  af: "af",
  ag: "ag",
  ai: "ai",
  al: "al",
  am: "am",
  ao: "ao",
  ar: "ar",
  as: "as",
  at: "at",
  au: "au",
  aw: "aw",
  az: "az",
  ba: "ba",
  bb: "bb",
  bd: "bd",
  be: "be",
  bf: "bf",
  bg: "bg",
  bh: "bh",
  bi: "bi",
  bj: "bj",
  bm: "bm",
  bn: "bn",
  bo: "bo",
  br: "br",
  bs: "bs",
  bt: "bt",
  bw: "bw",
  by: "by",
  bz: "bz",
  ca: "ca",
  cd: "cd",
  cf: "cf",
  cg: "cg",
  ch: "ch",
  ci: "ci",
  ck: "ck",
  cl: "cl",
  cm: "cm",
  cn: "cn",
  co: "co",
  cr: "cr",
  cu: "cu",
  cv: "cv",
  cy: "cy",
  cz: "cz",
  de: "de",
  dj: "dj",
  dk: "dk",
  dm: "dm",
  do: "do",
  dz: "dz",
  ec: "ec",
  ee: "ee",
  eg: "eg",
  er: "er",
  es: "es",
  et: "et",
  fi: "fi",
  fj: "fj",
  fm: "fm",
  fr: "fr",
  ga: "ga",
  gb: "gb",
  gd: "gd",
  ge: "ge",
  gh: "gh",
  gm: "gm",
  gn: "gn",
  gq: "gq",
  gr: "gr",
  gt: "gt",
  gw: "gw",
  gy: "gy",
  hk: "hk",
  hn: "hn",
  hr: "hr",
  ht: "ht",
  hu: "hu",
  id: "id",
  ie: "ie",
  il: "il",
  in: "in",
  iq: "iq",
  ir: "ir",
  is: "is",
  it: "it",
  jm: "jm",
  jo: "jo",
  jp: "jp",
  ke: "ke",
  kg: "kg",
  kh: "kh",
  ki: "ki",
  km: "km",
  kn: "kn",
  kp: "kp",
  kr: "kr",
  kw: "kw",
  ky: "ky",
  kz: "kz",
  la: "la",
  lb: "lb",
  lc: "lc",
  li: "li",
  lk: "lk",
  lr: "lr",
  ls: "ls",
  lt: "lt",
  lu: "lu",
  lv: "lv",
  ly: "ly",
  ma: "ma",
  mc: "mc",
  md: "md",
  me: "me",
  mg: "mg",
  mh: "mh",
  mk: "mk",
  ml: "ml",
  mm: "mm",
  mn: "mn",
  mr: "mr",
  mt: "mt",
  mu: "mu",
  mv: "mv",
  mw: "mw",
  mx: "mx",
  my: "my",
  mz: "mz",
  na: "na",
  ne: "ne",
  ng: "ng",
  ni: "ni",
  nl: "nl",
  no: "no",
  np: "np",
  nr: "nr",
  nu: "nu",
  nz: "nz",
  om: "om",
  pa: "pa",
  pe: "pe",
  pg: "pg",
  ph: "ph",
  pk: "pk",
  pl: "pl",
  pr: "pr",
  ps: "ps",
  pt: "pt",
  pw: "pw",
  py: "py",
  qa: "qa",
  ro: "ro",
  rs: "rs",
  ru: "ru",
  rw: "rw",
  sa: "sa",
  sb: "sb",
  sc: "sc",
  sd: "sd",
  se: "se",
  sg: "sg",
  si: "si",
  sk: "sk",
  sl: "sl",
  sm: "sm",
  sn: "sn",
  so: "so",
  sr: "sr",
  ss: "ss",
  st: "st",
  sv: "sv",
  sy: "sy",
  sz: "sz",
  td: "td",
  tg: "tg",
  th: "th",
  tj: "tj",
  tk: "tk",
  tl: "tl",
  tm: "tm",
  tn: "tn",
  to: "to",
  tr: "tr",
  tt: "tt",
  tv: "tv",
  tw: "tw",
  tz: "tz",
  ua: "ua",
  ug: "ug",
  us: "us",
  uy: "uy",
  uz: "uz",
  va: "va",
  vc: "vc",
  ve: "ve",
  vn: "vn",
  vu: "vu",
  ws: "ws",
  ye: "ye",
  za: "za",
  zm: "zm",
  zw: "zw",
} as const

export type ClientJsonldReadItemClientWithoutPrivateDataCivility =
  (typeof ClientJsonldReadItemClientWithoutPrivateDataCivility)[keyof typeof ClientJsonldReadItemClientWithoutPrivateDataCivility]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadItemClientWithoutPrivateDataCivility = {
  MALE: "MALE",
  FEMALE: "FEMALE",
} as const

export type ClientJsonldReadItemClientWithoutPrivateDataContextOneOfHydra =
  (typeof ClientJsonldReadItemClientWithoutPrivateDataContextOneOfHydra)[keyof typeof ClientJsonldReadItemClientWithoutPrivateDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadItemClientWithoutPrivateDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClientJsonldReadItemClientWithoutPrivateDataContextOneOf = {
  "@vocab": string
  hydra: ClientJsonldReadItemClientWithoutPrivateDataContextOneOfHydra
  [key: string]: any
}

export type ClientJsonldReadItemClientWithoutPrivateDataContext =
  | string
  | ClientJsonldReadItemClientWithoutPrivateDataContextOneOf

export interface ClientJsonldReadItemClientWithoutPrivateData {
  readonly "@context"?: ClientJsonldReadItemClientWithoutPrivateDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  civility?: ClientJsonldReadItemClientWithoutPrivateDataCivility
  firstName?: string | null
  lastName?: string | null
  nationality?: ClientJsonldReadItemClientWithoutPrivateDataNationality
  birthPlace?: string | null
  activity?: string | null
  favoriteContactType?: string | null
  referenceCurrency?: ClientJsonldReadItemClientWithoutPrivateDataReferenceCurrency
  contactOrigin?: ClientJsonldReadItemClientWithoutPrivateDataContactOrigin
  isGlobalNewsletterSubscriber?: boolean | null
  isThemedNewsletterSubscriber?: boolean | null
  isMagazinSubscriber?: boolean | null
  isMonitored?: boolean | null
  profileType?: ClientJsonldReadItemClientWithoutPrivateDataProfileType
  businessUnit?: ClientJsonldReadItemClientWithoutPrivateDataBusinessUnit
  user?: ClientJsonldReadItemClientWithoutPrivateDataUser
  languages?: ClientJsonldReadItemClientWithoutPrivateDataLanguagesItem[]
  visibility: ClientJsonldReadItemClientWithoutPrivateDataVisibility
  rating?: number | null
  holidayLocation?: HolidayLocationJsonldReadItemClientWithoutPrivateData[]
  hobbies?: ClientHobbyJsonldReadItemClientWithoutPrivateData[]
  circles?: CircleJsonldReadItemClientWithoutPrivateData[]
  subscriptions?: TsubscriptionJsonldReadItemClientWithoutPrivateData[]
  familySituation?: ClientJsonldReadItemClientWithoutPrivateDataFamilySituation
  hasChild?: boolean | null
  readonly id: string
  readonly projectStatus?: ClientJsonldReadItemClientWithoutPrivateDataProjectStatus
}

export type ClientJsonldReadCollectionTpropertyExcelExportContextOneOfHydra =
  (typeof ClientJsonldReadCollectionTpropertyExcelExportContextOneOfHydra)[keyof typeof ClientJsonldReadCollectionTpropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadCollectionTpropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClientJsonldReadCollectionTpropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: ClientJsonldReadCollectionTpropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type ClientJsonldReadCollectionTpropertyExcelExportContext =
  | string
  | ClientJsonldReadCollectionTpropertyExcelExportContextOneOf

export interface ClientJsonldReadCollectionTpropertyExcelExport {
  readonly "@context"?: ClientJsonldReadCollectionTpropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  firstName?: string | null
  lastName?: string | null
  emails?: string[]
  phoneNumbers?: string[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ClientJsonldReadCollectionInterbuCivility =
  (typeof ClientJsonldReadCollectionInterbuCivility)[keyof typeof ClientJsonldReadCollectionInterbuCivility]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadCollectionInterbuCivility = {
  MALE: "MALE",
  FEMALE: "FEMALE",
} as const

export type ClientJsonldReadCollectionInterbuContextOneOfHydra =
  (typeof ClientJsonldReadCollectionInterbuContextOneOfHydra)[keyof typeof ClientJsonldReadCollectionInterbuContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadCollectionInterbuContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClientJsonldReadCollectionInterbuContextOneOf = {
  "@vocab": string
  hydra: ClientJsonldReadCollectionInterbuContextOneOfHydra
  [key: string]: any
}

export type ClientJsonldReadCollectionInterbuContext =
  | string
  | ClientJsonldReadCollectionInterbuContextOneOf

export interface ClientJsonldReadCollectionInterbu {
  readonly "@context"?: ClientJsonldReadCollectionInterbuContext
  readonly "@id"?: string
  readonly "@type"?: string
  civility?: ClientJsonldReadCollectionInterbuCivility
  firstName?: string | null
  lastName?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type ClientJsonldReadCollectionClientAccessRequestProjectStatus =
  (typeof ClientJsonldReadCollectionClientAccessRequestProjectStatus)[keyof typeof ClientJsonldReadCollectionClientAccessRequestProjectStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadCollectionClientAccessRequestProjectStatus = {
  FINISHED: "FINISHED",
  IN_PROGRESS: "IN_PROGRESS",
  ABANDONED: "ABANDONED",
} as const

export type ClientJsonldReadCollectionClientAccessRequestProfileType =
  (typeof ClientJsonldReadCollectionClientAccessRequestProfileType)[keyof typeof ClientJsonldReadCollectionClientAccessRequestProfileType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadCollectionClientAccessRequestProfileType = {
  PRIVATE_INDIVIDUAL: "PRIVATE_INDIVIDUAL",
  INVESTOR: "INVESTOR",
  INSTITUTIONAL_INVESTOR: "INSTITUTIONAL_INVESTOR",
  JOINT_OWNERSHIP: "JOINT_OWNERSHIP",
  PROPERTY_TRADER: "PROPERTY_TRADER",
  NOTARY: "NOTARY",
  INTERMEDIATE: "INTERMEDIATE",
  COUNTERPART: "COUNTERPART",
} as const

export type ClientJsonldReadCollectionClientAccessRequestCivility =
  (typeof ClientJsonldReadCollectionClientAccessRequestCivility)[keyof typeof ClientJsonldReadCollectionClientAccessRequestCivility]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadCollectionClientAccessRequestCivility = {
  MALE: "MALE",
  FEMALE: "FEMALE",
} as const

export interface ClientJsonldReadCollectionClientAccessRequest {
  readonly "@context"?: ClientJsonldReadCollectionClientAccessRequestContext
  readonly "@id"?: string
  readonly "@type"?: string
  civility?: ClientJsonldReadCollectionClientAccessRequestCivility
  firstName?: string | null
  lastName?: string | null
  profileType?: ClientJsonldReadCollectionClientAccessRequestProfileType
  readonly id: string
  createdAt?: string
  readonly projectStatus?: ClientJsonldReadCollectionClientAccessRequestProjectStatus
}

export type ClientJsonldReadCollectionClientAccessRequestContextOneOfHydra =
  (typeof ClientJsonldReadCollectionClientAccessRequestContextOneOfHydra)[keyof typeof ClientJsonldReadCollectionClientAccessRequestContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadCollectionClientAccessRequestContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClientJsonldReadCollectionClientAccessRequestContextOneOf = {
  "@vocab": string
  hydra: ClientJsonldReadCollectionClientAccessRequestContextOneOfHydra
  [key: string]: any
}

export type ClientJsonldReadCollectionClientAccessRequestContext =
  | string
  | ClientJsonldReadCollectionClientAccessRequestContextOneOf

export type ClientJsonldReadCollectionClientProjectStatus =
  (typeof ClientJsonldReadCollectionClientProjectStatus)[keyof typeof ClientJsonldReadCollectionClientProjectStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadCollectionClientProjectStatus = {
  FINISHED: "FINISHED",
  IN_PROGRESS: "IN_PROGRESS",
  ABANDONED: "ABANDONED",
} as const

export type ClientJsonldReadCollectionClientVisibility =
  (typeof ClientJsonldReadCollectionClientVisibility)[keyof typeof ClientJsonldReadCollectionClientVisibility]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadCollectionClientVisibility = {
  NOT_CONFIDENTIAL: "NOT_CONFIDENTIAL",
  CONFIDENTIAL: "CONFIDENTIAL",
} as const

export type ClientJsonldReadCollectionClientUser = UserJsonldReadCollectionClient | null

export type ClientJsonldReadCollectionClientBusinessUnit =
  BusinessUnitJsonldReadCollectionClient | null

export type ClientJsonldReadCollectionClientProfileType =
  (typeof ClientJsonldReadCollectionClientProfileType)[keyof typeof ClientJsonldReadCollectionClientProfileType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadCollectionClientProfileType = {
  PRIVATE_INDIVIDUAL: "PRIVATE_INDIVIDUAL",
  INVESTOR: "INVESTOR",
  INSTITUTIONAL_INVESTOR: "INSTITUTIONAL_INVESTOR",
  JOINT_OWNERSHIP: "JOINT_OWNERSHIP",
  PROPERTY_TRADER: "PROPERTY_TRADER",
  NOTARY: "NOTARY",
  INTERMEDIATE: "INTERMEDIATE",
  COUNTERPART: "COUNTERPART",
} as const

export type ClientJsonldReadCollectionClientCivility =
  (typeof ClientJsonldReadCollectionClientCivility)[keyof typeof ClientJsonldReadCollectionClientCivility]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientJsonldReadCollectionClientCivility = {
  MALE: "MALE",
  FEMALE: "FEMALE",
} as const

export interface ClientJsonldReadCollectionClient {
  readonly "@id"?: string
  readonly "@type"?: string
  civility?: ClientJsonldReadCollectionClientCivility
  firstName?: string | null
  lastName?: string | null
  profileType?: ClientJsonldReadCollectionClientProfileType
  businessUnit?: ClientJsonldReadCollectionClientBusinessUnit
  user?: ClientJsonldReadCollectionClientUser
  visibility: ClientJsonldReadCollectionClientVisibility
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
  readonly projectStatus?: ClientJsonldReadCollectionClientProjectStatus
}

export type ClientLogsOutputJsonldContextOneOfHydra =
  (typeof ClientLogsOutputJsonldContextOneOfHydra)[keyof typeof ClientLogsOutputJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientLogsOutputJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type ClientLogsOutputJsonldContextOneOf = {
  "@vocab": string
  hydra: ClientLogsOutputJsonldContextOneOfHydra
  [key: string]: any
}

export type ClientLogsOutputJsonldContext = string | ClientLogsOutputJsonldContextOneOf

export interface ClientLogsOutputJsonld {
  readonly "@context"?: ClientLogsOutputJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  logs?: LogJsonld[]
}

export type CircleJsonldReadItemClientWithoutPrivateDataContextOneOfHydra =
  (typeof CircleJsonldReadItemClientWithoutPrivateDataContextOneOfHydra)[keyof typeof CircleJsonldReadItemClientWithoutPrivateDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CircleJsonldReadItemClientWithoutPrivateDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CircleJsonldReadItemClientWithoutPrivateDataContextOneOf = {
  "@vocab": string
  hydra: CircleJsonldReadItemClientWithoutPrivateDataContextOneOfHydra
  [key: string]: any
}

export type CircleJsonldReadItemClientWithoutPrivateDataContext =
  | string
  | CircleJsonldReadItemClientWithoutPrivateDataContextOneOf

export interface CircleJsonldReadItemClientWithoutPrivateData {
  readonly "@context"?: CircleJsonldReadItemClientWithoutPrivateDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
}

export type CircleJsonldReadCollectionCircleCountriesItem =
  (typeof CircleJsonldReadCollectionCircleCountriesItem)[keyof typeof CircleJsonldReadCollectionCircleCountriesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CircleJsonldReadCollectionCircleCountriesItem = {
  af: "af",
  ax: "ax",
  al: "al",
  dz: "dz",
  as: "as",
  ad: "ad",
  ao: "ao",
  ai: "ai",
  aq: "aq",
  ag: "ag",
  ar: "ar",
  am: "am",
  aw: "aw",
  au: "au",
  at: "at",
  az: "az",
  bs: "bs",
  bh: "bh",
  bd: "bd",
  bb: "bb",
  by: "by",
  be: "be",
  bz: "bz",
  bj: "bj",
  bm: "bm",
  bt: "bt",
  bo: "bo",
  ba: "ba",
  bw: "bw",
  bv: "bv",
  br: "br",
  io: "io",
  vg: "vg",
  bn: "bn",
  bg: "bg",
  bf: "bf",
  bi: "bi",
  kh: "kh",
  cm: "cm",
  ca: "ca",
  cv: "cv",
  bq: "bq",
  ky: "ky",
  cf: "cf",
  td: "td",
  cl: "cl",
  cn: "cn",
  cx: "cx",
  cc: "cc",
  co: "co",
  km: "km",
  ck: "ck",
  cr: "cr",
  ci: "ci",
  hr: "hr",
  cu: "cu",
  cw: "cw",
  cy: "cy",
  cz: "cz",
  cd: "cd",
  dk: "dk",
  dj: "dj",
  dm: "dm",
  do: "do",
  ec: "ec",
  eg: "eg",
  sv: "sv",
  gq: "gq",
  er: "er",
  ee: "ee",
  sz: "sz",
  et: "et",
  fk: "fk",
  fo: "fo",
  fj: "fj",
  fi: "fi",
  fr: "fr",
  gf: "gf",
  pf: "pf",
  tf: "tf",
  ga: "ga",
  gm: "gm",
  ge: "ge",
  de: "de",
  gh: "gh",
  gi: "gi",
  gr: "gr",
  gl: "gl",
  gd: "gd",
  gp: "gp",
  gu: "gu",
  gt: "gt",
  gg: "gg",
  gn: "gn",
  gw: "gw",
  gy: "gy",
  ht: "ht",
  hm: "hm",
  hn: "hn",
  hk: "hk",
  hu: "hu",
  is: "is",
  in: "in",
  id: "id",
  ir: "ir",
  iq: "iq",
  ie: "ie",
  im: "im",
  il: "il",
  it: "it",
  jm: "jm",
  jp: "jp",
  je: "je",
  jo: "jo",
  kz: "kz",
  ke: "ke",
  ki: "ki",
  xk: "xk",
  kw: "kw",
  kg: "kg",
  la: "la",
  lv: "lv",
  lb: "lb",
  ls: "ls",
  lr: "lr",
  ly: "ly",
  li: "li",
  lt: "lt",
  lu: "lu",
  mo: "mo",
  mg: "mg",
  mw: "mw",
  my: "my",
  mv: "mv",
  ml: "ml",
  mt: "mt",
  mh: "mh",
  mq: "mq",
  mr: "mr",
  mu: "mu",
  yt: "yt",
  mx: "mx",
  fm: "fm",
  md: "md",
  mc: "mc",
  mn: "mn",
  me: "me",
  ms: "ms",
  ma: "ma",
  mz: "mz",
  mm: "mm",
  na: "na",
  nr: "nr",
  np: "np",
  nl: "nl",
  nc: "nc",
  nz: "nz",
  ni: "ni",
  ne: "ne",
  ng: "ng",
  nu: "nu",
  nf: "nf",
  kp: "kp",
  mk: "mk",
  mp: "mp",
  no: "no",
  om: "om",
  pk: "pk",
  pw: "pw",
  ps: "ps",
  pa: "pa",
  pg: "pg",
  py: "py",
  pe: "pe",
  ph: "ph",
  pn: "pn",
  pl: "pl",
  pt: "pt",
  pr: "pr",
  qa: "qa",
  cg: "cg",
  re: "re",
  ro: "ro",
  ru: "ru",
  rw: "rw",
  bl: "bl",
  sh: "sh",
  kn: "kn",
  lc: "lc",
  mf: "mf",
  pm: "pm",
  vc: "vc",
  ws: "ws",
  sm: "sm",
  st: "st",
  sa: "sa",
  sn: "sn",
  rs: "rs",
  sc: "sc",
  sl: "sl",
  sg: "sg",
  sx: "sx",
  sk: "sk",
  si: "si",
  sb: "sb",
  so: "so",
  za: "za",
  gs: "gs",
  kr: "kr",
  ss: "ss",
  es: "es",
  lk: "lk",
  sd: "sd",
  sr: "sr",
  sj: "sj",
  se: "se",
  ch: "ch",
  sy: "sy",
  tw: "tw",
  tj: "tj",
  tz: "tz",
  th: "th",
  tl: "tl",
  tg: "tg",
  tk: "tk",
  to: "to",
  tt: "tt",
  tn: "tn",
  tr: "tr",
  tm: "tm",
  tc: "tc",
  tv: "tv",
  ug: "ug",
  ua: "ua",
  ae: "ae",
  gb: "gb",
  us: "us",
  um: "um",
  vi: "vi",
  uy: "uy",
  uz: "uz",
  vu: "vu",
  va: "va",
  ve: "ve",
  vn: "vn",
  wf: "wf",
  eh: "eh",
  ye: "ye",
  zm: "zm",
  zw: "zw",
} as const

export interface CircleJsonldReadCollectionCircle {
  readonly "@id"?: string
  readonly "@type"?: string
  countries: CircleJsonldReadCollectionCircleCountriesItem[]
  name: string
  readonly id: string
}

export type CircleJsonldCountriesItem =
  (typeof CircleJsonldCountriesItem)[keyof typeof CircleJsonldCountriesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CircleJsonldCountriesItem = {
  af: "af",
  ax: "ax",
  al: "al",
  dz: "dz",
  as: "as",
  ad: "ad",
  ao: "ao",
  ai: "ai",
  aq: "aq",
  ag: "ag",
  ar: "ar",
  am: "am",
  aw: "aw",
  au: "au",
  at: "at",
  az: "az",
  bs: "bs",
  bh: "bh",
  bd: "bd",
  bb: "bb",
  by: "by",
  be: "be",
  bz: "bz",
  bj: "bj",
  bm: "bm",
  bt: "bt",
  bo: "bo",
  ba: "ba",
  bw: "bw",
  bv: "bv",
  br: "br",
  io: "io",
  vg: "vg",
  bn: "bn",
  bg: "bg",
  bf: "bf",
  bi: "bi",
  kh: "kh",
  cm: "cm",
  ca: "ca",
  cv: "cv",
  bq: "bq",
  ky: "ky",
  cf: "cf",
  td: "td",
  cl: "cl",
  cn: "cn",
  cx: "cx",
  cc: "cc",
  co: "co",
  km: "km",
  ck: "ck",
  cr: "cr",
  ci: "ci",
  hr: "hr",
  cu: "cu",
  cw: "cw",
  cy: "cy",
  cz: "cz",
  cd: "cd",
  dk: "dk",
  dj: "dj",
  dm: "dm",
  do: "do",
  ec: "ec",
  eg: "eg",
  sv: "sv",
  gq: "gq",
  er: "er",
  ee: "ee",
  sz: "sz",
  et: "et",
  fk: "fk",
  fo: "fo",
  fj: "fj",
  fi: "fi",
  fr: "fr",
  gf: "gf",
  pf: "pf",
  tf: "tf",
  ga: "ga",
  gm: "gm",
  ge: "ge",
  de: "de",
  gh: "gh",
  gi: "gi",
  gr: "gr",
  gl: "gl",
  gd: "gd",
  gp: "gp",
  gu: "gu",
  gt: "gt",
  gg: "gg",
  gn: "gn",
  gw: "gw",
  gy: "gy",
  ht: "ht",
  hm: "hm",
  hn: "hn",
  hk: "hk",
  hu: "hu",
  is: "is",
  in: "in",
  id: "id",
  ir: "ir",
  iq: "iq",
  ie: "ie",
  im: "im",
  il: "il",
  it: "it",
  jm: "jm",
  jp: "jp",
  je: "je",
  jo: "jo",
  kz: "kz",
  ke: "ke",
  ki: "ki",
  xk: "xk",
  kw: "kw",
  kg: "kg",
  la: "la",
  lv: "lv",
  lb: "lb",
  ls: "ls",
  lr: "lr",
  ly: "ly",
  li: "li",
  lt: "lt",
  lu: "lu",
  mo: "mo",
  mg: "mg",
  mw: "mw",
  my: "my",
  mv: "mv",
  ml: "ml",
  mt: "mt",
  mh: "mh",
  mq: "mq",
  mr: "mr",
  mu: "mu",
  yt: "yt",
  mx: "mx",
  fm: "fm",
  md: "md",
  mc: "mc",
  mn: "mn",
  me: "me",
  ms: "ms",
  ma: "ma",
  mz: "mz",
  mm: "mm",
  na: "na",
  nr: "nr",
  np: "np",
  nl: "nl",
  nc: "nc",
  nz: "nz",
  ni: "ni",
  ne: "ne",
  ng: "ng",
  nu: "nu",
  nf: "nf",
  kp: "kp",
  mk: "mk",
  mp: "mp",
  no: "no",
  om: "om",
  pk: "pk",
  pw: "pw",
  ps: "ps",
  pa: "pa",
  pg: "pg",
  py: "py",
  pe: "pe",
  ph: "ph",
  pn: "pn",
  pl: "pl",
  pt: "pt",
  pr: "pr",
  qa: "qa",
  cg: "cg",
  re: "re",
  ro: "ro",
  ru: "ru",
  rw: "rw",
  bl: "bl",
  sh: "sh",
  kn: "kn",
  lc: "lc",
  mf: "mf",
  pm: "pm",
  vc: "vc",
  ws: "ws",
  sm: "sm",
  st: "st",
  sa: "sa",
  sn: "sn",
  rs: "rs",
  sc: "sc",
  sl: "sl",
  sg: "sg",
  sx: "sx",
  sk: "sk",
  si: "si",
  sb: "sb",
  so: "so",
  za: "za",
  gs: "gs",
  kr: "kr",
  ss: "ss",
  es: "es",
  lk: "lk",
  sd: "sd",
  sr: "sr",
  sj: "sj",
  se: "se",
  ch: "ch",
  sy: "sy",
  tw: "tw",
  tj: "tj",
  tz: "tz",
  th: "th",
  tl: "tl",
  tg: "tg",
  tk: "tk",
  to: "to",
  tt: "tt",
  tn: "tn",
  tr: "tr",
  tm: "tm",
  tc: "tc",
  tv: "tv",
  ug: "ug",
  ua: "ua",
  ae: "ae",
  gb: "gb",
  us: "us",
  um: "um",
  vi: "vi",
  uy: "uy",
  uz: "uz",
  vu: "vu",
  va: "va",
  ve: "ve",
  vn: "vn",
  wf: "wf",
  eh: "eh",
  ye: "ye",
  zm: "zm",
  zw: "zw",
} as const

export interface CircleJsonld {
  readonly "@context"?: CircleJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  countries: CircleJsonldCountriesItem[]
  name: string
  clients?: string[]
  readonly id: string
}

export type CircleJsonldContextOneOfHydra =
  (typeof CircleJsonldContextOneOfHydra)[keyof typeof CircleJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CircleJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type CircleJsonldContextOneOf = {
  "@vocab": string
  hydra: CircleJsonldContextOneOfHydra
  [key: string]: any
}

export type CircleJsonldContext = string | CircleJsonldContextOneOf

export interface CirclePostCircleInputJsonld {
  countries: string[]
  translations?: MandatoryTranslationsDTOJsonld
}

export type CirclePatchCircleInputTranslations = OptionalTranslationsDTO | null

export interface CirclePatchCircleInput {
  countries?: string[] | null
  translations?: CirclePatchCircleInputTranslations
}

export type CircleCircleWithTranslationsDTOTranslations = {
  [key: string]: { [key: string]: string }
}

export type CircleCircleWithTranslationsDTOCountriesItem =
  (typeof CircleCircleWithTranslationsDTOCountriesItem)[keyof typeof CircleCircleWithTranslationsDTOCountriesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CircleCircleWithTranslationsDTOCountriesItem = {
  af: "af",
  ax: "ax",
  al: "al",
  dz: "dz",
  as: "as",
  ad: "ad",
  ao: "ao",
  ai: "ai",
  aq: "aq",
  ag: "ag",
  ar: "ar",
  am: "am",
  aw: "aw",
  au: "au",
  at: "at",
  az: "az",
  bs: "bs",
  bh: "bh",
  bd: "bd",
  bb: "bb",
  by: "by",
  be: "be",
  bz: "bz",
  bj: "bj",
  bm: "bm",
  bt: "bt",
  bo: "bo",
  ba: "ba",
  bw: "bw",
  bv: "bv",
  br: "br",
  io: "io",
  vg: "vg",
  bn: "bn",
  bg: "bg",
  bf: "bf",
  bi: "bi",
  kh: "kh",
  cm: "cm",
  ca: "ca",
  cv: "cv",
  bq: "bq",
  ky: "ky",
  cf: "cf",
  td: "td",
  cl: "cl",
  cn: "cn",
  cx: "cx",
  cc: "cc",
  co: "co",
  km: "km",
  ck: "ck",
  cr: "cr",
  ci: "ci",
  hr: "hr",
  cu: "cu",
  cw: "cw",
  cy: "cy",
  cz: "cz",
  cd: "cd",
  dk: "dk",
  dj: "dj",
  dm: "dm",
  do: "do",
  ec: "ec",
  eg: "eg",
  sv: "sv",
  gq: "gq",
  er: "er",
  ee: "ee",
  sz: "sz",
  et: "et",
  fk: "fk",
  fo: "fo",
  fj: "fj",
  fi: "fi",
  fr: "fr",
  gf: "gf",
  pf: "pf",
  tf: "tf",
  ga: "ga",
  gm: "gm",
  ge: "ge",
  de: "de",
  gh: "gh",
  gi: "gi",
  gr: "gr",
  gl: "gl",
  gd: "gd",
  gp: "gp",
  gu: "gu",
  gt: "gt",
  gg: "gg",
  gn: "gn",
  gw: "gw",
  gy: "gy",
  ht: "ht",
  hm: "hm",
  hn: "hn",
  hk: "hk",
  hu: "hu",
  is: "is",
  in: "in",
  id: "id",
  ir: "ir",
  iq: "iq",
  ie: "ie",
  im: "im",
  il: "il",
  it: "it",
  jm: "jm",
  jp: "jp",
  je: "je",
  jo: "jo",
  kz: "kz",
  ke: "ke",
  ki: "ki",
  xk: "xk",
  kw: "kw",
  kg: "kg",
  la: "la",
  lv: "lv",
  lb: "lb",
  ls: "ls",
  lr: "lr",
  ly: "ly",
  li: "li",
  lt: "lt",
  lu: "lu",
  mo: "mo",
  mg: "mg",
  mw: "mw",
  my: "my",
  mv: "mv",
  ml: "ml",
  mt: "mt",
  mh: "mh",
  mq: "mq",
  mr: "mr",
  mu: "mu",
  yt: "yt",
  mx: "mx",
  fm: "fm",
  md: "md",
  mc: "mc",
  mn: "mn",
  me: "me",
  ms: "ms",
  ma: "ma",
  mz: "mz",
  mm: "mm",
  na: "na",
  nr: "nr",
  np: "np",
  nl: "nl",
  nc: "nc",
  nz: "nz",
  ni: "ni",
  ne: "ne",
  ng: "ng",
  nu: "nu",
  nf: "nf",
  kp: "kp",
  mk: "mk",
  mp: "mp",
  no: "no",
  om: "om",
  pk: "pk",
  pw: "pw",
  ps: "ps",
  pa: "pa",
  pg: "pg",
  py: "py",
  pe: "pe",
  ph: "ph",
  pn: "pn",
  pl: "pl",
  pt: "pt",
  pr: "pr",
  qa: "qa",
  cg: "cg",
  re: "re",
  ro: "ro",
  ru: "ru",
  rw: "rw",
  bl: "bl",
  sh: "sh",
  kn: "kn",
  lc: "lc",
  mf: "mf",
  pm: "pm",
  vc: "vc",
  ws: "ws",
  sm: "sm",
  st: "st",
  sa: "sa",
  sn: "sn",
  rs: "rs",
  sc: "sc",
  sl: "sl",
  sg: "sg",
  sx: "sx",
  sk: "sk",
  si: "si",
  sb: "sb",
  so: "so",
  za: "za",
  gs: "gs",
  kr: "kr",
  ss: "ss",
  es: "es",
  lk: "lk",
  sd: "sd",
  sr: "sr",
  sj: "sj",
  se: "se",
  ch: "ch",
  sy: "sy",
  tw: "tw",
  tj: "tj",
  tz: "tz",
  th: "th",
  tl: "tl",
  tg: "tg",
  tk: "tk",
  to: "to",
  tt: "tt",
  tn: "tn",
  tr: "tr",
  tm: "tm",
  tc: "tc",
  tv: "tv",
  ug: "ug",
  ua: "ua",
  ae: "ae",
  gb: "gb",
  us: "us",
  um: "um",
  vi: "vi",
  uy: "uy",
  uz: "uz",
  vu: "vu",
  va: "va",
  ve: "ve",
  vn: "vn",
  wf: "wf",
  eh: "eh",
  ye: "ye",
  zm: "zm",
  zw: "zw",
} as const

export interface CircleCircleWithTranslationsDTO {
  countries: CircleCircleWithTranslationsDTOCountriesItem[]
  translations: CircleCircleWithTranslationsDTOTranslations
}

export interface BusinessUnitJsonldUserReadItem {
  readonly "@context"?: BusinessUnitJsonldUserReadItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type BusinessUnitJsonldUserReadItemContextOneOfHydra =
  (typeof BusinessUnitJsonldUserReadItemContextOneOfHydra)[keyof typeof BusinessUnitJsonldUserReadItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessUnitJsonldUserReadItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type BusinessUnitJsonldUserReadItemContextOneOf = {
  "@vocab": string
  hydra: BusinessUnitJsonldUserReadItemContextOneOfHydra
  [key: string]: any
}

export type BusinessUnitJsonldUserReadItemContext =
  | string
  | BusinessUnitJsonldUserReadItemContextOneOf

export type BusinessUnitJsonldUserReadCollectionContextOneOfHydra =
  (typeof BusinessUnitJsonldUserReadCollectionContextOneOfHydra)[keyof typeof BusinessUnitJsonldUserReadCollectionContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessUnitJsonldUserReadCollectionContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type BusinessUnitJsonldUserReadCollectionContextOneOf = {
  "@vocab": string
  hydra: BusinessUnitJsonldUserReadCollectionContextOneOfHydra
  [key: string]: any
}

export type BusinessUnitJsonldUserReadCollectionContext =
  | string
  | BusinessUnitJsonldUserReadCollectionContextOneOf

export interface BusinessUnitJsonldUserReadCollection {
  readonly "@context"?: BusinessUnitJsonldUserReadCollectionContext
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type BusinessUnitJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra =
  (typeof BusinessUnitJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra)[keyof typeof BusinessUnitJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessUnitJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type BusinessUnitJsonldReadItemTpropertyWithoutPrivateDataContextOneOf = {
  "@vocab": string
  hydra: BusinessUnitJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra
  [key: string]: any
}

export type BusinessUnitJsonldReadItemTpropertyWithoutPrivateDataContext =
  | string
  | BusinessUnitJsonldReadItemTpropertyWithoutPrivateDataContextOneOf

export interface BusinessUnitJsonldReadItemTpropertyWithoutPrivateData {
  readonly "@context"?: BusinessUnitJsonldReadItemTpropertyWithoutPrivateDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
  createdAt?: string
}

export interface BusinessUnitJsonldReadItemInterbu {
  readonly "@context"?: BusinessUnitJsonldReadItemInterbuContext
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type BusinessUnitJsonldReadItemInterbuContextOneOfHydra =
  (typeof BusinessUnitJsonldReadItemInterbuContextOneOfHydra)[keyof typeof BusinessUnitJsonldReadItemInterbuContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessUnitJsonldReadItemInterbuContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type BusinessUnitJsonldReadItemInterbuContextOneOf = {
  "@vocab": string
  hydra: BusinessUnitJsonldReadItemInterbuContextOneOfHydra
  [key: string]: any
}

export type BusinessUnitJsonldReadItemInterbuContext =
  | string
  | BusinessUnitJsonldReadItemInterbuContextOneOf

export type BusinessUnitJsonldReadItemClientWithoutPrivateDataContextOneOfHydra =
  (typeof BusinessUnitJsonldReadItemClientWithoutPrivateDataContextOneOfHydra)[keyof typeof BusinessUnitJsonldReadItemClientWithoutPrivateDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessUnitJsonldReadItemClientWithoutPrivateDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type BusinessUnitJsonldReadItemClientWithoutPrivateDataContextOneOf = {
  "@vocab": string
  hydra: BusinessUnitJsonldReadItemClientWithoutPrivateDataContextOneOfHydra
  [key: string]: any
}

export type BusinessUnitJsonldReadItemClientWithoutPrivateDataContext =
  | string
  | BusinessUnitJsonldReadItemClientWithoutPrivateDataContextOneOf

export interface BusinessUnitJsonldReadItemClientWithoutPrivateData {
  readonly "@context"?: BusinessUnitJsonldReadItemClientWithoutPrivateDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
}

export type BusinessUnitJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOfHydra =
  (typeof BusinessUnitJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOfHydra)[keyof typeof BusinessUnitJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessUnitJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type BusinessUnitJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOf = {
  "@vocab": string
  hydra: BusinessUnitJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOfHydra
  [key: string]: any
}

export type BusinessUnitJsonldReadCollectionTpropertyWithoutPrivateDataContext =
  | string
  | BusinessUnitJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOf

export interface BusinessUnitJsonldReadCollectionTpropertyWithoutPrivateData {
  readonly "@context"?: BusinessUnitJsonldReadCollectionTpropertyWithoutPrivateDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
  createdAt?: string
}

export interface BusinessUnitJsonldReadCollectionTpropertyExcelExport {
  readonly "@context"?: BusinessUnitJsonldReadCollectionTpropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type BusinessUnitJsonldReadCollectionTpropertyExcelExportContextOneOfHydra =
  (typeof BusinessUnitJsonldReadCollectionTpropertyExcelExportContextOneOfHydra)[keyof typeof BusinessUnitJsonldReadCollectionTpropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessUnitJsonldReadCollectionTpropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type BusinessUnitJsonldReadCollectionTpropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: BusinessUnitJsonldReadCollectionTpropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type BusinessUnitJsonldReadCollectionTpropertyExcelExportContext =
  | string
  | BusinessUnitJsonldReadCollectionTpropertyExcelExportContextOneOf

export interface BusinessUnitJsonldReadCollectionProject {
  readonly "@context"?: BusinessUnitJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type BusinessUnitJsonldReadCollectionProjectContextOneOfHydra =
  (typeof BusinessUnitJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof BusinessUnitJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessUnitJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type BusinessUnitJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: BusinessUnitJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type BusinessUnitJsonldReadCollectionProjectContext =
  | string
  | BusinessUnitJsonldReadCollectionProjectContextOneOf

export interface BusinessUnitJsonldReadCollectionNames {
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
}

export interface BusinessUnitJsonldReadCollectionInterbu {
  readonly "@context"?: BusinessUnitJsonldReadCollectionInterbuContext
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type BusinessUnitJsonldReadCollectionInterbuContextOneOfHydra =
  (typeof BusinessUnitJsonldReadCollectionInterbuContextOneOfHydra)[keyof typeof BusinessUnitJsonldReadCollectionInterbuContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessUnitJsonldReadCollectionInterbuContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type BusinessUnitJsonldReadCollectionInterbuContextOneOf = {
  "@vocab": string
  hydra: BusinessUnitJsonldReadCollectionInterbuContextOneOfHydra
  [key: string]: any
}

export type BusinessUnitJsonldReadCollectionInterbuContext =
  | string
  | BusinessUnitJsonldReadCollectionInterbuContextOneOf

export type BusinessUnitJsonldReadCollectionClientAccessRequestContextOneOfHydra =
  (typeof BusinessUnitJsonldReadCollectionClientAccessRequestContextOneOfHydra)[keyof typeof BusinessUnitJsonldReadCollectionClientAccessRequestContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessUnitJsonldReadCollectionClientAccessRequestContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type BusinessUnitJsonldReadCollectionClientAccessRequestContextOneOf = {
  "@vocab": string
  hydra: BusinessUnitJsonldReadCollectionClientAccessRequestContextOneOfHydra
  [key: string]: any
}

export type BusinessUnitJsonldReadCollectionClientAccessRequestContext =
  | string
  | BusinessUnitJsonldReadCollectionClientAccessRequestContextOneOf

export interface BusinessUnitJsonldReadCollectionClientAccessRequest {
  readonly "@context"?: BusinessUnitJsonldReadCollectionClientAccessRequestContext
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
  createdAt?: string
}

export type BusinessUnitJsonldReadCollectionClientContextOneOfHydra =
  (typeof BusinessUnitJsonldReadCollectionClientContextOneOfHydra)[keyof typeof BusinessUnitJsonldReadCollectionClientContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessUnitJsonldReadCollectionClientContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type BusinessUnitJsonldReadCollectionClientContextOneOf = {
  "@vocab": string
  hydra: BusinessUnitJsonldReadCollectionClientContextOneOfHydra
  [key: string]: any
}

export type BusinessUnitJsonldReadCollectionClientContext =
  | string
  | BusinessUnitJsonldReadCollectionClientContextOneOf

export interface BusinessUnitJsonldReadCollectionClient {
  readonly "@context"?: BusinessUnitJsonldReadCollectionClientContext
  readonly "@id"?: string
  readonly "@type"?: string
  name: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type AgencySocietyDTOJsonldContextOneOfHydra =
  (typeof AgencySocietyDTOJsonldContextOneOfHydra)[keyof typeof AgencySocietyDTOJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencySocietyDTOJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AgencySocietyDTOJsonldContextOneOf = {
  "@vocab": string
  hydra: AgencySocietyDTOJsonldContextOneOfHydra
  [key: string]: any
}

export type AgencySocietyDTOJsonldContext = string | AgencySocietyDTOJsonldContextOneOf

export interface AgencySocietyDTOJsonld {
  readonly "@context"?: AgencySocietyDTOJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  id?: string
  agency?: string
  agencyOwner?: string
}

export interface AgencySocietyDTO {
  id?: string
  agency?: string
  agencyOwner?: string
}

export interface AgencyOwnerLegalsJsonldReadItemSocietyItem {
  readonly "@context"?: AgencyOwnerLegalsJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type AgencyOwnerLegalsJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof AgencyOwnerLegalsJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof AgencyOwnerLegalsJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyOwnerLegalsJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AgencyOwnerLegalsJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: AgencyOwnerLegalsJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type AgencyOwnerLegalsJsonldReadItemSocietyItemContext =
  | string
  | AgencyOwnerLegalsJsonldReadItemSocietyItemContextOneOf

export interface AgencyOwnerLegalsJsonld {
  readonly "@context"?: AgencyOwnerLegalsJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  amount?: number | null
  amountCurrency?: string
  name?: string
  type?: string | null
  value?: string | null
  agencyOwner?: RelAgencyOwnerLegalJsonld[]
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type AgencyOwnerLegalsJsonldContextOneOfHydra =
  (typeof AgencyOwnerLegalsJsonldContextOneOfHydra)[keyof typeof AgencyOwnerLegalsJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyOwnerLegalsJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AgencyOwnerLegalsJsonldContextOneOf = {
  "@vocab": string
  hydra: AgencyOwnerLegalsJsonldContextOneOfHydra
  [key: string]: any
}

export type AgencyOwnerLegalsJsonldContext = string | AgencyOwnerLegalsJsonldContextOneOf

export type AgencyMediaJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof AgencyMediaJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof AgencyMediaJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyMediaJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AgencyMediaJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: AgencyMediaJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type AgencyMediaJsonldReadItemSocietyItemContext =
  | string
  | AgencyMediaJsonldReadItemSocietyItemContextOneOf

export interface AgencyMediaJsonldReadItemSocietyItem {
  readonly "@context"?: AgencyMediaJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type AgencyMediaJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof AgencyMediaJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof AgencyMediaJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyMediaJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AgencyMediaJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: AgencyMediaJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type AgencyMediaJsonldReadItemCollaboratorItemContext =
  | string
  | AgencyMediaJsonldReadItemCollaboratorItemContextOneOf

export interface AgencyMediaJsonldReadItemCollaboratorItem {
  readonly "@context"?: AgencyMediaJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export interface AgencyMediaJsonldReadItemClusterItem {
  readonly "@context"?: AgencyMediaJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  readonly id: string
}

export type AgencyMediaJsonldReadItemClusterItemContextOneOfHydra =
  (typeof AgencyMediaJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof AgencyMediaJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyMediaJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AgencyMediaJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: AgencyMediaJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type AgencyMediaJsonldReadItemClusterItemContext =
  | string
  | AgencyMediaJsonldReadItemClusterItemContextOneOf

export type AgencyMediaJsonldReadItemAgencyItemUsage = RefLabelJsonldReadItemAgencyItem | null

export interface AgencyMediaJsonldReadItemAgencyItem {
  readonly "@context"?: AgencyMediaJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  agency?: AgencyJsonldReadItemAgencyItem
  media?: MediaJsonldReadItemAgencyItem
  order?: number | null
  isDefault?: boolean | null
  usage?: AgencyMediaJsonldReadItemAgencyItemUsage
  readonly id: string
  /** The media usage code */
  usageCode?: string | null
}

export type AgencyMediaJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof AgencyMediaJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof AgencyMediaJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyMediaJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AgencyMediaJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: AgencyMediaJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type AgencyMediaJsonldReadItemAgencyItemContext =
  | string
  | AgencyMediaJsonldReadItemAgencyItemContextOneOf

export type AgencyMediaJsonldReadCollectionAgencyUsage = RefLabelJsonldReadCollectionAgency | null

export type AgencyMediaJsonldReadCollectionAgencyContextOneOfHydra =
  (typeof AgencyMediaJsonldReadCollectionAgencyContextOneOfHydra)[keyof typeof AgencyMediaJsonldReadCollectionAgencyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyMediaJsonldReadCollectionAgencyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AgencyMediaJsonldReadCollectionAgencyContextOneOf = {
  "@vocab": string
  hydra: AgencyMediaJsonldReadCollectionAgencyContextOneOfHydra
  [key: string]: any
}

export type AgencyMediaJsonldReadCollectionAgencyContext =
  | string
  | AgencyMediaJsonldReadCollectionAgencyContextOneOf

export interface AgencyMediaJsonldReadCollectionAgency {
  readonly "@context"?: AgencyMediaJsonldReadCollectionAgencyContext
  readonly "@id"?: string
  readonly "@type"?: string
  agency?: AgencyJsonldReadCollectionAgency
  media?: MediaJsonldReadCollectionAgency
  order?: number | null
  isDefault?: boolean | null
  usage?: AgencyMediaJsonldReadCollectionAgencyUsage
  readonly id: string
  /** The media usage code */
  usageCode?: string | null
}

export type AgencyMediaJsonldContext = string | AgencyMediaJsonldContextOneOf

export interface AgencyMediaJsonld {
  readonly "@context"?: AgencyMediaJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  agency?: string
  media?: MediaJsonld
  order?: number | null
  isDefault?: boolean | null
  usage?: string | null
  readonly id: string
  /** The media usage code */
  usageCode?: string | null
}

export type AgencyMediaJsonldContextOneOfHydra =
  (typeof AgencyMediaJsonldContextOneOfHydra)[keyof typeof AgencyMediaJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyMediaJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AgencyMediaJsonldContextOneOf = {
  "@vocab": string
  hydra: AgencyMediaJsonldContextOneOfHydra
  [key: string]: any
}

export interface AgencyClusterDTOJsonld {
  readonly "@context"?: AgencyClusterDTOJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  id?: string
  isDefault?: boolean
  agency?: string
  cluster?: string
}

export type AgencyClusterDTOJsonldContextOneOfHydra =
  (typeof AgencyClusterDTOJsonldContextOneOfHydra)[keyof typeof AgencyClusterDTOJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyClusterDTOJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AgencyClusterDTOJsonldContextOneOf = {
  "@vocab": string
  hydra: AgencyClusterDTOJsonldContextOneOfHydra
  [key: string]: any
}

export type AgencyClusterDTOJsonldContext = string | AgencyClusterDTOJsonldContextOneOf

export interface AgencyClusterDTO {
  id?: string
  isDefault?: boolean
  agency?: string
  cluster?: string
}

export type AgencyJsonldReadItemSocietyItemStatus = RefLabelJsonldReadItemSocietyItem | null

export type AgencyJsonldReadItemSocietyItemContextOneOfHydra =
  (typeof AgencyJsonldReadItemSocietyItemContextOneOfHydra)[keyof typeof AgencyJsonldReadItemSocietyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyJsonldReadItemSocietyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AgencyJsonldReadItemSocietyItemContextOneOf = {
  "@vocab": string
  hydra: AgencyJsonldReadItemSocietyItemContextOneOfHydra
  [key: string]: any
}

export type AgencyJsonldReadItemSocietyItemContext =
  | string
  | AgencyJsonldReadItemSocietyItemContextOneOf

export interface AgencyJsonldReadItemSocietyItem {
  readonly "@context"?: AgencyJsonldReadItemSocietyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  name?: string
  status?: AgencyJsonldReadItemSocietyItemStatus
  contactDetails?: RelAgencyContactDetailsJsonldReadItemSocietyItem[]
  media?: AgencyMediaJsonldReadItemSocietyItem[]
  cluster?: RelAgencyClusterJsonldReadItemSocietyItem[]
  collaborator?: RelCollaboratorAgencyJsonldReadItemSocietyItem[]
  legal?: string | null
  officialName?: string | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type AgencyJsonldReadItemCollaboratorItemStatus =
  RefLabelJsonldReadItemCollaboratorItem | null

export interface AgencyJsonldReadItemCollaboratorItem {
  readonly "@context"?: AgencyJsonldReadItemCollaboratorItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  name?: string
  status?: AgencyJsonldReadItemCollaboratorItemStatus
  contactDetails?: RelAgencyContactDetailsJsonldReadItemCollaboratorItem[]
  activities?: RelAgencyActivityJsonldReadItemCollaboratorItem[]
  media?: AgencyMediaJsonldReadItemCollaboratorItem[]
  cluster?: RelAgencyClusterJsonldReadItemCollaboratorItem[]
  agencyOwner?: RelAgencySocietyJsonldReadItemCollaboratorItem[]
  legal?: string | null
  officialName?: string | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type AgencyJsonldReadItemCollaboratorItemContextOneOfHydra =
  (typeof AgencyJsonldReadItemCollaboratorItemContextOneOfHydra)[keyof typeof AgencyJsonldReadItemCollaboratorItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyJsonldReadItemCollaboratorItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AgencyJsonldReadItemCollaboratorItemContextOneOf = {
  "@vocab": string
  hydra: AgencyJsonldReadItemCollaboratorItemContextOneOfHydra
  [key: string]: any
}

export type AgencyJsonldReadItemCollaboratorItemContext =
  | string
  | AgencyJsonldReadItemCollaboratorItemContextOneOf

export type AgencyJsonldReadItemClusterItemStatus = RefLabelJsonldReadItemClusterItem | null

export interface AgencyJsonldReadItemClusterItem {
  readonly "@context"?: AgencyJsonldReadItemClusterItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  name?: string
  status?: AgencyJsonldReadItemClusterItemStatus
  contactDetails?: RelAgencyContactDetailsJsonldReadItemClusterItem[]
  media?: AgencyMediaJsonldReadItemClusterItem[]
  collaborator?: RelCollaboratorAgencyJsonldReadItemClusterItem[]
  legal?: string | null
  officialName?: string | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type AgencyJsonldReadItemClusterItemContextOneOfHydra =
  (typeof AgencyJsonldReadItemClusterItemContextOneOfHydra)[keyof typeof AgencyJsonldReadItemClusterItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyJsonldReadItemClusterItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AgencyJsonldReadItemClusterItemContextOneOf = {
  "@vocab": string
  hydra: AgencyJsonldReadItemClusterItemContextOneOfHydra
  [key: string]: any
}

export type AgencyJsonldReadItemClusterItemContext =
  | string
  | AgencyJsonldReadItemClusterItemContextOneOf

export type AgencyJsonldReadItemAgencyItemStatus = RefLabelJsonldReadItemAgencyItem | null

export interface AgencyJsonldReadItemAgencyItem {
  readonly "@context"?: AgencyJsonldReadItemAgencyItemContext
  readonly "@id"?: string
  readonly "@type"?: string
  name?: string
  status?: AgencyJsonldReadItemAgencyItemStatus
  contactDetails?: RelAgencyContactDetailsJsonldReadItemAgencyItem[]
  activities?: RelAgencyActivityJsonldReadItemAgencyItem[]
  media?: AgencyMediaJsonldReadItemAgencyItem[]
  cluster?: RelAgencyClusterJsonldReadItemAgencyItem[]
  collaborator?: RelCollaboratorAgencyJsonldReadItemAgencyItem[]
  agencyOwner?: RelAgencySocietyJsonldReadItemAgencyItem[]
  i18nText?: RelAgencyI18nTextJsonldReadItemAgencyItem[]
  legal?: string | null
  officialName?: string | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  nbAgencyOwner?: number
  nbCluster?: number
  nbCollaborator?: number
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type AgencyJsonldReadItemAgencyItemContextOneOfHydra =
  (typeof AgencyJsonldReadItemAgencyItemContextOneOfHydra)[keyof typeof AgencyJsonldReadItemAgencyItemContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyJsonldReadItemAgencyItemContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AgencyJsonldReadItemAgencyItemContextOneOf = {
  "@vocab": string
  hydra: AgencyJsonldReadItemAgencyItemContextOneOfHydra
  [key: string]: any
}

export type AgencyJsonldReadItemAgencyItemContext =
  | string
  | AgencyJsonldReadItemAgencyItemContextOneOf

export type AgencyJsonldReadCollectionAgencyExcelExportStatus =
  RefLabelJsonldReadCollectionAgencyExcelExport | null

export interface AgencyJsonldReadCollectionAgencyExcelExport {
  readonly "@id"?: string
  readonly "@type"?: string
  name?: string
  status?: AgencyJsonldReadCollectionAgencyExcelExportStatus
  contactDetails?: RelAgencyContactDetailsJsonldReadCollectionAgencyExcelExport[]
  activities?: RelAgencyActivityJsonldReadCollectionAgencyExcelExport[]
  i18nText?: RelAgencyI18nTextJsonldReadCollectionAgencyExcelExport[]
  legal?: string | null
  officialName?: string | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type AgencyJsonldReadCollectionAgencyStatus = RefLabelJsonldReadCollectionAgency | null

export type AgencyJsonldReadCollectionAgencyContextOneOfHydra =
  (typeof AgencyJsonldReadCollectionAgencyContextOneOfHydra)[keyof typeof AgencyJsonldReadCollectionAgencyContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyJsonldReadCollectionAgencyContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AgencyJsonldReadCollectionAgencyContextOneOf = {
  "@vocab": string
  hydra: AgencyJsonldReadCollectionAgencyContextOneOfHydra
  [key: string]: any
}

export type AgencyJsonldReadCollectionAgencyContext =
  | string
  | AgencyJsonldReadCollectionAgencyContextOneOf

export interface AgencyJsonldReadCollectionAgency {
  readonly "@id"?: string
  readonly "@type"?: string
  readonly "@context"?: AgencyJsonldReadCollectionAgencyContext
  name?: string
  status?: AgencyJsonldReadCollectionAgencyStatus
  contactDetails?: RelAgencyContactDetailsJsonldReadCollectionAgency[]
  activities?: RelAgencyActivityJsonldReadCollectionAgency[]
  media?: AgencyMediaJsonldReadCollectionAgency[]
  legal?: string | null
  officialName?: string | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  nbAgencyOwner?: number
  nbCluster?: number
  nbCollaborator?: number
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type AgencyJsonldContextOneOfHydra =
  (typeof AgencyJsonldContextOneOfHydra)[keyof typeof AgencyJsonldContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyJsonldContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AgencyJsonldContextOneOf = {
  "@vocab": string
  hydra: AgencyJsonldContextOneOfHydra
  [key: string]: any
}

export type AgencyJsonldContext = string | AgencyJsonldContextOneOf

export interface AgencyJsonld {
  readonly "@context"?: AgencyJsonldContext
  readonly "@id"?: string
  readonly "@type"?: string
  name?: string
  status?: string | null
  contactDetails?: RelAgencyContactDetailsJsonld[]
  activities?: RelAgencyActivityJsonld[]
  media?: AgencyMediaJsonld[]
  cluster?: RelAgencyClusterJsonld[]
  collaborator?: RelCollaboratorAgencyJsonld[]
  agencyOwner?: RelAgencySocietyJsonld[]
  i18nText?: RelAgencyI18nTextJsonld[]
  legal?: string | null
  officialName?: string | null
  accessLevel?: number | null
  accessLevelMin?: number | null
  accessLevelForced?: boolean
  nbAgencyOwner?: number
  nbCluster?: number
  nbCollaborator?: number
  internalSource?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export interface AgencyPostAgencyInputJsonld {
  name: string
  officialName?: string | null
  status?: string
  legal?: string | null
  contactDetails?: ContactDetailsDTOJsonld[] | null
  agencyOwner?: AgencySocietyDTOJsonld[] | null
  activities?: string[] | null
  collaborator?: CollaboratorAgencyDTOJsonld[] | null
  cluster?: AgencyClusterDTOJsonld[] | null
  accessLevel?: string | null
  accessLevelForced?: string | null
}

export interface AgencyPatchEntityMediaInput {
  datas?: EntityMediaDTO[]
}

export interface AgencyPatchAgencyInput {
  name?: string | null
  officialName?: string | null
  status?: string | null
  legal?: string | null
  contactDetails?: ContactDetailsDTO[] | null
  agencyOwner?: AgencySocietyDTO[] | null
  activities?: string[] | null
  collaborator?: CollaboratorAgencyDTO[] | null
  cluster?: AgencyClusterDTO[] | null
  accessLevel?: string | null
  accessLevelForced?: string | null
}

export interface AddressJsonldReadItemTpropertyWithoutPrivateData {
  readonly "@context"?: AddressJsonldReadItemTpropertyWithoutPrivateDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  city?: string | null
  readonly id: string
  createdAt?: string
}

export type AddressJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra =
  (typeof AddressJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra)[keyof typeof AddressJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AddressJsonldReadItemTpropertyWithoutPrivateDataContextOneOf = {
  "@vocab": string
  hydra: AddressJsonldReadItemTpropertyWithoutPrivateDataContextOneOfHydra
  [key: string]: any
}

export type AddressJsonldReadItemTpropertyWithoutPrivateDataContext =
  | string
  | AddressJsonldReadItemTpropertyWithoutPrivateDataContextOneOf

export type AddressJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOfHydra =
  (typeof AddressJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOfHydra)[keyof typeof AddressJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AddressJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOf = {
  "@vocab": string
  hydra: AddressJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOfHydra
  [key: string]: any
}

export type AddressJsonldReadCollectionTpropertyWithoutPrivateDataContext =
  | string
  | AddressJsonldReadCollectionTpropertyWithoutPrivateDataContextOneOf

export interface AddressJsonldReadCollectionTpropertyWithoutPrivateData {
  readonly "@context"?: AddressJsonldReadCollectionTpropertyWithoutPrivateDataContext
  readonly "@id"?: string
  readonly "@type"?: string
  city?: string | null
  readonly id: string
  createdAt?: string
}

export interface AddressJsonldReadCollectionTpropertyExcelExport {
  readonly "@context"?: AddressJsonldReadCollectionTpropertyExcelExportContext
  readonly "@id"?: string
  readonly "@type"?: string
  addressLine1?: string | null
  addressLine2?: string | null
  city?: string | null
  country?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

export type AddressJsonldReadCollectionTpropertyExcelExportContextOneOfHydra =
  (typeof AddressJsonldReadCollectionTpropertyExcelExportContextOneOfHydra)[keyof typeof AddressJsonldReadCollectionTpropertyExcelExportContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressJsonldReadCollectionTpropertyExcelExportContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AddressJsonldReadCollectionTpropertyExcelExportContextOneOf = {
  "@vocab": string
  hydra: AddressJsonldReadCollectionTpropertyExcelExportContextOneOfHydra
  [key: string]: any
}

export type AddressJsonldReadCollectionTpropertyExcelExportContext =
  | string
  | AddressJsonldReadCollectionTpropertyExcelExportContextOneOf

export type AddressJsonldReadCollectionProjectContextOneOfHydra =
  (typeof AddressJsonldReadCollectionProjectContextOneOfHydra)[keyof typeof AddressJsonldReadCollectionProjectContextOneOfHydra]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressJsonldReadCollectionProjectContextOneOfHydra = {
  httpwwww3orgnshydracore: "http://www.w3.org/ns/hydra/core#",
} as const

export type AddressJsonldReadCollectionProjectContextOneOf = {
  "@vocab": string
  hydra: AddressJsonldReadCollectionProjectContextOneOfHydra
  [key: string]: any
}

export type AddressJsonldReadCollectionProjectContext =
  | string
  | AddressJsonldReadCollectionProjectContextOneOf

export interface AddressJsonldReadCollectionProject {
  readonly "@context"?: AddressJsonldReadCollectionProjectContext
  readonly "@id"?: string
  readonly "@type"?: string
  city?: string | null
  readonly id: string
  createdAt?: string
  readonly updatedAt?: string
}

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

/**
 * Retrieves the collection of Agency resources.
 * @summary Retrieves the collection of Agency resources.
 */
export const getAgencies = (
  params?: GetAgenciesParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetAgencies200>(
    { url: `/api/agencies`, method: "get", params, signal },
    options
  )
}

export const getGetAgenciesQueryKey = (params?: GetAgenciesParams) => [
  `/api/agencies`,
  ...(params ? [params] : []),
]

export type GetAgenciesQueryResult = NonNullable<Awaited<ReturnType<typeof getAgencies>>>
export type GetAgenciesQueryError = unknown

export const useGetAgencies = <TData = Awaited<ReturnType<typeof getAgencies>>, TError = unknown>(
  params?: GetAgenciesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAgencies>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAgenciesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgencies>>> = ({ signal }) =>
    getAgencies(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getAgencies>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Creates a Agency resource.
 * @summary Creates a Agency resource.
 */
export const postAgency = (
  agencyPostAgencyInputJsonld: AgencyPostAgencyInputJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/agencies`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: agencyPostAgencyInputJsonld,
    },
    options
  )
}

export type PostAgencyMutationResult = NonNullable<Awaited<ReturnType<typeof postAgency>>>
export type PostAgencyMutationBody = AgencyPostAgencyInputJsonld
export type PostAgencyMutationError = void

export const usePostAgency = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAgency>>,
    TError,
    { data: AgencyPostAgencyInputJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAgency>>,
    { data: AgencyPostAgencyInputJsonld }
  > = props => {
    const { data } = props ?? {}

    return postAgency(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postAgency>>,
    TError,
    { data: AgencyPostAgencyInputJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves the collection of Agency resources.
 * @summary Retrieves the collection of Agency resources.
 */
export const getAgenciesExcelExportData = (
  params?: GetAgenciesExcelExportDataParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetAgenciesExcelExportData200>(
    { url: `/api/agencies/excel-export`, method: "get", params, signal },
    options
  )
}

export const getGetAgenciesExcelExportDataQueryKey = (
  params?: GetAgenciesExcelExportDataParams
) => [`/api/agencies/excel-export`, ...(params ? [params] : [])]

export type GetAgenciesExcelExportDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAgenciesExcelExportData>>
>
export type GetAgenciesExcelExportDataQueryError = unknown

export const useGetAgenciesExcelExportData = <
  TData = Awaited<ReturnType<typeof getAgenciesExcelExportData>>,
  TError = unknown
>(
  params?: GetAgenciesExcelExportDataParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAgenciesExcelExportData>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAgenciesExcelExportDataQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgenciesExcelExportData>>> = ({
    signal,
  }) => getAgenciesExcelExportData(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getAgenciesExcelExportData>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Removes the Agency resource.
 * @summary Removes the Agency resource.
 */
export const deleteAgency = (id: string, options?: SecondParameter<typeof customMutator>) => {
  return customMutator<void>({ url: `/api/agencies/${id}`, method: "delete" }, options)
}

export type DeleteAgencyMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAgency>>>

export type DeleteAgencyMutationError = unknown

export const useDeleteAgency = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAgency>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAgency>>,
    { id: string }
  > = props => {
    const { id } = props ?? {}

    return deleteAgency(id, requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof deleteAgency>>, TError, { id: string }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Updates the Agency resource.
 * @summary Updates the Agency resource.
 */
export const patchAgency = (
  id: string,
  agencyPatchAgencyInput: AgencyPatchAgencyInput,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/agencies/${id}`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: agencyPatchAgencyInput,
    },
    options
  )
}

export type PatchAgencyMutationResult = NonNullable<Awaited<ReturnType<typeof patchAgency>>>
export type PatchAgencyMutationBody = AgencyPatchAgencyInput
export type PatchAgencyMutationError = void

export const usePatchAgency = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchAgency>>,
    TError,
    { id: string; data: AgencyPatchAgencyInput },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchAgency>>,
    { id: string; data: AgencyPatchAgencyInput }
  > = props => {
    const { id, data } = props ?? {}

    return patchAgency(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchAgency>>,
    TError,
    { id: string; data: AgencyPatchAgencyInput },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Creates a Agency resource.
 * @summary Creates a Agency resource.
 */
export const postAgencyMedia = (
  id: string,
  postAgencyMediaBody: PostAgencyMediaBody,
  options?: SecondParameter<typeof customMutator>
) => {
  const formData = customFormDataFn(postAgencyMediaBody)
  return customMutator<unknown>(
    {
      url: `/api/agencies/${id}/medias`,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  )
}

export type PostAgencyMediaMutationResult = NonNullable<Awaited<ReturnType<typeof postAgencyMedia>>>
export type PostAgencyMediaMutationBody = PostAgencyMediaBody
export type PostAgencyMediaMutationError = void

export const usePostAgencyMedia = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAgencyMedia>>,
    TError,
    { id: string; data: PostAgencyMediaBody },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAgencyMedia>>,
    { id: string; data: PostAgencyMediaBody }
  > = props => {
    const { id, data } = props ?? {}

    return postAgencyMedia(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postAgencyMedia>>,
    TError,
    { id: string; data: PostAgencyMediaBody },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Updates the Agency resource.
 * @summary Updates the Agency resource.
 */
export const patchAgencyMedia = (
  id: string,
  agencyPatchEntityMediaInput: AgencyPatchEntityMediaInput,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/agencies/${id}/medias`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: agencyPatchEntityMediaInput,
    },
    options
  )
}

export type PatchAgencyMediaMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchAgencyMedia>>
>
export type PatchAgencyMediaMutationBody = AgencyPatchEntityMediaInput
export type PatchAgencyMediaMutationError = void

export const usePatchAgencyMedia = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchAgencyMedia>>,
    TError,
    { id: string; data: AgencyPatchEntityMediaInput },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchAgencyMedia>>,
    { id: string; data: AgencyPatchEntityMediaInput }
  > = props => {
    const { id, data } = props ?? {}

    return patchAgencyMedia(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchAgencyMedia>>,
    TError,
    { id: string; data: AgencyPatchEntityMediaInput },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Removes the Agency resource.
 * @summary Removes the Agency resource.
 */
export const deleteAgencyMedia = (
  id: string,
  mediaId: string,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<void>(
    { url: `/api/agencies/${id}/medias/${mediaId}`, method: "delete" },
    options
  )
}

export type DeleteAgencyMediaMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAgencyMedia>>
>

export type DeleteAgencyMediaMutationError = unknown

export const useDeleteAgencyMedia = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAgencyMedia>>,
    TError,
    { id: string; mediaId: string },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAgencyMedia>>,
    { id: string; mediaId: string }
  > = props => {
    const { id, mediaId } = props ?? {}

    return deleteAgencyMedia(id, mediaId, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof deleteAgencyMedia>>,
    TError,
    { id: string; mediaId: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves a Agency resource.
 * @summary Retrieves a Agency resource.
 */
export const getAgency = (
  id: string,
  params?: GetAgencyParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<AgencyJsonldReadItemAgencyItem>(
    { url: `/api/agency/${id}`, method: "get", params, signal },
    options
  )
}

export const getGetAgencyQueryKey = (id: string, params?: GetAgencyParams) => [
  `/api/agency/${id}`,
  ...(params ? [params] : []),
]

export type GetAgencyQueryResult = NonNullable<Awaited<ReturnType<typeof getAgency>>>
export type GetAgencyQueryError = void

export const useGetAgency = <TData = Awaited<ReturnType<typeof getAgency>>, TError = void>(
  id: string,
  params?: GetAgencyParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAgency>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAgencyQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgency>>> = ({ signal }) =>
    getAgency(id, params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getAgency>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of BusinessUnit resources.
 * @summary Retrieves the collection of BusinessUnit resources.
 */
export const getBusinessUnitNames = (
  params?: GetBusinessUnitNamesParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetBusinessUnitNames200>(
    { url: `/api/business-units/names`, method: "get", params, signal },
    options
  )
}

export const getGetBusinessUnitNamesQueryKey = (params?: GetBusinessUnitNamesParams) => [
  `/api/business-units/names`,
  ...(params ? [params] : []),
]

export type GetBusinessUnitNamesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBusinessUnitNames>>
>
export type GetBusinessUnitNamesQueryError = unknown

export const useGetBusinessUnitNames = <
  TData = Awaited<ReturnType<typeof getBusinessUnitNames>>,
  TError = unknown
>(
  params?: GetBusinessUnitNamesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBusinessUnitNames>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetBusinessUnitNamesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBusinessUnitNames>>> = ({ signal }) =>
    getBusinessUnitNames(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getBusinessUnitNames>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of Circle resources.
 * @summary Retrieves the collection of Circle resources.
 */
export const getCircles = (
  params?: GetCirclesParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetCircles200>(
    { url: `/api/circles`, method: "get", params, signal },
    options
  )
}

export const getGetCirclesQueryKey = (params?: GetCirclesParams) => [
  `/api/circles`,
  ...(params ? [params] : []),
]

export type GetCirclesQueryResult = NonNullable<Awaited<ReturnType<typeof getCircles>>>
export type GetCirclesQueryError = unknown

export const useGetCircles = <TData = Awaited<ReturnType<typeof getCircles>>, TError = unknown>(
  params?: GetCirclesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCircles>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCirclesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCircles>>> = ({ signal }) =>
    getCircles(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getCircles>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Creates a Circle resource.
 * @summary Creates a Circle resource.
 */
export const postCircle = (
  circlePostCircleInputJsonld: CirclePostCircleInputJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/circles`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: circlePostCircleInputJsonld,
    },
    options
  )
}

export type PostCircleMutationResult = NonNullable<Awaited<ReturnType<typeof postCircle>>>
export type PostCircleMutationBody = CirclePostCircleInputJsonld
export type PostCircleMutationError = void

export const usePostCircle = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCircle>>,
    TError,
    { data: CirclePostCircleInputJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCircle>>,
    { data: CirclePostCircleInputJsonld }
  > = props => {
    const { data } = props ?? {}

    return postCircle(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postCircle>>,
    TError,
    { data: CirclePostCircleInputJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Removes the Circle resource.
 * @summary Removes the Circle resource.
 */
export const deleteCircle = (id: string, options?: SecondParameter<typeof customMutator>) => {
  return customMutator<void>({ url: `/api/circles/${id}`, method: "delete" }, options)
}

export type DeleteCircleMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCircle>>>

export type DeleteCircleMutationError = unknown

export const useDeleteCircle = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCircle>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCircle>>,
    { id: string }
  > = props => {
    const { id } = props ?? {}

    return deleteCircle(id, requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof deleteCircle>>, TError, { id: string }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Updates the Circle resource.
 * @summary Updates the Circle resource.
 */
export const patchCircle = (
  id: string,
  circlePatchCircleInput: CirclePatchCircleInput,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/circles/${id}`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: circlePatchCircleInput,
    },
    options
  )
}

export type PatchCircleMutationResult = NonNullable<Awaited<ReturnType<typeof patchCircle>>>
export type PatchCircleMutationBody = CirclePatchCircleInput
export type PatchCircleMutationError = void

export const usePatchCircle = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchCircle>>,
    TError,
    { id: string; data: CirclePatchCircleInput },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchCircle>>,
    { id: string; data: CirclePatchCircleInput }
  > = props => {
    const { id, data } = props ?? {}

    return patchCircle(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchCircle>>,
    TError,
    { id: string; data: CirclePatchCircleInput },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves a Circle resource.
 * @summary Retrieves a Circle resource.
 */
export const getCircleWithTranslations = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<CircleCircleWithTranslationsDTO>(
    { url: `/api/circles/${id}/translations`, method: "get", signal },
    options
  )
}

export const getGetCircleWithTranslationsQueryKey = (id: string) => [
  `/api/circles/${id}/translations`,
]

export type GetCircleWithTranslationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCircleWithTranslations>>
>
export type GetCircleWithTranslationsQueryError = void

export const useGetCircleWithTranslations = <
  TData = Awaited<ReturnType<typeof getCircleWithTranslations>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCircleWithTranslations>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCircleWithTranslationsQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCircleWithTranslations>>> = ({
    signal,
  }) => getCircleWithTranslations(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getCircleWithTranslations>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Creates a ClientAccessRequest resource.
 * @summary Creates a ClientAccessRequest resource.
 */
export const postClientAccessRequest = (
  clientAccessRequestCreateClientAccessRequestInputJsonld: ClientAccessRequestCreateClientAccessRequestInputJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<ClientAccessRequestJsonld>(
    {
      url: `/api/client-access-requests`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: clientAccessRequestCreateClientAccessRequestInputJsonld,
    },
    options
  )
}

export type PostClientAccessRequestMutationResult = NonNullable<
  Awaited<ReturnType<typeof postClientAccessRequest>>
>
export type PostClientAccessRequestMutationBody =
  ClientAccessRequestCreateClientAccessRequestInputJsonld
export type PostClientAccessRequestMutationError = void

export const usePostClientAccessRequest = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postClientAccessRequest>>,
    TError,
    { data: ClientAccessRequestCreateClientAccessRequestInputJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postClientAccessRequest>>,
    { data: ClientAccessRequestCreateClientAccessRequestInputJsonld }
  > = props => {
    const { data } = props ?? {}

    return postClientAccessRequest(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postClientAccessRequest>>,
    TError,
    { data: ClientAccessRequestCreateClientAccessRequestInputJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves the collection of ClientAccessRequest resources.
 * @summary Retrieves the collection of ClientAccessRequest resources.
 */
export const getAnsweredClientAccessRequest = (
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetAnsweredClientAccessRequest200>(
    { url: `/api/client-access-requests/answered`, method: "get", signal },
    options
  )
}

export const getGetAnsweredClientAccessRequestQueryKey = () => [
  `/api/client-access-requests/answered`,
]

export type GetAnsweredClientAccessRequestQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAnsweredClientAccessRequest>>
>
export type GetAnsweredClientAccessRequestQueryError = unknown

export const useGetAnsweredClientAccessRequest = <
  TData = Awaited<ReturnType<typeof getAnsweredClientAccessRequest>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAnsweredClientAccessRequest>>, TError, TData>
  request?: SecondParameter<typeof customMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAnsweredClientAccessRequestQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAnsweredClientAccessRequest>>> = ({
    signal,
  }) => getAnsweredClientAccessRequest(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getAnsweredClientAccessRequest>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of ClientAccessRequest resources.
 * @summary Retrieves the collection of ClientAccessRequest resources.
 */
export const getPendingClientAccessRequest = (
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetPendingClientAccessRequest200>(
    { url: `/api/client-access-requests/pending`, method: "get", signal },
    options
  )
}

export const getGetPendingClientAccessRequestQueryKey = () => [
  `/api/client-access-requests/pending`,
]

export type GetPendingClientAccessRequestQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPendingClientAccessRequest>>
>
export type GetPendingClientAccessRequestQueryError = unknown

export const useGetPendingClientAccessRequest = <
  TData = Awaited<ReturnType<typeof getPendingClientAccessRequest>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPendingClientAccessRequest>>, TError, TData>
  request?: SecondParameter<typeof customMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPendingClientAccessRequestQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPendingClientAccessRequest>>> = ({
    signal,
  }) => getPendingClientAccessRequest(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getPendingClientAccessRequest>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Updates the ClientAccessRequest resource.
 * @summary Updates the ClientAccessRequest resource.
 */
export const patchClientAccessRequest = (
  id: string,
  clientAccessRequestWriteItemClientAccessRequest: ClientAccessRequestWriteItemClientAccessRequest,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<ClientAccessRequestJsonld>(
    {
      url: `/api/client-access-requests/${id}`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: clientAccessRequestWriteItemClientAccessRequest,
    },
    options
  )
}

export type PatchClientAccessRequestMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchClientAccessRequest>>
>
export type PatchClientAccessRequestMutationBody = ClientAccessRequestWriteItemClientAccessRequest
export type PatchClientAccessRequestMutationError = void

export const usePatchClientAccessRequest = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchClientAccessRequest>>,
    TError,
    { id: string; data: ClientAccessRequestWriteItemClientAccessRequest },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchClientAccessRequest>>,
    { id: string; data: ClientAccessRequestWriteItemClientAccessRequest }
  > = props => {
    const { id, data } = props ?? {}

    return patchClientAccessRequest(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchClientAccessRequest>>,
    TError,
    { id: string; data: ClientAccessRequestWriteItemClientAccessRequest },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves the collection of Client resources.
 * @summary Retrieves the collection of Client resources.
 */
export const getClients = (
  params?: GetClientsParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetClients200>(
    { url: `/api/clients`, method: "get", params, signal },
    options
  )
}

export const getGetClientsQueryKey = (params?: GetClientsParams) => [
  `/api/clients`,
  ...(params ? [params] : []),
]

export type GetClientsQueryResult = NonNullable<Awaited<ReturnType<typeof getClients>>>
export type GetClientsQueryError = unknown

export const useGetClients = <TData = Awaited<ReturnType<typeof getClients>>, TError = unknown>(
  params?: GetClientsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getClients>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetClientsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClients>>> = ({ signal }) =>
    getClients(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getClients>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves a Client resource.
 * @summary Retrieves a Client resource.
 */
export const getClient = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<ClientJsonldReadItemClientWithoutPrivateData>(
    { url: `/api/clients/${id}`, method: "get", signal },
    options
  )
}

export const getGetClientQueryKey = (id: string) => [`/api/clients/${id}`]

export type GetClientQueryResult = NonNullable<Awaited<ReturnType<typeof getClient>>>
export type GetClientQueryError = void

export const useGetClient = <TData = Awaited<ReturnType<typeof getClient>>, TError = void>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getClient>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetClientQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClient>>> = ({ signal }) =>
    getClient(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getClient>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of InterBu resources.
 * @summary Retrieves the collection of InterBu resources.
 */
export const getClientInterBUs = (
  id: string,
  params?: GetClientInterBUsParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetClientInterBUs200>(
    { url: `/api/clients/${id}/inter-bus`, method: "get", params, signal },
    options
  )
}

export const getGetClientInterBUsQueryKey = (id: string, params?: GetClientInterBUsParams) => [
  `/api/clients/${id}/inter-bus`,
  ...(params ? [params] : []),
]

export type GetClientInterBUsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClientInterBUs>>
>
export type GetClientInterBUsQueryError = unknown

export const useGetClientInterBUs = <
  TData = Awaited<ReturnType<typeof getClientInterBUs>>,
  TError = unknown
>(
  id: string,
  params?: GetClientInterBUsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getClientInterBUs>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetClientInterBUsQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClientInterBUs>>> = ({ signal }) =>
    getClientInterBUs(id, params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getClientInterBUs>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves a Client resource.
 * @summary Retrieves a Client resource.
 */
export const getClientLogs = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<ClientLogsOutputJsonld>(
    { url: `/api/clients/${id}/logs`, method: "get", signal },
    options
  )
}

export const getGetClientLogsQueryKey = (id: string) => [`/api/clients/${id}/logs`]

export type GetClientLogsQueryResult = NonNullable<Awaited<ReturnType<typeof getClientLogs>>>
export type GetClientLogsQueryError = void

export const useGetClientLogs = <TData = Awaited<ReturnType<typeof getClientLogs>>, TError = void>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getClientLogs>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetClientLogsQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClientLogs>>> = ({ signal }) =>
    getClientLogs(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getClientLogs>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of ClientAccessRequest resources.
 * @summary Retrieves the collection of ClientAccessRequest resources.
 */
export const apiClientsClientAccessRequestsGetSubresource = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<ApiClientsClientAccessRequestsGetSubresource200>(
    { url: `/api/clients/${id}/pending-access-request`, method: "get", signal },
    options
  )
}

export const getApiClientsClientAccessRequestsGetSubresourceQueryKey = (id: string) => [
  `/api/clients/${id}/pending-access-request`,
]

export type ApiClientsClientAccessRequestsGetSubresourceQueryResult = NonNullable<
  Awaited<ReturnType<typeof apiClientsClientAccessRequestsGetSubresource>>
>
export type ApiClientsClientAccessRequestsGetSubresourceQueryError = unknown

export const useApiClientsClientAccessRequestsGetSubresource = <
  TData = Awaited<ReturnType<typeof apiClientsClientAccessRequestsGetSubresource>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof apiClientsClientAccessRequestsGetSubresource>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getApiClientsClientAccessRequestsGetSubresourceQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof apiClientsClientAccessRequestsGetSubresource>>
  > = ({ signal }) => apiClientsClientAccessRequestsGetSubresource(id, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof apiClientsClientAccessRequestsGetSubresource>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of Tproject resources.
 * @summary Retrieves the collection of Tproject resources.
 */
export const getClientProjects = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetClientProjects200>(
    { url: `/api/clients/${id}/projects`, method: "get", signal },
    options
  )
}

export const getGetClientProjectsQueryKey = (id: string) => [`/api/clients/${id}/projects`]

export type GetClientProjectsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClientProjects>>
>
export type GetClientProjectsQueryError = unknown

export const useGetClientProjects = <
  TData = Awaited<ReturnType<typeof getClientProjects>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getClientProjects>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetClientProjectsQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClientProjects>>> = ({ signal }) =>
    getClientProjects(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getClientProjects>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of Tproperty resources.
 * @summary Retrieves the collection of Tproperty resources.
 */
export const getClientProperties = (
  id: string,
  params?: GetClientPropertiesParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetClientProperties200>(
    { url: `/api/clients/${id}/properties`, method: "get", params, signal },
    options
  )
}

export const getGetClientPropertiesQueryKey = (id: string, params?: GetClientPropertiesParams) => [
  `/api/clients/${id}/properties`,
  ...(params ? [params] : []),
]

export type GetClientPropertiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClientProperties>>
>
export type GetClientPropertiesQueryError = unknown

export const useGetClientProperties = <
  TData = Awaited<ReturnType<typeof getClientProperties>>,
  TError = unknown
>(
  id: string,
  params?: GetClientPropertiesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getClientProperties>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetClientPropertiesQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClientProperties>>> = ({ signal }) =>
    getClientProperties(id, params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getClientProperties>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves a Cluster resource.
 * @summary Retrieves a Cluster resource.
 */
export const getCluster = (
  id: string,
  params?: GetClusterParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<ClusterJsonldReadItemClusterItem>(
    { url: `/api/cluster/${id}`, method: "get", params, signal },
    options
  )
}

export const getGetClusterQueryKey = (id: string, params?: GetClusterParams) => [
  `/api/cluster/${id}`,
  ...(params ? [params] : []),
]

export type GetClusterQueryResult = NonNullable<Awaited<ReturnType<typeof getCluster>>>
export type GetClusterQueryError = void

export const useGetCluster = <TData = Awaited<ReturnType<typeof getCluster>>, TError = void>(
  id: string,
  params?: GetClusterParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCluster>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetClusterQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCluster>>> = ({ signal }) =>
    getCluster(id, params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getCluster>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of Cluster resources.
 * @summary Retrieves the collection of Cluster resources.
 */
export const getClusters = (
  params?: GetClustersParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetClusters200>(
    { url: `/api/clusters`, method: "get", params, signal },
    options
  )
}

export const getGetClustersQueryKey = (params?: GetClustersParams) => [
  `/api/clusters`,
  ...(params ? [params] : []),
]

export type GetClustersQueryResult = NonNullable<Awaited<ReturnType<typeof getClusters>>>
export type GetClustersQueryError = unknown

export const useGetClusters = <TData = Awaited<ReturnType<typeof getClusters>>, TError = unknown>(
  params?: GetClustersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getClusters>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetClustersQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClusters>>> = ({ signal }) =>
    getClusters(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getClusters>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Creates a Cluster resource.
 * @summary Creates a Cluster resource.
 */
export const postCluster = (
  clusterPostClusterInputJsonld: ClusterPostClusterInputJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/clusters`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: clusterPostClusterInputJsonld,
    },
    options
  )
}

export type PostClusterMutationResult = NonNullable<Awaited<ReturnType<typeof postCluster>>>
export type PostClusterMutationBody = ClusterPostClusterInputJsonld
export type PostClusterMutationError = void

export const usePostCluster = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCluster>>,
    TError,
    { data: ClusterPostClusterInputJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCluster>>,
    { data: ClusterPostClusterInputJsonld }
  > = props => {
    const { data } = props ?? {}

    return postCluster(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postCluster>>,
    TError,
    { data: ClusterPostClusterInputJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves the collection of Cluster resources.
 * @summary Retrieves the collection of Cluster resources.
 */
export const getClustersExcelExportData = (
  params?: GetClustersExcelExportDataParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetClustersExcelExportData200>(
    { url: `/api/clusters/excel-export`, method: "get", params, signal },
    options
  )
}

export const getGetClustersExcelExportDataQueryKey = (
  params?: GetClustersExcelExportDataParams
) => [`/api/clusters/excel-export`, ...(params ? [params] : [])]

export type GetClustersExcelExportDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClustersExcelExportData>>
>
export type GetClustersExcelExportDataQueryError = unknown

export const useGetClustersExcelExportData = <
  TData = Awaited<ReturnType<typeof getClustersExcelExportData>>,
  TError = unknown
>(
  params?: GetClustersExcelExportDataParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getClustersExcelExportData>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetClustersExcelExportDataQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClustersExcelExportData>>> = ({
    signal,
  }) => getClustersExcelExportData(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getClustersExcelExportData>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Removes the Cluster resource.
 * @summary Removes the Cluster resource.
 */
export const deleteCluster = (id: string, options?: SecondParameter<typeof customMutator>) => {
  return customMutator<void>({ url: `/api/clusters/${id}`, method: "delete" }, options)
}

export type DeleteClusterMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCluster>>>

export type DeleteClusterMutationError = unknown

export const useDeleteCluster = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCluster>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCluster>>,
    { id: string }
  > = props => {
    const { id } = props ?? {}

    return deleteCluster(id, requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof deleteCluster>>, TError, { id: string }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Updates the Cluster resource.
 * @summary Updates the Cluster resource.
 */
export const patchCluster = (
  id: string,
  clusterPatchClusterInput: ClusterPatchClusterInput,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/clusters/${id}`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: clusterPatchClusterInput,
    },
    options
  )
}

export type PatchClusterMutationResult = NonNullable<Awaited<ReturnType<typeof patchCluster>>>
export type PatchClusterMutationBody = ClusterPatchClusterInput
export type PatchClusterMutationError = void

export const usePatchCluster = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchCluster>>,
    TError,
    { id: string; data: ClusterPatchClusterInput },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchCluster>>,
    { id: string; data: ClusterPatchClusterInput }
  > = props => {
    const { id, data } = props ?? {}

    return patchCluster(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchCluster>>,
    TError,
    { id: string; data: ClusterPatchClusterInput },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Creates a Cluster resource.
 * @summary Creates a Cluster resource.
 */
export const postClusterMedia = (
  id: string,
  postClusterMediaBody: PostClusterMediaBody,
  options?: SecondParameter<typeof customMutator>
) => {
  const formData = customFormDataFn(postClusterMediaBody)
  return customMutator<unknown>(
    {
      url: `/api/clusters/${id}/medias`,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  )
}

export type PostClusterMediaMutationResult = NonNullable<
  Awaited<ReturnType<typeof postClusterMedia>>
>
export type PostClusterMediaMutationBody = PostClusterMediaBody
export type PostClusterMediaMutationError = void

export const usePostClusterMedia = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postClusterMedia>>,
    TError,
    { id: string; data: PostClusterMediaBody },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postClusterMedia>>,
    { id: string; data: PostClusterMediaBody }
  > = props => {
    const { id, data } = props ?? {}

    return postClusterMedia(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postClusterMedia>>,
    TError,
    { id: string; data: PostClusterMediaBody },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Updates the Cluster resource.
 * @summary Updates the Cluster resource.
 */
export const patchClusterMedia = (
  id: string,
  clusterPatchEntityMediaInput: ClusterPatchEntityMediaInput,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/clusters/${id}/medias`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: clusterPatchEntityMediaInput,
    },
    options
  )
}

export type PatchClusterMediaMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchClusterMedia>>
>
export type PatchClusterMediaMutationBody = ClusterPatchEntityMediaInput
export type PatchClusterMediaMutationError = void

export const usePatchClusterMedia = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchClusterMedia>>,
    TError,
    { id: string; data: ClusterPatchEntityMediaInput },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchClusterMedia>>,
    { id: string; data: ClusterPatchEntityMediaInput }
  > = props => {
    const { id, data } = props ?? {}

    return patchClusterMedia(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchClusterMedia>>,
    TError,
    { id: string; data: ClusterPatchEntityMediaInput },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Removes the Cluster resource.
 * @summary Removes the Cluster resource.
 */
export const deleteClusterMedia = (
  id: string,
  mediaId: string,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<void>(
    { url: `/api/clusters/${id}/medias/${mediaId}`, method: "delete" },
    options
  )
}

export type DeleteClusterMediaMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteClusterMedia>>
>

export type DeleteClusterMediaMutationError = unknown

export const useDeleteClusterMedia = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteClusterMedia>>,
    TError,
    { id: string; mediaId: string },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteClusterMedia>>,
    { id: string; mediaId: string }
  > = props => {
    const { id, mediaId } = props ?? {}

    return deleteClusterMedia(id, mediaId, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof deleteClusterMedia>>,
    TError,
    { id: string; mediaId: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves a Collaborator resource.
 * @summary Retrieves a Collaborator resource.
 */
export const getCollaborator = (
  id: string,
  params?: GetCollaboratorParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<CollaboratorJsonldReadItemCollaboratorItem>(
    { url: `/api/collaborator/${id}`, method: "get", params, signal },
    options
  )
}

export const getGetCollaboratorQueryKey = (id: string, params?: GetCollaboratorParams) => [
  `/api/collaborator/${id}`,
  ...(params ? [params] : []),
]

export type GetCollaboratorQueryResult = NonNullable<Awaited<ReturnType<typeof getCollaborator>>>
export type GetCollaboratorQueryError = void

export const useGetCollaborator = <
  TData = Awaited<ReturnType<typeof getCollaborator>>,
  TError = void
>(
  id: string,
  params?: GetCollaboratorParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCollaborator>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCollaboratorQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollaborator>>> = ({ signal }) =>
    getCollaborator(id, params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getCollaborator>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of Collaborator resources.
 * @summary Retrieves the collection of Collaborator resources.
 */
export const getCollaborators = (
  params?: GetCollaboratorsParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetCollaborators200>(
    { url: `/api/collaborators`, method: "get", params, signal },
    options
  )
}

export const getGetCollaboratorsQueryKey = (params?: GetCollaboratorsParams) => [
  `/api/collaborators`,
  ...(params ? [params] : []),
]

export type GetCollaboratorsQueryResult = NonNullable<Awaited<ReturnType<typeof getCollaborators>>>
export type GetCollaboratorsQueryError = unknown

export const useGetCollaborators = <
  TData = Awaited<ReturnType<typeof getCollaborators>>,
  TError = unknown
>(
  params?: GetCollaboratorsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCollaborators>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCollaboratorsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollaborators>>> = ({ signal }) =>
    getCollaborators(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getCollaborators>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Creates a Collaborator resource.
 * @summary Creates a Collaborator resource.
 */
export const postCollaborator = (
  collaboratorPostCollaboratorInputJsonld: CollaboratorPostCollaboratorInputJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/collaborators`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: collaboratorPostCollaboratorInputJsonld,
    },
    options
  )
}

export type PostCollaboratorMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCollaborator>>
>
export type PostCollaboratorMutationBody = CollaboratorPostCollaboratorInputJsonld
export type PostCollaboratorMutationError = void

export const usePostCollaborator = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCollaborator>>,
    TError,
    { data: CollaboratorPostCollaboratorInputJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCollaborator>>,
    { data: CollaboratorPostCollaboratorInputJsonld }
  > = props => {
    const { data } = props ?? {}

    return postCollaborator(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postCollaborator>>,
    TError,
    { data: CollaboratorPostCollaboratorInputJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves the collection of Collaborator resources.
 * @summary Retrieves the collection of Collaborator resources.
 */
export const getCollaboratorsExcelExportData = (
  params?: GetCollaboratorsExcelExportDataParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetCollaboratorsExcelExportData200>(
    { url: `/api/collaborators/excel-export`, method: "get", params, signal },
    options
  )
}

export const getGetCollaboratorsExcelExportDataQueryKey = (
  params?: GetCollaboratorsExcelExportDataParams
) => [`/api/collaborators/excel-export`, ...(params ? [params] : [])]

export type GetCollaboratorsExcelExportDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCollaboratorsExcelExportData>>
>
export type GetCollaboratorsExcelExportDataQueryError = unknown

export const useGetCollaboratorsExcelExportData = <
  TData = Awaited<ReturnType<typeof getCollaboratorsExcelExportData>>,
  TError = unknown
>(
  params?: GetCollaboratorsExcelExportDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCollaboratorsExcelExportData>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCollaboratorsExcelExportDataQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollaboratorsExcelExportData>>> = ({
    signal,
  }) => getCollaboratorsExcelExportData(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getCollaboratorsExcelExportData>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Removes the Collaborator resource.
 * @summary Removes the Collaborator resource.
 */
export const deleteCollaborator = (id: string, options?: SecondParameter<typeof customMutator>) => {
  return customMutator<void>({ url: `/api/collaborators/${id}`, method: "delete" }, options)
}

export type DeleteCollaboratorMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCollaborator>>
>

export type DeleteCollaboratorMutationError = unknown

export const useDeleteCollaborator = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCollaborator>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCollaborator>>,
    { id: string }
  > = props => {
    const { id } = props ?? {}

    return deleteCollaborator(id, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof deleteCollaborator>>,
    TError,
    { id: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Updates the Collaborator resource.
 * @summary Updates the Collaborator resource.
 */
export const patchCollaborator = (
  id: string,
  collaboratorPatchCollaboratorInput: CollaboratorPatchCollaboratorInput,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/collaborators/${id}`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: collaboratorPatchCollaboratorInput,
    },
    options
  )
}

export type PatchCollaboratorMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchCollaborator>>
>
export type PatchCollaboratorMutationBody = CollaboratorPatchCollaboratorInput
export type PatchCollaboratorMutationError = void

export const usePatchCollaborator = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchCollaborator>>,
    TError,
    { id: string; data: CollaboratorPatchCollaboratorInput },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchCollaborator>>,
    { id: string; data: CollaboratorPatchCollaboratorInput }
  > = props => {
    const { id, data } = props ?? {}

    return patchCollaborator(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchCollaborator>>,
    TError,
    { id: string; data: CollaboratorPatchCollaboratorInput },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Creates a Collaborator resource.
 * @summary Creates a Collaborator resource.
 */
export const postCollaboratorMedia = (
  id: string,
  postCollaboratorMediaBody: PostCollaboratorMediaBody,
  options?: SecondParameter<typeof customMutator>
) => {
  const formData = customFormDataFn(postCollaboratorMediaBody)
  return customMutator<unknown>(
    {
      url: `/api/collaborators/${id}/medias`,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  )
}

export type PostCollaboratorMediaMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCollaboratorMedia>>
>
export type PostCollaboratorMediaMutationBody = PostCollaboratorMediaBody
export type PostCollaboratorMediaMutationError = void

export const usePostCollaboratorMedia = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCollaboratorMedia>>,
    TError,
    { id: string; data: PostCollaboratorMediaBody },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCollaboratorMedia>>,
    { id: string; data: PostCollaboratorMediaBody }
  > = props => {
    const { id, data } = props ?? {}

    return postCollaboratorMedia(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postCollaboratorMedia>>,
    TError,
    { id: string; data: PostCollaboratorMediaBody },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Updates the Collaborator resource.
 * @summary Updates the Collaborator resource.
 */
export const patchCollaboratorMedia = (
  id: string,
  collaboratorPatchEntityMediaInput: CollaboratorPatchEntityMediaInput,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/collaborators/${id}/medias`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: collaboratorPatchEntityMediaInput,
    },
    options
  )
}

export type PatchCollaboratorMediaMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchCollaboratorMedia>>
>
export type PatchCollaboratorMediaMutationBody = CollaboratorPatchEntityMediaInput
export type PatchCollaboratorMediaMutationError = void

export const usePatchCollaboratorMedia = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchCollaboratorMedia>>,
    TError,
    { id: string; data: CollaboratorPatchEntityMediaInput },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchCollaboratorMedia>>,
    { id: string; data: CollaboratorPatchEntityMediaInput }
  > = props => {
    const { id, data } = props ?? {}

    return patchCollaboratorMedia(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchCollaboratorMedia>>,
    TError,
    { id: string; data: CollaboratorPatchEntityMediaInput },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Removes the Collaborator resource.
 * @summary Removes the Collaborator resource.
 */
export const deleteCollaboratorMedia = (
  id: string,
  mediaId: string,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<void>(
    { url: `/api/collaborators/${id}/medias/${mediaId}`, method: "delete" },
    options
  )
}

export type DeleteCollaboratorMediaMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCollaboratorMedia>>
>

export type DeleteCollaboratorMediaMutationError = unknown

export const useDeleteCollaboratorMedia = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCollaboratorMedia>>,
    TError,
    { id: string; mediaId: string },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCollaboratorMedia>>,
    { id: string; mediaId: string }
  > = props => {
    const { id, mediaId } = props ?? {}

    return deleteCollaboratorMedia(id, mediaId, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof deleteCollaboratorMedia>>,
    TError,
    { id: string; mediaId: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves the collection of EntityResource resources.
 * @summary Retrieves the collection of EntityResource resources.
 */
export const getUserClustersNoId = (
  params?: GetUserClustersNoIdParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetUserClustersNoId200>(
    { url: `/api/entities/user-clusters`, method: "get", params, signal },
    options
  )
}

export const getGetUserClustersNoIdQueryKey = (params?: GetUserClustersNoIdParams) => [
  `/api/entities/user-clusters`,
  ...(params ? [params] : []),
]

export type GetUserClustersNoIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserClustersNoId>>
>
export type GetUserClustersNoIdQueryError = unknown

export const useGetUserClustersNoId = <
  TData = Awaited<ReturnType<typeof getUserClustersNoId>>,
  TError = unknown
>(
  params?: GetUserClustersNoIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserClustersNoId>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserClustersNoIdQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserClustersNoId>>> = ({ signal }) =>
    getUserClustersNoId(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getUserClustersNoId>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of EntityResource resources.
 * @summary Retrieves the collection of EntityResource resources.
 */
export const getUserClusters = (
  id?: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetUserClusters200>(
    { url: `/api/entities/user-clusters/${id}`, method: "get", signal },
    options
  )
}

export const getGetUserClustersQueryKey = (id?: string) => [`/api/entities/user-clusters/${id}`]

export type GetUserClustersQueryResult = NonNullable<Awaited<ReturnType<typeof getUserClusters>>>
export type GetUserClustersQueryError = unknown

export const useGetUserClusters = <
  TData = Awaited<ReturnType<typeof getUserClusters>>,
  TError = unknown
>(
  id?: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserClusters>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserClustersQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserClusters>>> = ({ signal }) =>
    getUserClusters(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getUserClusters>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of Event resources.
 * @summary Retrieves the collection of Event resources.
 */
export const getEvents = (
  params?: GetEventsParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetEvents200>({ url: `/api/events`, method: "get", params, signal }, options)
}

export const getGetEventsQueryKey = (params?: GetEventsParams) => [
  `/api/events`,
  ...(params ? [params] : []),
]

export type GetEventsQueryResult = NonNullable<Awaited<ReturnType<typeof getEvents>>>
export type GetEventsQueryError = unknown

export const useGetEvents = <TData = Awaited<ReturnType<typeof getEvents>>, TError = unknown>(
  params?: GetEventsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getEvents>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetEventsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEvents>>> = ({ signal }) =>
    getEvents(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getEvents>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Creates a Event resource.
 * @summary Creates a Event resource.
 */
export const postEvent = (
  eventPostEventInputJsonld: EventPostEventInputJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/events`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: eventPostEventInputJsonld,
    },
    options
  )
}

export type PostEventMutationResult = NonNullable<Awaited<ReturnType<typeof postEvent>>>
export type PostEventMutationBody = EventPostEventInputJsonld
export type PostEventMutationError = void

export const usePostEvent = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postEvent>>,
    TError,
    { data: EventPostEventInputJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postEvent>>,
    { data: EventPostEventInputJsonld }
  > = props => {
    const { data } = props ?? {}

    return postEvent(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postEvent>>,
    TError,
    { data: EventPostEventInputJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Removes the Event resource.
 * @summary Removes the Event resource.
 */
export const deleteEvent = (id: string, options?: SecondParameter<typeof customMutator>) => {
  return customMutator<void>({ url: `/api/events/${id}`, method: "delete" }, options)
}

export type DeleteEventMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEvent>>>

export type DeleteEventMutationError = unknown

export const useDeleteEvent = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteEvent>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteEvent>>,
    { id: string }
  > = props => {
    const { id } = props ?? {}

    return deleteEvent(id, requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof deleteEvent>>, TError, { id: string }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Updates the Event resource.
 * @summary Updates the Event resource.
 */
export const patchEvent = (
  id: string,
  eventPatchEventInput: EventPatchEventInput,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/events/${id}`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: eventPatchEventInput,
    },
    options
  )
}

export type PatchEventMutationResult = NonNullable<Awaited<ReturnType<typeof patchEvent>>>
export type PatchEventMutationBody = EventPatchEventInput
export type PatchEventMutationError = void

export const usePatchEvent = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchEvent>>,
    TError,
    { id: string; data: EventPatchEventInput },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchEvent>>,
    { id: string; data: EventPatchEventInput }
  > = props => {
    const { id, data } = props ?? {}

    return patchEvent(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchEvent>>,
    TError,
    { id: string; data: EventPatchEventInput },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves a Event resource.
 * @summary Retrieves a Event resource.
 */
export const getEventWithTranslations = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<EventEventWithTranslationsDTO>(
    { url: `/api/events/${id}/translations`, method: "get", signal },
    options
  )
}

export const getGetEventWithTranslationsQueryKey = (id: string) => [
  `/api/events/${id}/translations`,
]

export type GetEventWithTranslationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEventWithTranslations>>
>
export type GetEventWithTranslationsQueryError = void

export const useGetEventWithTranslations = <
  TData = Awaited<ReturnType<typeof getEventWithTranslations>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getEventWithTranslations>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetEventWithTranslationsQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventWithTranslations>>> = ({
    signal,
  }) => getEventWithTranslations(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getEventWithTranslations>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Creates a Hobby resource.
 * @summary Creates a Hobby resource.
 */
export const postHobby = (
  hobbyPostHobbyInputJsonld: HobbyPostHobbyInputJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/hobbies`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: hobbyPostHobbyInputJsonld,
    },
    options
  )
}

export type PostHobbyMutationResult = NonNullable<Awaited<ReturnType<typeof postHobby>>>
export type PostHobbyMutationBody = HobbyPostHobbyInputJsonld
export type PostHobbyMutationError = void

export const usePostHobby = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postHobby>>,
    TError,
    { data: HobbyPostHobbyInputJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postHobby>>,
    { data: HobbyPostHobbyInputJsonld }
  > = props => {
    const { data } = props ?? {}

    return postHobby(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postHobby>>,
    TError,
    { data: HobbyPostHobbyInputJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Removes the Hobby resource.
 * @summary Removes the Hobby resource.
 */
export const deleteHobby = (id: string, options?: SecondParameter<typeof customMutator>) => {
  return customMutator<void>({ url: `/api/hobbies/${id}`, method: "delete" }, options)
}

export type DeleteHobbyMutationResult = NonNullable<Awaited<ReturnType<typeof deleteHobby>>>

export type DeleteHobbyMutationError = unknown

export const useDeleteHobby = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteHobby>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteHobby>>,
    { id: string }
  > = props => {
    const { id } = props ?? {}

    return deleteHobby(id, requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof deleteHobby>>, TError, { id: string }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Updates the Hobby resource.
 * @summary Updates the Hobby resource.
 */
export const patchHobby = (
  id: string,
  hobbyPatchHobbyInput: HobbyPatchHobbyInput,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/hobbies/${id}`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: hobbyPatchHobbyInput,
    },
    options
  )
}

export type PatchHobbyMutationResult = NonNullable<Awaited<ReturnType<typeof patchHobby>>>
export type PatchHobbyMutationBody = HobbyPatchHobbyInput
export type PatchHobbyMutationError = void

export const usePatchHobby = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchHobby>>,
    TError,
    { id: string; data: HobbyPatchHobbyInput },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchHobby>>,
    { id: string; data: HobbyPatchHobbyInput }
  > = props => {
    const { id, data } = props ?? {}

    return patchHobby(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchHobby>>,
    TError,
    { id: string; data: HobbyPatchHobbyInput },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves a Hobby resource.
 * @summary Retrieves a Hobby resource.
 */
export const getHobbyTranslations = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<HobbyTranslationsDTO>(
    { url: `/api/hobbies/${id}/translations`, method: "get", signal },
    options
  )
}

export const getGetHobbyTranslationsQueryKey = (id: string) => [`/api/hobbies/${id}/translations`]

export type GetHobbyTranslationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getHobbyTranslations>>
>
export type GetHobbyTranslationsQueryError = void

export const useGetHobbyTranslations = <
  TData = Awaited<ReturnType<typeof getHobbyTranslations>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getHobbyTranslations>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetHobbyTranslationsQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHobbyTranslations>>> = ({ signal }) =>
    getHobbyTranslations(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getHobbyTranslations>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of HobbyCategory resources.
 * @summary Retrieves the collection of HobbyCategory resources.
 */
export const getHobbyCategories = (
  params?: GetHobbyCategoriesParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetHobbyCategories200>(
    { url: `/api/hobby-categories`, method: "get", params, signal },
    options
  )
}

export const getGetHobbyCategoriesQueryKey = (params?: GetHobbyCategoriesParams) => [
  `/api/hobby-categories`,
  ...(params ? [params] : []),
]

export type GetHobbyCategoriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getHobbyCategories>>
>
export type GetHobbyCategoriesQueryError = unknown

export const useGetHobbyCategories = <
  TData = Awaited<ReturnType<typeof getHobbyCategories>>,
  TError = unknown
>(
  params?: GetHobbyCategoriesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getHobbyCategories>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetHobbyCategoriesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHobbyCategories>>> = ({ signal }) =>
    getHobbyCategories(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getHobbyCategories>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Creates a HobbyCategory resource.
 * @summary Creates a HobbyCategory resource.
 */
export const postHobbyCategory = (
  hobbyCategoryPostHobbyCategoryInputJsonld: HobbyCategoryPostHobbyCategoryInputJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/hobby-categories`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: hobbyCategoryPostHobbyCategoryInputJsonld,
    },
    options
  )
}

export type PostHobbyCategoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof postHobbyCategory>>
>
export type PostHobbyCategoryMutationBody = HobbyCategoryPostHobbyCategoryInputJsonld
export type PostHobbyCategoryMutationError = void

export const usePostHobbyCategory = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postHobbyCategory>>,
    TError,
    { data: HobbyCategoryPostHobbyCategoryInputJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postHobbyCategory>>,
    { data: HobbyCategoryPostHobbyCategoryInputJsonld }
  > = props => {
    const { data } = props ?? {}

    return postHobbyCategory(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postHobbyCategory>>,
    TError,
    { data: HobbyCategoryPostHobbyCategoryInputJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Removes the HobbyCategory resource.
 * @summary Removes the HobbyCategory resource.
 */
export const deleteHobbyCategory = (
  id: string,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<void>({ url: `/api/hobby-categories/${id}`, method: "delete" }, options)
}

export type DeleteHobbyCategoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteHobbyCategory>>
>

export type DeleteHobbyCategoryMutationError = unknown

export const useDeleteHobbyCategory = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteHobbyCategory>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteHobbyCategory>>,
    { id: string }
  > = props => {
    const { id } = props ?? {}

    return deleteHobbyCategory(id, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof deleteHobbyCategory>>,
    TError,
    { id: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Updates the HobbyCategory resource.
 * @summary Updates the HobbyCategory resource.
 */
export const patchHobbyCategory = (
  id: string,
  hobbyCategoryPatchHobbyCategoryInput: HobbyCategoryPatchHobbyCategoryInput,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/hobby-categories/${id}`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: hobbyCategoryPatchHobbyCategoryInput,
    },
    options
  )
}

export type PatchHobbyCategoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchHobbyCategory>>
>
export type PatchHobbyCategoryMutationBody = HobbyCategoryPatchHobbyCategoryInput
export type PatchHobbyCategoryMutationError = void

export const usePatchHobbyCategory = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchHobbyCategory>>,
    TError,
    { id: string; data: HobbyCategoryPatchHobbyCategoryInput },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchHobbyCategory>>,
    { id: string; data: HobbyCategoryPatchHobbyCategoryInput }
  > = props => {
    const { id, data } = props ?? {}

    return patchHobbyCategory(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchHobbyCategory>>,
    TError,
    { id: string; data: HobbyCategoryPatchHobbyCategoryInput },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves a HobbyCategory resource.
 * @summary Retrieves a HobbyCategory resource.
 */
export const getHobbyCategoryTranslations = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<HobbyCategoryTranslationsDTO>(
    { url: `/api/hobby-categories/${id}/translations`, method: "get", signal },
    options
  )
}

export const getGetHobbyCategoryTranslationsQueryKey = (id: string) => [
  `/api/hobby-categories/${id}/translations`,
]

export type GetHobbyCategoryTranslationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getHobbyCategoryTranslations>>
>
export type GetHobbyCategoryTranslationsQueryError = void

export const useGetHobbyCategoryTranslations = <
  TData = Awaited<ReturnType<typeof getHobbyCategoryTranslations>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getHobbyCategoryTranslations>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetHobbyCategoryTranslationsQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHobbyCategoryTranslations>>> = ({
    signal,
  }) => getHobbyCategoryTranslations(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getHobbyCategoryTranslations>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Creates a InterBuProgressStep resource.
 * @summary Creates a InterBuProgressStep resource.
 */
export const postInterBuProgressStep = (
  interBuProgressStepCreateInterBuProgressStepInput: InterBuProgressStepCreateInterBuProgressStepInput,
  options?: SecondParameter<typeof customMutator>
) => {
  const formData = customFormDataFn(interBuProgressStepCreateInterBuProgressStepInput)
  return customMutator<unknown>(
    {
      url: `/api/inter-bu-progress-steps`,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  )
}

export type PostInterBuProgressStepMutationResult = NonNullable<
  Awaited<ReturnType<typeof postInterBuProgressStep>>
>
export type PostInterBuProgressStepMutationBody = InterBuProgressStepCreateInterBuProgressStepInput
export type PostInterBuProgressStepMutationError = void

export const usePostInterBuProgressStep = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postInterBuProgressStep>>,
    TError,
    { data: InterBuProgressStepCreateInterBuProgressStepInput },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postInterBuProgressStep>>,
    { data: InterBuProgressStepCreateInterBuProgressStepInput }
  > = props => {
    const { data } = props ?? {}

    return postInterBuProgressStep(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postInterBuProgressStep>>,
    TError,
    { data: InterBuProgressStepCreateInterBuProgressStepInput },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Creates a InterBu resource.
 * @summary Creates a InterBu resource.
 */
export const postInterBu = (
  interBuCreateInterBUsInputJsonld: InterBuCreateInterBUsInputJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/inter-bus`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: interBuCreateInterBUsInputJsonld,
    },
    options
  )
}

export type PostInterBuMutationResult = NonNullable<Awaited<ReturnType<typeof postInterBu>>>
export type PostInterBuMutationBody = InterBuCreateInterBUsInputJsonld
export type PostInterBuMutationError = void

export const usePostInterBu = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postInterBu>>,
    TError,
    { data: InterBuCreateInterBUsInputJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postInterBu>>,
    { data: InterBuCreateInterBUsInputJsonld }
  > = props => {
    const { data } = props ?? {}

    return postInterBu(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postInterBu>>,
    TError,
    { data: InterBuCreateInterBUsInputJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves the collection of InterBu resources.
 * @summary Retrieves the collection of InterBu resources.
 */
export const getBuAdminInterBUs = (
  params?: GetBuAdminInterBUsParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetBuAdminInterBUs200>(
    { url: `/api/inter-bus/bu-admin-inter-bu`, method: "get", params, signal },
    options
  )
}

export const getGetBuAdminInterBUsQueryKey = (params?: GetBuAdminInterBUsParams) => [
  `/api/inter-bus/bu-admin-inter-bu`,
  ...(params ? [params] : []),
]

export type GetBuAdminInterBUsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBuAdminInterBUs>>
>
export type GetBuAdminInterBUsQueryError = unknown

export const useGetBuAdminInterBUs = <
  TData = Awaited<ReturnType<typeof getBuAdminInterBUs>>,
  TError = unknown
>(
  params?: GetBuAdminInterBUsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBuAdminInterBUs>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetBuAdminInterBUsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBuAdminInterBUs>>> = ({ signal }) =>
    getBuAdminInterBUs(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getBuAdminInterBUs>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of InterBu resources.
 * @summary Retrieves the collection of InterBu resources.
 */
export const getAssignedInterBUs = (
  params?: GetAssignedInterBUsParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetAssignedInterBUs200>(
    { url: `/api/inter-bus/user-assigned-inter-bu`, method: "get", params, signal },
    options
  )
}

export const getGetAssignedInterBUsQueryKey = (params?: GetAssignedInterBUsParams) => [
  `/api/inter-bus/user-assigned-inter-bu`,
  ...(params ? [params] : []),
]

export type GetAssignedInterBUsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAssignedInterBUs>>
>
export type GetAssignedInterBUsQueryError = unknown

export const useGetAssignedInterBUs = <
  TData = Awaited<ReturnType<typeof getAssignedInterBUs>>,
  TError = unknown
>(
  params?: GetAssignedInterBUsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAssignedInterBUs>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAssignedInterBUsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssignedInterBUs>>> = ({ signal }) =>
    getAssignedInterBUs(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getAssignedInterBUs>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of InterBu resources.
 * @summary Retrieves the collection of InterBu resources.
 */
export const getSentInterBUs = (
  params?: GetSentInterBUsParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetSentInterBUs200>(
    { url: `/api/inter-bus/user-sent-inter-bu`, method: "get", params, signal },
    options
  )
}

export const getGetSentInterBUsQueryKey = (params?: GetSentInterBUsParams) => [
  `/api/inter-bus/user-sent-inter-bu`,
  ...(params ? [params] : []),
]

export type GetSentInterBUsQueryResult = NonNullable<Awaited<ReturnType<typeof getSentInterBUs>>>
export type GetSentInterBUsQueryError = unknown

export const useGetSentInterBUs = <
  TData = Awaited<ReturnType<typeof getSentInterBUs>>,
  TError = unknown
>(
  params?: GetSentInterBUsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSentInterBUs>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSentInterBUsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSentInterBUs>>> = ({ signal }) =>
    getSentInterBUs(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getSentInterBUs>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves a InterBu resource.
 * @summary Retrieves a InterBu resource.
 */
export const getInterBu = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<InterBuJsonldReadItemInterbu>(
    { url: `/api/inter-bus/${id}`, method: "get", signal },
    options
  )
}

export const getGetInterBuQueryKey = (id: string) => [`/api/inter-bus/${id}`]

export type GetInterBuQueryResult = NonNullable<Awaited<ReturnType<typeof getInterBu>>>
export type GetInterBuQueryError = void

export const useGetInterBu = <TData = Awaited<ReturnType<typeof getInterBu>>, TError = void>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getInterBu>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetInterBuQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInterBu>>> = ({ signal }) =>
    getInterBu(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getInterBu>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * @summary Use email and password to get JWT access token and refresh token.
 */
export const postCredentials = (
  credentials: Credentials,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<Token>(
    {
      url: `/api/login_check`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: credentials,
    },
    options
  )
}

export type PostCredentialsMutationResult = NonNullable<Awaited<ReturnType<typeof postCredentials>>>
export type PostCredentialsMutationBody = Credentials
export type PostCredentialsMutationError = unknown

export const usePostCredentials = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCredentials>>,
    TError,
    { data: Credentials },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCredentials>>,
    { data: Credentials }
  > = props => {
    const { data } = props ?? {}

    return postCredentials(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postCredentials>>,
    TError,
    { data: Credentials },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves the collection of MBV4 Synchro resources.
 * @summary Retrieves the collection of MBV4 Synchro resources.
 */
export const getBarnesStatusDeleted = (
  params?: GetBarnesStatusDeletedParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetBarnesStatusDeleted200>(
    { url: `/api/mbv4/barnes-status-deleted`, method: "get", params, signal },
    options
  )
}

export const getGetBarnesStatusDeletedQueryKey = (params?: GetBarnesStatusDeletedParams) => [
  `/api/mbv4/barnes-status-deleted`,
  ...(params ? [params] : []),
]

export type GetBarnesStatusDeletedQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBarnesStatusDeleted>>
>
export type GetBarnesStatusDeletedQueryError = unknown

export const useGetBarnesStatusDeleted = <
  TData = Awaited<ReturnType<typeof getBarnesStatusDeleted>>,
  TError = unknown
>(
  params?: GetBarnesStatusDeletedParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBarnesStatusDeleted>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetBarnesStatusDeletedQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBarnesStatusDeleted>>> = ({ signal }) =>
    getBarnesStatusDeleted(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getBarnesStatusDeleted>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of MBV4 Synchro resources.
 * @summary Retrieves the collection of MBV4 Synchro resources.
 */
export const getBarnesStatusUpdated = (
  params?: GetBarnesStatusUpdatedParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetBarnesStatusUpdated200>(
    { url: `/api/mbv4/barnes-status-updated`, method: "get", params, signal },
    options
  )
}

export const getGetBarnesStatusUpdatedQueryKey = (params?: GetBarnesStatusUpdatedParams) => [
  `/api/mbv4/barnes-status-updated`,
  ...(params ? [params] : []),
]

export type GetBarnesStatusUpdatedQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBarnesStatusUpdated>>
>
export type GetBarnesStatusUpdatedQueryError = unknown

export const useGetBarnesStatusUpdated = <
  TData = Awaited<ReturnType<typeof getBarnesStatusUpdated>>,
  TError = unknown
>(
  params?: GetBarnesStatusUpdatedParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBarnesStatusUpdated>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetBarnesStatusUpdatedQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBarnesStatusUpdated>>> = ({ signal }) =>
    getBarnesStatusUpdated(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getBarnesStatusUpdated>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Creates a MBV4 Synchro resource.
 * @summary Creates a MBV4 Synchro resource.
 */
export const postLaunchImport = (
  mBV4SynchroPostMbv4ImportInputJsonld: MBV4SynchroPostMbv4ImportInputJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<MBV4SynchroJsonld>(
    {
      url: `/api/mbv4/import-launcher`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: mBV4SynchroPostMbv4ImportInputJsonld,
    },
    options
  )
}

export type PostLaunchImportMutationResult = NonNullable<
  Awaited<ReturnType<typeof postLaunchImport>>
>
export type PostLaunchImportMutationBody = MBV4SynchroPostMbv4ImportInputJsonld
export type PostLaunchImportMutationError = void

export const usePostLaunchImport = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLaunchImport>>,
    TError,
    { data: MBV4SynchroPostMbv4ImportInputJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postLaunchImport>>,
    { data: MBV4SynchroPostMbv4ImportInputJsonld }
  > = props => {
    const { data } = props ?? {}

    return postLaunchImport(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postLaunchImport>>,
    TError,
    { data: MBV4SynchroPostMbv4ImportInputJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Creates a MBV4 Synchro resource.
 * @summary Creates a MBV4 Synchro resource.
 */
export const postLaunchSynchro = (
  mBV4SynchroPostMbv4SynchroInputJsonld: MBV4SynchroPostMbv4SynchroInputJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<MBV4SynchroJsonld>(
    {
      url: `/api/mbv4/synchro-launcher`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: mBV4SynchroPostMbv4SynchroInputJsonld,
    },
    options
  )
}

export type PostLaunchSynchroMutationResult = NonNullable<
  Awaited<ReturnType<typeof postLaunchSynchro>>
>
export type PostLaunchSynchroMutationBody = MBV4SynchroPostMbv4SynchroInputJsonld
export type PostLaunchSynchroMutationError = void

export const usePostLaunchSynchro = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLaunchSynchro>>,
    TError,
    { data: MBV4SynchroPostMbv4SynchroInputJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postLaunchSynchro>>,
    { data: MBV4SynchroPostMbv4SynchroInputJsonld }
  > = props => {
    const { data } = props ?? {}

    return postLaunchSynchro(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postLaunchSynchro>>,
    TError,
    { data: MBV4SynchroPostMbv4SynchroInputJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Creates a MBV4 Synchro resource.
 * @summary Creates a MBV4 Synchro resource.
 */
export const postLoadSynchroLog = (
  mBV4SynchroSynchroLogDTOJsonld: MBV4SynchroSynchroLogDTOJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/mbv4/synchro-log`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: mBV4SynchroSynchroLogDTOJsonld,
    },
    options
  )
}

export type PostLoadSynchroLogMutationResult = NonNullable<
  Awaited<ReturnType<typeof postLoadSynchroLog>>
>
export type PostLoadSynchroLogMutationBody = MBV4SynchroSynchroLogDTOJsonld
export type PostLoadSynchroLogMutationError = void

export const usePostLoadSynchroLog = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLoadSynchroLog>>,
    TError,
    { data: MBV4SynchroSynchroLogDTOJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postLoadSynchroLog>>,
    { data: MBV4SynchroSynchroLogDTOJsonld }
  > = props => {
    const { data } = props ?? {}

    return postLoadSynchroLog(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postLoadSynchroLog>>,
    TError,
    { data: MBV4SynchroSynchroLogDTOJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves the collection of MBV4 Synchro resources.
 * @summary Retrieves the collection of MBV4 Synchro resources.
 */
export const getSynchroStatus = (
  params?: GetSynchroStatusParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetSynchroStatus200>(
    { url: `/api/mbv4/synchro-status`, method: "get", params, signal },
    options
  )
}

export const getGetSynchroStatusQueryKey = (params?: GetSynchroStatusParams) => [
  `/api/mbv4/synchro-status`,
  ...(params ? [params] : []),
]

export type GetSynchroStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getSynchroStatus>>>
export type GetSynchroStatusQueryError = unknown

export const useGetSynchroStatus = <
  TData = Awaited<ReturnType<typeof getSynchroStatus>>,
  TError = unknown
>(
  params?: GetSynchroStatusParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSynchroStatus>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSynchroStatusQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSynchroStatus>>> = ({ signal }) =>
    getSynchroStatus(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getSynchroStatus>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of People resources.
 * @summary Retrieves the collection of People resources.
 */
export const getPeoples = (
  params?: GetPeoplesParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetPeoples200>(
    { url: `/api/people`, method: "get", params, signal },
    options
  )
}

export const getGetPeoplesQueryKey = (params?: GetPeoplesParams) => [
  `/api/people`,
  ...(params ? [params] : []),
]

export type GetPeoplesQueryResult = NonNullable<Awaited<ReturnType<typeof getPeoples>>>
export type GetPeoplesQueryError = unknown

export const useGetPeoples = <TData = Awaited<ReturnType<typeof getPeoples>>, TError = unknown>(
  params?: GetPeoplesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPeoples>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPeoplesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPeoples>>> = ({ signal }) =>
    getPeoples(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getPeoples>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of People resources.
 * @summary Retrieves the collection of People resources.
 */
export const getPeoplesExcelExportData = (
  params?: GetPeoplesExcelExportDataParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetPeoplesExcelExportData200>(
    { url: `/api/people/excel-export`, method: "get", params, signal },
    options
  )
}

export const getGetPeoplesExcelExportDataQueryKey = (params?: GetPeoplesExcelExportDataParams) => [
  `/api/people/excel-export`,
  ...(params ? [params] : []),
]

export type GetPeoplesExcelExportDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPeoplesExcelExportData>>
>
export type GetPeoplesExcelExportDataQueryError = unknown

export const useGetPeoplesExcelExportData = <
  TData = Awaited<ReturnType<typeof getPeoplesExcelExportData>>,
  TError = unknown
>(
  params?: GetPeoplesExcelExportDataParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPeoplesExcelExportData>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPeoplesExcelExportDataQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPeoplesExcelExportData>>> = ({
    signal,
  }) => getPeoplesExcelExportData(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getPeoplesExcelExportData>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves a People resource.
 * @summary Retrieves a People resource.
 */
export const getPeople = (
  id: string,
  params?: GetPeopleParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<PeopleJsonldReadItemPeople>(
    { url: `/api/people/${id}`, method: "get", params, signal },
    options
  )
}

export const getGetPeopleQueryKey = (id: string, params?: GetPeopleParams) => [
  `/api/people/${id}`,
  ...(params ? [params] : []),
]

export type GetPeopleQueryResult = NonNullable<Awaited<ReturnType<typeof getPeople>>>
export type GetPeopleQueryError = void

export const useGetPeople = <TData = Awaited<ReturnType<typeof getPeople>>, TError = void>(
  id: string,
  params?: GetPeopleParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPeople>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPeopleQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPeople>>> = ({ signal }) =>
    getPeople(id, params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getPeople>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves a Project resource.
 * @summary Retrieves a Project resource.
 */
export const getProject = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<ProjectJsonldReadItemProject>(
    { url: `/api/project/${id}`, method: "get", signal },
    options
  )
}

export const getGetProjectQueryKey = (id: string) => [`/api/project/${id}`]

export type GetProjectQueryResult = NonNullable<Awaited<ReturnType<typeof getProject>>>
export type GetProjectQueryError = void

export const useGetProject = <TData = Awaited<ReturnType<typeof getProject>>, TError = void>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProject>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProjectQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProject>>> = ({ signal }) =>
    getProject(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getProject>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of Project resources.
 * @summary Retrieves the collection of Project resources.
 */
export const getProjects = (
  params?: GetProjectsParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetProjects200>(
    { url: `/api/projects`, method: "get", params, signal },
    options
  )
}

export const getGetProjectsQueryKey = (params?: GetProjectsParams) => [
  `/api/projects`,
  ...(params ? [params] : []),
]

export type GetProjectsQueryResult = NonNullable<Awaited<ReturnType<typeof getProjects>>>
export type GetProjectsQueryError = unknown

export const useGetProjects = <TData = Awaited<ReturnType<typeof getProjects>>, TError = unknown>(
  params?: GetProjectsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProjects>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProjectsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjects>>> = ({ signal }) =>
    getProjects(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getProjects>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of Project resources.
 * @summary Retrieves the collection of Project resources.
 */
export const getProjectsExcelExportData = (
  params?: GetProjectsExcelExportDataParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetProjectsExcelExportData200>(
    { url: `/api/projects/excel-export`, method: "get", params, signal },
    options
  )
}

export const getGetProjectsExcelExportDataQueryKey = (
  params?: GetProjectsExcelExportDataParams
) => [`/api/projects/excel-export`, ...(params ? [params] : [])]

export type GetProjectsExcelExportDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectsExcelExportData>>
>
export type GetProjectsExcelExportDataQueryError = unknown

export const useGetProjectsExcelExportData = <
  TData = Awaited<ReturnType<typeof getProjectsExcelExportData>>,
  TError = unknown
>(
  params?: GetProjectsExcelExportDataParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProjectsExcelExportData>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProjectsExcelExportDataQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectsExcelExportData>>> = ({
    signal,
  }) => getProjectsExcelExportData(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getProjectsExcelExportData>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of Property resources.
 * @summary Retrieves the collection of Property resources.
 */
export const getProperties = (
  params?: GetPropertiesParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetProperties200>(
    { url: `/api/properties`, method: "get", params, signal },
    options
  )
}

export const getGetPropertiesQueryKey = (params?: GetPropertiesParams) => [
  `/api/properties`,
  ...(params ? [params] : []),
]

export type GetPropertiesQueryResult = NonNullable<Awaited<ReturnType<typeof getProperties>>>
export type GetPropertiesQueryError = unknown

export const useGetProperties = <
  TData = Awaited<ReturnType<typeof getProperties>>,
  TError = unknown
>(
  params?: GetPropertiesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProperties>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPropertiesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProperties>>> = ({ signal }) =>
    getProperties(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getProperties>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of Property resources.
 * @summary Retrieves the collection of Property resources.
 */
export const getPropertiesExcelExportData = (
  params?: GetPropertiesExcelExportDataParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetPropertiesExcelExportData200>(
    { url: `/api/properties/excel-export`, method: "get", params, signal },
    options
  )
}

export const getGetPropertiesExcelExportDataQueryKey = (
  params?: GetPropertiesExcelExportDataParams
) => [`/api/properties/excel-export`, ...(params ? [params] : [])]

export type GetPropertiesExcelExportDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPropertiesExcelExportData>>
>
export type GetPropertiesExcelExportDataQueryError = unknown

export const useGetPropertiesExcelExportData = <
  TData = Awaited<ReturnType<typeof getPropertiesExcelExportData>>,
  TError = unknown
>(
  params?: GetPropertiesExcelExportDataParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPropertiesExcelExportData>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPropertiesExcelExportDataQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPropertiesExcelExportData>>> = ({
    signal,
  }) => getPropertiesExcelExportData(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getPropertiesExcelExportData>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves a PropertyOffer resource.
 * @summary Retrieves a PropertyOffer resource.
 */
export const getPropertyOffer = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<PropertyOfferJsonldReadItemPropertyOffer>(
    { url: `/api/property-offer/${id}`, method: "get", signal },
    options
  )
}

export const getGetPropertyOfferQueryKey = (id: string) => [`/api/property-offer/${id}`]

export type GetPropertyOfferQueryResult = NonNullable<Awaited<ReturnType<typeof getPropertyOffer>>>
export type GetPropertyOfferQueryError = void

export const useGetPropertyOffer = <
  TData = Awaited<ReturnType<typeof getPropertyOffer>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPropertyOffer>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPropertyOfferQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPropertyOffer>>> = ({ signal }) =>
    getPropertyOffer(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getPropertyOffer>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of PropertyOffer resources.
 * @summary Retrieves the collection of PropertyOffer resources.
 */
export const getPropertiesOffer = (
  params?: GetPropertiesOfferParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetPropertiesOffer200>(
    { url: `/api/property-offers`, method: "get", params, signal },
    options
  )
}

export const getGetPropertiesOfferQueryKey = (params?: GetPropertiesOfferParams) => [
  `/api/property-offers`,
  ...(params ? [params] : []),
]

export type GetPropertiesOfferQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPropertiesOffer>>
>
export type GetPropertiesOfferQueryError = unknown

export const useGetPropertiesOffer = <
  TData = Awaited<ReturnType<typeof getPropertiesOffer>>,
  TError = unknown
>(
  params?: GetPropertiesOfferParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPropertiesOffer>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPropertiesOfferQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPropertiesOffer>>> = ({ signal }) =>
    getPropertiesOffer(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getPropertiesOffer>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of PropertyOffer resources.
 * @summary Retrieves the collection of PropertyOffer resources.
 */
export const getPropertiesOfferOfferExcelExportData = (
  params?: GetPropertiesOfferOfferExcelExportDataParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetPropertiesOfferOfferExcelExportData200>(
    { url: `/api/property-offers/excel-export`, method: "get", params, signal },
    options
  )
}

export const getGetPropertiesOfferOfferExcelExportDataQueryKey = (
  params?: GetPropertiesOfferOfferExcelExportDataParams
) => [`/api/property-offers/excel-export`, ...(params ? [params] : [])]

export type GetPropertiesOfferOfferExcelExportDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPropertiesOfferOfferExcelExportData>>
>
export type GetPropertiesOfferOfferExcelExportDataQueryError = unknown

export const useGetPropertiesOfferOfferExcelExportData = <
  TData = Awaited<ReturnType<typeof getPropertiesOfferOfferExcelExportData>>,
  TError = unknown
>(
  params?: GetPropertiesOfferOfferExcelExportDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPropertiesOfferOfferExcelExportData>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetPropertiesOfferOfferExcelExportDataQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPropertiesOfferOfferExcelExportData>>
  > = ({ signal }) => getPropertiesOfferOfferExcelExportData(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getPropertiesOfferOfferExcelExportData>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves a PropertySearch resource.
 * @summary Retrieves a PropertySearch resource.
 */
export const getPropertySearch = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<PropertySearchJsonldReadItemPropertySearch>(
    { url: `/api/property-search/${id}`, method: "get", signal },
    options
  )
}

export const getGetPropertySearchQueryKey = (id: string) => [`/api/property-search/${id}`]

export type GetPropertySearchQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPropertySearch>>
>
export type GetPropertySearchQueryError = void

export const useGetPropertySearch = <
  TData = Awaited<ReturnType<typeof getPropertySearch>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPropertySearch>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPropertySearchQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPropertySearch>>> = ({ signal }) =>
    getPropertySearch(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getPropertySearch>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of PropertySearch resources.
 * @summary Retrieves the collection of PropertySearch resources.
 */
export const getPropertiesSearch = (
  params?: GetPropertiesSearchParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetPropertiesSearch200>(
    { url: `/api/property-searches`, method: "get", params, signal },
    options
  )
}

export const getGetPropertiesSearchQueryKey = (params?: GetPropertiesSearchParams) => [
  `/api/property-searches`,
  ...(params ? [params] : []),
]

export type GetPropertiesSearchQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPropertiesSearch>>
>
export type GetPropertiesSearchQueryError = unknown

export const useGetPropertiesSearch = <
  TData = Awaited<ReturnType<typeof getPropertiesSearch>>,
  TError = unknown
>(
  params?: GetPropertiesSearchParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPropertiesSearch>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPropertiesSearchQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPropertiesSearch>>> = ({ signal }) =>
    getPropertiesSearch(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getPropertiesSearch>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of PropertySearch resources.
 * @summary Retrieves the collection of PropertySearch resources.
 */
export const getPropertiesSearchExcelExportData = (
  params?: GetPropertiesSearchExcelExportDataParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetPropertiesSearchExcelExportData200>(
    { url: `/api/property-searches/excel-export`, method: "get", params, signal },
    options
  )
}

export const getGetPropertiesSearchExcelExportDataQueryKey = (
  params?: GetPropertiesSearchExcelExportDataParams
) => [`/api/property-searches/excel-export`, ...(params ? [params] : [])]

export type GetPropertiesSearchExcelExportDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPropertiesSearchExcelExportData>>
>
export type GetPropertiesSearchExcelExportDataQueryError = unknown

export const useGetPropertiesSearchExcelExportData = <
  TData = Awaited<ReturnType<typeof getPropertiesSearchExcelExportData>>,
  TError = unknown
>(
  params?: GetPropertiesSearchExcelExportDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPropertiesSearchExcelExportData>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPropertiesSearchExcelExportDataQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPropertiesSearchExcelExportData>>> = ({
    signal,
  }) => getPropertiesSearchExcelExportData(params, requestOptions, signal)

  const query = useQuery<
    Awaited<ReturnType<typeof getPropertiesSearchExcelExportData>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves a Property resource.
 * @summary Retrieves a Property resource.
 */
export const getProperty = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<PropertyJsonldReadItemProperty>(
    { url: `/api/property/${id}`, method: "get", signal },
    options
  )
}

export const getGetPropertyQueryKey = (id: string) => [`/api/property/${id}`]

export type GetPropertyQueryResult = NonNullable<Awaited<ReturnType<typeof getProperty>>>
export type GetPropertyQueryError = void

export const useGetProperty = <TData = Awaited<ReturnType<typeof getProperty>>, TError = void>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProperty>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPropertyQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProperty>>> = ({ signal }) =>
    getProperty(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getProperty>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Removes the RefLabelGroupRelation resource.
 * @summary Removes the RefLabelGroupRelation resource.
 */
export const deleteRefLabelRelation = (
  id: string,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<void>(
    { url: `/api/ref-label-group-relations/${id}`, method: "delete" },
    options
  )
}

export type DeleteRefLabelRelationMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteRefLabelRelation>>
>

export type DeleteRefLabelRelationMutationError = unknown

export const useDeleteRefLabelRelation = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteRefLabelRelation>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteRefLabelRelation>>,
    { id: string }
  > = props => {
    const { id } = props ?? {}

    return deleteRefLabelRelation(id, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof deleteRefLabelRelation>>,
    TError,
    { id: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves a RefLabelGroup resource.
 * @summary Retrieves a RefLabelGroup resource.
 */
export const getRefLabelGroup = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<RefLabelGroupJsonldReadItemRefLabelGroupItem>(
    { url: `/api/ref-label-group/${id}`, method: "get", signal },
    options
  )
}

export const getGetRefLabelGroupQueryKey = (id: string) => [`/api/ref-label-group/${id}`]

export type GetRefLabelGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getRefLabelGroup>>>
export type GetRefLabelGroupQueryError = void

export const useGetRefLabelGroup = <
  TData = Awaited<ReturnType<typeof getRefLabelGroup>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRefLabelGroup>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRefLabelGroupQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRefLabelGroup>>> = ({ signal }) =>
    getRefLabelGroup(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getRefLabelGroup>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of RefLabelGroup resources.
 * @summary Retrieves the collection of RefLabelGroup resources.
 */
export const getRefLabelGroups = (
  params?: GetRefLabelGroupsParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetRefLabelGroups200>(
    { url: `/api/ref-label-groups`, method: "get", params, signal },
    options
  )
}

export const getGetRefLabelGroupsQueryKey = (params?: GetRefLabelGroupsParams) => [
  `/api/ref-label-groups`,
  ...(params ? [params] : []),
]

export type GetRefLabelGroupsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRefLabelGroups>>
>
export type GetRefLabelGroupsQueryError = unknown

export const useGetRefLabelGroups = <
  TData = Awaited<ReturnType<typeof getRefLabelGroups>>,
  TError = unknown
>(
  params?: GetRefLabelGroupsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRefLabelGroups>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRefLabelGroupsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRefLabelGroups>>> = ({ signal }) =>
    getRefLabelGroups(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getRefLabelGroups>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Creates a RefLabelGroup resource.
 * @summary Creates a RefLabelGroup resource.
 */
export const postRefLabelGroup = (
  refLabelGroupPostRefLabelGroupInputJsonld: RefLabelGroupPostRefLabelGroupInputJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/ref-label-groups`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: refLabelGroupPostRefLabelGroupInputJsonld,
    },
    options
  )
}

export type PostRefLabelGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof postRefLabelGroup>>
>
export type PostRefLabelGroupMutationBody = RefLabelGroupPostRefLabelGroupInputJsonld
export type PostRefLabelGroupMutationError = void

export const usePostRefLabelGroup = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postRefLabelGroup>>,
    TError,
    { data: RefLabelGroupPostRefLabelGroupInputJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postRefLabelGroup>>,
    { data: RefLabelGroupPostRefLabelGroupInputJsonld }
  > = props => {
    const { data } = props ?? {}

    return postRefLabelGroup(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postRefLabelGroup>>,
    TError,
    { data: RefLabelGroupPostRefLabelGroupInputJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Removes the RefLabelGroup resource.
 * @summary Removes the RefLabelGroup resource.
 */
export const deleteRefLabelGroup = (
  id: string,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<void>({ url: `/api/ref-label-groups/${id}`, method: "delete" }, options)
}

export type DeleteRefLabelGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteRefLabelGroup>>
>

export type DeleteRefLabelGroupMutationError = unknown

export const useDeleteRefLabelGroup = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteRefLabelGroup>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteRefLabelGroup>>,
    { id: string }
  > = props => {
    const { id } = props ?? {}

    return deleteRefLabelGroup(id, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof deleteRefLabelGroup>>,
    TError,
    { id: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Updates the RefLabelGroup resource.
 * @summary Updates the RefLabelGroup resource.
 */
export const patchRefLabelGroup = (
  id: string,
  refLabelGroupPatchRefLabelGroupInput: RefLabelGroupPatchRefLabelGroupInput,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/ref-label-groups/${id}`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: refLabelGroupPatchRefLabelGroupInput,
    },
    options
  )
}

export type PatchRefLabelGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchRefLabelGroup>>
>
export type PatchRefLabelGroupMutationBody = RefLabelGroupPatchRefLabelGroupInput
export type PatchRefLabelGroupMutationError = void

export const usePatchRefLabelGroup = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchRefLabelGroup>>,
    TError,
    { id: string; data: RefLabelGroupPatchRefLabelGroupInput },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchRefLabelGroup>>,
    { id: string; data: RefLabelGroupPatchRefLabelGroupInput }
  > = props => {
    const { id, data } = props ?? {}

    return patchRefLabelGroup(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchRefLabelGroup>>,
    TError,
    { id: string; data: RefLabelGroupPatchRefLabelGroupInput },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves a RefLabel resource.
 * @summary Retrieves a RefLabel resource.
 */
export const getRefLabel = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<RefLabelJsonldReadItemRefLabelItem>(
    { url: `/api/ref-label/${id}`, method: "get", signal },
    options
  )
}

export const getGetRefLabelQueryKey = (id: string) => [`/api/ref-label/${id}`]

export type GetRefLabelQueryResult = NonNullable<Awaited<ReturnType<typeof getRefLabel>>>
export type GetRefLabelQueryError = void

export const useGetRefLabel = <TData = Awaited<ReturnType<typeof getRefLabel>>, TError = void>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRefLabel>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRefLabelQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRefLabel>>> = ({ signal }) =>
    getRefLabel(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getRefLabel>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of RefLabel resources.
 * @summary Retrieves the collection of RefLabel resources.
 */
export const getRefLabels = (
  params?: GetRefLabelsParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetRefLabels200>(
    { url: `/api/ref-labels`, method: "get", params, signal },
    options
  )
}

export const getGetRefLabelsQueryKey = (params?: GetRefLabelsParams) => [
  `/api/ref-labels`,
  ...(params ? [params] : []),
]

export type GetRefLabelsQueryResult = NonNullable<Awaited<ReturnType<typeof getRefLabels>>>
export type GetRefLabelsQueryError = unknown

export const useGetRefLabels = <TData = Awaited<ReturnType<typeof getRefLabels>>, TError = unknown>(
  params?: GetRefLabelsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRefLabels>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRefLabelsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRefLabels>>> = ({ signal }) =>
    getRefLabels(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getRefLabels>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Creates a RefLabel resource.
 * @summary Creates a RefLabel resource.
 */
export const postRefLabel = (
  refLabelPostRefLabelInputJsonld: RefLabelPostRefLabelInputJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/ref-labels`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: refLabelPostRefLabelInputJsonld,
    },
    options
  )
}

export type PostRefLabelMutationResult = NonNullable<Awaited<ReturnType<typeof postRefLabel>>>
export type PostRefLabelMutationBody = RefLabelPostRefLabelInputJsonld
export type PostRefLabelMutationError = void

export const usePostRefLabel = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postRefLabel>>,
    TError,
    { data: RefLabelPostRefLabelInputJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postRefLabel>>,
    { data: RefLabelPostRefLabelInputJsonld }
  > = props => {
    const { data } = props ?? {}

    return postRefLabel(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postRefLabel>>,
    TError,
    { data: RefLabelPostRefLabelInputJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves the collection of RefLabel resources.
 * @summary Retrieves the collection of RefLabel resources.
 */
export const getRefLabelsExcelExportData = (
  params?: GetRefLabelsExcelExportDataParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetRefLabelsExcelExportData200>(
    { url: `/api/ref-labels/excel-export`, method: "get", params, signal },
    options
  )
}

export const getGetRefLabelsExcelExportDataQueryKey = (
  params?: GetRefLabelsExcelExportDataParams
) => [`/api/ref-labels/excel-export`, ...(params ? [params] : [])]

export type GetRefLabelsExcelExportDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRefLabelsExcelExportData>>
>
export type GetRefLabelsExcelExportDataQueryError = unknown

export const useGetRefLabelsExcelExportData = <
  TData = Awaited<ReturnType<typeof getRefLabelsExcelExportData>>,
  TError = unknown
>(
  params?: GetRefLabelsExcelExportDataParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRefLabelsExcelExportData>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRefLabelsExcelExportDataQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRefLabelsExcelExportData>>> = ({
    signal,
  }) => getRefLabelsExcelExportData(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getRefLabelsExcelExportData>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Removes the RefLabel resource.
 * @summary Removes the RefLabel resource.
 */
export const deleteRefLabel = (id: string, options?: SecondParameter<typeof customMutator>) => {
  return customMutator<void>({ url: `/api/ref-labels/${id}`, method: "delete" }, options)
}

export type DeleteRefLabelMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRefLabel>>>

export type DeleteRefLabelMutationError = unknown

export const useDeleteRefLabel = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteRefLabel>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteRefLabel>>,
    { id: string }
  > = props => {
    const { id } = props ?? {}

    return deleteRefLabel(id, requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof deleteRefLabel>>, TError, { id: string }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Updates the RefLabel resource.
 * @summary Updates the RefLabel resource.
 */
export const patchRefLabel = (
  id: string,
  refLabelPatchRefLabelInput: RefLabelPatchRefLabelInput,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/ref-labels/${id}`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: refLabelPatchRefLabelInput,
    },
    options
  )
}

export type PatchRefLabelMutationResult = NonNullable<Awaited<ReturnType<typeof patchRefLabel>>>
export type PatchRefLabelMutationBody = RefLabelPatchRefLabelInput
export type PatchRefLabelMutationError = void

export const usePatchRefLabel = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchRefLabel>>,
    TError,
    { id: string; data: RefLabelPatchRefLabelInput },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchRefLabel>>,
    { id: string; data: RefLabelPatchRefLabelInput }
  > = props => {
    const { id, data } = props ?? {}

    return patchRefLabel(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchRefLabel>>,
    TError,
    { id: string; data: RefLabelPatchRefLabelInput },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Creates a ResetPasswordRequest resource.
 * @summary Creates a ResetPasswordRequest resource.
 */
export const postResetPassword = (
  resetPasswordRequestPostCheckResetPasswordRequestDTOJsonld: ResetPasswordRequestPostCheckResetPasswordRequestDTOJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<ResetPasswordRequestJsonld>(
    {
      url: `/api/reset/password`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: resetPasswordRequestPostCheckResetPasswordRequestDTOJsonld,
    },
    options
  )
}

export type PostResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof postResetPassword>>
>
export type PostResetPasswordMutationBody =
  ResetPasswordRequestPostCheckResetPasswordRequestDTOJsonld
export type PostResetPasswordMutationError = void

export const usePostResetPassword = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postResetPassword>>,
    TError,
    { data: ResetPasswordRequestPostCheckResetPasswordRequestDTOJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postResetPassword>>,
    { data: ResetPasswordRequestPostCheckResetPasswordRequestDTOJsonld }
  > = props => {
    const { data } = props ?? {}

    return postResetPassword(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postResetPassword>>,
    TError,
    { data: ResetPasswordRequestPostCheckResetPasswordRequestDTOJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Creates a ResetPasswordRequest resource.
 * @summary Creates a ResetPasswordRequest resource.
 */
export const postResetRequest = (
  resetPasswordRequestPostResetPasswordRequestDTOJsonld: ResetPasswordRequestPostResetPasswordRequestDTOJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<ResetPasswordRequestJsonld>(
    {
      url: `/api/reset/request`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: resetPasswordRequestPostResetPasswordRequestDTOJsonld,
    },
    options
  )
}

export type PostResetRequestMutationResult = NonNullable<
  Awaited<ReturnType<typeof postResetRequest>>
>
export type PostResetRequestMutationBody = ResetPasswordRequestPostResetPasswordRequestDTOJsonld
export type PostResetRequestMutationError = void

export const usePostResetRequest = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postResetRequest>>,
    TError,
    { data: ResetPasswordRequestPostResetPasswordRequestDTOJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postResetRequest>>,
    { data: ResetPasswordRequestPostResetPasswordRequestDTOJsonld }
  > = props => {
    const { data } = props ?? {}

    return postResetRequest(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postResetRequest>>,
    TError,
    { data: ResetPasswordRequestPostResetPasswordRequestDTOJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves a Settings resource.
 * @summary Retrieves a Settings resource.
 */
export const getSettings = (
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<SettingsJsonld>({ url: `/api/settings`, method: "get", signal }, options)
}

export const getGetSettingsQueryKey = () => [`/api/settings`]

export type GetSettingsQueryResult = NonNullable<Awaited<ReturnType<typeof getSettings>>>
export type GetSettingsQueryError = void

export const useGetSettings = <
  TData = Awaited<ReturnType<typeof getSettings>>,
  TError = void
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getSettings>>, TError, TData>
  request?: SecondParameter<typeof customMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSettingsQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSettings>>> = ({ signal }) =>
    getSettings(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getSettings>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of SharedData resources.
 * @summary Retrieves the collection of SharedData resources.
 */
export const getUserSharedDatas = (
  params?: GetUserSharedDatasParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetUserSharedDatas200>(
    { url: `/api/shared-datas`, method: "get", params, signal },
    options
  )
}

export const getGetUserSharedDatasQueryKey = (params?: GetUserSharedDatasParams) => [
  `/api/shared-datas`,
  ...(params ? [params] : []),
]

export type GetUserSharedDatasQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserSharedDatas>>
>
export type GetUserSharedDatasQueryError = unknown

export const useGetUserSharedDatas = <
  TData = Awaited<ReturnType<typeof getUserSharedDatas>>,
  TError = unknown
>(
  params?: GetUserSharedDatasParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserSharedDatas>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserSharedDatasQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserSharedDatas>>> = ({ signal }) =>
    getUserSharedDatas(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getUserSharedDatas>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Creates a SharedData resource.
 * @summary Creates a SharedData resource.
 */
export const createUserSharedData = (
  sharedDataPostSharedDataInputJsonldWriteItemSharedData: SharedDataPostSharedDataInputJsonldWriteItemSharedData,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/shared-datas`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: sharedDataPostSharedDataInputJsonldWriteItemSharedData,
    },
    options
  )
}

export type CreateUserSharedDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof createUserSharedData>>
>
export type CreateUserSharedDataMutationBody =
  SharedDataPostSharedDataInputJsonldWriteItemSharedData
export type CreateUserSharedDataMutationError = void

export const useCreateUserSharedData = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUserSharedData>>,
    TError,
    { data: SharedDataPostSharedDataInputJsonldWriteItemSharedData },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createUserSharedData>>,
    { data: SharedDataPostSharedDataInputJsonldWriteItemSharedData }
  > = props => {
    const { data } = props ?? {}

    return createUserSharedData(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof createUserSharedData>>,
    TError,
    { data: SharedDataPostSharedDataInputJsonldWriteItemSharedData },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Updates the SharedData resource.
 * @summary Updates the SharedData resource.
 */
export const updateUserSharedData = (
  sharedDataPatchSharedDataInputWriteItemSharedData: SharedDataPatchSharedDataInputWriteItemSharedData,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/shared-datas`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: sharedDataPatchSharedDataInputWriteItemSharedData,
    },
    options
  )
}

export type UpdateUserSharedDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUserSharedData>>
>
export type UpdateUserSharedDataMutationBody = SharedDataPatchSharedDataInputWriteItemSharedData
export type UpdateUserSharedDataMutationError = void

export const useUpdateUserSharedData = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserSharedData>>,
    TError,
    { data: SharedDataPatchSharedDataInputWriteItemSharedData },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserSharedData>>,
    { data: SharedDataPatchSharedDataInputWriteItemSharedData }
  > = props => {
    const { data } = props ?? {}

    return updateUserSharedData(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof updateUserSharedData>>,
    TError,
    { data: SharedDataPatchSharedDataInputWriteItemSharedData },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves a SharedData resource.
 * @summary Retrieves a SharedData resource.
 */
export const getUserSharedData = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<SharedDataJsonldReadCollectionSharedDataReadItemSharedData>(
    { url: `/api/shared-datas/${id}`, method: "get", signal },
    options
  )
}

export const getGetUserSharedDataQueryKey = (id: string) => [`/api/shared-datas/${id}`]

export type GetUserSharedDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserSharedData>>
>
export type GetUserSharedDataQueryError = void

export const useGetUserSharedData = <
  TData = Awaited<ReturnType<typeof getUserSharedData>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserSharedData>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserSharedDataQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserSharedData>>> = ({ signal }) =>
    getUserSharedData(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getUserSharedData>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of Society resources.
 * @summary Retrieves the collection of Society resources.
 */
export const getSocieties = (
  params?: GetSocietiesParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetSocieties200>(
    { url: `/api/societies`, method: "get", params, signal },
    options
  )
}

export const getGetSocietiesQueryKey = (params?: GetSocietiesParams) => [
  `/api/societies`,
  ...(params ? [params] : []),
]

export type GetSocietiesQueryResult = NonNullable<Awaited<ReturnType<typeof getSocieties>>>
export type GetSocietiesQueryError = unknown

export const useGetSocieties = <TData = Awaited<ReturnType<typeof getSocieties>>, TError = unknown>(
  params?: GetSocietiesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSocieties>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSocietiesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSocieties>>> = ({ signal }) =>
    getSocieties(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getSocieties>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Creates a Society resource.
 * @summary Creates a Society resource.
 */
export const postSociety = (
  societyPostSocietyInputJsonld: SocietyPostSocietyInputJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/societies`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: societyPostSocietyInputJsonld,
    },
    options
  )
}

export type PostSocietyMutationResult = NonNullable<Awaited<ReturnType<typeof postSociety>>>
export type PostSocietyMutationBody = SocietyPostSocietyInputJsonld
export type PostSocietyMutationError = void

export const usePostSociety = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSociety>>,
    TError,
    { data: SocietyPostSocietyInputJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSociety>>,
    { data: SocietyPostSocietyInputJsonld }
  > = props => {
    const { data } = props ?? {}

    return postSociety(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postSociety>>,
    TError,
    { data: SocietyPostSocietyInputJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves the collection of Society resources.
 * @summary Retrieves the collection of Society resources.
 */
export const getSocietiesExcelExportData = (
  params?: GetSocietiesExcelExportDataParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetSocietiesExcelExportData200>(
    { url: `/api/societies/excel-export`, method: "get", params, signal },
    options
  )
}

export const getGetSocietiesExcelExportDataQueryKey = (
  params?: GetSocietiesExcelExportDataParams
) => [`/api/societies/excel-export`, ...(params ? [params] : [])]

export type GetSocietiesExcelExportDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSocietiesExcelExportData>>
>
export type GetSocietiesExcelExportDataQueryError = unknown

export const useGetSocietiesExcelExportData = <
  TData = Awaited<ReturnType<typeof getSocietiesExcelExportData>>,
  TError = unknown
>(
  params?: GetSocietiesExcelExportDataParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSocietiesExcelExportData>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSocietiesExcelExportDataQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSocietiesExcelExportData>>> = ({
    signal,
  }) => getSocietiesExcelExportData(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getSocietiesExcelExportData>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves a Society resource.
 * @summary Retrieves a Society resource.
 */
export const getSociety = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<SocietyJsonldReadItemSocietyItem>(
    { url: `/api/societies/${id}`, method: "get", signal },
    options
  )
}

export const getGetSocietyQueryKey = (id: string) => [`/api/societies/${id}`]

export type GetSocietyQueryResult = NonNullable<Awaited<ReturnType<typeof getSociety>>>
export type GetSocietyQueryError = void

export const useGetSociety = <TData = Awaited<ReturnType<typeof getSociety>>, TError = void>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getSociety>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSocietyQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSociety>>> = ({ signal }) =>
    getSociety(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getSociety>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Removes the Society resource.
 * @summary Removes the Society resource.
 */
export const deleteSociety = (id: string, options?: SecondParameter<typeof customMutator>) => {
  return customMutator<void>({ url: `/api/societies/${id}`, method: "delete" }, options)
}

export type DeleteSocietyMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSociety>>>

export type DeleteSocietyMutationError = unknown

export const useDeleteSociety = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSociety>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSociety>>,
    { id: string }
  > = props => {
    const { id } = props ?? {}

    return deleteSociety(id, requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof deleteSociety>>, TError, { id: string }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Updates the Society resource.
 * @summary Updates the Society resource.
 */
export const patchSociety = (
  id: string,
  societyPatchSocietyInput: SocietyPatchSocietyInput,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/societies/${id}`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: societyPatchSocietyInput,
    },
    options
  )
}

export type PatchSocietyMutationResult = NonNullable<Awaited<ReturnType<typeof patchSociety>>>
export type PatchSocietyMutationBody = SocietyPatchSocietyInput
export type PatchSocietyMutationError = void

export const usePatchSociety = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchSociety>>,
    TError,
    { id: string; data: SocietyPatchSocietyInput },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchSociety>>,
    { id: string; data: SocietyPatchSocietyInput }
  > = props => {
    const { id, data } = props ?? {}

    return patchSociety(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchSociety>>,
    TError,
    { id: string; data: SocietyPatchSocietyInput },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Creates a Society resource.
 * @summary Creates a Society resource.
 */
export const postSocietyMedia = (
  id: string,
  postSocietyMediaBody: PostSocietyMediaBody,
  options?: SecondParameter<typeof customMutator>
) => {
  const formData = customFormDataFn(postSocietyMediaBody)
  return customMutator<unknown>(
    {
      url: `/api/societies/${id}/medias`,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options
  )
}

export type PostSocietyMediaMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSocietyMedia>>
>
export type PostSocietyMediaMutationBody = PostSocietyMediaBody
export type PostSocietyMediaMutationError = void

export const usePostSocietyMedia = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSocietyMedia>>,
    TError,
    { id: string; data: PostSocietyMediaBody },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSocietyMedia>>,
    { id: string; data: PostSocietyMediaBody }
  > = props => {
    const { id, data } = props ?? {}

    return postSocietyMedia(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postSocietyMedia>>,
    TError,
    { id: string; data: PostSocietyMediaBody },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Updates the Society resource.
 * @summary Updates the Society resource.
 */
export const patchSocietyMedia = (
  id: string,
  societyPatchEntityMediaInput: SocietyPatchEntityMediaInput,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/societies/${id}/medias`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: societyPatchEntityMediaInput,
    },
    options
  )
}

export type PatchSocietyMediaMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchSocietyMedia>>
>
export type PatchSocietyMediaMutationBody = SocietyPatchEntityMediaInput
export type PatchSocietyMediaMutationError = void

export const usePatchSocietyMedia = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchSocietyMedia>>,
    TError,
    { id: string; data: SocietyPatchEntityMediaInput },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchSocietyMedia>>,
    { id: string; data: SocietyPatchEntityMediaInput }
  > = props => {
    const { id, data } = props ?? {}

    return patchSocietyMedia(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchSocietyMedia>>,
    TError,
    { id: string; data: SocietyPatchEntityMediaInput },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Removes the Society resource.
 * @summary Removes the Society resource.
 */
export const deleteSocietyMedia = (
  id: string,
  mediaId: string,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<void>(
    { url: `/api/societies/${id}/medias/${mediaId}`, method: "delete" },
    options
  )
}

export type DeleteSocietyMediaMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSocietyMedia>>
>

export type DeleteSocietyMediaMutationError = unknown

export const useDeleteSocietyMedia = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSocietyMedia>>,
    TError,
    { id: string; mediaId: string },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSocietyMedia>>,
    { id: string; mediaId: string }
  > = props => {
    const { id, mediaId } = props ?? {}

    return deleteSocietyMedia(id, mediaId, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof deleteSocietyMedia>>,
    TError,
    { id: string; mediaId: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * @summary Invalidate refresh token.
 */
export const getInvalidateRefreshToken = (
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<InvalidateRefreshTokenResponse>(
    { url: `/api/token/invalidate`, method: "get", signal },
    options
  )
}

export const getGetInvalidateRefreshTokenQueryKey = () => [`/api/token/invalidate`]

export type GetInvalidateRefreshTokenQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInvalidateRefreshToken>>
>
export type GetInvalidateRefreshTokenQueryError = unknown

export const useGetInvalidateRefreshToken = <
  TData = Awaited<ReturnType<typeof getInvalidateRefreshToken>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getInvalidateRefreshToken>>, TError, TData>
  request?: SecondParameter<typeof customMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetInvalidateRefreshTokenQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvalidateRefreshToken>>> = ({
    signal,
  }) => getInvalidateRefreshToken(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getInvalidateRefreshToken>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * @summary Use refresh token to get new access token.
 */
export const getNewAccessToken = (
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<Token>({ url: `/api/token/refresh`, method: "get", signal }, options)
}

export const getGetNewAccessTokenQueryKey = () => [`/api/token/refresh`]

export type GetNewAccessTokenQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNewAccessToken>>
>
export type GetNewAccessTokenQueryError = unknown

export const useGetNewAccessToken = <
  TData = Awaited<ReturnType<typeof getNewAccessToken>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getNewAccessToken>>, TError, TData>
  request?: SecondParameter<typeof customMutator>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetNewAccessTokenQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNewAccessToken>>> = ({ signal }) =>
    getNewAccessToken(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getNewAccessToken>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of Tproperty resources.
 * @summary Retrieves the collection of Tproperty resources.
 */
export const getTproperties = (
  params?: GetTpropertiesParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetTproperties200>(
    { url: `/api/tproperties`, method: "get", params, signal },
    options
  )
}

export const getGetTpropertiesQueryKey = (params?: GetTpropertiesParams) => [
  `/api/tproperties`,
  ...(params ? [params] : []),
]

export type GetTpropertiesQueryResult = NonNullable<Awaited<ReturnType<typeof getTproperties>>>
export type GetTpropertiesQueryError = unknown

export const useGetTproperties = <
  TData = Awaited<ReturnType<typeof getTproperties>>,
  TError = unknown
>(
  params?: GetTpropertiesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTproperties>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTpropertiesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTproperties>>> = ({ signal }) =>
    getTproperties(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getTproperties>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of Tproperty resources.
 * @summary Retrieves the collection of Tproperty resources.
 */
export const getTpropertiesExcelExportData = (
  params?: GetTpropertiesExcelExportDataParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetTpropertiesExcelExportData200>(
    { url: `/api/tproperties/excel-export`, method: "get", params, signal },
    options
  )
}

export const getGetTpropertiesExcelExportDataQueryKey = (
  params?: GetTpropertiesExcelExportDataParams
) => [`/api/tproperties/excel-export`, ...(params ? [params] : [])]

export type GetTpropertiesExcelExportDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTpropertiesExcelExportData>>
>
export type GetTpropertiesExcelExportDataQueryError = unknown

export const useGetTpropertiesExcelExportData = <
  TData = Awaited<ReturnType<typeof getTpropertiesExcelExportData>>,
  TError = unknown
>(
  params?: GetTpropertiesExcelExportDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTpropertiesExcelExportData>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTpropertiesExcelExportDataQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTpropertiesExcelExportData>>> = ({
    signal,
  }) => getTpropertiesExcelExportData(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getTpropertiesExcelExportData>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves a Tproperty resource.
 * @summary Retrieves a Tproperty resource.
 */
export const getTproperty = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<TpropertyJsonldReadItemTpropertyWithoutPrivateData>(
    { url: `/api/tproperties/${id}`, method: "get", signal },
    options
  )
}

export const getGetTpropertyQueryKey = (id: string) => [`/api/tproperties/${id}`]

export type GetTpropertyQueryResult = NonNullable<Awaited<ReturnType<typeof getTproperty>>>
export type GetTpropertyQueryError = void

export const useGetTproperty = <TData = Awaited<ReturnType<typeof getTproperty>>, TError = void>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTproperty>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTpropertyQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTproperty>>> = ({ signal }) =>
    getTproperty(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getTproperty>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Updates the Tproperty resource.
 * @summary Updates the Tproperty resource.
 */
export const patchTproperty = (
  id: string,
  tpropertyWriteItemTproperty: TpropertyWriteItemTproperty,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<TpropertyJsonldReadItemTpropertyWithoutPrivateData>(
    {
      url: `/api/tproperties/${id}`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: tpropertyWriteItemTproperty,
    },
    options
  )
}

export type PatchTpropertyMutationResult = NonNullable<Awaited<ReturnType<typeof patchTproperty>>>
export type PatchTpropertyMutationBody = TpropertyWriteItemTproperty
export type PatchTpropertyMutationError = void

export const usePatchTproperty = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchTproperty>>,
    TError,
    { id: string; data: TpropertyWriteItemTproperty },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchTproperty>>,
    { id: string; data: TpropertyWriteItemTproperty }
  > = props => {
    const { id, data } = props ?? {}

    return patchTproperty(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchTproperty>>,
    TError,
    { id: string; data: TpropertyWriteItemTproperty },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves a Tproperty resource.
 * @summary Retrieves a Tproperty resource.
 */
export const getTpropertyLogs = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<TpropertyLogsOutputJsonld>(
    { url: `/api/tproperties/${id}/logs`, method: "get", signal },
    options
  )
}

export const getGetTpropertyLogsQueryKey = (id: string) => [`/api/tproperties/${id}/logs`]

export type GetTpropertyLogsQueryResult = NonNullable<Awaited<ReturnType<typeof getTpropertyLogs>>>
export type GetTpropertyLogsQueryError = void

export const useGetTpropertyLogs = <
  TData = Awaited<ReturnType<typeof getTpropertyLogs>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTpropertyLogs>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTpropertyLogsQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTpropertyLogs>>> = ({ signal }) =>
    getTpropertyLogs(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getTpropertyLogs>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of TpropertyAvailability resources.
 * @summary Retrieves the collection of TpropertyAvailability resources.
 */
export const gettPropertyAvailabilities = (
  params?: GettPropertyAvailabilitiesParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GettPropertyAvailabilities200>(
    { url: `/api/tproperty-availabilities`, method: "get", params, signal },
    options
  )
}

export const getGettPropertyAvailabilitiesQueryKey = (
  params?: GettPropertyAvailabilitiesParams
) => [`/api/tproperty-availabilities`, ...(params ? [params] : [])]

export type GettPropertyAvailabilitiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof gettPropertyAvailabilities>>
>
export type GettPropertyAvailabilitiesQueryError = unknown

export const useGettPropertyAvailabilities = <
  TData = Awaited<ReturnType<typeof gettPropertyAvailabilities>>,
  TError = unknown
>(
  params?: GettPropertyAvailabilitiesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof gettPropertyAvailabilities>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGettPropertyAvailabilitiesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gettPropertyAvailabilities>>> = ({
    signal,
  }) => gettPropertyAvailabilities(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof gettPropertyAvailabilities>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of TpropertyFacility resources.
 * @summary Retrieves the collection of TpropertyFacility resources.
 */
export const gettPropertyFacilities = (
  params?: GettPropertyFacilitiesParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GettPropertyFacilities200>(
    { url: `/api/tproperty-facilities`, method: "get", params, signal },
    options
  )
}

export const getGettPropertyFacilitiesQueryKey = (params?: GettPropertyFacilitiesParams) => [
  `/api/tproperty-facilities`,
  ...(params ? [params] : []),
]

export type GettPropertyFacilitiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof gettPropertyFacilities>>
>
export type GettPropertyFacilitiesQueryError = unknown

export const useGettPropertyFacilities = <
  TData = Awaited<ReturnType<typeof gettPropertyFacilities>>,
  TError = unknown
>(
  params?: GettPropertyFacilitiesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof gettPropertyFacilities>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGettPropertyFacilitiesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gettPropertyFacilities>>> = ({ signal }) =>
    gettPropertyFacilities(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof gettPropertyFacilities>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of TpropertyMandateType resources.
 * @summary Retrieves the collection of TpropertyMandateType resources.
 */
export const gettPropertyMandateTypes = (
  params?: GettPropertyMandateTypesParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GettPropertyMandateTypes200>(
    { url: `/api/tproperty-mandate-types`, method: "get", params, signal },
    options
  )
}

export const getGettPropertyMandateTypesQueryKey = (params?: GettPropertyMandateTypesParams) => [
  `/api/tproperty-mandate-types`,
  ...(params ? [params] : []),
]

export type GettPropertyMandateTypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof gettPropertyMandateTypes>>
>
export type GettPropertyMandateTypesQueryError = unknown

export const useGettPropertyMandateTypes = <
  TData = Awaited<ReturnType<typeof gettPropertyMandateTypes>>,
  TError = unknown
>(
  params?: GettPropertyMandateTypesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof gettPropertyMandateTypes>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGettPropertyMandateTypesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gettPropertyMandateTypes>>> = ({
    signal,
  }) => gettPropertyMandateTypes(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof gettPropertyMandateTypes>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of TpropertySituation resources.
 * @summary Retrieves the collection of TpropertySituation resources.
 */
export const gettPropertySituations = (
  params?: GettPropertySituationsParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GettPropertySituations200>(
    { url: `/api/tproperty-situations`, method: "get", params, signal },
    options
  )
}

export const getGettPropertySituationsQueryKey = (params?: GettPropertySituationsParams) => [
  `/api/tproperty-situations`,
  ...(params ? [params] : []),
]

export type GettPropertySituationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof gettPropertySituations>>
>
export type GettPropertySituationsQueryError = unknown

export const useGettPropertySituations = <
  TData = Awaited<ReturnType<typeof gettPropertySituations>>,
  TError = unknown
>(
  params?: GettPropertySituationsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof gettPropertySituations>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGettPropertySituationsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gettPropertySituations>>> = ({ signal }) =>
    gettPropertySituations(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof gettPropertySituations>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of TpropertySpecificity resources.
 * @summary Retrieves the collection of TpropertySpecificity resources.
 */
export const gettPropertySpecificities = (
  params?: GettPropertySpecificitiesParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GettPropertySpecificities200>(
    { url: `/api/tproperty-specificities`, method: "get", params, signal },
    options
  )
}

export const getGettPropertySpecificitiesQueryKey = (params?: GettPropertySpecificitiesParams) => [
  `/api/tproperty-specificities`,
  ...(params ? [params] : []),
]

export type GettPropertySpecificitiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof gettPropertySpecificities>>
>
export type GettPropertySpecificitiesQueryError = unknown

export const useGettPropertySpecificities = <
  TData = Awaited<ReturnType<typeof gettPropertySpecificities>>,
  TError = unknown
>(
  params?: GettPropertySpecificitiesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof gettPropertySpecificities>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGettPropertySpecificitiesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gettPropertySpecificities>>> = ({
    signal,
  }) => gettPropertySpecificities(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof gettPropertySpecificities>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of TpropertyState resources.
 * @summary Retrieves the collection of TpropertyState resources.
 */
export const gettPropertyStates = (
  params?: GettPropertyStatesParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GettPropertyStates200>(
    { url: `/api/tproperty-states`, method: "get", params, signal },
    options
  )
}

export const getGettPropertyStatesQueryKey = (params?: GettPropertyStatesParams) => [
  `/api/tproperty-states`,
  ...(params ? [params] : []),
]

export type GettPropertyStatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof gettPropertyStates>>
>
export type GettPropertyStatesQueryError = unknown

export const useGettPropertyStates = <
  TData = Awaited<ReturnType<typeof gettPropertyStates>>,
  TError = unknown
>(
  params?: GettPropertyStatesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof gettPropertyStates>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGettPropertyStatesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gettPropertyStates>>> = ({ signal }) =>
    gettPropertyStates(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof gettPropertyStates>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of TpropertySubType resources.
 * @summary Retrieves the collection of TpropertySubType resources.
 */
export const gettPropertySubTypes = (
  params?: GettPropertySubTypesParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GettPropertySubTypes200>(
    { url: `/api/tproperty-sub-types`, method: "get", params, signal },
    options
  )
}

export const getGettPropertySubTypesQueryKey = (params?: GettPropertySubTypesParams) => [
  `/api/tproperty-sub-types`,
  ...(params ? [params] : []),
]

export type GettPropertySubTypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof gettPropertySubTypes>>
>
export type GettPropertySubTypesQueryError = unknown

export const useGettPropertySubTypes = <
  TData = Awaited<ReturnType<typeof gettPropertySubTypes>>,
  TError = unknown
>(
  params?: GettPropertySubTypesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof gettPropertySubTypes>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGettPropertySubTypesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gettPropertySubTypes>>> = ({ signal }) =>
    gettPropertySubTypes(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof gettPropertySubTypes>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of TpropertyViewType resources.
 * @summary Retrieves the collection of TpropertyViewType resources.
 */
export const gettPropertyViewTypes = (
  params?: GettPropertyViewTypesParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GettPropertyViewTypes200>(
    { url: `/api/tproperty-view-types`, method: "get", params, signal },
    options
  )
}

export const getGettPropertyViewTypesQueryKey = (params?: GettPropertyViewTypesParams) => [
  `/api/tproperty-view-types`,
  ...(params ? [params] : []),
]

export type GettPropertyViewTypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof gettPropertyViewTypes>>
>
export type GettPropertyViewTypesQueryError = unknown

export const useGettPropertyViewTypes = <
  TData = Awaited<ReturnType<typeof gettPropertyViewTypes>>,
  TError = unknown
>(
  params?: GettPropertyViewTypesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof gettPropertyViewTypes>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGettPropertyViewTypesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gettPropertyViewTypes>>> = ({ signal }) =>
    gettPropertyViewTypes(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof gettPropertyViewTypes>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of TpropertyView resources.
 * @summary Retrieves the collection of TpropertyView resources.
 */
export const gettPropertyViews = (
  params?: GettPropertyViewsParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GettPropertyViews200>(
    { url: `/api/tproperty-views`, method: "get", params, signal },
    options
  )
}

export const getGettPropertyViewsQueryKey = (params?: GettPropertyViewsParams) => [
  `/api/tproperty-views`,
  ...(params ? [params] : []),
]

export type GettPropertyViewsQueryResult = NonNullable<
  Awaited<ReturnType<typeof gettPropertyViews>>
>
export type GettPropertyViewsQueryError = unknown

export const useGettPropertyViews = <
  TData = Awaited<ReturnType<typeof gettPropertyViews>>,
  TError = unknown
>(
  params?: GettPropertyViewsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof gettPropertyViews>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGettPropertyViewsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof gettPropertyViews>>> = ({ signal }) =>
    gettPropertyViews(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof gettPropertyViews>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of Tsubscription resources.
 * @summary Retrieves the collection of Tsubscription resources.
 */
export const getTsubscriptions = (
  params?: GetTsubscriptionsParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetTsubscriptions200>(
    { url: `/api/tsubscriptions`, method: "get", params, signal },
    options
  )
}

export const getGetTsubscriptionsQueryKey = (params?: GetTsubscriptionsParams) => [
  `/api/tsubscriptions`,
  ...(params ? [params] : []),
]

export type GetTsubscriptionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTsubscriptions>>
>
export type GetTsubscriptionsQueryError = unknown

export const useGetTsubscriptions = <
  TData = Awaited<ReturnType<typeof getTsubscriptions>>,
  TError = unknown
>(
  params?: GetTsubscriptionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTsubscriptions>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTsubscriptionsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTsubscriptions>>> = ({ signal }) =>
    getTsubscriptions(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getTsubscriptions>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Creates a Tsubscription resource.
 * @summary Creates a Tsubscription resource.
 */
export const postTsubscription = (
  tsubscriptionPostSubscriptionInputJsonld: TsubscriptionPostSubscriptionInputJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/tsubscriptions`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: tsubscriptionPostSubscriptionInputJsonld,
    },
    options
  )
}

export type PostTsubscriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postTsubscription>>
>
export type PostTsubscriptionMutationBody = TsubscriptionPostSubscriptionInputJsonld
export type PostTsubscriptionMutationError = void

export const usePostTsubscription = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postTsubscription>>,
    TError,
    { data: TsubscriptionPostSubscriptionInputJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postTsubscription>>,
    { data: TsubscriptionPostSubscriptionInputJsonld }
  > = props => {
    const { data } = props ?? {}

    return postTsubscription(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postTsubscription>>,
    TError,
    { data: TsubscriptionPostSubscriptionInputJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Removes the Tsubscription resource.
 * @summary Removes the Tsubscription resource.
 */
export const deleteTsubscription = (
  id: string,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<void>({ url: `/api/tsubscriptions/${id}`, method: "delete" }, options)
}

export type DeleteTsubscriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTsubscription>>
>

export type DeleteTsubscriptionMutationError = unknown

export const useDeleteTsubscription = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTsubscription>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTsubscription>>,
    { id: string }
  > = props => {
    const { id } = props ?? {}

    return deleteTsubscription(id, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof deleteTsubscription>>,
    TError,
    { id: string },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Updates the Tsubscription resource.
 * @summary Updates the Tsubscription resource.
 */
export const patchTsubscription = (
  id: string,
  tsubscriptionPatchSubscriptionInput: TsubscriptionPatchSubscriptionInput,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/tsubscriptions/${id}`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: tsubscriptionPatchSubscriptionInput,
    },
    options
  )
}

export type PatchTsubscriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchTsubscription>>
>
export type PatchTsubscriptionMutationBody = TsubscriptionPatchSubscriptionInput
export type PatchTsubscriptionMutationError = void

export const usePatchTsubscription = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchTsubscription>>,
    TError,
    { id: string; data: TsubscriptionPatchSubscriptionInput },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchTsubscription>>,
    { id: string; data: TsubscriptionPatchSubscriptionInput }
  > = props => {
    const { id, data } = props ?? {}

    return patchTsubscription(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchTsubscription>>,
    TError,
    { id: string; data: TsubscriptionPatchSubscriptionInput },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves a Tsubscription resource.
 * @summary Retrieves a Tsubscription resource.
 */
export const getTsubscriptionTranslations = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<TsubscriptionTranslationsDTO>(
    { url: `/api/tsubscriptions/${id}/translations`, method: "get", signal },
    options
  )
}

export const getGetTsubscriptionTranslationsQueryKey = (id: string) => [
  `/api/tsubscriptions/${id}/translations`,
]

export type GetTsubscriptionTranslationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTsubscriptionTranslations>>
>
export type GetTsubscriptionTranslationsQueryError = void

export const useGetTsubscriptionTranslations = <
  TData = Awaited<ReturnType<typeof getTsubscriptionTranslations>>,
  TError = void
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTsubscriptionTranslations>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTsubscriptionTranslationsQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTsubscriptionTranslations>>> = ({
    signal,
  }) => getTsubscriptionTranslations(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getTsubscriptionTranslations>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Retrieves the collection of User resources.
 * @summary Retrieves the collection of User resources.
 */
export const getUsers = (
  params?: GetUsersParams,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<GetUsers200>({ url: `/api/users`, method: "get", params, signal }, options)
}

export const getGetUsersQueryKey = (params?: GetUsersParams) => [
  `/api/users`,
  ...(params ? [params] : []),
]

export type GetUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getUsers>>>
export type GetUsersQueryError = unknown

export const useGetUsers = <TData = Awaited<ReturnType<typeof getUsers>>, TError = unknown>(
  params?: GetUsersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({ signal }) =>
    getUsers(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getUsers>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Creates a User resource.
 * @summary Creates a User resource.
 */
export const postUser = (
  userCreateUserInputJsonld: UserCreateUserInputJsonld,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/users/`,
      method: "post",
      headers: { "Content-Type": "application/ld+json" },
      data: userCreateUserInputJsonld,
    },
    options
  )
}

export type PostUserMutationResult = NonNullable<Awaited<ReturnType<typeof postUser>>>
export type PostUserMutationBody = UserCreateUserInputJsonld
export type PostUserMutationError = void

export const usePostUser = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUser>>,
    TError,
    { data: UserCreateUserInputJsonld },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUser>>,
    { data: UserCreateUserInputJsonld }
  > = props => {
    const { data } = props ?? {}

    return postUser(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof postUser>>,
    TError,
    { data: UserCreateUserInputJsonld },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Retrieves a User resource.
 * @summary Retrieves a User resource.
 */
export const getUser = (
  id: string,
  options?: SecondParameter<typeof customMutator>,
  signal?: AbortSignal
) => {
  return customMutator<UserJsonldUserReadItem>(
    { url: `/api/users/${id}`, method: "get", signal },
    options
  )
}

export const getGetUserQueryKey = (id: string) => [`/api/users/${id}`]

export type GetUserQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>
export type GetUserQueryError = void

export const useGetUser = <TData = Awaited<ReturnType<typeof getUser>>, TError = void>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>
    request?: SecondParameter<typeof customMutator>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({ signal }) =>
    getUser(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getUser>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Removes the User resource.
 * @summary Removes the User resource.
 */
export const deleteUser = (id: string, options?: SecondParameter<typeof customMutator>) => {
  return customMutator<void>({ url: `/api/users/${id}`, method: "delete" }, options)
}

export type DeleteUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUser>>>

export type DeleteUserMutationError = unknown

export const useDeleteUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUser>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUser>>,
    { id: string }
  > = props => {
    const { id } = props ?? {}

    return deleteUser(id, requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof deleteUser>>, TError, { id: string }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Updates the User resource.
 * @summary Updates the User resource.
 */
export const patchUser = (
  id: string,
  userCreateUserInput: UserCreateUserInput,
  options?: SecondParameter<typeof customMutator>
) => {
  return customMutator<unknown>(
    {
      url: `/api/users/${id}`,
      method: "patch",
      headers: { "Content-Type": "application/merge-patch+json" },
      data: userCreateUserInput,
    },
    options
  )
}

export type PatchUserMutationResult = NonNullable<Awaited<ReturnType<typeof patchUser>>>
export type PatchUserMutationBody = UserCreateUserInput
export type PatchUserMutationError = void

export const usePatchUser = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUser>>,
    TError,
    { id: string; data: UserCreateUserInput },
    TContext
  >
  request?: SecondParameter<typeof customMutator>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchUser>>,
    { id: string; data: UserCreateUserInput }
  > = props => {
    const { id, data } = props ?? {}

    return patchUser(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof patchUser>>,
    TError,
    { id: string; data: UserCreateUserInput },
    TContext
  >(mutationFn, mutationOptions)
}
